/* eslint-disable import/prefer-default-export */
import styled from 'react-emotion';
import { MediumLabel, LargeLabel } from '~/modules/coreUI/components/legacies/Labels';

export const SubDepartmentName = styled(LargeLabel)`
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: capitalize;
`;

export const DepartmentTabsTitle = styled(MediumLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

/* eslint-env browser */
/* eslint-disable */
import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import Icon from '~/modules/coreUI/components/basic/Icon';
import Tracks from '../Tracks';

const FloatingIconWrapper = withDirection(styled.div`
  position: fixed;
  z-index: 4;
  ${props => (props.direction === 'rtl'
    ? css`
      right: 0;
    `
    : css`
      left: 0;
  `)}
  top: 40%;
  width: 45px;
`);

const IconStyle = styled(Icon)`
  display: inline-block;
  width: ${props => props.theme.paddings.xxxxxLarge + 5}px;
  text-align: center;
  font-size: ${props => props.theme.fonts.sizes.xLarge - 2}px;
  color: ${props => props.color};
  ${props => props.active
    && css`
      color: ${props.theme.colors.named.white};
    `};
`;
const Track = withDirection(styled(Row)`
  width: ${props => props.theme.new.spacer * 15 + 3}px;
  padding: ${props => props.theme.paddings.normal}px ${props => props.theme.paddings.medium}px;
  color: ${props => props.color};
  background: ${props => props.theme.colors.named.white};
  border-radius: 0;

  ${props => (props.direction === 'rtl'
    ? css`
          text-align: left;

          border-top-left-radius: ${props.theme.borders.radius.normal}px;
          border-bottom-left-radius: ${props.theme.borders.radius.normal}px;
          transform: translateX(107px);
          padding-left: 0;
        `
    : css`
          text-align: right;
          padding-right: 0;
          border-top-right-radius: ${props.theme.borders.radius.normal}px;
          border-bottom-right-radius: ${props.theme.borders.radius.normal}px;
          transform: translateX(-107px);
        `)} box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  ${props => props.active
    && css`
      color: ${props.theme.colors.named.white};
      background: ${props.color};
      img {
        filter: brightness(0) invert(1);
      }
    `} &:hover {
    transform: translateX(0);
    color: ${props => props.theme.colors.named.white};
    background: ${props => props.color};
    i,
    a {
      color: ${props => props.theme.colors.named.white};
    }
    img {
      filter: brightness(0) invert(1);
    }
  }
  transition: 0.3s ease-in-out;
  cursor: pointer;
`);
const ExternalLinkStyle = styled(ExternalLink)`
  color: ${props => props.color};
  font-size: ${props => props.theme.fonts.sizes.small - 0.5}px;
`;
const FloatingIcon = ({ media, match }) => {
  const initialArr = [
    {
      index: 0,
      value: false
    },
    {
      index: 1,
      value: false
    },
    {
      index: 2,
      value: false
    },
    {
      index: 3,
      value: false
    },
    {
      index: 4,
      value: false
    }
  ]

  function handleHover(index, value){
    let newArr = [...hoveringArr];
    newArr[index].value = value;

    setHoveringArr(newArr);
  }
  const [hoveringArr, setHoveringArr] = useState(initialArr);
  return(
    <React.Fragment>
      {media.minDesktop && (
        <FloatingIconWrapper>
          {Tracks.map(track => (
            <ExternalLinkStyle url={track.url} color={track.color} key={track.name + track.icon}>
              <Track 
                className="track-body"
                centerAligned
                spaceBetweenJustified
                active={match.params.track === track.id}
                color={track.color}
                onMouseEnter={() => handleHover(track.index, true)}
                onMouseLeave={() => handleHover(track.index, false)}
              >
                <span>{i18next.t(track.name)}</span>
                <span className="wrapper-icon">
                  {hoveringArr[track.index].value == true &&(
                    track.activeIcon
                  )}
                  {match.params.track != track.id && hoveringArr[track.index].value == false &&(
                    track.trackSvgIcon
                  )}
                  {match.params.track === track.id && hoveringArr[track.index].value == false &&(
                    track.activeIcon
                  )}
                </span>
              </Track>
              <Spacer size={0.2} />
            </ExternalLinkStyle>
          ))}
        </FloatingIconWrapper>
      )}
    </React.Fragment>
  )
};
FloatingIcon.propTypes = {
  media: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      track: PropTypes.string,
    }),
  }).isRequired,
};
export default withRouter(translate('ecommerceCoreUI')(withMedia(FloatingIcon)));

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { withTheme } from 'emotion-theming';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';

import EditUserSettingsForm from './EditUserSettingsForm';

class UserProfile extends React.Component {
  getUserInfo = (currentUser, userSettings) => ({
    country_id: currentUser?.country?.ref_id || userSettings?.current_location_country?.ref_id,
    country_code: currentUser?.country?.phone_code || userSettings?.current_location_country?.phone_code,
    country_name: currentUser?.country?.name || userSettings?.current_location_country?.name,
    default_currency: currentUser?.default_currency?.name || userSettings?.currency || 'QAR',
    default_lang: currentUser?.default_lang || userSettings?.language || 'en',
    supported_currencies: userSettings?.supported_currencies,
    supported_languages: userSettings?.supported_languages,
    receive_push_notifications: currentUser?.receive_push_notifications,
    newsletter_subscription: currentUser?.newsletter_subscription,
  });

  render = () => {
    let userInfo = null;
    const errors = this.props.currentUser?.errors;
    const hasErrors = errors?.length > 0;

    if (!hasErrors) {
      userInfo = this.getUserInfo(this.props.currentUser, this.props.userInfo.settings);
    }

    return (
      <Panel
        borderLessHeader
        borderLessBody
        headerTitle={
          i18next.t('accountManagement:Profile.personalInfo')
        }
        body={(
          <Box centerAligned fullWidth>
            <Spacer size={1.5} />
            {hasErrors && (
              <React.Fragment>
                <PanelAlert type="error" inverted errors={errors} />
                <Spacer size={1.5} />
              </React.Fragment>
            )}
            <EditUserSettingsForm
              userInfo={userInfo}
            />
          </Box>
        )}
      />
    );
  }
}

UserProfile.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};


export default withTheme(withUserInfo(UserProfile));

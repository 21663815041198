import React from 'react';

import OrdersList from '~/modules/ecommerceOrder/containers/ordersList/OrdersList';
import RefundList from '~/modules/ecommerceOrder/containers/refund/RefundList';
import RecentViewed from '~/modules/ecommerceOrder/containers/recentViewed/RecentViewed';
import WishList from '~/modules/ecommerceOrder/containers/wishList/WishList';
import UserProfile from '~/modules/accountManagement/containers/accountSettings/userProfile';
import UserSettings from '~/modules/accountManagement/containers/accountSettings/userSettings';
import FollowingList from '~/modules/ecommerceOrder/containers/followingList/FollowingList';
import MyPromos from '~/modules/accountManagement/containers/accountSettings/myPromos/MyPromos';
import LoyaltyPrograms from '~/modules/ecommerceOrder/containers/loyaltyPrograms/LoyaltyPrograms';
import InviteFriend from '~/modules/ecommerceOrder/containers/inviteFriend/InviteFriend';
import SmartCart from '~/modules/ecommerceOrder/containers/smartCart/smartCartsList/SmartCartsList';
import NotificationAndMessages from '~/modules/ecommerceOrder/containers/notificationAndMessages';

/* NOTE: value have to match :tab params name */

const DesktopTabPanelContent = [
  {
    value: 'profile',
    title: 'ecommerceCoreUI:Tabs.Profile',
    content: <UserProfile />,
    iconName: 'profile',
  },
  {
    value: 'my-orders',
    title: 'ecommerceCoreUI:Tabs.Orders',
    content: <OrdersList />,
    iconName: 'list',
  },
  {
    value: 'my-refunds',
    title: 'ecommerceCoreUI:Tabs.Refunds',
    content: <RefundList />,
    iconName: 'refund',
  },
  {
    value: 'recent-viewed',
    title: 'ecommerceCoreUI:Tabs.RecentlyViewed',
    content: <RecentViewed />,
    iconName: 'view',
  },
  {
    value: 'notificationsAndMessages',
    title: 'ecommerceCoreUI:Tabs.Notifications',
    content: <NotificationAndMessages />,
    iconName: 'notifications',
  },
  {
    value: 'wishlist',
    title: 'ecommerceCoreUI:Tabs.Wishlist',
    content: <WishList />,
    iconName: 'heart',
  },
  {
    value: 'smartcart',
    title: 'ecommerceCoreUI:Tabs.SmartCart',
    content: <SmartCart />,
    iconName: 'smart',
  },
  {
    value: 'following',
    title: 'ecommerceCoreUI:Tabs.Following',
    content: <FollowingList />,
    iconName: 'follow',
  },
  {
    value: 'loyalty',
    title: 'ecommerceCoreUI:Tabs.Loyalty',
    content: <LoyaltyPrograms />,
    iconName: 'loyalty',
  },
  {
    value: 'coupons',
    title: 'ecommerceCoreUI:Tabs.Coupons',
    content: <MyPromos />,
    iconName: 'promo',
  },
  {
    value: 'settings',
    title: 'ecommerceCoreUI:Tabs.Settings',
    content: <UserSettings />,
    iconName: 'setting',
  },
  {
    value: 'invite',
    title: 'ecommerceCoreUI:Tabs.Invite',
    content: <InviteFriend />,
    iconName: 'invite',
  },
];

export default DesktopTabPanelContent;

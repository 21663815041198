import { graphql } from 'react-relay';

export const ResendSMSConfirmationCodeMutationRoot = 'send_confirmation_code';

export default graphql`
  mutation ResendSMSConfirmationCodeMutation {
    send_confirmation_sms {
      errors {
        field
        messages
        code
      }
      sent
    }
  }
`;

import React from 'react';
import styled from 'react-emotion';
import i18next from 'i18next';
import Media from 'react-media';
import PropTypes from 'prop-types';

import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import { ParagraphPanelContent } from '~/modules/accountManagement/components/basic/Labels';
import {
  CenterAlignedColumn,
  LeftAlignedColumn,
} from '~/modules/coreUI/components/legacies/Columns';
import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import { LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Button from '~/modules/coreUI/components/basic/Button';
import { MediumSpacer, XLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import Panel, { PanelRoot } from '~/modules/accountManagement/components/basic/Panel';
import { cssMediaMax, cssMediaMin, mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Label } from '~/modules/coreUI/components/basic/Labels';

import PopupLogo from '~/modules/accountManagement/components/basic/PopupLogo';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';

import ResendEmailConfirmationCodeMutation, {
  ResendEmailConfirmationCodeMutationRoot,
} from '~/modules/accountManagement/containers/panels/verifyAccount/ResendEmailConfirmationCodeMutation';

import ResendSMSConfirmationCodeMutation, {
  ResendSMSConfirmationCodeMutationRoot,
} from '~/modules/accountManagement/containers/panels/verifyAccount/ResendSMSConfirmationCodeMutation';

const InputLayout = styled(PanelRoot)`
  display: flex;
  align-items: stretch;

  ${cssMediaMin.tablet`
    width: 300px;
  `};
`;

const ResponsivePanel = styled(Panel)`
  ${cssMediaMax.mobile`
    width: 90%;
  `};
`;

const MediumLogo = styled(PopupLogo)`
  height: unset;
  width: unset;
  max-width: 80px;
`;

const ImageContainer = styled.div`
  width: 115px;
`;

const SpacedList = styled.ul`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 0px;

    ${cssMediaMax.tablet`
      margin: auto;
    `} li {
      margin-top: 10px;
    }
  }
`;


class EmailOrSMS extends React.Component {
  state = {
    panelError: null,
    emailIsLoading: false,
    smsIsLoading: false,
  };

  /* eslint-disable react/sort-comp */
  componentDidMount = () => {
    if (!this.props.userInfo || !this.props.userInfo.email) {
      this.setState({
        panelError:
        'Seems like you reached here by mistake, email/number is missing, kindly go to the previous step and try again',
      });
    }
  };

  onMutationError = (errors) => {
    this.setState({ panelError: errors });
  };

  onEmailMutationLoading = (emailIsLoading) => {
    this.setState({
      emailIsLoading,
    });
  };

  onSmsMutationLoading = (smsIsLoading) => {
    this.setState({
      smsIsLoading,
    });
  };

  curriedCommitMutation = _.curry(commitMutation)(this.props.environment, _, _, _, _, this.onMutationError, _); // eslint-disable-line no-undef
  /* eslint-enable react/sort-comp */

  navigateTo = method => navigateToModal(this.props.history, `/accountManagement/VerifyBy${method}`);

  verifyByEmail = () => {
    this.curriedCommitMutation(
      ResendEmailConfirmationCodeMutation,
      ResendEmailConfirmationCodeMutationRoot,
      { email: this.props.userInfo.email },
      () => this.navigateTo('Email'),
      this.onEmailMutationLoading,
    );
  };

  verifyBySMS = () => {
    this.curriedCommitMutation(
      ResendSMSConfirmationCodeMutation,
      ResendSMSConfirmationCodeMutationRoot,
      null,
      () => this.navigateTo('SMS'),
      this.onSmsMutationLoading,
    );
  };

  render = () => {
    const { panelError } = this.state;
    return (
      <ResponsivePanel
        titleLabel={i18next.t('accountManagement:verifyAccount.HeaderTitle')}
        subTitleLabel={i18next.t('accountManagement:verifyAccount.SubTitle')}
        error={panelError}
      >
        <CenterAlignedColumn>
          <Spacer size={2} />
          <ImageContainer>
            <MediumLogo />
          </ImageContainer>
          <XLargeSpacer />
          <ParagraphPanelContent>
            <CenterAlignedRow>
              <CenterAlignedColumn>
                {i18next.t('accountManagement:verifyAccount.verifyParagraph')}
              </CenterAlignedColumn>
            </CenterAlignedRow>
          </ParagraphPanelContent>
          <MediumSpacer />
          <Media query={mediaQueryMin('mobile')}>
            {isOnDesktop => (
              <LinearLayout row={isOnDesktop}>
                <InputLayout>
                  <Button
                    block
                    primary
                    lg
                    loading={this.state.emailIsLoading}
                    onClicked={this.verifyByEmail}
                    disabled={this.state.smsIsLoading}
                    width="100%"
                  >
                    {i18next.t('accountManagement:verifyAccount.VerifyByMail')}
                  </Button>
                </InputLayout>
                <MediumSpacer />
                <InputLayout>
                  <Button
                    block
                    primary
                    lg
                    loading={this.state.smsIsLoading}
                    disabled={this.state.emailIsLoading}
                    onClicked={this.verifyBySMS}
                    width="100%"
                  >
                    {i18next.t('accountManagement:verifyAccount.VerifyBySMS')}
                  </Button>
                </InputLayout>
              </LinearLayout>
            )}
          </Media>
          <MediumSpacer />
          <LeftAlignedColumn>
            <SpacedList>
              <li key="1">
                <span>
                  <Label important sm semiBold>
                    {i18next.t('accountManagement:verifyAccount.VerifyByMail')}
                  </Label>
                    &nbsp;
                  {i18next.t('accountManagement:verifyAccount.VerifyEmailTxt')}
                </span>
              </li>
              <li key="2">
                <span>
                  <Label important sm semiBold>
                    {i18next.t('accountManagement:verifyAccount.VerifyBySMS')}
                  </Label>
                  &nbsp;
                  {i18next.t('accountManagement:verifyAccount.VerifySMSTxt')}
                </span>
              </li>
            </SpacedList>
          </LeftAlignedColumn>
        </CenterAlignedColumn>
      </ResponsivePanel>
    );
  };
}

EmailOrSMS.propTypes = {
  history: PropTypes.shape({}).isRequired,
  environment: PropTypes.shape({}).isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    mobile_number: PropTypes.string,
  }).isRequired,
};

export default withUserInfo(
  withRelayEnvironment(EmailOrSMS),
);

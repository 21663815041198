import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';

import SquareImageCard from '~/modules/ecommerceCoreUI/components/basic/SquareImageCard';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const Image = withMedia(styled(SquareImageCard)`
  width: 105px;
  height: 105px;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  border-width: ${props => props.theme.borders.size.thin}px;
  border-style: solid;
  border-color: ${props => props.theme.borders.color.light};
  ${props => props.media.maxMobile
    && css`
      width: 75px;
      height: 75px;
    `};
`);

const ItemImage = ({ src }) => <Image imageBackground={src} />;

ItemImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ItemImage;

/**
 * @flow
 * @relayHash 905d8b2aa02bdfeee298c41767eb740b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarkReadMutationVariables = {|
  notification_id?: ?string
|};
export type MarkReadMutationResponse = {|
  +mark_as_read: ?{|
    +notification: ?{|
      +read: ?boolean
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type MarkReadMutation = {|
  variables: MarkReadMutationVariables,
  response: MarkReadMutationResponse,
|};
*/


/*
mutation MarkReadMutation(
  $notification_id: ID
) {
  mark_as_read(notification_id: $notification_id) {
    notification {
      read
      id
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "notification_id",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "notification_id",
    "variableName": "notification_id",
    "type": "ID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "read",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MarkReadMutation",
  "id": null,
  "text": "mutation MarkReadMutation(\n  $notification_id: ID\n) {\n  mark_as_read(notification_id: $notification_id) {\n    notification {\n      read\n      id\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MarkReadMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mark_as_read",
        "storageKey": null,
        "args": v1,
        "concreteType": "MarkReadResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "notification",
            "storageKey": null,
            "args": null,
            "concreteType": "NotificationDetails",
            "plural": false,
            "selections": [
              v2
            ]
          },
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkReadMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mark_as_read",
        "storageKey": null,
        "args": v1,
        "concreteType": "MarkReadResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "notification",
            "storageKey": null,
            "args": null,
            "concreteType": "NotificationDetails",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fafcd3ae02e7d003a5412ffe8c8989c1';
module.exports = node;

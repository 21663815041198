const ProductTrans = {
  En: {
    startFrom: 'Start from',
    taxesIncluded: 'Taxes Included',
    taxesNotIncluded: 'Taxes Not Included',
    moreThan: 'More Than',
    share: 'Share Product',
    description: 'Description',
    specifications: 'Properties',
    sellingShops: {
      title: 'Buying Options',
      subTitle: 'Add to cart from your favorite shop',
      madeIn: 'Made in',
      brand: 'Brand',
      weight: 'Gross Weight',
      dimensions: 'Package Dimensions',
    },
    newItem: 'Added to your cart successfully',
    hoverToZoom: 'Hover to Zoom In',
    holdToZoom: 'Hold to Zoom In',
    whatSize: 'Size Chart',
    offerItemsTitle: 'Items in This Bundle Offer',
    offerLabel: 'Bundle Offer',
    savedLabel: 'You Save',
    discount: ' Discount ',
    NoDescriptionLabel: 'No available description',
    viewOfferLink: 'View Offer',
    relatedProducts: 'Related Products',
    UnavailableProductLabel: 'Currently Unavailable',
    buyingOptionsLink: 'Check other buying options',
    buyingOptionsScrollLink: 'See All Buying Options',
    noSpecifications: 'No available properties',
    productSummary: 'Summary',
  },
  Ar: {
    startFrom: 'يبدأ من',
    taxesIncluded: 'شامل الضريبة',
    taxesNotIncluded: 'غير شامل الضريبة',
    moreThan: 'اكثر من',
    share: 'مشاركة المنتج',
    description: 'الوصف',
    specifications: 'الخصائص',
    sellingShops: {
      title: 'خيارات الشراء',
      subTitle: 'أضف إلى العربة من محلك المفضل',
      madeIn: 'صنع في',
      brand: 'العلامة التجارية',
      weight: 'الوزن الإجمالي',
      dimensions: 'أبعاد العبوة',
    },
    newItem: 'تمت الإضافة إلى عربتك بنجاح',
    hoverToZoom: 'ضع المؤشر للتكبير',
    holdToZoom: 'ثبت إصبعك للتكبير',
    whatSize: 'جدول المقاسات',
    offerItemsTitle: 'الأصناف الموجودة في هذا العرض',
    offerLabel: 'عرض الحزمة',
    savedLabel: 'ستوفر',
    discount: ' خصم',
    NoDescriptionLabel: 'الوصف غير متاح',
    viewOfferLink: 'مشاهدة العرض',
    relatedProducts: 'منتجات ذات صلة',
    UnavailableProductLabel: 'غير متاح حاليًا',
    buyingOptionsLink: 'تحقق من خيارات الشراء الأخرى',
    buyingOptionsScrollLink: 'انظر جميع خيارات الشراء',
    noSpecifications: 'الخصائص غير متاحة',
    productSummary: 'وصف ملخص',
  },
};

export default ProductTrans;

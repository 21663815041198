import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { XLargeLabel } from '~/modules/coreUI/components/basic/Labels';
import CloseLuckyWheelBox from './CloseLuckyWheelBox';

const XLargeLabelStyle = styled(XLargeLabel)`
  color: ${props => props.theme.colors.labels.important};
  text-align: center;
`;

const SorryBoxDescription = ({ description }) => (
  <Column spaceBetween={4}>
    <XLargeLabelStyle semiBold>
      {description}
    </XLargeLabelStyle>
    <CloseLuckyWheelBox text={i18next.t('ecommerceCoreUI:luckyWheel.close')} />
  </Column>
);

SorryBoxDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default SorryBoxDescription;

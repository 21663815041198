import React from 'react';
import i18next from 'i18next';
import { Container } from 'semantic-ui-react';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import SectionHeader from '~/modules/coreUI/components/layouts/SectionHeader';

const SectionTitle = () => (
  <Container>
    <Spacer size={4} />
    <SectionHeader
      title={i18next.t('ecommerceCoreUI:DailyDeals.deailsOfDay')}
      subtitle={i18next.t('ecommerceCoreUI:DailyDeals.enjoyOurDeals')}
    />
    <Spacer size={2} />
  </Container>
);

export default SectionTitle;

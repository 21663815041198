import i18next from 'i18next';
import Checkout from './Checkout';
import Tooltip from './tooltip';
import Cart from './cart';
import Order from './Order';
import Following from './Following';
import RecentViewed from './RecentViewed';
import Wishlist from './Wishlist';
import refundTrans from './refund';
import Loyalty from './Loyalty';
import InviteFriend from './InviteFriend';
import smartCart from './smartCart';
import notificationsAndMessages from './notificationsAndMessages';
import askAyk from './askAyk';
import cancelService from './cancelService';

const loadLocales = () => {
  i18next.addResourceBundle(
    'en',
    'ecommerceOrder',
    {
      metadata: {
        name: 'ecommerceOrder',
        displayName: 'recommended Module',
        description: 'This module is used to show shopping cart',
      },
      days: 'Days',
      hours: 'Hrs',
      mins: 'Mins',
      secs: 'Secs',
      expired: 'Expired',
      order: {
        ...Order.En,
      },
      InviteFriend: {
        ...InviteFriend.En,
      },
      Tooltip: {
        ...Tooltip.En,
      },
      Checkout: {
        ...Checkout.En,
      },
      Cart: {
        ...Cart.En,
      },
      Following: {
        ...Following.En,
      },
      RecentViewed: {
        ...RecentViewed.En,
      },
      Wishlist: {
        ...Wishlist.En,
      },
      refund: {
        ...refundTrans.En,
      },
      Loyalty: {
        ...Loyalty.En,
      },
      smartCart: {
        ...smartCart.En,
      },
      askAyk: {
        ...askAyk.En,
      },
      notificationsAndMessages: {
        ...notificationsAndMessages.En,
      },
      cancelService: {
        ...cancelService.En,
      },
    },
    true,
    true,
  );

  i18next.addResourceBundle(
    'ar',
    'ecommerceOrder',
    {
      metadata: {
        name: 'ecommerceOrder',
        displayName: 'recommended Module',
        description: 'This module is used to show shopping cart',
      },
      home: {
        menuTitle: 'shopping cart',
      },
      days: 'يوم',
      hours: 'ساعة',
      mins: 'دقيقة',
      secs: 'ثانية',
      expired: 'انتهى',
      order: {
        ...Order.Ar,
      },
      InviteFriend: {
        ...InviteFriend.Ar,
      },
      Tooltip: {
        ...Tooltip.Ar,
      },
      Checkout: {
        ...Checkout.Ar,
      },
      Cart: {
        ...Cart.Ar,
      },
      Following: {
        ...Following.Ar,
      },
      RecentViewed: {
        ...RecentViewed.Ar,
      },
      Wishlist: {
        ...Wishlist.Ar,
      },
      refund: {
        ...refundTrans.Ar,
      },
      Loyalty: {
        ...Loyalty.Ar,
      },
      smartCart: {
        ...smartCart.Ar,
      },
      askAyk: {
        ...askAyk.Ar,
      },
      notificationsAndMessages: {
        ...notificationsAndMessages.Ar,
      },
      cancelService: {
        ...cancelService.Ar,
      },
    },
    true,
    true,
  );

  i18next.loadNamespaces('ecommerceOrder');
};

export default loadLocales;

/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'react-relay';
import _ from 'lodash';

import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';
import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import PaginationBox from '~/modules/coreUI/components/pagination/PaginationBox';
import PaginationTextInfo from '~/modules/coreUI/components/pagination/PaginationTextInfo';

import NotificationsList from './NotificationsList';
import EmptyNotifications from './EmptyNotifications';

const Notifications = ({ queryResult }) => (queryResult?.notifications.result?.length > 0 ? (
  <Column fullWidth leftAligned className="PaginatedListContainer">
    <Row fullWidth paddingLeft={1.5}><PaginationTextInfo /></Row>
    <NotificationsList notifications={queryResult?.notifications.result} />
    <Row fullWidth centerJustified><PaginationBox /></Row>
  </Column>
) : (
  <EmptyNotifications />
));

const fragment = {
  queryResult: graphql`
    fragment Notifications_queryResult on Query
      @argumentDefinitions(input: { type: "NotificationInput" }) {
        notifications(input: $input) {
          unread_count
          result {
            ref_id
            created_at
            key
            read
            subject
            topic
            redirect_to
          }
          page_info {
            ...PaginationData_pageInfo
          }
          errors {
            field
            messages
            code
          }
        }
    }
  `,
};

const query = graphql`
  query NotificationsQuery($input: NotificationInput) {
    ...Notifications_queryResult @arguments(input: $input)
  }
`;

export default withRefetchQuery(
  Notifications,
  query,
  fragment,
  null,
  () => ({
    input: {
      page: {
        offset: 0,
        limit: 12,
      },
    },
  }),
  null,
  {
    pageInfo: queryResult => _.cloneDeep(queryResult.notifications.page_info),
  },
  true,
  null,
  { force: true },
);

import coreUI from '~/modules/coreUI';
import eCommerceCore from '~/modules/ecommerceCore';
import eCommerceCoreUI from '~/modules/ecommerceCoreUI';
import accountManagement from '~/modules/accountManagement';
import eCommerceShops from '~/modules/ecommerceShops';
import ecommerceHome from '~/modules/ecommerceHome';
import ecommerceProducts from '~/modules/ecommerceProducts';
import ecommerceOrder from '~/modules/ecommerceOrder';

const modules = [
  coreUI,
  eCommerceCore,
  eCommerceCoreUI,
  accountManagement,
  eCommerceShops,
  ecommerceHome,
  ecommerceProducts,
  ecommerceOrder,
];

export default modules;

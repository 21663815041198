/* eslint-disable function-paren-newline, react/no-redundant-should-component-update */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';

import Loader from '~/modules/coreUI/components/basic/Loader';

import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import stringHash from '~/modules/core/utils/jsHelpers/stringHash';

import withRelayEnvironment from './withRelayEnvironment';
import { ServerErrorContext } from '../../../../contextApi/contexts/serverErrorContext';

const memoisedVars = {};

const getMemoisedVars = (variables) => {
  if (!variables) {
    return variables;
  }
  const stringifiedVariables = JSON.stringify(variables);
  const hashKey = stringHash(stringifiedVariables);

  if (!memoisedVars[hashKey]) { // if not memoised before, then memoise it
    memoisedVars[hashKey] = stringifiedVariables;
  } else if (JSON.stringify(memoisedVars[hashKey]) === stringifiedVariables) {
    return memoisedVars[hashKey]; // make sure that it's really the same
  }
  memoisedVars[hashKey] = variables;
  return variables;
};

const RootQuery = (props) => {
  const { dispatch } = useContext(ServerErrorContext);
  const {
    children,
    render = children,
    useDefaultLoading = false,
    variables,
    query,
    cacheConfig,
    environment,
    notifyAlert,
  } = props;
  const [checkError, setCheckError] = useState('');
  const [checkErrorType, setCheckErrorType] = useState('internet connected');
  useEffect(() => {
    if (checkError && checkErrorType === 'Failed to fetch') {
      dispatch({ type: 'SHOW_SERVER_DISCONNECTED' });
    } else if (checkError && checkErrorType === 'internet connected') {
      dispatch({ type: 'SHOW_SERVER_ERROR' });
    }
  }, [checkError]);
  if (process.isStyleguidistActive) {
    return render();
  }
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={getMemoisedVars(variables) || {}}
      cacheConfig={cacheConfig}
      render={({ error, props: queryResult }) => {
        if (error) {
          setCheckError(true);
          notifyAlert({
            messageText: error.message,
            type: AlertTypes.error,
            toastID: 'RootQuery_error_toast',
          });
          if (error.message === 'Failed to fetch') {
            setCheckErrorType('Failed to fetch');
          }
          return render(queryResult);
        }
        if (!queryResult && useDefaultLoading) {
          return <Loader />;
        }
        // loading for user in case he dose not add useDefaultLoading to true
        return render(queryResult);
      }}
    />
  );
};

RootQuery.defaultProps = {
  query: null,
  useDefaultLoading: undefined,
  variables: undefined,
  cacheConfig: undefined,
  children: undefined,
  render: undefined,
};

RootQuery.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  query: PropTypes.func,
  useDefaultLoading: PropTypes.bool,
  variables: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  cacheConfig: PropTypes.shape({}),
  notifyAlert: PropTypes.func.isRequired,
  environment: PropTypes.shape().isRequired,
};

export default withRelayEnvironment(withAlertMsg(RootQuery));

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import ItemsListSpinner from '~/modules/ecommerceCoreUI/components/listViewLayout/ItemsListSpinner';

import SectionTitle from './SectionTitle';
import Tabs from './Tabs';

const DailyDealsWrapper = styled.div`
  background-color: #f9f9f9;
  text-align: center;
`;

const RenderDailyDealsSections = queryResult => (
  <React.Fragment>
    {(queryResult?.hot_deals.result.length > 3
      || queryResult?.discount.result.length > 3
      || queryResult?.offers.result.length > 3) && (
        <React.Fragment>
          <SectionTitle />
          <Tabs
            hotDeals={queryResult?.hot_deals.result}
            discounts={queryResult?.discount.result}
            offers={queryResult?.offers.result}
          />
        </React.Fragment>
    )}
  </React.Fragment>
);
const DailyDeals = ({ queryResult, isLoading }) => (
  <DailyDealsWrapper>
    {isLoading ? (
      <React.Fragment>
        <SectionTitle />
        <ItemsListSpinner vertical oneRow itemsCount={6} />
      </React.Fragment>
    ) : (
      RenderDailyDealsSections(queryResult)
    )}
  </DailyDealsWrapper>
);

DailyDeals.propTypes = {
  queryResult: PropTypes.shape({
    variants_search: PropTypes.shape({
      result: PropTypes.objectOf(PropTypes.any),
    }),
  }),
  isLoading: PropTypes.bool.isRequired,
};

DailyDeals.defaultProps = {
  queryResult: null,
};

export default withRootQuery(
  DailyDeals,
  graphql`
    query DailyDealsQuery {
      hot_deals: variants_search(input: { hot_deal: true }) {
        result {
          ...VariantData_variantInfo @relay(mask: false)
        }
      }
      discount: variants_search(input: { discount: true }) {
        result {
          ...VariantData_variantInfo @relay(mask: false)
        }
      }
      offers: variants_search(input: { offer: true }) {
        result {
          ...VariantData_variantInfo @relay(mask: false)
        }
      }
    }
  `,
);

import React from 'react';
import styled from 'react-emotion';
import i18next from 'i18next';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import EmptyStatePage from '~/modules/coreUI/components/layouts/emptyStatePage';

const EmptyPageWrapper = styled.div`
  text-align: center;
`;

const EmptyPromos = () => (
  <EmptyPageWrapper>
    <Spacer size={1.8} />
    <EmptyStatePage
      imagePath={`/${imagesPathName}/promo.png`}
      contentHeader={i18next.t('accountManagement:MyPromos.empty')}
    />
  </EmptyPageWrapper>
);

export default EmptyPromos;

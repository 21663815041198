import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';

import { Column } from '~/modules/coreUI/components/legacies/Columns';
import { Row } from '~/modules/coreUI/components/legacies/Rows';

import withModalTracker from '~/modules/core/utils/modalHelpers/withModalTracker';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';

import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';

import { setLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';

import changeLanguage from '~/modules/ecommerceCore/utils/changeLanguage';
import changeCurrency from '~/modules/ecommerceCore/utils/changeCurrency';

import { retrieveUserInfoFromStorage } from '~/modules/core/utils/accessManagementHelpers/userInfoUtils';

const StyledIcon = styled.div`
  svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      animation: dash 0.9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      animation: dash 0.9s 0.35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
  }

  p {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25em;
    &.success {
      color: #73af55;
    }
    &.error {
      color: #d06079;
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
`;

class SuccessPanel extends React.Component {
  state = {
    animationStarted: false,
  }

  changeLanguageAndCurrency = () => {
    const userInfo = retrieveUserInfoFromStorage();
    changeCurrency(userInfo.default_currency);
    changeLanguage(
      userInfo.default_lang,
      this.props.history.location,
    );
  }

  componentDidMount = () => {
    const {
      history,
      closeCurrentModal,
      redirectIfPathExists,
      getSocialMediaData,
    } = this.props;

    const pathData = redirectIfPathExists(PathRedirectKeysMapper.showSuccess);

    if (pathData) {
      setTimeout(() => {
        if (pathData.isModal) {
          navigateToModal(history, pathData.name);
        } else if (pathData.shouldRefresh) {
          externalRedirect(pathData.name);
        } else {
          history.push(pathData.name);
        }
      }, 1500);
    } else {
      setTimeout(() => {
        // Remove Saved Session Data
        getSocialMediaData(true);
        setLocalMessageFor(PathRedirectKeysMapper.showSuccess, null);
        closeCurrentModal(history);

        this.changeLanguageAndCurrency();
      }, 1500);
    }

    setTimeout(() => {
      this.setState({ animationStarted: true });
    }, 500);
  };

  render = () => {
    const mode = this.props.mode || 'success';
    return this.state.animationStarted ? (
      <Row centerAligned centerJustified fullWidth fullHeight style={{ position: 'absolute' }}>
        <Column centerAligned centerJustified fullWidth fullHeight style={{ position: 'absolute' }}>
          <StyledIcon>
            {mode === 'success' && (
              <React.Fragment>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
                <p className="success">{i18next.t('accountManagement:Login.welcomeMessage')}</p>
              </React.Fragment>
            )}
            {mode === 'error' && (
              <React.Fragment>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#D06079"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <line
                    className="path line"
                    fill="none"
                    stroke="#D06079"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="34.4"
                    y1="37.9"
                    x2="95.8"
                    y2="92.3"
                  />
                  <line
                    className="path line"
                    fill="none"
                    stroke="#D06079"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="95.8"
                    y1="38"
                    x2="34.4"
                    y2="92.2"
                  />
                </svg>
                <p className="error">{i18next.t('accountManagement:Login.errorMessage')}</p>
              </React.Fragment>
            )}
          </StyledIcon>
        </Column>
      </Row>
    ) : (
      null
    );
  };
}

SuccessPanel.propTypes = PropTypes.shape({
  mode: PropTypes.oneOf(['success', 'error']).isRequired,
}).isRequired;

export default withUserInfo(withRouter(withModalTracker(SuccessPanel)));

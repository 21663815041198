import React from 'react';
import i18next from 'i18next';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Column, CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import { SocialMediaSectionTitle } from '~/modules/accountManagement/components/basic/Labels';
import { XXXXXLargeSpacer, XXLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { mediaQueryMin, mediaQueryMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Loader from '~/modules/coreUI/components/basic/Loader';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import LoginWithSocialMedia from '~/modules/accountManagement/containers/panels/socialMedia/LoginWithSocialMedia';

const socialMedias = [
  {
    key: 'accountManagement:SocialMedia.Facebook',
    name: 'Facebook',
    icon: 'icon-fb',
    background: '#3b5998',
  },
  {
    key: 'accountManagement:SocialMedia.Twitter',
    name: 'Twitter',
    icon: 'icon-twitter',
    background: '#1da1f2',
  },
  {
    key: 'accountManagement:SocialMedia.GooglePlus',
    name: 'Google Plus',
    icon: 'el-icon el-icon-google',
    background: '#4285f4',
  },
];

const SocialMedia = (props) => {
  let isLanding = false;
  const { pathname } = props.location;

  if (pathname.includes('loginWithSocialMedia')) {
    isLanding = true;
  }

  const getStyle = () => {
    const style = {
      display: 'none',
    };

    return isLanding ? style : {};
  };

  return (
    <React.Fragment>
      <Column justifyCenter centerAligned fullWidth style={getStyle()}>
        <Media
          query={mediaQueryMin('desktop')}
          render={() => <Separator separatorLength="xLarge" />}
        />
        <XXLargeSpacer />
        <CenterAlignedColumn>
          <SocialMediaSectionTitle>
            {i18next.t('accountManagement:SocialMedia.CONNECTWITH')}
          </SocialMediaSectionTitle>

          <Spacer size={2} />
          <Row spaceBetween={1}>
            {socialMedias.map(socialMedia => (
              <LoginWithSocialMedia key={socialMedia.name} socialMedia={socialMedia} />
            ))}
          </Row>
          <XXXXXLargeSpacer />
          <Media query={mediaQueryMax('tablet')} render={() => <XXXXXLargeSpacer />} />
        </CenterAlignedColumn>
      </Column>

      {isLanding && (
      <Column fullWidth fullHeight centerJustified>
        <Loader />
      </Column>
      )}
    </React.Fragment>
  );
};

SocialMedia.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(SocialMedia);

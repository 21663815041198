// import { SHOW_SERVER_ERROR } from '../actions/serverErrorAction';

const showPageErrorReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_SERVER_ERROR':
      return {
        ...state,
        errors: true,
      };
    case 'SHOW_SERVER_DISCONNECTED':
      return {
        ...state,
        connected: false,
      };
    default: return state;
  }
};

export default showPageErrorReducer;

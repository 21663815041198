import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import withLuckyWheelInfo from './withLuckyWheelInfo';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.5s ease-in-out;
`;

const DarkBackground = ({ handleToggleLuckyWheel }) => <Wrapper onClick={() => handleToggleLuckyWheel()} />;

DarkBackground.propTypes = {
  handleToggleLuckyWheel: PropTypes.func.isRequired,
};
export default withLuckyWheelInfo(DarkBackground);

const MyPromosTrans = {
  En: {
    expiryDate: 'Expiry Date',
    presentedBy: 'Presented by',
    promoCode: 'Coupon Code',
    copyCode: 'Copy Code',
    copied: 'Copied to clipboard',
    empty: 'You don\'t have coupons yet!',
  },
  Ar: {
    expiryDate: 'تاريخ الانتهاء',
    presentedBy: 'مقدم من',
    promoCode: 'رمز الكوبون',
    copyCode: 'انسخ الرمز',
    copied: 'تم النسخ',
    empty: 'ليس لديك كوبونات حتى الآن!',
  },
};
export default MyPromosTrans;

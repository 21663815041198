import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { translate, Interpolate } from 'react-i18next';

import { BasicButton } from '~/modules/coreUI/components/legacies/Button';

const BasicButtonStyled = styled(BasicButton)`
  display: grid;
  width: fit-content;
  height: 32px;
  padding: 0px ${props => props.theme.paddings.xLarge}px;
  margin-right: -${props => props.theme.paddings.medium}px;
  font-weight: ${props => props.theme.new.fonts.weights.semiBold};
  align-content: center;
  justify-content: flex-end;
`;

const ButtonStyle = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.paddings.large}px;
  padding-top: ${props => props.theme.paddings.large}px;
  justify-content: flex-end;
`;

const SearchResultButton = ({ onClicked, itemsCount }) => (
  <ButtonStyle>
    <BasicButtonStyled block primary inverted onClicked={() => onClicked()}>
      <Interpolate i18nKey="QuickSearch.SeeAllResults" count={itemsCount} />
    </BasicButtonStyled>
  </ButtonStyle>
);

SearchResultButton.propTypes = {
  onClicked: PropTypes.func.isRequired,
  itemsCount: PropTypes.string.isRequired,
}.isRequired;

export default translate('ecommerceCoreUI')(SearchResultButton);

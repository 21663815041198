import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import withOmitedProps from '~/modules/core/utils/cssHelpers/withOmitedProps';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const TextArea = styled(withOmitedProps(TextareaAutosize, ['customStyles']))`
  width: 100%;
  padding: ${props => props.theme.new.spacer}px;

  border-radius: 20px;
  border-color: #cccccc;
  resize: none;

  ::placeholder {
    padding: 0 4px;
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    opacity: 1; /* Firefox */
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 60px white inset;
  }
  &:focus {
    outline: none;
    border-color: #33a8ff;
  }
`;

class TextAreaInput extends Component {
  state={
    inputValue: this.props.inputValue,
  }

  onChange = (event) => {
    this.setState({ inputValue: event.target.value });
    this.props.onChange(event);
  }

  isControled = props => !!props.value;

  inputValue = props => (this.isControled(props) ? ({ value: props.value }) : ({ defaultValue: this.state.inputValue }));

  render() {
    return (
      <Fragment>
        <TextArea
          rows={this.props.rows}
          cols={this.props.columns}
          maxLength={this.props.maxChars}
          onChange={e => this.onChange(e)}
          placeholder={this.props.placeholder}
          className={this.props.customStyles ? this.props.customStyles(this.props) : null}
          {...this.inputValue(this.props)}
        />
        {this.props.showCountLabel ? (
          <Row fullWidth rightJustified>
            <Label xs subtle>{`${this.state.inputValue.length}/${this.props.maxChars}`}</Label>
          </Row>
        ) : null}
      </Fragment>
    );
  }
}

TextAreaInput.defaultProps = {
  customStyles: null,
  inputValue: '',
  placeholder: '',
  value: '',
  rows: '4',
  columns: '50',
  maxChars: '1000',
  showCountLabel: false,

};

TextAreaInput.propTypes = {
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customStyles: PropTypes.func,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxChars: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCountLabel: PropTypes.bool,
};


export default TextAreaInput;

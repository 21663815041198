/**
 * @flow
 * @relayHash ba77c1856d6bd855a10bd347e5fe79c4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SignupFormMutationVariables = {|
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  nationality: string,
  mobile_number: string,
  gender: string,
  newsletter_subscription?: ?boolean,
  referrer_unique_code?: ?string,
  default_lang?: ?string,
  default_currency?: ?string,
|};
export type SignupFormMutationResponse = {|
  +create_user: ?{|
    +token: ?string,
    +client_id: ?string,
    +expiry: ?string,
    +user: ?{|
      +id: string,
      +ref_id: string,
      +first_name: ?string,
      +last_name: ?string,
      +email: ?string,
      +verified: ?boolean,
      +avatar: ?string,
      +default_lang: ?string,
      +default_currency: ?string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type SignupFormMutation = {|
  variables: SignupFormMutationVariables,
  response: SignupFormMutationResponse,
|};
*/


/*
mutation SignupFormMutation(
  $email: String!
  $password: String!
  $first_name: String!
  $last_name: String!
  $nationality: String!
  $mobile_number: String!
  $gender: String!
  $newsletter_subscription: Boolean
  $referrer_unique_code: String
  $default_lang: String
  $default_currency: String
) {
  create_user(email: $email, password: $password, first_name: $first_name, last_name: $last_name, nationality: $nationality, mobile_number: $mobile_number, gender: $gender, newsletter_subscription: $newsletter_subscription, referrer_unique_code: $referrer_unique_code, default_lang: $default_lang, default_currency: $default_currency) {
    token
    client_id
    expiry
    user {
      id
      ref_id
      first_name
      last_name
      email
      verified
      avatar
      default_lang
      default_currency
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first_name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last_name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "nationality",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "mobile_number",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "gender",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "newsletter_subscription",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "referrer_unique_code",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "default_lang",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "default_currency",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "create_user",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "default_currency",
        "variableName": "default_currency",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "default_lang",
        "variableName": "default_lang",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "first_name",
        "variableName": "first_name",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "gender",
        "variableName": "gender",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "last_name",
        "variableName": "last_name",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "mobile_number",
        "variableName": "mobile_number",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "nationality",
        "variableName": "nationality",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "newsletter_subscription",
        "variableName": "newsletter_subscription",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "referrer_unique_code",
        "variableName": "referrer_unique_code",
        "type": "String"
      }
    ],
    "concreteType": "CreateUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "client_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expiry",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ref_id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "first_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "last_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verified",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_lang",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_currency",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SignupFormMutation",
  "id": null,
  "text": "mutation SignupFormMutation(\n  $email: String!\n  $password: String!\n  $first_name: String!\n  $last_name: String!\n  $nationality: String!\n  $mobile_number: String!\n  $gender: String!\n  $newsletter_subscription: Boolean\n  $referrer_unique_code: String\n  $default_lang: String\n  $default_currency: String\n) {\n  create_user(email: $email, password: $password, first_name: $first_name, last_name: $last_name, nationality: $nationality, mobile_number: $mobile_number, gender: $gender, newsletter_subscription: $newsletter_subscription, referrer_unique_code: $referrer_unique_code, default_lang: $default_lang, default_currency: $default_currency) {\n    token\n    client_id\n    expiry\n    user {\n      id\n      ref_id\n      first_name\n      last_name\n      email\n      verified\n      avatar\n      default_lang\n      default_currency\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SignupFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SignupFormMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23cebb163d9a0596be005a24d9f8ec1e';
module.exports = node;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Label } from '~/modules/coreUI/components/legacies/Labels';
import PropTypes from 'prop-types';

import { makeModalFullPath } from '~/modules/core/utils/modalHelpers';

const ModalLink = (props) => {
  const { to, history, children } = props;
  return (
    <Label semiBold>
      <Link to={makeModalFullPath(history.location, to)}>{children}</Link>
    </Label>
  );
};

ModalLink.propTypes = PropTypes.shape({
  to: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}).isRequired;

export default withRouter(ModalLink);

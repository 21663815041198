import React from 'react';
import PropTypes from 'prop-types';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const TabletPanelFooter = props => (
  <Row fullWidth spaceBetweenJustified>
    <Column leftAligned spaceBetween={1}>
      <Row>{props.orderDeliveryDate}</Row>
      <Row>{props.orderTrackingNumber}</Row>
      <Row>{props.orderNote}</Row>
    </Column>
    <Spacer size={0.5} />
    <Row>
      {props.refundOrderButton}
      {props.orderCancelButton}
      {([props.refundOrderButton, props.orderCancelButton, props.orderDetailsButton].filter(x => x).length > 1) ? (
        <Separator vertical separatorLength="full" spacerSize={1.5} />
      ) : null}
      {props.orderDetailsButton}
    </Row>
  </Row>
);

TabletPanelFooter.defaultProps = {
  orderDeliveryDate: null,
  orderTrackingNumber: null,
  refundOrderButton: null,
  orderCancelButton: null,
  orderNote: null,
  orderDetailsButton: null,
};

TabletPanelFooter.propTypes = {
  orderDeliveryDate: PropTypes.element,
  orderTrackingNumber: PropTypes.element,
  refundOrderButton: PropTypes.element,
  orderCancelButton: PropTypes.element,
  orderNote: PropTypes.element,
  orderDetailsButton: PropTypes.element,
};

export default TabletPanelFooter;

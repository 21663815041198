/**
 * @flow
 * @relayHash 15bf6d116ff29bcaedc74a38af704abf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DropdownNotifications_queryResult$ref = any;
export type NotificationInput = {
  page?: ?PageInfoInput
};
export type PageInfoInput = {
  limit?: ?number,
  offset?: ?number,
};
export type DropdownNotificationsQueryVariables = {|
  input?: ?NotificationInput
|};
export type DropdownNotificationsQueryResponse = {|
  +$fragmentRefs: DropdownNotifications_queryResult$ref
|};
export type DropdownNotificationsQuery = {|
  variables: DropdownNotificationsQueryVariables,
  response: DropdownNotificationsQueryResponse,
|};
*/


/*
query DropdownNotificationsQuery(
  $input: NotificationInput
) {
  ...DropdownNotifications_queryResult_2VV6jB
}

fragment DropdownNotifications_queryResult_2VV6jB on Query {
  notifications(input: $input) {
    notifications_count
    unread_count
    result {
      ref_id
      created_at
      key
      read
      subject
      topic
      redirect_to
      id
    }
    page_info {
      ...PaginationData_pageInfo
    }
  }
}

fragment PaginationData_pageInfo on PageInfo {
  current_page
  items_count
  limit
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "NotificationInput",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DropdownNotificationsQuery",
  "id": null,
  "text": "query DropdownNotificationsQuery(\n  $input: NotificationInput\n) {\n  ...DropdownNotifications_queryResult_2VV6jB\n}\n\nfragment DropdownNotifications_queryResult_2VV6jB on Query {\n  notifications(input: $input) {\n    notifications_count\n    unread_count\n    result {\n      ref_id\n      created_at\n      key\n      read\n      subject\n      topic\n      redirect_to\n      id\n    }\n    page_info {\n      ...PaginationData_pageInfo\n    }\n  }\n}\n\nfragment PaginationData_pageInfo on PageInfo {\n  current_page\n  items_count\n  limit\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DropdownNotificationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "DropdownNotifications_queryResult",
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DropdownNotificationsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notifications",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "NotificationInput"
          }
        ],
        "concreteType": "Notifications",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "notifications_count",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unread_count",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "NotificationDetails",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "ref_id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "created_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "key",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "read",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subject",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "topic",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "redirect_to",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "page_info",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "current_page",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "items_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "limit",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4ddeb6e3be093c4fa4f8de5449336e6';
module.exports = node;

import React, { Component } from 'react';
import styled from 'react-emotion';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

import Tracks from '../Tracks';
import MainBackground from './MainBackground';
import TrackHeadingArea from './TrackHeadingArea';
import TrackItem from './TrackItem';

const HomeTracksWrapper = styled.div`
  position: relative;
  width: 80%;
  height: 100%;
  margin: auto;
  max-width: 850px;
  ${cssMediaMax.tablet`
    width: 100%;
  `}
`;

const HomeTracksLayout = styled.div`
  .container {
    position: relative;
  }
`;

const TracksItemsBox = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
  top: -13%;
`;

class HomeTracks extends Component {
  state = {
    activeTrackWelcomeMessage: '',
  }

  showWelcomeMessage = (message) => {
    this.setState({
      activeTrackWelcomeMessage: message,
    });
  }

  hideWelcomeMessage = () => {
    this.setState({
      activeTrackWelcomeMessage: '',
    });
  }

  render = () => {
    const { activeTrackWelcomeMessage } = this.state;
    return (
      <HomeTracksLayout>
        <Container>
          <HomeTracksWrapper>
            <MainBackground />
            <TrackHeadingArea trackWelcomeMessage={activeTrackWelcomeMessage} />
            <TracksItemsBox fullWidth centerAligned>
              {Tracks.map(track => (
                <TrackItem
                  key={track.name + track.icon}
                  id={track.id}
                  url={track.url}
                  trackColor={track.color}
                  trackIcon={track.icon}
                  trackName={track.name}
                  trackImg={track.imagePath}
                  imageAlt={track.alt}
                  trackSvg={track.trackSvgIcon}
                  onMouseEnter={() => this.showWelcomeMessage(i18next.t(track.welcomeMessage))}
                  onMouseLeave={() => this.hideWelcomeMessage()}
                />
              ))}
            </TracksItemsBox>
          </HomeTracksWrapper>
        </Container>
        <Spacer size={[3.5, 3.5, 4.7]} />
      </HomeTracksLayout>
    );
  }
}

export default withRouter(HomeTracks);

import React from 'react';
import i18next from 'i18next';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';

const FormMutation = graphql`
  mutation CreateNewPasswordFormMutation(
    $email: String!
    $code: String!
    $password: String!
    $password_confirmation: String!
  ) {
    forgot_password_update_password(
      email: $email
      code: $code
      password: $password
      password_confirmation: $password_confirmation
    ) {
      errors {
        field
        messages
        code
      }
      message
    }
  }
`;

class CreateNewPasswordForm extends React.Component {
  getValue = () => this.form.getValue();

  submitForm = () => {
    this.form.submitForm();
  };

  render = () => {
    const {
      onFormError, onFormSuccess, onFormLoading, addiontalMutationVariables,
    } = this.props;

    return (
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={onFormError}
        onFormSuccess={onFormSuccess}
        onFormLoading={onFormLoading}
        addiontalMutationVariables={addiontalMutationVariables}
        mutationRoot="forgot_password_update_password"
        options={{
          fields: [
            {
              name: 'password',
              placeholder: i18next.t('accountManagement:CreateNewPassword.PasswordPlaceHolder'),
              displayName: i18next.t('accountManagement:CreateNewPassword.PasswordPlaceHolder'),
              input_type: 'password',
              type: 'RequiredString',
            },
            {
              name: 'password_confirmation',
              placeholder: i18next.t('accountManagement:CreateNewPassword.ConfirmPasswordPlaceHolder'),
              displayName: i18next.t('accountManagement:CreateNewPassword.ConfirmPasswordPlaceHolder'),
              input_type: 'password',
              type: 'RequiredString',
            },
          ],
        }}
        mutation={FormMutation}
      />
    );
  };
}

CreateNewPasswordForm.propTypes = PropTypes.shape({
  onFormError: PropTypes.func.isRequired,
  onFormSuccess: PropTypes.func.isRequired,
  onFormLoading: PropTypes.func.isRequired,
  addiontalMutationVariables: PropTypes.shape({}).isRequired,
}).isRequired;

export default CreateNewPasswordForm;

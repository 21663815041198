import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import SquareImageCard from '~/modules/ecommerceCoreUI/components/basic/SquareImageCard';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const ItemImageContainer = withMedia(styled(SquareImageCard)`
  ${props => (!props.isVertical
    ? css`
    width: ${19.5 * props.theme.new.spacer}px;
    height: ${19.5 * props.theme.new.spacer}px;
  ` : css`
    width: 100%;
    height: ${23.5 * props.theme.new.spacer}px;
    padding-top: 0;
  `)}
  ${props => (!props.isVertical && props.media.xsmall && css`
    width: ${10 * props.theme.new.spacer}px;
    height: ${10 * props.theme.new.spacer}px;
  `)}
  background-size: contain;
`);

const AdWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: flex-start;
`;

const Ad = styled.span`
  text-transform: capitalize;
  padding: ${props => props.theme.paddings.small}px ${props => props.theme.paddings.medium}px;
  margin: ${props => props.theme.paddings.medium}px;
  background: ${props => props.theme.colors.invertedLabels.subtle};
  color: ${props => props.theme.colors.labels.normal};
  font-size: ${props => props.theme.fonts.sizes.small}px;
  border: ${props => props.theme.borders.size.thin}px solid ${props => props.theme.borders.color.dark};
  border-radius: ${props => props.theme.borders.radius.normal}px;
`;

const ItemImage = ({
  imgUrl, // Else isShop
  isAd,
  isVertical,
}) => (
  <React.Fragment>
    <ItemImageContainer imageBackground={imgUrl} alt="" isVertical={isVertical} />
    {isAd && (
      <AdWrapper>
        <Ad>{isAd ? 'ad' : undefined}</Ad>
      </AdWrapper>
    )}
  </React.Fragment>
);

ItemImage.propTypes = {
  imgUrl: PropTypes.string,
  isAd: PropTypes.bool,
  isVertical: PropTypes.bool,
};

ItemImage.defaultProps = {
  isAd: false,
  isVertical: true,
  imgUrl: null,
};

export default ItemImage;

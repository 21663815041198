/**
 * @flow
 * @relayHash cdc159ad09a5af54405b2bae08c3772e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSettingsLoaderQueryVariables = {||};
export type UserSettingsLoaderQueryResponse = {|
  +system_configuration_settings: ?{|
    +current_location_country_code: ?string,
    +currency: ?string,
    +language: ?string,
    +supported_currencies: ?$ReadOnlyArray<?string>,
    +supported_languages: ?$ReadOnlyArray<?string>,
    +current_location_country: ?{|
      +name: ?string,
      +ref_id: string,
      +phone_code: ?string,
    |},
  |}
|};
export type UserSettingsLoaderQuery = {|
  variables: UserSettingsLoaderQueryVariables,
  response: UserSettingsLoaderQueryResponse,
|};
*/


/*
query UserSettingsLoaderQuery {
  system_configuration_settings {
    current_location_country_code
    currency
    language
    supported_currencies
    supported_languages
    current_location_country {
      name
      ref_id
      phone_code
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "current_location_country_code",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "supported_currencies",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "supported_languages",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone_code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserSettingsLoaderQuery",
  "id": null,
  "text": "query UserSettingsLoaderQuery {\n  system_configuration_settings {\n    current_location_country_code\n    currency\n    language\n    supported_currencies\n    supported_languages\n    current_location_country {\n      name\n      ref_id\n      phone_code\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserSettingsLoaderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "system_configuration_settings",
        "storageKey": null,
        "args": null,
        "concreteType": "ConfigurationSettings",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_location_country",
            "storageKey": null,
            "args": null,
            "concreteType": "Country",
            "plural": false,
            "selections": [
              v5,
              v6,
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSettingsLoaderQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "system_configuration_settings",
        "storageKey": null,
        "args": null,
        "concreteType": "ConfigurationSettings",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_location_country",
            "storageKey": null,
            "args": null,
            "concreteType": "Country",
            "plural": false,
            "selections": [
              v5,
              v6,
              v7,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7acc250d5c769db307e0cc91628ee5a';
module.exports = node;

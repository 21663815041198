import React from 'react';
import i18next from 'i18next';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';

export const RecoverPasswordFormMutationRoot = 'forgot_password_send_code';
export const RecoverPasswordFormMutation = graphql`
  mutation RecoverPasswordFormMutation($user_signin: String!) {
    forgot_password_send_code(user_signin: $user_signin) {
      errors {
        field
        messages
        code
      }
      message
      verification_via
    }
  }
`;

class RecoverByPasswordForm extends React.Component {
  getValue = () => this.form.getValue();

  submitForm = () => {
    this.form.submitForm();
  };

  render = () => {
    const { onFormError, onFormSuccess, onFormLoading } = this.props;

    return (
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={onFormError}
        onFormSuccess={onFormSuccess}
        onFormLoading={onFormLoading}
        mutationRoot="forgot_password_send_code"
        options={{
          fields: [
            {
              name: 'user_signin',
              placeholder: i18next.t('accountManagement:Login.EmailMobileNumber'),
              displayName: i18next.t('accountManagement:Login.EmailMobileNumber'),
              input_type: 'textbox',
              type: 'RequiredString',
            },
          ],
        }}
        mutation={RecoverPasswordFormMutation}
      />
    );
  };
}

RecoverByPasswordForm.propTypes = PropTypes.shape({
  onFormError: PropTypes.func.isRequired,
  onFormSuccess: PropTypes.func.isRequired,
  onFormLoading: PropTypes.func.isRequired,
}).isRequired;

export default RecoverByPasswordForm;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Container } from 'semantic-ui-react';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import AdFeaturedShopCard from '~/modules/ecommerceCoreUI/components/advertisement/AdFeaturedShopCard';

import FeaturedShopsSlider from './FeaturedShopsSlider';
import Heading from './Heading';

const FeaturedShopsWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  background-image: url(/${imagesPathName}/featured-bg-left.png),
    url(/${imagesPathName}/featured-bg-right.png);
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, auto;
  ${cssMediaMax.tablet`
    background-size: contain, 30% auto;
    background-image: url(/${imagesPathName}/featured-bg-left-md.png), url(/${imagesPathName}/featured-bg-right.png);
  `}
  ${cssMediaMax.xsmall`
    background-size: contain, 30% 0;
  `}
`;
const FeaturedShops = ({ queryResult, isLoading }) => (
  <FeaturedShopsWrapper>
    {!isLoading && queryResult && queryResult.slides.length !== 0 && (
      <Container>
        <Spacer size={[2.5, 2.5, 3.9]} />
        <Heading />
        <Spacer size={2.4} />
        <FeaturedShopsSlider>
          {queryResult.slides.map(slide => (
            <AdFeaturedShopCard key={slide.id} {...slide} />
          ))}
        </FeaturedShopsSlider>
        <Spacer size={[2.5, 2.5, 3.9]} />
      </Container>
    )}
  </FeaturedShopsWrapper>
);

FeaturedShops.defaultProps = {
  queryResult: null,
};

FeaturedShops.propTypes = {
  queryResult: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
};

FeaturedShops.defaultProps = {
  queryResult: null,
};

export default withRootQuery(
  FeaturedShops,
  graphql`
    query FeaturedShopsQuery {
      slides(featured_shop: true) {
        button_color
        button_text
        button_text_color
        id
        large_photo_object {
          url_original
        }
        ref_id
        title
        title_color
        url
      }
    }
  `,
  FeaturedShops,
);

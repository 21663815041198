import React, { Component } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { Container } from 'semantic-ui-react';

import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import DropdownCustom from '~/modules/coreUI/components/legacies/Dropdownv2';
import { cssMediaMax, cssMediaMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import { SmallLabel } from '~/modules/coreUI/components/legacies/Labels';
import RecommededProduct from './RecommendedProduct';
import RecommededShop from './RecommendedShop';

const RecommendedContainer = styled.div`
  width: 100%;
`;

const DropdownLabel = styled(SmallLabel)`
  color: ${props => props.theme.colors.labels.emphasized};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

const customDropdownStyles = props => css`
  width: 100%;

  ${cssMediaMax.mobile`
    ${cssMediaMin.mobile`
      padding: 0px ${props.theme.paddings.large}px;
    `}
  `} .Dropdown-control {
    height: 42px;
    text-align: center;
    padding-right: ${props.theme.paddings.large}px;
    padding-left: ${props.theme.paddings.large}px;
    justify-content: space-between;
    align-items: center;
    color: ${props.theme.colors.primary};
    border: 1px solid ${props.theme.borders.color.light};
    border-radius: ${props.theme.borders.radius.xxLarge}px;
  }
  .Dropdown-menu {
    border: 1px solid ${props.theme.borders.color.light};
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    transition: opacity 0.1s ease;
  }
  .Dropdown-menu:after {
    box-shadow: -1px -1px 0 0 rgba(34, 36, 38, 0.15);
  }
  .Dropdown-arrow {
    color: ${props.theme.borders.color.dark};
  }
`;

const CardsContainer = styled.div`
  min-height: 400px;
`;

class RecommendedSectionResponsive extends Component {
  state = {
    tracks: [],
    isProduct: true,
    selectedTrack: '',
    currentValue: {},
  };

  componentWillMount() {
    this.generateTracksOptions(this.props.tracks);
  }

  onChange = (selectedValue) => {
    if (selectedValue.value === 'products') {
      this.setState({ isProduct: true });
    } else {
      this.setState({ isProduct: false });
      this.setState({ selectedTrack: selectedValue.value });
      this.setState({ currentValue: selectedValue });
    }
  };

  generateTracksOptions = (options) => {
    const shopsOptions = options.map(option => ({
      label: option.name,
      value: option.track_type,
    }));
    const MIN_NUMBER_OF_PRODUCTS = 3;
    const recommendedProducts = this.props.queryResult.recommended_products;

    if (recommendedProducts.result.length >= MIN_NUMBER_OF_PRODUCTS) {
      const productsOption = [
        {
          label: i18next.t('ecommerceRecommended:RecommendedShopsAndProducts.productsTab'),
          value: 'products',
        },
      ];

      this.setState({
        tracks: [...productsOption, ...shopsOptions],
      });
      this.setState({ currentValue: productsOption[0] });
    } else if (shopsOptions.length > 0) {
      this.setState({
        tracks: [...shopsOptions],
      });
      this.setState({ currentValue: shopsOptions[0] });
      this.setState({ selectedTrack: shopsOptions[0].value });
    }
  };

  render() {
    const {
      currentValue, tracks, isProduct, selectedTrack,
    } = this.state;
    const { queryResult } = this.props;
    const MIN_NUMBER_OF_PRODUCTS = 3;
    return (
      <RecommendedContainer>
        <Container>
          <DropdownLabel>
            {' '}
            <Trans i18nKey="RecommendedShopsAndProducts.dropdownLabel" />
            {' '}
          </DropdownLabel>
          <LargeSpacer />
          <DropdownCustom
            customStyles={customDropdownStyles}
            currentValue={currentValue}
            options={tracks}
            onChange={val => this.onChange(val)}
          />
        </Container>
        <LargeSpacer />
        <CardsContainer>
          {isProduct && queryResult.recommended_products.result.length >= MIN_NUMBER_OF_PRODUCTS ? (
            <RecommededProduct />
          ) : (
            <RecommededShop trackname={selectedTrack} />
          )}
        </CardsContainer>
      </RecommendedContainer>
    );
  }
}

RecommendedSectionResponsive.propTypes = {
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      track_type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  queryResult: PropTypes.shape({
    recommended_products: PropTypes.shape({}),
  }).isRequired,
};

export default withRootQuery(
  RecommendedSectionResponsive,
  graphql`
    query RecommendedSectionResponsiveQuery {
      recommended_products {
        result {
          name
        }
      }
    }
  `,
);

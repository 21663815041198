/**
 * @flow
 * @relayHash a05931a58053e12b7410eb4ff6034555
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TrackContent_taxon$ref = any;
export type ProductsTracksDesktopQueryVariables = {|
  vendorID?: ?string
|};
export type ProductsTracksDesktopQueryResponse = {|
  +taxons: $ReadOnlyArray<?{|
    +id: string,
    +ref_id: string,
    +name: string,
    +$fragmentRefs: TrackContent_taxon$ref,
  |}>
|};
export type ProductsTracksDesktopQuery = {|
  variables: ProductsTracksDesktopQueryVariables,
  response: ProductsTracksDesktopQueryResponse,
|};
*/


/*
query ProductsTracksDesktopQuery(
  $vendorID: ID
) {
  taxons(type: department, vendor_id: $vendorID) {
    id
    ref_id
    name
    ...TrackContent_taxon_2LhdUg
  }
}

fragment TrackContent_taxon_2LhdUg on Taxon {
  ref_id
  name
  logo
  children(vendor_id: $vendorID) {
    name
    id
    ...CategoriesCards_categoriesInfo_2LhdUg
  }
  top_brands(vendor_id: $vendorID) {
    ...BrandsCards_brandsInfo
    id
  }
}

fragment CategoriesCards_categoriesInfo_2LhdUg on Taxon {
  children(vendor_id: $vendorID) {
    ...TrackCard_cardInfo
    id
  }
}

fragment BrandsCards_brandsInfo on Taxon {
  id
  ...TrackCard_cardInfo
}

fragment TrackCard_cardInfo on Taxon {
  id
  name
  logo
  icon
  ref_id
  type
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vendorID",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "vendor_id",
  "variableName": "vendorID",
  "type": "ID"
},
v2 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "department",
    "type": "TaxonKind"
  },
  v1
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logo",
  "args": null,
  "storageKey": null
},
v7 = [
  v1
],
v8 = [
  v3,
  v5,
  v6,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "icon",
    "args": null,
    "storageKey": null
  },
  v4,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProductsTracksDesktopQuery",
  "id": null,
  "text": "query ProductsTracksDesktopQuery(\n  $vendorID: ID\n) {\n  taxons(type: department, vendor_id: $vendorID) {\n    id\n    ref_id\n    name\n    ...TrackContent_taxon_2LhdUg\n  }\n}\n\nfragment TrackContent_taxon_2LhdUg on Taxon {\n  ref_id\n  name\n  logo\n  children(vendor_id: $vendorID) {\n    name\n    id\n    ...CategoriesCards_categoriesInfo_2LhdUg\n  }\n  top_brands(vendor_id: $vendorID) {\n    ...BrandsCards_brandsInfo\n    id\n  }\n}\n\nfragment CategoriesCards_categoriesInfo_2LhdUg on Taxon {\n  children(vendor_id: $vendorID) {\n    ...TrackCard_cardInfo\n    id\n  }\n}\n\nfragment BrandsCards_brandsInfo on Taxon {\n  id\n  ...TrackCard_cardInfo\n}\n\nfragment TrackCard_cardInfo on Taxon {\n  id\n  name\n  logo\n  icon\n  ref_id\n  type\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProductsTracksDesktopQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "taxons",
        "storageKey": null,
        "args": v2,
        "concreteType": "Taxon",
        "plural": true,
        "selections": [
          v3,
          v4,
          v5,
          {
            "kind": "FragmentSpread",
            "name": "TrackContent_taxon",
            "args": [
              {
                "kind": "Variable",
                "name": "vendor_id",
                "variableName": "vendorID",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductsTracksDesktopQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "taxons",
        "storageKey": null,
        "args": v2,
        "concreteType": "Taxon",
        "plural": true,
        "selections": [
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "children",
            "storageKey": null,
            "args": v7,
            "concreteType": "Taxon",
            "plural": true,
            "selections": [
              v5,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "children",
                "storageKey": null,
                "args": v7,
                "concreteType": "Taxon",
                "plural": true,
                "selections": v8
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "top_brands",
            "storageKey": null,
            "args": v7,
            "concreteType": "Taxon",
            "plural": true,
            "selections": v8
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '914955432e355606915061c51902229d';
module.exports = node;

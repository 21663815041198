import React from 'react';
import { withTheme } from 'emotion-theming';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const Actions = (props) => {
  if (_.isArray(props.actions) && props.actions?.length > 0) {
    return props.actions.map(action => (
      <Button
        tight
        semiBold
        borderLess
        secondary
        inverted
        iconName={action.iconName}
        backgroundColor="rgba(0, 0, 0, 0)"
        onClicked={action.onClicked}
        key={action.iconName || action.iconAfterName}
        iconAfterName={action.iconAfterName}
      >
        {action.label}
      </Button>
    ));
  } else if (!props.action || React.isValidElement(props.actions)) { // eslint-disable-line no-else-return
    return props.actions;
  }
  throw new Error('Unknown actions format');
};

Actions.defaultProps = {
  actions: null,
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})),
};

const HeaderContainer = withDirection(styled(Row)`
  background-color: ${props => props.theme.new.colors.backgrounds.panel};
  ${props => (props.direction === 'rtl'
    ? css`
      border-right: ${props.borderLess && `2px solid ${props.theme.new.colors.named.primary}`};
      `
    : css`
      border-left: ${props.borderLess && `2px solid ${props.theme.new.colors.named.primary}`};
  `)}
  border: ${props => !props.borderLess && `3px solid ${props.theme.new.colors.labels.borders.inverted.important}`};
  border-radius: ${props => !props.borderLess && 0.5 * props.theme.new.spacer}px;
`);

export default withTheme(props => (
  <HeaderContainer fullWidth p={1.25} spaceBetweenJustified centerAligned borderLess={props.borderLess}>
    <Label lg important>
      {props.title}
    </Label>
    <Actions actions={props.actions} />
  </HeaderContainer>
));

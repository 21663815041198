/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';

import FirebaseContext from './FirebaseContext';

export default (WrappedComponent, path) => {
  class Notifier extends PureComponent {
    state = { notifications: null }

    componentDidMount = () => {
      this.context.firebase
        .database()
        .ref(path)
        .on('value', (snapshot) => {
          this.setState({ notifications: snapshot.val() });
        });
    }

    render = () => <WrappedComponent notifications={this.state.notifications} {...this.props} />;
  }

  Notifier.contextType = FirebaseContext;

  return Notifier;
};

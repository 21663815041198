import React, { Component } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import DeleteSmartCartPopup from './DeleteSmartCartPopup';
import Icon from '~/modules/coreUI/components/basic/Icon';

class DeleteSmartCart extends Component {
  state = {
    showDeleteMesssage: false,
    deletedSmartCart: null,
  }

  showDeleteModal = (event, smartCart) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      showDeleteMesssage: true,
      deletedSmartCart: smartCart,
    });
  }

  closeModal = () => {
    this.setState({ showDeleteMesssage: false });
  }

  render() {
    const { showDeleteMesssage, deletedSmartCart } = this.state;
    const { smartCart } = this.props;
    return (
      <div>
        <Icon lg emphasized title={i18next.t('ecommerceOrder:smartCart.DeleteSmartCartTooltip')} onClick={event => this.showDeleteModal(event, smartCart)} className="el-icon el-icon-delete" />
        {showDeleteMesssage && (
          <DeleteSmartCartPopup
            onCloseModal={this.closeModal}
            smartCart={deletedSmartCart}
          />
        )}
      </div>
    );
  }
}

DeleteSmartCart.propTypes = {
  smartCart: PropTypes.shape({}),
};

DeleteSmartCart.defaultProps = {
  smartCart: {},
};

export default DeleteSmartCart;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';

import BasicLink from '~/modules/ecommerceCoreUI/components/basic/Link';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const FooterLinksItems = styled.ul`
  width: 100%;
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  list-style: none;
  ${cssMediaMax.mobile`
    flex-direction: column;
  `} span:last-child > span {
    display: none;
  }
`;

const DotSpacer = styled.span`
  position: relative;
  margin: 0px ${props => props.theme.new.spacer * 1.5}px;
  font-size: ${props => props.theme.fonts.sizes.large + 2}px;
  top: -3px;
  ${cssMediaMax.mobile`
    display: none;
  `};
`;

const ItemLink = styled.span`
  display: flex;
`;

const InnerFooterLinks = ({ links }) => (
  <FooterLinksItems>
    {links.map(link => (
      <ItemLink key={link.key}>
        <BasicLink targeturl={link.url} key={link.key} primary>
          <span>{i18next.t(link.key)}</span>
          <Spacer size={1.2} />
        </BasicLink>
        <DotSpacer> . </DotSpacer>
      </ItemLink>
    ))}
  </FooterLinksItems>
);

export default InnerFooterLinks;

InnerFooterLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

import { graphql } from 'react-relay';

import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';

const mutation = graphql`
  mutation DeleteAddressMutation($address_id: Int!) {
    remove_user_address(address_id: $address_id) {
      errors {
        field
        messages
        code
      }
      user {
        billing_addresses_previous{
          ...WithAddressData_addressInfo @relay(mask: false)
        }
        shipping_addresses_previous{
          ...WithAddressData_addressInfo @relay(mask: false)
        }
        cart{
          ...WithCartData_cart @relay(mask: false)
        }
      }
    }
  }
`;

export default (environment, addressID, onLoading) => commitMutation(
  environment,
  mutation,
  'remove_user_address',
  {
    address_id: addressID,
  },
  () => { /* NO-OP */ },
  (errors) => {
    if (errors) {
      throw new Error(errors);
    }
  },
  onLoading,
);

import React from 'react';
import _ from 'lodash';
import i18next from 'i18next';

import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import {
  PathRedirectKeysMapper,
} from '~/modules/core/utils/relayHelpers/RelayProvider';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';


const handleClick = (e, history, saveCurrentPath) => {
  e.preventDefault();
  saveCurrentPath(PathRedirectKeysMapper.showSuccess, false, '/cart', true);
  navigateToModal(history, '/accountManagement/VerifyAccountEmailOrSms');
};

const ERRORS_ACTIONS = {
  403: (message, history, saveCurrentPath) => (
    /* eslint-disable jsx-a11y/anchor-is-valid */
    <React.Fragment>
      {i18next.t('ecommerceOrder:Cart.verifyLabel')}
      <a href="" onClick={e => handleClick(e, history, saveCurrentPath)}>
        {i18next.t('ecommerceOrder:Cart.verifyButton')}
      </a>
    </React.Fragment>
    /* eslint-enable */
  ),
};


const processErrors = (errors, history, additionalFunction = null) => {
  const processedErrors = [];

  Object.keys(errors).forEach((key) => {
    if (key === 'generalErrors') {
      errors.generalErrors.forEach((error) => {
        const errorCode = error.code;
        const errorTransformer = ERRORS_ACTIONS[errorCode];

        if (errorTransformer) {
          const oldMessage = error.messages[0] || error.message;
          const newMessage = errorTransformer(oldMessage, history, additionalFunction);

          processedErrors.push({
            ...error,
            messages: [newMessage],
          });
        }
      });
    } else {
      let error = errors[key];
      if (_.isArray(error)) {
        error = {
          field: key,
          messages: error,
        };
      }
      processedErrors.push({
        ...error,
      });
    }
  });

  return processedErrors;
};

export const getFirstError = (errors) => {
  let errorsArray = errors;
  if (_.isObject(errors)) {
    errorsArray = _.values(errors);
  }

  if (errorsArray[0]) {
    return _.isArray(errorsArray[0]) ? errorsArray[0][0] : JSON.stringify(errorsArray[0]);
  }
  return null;
};

export const notifyUnexpectedError = (props, errors) => {
  props.notifyAlert({
    component: () => (
      <Label inverted important semiBold>
        <i className="el-icon el-icon-error" />
        &nbsp;
        {getFirstError(errors)}
      </Label>
    ),
    type: AlertTypes.error,
    opacity: 1,
  });
};

export default processErrors;

/* eslint-disable react/prop-types, camelcase */
import React from 'react';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';

import FilledIcon from './FilledIcon';
import LineIcon from './LineIcon';

const NotificationIcon = props => (props.authenticated ? (
  <FilledIcon showNotificationsMenu={props.showNotificationsMenu} />
) : (
  <LineIcon showNotificationsMenu={props.showNotificationsMenu} />
));

export default withUserInfo(NotificationIcon);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';

const OriginalPriceLabel = styled(Label)`
  text-decoration: line-through;
`;

const OriginalPrice = ({ itemInfo }) => (
  <OriginalPriceLabel size={['xs', 'md']} semiBold subtle>
    {`${itemInfo.original_price} ${currentCurrencyDisplayName()}`}
  </OriginalPriceLabel>
);

OriginalPrice.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default OriginalPrice;

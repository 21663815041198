import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const SeparatorItem = styled.div`
  border-bottom: 2px dashed ${props => props.color};
  width: 93%;
`;
const CardSeparator = ({ color }) => <SeparatorItem color={color} />;

CardSeparator.propTypes = {
  color: PropTypes.string.isRequired,
};

export default CardSeparator;

const notificationsAndMessagesTrans = {
  En: {
    messagesTabLabel: 'Messages',
    notificationsTabLabel: 'Notifications',
    emptyMessagesTitle: 'No Messages Yet!',
    emptyMessagesSubTitle: 'To Create a New Message',
    aykMallName: 'Ayk Mall',
    sendMessageButton: 'Send',
    messageDetailsTitle: 'Message Details ',
    backToMessageListPage: 'Back to Messages List',
    messageInputPlaceholder: 'Write a comment here',
    refNoLabel: 'Ref No.',
    viewAllLabel: 'View All',
    emptyNotifications: 'No Notifications Yet!',
  },
  Ar: {
    messagesTabLabel: 'الرسائل',
    notificationsTabLabel: 'إخطارات',
    emptyMessagesTitle: 'لا توجد رسائل حتى الآن!',
    emptyMessagesSubTitle: 'لإنشاء رسالة جديدة',
    aykMallName: 'أيك مول',
    sendMessageButton: 'أرسل',
    messageDetailsTitle: 'تفاصيل الرسالة ',
    backToMessageListPage: 'العودة إلى قائمة الرسائل',
    messageInputPlaceholder: 'اكتب تعليقًا هنا',
    refNoLabel: 'الرقم',
    viewAllLabel: 'اظهر الكل',
    emptyNotifications: 'لا يوجد اشعارات!',
  },
};
export default notificationsAndMessagesTrans;

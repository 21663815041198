/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const SizedIcon = withMedia(styled.i`
  font-size: ${props => props.size || props.theme.fonts.sizes.xLarge}px;
  ${props => props.customIconStyle};
`);

const PrimaryStyleIconButton = styled(SizedIcon)`
  color: ${props => props.theme.colors.primary};
  &:hover {
    color: ${props => props.theme.colors.secondary};
  }

  &:active {
    color: ${props => props.theme.colors.secondaryDark};
  }

  cursor: pointer;
`;

const SecondaryStyleIconButton = styled(SizedIcon)`
  color: ${props => props.theme.colors.secondary};
  &:hover {
    color: ${props => props.theme.colors.secondaryHover};
  }

  &:active {
    color: ${props => props.theme.colors.secondaryClicked};
  }
  cursor: pointer;
`;

export const IconOnlyButton = (props) => {
  const ButtonElement = props.primary ? PrimaryStyleIconButton : SecondaryStyleIconButton;

  return (
    <ButtonElement
      customIconStyle={props.customIconStyle}
      className={props.iconName}
      title={props.title}
      data-disable={!props.tooltipContent}
      {...props}
    />
  );
};

IconOnlyButton.propTypes = PropTypes.shape({
  iconName: PropTypes.string.isRequired,
  size: PropTypes.number,
}).isRequired;

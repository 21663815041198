import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import Button from '~/modules/coreUI/components/basic/Button';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Icon from '~/modules/coreUI/components/basic/Icon';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';

class SiteTopAlert extends React.Component {
  state = {
    closed: false,
  }

  shouldShowAlert = () => !(
    this.state.closed
    || this.props.userInfo.verified
    || !this.props.authenticated
  );

  render = () => (!this.shouldShowAlert() ? (
    null
  ) : (
    <Row fullWidth backgroundColor="darkBackground">
      <Row centerAligned centerJustified grow fullWidth p={1} spaceBetween={1}>
        <Icon normal xxl className="far fa-check-circle" />
        <Label sm semiBold>
          {i18next.t('aykLayout:verifyAccount.verifyAccount')}
        </Label>
        <Button
          sm
          secondary
          onClicked={() => navigateToModal(this.props.history, '/accountManagement/VerifyAccountEmailOrSms')}
        >
          {i18next.t('aykLayout:verifyAccount.verifyButton')}
        </Button>
      </Row>
      <Icon normal xl className="el-icon el-icon-cancel" onClick={() => this.setState({ closed: true })} />
      <Spacer size={2} />
    </Row>
  ));
}

SiteTopAlert.defaultProps = {
  userInfo: {
    verified: false,
  },
};

SiteTopAlert.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    verified: PropTypes.bool,
  }),
  history: PropTypes.shape({}).isRequired,
};

export default withUserInfo(withRouter(SiteTopAlert));

/* eslint-disable import/prefer-default-export */
import styled from 'react-emotion';
import { css } from 'emotion';
import {
  XXLargeLabel,
  XXXLargeLabel,
  XLargeLabel,
  LargeLabel,
  MediumLabel,
  SmallLabel,
  XSmallLabel,
  Label,
} from '~/modules/coreUI/components/legacies/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

// --------------- COMMON ---------------- //
export const SectionHeader = styled(XXLargeLabel)`
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: capitalize;
`;

export const SectionHeaderSmall = styled(XLargeLabel)`
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const PriceLabel = styled(SectionHeader)`
  font-size: ${props => (props.dropdown ? props.theme.fonts.sizes.xSmall : props.theme.fonts.sizes.large)}px;
  text-align: center;
`;

export const SectionHeaderSubtitle = styled(LargeLabel)`
  color: ${props => props.theme.colors.labels.subtle};
  text-align: center;
`;

export const SectionHeaderSubtitleSmall = styled(MediumLabel)`
  color: ${props => props.theme.colors.labels.subtle};
  text-align: center;
`;

export const TabLabel = styled(MediumLabel)`
  color: ${props => props.theme.colors.labels.normal};
`;

export const MinorDetailsLabel = styled(XSmallLabel)`
  color: ${props => props.theme.colors.labels.subtle};
`;

export const EmphasizedMinorDetailsLabel = styled(MinorDetailsLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const ImportantMinorDetailsLabel = styled(XSmallLabel)`
  color: ${props => props.theme.colors.labels.important};
`;

export const EmphasizedImportantMinorDetailsLabel = styled(ImportantMinorDetailsLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const SmallTitle = styled(SmallLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: uppercase;
  letter-spacing: -0.5px;
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.large}px;
  `};
`;

export const DepartmentTitle = styled(LargeLabel)`
  display: flex;
  color: ${props => props.theme.colors.secondary};
  font-family: 'Pacifico', sans-serif;
`;
export const BlackDepartmentTitle = styled(LargeLabel)`
  display: flex;
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: uppercase;
`;
export const BigTitle = styled(XXXLargeLabel)`
  display: flex;
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;
export const SmallParagraph = styled(SmallLabel)`
  color: ${props => props.theme.colors.labels.normal};
`;

// --------------- PRODUCT DETAILS ---------------- //

/* Title */
export const ProductDetailsTitle = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xLarge}px;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  line-height: normal;
  letter-spacing: 0.6px;
  color: ${props => props.theme.colors.labels.important};

  ${props => props.media.xsmall
    && css`
      font-size: ${props.theme.fonts.sizes.large}px;
    `};
`;
/* RootVariants */
export const OptionType = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.large}px;
  color: black;
  font-weight: ${props => props.theme.fonts.weights.semiBold};

  line-height: 1.11;

  ${props => props.media.xsmall
    && css`
      font-size: ${props.theme.fonts.sizes.medium}px;
    `};
`;

/* Price */
export const StartFrom = styled(Label)`
  opacity: 0.3;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  font-size: ${props => props.theme.fonts.sizes.xSmall}px;
`;

export const PriceNumber = styled(Label)`
  display: inline-block;

  font-size: ${props => props.theme.fonts.sizes.xxLarge}px;
  font-weight: ${props => props.theme.new.fonts.weights.semiBold};
  color: ${props => props.theme.colors.primary};

  ${props => props.media.xsmall
    && css`
      font-size: ${props.theme.fonts.sizes.xLarge}px;
    `} ${props => props.mobileCard
    && css`
      font-size: ${props.theme.new.fonts.sizes.lg}px;
    `};
`;

export const Currency = styled(Label)`
  display: inline-block;

  font-size: ${props => props.theme.fonts.sizes.large}px;
  font-weight: ${props => props.theme.new.fonts.weights.regular};
  color: ${props => props.theme.colors.primary};

  ${props => props.mobileCard
    && css`
      font-size: ${props.theme.new.fonts.sizes.sm}px;
    `};
`;

export const OldPrice = styled(Label)`
  color: ${props => props.theme.colors.labels.hint};
  font-size: ${props => props.theme.fonts.sizes.medium}px;
  text-decoration: line-through;

  ${props => props.mobileCard
    && css`
      font-size: ${props.theme.new.fonts.sizes.xs}px;
    `};
`;
/* Common */
export const Status = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.small}px;
  color: ${props => props.color || props.theme.colors.secondary};
`;

/* Dropdown */

export const EmptyCartTitle = styled(Label)`
  text-align: center;
  color: ${props => props.theme.colors.labels.important};
  font-size: ${props => props.theme.fonts.sizes.large}px;
`;

export const EmptyCartBody = styled(Label)`
  text-align: center;
  color: ${props => props.theme.colors.labels.normal};
  font-size: ${props => props.theme.fonts.sizes.medium}px;
  font-weight: normal;
`;

export const DropdownItemTitle = styled(Label)`
  color: ${props => props.theme.colors.labels.important};
  font-size: ${props => props.theme.fonts.sizes.small}px;
`;

export const DropdownItemShopName = styled(Label)`
  color: ${props => props.theme.colors.labels.subtle};
  font-size: ${props => props.theme.fonts.sizes.xSmall}px;
`;

// --------------- HEADER ---------------- //

// --------------- HOME PAGE ------------ //

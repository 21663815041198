import React, { Component } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import DropdownCustom from '~/modules/coreUI/components/legacies/Dropdownv2';
import { scrollHtmltoTop } from '~/modules/core/utils/jsHelpers/ScrollToTop';
import TrackContent from './TrackContent';

const TracksContainer = styled.div`
  margin: 0 15px 50px;
`;

// TODO : i will refactor this code after refactoring dropdown component
const customDropdownStyles = props => css`
  width: 100%;

  .Dropdown-control {
    height: 42px;
    text-align: center;
    padding-right: ${props.theme.paddings.large}px;
    padding-left: ${props.theme.paddings.large}px;
    justify-content: space-between;
    align-items: center;
    color: ${props.theme.colors.primary};
    font-weight: 600;
    border: 1px solid ${props.theme.borders.color.light};
    border-radius: 50vh;
  }
  .Dropdown-menu {
    border: 1px solid ${props.theme.borders.color.light};
    border-radius: ${props.theme.borders.radius.normal}px;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    transition: opacity 0.1s ease;
  }
  .Dropdown-menu:after {
    box-shadow: -1px -1px 0 0 rgba(34, 36, 38, 0.15);
  }
  .Dropdown-arrow {
    color: ${props.theme.borders.color.dark};
  }
`;

const CardsContainer = styled.div`
  min-height: 400px;
`;

class ProductTracksResponsive extends Component {
  state = {
    tracks: [],
    currentValue: {},
    activeTrack: {},
  };

  componentWillMount = () => {
    this.generateDepartmentsOptions(this.props.taxons);
  };

  componentDidMount = () => {
    this.onChange(this.state.currentValue);
  };

  onChange = (selectedValue) => {
    scrollHtmltoTop();

    const currentTrack = this.props.taxons.find(track => track.ref_id === selectedValue.value);

    this.setState({ currentValue: selectedValue });
    this.setState({ activeTrack: currentTrack });

    this.props.selectDepartment(currentTrack);
  };

  generateDepartmentsOptions = (options) => {
    const departments = options.map(option => ({
      label: option.name,
      value: option.ref_id,
    }));

    this.setState({
      tracks: [...departments],
    });

    const selectedDepartment = this.props.getSelectedDepartment();
    this.setState({ currentValue: departments[selectedDepartment] });
  };

  render() {
    const { currentValue, tracks } = this.state;

    return (
      <TracksContainer>
        <DropdownCustom
          customStyles={customDropdownStyles}
          currentValue={currentValue}
          options={tracks}
          onChange={val => this.onChange(val)}
        />
        <LargeSpacer />
        <CardsContainer>
          <TrackContent
            taxon={this.state.activeTrack}
            vendorID={this.props.vendorID}
            vendorName={this.props.vendorName}
          />
        </CardsContainer>
      </TracksContainer>
    );
  }
}

ProductTracksResponsive.defaultProps = {
  vendorID: null,
  vendorName: null,
};

ProductTracksResponsive.propTypes = {
  taxons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      ref_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logo: PropTypes.string,
    }),
  ).isRequired,
  getSelectedDepartment: PropTypes.func.isRequired,
  selectDepartment: PropTypes.func.isRequired,
  vendorID: PropTypes.string,
  vendorName: PropTypes.string,
};

export default ProductTracksResponsive;

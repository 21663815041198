import React from 'react';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import styled from 'react-emotion';

const TotalLabel = styled(Label)`
  line-height: inherit;
`;
const GrandTotalLabel = props => (
  <TotalLabel semiBold nowrap important size={['lg', 'lg', 'xl']} {...props}>
    {
      props.children // eslint-disable-line react/prop-types
    }
  </TotalLabel>
);
export default GrandTotalLabel;

/* eslint-disable no-unused-vars, no-eval, camelcase */
import parameterize from 'parameterize';

const helpers = {
  accountSettings: (tab, tabIndex) => {
    if (tabIndex) {
      return `/account/${tab}/${tabIndex}`;
    }
    return `/account/${tab}`;
  },
  getHome: () => '/',
  getCategoryLink: (categoryId, categoryName) => {
    const parameterizeName = parameterize(categoryName);
    return `/${categoryId}-${parameterizeName}/c-p/`;
  },

  getDepartmentLink: (departmentId, departmentName) => {
    const parameterizeName = parameterize(departmentName);
    return `/${departmentId}-${parameterizeName}/product/pt/`;
  },

  getBrandLink: (brandId, brandName) => {
    const parameterizeName = parameterize(brandName);
    return `/${brandId}-${parameterizeName}/b-p/`;
  },

  getShopTaxonLink: (shopId, shopName, taxonInfo) => {
    const parameterizeShopName = parameterize(shopName);
    const parameterizeTaxonName = parameterize(taxonInfo.name);

    const taxonType = taxonInfo.type === 'category' ? 'c' : 'b';

    return `/${shopId}-${parameterizeShopName}/${taxonInfo.ref_id}-${parameterizeTaxonName}/s-${taxonType}-p/`;
  },

  getProductTracksLink: (department = null, shopId = null, shopName = null, subdepartmentName = null, subdepartmentId = null) => {
    if (shopId !== null) {
      const parameterizeShopName = parameterize(shopName);

      if (department === null) {
        return `/${shopId}-${parameterizeShopName}/s-pt/`;
      }

      const parameterizeDepartmentName = parameterize(department.name);
      const url = `/${shopId}-${parameterizeShopName}/${department.ref_id}-${parameterizeDepartmentName}`;
      if (subdepartmentId === null) {
        return `${url}/s-d-pt/`;
      }

      const parameterizeSubDepartmentName = parameterize(subdepartmentName);
      return `${url}/${subdepartmentId}-${parameterizeSubDepartmentName}/s-sd-pt/`;
    }

    if (department === null) {
      return '/products';
    }

    const parameterizeName = parameterize(department.name);
    const link = `/${department.ref_id}-${parameterizeName}/product/pt/`;

    if (subdepartmentName === null) {
      return link;
    }

    return `${link}#${parameterize(subdepartmentName)}`;
  },

  getShopTracksLink: (track = 'shops', department = null) => {
    if (department === null) {
      return `/${track}`;
    }

    const parameterizeName = parameterize(department.name);
    return `/${department.ref_id}-${parameterizeName}/${track}/st/`;
  },

  // This is used for category and brand (of department) links
  getTaxonLink: (taxonInfo, departmentId = '', departmentName = '', vendorId = null, vendorName = null) => {
    if (vendorId !== null) {
      return helpers.getShopTaxonLink(vendorId, vendorName, taxonInfo);
    }

    if (taxonInfo.type === 'category') {
      return `/${taxonInfo.ref_id}-${parameterize(taxonInfo.name)}/c-p/`;
    }

    // brand url
    return `/${departmentId}-${parameterize(departmentName)}/${taxonInfo.ref_id}-${parameterize(taxonInfo.name)}/d-b-p/`;
  },

  getQuickSearchLink: val => (val ? `/${val}/t-p/` : '/'),

  getJoinBusinessLink: val => '/join/',

  getOrderDetailsLink: number => `/${number}/od`,

  getProductDetailsLink: (slug, vendorVariantsCount = 0, vendor = {}, forceVendorVariant = false) => {
    if (!forceVendorVariant) {
      if (!vendor?.ref_id || vendorVariantsCount > 1) {
        return `/${slug}/p/`;
      }
    }

    const parameterizeName = parameterize(vendor.name);
    return `/${vendor.ref_id}-${parameterizeName}/${slug}/sp/`;
  },

  getShopDetailsLink: val => `/${val}/s/`,

  getQuickSearchShopsLink: (track, searchString) => (track && searchString ? `/${track}/${searchString}/t-s/` : '/'),

  getStaticPageLink: page => `/${page}/stp/`,
  getFaqDetails: faqCategory => `/${faqCategory}/csrv/`,
  getFaqSearchDetails: (faqCategory, searchString) => `/${faqCategory}/${searchString}/csrv/`,

  getCartLink: () => '/cart',
  getCheckoutLink: () => '/CheckoutSteps',
  getRefundOrderLink: orderNumber => `/${orderNumber}/ro`,
  getRefundItemLink: (orderNumber, itemID) => `/${orderNumber}/${itemID}/ri`,
  getCancelOrderLink: orderNumber => `/${orderNumber}/rso`,
  getCancelServiceLink: (orderNumber, itemID) => `/${orderNumber}/${itemID}/rsi`,
  getSmartCartDetailsLink: smartCartId => `/${smartCartId}/sc/`,
  getSmartCartListLink: () => '/account/smartcart/',
  getNotificationAndMessagesLink: tabIndex => `/account/notificationsAndMessages/${tabIndex}`,
  getRefundDetailsLink: refundNumber => `/${refundNumber}/rd`,
  getAskShopLink: (vendorName, vendorId) => `/${vendorName}/${vendorId}/cscm`,
  getAskAykLink: () => '/cscm',
  getAskingSuccessedLink: ticketNumber => `/${ticketNumber}/cscms`,
  getMessageDetailsLink: messageId => `/${messageId}/md`,
};

export default function urlFor(routeName, params = []) {
  const path = window.location.pathname;

  let locale = '';
  const localeMatch = path.match(/^(\/(ar|en))(\/.*)?$/);
  if (localeMatch !== null) {
    [, locale] = localeMatch;
  }

  if (params === undefined) {
    return `${locale}${helpers[routeName]()}`;
  }
  return `${locale}${helpers[routeName](...params)}`;
}

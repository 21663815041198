import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const CrownItem = styled.img`
  display: inline-block;
  width: ${props => (props.size === 'small' ? 5 * props.theme.new.spacer : 7.5 * props.theme.new.spacer)}px;
  ${props => cssMediaMax.xsmall`
    width: ${4.4 * props.theme.new.spacer}px;
  `}
`;

const Crown = ({ crownLink, size }) => <CrownItem src={crownLink} alt="crown" size={size} />;

Crown.propTypes = {
  crownLink: PropTypes.string.isRequired,
  size: PropTypes.string,
};
Crown.defaultProps = {
  size: null,
};

export default Crown;

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'react-emotion';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';

import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';

import AccountItems from '../headerActions/AccountItems';

const navigateToItem = (props, item) => {
  if (item.guestAccessible || props.authenticated) {
    externalRedirect(item.url);
  } else {
    props.saveCurrentPath(
      PathRedirectKeysMapper.showSuccess,
      false,
      item.url,
      true,
    );
    navigateToModal(props.history, '/accountManagement/login');
  }
};

const EntryContainer = styled(CenterAlignedRow)`
  cursor: pointer;
`;

const DropDownElementAccount = withRouter(withUserInfo(props => (
  <ul>
    {AccountItems.map(
      item => (item.guestView || props.authenticated) && (
      <li key={item.name || i18next.t(item.title)} onClick={() => navigateToItem(props, item)}>
        <EntryContainer>
          <i className={item.icon} />
          <Spacer size={0.8} />
          {item.name || i18next.t(item.title)}
        </EntryContainer>
      </li>
      ),
    )}
  </ul>
)));

DropDownElementAccount.propTypes = PropTypes.shape({
  authenticated: PropTypes.boolen,
}).isRequired;

export default withUserInfo(DropDownElementAccount);

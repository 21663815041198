import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const RibbonWrapper = withDirection(styled.div`
  width: ${props => (props.size === 'small' ? 50 : 80)}px;
  position: relative;
  top: -1px;
  ${props => props.customStyle};
  ${props => (props.direction === 'rtl'
    ? css`
        right: -1px;
      `
    : css`
        left: -1px;
  `)}
  ${cssMediaMax.xsmall`
    width: 50px;
  `}
`);
const Img = styled.img`
  width: 100%;
`;
const Ribbon = ({
  degree,
  direction,
  size,
  customeStyle,
}) => (
  <RibbonWrapper customStyle={customeStyle} size={size}>
    {degree === 'silver' && direction === 'rtl' && (
      <Img src={`/${imagesPathName}/ribbon-silver-ar.png`} alt="ribbon" />
    )}
    {degree === 'silver' && direction !== 'rtl' && (
      <Img src={`/${imagesPathName}/ribbon-silver.png`} alt="ribbon" />
    )}
    {degree === 'gold' && direction === 'rtl' && (
      <Img src={`/${imagesPathName}/ribbon-gold-ar.png`} alt="ribbon" />
    )}
    {degree === 'gold' && direction !== 'rtl' && (
      <Img src={`/${imagesPathName}/ribbon-gold.png`} alt="ribbon" />
    )}
    {degree === 'diamond' && direction === 'rtl' && (
      <Img src={`/${imagesPathName}/ribbon-diamond-ar.png`} alt="ribbon" />
    )}
    {degree === 'diamond' && direction !== 'rtl' && (
      <Img src={`/${imagesPathName}/ribbon-diamond.png`} alt="ribbon" />
    )}
  </RibbonWrapper>
);

Ribbon.propTypes = {
  direction: PropTypes.string.isRequired,
  degree: PropTypes.string.isRequired,
  size: PropTypes.string,
  customeStyle: PropTypes.string,
};
Ribbon.defaultProps = {
  size: null,
  customeStyle: null,
};
export default withDirection(Ribbon);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';

import Panel from '~/modules/accountManagement/components/basic/Panel';
import Modal from '~/modules/core/components/Modal/index';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withModalTracker from '~/modules/core/utils/modalHelpers/withModalTracker';


export const ModalContent = withMedia(styled(Column)`
  position: unset;

  height: 100%;
  width: 100%;

  ${props => props.media.minDesktop && css`&& {
      width: ${48 * props.theme.new.spacer}px;
      min-height: ${20 * props.theme.new.spacer}px;
      max-height: ${30 * props.theme.new.spacer}px;
      position: relative;
    }
  `};

  ${props => props.media.maxTablet && css`&& {
       min-height: 100vh;
    }
  `};

  padding: ${props => 2.5 * props.theme.new.spacer}px;
  align-items: stretch;
  justify-content: space-between;

  background-color: ${props => props.theme.colors.named.white};

  border: 1px solid;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  border-color: white;
`);
class ModalWrapper extends Component {
  componentWillUnmount = () => {
    this.props.closeCurrentModal();
  }

  onCloseModal = () => {
    this.props.onCloseModal();
  }

  render() {
    return (
      <Modal
        onRef={(ref) => {
          this.modal = ref;
        }}
        onCloseRequested={() => this.onCloseModal()}
      >
        <ModalContent>
          <Panel
            panelWidth="100%"
            titleLabel={this.props.titleLabel}
            showHeader={false}
          >
            {this.props.children}
          </Panel>
        </ModalContent>
      </Modal>
    );
  }
}

ModalWrapper.defaultProps = {
  titleLabel: '',
};

ModalWrapper.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  titleLabel: PropTypes.string,
  closeCurrentModal: PropTypes.func.isRequired,
};

export default withModalTracker(ModalWrapper);

import i18next from 'i18next';
import ShopCard from './ShopCard';
import ShopsTracks from './ShopsTracks';
import ShopDetails from './ShopDetails';
import FeaturedShops from './FeaturedShops';

const loadLocales = () => {
  i18next.addResourceBundle(
    'en',
    'eCommerceShops',
    {
      metadata: {
        name: 'eCommerceShops',
        displayName: 'shop Card Module',
        description: 'This module is used to show all dynamic shops',
      },
      ShopCard: {
        ...ShopCard.En,
      },
      ShopsTracks: {
        ...ShopsTracks.En,
      },
      ShopDetails: {
        ...ShopDetails.En,
      },
      FeaturedShops: {
        ...FeaturedShops.En,
      },
    },
    true,
    true,
  );

  i18next.addResourceBundle(
    'ar',
    'eCommerceShops',
    {
      metadata: {
        name: 'eCommerceShops',
        displayName: 'Shops Card Module',
        description: 'This module is used to show all dynamic shops',
      },
      home: {
        menuTitle: 'Shops',
      },
      ShopCard: {
        ...ShopCard.Ar,
      },
      ShopsTracks: {
        ...ShopsTracks.Ar,
      },
      ShopDetails: {
        ...ShopDetails.Ar,
      },
      FeaturedShops: {
        ...FeaturedShops.Ar,
      },
    },
    true,
    true,
  );

  i18next.loadNamespaces('eCommerceShops');
};

export default loadLocales;

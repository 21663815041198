const CancelServiceTrans = {
  En: {
    headers: {
      cancelOrder: 'Cancel Order Request',
      cancelService: 'Cancel Service Request',
    },
    labels: {
      myOrders: 'My Orders',
      comment: 'Comment',
    },
    placeholders: {
      writeYourComment: 'Write your comment',
    },
    buttons: {
      cancel: 'Cancel',
      submit: 'Submit',
    },
  },
  Ar: {
    headers: {
      cancelOrder: 'الغاء الطلب',
      cancelService: 'الغاء الخدمة',
    },
    labels: {
      myOrders: 'طلباتي',
      comment: 'التعليق',
    },
    placeholders: {
      writeYourComment: 'اكتب تعليقك',
    },
    buttons: {
      cancel: 'الغاء',
      submit: 'ارسال',
    },
  },
};
export default CancelServiceTrans;

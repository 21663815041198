/* eslint-disable indent */
import React, { Component } from 'react';
import { Tab, Menu } from 'semantic-ui-react';
import styled, { css } from 'react-emotion';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import OutSideClick from '~/modules/ecommerceCoreUI/components/basic/OutSideClick';

import DepartmentTabPanContent from './DepartmentTabPanContent';


const DepartmentsTabContent = withDirection(styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 70px;
  border-radius: 7px;
  .ui.menu .item:before {
    content: initial;
  }
  .ui.bulleted.list>.item:before,
  .ui.bulleted.list .list>.item:before,
  ul.ui.list li:before {
    ${props => (props.direction === 'rtl'
      && css`
          margin-right: -1.25rem;
        `
    )}
  }
  .ui.bulleted.list, ul.ui.list{
    ${props => (props.direction === 'rtl'
      && css`
          margin-right: 1.25rem;
        `
    )}
  }

  .ui[class*="bottom attached"].menu {
    display: inline-block;
    width: 20%;
    height: 432px;
    background-color: #fff;
    border-radius: 7px;
    ${props => (props.direction === 'rtl'
      ? css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `
      : css`
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `
    )}
    border-top: 1px solid #e1e1e1 !important;
    a {
      position: relative;
      color: #b4b4b4;
      line-height: 1.5;
      &:after {
        position: absolute;
        width: 85%;
        height: 1px;
        ${props => (props.direction === 'rtl'
          ? css`
              right: 7%;
            `
          : css`
              left: 7%;
            `
        )}
        bottom: 0;
        border-top: 1px solid #e1e1e1;
        content: '';
      }
      &:last-child:after {
        content: initial;
      }
    }
  }

  .ui.attached + .ui.attached.menu:not(.top) {
    width: 20%;
    max-height: 432px;
    overflow-y: auto;
    padding: 15px 0;
    ${props => (props.direction === 'rtl'
      ? css`
          float: right;
        `
      : css`
          float: left;
        `
    )}
  }
  .ui.menu {
    margin: 0;
  }
  .ui.bottom.attached.menu:before {
    height: 15px;
    width: 15px;
    position: absolute;
    top: -8px;
    ${props => (props.direction === 'rtl'
      ? css`
          right: 22px;
          border-right: 1px solid #d4d4d5;
          transform: rotate(-134deg);
        `
      : css`
          left: 22px;
          border-left: 1px solid #d4d4d5;
          transform: rotate(134deg);
        `
    )}
    background: #fff;
    border-bottom: 1px solid #d4d4d5;
    content: '';
  }
  .item {
    color: rgba(0, 0, 0, 0.7);
    font-weight: ${props => props.theme.fonts.weights.semiBold};
    > i.icon {
      position: absolute;
      ${props => (props.direction === 'rtl'
        ? css`
            left: 7px;
          `
        : css`
            right: 7px;
          `
      )}
      font-size: 14px;
      text-transform: capitalize;
      color: #cacaca;
    }
  }
  .ui.menu .active.item,
  .ui.menu a.item:hover {
    color: #309de0;
    background-color: transparent;
    font-weight: ${props => props.theme.fonts.weights.semiBold};
    i {
      color: #309de0;
    }
  }

`);
class DepartmentsTab extends Component {
  showSubDepartment = (e) => {
    e.target.click();
  };

  render() {
    const { queryResult, isLoading, targetContainerIsShown } = this.props;
    const allTaxons = (queryResult && queryResult.taxons) || [];
    const panes = allTaxons.map((taxon, i) => ({
      menuItem: (
        <Menu.Item key={taxon.id} className={taxon.name} onMouseEnter={this.showSubDepartment}>
          {taxon.name}
          {' '}
          <i className="angle right" />
        </Menu.Item>
      ),
      render: () => <DepartmentTabPanContent departmentInfo={taxon} hideDepartments={this.props.clickOutSide} />,
    }));

    if (isLoading) {
      panes.push({
        menuItem: <Menu.Item>Loading...</Menu.Item>,
      });
    }

    return (
      <DepartmentsTabContent>
        {targetContainerIsShown && (
          <OutSideClick clickOutSide={() => this.props.clickOutSide()}>
            <Tab menu={{ attached: 'bottom' }} panes={panes} />
          </OutSideClick>
        )}
      </DepartmentsTabContent>
    );
  }
}

DepartmentsTab.defaultProps = {
  isLoading: false,
  targetContainerIsShown: false,
  clickOutSide: '',
  queryResult: [],
};

DepartmentsTab.propTypes = {
  isLoading: PropTypes.bool,
  queryResult: PropTypes.shape({
    taxons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        ref_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
  clickOutSide: PropTypes.func,
  targetContainerIsShown: PropTypes.bool,
};

export default withRootQuery(
  DepartmentsTab,
  graphql`
    query DepartmentsTabQuery {
      taxons(type: department) {
        id
        ref_id
        name
        ...DepartmentTabPanContent_departmentInfo
      }
    }
  `,
  DepartmentsTab,
);

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Button from '~/modules/coreUI/components/basic/Button';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import _ from 'lodash';
import RelayForm from '~/modules/coreUI/components/forms/RelayForm';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';

import getFormFields from './EditUserInfoFormFields';
import EditUserInfoFormLayout from '../editInfoLayouts/EditUserInfoFormLayout';
import EditUserInfoFormResposiveLayout from '../editInfoLayouts/EditUserInfoFormResposiveLayout';
import EditUserInfoMutation from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/EditUserInfoMutation';
import { scrollToContainerTop } from '~/modules/core/utils/jsHelpers/ScrollToTop';

const flattenAndParseCityCountryFieldValue = formVariables => ({ // eslint-disable-line import/prefer-default-export
  ..._.omit(formVariables, ['city', 'country', 'nationality']),
  city_id: formVariables.city.state_id,
  country_id: formVariables.country.country_id,
  nationality: formVariables.nationality.country_id,
});

class EditUserInfoForm extends React.Component {
  state = {
    isLoading: false,
    errors: null,
  };

  constructor(props) {
    super(props);
    this.formFields = getFormFields(props.userInfo);
  }

  onSuccess = response => this.props.onSuccess(response);

  onError = (errors) => {
    scrollToContainerTop('profileForm');
    this.setState({ errors });
  }

  onLoading = isLoading => this.setState({ isLoading });

  submitForm = () => this.form.submitForm();

  getInitialValues = userInfo => ({
    ..._.pick(userInfo, [
      'first_name',
      'last_name',
      'mobile_number',
      'email',
      'nationality',
      'gender',
      'birthdate_timestamp',
      'short_address',
      'club_id',
      'employer_id',
      'school_id',
      'insurance_company_id',
      'phone_provider_id',
      'phone_provider_attachment',
      'school_attachment',
      'employer_attachment',
      'insurance_company_attachment',
      'club_attachment',
    ]),
    city: {
      state_id: userInfo.city_id,
      country_id: userInfo.country_id,
    },
    country: {
      country_code: userInfo.phone_code,
      country_id: userInfo.country_id,
    },
    nationality: {
      country_code: userInfo.nationality,
      country_id: userInfo.nationality,
    },
  });

  render = () => (
    <Column className="profileForm" showHeader={false} width="100%" maxWidth={60} spaceBetween={1.5}>
      {this.state.errors && (
        <PanelAlert type="error" inverted errors={this.state.errors} />
      )}
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={error => this.onError(error)}
        onFormSuccess={response => this.onSuccess(response)}
        onFormLoading={loading => this.onLoading(loading)}
        mutationRoot="update_user_info"
        mutation={EditUserInfoMutation}
        options={{
          customLayout: this.props.media.minTablet ? EditUserInfoFormLayout : EditUserInfoFormResposiveLayout,
          initialFormValue: this.getInitialValues(this.props.userInfo),
          fields: this.formFields,
        }}
        getSubmissionVariables={formVariables => ({
          input: {
            ...flattenAndParseCityCountryFieldValue(formVariables),
          },
        })}
      />
      <Row fullWidth rightJustified spaceBetween={2}>
        <Button
          inverted
          width={this.props.media.xsmall ? null : '120px'}
          onClicked={() => this.props.onCloseEditModeRequested()}
          block={this.props.media.xsmall}
        >
          {i18next.t('accountManagement:Profile.cancel')}
        </Button>
        <Button
          secondary
          loading={this.state.isLoading}
          width={this.props.media.xsmall ? null : '120px'}
          onClicked={() => this.submitForm()}
          block={this.props.media.xsmall}
        >
          {i18next.t('accountManagement:Profile.save')}
        </Button>
      </Row>
    </Column>
  );
}

EditUserInfoForm.propTypes = {
  onCloseEditModeRequested: PropTypes.func.isRequired,
  media: PropTypes.shape({
    minTablet: PropTypes.bool,
    xsmall: PropTypes.bool,
  }).isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default withMedia(EditUserInfoForm);

/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';

import CurrentUserQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/CurrentUserQuery';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';

import EmptyPage from '../emptyStatePage/EmptyPage';
import SmartCartEmptyStateActions from '../smartCartActions/SmartCartEmptyStateActions';
import SmartCartItemsTable from './SmartCartItemsTable';


const SmartCartsList = ({ queryResult, authenticated }) => (
  <React.Fragment>
    {authenticated && queryResult?.current_user_details?.current_user?.smart_carts.length > 0 ? (
      <Column>
        <SmartCartEmptyStateActions smartCartsLength={queryResult.current_user_details.current_user.smart_carts.length} edit={false} continueShopping={false} />
        <SmartCartItemsTable smartCarts={queryResult?.current_user_details?.current_user?.smart_carts} />
      </Column>
    ) : (
      <EmptyPage edit={false} continueShopping={false} />
    )}
  </React.Fragment>
);


SmartCartsList.defaultProps = {
  queryResult: null,
};

SmartCartsList.propTypes = {
  queryResult: PropTypes.shape({}),
  authenticated: PropTypes.bool.isRequired,
};

export default withUserInfo(withRootQuery(
  SmartCartsList,
  CurrentUserQuery,
  null,
  null,
  { force: true },
));

/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import styled from 'react-emotion';

import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import ShoppingCart from '~/modules/ecommerceOrder/containers/shoppingCart';
import NavProductsIcon from './NavProducts/NavProductsIcon';

const ShoppingCartDropdown = styled.div`
  position: absolute;
  right: ${props => -14.5 * props.theme.new.spacer}px;
  left: auto;
  top: 45px;
  background: ${props => props.theme.colors.named.white};
  z-index: 5;
  border: solid ${props => props.theme.borders.size.thin}px ${props => props.theme.borders.color.light};
  border-radius: ${props => props.theme.borders.radius.small + 1}px;
  visibility: hidden;
  &&.active {
    visibility: visible;
  }
`;
const DropdownArrow = styled.div`
  position: absolute;
  top: ${props => props.theme.new.spacer * 4}px;
  right: 50%;
  background: ${props => props.theme.colors.named.white};
  width: ${props => props.theme.paddings.medium}px;
  height: ${props => props.theme.paddings.medium}px;
  border-top: solid ${props => props.theme.borders.size.thin}px ${props => props.theme.borders.color.light};
  border-right: solid ${props => props.theme.borders.size.thin}px ${props => props.theme.borders.color.light};
  transform: rotate(-45deg);
  z-index: 6;
  visibility: hidden;
  &&.active {
    visibility: visible;
  }
`;
const CloseDropDown = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
  cursor: default;
  visibility: hidden;
  &&.active {
    visibility: visible;
  }
`;

const WrapperStyle = styled(CenterAlignedRow)`
  position: relative;
`;

class NavProductsItems extends Component {
  state = {
    showDropdown: false,
  };

  handleToggleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  };

  render() {
    const { quantity, raw_amount, orders } = this.props.shoppingCart || {};
    const { showDropdown } = this.state;
    return (
      <WrapperStyle>
        <NavProductsIcon
          quantity={quantity}
          amount={raw_amount}
          orders={orders}
          onClick={this.handleToggleDropdown}
        />
        <React.Fragment>
          <DropdownArrow className={showDropdown && 'active'} />
          <ShoppingCartDropdown className={showDropdown && 'active'}>
            <ShoppingCart isDropdown amount={raw_amount} />
          </ShoppingCartDropdown>
        </React.Fragment>
        <CloseDropDown className={showDropdown && 'active'} onClick={this.handleToggleDropdown} />
      </WrapperStyle>
    );
  }
}

NavProductsItems.propTypes = {
  shoppingCart: PropTypes.objectOf(PropTypes.any),
};
NavProductsItems.defaultProps = {
  shoppingCart: null,
};
export default translate('aykLayout')(withCartInfo(NavProductsItems));

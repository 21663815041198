/**
 * @flow
 * @relayHash 0fb581ad6ff2a5cdb2c65f8ae04043c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PhoneNumberQueryVariables = {||};
export type PhoneNumberQueryResponse = {|
  +list_countries: $ReadOnlyArray<?{|
    +iso3: ?string,
    +name: ?string,
    +ref_id: string,
    +flag: ?string,
    +phone_code: ?string,
  |}>
|};
export type PhoneNumberQuery = {|
  variables: PhoneNumberQueryVariables,
  response: PhoneNumberQueryResponse,
|};
*/


/*
query PhoneNumberQuery {
  list_countries {
    iso3
    name
    ref_id
    flag
    phone_code
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "iso3",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "flag",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone_code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PhoneNumberQuery",
  "id": null,
  "text": "query PhoneNumberQuery {\n  list_countries {\n    iso3\n    name\n    ref_id\n    flag\n    phone_code\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PhoneNumberQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list_countries",
        "storageKey": null,
        "args": null,
        "concreteType": "Country",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PhoneNumberQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list_countries",
        "storageKey": null,
        "args": null,
        "concreteType": "Country",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca8e22eda7e682740a05fd879037fcdf';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Container } from 'semantic-ui-react';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import AdSquereCard from './AdSquereCard';

const RowWrapper = styled(Row)`
  ${cssMediaMax.tablet`
    flex-direction: column;
  `}
`;
const CardWrapper = styled.div`
  width: 50%;
  ${cssMediaMax.tablet`
    width: 100%;
  `}
`;
const AdLevel1Card = ({ queryResult }) => (
  <React.Fragment>
    {queryResult && queryResult.advertisements.length !== 0 && (
      <Container>
        <Spacer size={[2.5, 2.5, 2.5, 4]} />
        <RowWrapper spaceBetween={[1.5, 1.5, 2.4, 3]}>
          {queryResult.advertisements.map(Advertising => (
            <CardWrapper key={Advertising.id}>
              <AdSquereCard {...Advertising} />
            </CardWrapper>
          ))}
        </RowWrapper>
        <Spacer size={[2.5, 2.5, 2.5, 4]} />
      </Container>
    )}
  </React.Fragment>
);

AdLevel1Card.propTypes = {
  queryResult: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    button_text: PropTypes.string,
    title_color: PropTypes.string,
    button_color: PropTypes.string,
    button_text_color: PropTypes.string,
    large_photo: PropTypes.shape({
      url_original: PropTypes.string,
    }),
  }),
};

AdLevel1Card.defaultProps = {
  queryResult: null,
};

export default withRootQuery(
  AdLevel1Card,
  graphql`
    query AdLevel1CardQuery {
      advertisements(limit: 2, ad_section: homepage_1, kind: web) {
        ...WithAdvertisingData_Advertising @relay(mask: false)
      }
    }
  `,
  AdLevel1Card,
);

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Label } from '~/modules/coreUI/components/basic/Labels';

const ProductsLeftInStockCounter = ({ stockItemsLength }) => (
  <Label primary size={['xs', 'xs', 'sm']}>
    {stockItemsLength ? (
      <React.Fragment>
        {i18next.t('ecommerceCoreUI:Stock.only', { stockItemsLength })}
      </React.Fragment>
    ) : (
      <React.Fragment>{i18next.t('ecommerceCoreUI:Stock.in')}</React.Fragment>
    )}
  </Label>
);

ProductsLeftInStockCounter.propTypes = {
  stockItemsLength: PropTypes.number,
};

ProductsLeftInStockCounter.defaultProps = {
  stockItemsLength: undefined,
};

export default ProductsLeftInStockCounter;

/* eslint-disable indent */
import React from 'react';
import { Tab } from 'semantic-ui-react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import SubDepartmentsList from './SubDepartmentsList';
import DepartmentCountSection from './DepartmentCountSection';
import DepartmentBrands from './DepartmentBrands';

const TabContent = withDirection(styled(Tab.Pane)`
  background-color: #e1e1e1;
  &.ui.tab.active,
  &.ui.tab.open {
    display: inline-block;
    width: 80%;
    height: 432px;
    background-color: #fafafa;
    ${props => (props.direction === 'rtl'
      ? css`
          border-top-left-radius: 7px;
        `
      : css`
          border-top-right-radius: 7px;
        `
    )}
    border-top: 1px solid #e1e1e1;
  }
`);
const DepartmentsContent = styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MAX_SUBDEPARTMENTS_TO_SHOW = hasTopBrands => (hasTopBrands ? '5' : '6');

function DepartmentTabPanContent({ departmentInfo, hideDepartments }) {
  return (
    <TabContent className="tab-content">
      <DepartmentsContent>
        {departmentInfo.children
          && departmentInfo.children.map((subDepartment, index) => (
            index < MAX_SUBDEPARTMENTS_TO_SHOW(departmentInfo.top_brands.length > 0) && (
              <SubDepartmentsList
                subDepartmentInfo={subDepartment}
              // eslint-disable-next-line no-underscore-dangle
                key={subDepartment.__id}
                departmentId={departmentInfo.ref_id}
                departmentName={departmentInfo.name}
                hideDepartments={hideDepartments}
              />
            )))}
        <DepartmentBrands
          topBrands={departmentInfo.top_brands}
          departmentId={departmentInfo.ref_id}
          departmentName={departmentInfo.name}
          hideDepartments={hideDepartments}
        />
        <DepartmentCountSection
          mainDepartmentCount={departmentInfo.products_count}
          departmentName={departmentInfo.name}
          departmentId={departmentInfo.ref_id}
          description={departmentInfo.mega_dropdown_description}
          image={departmentInfo.mega_dropdown_image}
        />
      </DepartmentsContent>
    </TabContent>
  );
}

DepartmentTabPanContent.propTypes = {
  departmentInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    products_count: PropTypes.number.isRequired,
  }).isRequired,
  hideDepartments: PropTypes.func.isRequired,
};

export default withFragmentContainer(
  DepartmentTabPanContent,
  graphql`
    fragment DepartmentTabPanContent_departmentInfo on Taxon {
      name
      products_count
      mega_dropdown_description
      mega_dropdown_image
      ref_id
      children(limit: 6) {
        ...SubDepartmentsList_subDepartmentInfo
      }
      top_brands {
        ...DepartmentBrands_topBrands
      }
    }
  `,
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled, { css } from 'react-emotion';

import NavProductsItems from '~/modules/ecommerceCoreUI/components/header/headerActions/NavProductsItems';
import NavNotification from '~/modules/ecommerceCoreUI/components/header/headerActions/NavNotifications/NavNotification';
import NavWishList from '~/modules/ecommerceCoreUI/components/header/headerActions/NavWishList';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

export const MenuContainer = withDirection(styled(Menu)`
  ${cssMediaMax.tablet`
    margin-bottom: 0 !important;
  `}
  .item {
    ${props => (props.direction === 'rtl'
    ? css`
        padding-left: ${1.2 * props.theme.new.spacer}px !important;
        padding-right: 0px !important;
      `
    : css`
      padding-right: ${1.2 * props.theme.new.spacer}px !important;
      padding-left: 0px !important;
  `)}
  }
  .item:before {
    background: none !important;
  }
  &&.right.item, &&.ui.menu:not(.vertical) .right.menu {
    ${props => (props.direction === 'rtl'
      && css`
          margin-right: auto !important;
          margin-left: 0 !important;
        `
  )}
  }
`);

export const BurgerMenuIcon = withDirection(styled(Icon)`
  padding-left: ${props => props.theme.paddings.large}px !important;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.sizes.xLarge}px !important;
  object-fit: contain;
  cursor: pointer;
`);
export const ImageLogo = styled(Image)`
  max-width: 74% !important;
`;

class HeaderMobile extends Component {
  showSideMenu = () => {
    document.getElementById('root').classList.toggle(this.props.direction === 'rtl' ? 'root-animation-ar' : 'root-animation');
    document.getElementsByTagName('body')[0].classList.toggle('side-menu-open');
  };

  render() {
    return (
      <MenuContainer>
        <Menu.Item className="menu-btn">
          <BurgerMenuIcon name="bars" onClick={this.showSideMenu} />
        </Menu.Item>
        <Menu.Item>
          <Link to="/" href="/">
            <ImageLogo
              src={
                this.props.direction === 'rtl'
                  ? `/${imagesPathName}/Header/logo-header.png`
                  : `/${imagesPathName}/Header/logo-en-lg.png`
                }
              className="logo"
            />
          </Link>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <NavWishList />
          </Menu.Item>
          <Menu.Item>
            <NavNotification />
          </Menu.Item>
          <Menu.Item>
            <NavProductsItems />
          </Menu.Item>
        </Menu.Menu>
      </MenuContainer>
    );
  }
}
HeaderMobile.propTypes = {
  direction: PropTypes.string.isRequired,
};
export default withDirection(HeaderMobile);

/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import { SmallLabel } from '../basic/Labels';

const StylableCheckbox = withDirection(styled.input`
  position: absolute;
  left: -999em;

  &:checked + label .checkbox-sign::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 6px;
    background: rgba(0, 0, 0, 0);
    bottom: 5px;
    ${props => (props.direction === 'rtl'
    ? css`
        right: 2px;
      `
    : css`
        left: 2px;
    `)};
    border: 2px solid ${props => props.theme.colors.secondary};
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  & + label .checkbox-sign {
    cursor: pointer;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 1px solid;
    border-color: ${props => props.theme.inputs.borderColor};
    border-radius: 3px;
    flex-shrink: 0;
    position: relative;
    ${props => (props.direction === 'rtl'
    ? css`
        margin-left: 6px;
      `
    : css`
        margin-right: 6px;
      `)};
  }

  &:focus + label .checkbox-sign {
    border-color: ${props => props.theme.inputs.borderColorActive};
  }

  & + label {
    margin-left: 2px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.labels.normal};
  }
`);

const Checkbox = props => (
  <CenterAlignedRow>
    <StylableCheckbox type="checkbox" id={props.elemID} {...props} />
    <label htmlFor={props.elemID}>
      <span className="checkbox-sign" />
      <SmallLabel bold={props.bold}>{props.label}</SmallLabel>
    </label>
  </CenterAlignedRow>
);

Checkbox.propTypes = PropTypes.shape({
  elemID: PropTypes.string.isRequired,
}).isRequired;

export default Checkbox;

import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'react-emotion';
import Dropdown from '~/modules/coreUI/components/legacies/Dropdown';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Column } from '~/modules/coreUI/components/legacies/Columns';

import { SearchDropdownTriggerStyles } from '../CustomHeaderComponentsStyles';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const StyledProductSearchDropdownContainer = withDirection(styled.div`
  .ui.dropdown .menu>.item {
    ${props => (props.direction === 'rtl'
    && css`
      text-align: right;
    `)}
  }
  flex-grow: 1;
  ${cssMediaMax.xsmall`
     width: 100%;
     margin-bottom: 10px;
  `} .menu {
    width: 100%;
  }
  div {
    width: 100%;
    border-radius: 5px;
  }
  .dropdown {
    display: flex;
    height: 40px;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    border-bottom-left-radius: ${props => props.theme.borders.radius.normal}px;
    border-top-left-radius: ${props => props.theme.borders.radius.normal}px;
    align-items: center;
    padding: 15px;
    i.icon {
      font-size: 9px;
    }
    i.icon:before {
      font-size: 9px;
      opacity: 0.5;
    }
    .menu:after {
      left: 50% !important;
    }
  }

  .chevron {
    font-size: ${props => 0.75 * props.theme.fonts.sizes.xSmall}px;
    padding-left: ${props => 0.75 * props.theme.fonts.sizes.xSmall}px;
    padding-top: 1px;
  }
`);

const DepartmentDropdown = ({ departments, onDepartmentChanged, selectedValue }) => (
  <StyledProductSearchDropdownContainer>
    <Column fullWidth stretchAligned>
      <Dropdown
        options={departments}
        pointing="top left"
        customTriggerLabelStyles={SearchDropdownTriggerStyles}
        onChange={val => onDepartmentChanged(val)}
        selectedValue={selectedValue}
        icon="el-icon el-icon-down-arrow"
      />
    </Column>
  </StyledProductSearchDropdownContainer>
);

DepartmentDropdown.propTypes = {
  // eslint-disable-next-line function-paren-newline
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDepartmentChanged: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default DepartmentDropdown;

import styled from 'react-emotion';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import spaceStyles from '~/modules/coreUI/utils/styleSystem';

const ItemImage = withDirection(styled.div`
  background-image: url(${props => props.src});
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;

  border-style: solid;
  border-width: ${props => props.theme.borders.size.thin}px;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  border-color: ${props => props.theme.borders.color.lightGray};

  ${props => spaceStyles(props)};
  ${props => props.customStyle && props.customStyle(props)};
`);

export default withMedia(ItemImage);

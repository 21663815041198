import _ from 'lodash';

export default (codeToFind, responseData) => {
  let foundError = null;
  _.values(responseData).find((responseRoot) => {
    if (responseRoot?.errors?.length > 0) {
      return responseRoot?.errors?.find((error) => {
        if (error.code === codeToFind) {
          foundError = error;
        }
        return null;
      });
    }
    return null;
  });
  return foundError;
};

/* eslint-disable  camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import OfferLabel from './OfferLabel';

const getOfferType = (itemInfo) => {
  let offerType = null;
  if (itemInfo.has_discount) {
    offerType = 'hasDiscount';
  } else if (itemInfo.has_offer) {
    offerType = 'offer';
  } else if (itemInfo.hot_deal) {
    offerType = 'hotOffer';
  }
  return offerType;
};

const ItemOfferLabel = ({ itemInfo }) => {
  const offerType = getOfferType(itemInfo);

  if (!(offerType === 'offer' || offerType === 'hotOffer')) {
    return null;
  }

  const contentRenderer = itemInfo?.gift_image?.url_original && (
    <img
      src={itemInfo.gift_image.url_original}
      alt=""
    />
  );

  return itemInfo?.gift_included ? <OfferLabel text={itemInfo.gift_text} offer={contentRenderer} /> : <span />;
};

ItemOfferLabel.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default ItemOfferLabel;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-relay';
import _ from 'lodash';
import i18next from 'i18next';

import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import Button from '~/modules/coreUI/components/basic/Button';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { scrollHtmltoTop } from '~/modules/core/utils/jsHelpers/ScrollToTop';

import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';

const ProceedToCheckoutButtonMutationRoot = 'cart_checkout_next';

const ProceedToCheckoutButtonMutation = graphql`
  mutation ProceedToCheckoutButtonMutation($from_state: AvailableState) {
    cart_checkout_next(from_state: $from_state) {
      cart {
        ...WithCartData_cart @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;

class ProceedToCheckoutButton extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  onMutationError = (errors) => {
    const {
      isDropdown,
      setCartManagementPartial,
    } = this.props;

    if (errors) {
      setCartManagementPartial({
        errors,
      });
      scrollHtmltoTop();
    }

    if (isDropdown) {
      externalRedirect('/cart');
    }
  };

  onMutationSuccess = (response) => {
    const {
      isDropdown,
    } = this.props;

    const cart = response.cart_checkout_next
      && response.cart_checkout_next.cart;

    if (this.isBlockingErrorsExist(cart)) {
      if (isDropdown) {
        externalRedirect('/cart');
      } else {
        scrollHtmltoTop();
      }
    } else {
      externalRedirect(`/checkoutSteps/${response.cart_checkout_next.cart.least_state}`);
    }
  };

  onMutationLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  isBlockingErrorsExist = (cart) => {
    const orders = cart && cart.orders;

    let lineItems = orders && _.flatten(orders.map(order => order.line_items));
    lineItems = lineItems || [];
    const warnings = _.flatten(lineItems.map(lineItem => lineItem.warnings));
    return _.findKey(warnings, 'blocking');
  }

  handleCheckout = () => {
    const {
      history,
      shoppingCart,
    } = this.props;

    if (this.props.authenticated) {
      // Passed optionalCallback as it is in Dropdown component which removed from DOM tree
      commitMutation(
        this.props.environment,
        ProceedToCheckoutButtonMutation,
        ProceedToCheckoutButtonMutationRoot,
        { from_state: 'cart' },
        this.onMutationSuccess,
        this.onMutationError,
        this.onMutationLoading,
      );
    } else if (this.isBlockingErrorsExist(shoppingCart)) {
      externalRedirect('/cart');
    } else {
      this.props.saveCurrentPath(PathRedirectKeysMapper.showSuccess, false, '/cart');
      navigateToModal(history, '/accountManagement/login');
    }
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Button
        size={this.props.isDropdown ? 'lg' : 'xl'}
        block={this.props.fullWidth}
        secondary
        loading={isLoading}
        onClicked={this.handleCheckout}
      >
        {this.props.isDropdown ? i18next.t('ecommerceOrder:Cart.checkout') : i18next.t('ecommerceOrder:Cart.proceedCheckout')}
      </Button>
    );
  }
}

ProceedToCheckoutButton.defaultProps = {
  onRef: null,
  fullWidth: false,
  isDropdown: false,
};

ProceedToCheckoutButton.propTypes = {
  history: PropTypes.shape({}).isRequired,
  authenticated: PropTypes.bool.isRequired,
  saveCurrentPath: PropTypes.func.isRequired,
  onRef: PropTypes.func,
  fullWidth: PropTypes.bool,
  isDropdown: PropTypes.bool,
  environment: PropTypes.shape({}).isRequired,
  setCartManagementPartial: PropTypes.func.isRequired,
  shoppingCart: PropTypes.shape({}).isRequired,
};

export default withRouter(
  withUserInfo(
    withCartInfo(
      withRelayEnvironment(
        ProceedToCheckoutButton,
      ),
    ),
  ),
);

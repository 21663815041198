/**
 * @flow
 * @relayHash 987d52571e9fa1211189fcc5377efc5d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Product_productInfo$ref = any;
export type ProductsListPageQueryVariables = {||};
export type ProductsListPageQueryResponse = {|
  +products: {|
    +result: $ReadOnlyArray<?{|
      +$fragmentRefs: Product_productInfo$ref
    |}>,
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type ProductsListPageQuery = {|
  variables: ProductsListPageQueryVariables,
  response: ProductsListPageQueryResponse,
|};
*/


/*
query ProductsListPageQuery {
  products(input: {}) {
    result {
      ...Product_productInfo
      id
    }
    errors {
      field
      messages
      code
    }
  }
}

fragment Product_productInfo on Product {
  id
  slug
  vendor_variants_count
  min_variant {
    vendor {
      name
      ref_id
      id
    }
    slug
    name
    ref_id
    root_variant_id
    discount_expire_at
    remaining_usage_percentage
    total_on_hand
    price
    original_price
    id
  }
  ref_id
  images
  name
  avg_rating
  rating_count
  price
  original_price
  discount
  has_discount
  has_offer
  hot_deal
  wish_listed
  root_variant {
    root_variant_id
    ref_id
    wish_listed
    id
  }
  variants {
    id
    ref_id
    option_values {
      id
      ref_id
      option_type_name
    }
  }
  related_products {
    images
    name
    id
  }
  gift_image {
    url_large
    url_original
  }
  gift_text
  gift_included
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {},
    "type": "ProductsQueryInput"
  }
],
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    }
  ]
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "root_variant_id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original_price",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "images",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProductsListPageQuery",
  "id": null,
  "text": "query ProductsListPageQuery {\n  products(input: {}) {\n    result {\n      ...Product_productInfo\n      id\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n\nfragment Product_productInfo on Product {\n  id\n  slug\n  vendor_variants_count\n  min_variant {\n    vendor {\n      name\n      ref_id\n      id\n    }\n    slug\n    name\n    ref_id\n    root_variant_id\n    discount_expire_at\n    remaining_usage_percentage\n    total_on_hand\n    price\n    original_price\n    id\n  }\n  ref_id\n  images\n  name\n  avg_rating\n  rating_count\n  price\n  original_price\n  discount\n  has_discount\n  has_offer\n  hot_deal\n  wish_listed\n  root_variant {\n    root_variant_id\n    ref_id\n    wish_listed\n    id\n  }\n  variants {\n    id\n    ref_id\n    option_values {\n      id\n      ref_id\n      option_type_name\n    }\n  }\n  related_products {\n    images\n    name\n    id\n  }\n  gift_image {\n    url_large\n    url_original\n  }\n  gift_text\n  gift_included\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProductsListPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "products",
        "storageKey": "products(input:{})",
        "args": v0,
        "concreteType": "ProductsQueryResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "Product_productInfo",
                "args": null
              }
            ]
          },
          v1
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductsListPageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "products",
        "storageKey": "products(input:{})",
        "args": v0,
        "concreteType": "ProductsQueryResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "discount",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "vendor_variants_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "min_variant",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "discount_expire_at",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "vendor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Shop",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v2
                    ]
                  },
                  v3,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "remaining_usage_percentage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "total_on_hand",
                    "args": null,
                    "storageKey": null
                  },
                  v7,
                  v8,
                  v2
                ]
              },
              v4,
              v9,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "avg_rating",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "rating_count",
                "args": null,
                "storageKey": null
              },
              v7,
              v8,
              v6,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "has_discount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "has_offer",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hot_deal",
                "args": null,
                "storageKey": null
              },
              v10,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "root_variant",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": false,
                "selections": [
                  v5,
                  v4,
                  v10,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "variants",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": true,
                "selections": [
                  v2,
                  v4,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "option_values",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OptionValue",
                    "plural": true,
                    "selections": [
                      v2,
                      v4,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "option_type_name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "related_products",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": true,
                "selections": [
                  v9,
                  v3,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "gift_image",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageAttachment",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url_large",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url_original",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "gift_text",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "gift_included",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed535a62d1b726a3a5b41186b4bc1626';
module.exports = node;

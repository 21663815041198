import { graphql } from 'react-relay';

import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

export default function (WrappedComponent) {
  return withFragmentContainer(
    WrappedComponent,
    graphql`
      fragment WithCartData_cart on Cart @relay(mask: false) {
        id
        ref_id
        name
        amount
        amount_in_qar
        quantity
        shipping_price
        raw_amount
        ref_id
        services_only
        least_state
        promo_code
        discount
        taxes
        raw_amount
        selected_shipping_address {
          ref_id
        }
        selected_billing_address {
          ref_id
        }
        smart_cart
        user_smart_cart
        same_as_shipping
        discount
        payment_reference
        amount_with_cash
        amount_with_credit
        orders {
          ...WithOrderData_orders @relay(mask: false)
        }
        promotions{
          ref_id
          code
        }
        user{
          avatar_details{
            url_original
            name
          }
          first_name
          last_name
        }
      }
    `,
  );
}

/**
 * @flow
 * @relayHash 155895899076bcdeb01b10c03c90ace9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployerQueryVariables = {||};
export type EmployerQueryResponse = {|
  +employers: ?$ReadOnlyArray<?{|
    +ref_id: string,
    +name: ?string,
    +id: string,
  |}>
|};
export type EmployerQuery = {|
  variables: EmployerQueryVariables,
  response: EmployerQueryResponse,
|};
*/


/*
query EmployerQuery {
  employers {
    ref_id
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "employers",
    "storageKey": null,
    "args": null,
    "concreteType": "Employer",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ref_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EmployerQuery",
  "id": null,
  "text": "query EmployerQuery {\n  employers {\n    ref_id\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EmployerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "EmployerQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6aaf1e3b7cef089e54a6ff57e17afcca';
module.exports = node;

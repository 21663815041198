import React from 'react';
import PropTypes from 'prop-types';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';

const DesktopPanelHeader = props => (
  <Row spaceBetweenJustified>
    <Column leftAligned spaceBetween={1}>
      <Row spaceBetween={0.5}>
        {props.orderNumberLabel}
        {props.orderNumberValue}
      </Row>
      <Row>
        {props.orderDate}
        <Separator vertical separatorLength="full" spacerSize={1.3} />
        {props.orderStatus}
      </Row>
    </Column>
    {props.orderSteps}
  </Row>
);

DesktopPanelHeader.propTypes = {
  orderNumberLabel: PropTypes.element.isRequired,
  orderNumberValue: PropTypes.element.isRequired,
  orderDate: PropTypes.element.isRequired,
  orderStatus: PropTypes.element.isRequired,
  orderSteps: PropTypes.element.isRequired,
};

export default DesktopPanelHeader;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import extractSocialMediaData from '~/modules/accountManagement/utilities/userData';
import getSocialProviderCredentials, {
  getRedirectURL,
} from '~/modules/accountManagement/utilities/socialMediaData';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';

import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

import { prepareLocalMessages } from '~/modules/accountManagement/utilities/messagesHelper';

import SocialMediaWrapper from './SocialMediaWrapper';
import SocialMediaButton from './SocialMediaButton';

class LoginWithSocialMedia extends React.Component {
  onLoginSuccess = (user) => {
    const { history, saveSocialMediaData } = this.props;

    const socialMediaReturnedUserInfo = extractSocialMediaData(user);

    saveSocialMediaData(socialMediaReturnedUserInfo);

    navigateToModal(history, '/accountManagement/socialMediaLanding');
  };

  onLoginFailure = (error) => {
    const { getSocialMediaData, history } = this.props;

    getSocialMediaData(true);

    if (error) {
      const errorMessage = prepareLocalMessages(error);
      this.props.notifyAlert({ messageText: errorMessage, type: AlertTypes.error, toastID: 'LoginWithSocialMedia_success_toast' });
    } else {
      navigateToModal(history, '/accountManagement/login');
    }
  };

  getSocialConfiguration = socialMediaName => getSocialProviderCredentials(socialMediaName);

  triggerTwitterLogin = () => {
    const { history } = this.props;
    navigateToModal(history, '/accountManagement/triggerTwitterLogin');
  };

  beforeLoginAction = () => {
    const { getSocialMediaData, removeSavedPaths } = this.props;

    getSocialMediaData(true);
    removeSavedPaths();
    // CODE_REVIEW : Why do we need this ??
    // localMessagesFor();
  };

  render = () => {
    let FinalSocialMediaButton = null;

    const { socialMedia } = this.props;

    const { provider, appId } = this.getSocialConfiguration(socialMedia.name);
    const isTwitter = provider === 'twitter';

    FinalSocialMediaButton = isTwitter ? (
      <SocialMediaButton
        triggerLogin={this.triggerTwitterLogin}
        socialMedia={socialMedia}
        beforeLoginAction={this.beforeLoginAction}
      />
    ) : (
      <SocialMediaWrapper
        provider={provider}
        appId={appId}
        redirect={getRedirectURL()}
        beforeLoginAction={this.beforeLoginAction}
        onLoginSuccess={this.onLoginSuccess}
        onLoginFailure={this.onLoginFailure}
        socialMedia={socialMedia}
      />
    );
    return FinalSocialMediaButton;
  };
}

LoginWithSocialMedia.propTypes = {
  saveSocialMediaData: PropTypes.func.isRequired,
  getSocialMediaData: PropTypes.func.isRequired,
  removeSavedPaths: PropTypes.func.isRequired,
  notifyAlert: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  socialMedia: PropTypes.shape({}).isRequired,
};

export default withRouter(withUserInfo(withAlertMsg(LoginWithSocialMedia)));

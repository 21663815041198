import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { Column, Box, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';

import DesktopLayout from '../smartListLayout/DesktopLayout';
import SmartCartListLayout from '../smartListLayout/Layout';

const smartCartsRequiredFields = [
  'smartName',
  'noOfItems',
  'totalPrice',
  'actions,',
];

const defaultValuesForRequiredSmartCartFields = smartCartsRequiredFields.reduce(
  (accumulator, field) => ({ ...accumulator, [field]: <span /> }),
  {},
);


const HeaderCellContainer = styled(Box)`
  z-index: 1;
`;

const TableHeaderEntry = props => (
  <HeaderCellContainer fullWidth centerAligned>
    <Label semiBold emphasized md>
      {
        props.children //eslint-disable-line
      }
    </Label>
  </HeaderCellContainer>
);

export const HeaderContainer = styled(Row)`
  height: ${props => 5 * props.theme.new.spacer}px;
  background: ${props => props.theme.new.colors.backgrounds.panel};
  border: ${props => props.theme.borders.size.bold + 2}px solid
    ${props => props.theme.colors.named.white};
  border-bottom: 0;
  border-radius: ${props => 0.5 * props.theme.new.spacer}px;
`;

const bodyStyle = props => css`
  ${props.media.maxTablet && css`
     padding-top: 0;
  `}
`;

const TableHeader = () => (
  <HeaderContainer fullWidth>
    <DesktopLayout
      {...defaultValuesForRequiredSmartCartFields}
      rowGap={0}
      smartName={<TableHeaderEntry>{i18next.t('ecommerceOrder:smartCart.MySmartCartsTitle')}</TableHeaderEntry>}
      noOfItems={(
        <TableHeaderEntry>
          {i18next.t('ecommerceOrder:smartCart.numberOfItemsTableHeader')}
        </TableHeaderEntry>
      )}
      totalPrice={(
        <TableHeaderEntry>
          {i18next.t('ecommerceOrder:smartCart.totalPriceTableHeader')}
        </TableHeaderEntry>
)}
      actions={<TableHeaderEntry>&nbsp;</TableHeaderEntry>}
      isClickable={false}
    />
  </HeaderContainer>
);


const TableContainer = styled(Column)`
  position: relative;
  border-radius: ${props => 0.5 * props.theme.new.spacer}px;
`;

const DesktopSmartCartTable = smartCarts => (
  <TableContainer fullWidth bordered>
    <TableHeader />
    {smartCarts.map((smartCart, index) => (
      <React.Fragment key={smartCart.ref_id}>
        <SmartCartListLayout smartCart={smartCart} />
        {index !== smartCarts.length - 1 && (
          <Separator spacerSize={0.1} separatorLength="full" />
        )}
      </React.Fragment>
    ))}
  </TableContainer>
);

const MobileSmartCartTable = smartCarts => (
  <Panel
    headerTitle={i18next.t('ecommerceOrder:smartCart.MySmartCartsTitle')}
    bodyStyle={bodyStyle}
    body={(
      <React.Fragment>
        {smartCarts.map((smartCart, index) => (
          <React.Fragment key={smartCart.ref_id}>
            <SmartCartListLayout smartCart={smartCart} />
            {index !== smartCarts.length - 1 && (
            <Separator spacerSize={0.1} separatorLength="full" />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
  )}
  />
);

const SmartCartItemsTable = ({ smartCarts, media }) => (
  media.minDesktop ? (
    DesktopSmartCartTable(smartCarts)
  ) : (
    MobileSmartCartTable(smartCarts)
  )

);

SmartCartItemsTable.propTypes = {
  smartCarts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  media: PropTypes.shape({
    minDesktop: PropTypes.bool,
  }).isRequired,
};

export default withMedia(SmartCartItemsTable);

const MessagingTrans = {
  En: {
    faqLink: 'FAQs',
    askAykInfoFirstPart: 'Check',
    askAykInfoSecondPart: ' , if you didn’t find your answer, we would be happy to help you.',
    submitButton: 'Submit',
    successPageTitle: 'Thank You',
    successPageSubTitle: 'Your question sent successfully and your ticket number is {{ticketNumber}}',
    goToMessagesLink: 'Check Your Messages',
    questionsInputLabel: 'Question Category',
    questionsInputDisplayName: 'Question category',
    questionsInputPlaceholder: 'Select question category',
    bodyInputLabel: 'Tell Us About Your Problem',
    bodyInputDisplayName: 'Message',
    bodyInputPlaceholder: 'Enter your question',
    attachmentsInputDisplayName: 'Attachments',
    uploadImageTitle: 'Attach Images',
    uploadImageButton: 'Upload Images',
    uploadImageValidationInfo: ' Optional with Max. 3 images, 5MB per image',
    messageOfimageMaxSize: 'Image size too big, you can upload image with maximum size 5 MB',
    messageOfimageMaxNumber: 'Maximum number of images 3 images',
    MaxNumberImageIsOne: 'Maximum number of images 1 image',
    askAykTitle: 'Ask Ayk',
    customerServiceLink: 'Help Center',
    shopDetailsLink: 'Shop Details',
    askLabelShop: 'Ask',
    messageOfNotValidExtenstion: 'Unsupported file extension. Upload only png, jpeg or jpg',
  },
  Ar: {
    faqLink: 'الأسئلة الشائعة',
    askAykInfoFirstPart: 'تحقق',
    askAykInfoSecondPart: 'إذا لم تجد إجابتك ، فسيسعدنا مساعدتك.',
    submitButton: 'إرسال',
    successPageTitle: 'شكرًا لك',
    successPageSubTitle: 'تم إرسال سؤالك بنجاح ورقم تذكرتك هو {{ticketNumber}}',
    goToMessagesLink: 'تحقق من رسائلك',
    questionsInputLabel: 'فئة السؤال',
    questionsInputDisplayName: 'فئة السؤال',
    questionsInputPlaceholder: 'حدد فئة السؤال',
    bodyInputLabel: 'أخبرنا عن مشكلتك',
    bodyInputDisplayName: 'رسالة',
    bodyInputPlaceholder: 'أدخل سؤالك',
    attachmentsInputDisplayName: 'المرفقات',
    uploadImageTitle: 'إرفاق صور',
    uploadImageButton: 'تحميل الصور',
    uploadImageValidationInfo: 'اختياري مع ماكس. 3 صور ، 5 ميغابايت',
    messageOfimageMaxSize: 'حجم الصورة كبير جدًا ، يمكنك تحميل صورة بحجم أقصى 5 ميغابايت',
    messageOfimageMaxNumber: 'الحد الأقصى لعدد الصور 3 صور',
    MaxNumberImageIsOne: 'الحد الأقصى لعدد الصور 1 صور',
    shopDetailsLink: 'تفاصيل المتجر',
    askLabelShop: 'اسأل',
    customerServiceLink: 'مركز المساعدة',
    askAykTitle: 'اسأل أيك',
    messageOfNotValidExtenstion: 'لا يستطيع المستخدم تحميل أى امتداد عدا ‎png, jpeg and jpg',
  },
};
export default MessagingTrans;

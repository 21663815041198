/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import BasicInfo from './basicInfo';
import i18next from 'i18next';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import CurrentUserQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/CurrentUserQuery';
import Addresses from '~/modules/ecommerceCoreUI/containers/addresses';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

const UserProfile = props => (
  <Column spaceBetween={1}>
    <BasicInfo
      currentUser={props.queryResult?.current_user_details?.current_user}
    />
    <Addresses
      currentUserInfo={props.queryResult.current_user_details.current_user}
      addressSelectionEnabled={false}
      addressDeletionEnabled
      showSameAsShippingToggleAfterFirstTime={false}
      onSelectedAddressesSaved={
        () => props.notifyAlert({
          messageText: i18next.t('ecommerceOrder:Checkout.onAddressSaved'),
          type: AlertTypes.success,
          opacity: 1,
          toastID: 'UserProfile_success_toast',
        })
      }
      forceInitialMode="column"
      panelExtraProps={{
        borderLessHeader: true,
      }}
      saveButtonLabel={i18next.t('accountManagement:Profile.saveAddressButtonLabel')}
    />
  </Column>
);

UserProfile.propTypes = {
  notifyAlert: PropTypes.func.isRequired,
  queryResult: PropTypes.shape({
    current_user_details: PropTypes.shape({
      current_user: PropTypes.shape({
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default withAlertMsg(withRootQuery(
  UserProfile,
  CurrentUserQuery,
  null,
  null,
  { force: true },
));

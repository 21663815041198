import { css } from 'emotion';

const CardsContainer = css`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
`;

export default CardsContainer;

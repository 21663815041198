/**
 * @flow
 * @relayHash 7aeb3cf37e4db6eab5ada63fc2ab601c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DropdownShops_queryResult$ref = any;
export type TrackType = "education_park" | "health_park" | "homemade" | "shops" | "%future added value";
export type ShopQueryInput = {
  type?: ?TrackType,
  freetext_search?: ?string,
  recommended?: ?TrackType,
  page?: ?PageInfoInput,
};
export type PageInfoInput = {
  limit?: ?number,
  offset?: ?number,
};
export type DropdownShopsQueryVariables = {|
  input?: ?ShopQueryInput
|};
export type DropdownShopsQueryResponse = {|
  +$fragmentRefs: DropdownShops_queryResult$ref
|};
export type DropdownShopsQuery = {|
  variables: DropdownShopsQueryVariables,
  response: DropdownShopsQueryResponse,
|};
*/


/*
query DropdownShopsQuery(
  $input: ShopQueryInput
) {
  ...DropdownShops_queryResult_2VV6jB
}

fragment DropdownShops_queryResult_2VV6jB on Query {
  shops(input: $input) {
    shops {
      logo_url
      name
      ref_id
      id
    }
    page_info {
      items_count
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ShopQueryInput",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DropdownShopsQuery",
  "id": null,
  "text": "query DropdownShopsQuery(\n  $input: ShopQueryInput\n) {\n  ...DropdownShops_queryResult_2VV6jB\n}\n\nfragment DropdownShops_queryResult_2VV6jB on Query {\n  shops(input: $input) {\n    shops {\n      logo_url\n      name\n      ref_id\n      id\n    }\n    page_info {\n      items_count\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DropdownShopsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "DropdownShops_queryResult",
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DropdownShopsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "shops",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "ShopQueryInput"
          }
        ],
        "concreteType": "PaginatedShops",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shops",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "logo_url",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "ref_id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "page_info",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "items_count",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '300a95c0132dcc34f14f72bba785f66a';
module.exports = node;

const forgetEmailVerify = {
  En: {
    headerTitle: 'Verify Your Account',
    subTitle: ' Necessary step to activate your account',
    SMSTxt: ' By SMS  ',
    EmailTxt: 'By Email  ',
    VerifyParagraph:
      ' Activate your account by one of these methods and enjoy shopping with us',
    VerifyEmailTxt:
      'You will receive an email with a verification code.',
    VerifySMSTxt:
      'You will receive a message on your mobile with a verification code.',
  },
  Ar: {
    headerTitle: 'التحقق من حسابك',
    subTitle: ' خطوة ضرورية لتفعيل حسابك',
    SMSTxt: ' بالرسائل القصيرة ',
    EmailTxt: 'بالبريد الإلكتروني ',
    VerifyParagraph:
      ' قم بتفعيل حسابك بإحدى هذه الطرق واستمتع بالتسوق معنا',
    VerifyEmailTxt:
      'ستصلك رسالة بريد إلكتروني مع رمز التحقق.',
    VerifySMSTxt:
      'ستصلك رسالة على هاتفك الجوال مع رمز التحقق.',
  },
};
export default forgetEmailVerify;

/* eslint-disable no-underscore-dangle */
export default function extractSocialMediaData(dataObject) {
  const mutationVariables = {};
  mutationVariables.pid = dataObject._profile.pid || dataObject._profile.id;
  mutationVariables.secret = dataObject._profile.secret;
  mutationVariables.email = dataObject._profile.email;
  mutationVariables.token = dataObject._token.idToken || dataObject._token.accessToken;
  mutationVariables.provider = dataObject._provider;

  mutationVariables.firstName = dataObject._profile.firstName;
  mutationVariables.lastName = dataObject._profile.lastName;

  return mutationVariables;
}

import React from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import styled from 'react-emotion';
import Media from 'react-media';
// TODO : this should be moved to Header.js as part of Mobile Menu handling
import { mediaQueryMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import HeaderDesktop from './HeaderDesktop';
import { HeaderContent } from './HeaderMobile';

const ResponsiveHeaderContent = styled.div`
  position: relative;
`;

class TwoLevelsHeader extends React.PureComponent {
  generateMenuItemsKeys = (menuInfo) => {
    const AllItemsGroups = [
      menuInfo.desktop.top.left,
      menuInfo.desktop.top.center,
      menuInfo.desktop.top.right,

      menuInfo.desktop.bottom.left,
      menuInfo.desktop.bottom.center,
      menuInfo.desktop.bottom.right,

      menuInfo.mobile.top.left,
      menuInfo.mobile.top.center,
      menuInfo.mobile.top.right,

      menuInfo.mobile.bottom.left,
      menuInfo.mobile.bottom.center,
      menuInfo.mobile.bottom.right,
    ];

    AllItemsGroups.forEach((menuSpecs) => {
      if (!menuSpecs) {
        return;
      }
      menuSpecs.forEach((item) => {
        if (item.key) return;
        // eslint-disable-next-line no-param-reassign
        item.key = cuid();
      });
    });
  };

  render() {
    const { menuInfo, desktopTopHeaderStyles, desktopBottomHeaderStyles } = this.props;
    this.generateMenuItemsKeys(menuInfo);

    return (
      <Media query={mediaQueryMax('tablet')}>
        {matches => (matches ? (
          <ResponsiveHeaderContent>
            <HeaderContent />
          </ResponsiveHeaderContent>
        ) : (
          <div>
            <HeaderDesktop
              menuInfo={menuInfo.desktop}
              topHeaderStyles={desktopTopHeaderStyles}
              bottomHeaderStyles={desktopBottomHeaderStyles}
            />
          </div>
        ))
        }
      </Media>
    );
  }
}

TwoLevelsHeader.propTypes = PropTypes.shape({
  desktopTopHeaderStyles: PropTypes.string,
  desktopBottomHeaderStyles: PropTypes.string,
  menuInfo: PropTypes.shape({
    desktop: PropTypes.shape({
      ...HeaderDesktop.MenuInfoPropTypes,
    }).isRequired,
    mobile: PropTypes.shape({
      // TODO : Change this when a mobile version is ready
      ...HeaderContent.MenuInfoPropTypes,
    }),
  }).isRequired,
}).isRequired;

export default TwoLevelsHeader;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const BannerWrapper = styled.div`
  width: 100%;
  height: 150px;
  position: relative;

  ${cssMediaMax.mobile`
    height: 100px;
  `} ${props => props.customStyle && props.customStyle(props)};
`;

const BannerImg = styled.img`
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: ${props => (props.isRounded ? props.theme.borders.radius.normal : 0)}px;
  object-fit: cover;
  box-shadow: 0px 1px 5px 0 ${props => props.theme.borders.color.light};
`;

const ImageBanner = ({
  imgSrc,
  imgName,
  customStyle,
  isRounded,
}) => (
  <React.Fragment>
    <BannerWrapper customStyle={customStyle}>
      <BannerImg src={imgSrc} isRounded={isRounded} alt={imgName} />
    </BannerWrapper>
  </React.Fragment>
);

ImageBanner.defaultProps = {
  imgName: '',
  customStyle: null,
  isRounded: true,
};

ImageBanner.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgName: PropTypes.string,
  customStyle: PropTypes.func,
  isRounded: PropTypes.bool,
};

export default ImageBanner;

/* eslint-disable no-unused-vars */
import React from 'react';
import i18next from 'i18next';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import _ from 'lodash';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { getInvitationCodeFromLocalStorage } from '~/modules/ecommerceOrder/containers/inviteFriend/InviteFriendLocalStorageUtils';
import { currentLocale } from '~/modules/ecommerceCore/utils/changeLanguage';
import { currentCurrency } from '~/modules/ecommerceCore/utils/changeCurrency';

const flattenAndParseCountryFieldValue = formVariables => ({ // eslint-disable-line import/prefer-default-export
  ..._.omit(formVariables, ['country']),
  nationality: formVariables.country.country_id,
});
const FormMutation = graphql`
  mutation SignupFormMutation(
    $email: String!
    $password: String!
    $first_name: String!
    $last_name: String!
    $nationality: String!
    $mobile_number: String!
    $gender: String!
    $newsletter_subscription: Boolean
    $referrer_unique_code: String
    $default_lang: String
    $default_currency: String
  ) {
    create_user(
      email: $email
      password: $password
      first_name: $first_name
      last_name: $last_name
      nationality: $nationality
      mobile_number: $mobile_number
      gender: $gender
      newsletter_subscription: $newsletter_subscription
      referrer_unique_code: $referrer_unique_code
      default_lang: $default_lang
      default_currency: $default_currency
    ) {
      token
      client_id
      expiry
      user {
        ...WithUserData_user @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;

class SignupForm extends React.Component {
  componentDidMount = () => {
    this.props.onRef(this);
  };

  submitForm = () => {
    this.form.submitForm();
  };

  render = () => {
    const {
      customLayout,
      customInputsContainer,
      onFormError,
      onFormSuccess,
      onFormLoading,
      userInfo,
      getSocialMediaData,
    } = this.props;

    let defaultCountry = '974';
    if (userInfo && userInfo.settings) {
      defaultCountry = userInfo.settings.current_location_country_code;
    }

    const SMData = getSocialMediaData();
    let email;
    let firstName;
    let lastName;

    if (SMData) {
      email = SMData.email || email;
      firstName = SMData.firstName || firstName;
      lastName = SMData.lastName || lastName;
    }

    const fieldsArray = [
      {
        name: 'first_name',
        placeholder: i18next.t('accountManagement:SignUp.FirstName'),
        displayName: i18next.t('accountManagement:SignUp.FirstName'),
        input_type: 'textbox',
        type: 'RequiredString',
      },
      {
        name: 'last_name',
        placeholder: i18next.t('accountManagement:SignUp.LastName'),
        displayName: i18next.t('accountManagement:SignUp.LastName'),
        input_type: 'textbox',
        type: 'RequiredString',
      },
      {
        name: 'email',
        placeholder: i18next.t('accountManagement:Profile.email'),
        displayName: i18next.t('accountManagement:Profile.email'),
        input_type: 'textbox',
        type: 'RequiredString',
      },
      {
        name: 'password',
        displayName: i18next.t('accountManagement:Login.password'),
        placeholder: i18next.t('accountManagement:Login.password'),
        input_type: 'password',
        type: 'RequiredString',
      },
      {
        name: 'country',
        displayName: i18next.t('accountManagement:SignUp.Nationality'),
        placeholder: i18next.t('accountManagement:SignUp.Nationality'),
        input_type: 'country',
        type: 'RequiredCountry',
      },
      {
        name: 'mobile_number',
        displayName: i18next.t('accountManagement:SignUp.Mobile'),
        placeholder: i18next.t('accountManagement:SignUp.Mobile'),
        input_type: 'phoneNumber',
        type: 'RequiredNumber',
      },
      {
        name: 'gender',
        displayName: i18next.t('accountManagement:Profile.gender'),
        input_type: 'gender',
        type: 'RequiredEnum',
      },
      {
        name: 'newsletter_subscription',
        label: i18next.t('accountManagement:SignUp.RegisterFornewsLetter'),
        importantLabel: true,
        checkboxNote: i18next.t('accountManagement:SignUp.AfterRegisteringyouwillrecieveanemail'),
        input_type: 'checkbox',
        type: 'Boolean',
      },
    ];

    return (
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={onFormError}
        onFormSuccess={onFormSuccess}
        onFormLoading={onFormLoading}
        mutationRoot="create_user"
        options={{
          customLayout,
          customInputsContainer,
          initialFormValue: {
            newsletter_subscription: true,
            mobile_number: `(+${defaultCountry})`,
            email: email || '',
            first_name: firstName || '',
            last_name: lastName || '',
          },
          fields: fieldsArray,
        }}
        mutation={FormMutation}
        getSubmissionVariables={formVariables => ({
          ...flattenAndParseCountryFieldValue(formVariables),
          referrer_unique_code: getInvitationCodeFromLocalStorage(),
          default_lang: currentLocale(),
          default_currency: currentCurrency(),
        })}
      />
    );
  };
}

SignupForm.propTypes = PropTypes.shape({
  onFormError: PropTypes.func.isRequired,
  onFormSuccess: PropTypes.func.isRequired,
  onFormLoading: PropTypes.func.isRequired,
}).isRequired;

export default withUserInfo(SignupForm);

/**
 * @flow
 * @relayHash da7a6e1f971af393d65de86aca2ea6fa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CountriesDropdownQueryVariables = {||};
export type CountriesDropdownQueryResponse = {|
  +list_countries: $ReadOnlyArray<?{|
    +iso3: ?string,
    +name: ?string,
    +ref_id: string,
    +flag: ?string,
    +phone_code: ?string,
    +accept_delivery_and_shipping: ?boolean,
  |}>
|};
export type CountriesDropdownQuery = {|
  variables: CountriesDropdownQueryVariables,
  response: CountriesDropdownQueryResponse,
|};
*/


/*
query CountriesDropdownQuery {
  list_countries {
    iso3
    name
    ref_id
    flag
    phone_code
    accept_delivery_and_shipping
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "iso3",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "flag",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone_code",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accept_delivery_and_shipping",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CountriesDropdownQuery",
  "id": null,
  "text": "query CountriesDropdownQuery {\n  list_countries {\n    iso3\n    name\n    ref_id\n    flag\n    phone_code\n    accept_delivery_and_shipping\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CountriesDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list_countries",
        "storageKey": null,
        "args": null,
        "concreteType": "Country",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CountriesDropdownQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "list_countries",
        "storageKey": null,
        "args": null,
        "concreteType": "Country",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6499b18343bd66fbfe082d97d5437f6';
module.exports = node;

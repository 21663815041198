/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import NavSlider from './NavSlider';
import MainSlider from './MainSlider';

const DoubleSlickSliderWrapper = styled.div`
  width: 100%;
`;
export default class DoubleSlickSlider extends Component {
  state = {
    activeSlide: 0,
  }

  render = () => {
    const {
      mainSliderChildren,
      navSliderChildren,
      childrenCount,
      hideNavSlider,
      isVertical,
    } = this.props;
    return (
      <DoubleSlickSliderWrapper>
        <MainSlider
          isVertical={isVertical}
          innerRef={(slider) => {
            this.slider1 = slider;
          }}
          afterChange={(current) => {
            if (!hideNavSlider) {
              this.setState({ activeSlide: current }, () => {
                this.slider2.slickGoTo(this.state.activeSlide);
              });
            }
          }}
        >
          {mainSliderChildren}
        </MainSlider>
        <React.Fragment>
          {childrenCount > 1 && (
            <React.Fragment>
              <NavSlider
                childrenCount={childrenCount}
                innerRef={(slider) => {
                  this.slider2 = slider;
                }}
                afterChange={(current) => {
                  this.setState({ activeSlide: current }, () => {
                    this.slider1.slickGoTo(this.state.activeSlide);
                  });
                }}
                activeSlide={this.state.activeSlide}
                onClick={
                  (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }
              >
                {navSliderChildren}
              </NavSlider>
            </React.Fragment>
          )}
        </React.Fragment>
      </DoubleSlickSliderWrapper>
    );
  }
}

DoubleSlickSlider.propTypes = {
  mainSliderChildren: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  navSliderChildren: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  childrenCount: PropTypes.number.isRequired,
  hideNavSlider: PropTypes.bool,
  isVertical: PropTypes.bool,
};

DoubleSlickSlider.defaultProps = {
  hideNavSlider: false,
  isVertical: true,
};

import {
  graphql,
} from 'relay-runtime';

const UpdateUserInfoMutation = graphql`
  mutation EditUserInfoMutation($input: UserInfoQuery) {
    update_user_info(input: $input) {
      result {
        ...UserInfoFragment @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;


export default UpdateUserInfoMutation;

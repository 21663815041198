import { graphql } from 'react-relay';
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import RecommendedSlider from '~/modules/ecommerceHome/components/recommended/RecommendedSlider';
import RecommendedProductCard from '~/modules/ecommerceHome/components/recommended/RecommendedProductCard';
import AdRecommended from '~/modules/ecommerceCoreUI/components/advertisement/AdRecommended';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const SliderContainer = styled.div`
  width: 80%;
  ${cssMediaMax.desktop`
    width: 75%;
  `}
  ${cssMediaMax.mobile`
    width: 50%;
  `}
  ${cssMediaMax.xsmall`
    width: 100%;
  `}
`;

const PinnedProduct = styled.div`
  width: 20%;
  margin: 24px 0 !important;
  ${cssMediaMax.desktop`
    width: 25%;
  `}
  ${cssMediaMax.mobile`
    width: 50%;
    margin: 24px auto !important;
  `}
  ${cssMediaMax.xsmall`
    width: 100%;
  `}
  .ui.segment{
    border: none;
    background: transparent;

    ${props => cssMediaMax.mobile`
      margin: 0px ${props.theme.paddings.large}px !important;
  `}
  }
`;
const MainWrapper = styled(Row)`
  width: 100%;
  ${cssMediaMax.xsmall`
    flex-direction: column;
  `}
`;

class RecommendedProduct extends React.PureComponent {
  render = () => {
    const { queryResult } = this.props;
    return (
      <MainWrapper spaceBetween={1} topAligned>
        {queryResult.recommended_products.higlighted.length > 0 && (
          <PinnedProduct>
            <AdRecommended AdTrack="products" />
          </PinnedProduct>
        )}

        <SliderContainer>
          <RecommendedSlider>
            {queryResult
              && queryResult.recommended_products.result.length > 0
              && queryResult.recommended_products.result.map(entry => (
                <RecommendedProductCard
                  isVertical
                  // eslint-disable-next-line no-underscore-dangle
                  key={entry.__id}
                  productInfo={entry}
                />
              ))}
          </RecommendedSlider>
        </SliderContainer>
      </MainWrapper>
    );
  }
}

RecommendedProduct.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
};

export default withRootQuery(
  RecommendedProduct,
  graphql`
    query RecommendedProductQuery {
      recommended_products {
        higlighted {
          ...RecommendedProductCard_productInfo
        }
        result {
          ...RecommendedProductCard_productInfo
        }
      }
    }
  `,
);

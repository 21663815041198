
/* eslint-env browser */
/* eslint-disable */
import i18next from 'i18next';
import { InwaniFormElement, InwaniDataType } from '~/modules/ecommerceCoreUI/components/addresses/inwani/InwaniFormElement';
import { GoogleMapFormElementTemplate, GoogleMapDataType } from '~/modules/ecommerceCoreUI/components/addresses/map/GoogleMapFormElement';
import { prop, props, type } from 'sanctuary';
import getCityStatus from '~/statusRequired';

const doesInwaniHasAnyValues = inwani => inwani.inwani_street && inwani.inwani_zone && inwani.inwani_building;
const isQatar = formValues => formValues.country.country_code === '974';

const getSaveAsDefaultField = isFirstTime => (!isFirstTime ? ([{
  name: 'save_as_default',
  label: i18next.t('ecommerceOrder:Checkout.saveAsDefault'),
  input_type: 'checkbox',
  type: 'Boolean',
  semiBoldLabel: true,
  showAsToggle: true,
  isDisabled: locals => locals.context.initialFormValue.save_as_default, // We need to disable the button, if this is the default address in the db
  onDisabledClickMessage: i18next.t('ecommerceOrder:Checkout.setAsDefaultChangeText'),
}]) : (
  []
));


export default (props, isFirstTime, status_required) => ([
  {
    name: 'firstname',
    displayName: i18next.t('ecommerceOrder:Checkout.FirstName'),
    placeholder: i18next.t('ecommerceOrder:Checkout.FirstName'),
    input_type: 'textbox',
    type: 'RequiredString',
  },
  {
    name: 'lastname',
    displayName: i18next.t('ecommerceOrder:Checkout.LastName'),
    placeholder: i18next.t('ecommerceOrder:Checkout.LastName'),
    input_type: 'textbox',
    type: 'RequiredString',
  },
  {
    name: 'phone',
    placeholder: i18next.t('ecommerceOrder:Checkout.MobileNumber'),
    input_type: 'phoneNumber',
    type: 'RequiredNumber',
  },
  {
    name: 'country',
    placeholder: i18next.t('accountManagement:Profile.country_name'),
    displayName: i18next.t('accountManagement:Profile.country_name'),
    input_type: 'country',
    type: 'OptionalState',
    deliverableOnly: true,
  },
  {
    name: 'state',
    displayName: i18next.t('ecommerceOrder:Checkout.City'),
    placeholder: i18next.t('ecommerceOrder:Checkout.City'),
    input_type: 'city',
    type: (status_required) ? 'RequiredState' : 'OptionalState',
    getUpdatesOnFormValueChange: (value, path) => (path && path[0] === 'country'
      ? ({ // reset the selected city (Will cause cities to reload)
        $set: { state_id: null, country_id: value.country.country_id },
      }) : (
        null // no-updates
      )
    ),
  },
  {
    name: 'inwani',
    displayName: i18next.t('ecommerceOrder:Checkout.inwani'),
    factory: InwaniFormElement,
    type: InwaniDataType,
    supportAsyncValidation: true,
    validate: (formValues) => {
      if (isQatar(formValues)) {
        if (!doesInwaniHasAnyValues(formValues.inwani)) {
          const INWANI_ERROR_MESSAGE = (props.direction === 'rtl' ? 'عنوانى به حقول مفقودة' : 'Inwani has missing fields');

          return INWANI_ERROR_MESSAGE;
        }
      }
      return null;
    },
    isVisible: locals => locals.context.currentFormValues.country.country_code === '974',
  },
  {
    name: 'map',
    template: GoogleMapFormElementTemplate,
    type: GoogleMapDataType,
    autoDetectLocation: !props.isEditingMode,
    getUpdatesOnFormValueChange: (value, path) => {
      if (path && path[0] === 'inwani' && value.inwani.lat && value.inwani.lng) {
        return {
          $set: {
            lat: parseFloat(value.inwani.lat),
            lng: parseFloat(value.inwani.lng),
          },
        };
      }
      return null;
    },
  },
  {
    name: 'address1',
    displayName: i18next.t('ecommerceOrder:Checkout.Address'),
    placeholder: i18next.t('ecommerceOrder:Checkout.Address'),
    input_type: 'textbox',
    type: 'RequiredStringWithMinimum',
    selectedValue: props.address1,
    getUpdatesOnFormValueChange: (value, path) => {
      if (path && path[0] === 'inwani' && value.inwani.address1) {
        return { $set: value.inwani.address1 };
      } else if (path && path[0] === 'map' && value.map.address1) { // eslint-disable-line no-else-return
        return { $set: value.map.address1 };
      }
      return null;
    },
  },
  ...getSaveAsDefaultField(isFirstTime),
]);

/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import PropTypes from 'prop-types';
import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import Button from '~/modules/coreUI/components/basic/Button';

import EmptyStatePage from '~/modules/coreUI/components/layouts/emptyStatePage';
import LongMessagesError from '~/modules/ecommerceOrder/containers/shoppingCart/layouts/areas/LongMessagesError';

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import LayoutMobile from '~/modules/ecommerceOrder/containers/shoppingCart/layouts/LayoutMobile';
import LayoutDesktop from '~/modules/ecommerceOrder/containers/shoppingCart/layouts/LayoutDesktop';
import LayoutDropdown from '~/modules/ecommerceOrder/containers/shoppingCart/layouts/LayoutDropdown';

import CartItemsTable from '~/modules/ecommerceOrder/containers/shoppingCart/layouts/areas/CartItemsTable';
import CartPageHeader from '~/modules/ecommerceOrder/containers/shoppingCart/layouts/areas/CartPageHeader';

import Loader from '~/modules/coreUI/components/basic/Loader';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import CartBreadcrumbs from '~/modules/ecommerceCoreUI/components/breadcrumbs/CartBreadcrumbs';

import processErrors from './utils/ErrorsProcessor';

const ShoppingCart = (props) => {
  const {
    orders,
    quantity,
    raw_amount,
  } = props.shoppingCart || {};

  const {
    shoppingCartErrors,
    history,
    saveCurrentPath,
  } = props;

  let lineItems = orders
    && _.reverse(
      _.sortBy(_.flatten(orders.map(order => order.line_items)), [
        order => new Date(order.created_at),
      ]),
    );

  lineItems = lineItems || [];

  let warnings = [];

  if (shoppingCartErrors) {
    warnings = processErrors(shoppingCartErrors, history, saveCurrentPath);
    warnings = _.flatten(warnings);
  }

  warnings = [...warnings, ..._.flatten(lineItems.map(lineItem => lineItem.warnings))];

  // eslint-disable-next-line no-nested-ternary
  const Layout = props.isDropdown
    ? LayoutDropdown
    : props.media.minDesktop
      ? LayoutDesktop
      : LayoutMobile;

  const hasItems = lineItems && lineItems.length > 0;

  return (
    <Fragment>
      {props.isDropdown ? null : <CartBreadcrumbs />}
      <Layout
        cartTotal={raw_amount || 0.0}
        header={(
          <CartPageHeader
            isDropdown={props.isDropdown}
            title={i18next.t('ecommerceOrder:Cart.title')}
            subtitle={`${
              quantity || quantity === 0
                ? i18next.t('ecommerceOrder:Cart.quantity', { quantity })
                : i18next.t('ecommerceOrder:Cart.loading')
            }`}
          />
  )}
        errorsAlert={
          warnings && warnings.length > 0 ? (
            <LongMessagesError
              title={i18next.t('ecommerceOrder:Cart.cartUpdated')}
              warnings={warnings}
            />
          ) : null
        }
        cartItemsTable={
          hasItems ? (
            <CartItemsTable lineItems={lineItems} cartTotal={raw_amount} />
          ) : (
            <Column fullWidth fullHeight centerJustified>
              <Loader />
            </Column>
          )
        }
        emptyStatePage={
          quantity === 0 ? (
            <EmptyStatePage
              imagePath={`/${imagesPathName}/empty-bag.png`}
              contentHeader={i18next.t('ecommerceOrder:Cart.emptyHeader')}
              content={i18next.t('ecommerceOrder:Cart.emptyContent')}
            />
          ) : null
        }
        continueShoppingButton={(
          <ExternalLink url={urlFor('getProductTracksLink')}>
            <Button xl secondary>
              {i18next.t('ecommerceOrder:Cart.continueShopping')}
            </Button>
          </ExternalLink>
        )}
        dataLoaded={hasItems}
      />
    </Fragment>
  );
};

ShoppingCart.defaultProps = {
  isDropdown: false,
  shoppingCartErrors: [],
};

ShoppingCart.propTypes = {
  shoppingCart: PropTypes.objectOf(PropTypes.any),
  shoppingCartErrors: PropTypes.arrayOf(PropTypes.any),
  media: PropTypes.shape({
    minDesktop: PropTypes.bool.isRequired,
  }).isRequired,
  isDropdown: PropTypes.bool,
  saveCurrentPath: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ShoppingCart.defaultProps = {
  shoppingCart: {},
};

export default withRouter(withUserInfo(withCartInfo(withMedia(ShoppingCart))));

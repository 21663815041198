/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

export const pushToCompare = (props, onError = () => {}, redirect = true) => {
  if (localStorage.getItem('compare')) {
    const compare = JSON.parse(localStorage.getItem('compare'));
    if (compare.length < 4) {
      if (!compare.includes(props.variantID)) {
        compare.push(props.variantID);
        localStorage.setItem('compare', JSON.stringify(_.takeRight(compare, 4)));
      }
    } else {
      onError();
    }
  } else {
    localStorage.setItem('compare', JSON.stringify([props.variantID]));
  }
  if (redirect) {
    props.history.push('/compare');
  }
};

export const removeCompareItem = (props) => {
  const compare = JSON.parse(localStorage.getItem('compare')).filter(
    variant => variant !== props.variantID,
  );
  localStorage.setItem('compare', JSON.stringify(compare));
};

/* eslint-disable camelcase */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import Blanket from '~/modules/coreUI/components/layouts/BlanketBackground';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';


const Wrapper = styled(Column)`
  position: absolute;
  height: ${props => 40 * props.theme.new.spacer}px;
  width: ${props => 40 * props.theme.new.spacer}px;
  z-index: 2;
  top: ${props => 4 * props.theme.new.spacer}px;
  background-color: white;
  box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
  border: 1px solid rgba(34,36,38,.15);
  border-radius: ${props => 0.3 * props.theme.new.spacer}px;

  &&::after{
    width: ${props => props.theme.new.spacer}px;
    height: ${props => props.theme.new.spacer}px;
    display: block;
    position: absolute;
    z-index: 2;
    top: ${props => -0.4 * props.theme.new.spacer}px;
    background: #fff;
    pointer-events: none;
    visibility: visible;
    transform: rotate(45deg);
    box-shadow: -1px -1px 0 0 rgba(34,36,38,.15);
    content: '';
  }
`;

const DropdownBody = styled.div`
  width: 100%;
  height: ${props => 3.5 * props.theme.new.spacer}px;
  background-color: white;
`;

const CustomDropdown = props => (
  <React.Fragment>
    <Blanket onClick={props.showMenu} />
    <Wrapper fullWidth>
      <DropdownBody>
        {props.dropdownContent}
      </DropdownBody>
    </Wrapper>
  </React.Fragment>
);

CustomDropdown.defaultProps = {
  queryResult: {},
};

CustomDropdown.propTypes = {
  queryResult: PropTypes.shape({
    thread_list: PropTypes.shape({}),
  }),
  showMenu: PropTypes.func.isRequired,
  dropdownContent: PropTypes.element.isRequired,
};

export default CustomDropdown;

import React from 'react';
import { graphql } from 'relay-runtime';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import objectsDeepNotEqualComparison from '~/modules/core/utils/jsHelpers/objectsDeepComparison';

import WithCartData from '~/modules/ecommerceOrder/containers/shoppingCart/dataContainers/WithCartData';

import withCartInfo from './withCartInfo';
import CartAssignUserMutation from './CartAssignUserMutation';

class CartInfoLoader extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.cart) {
      return null;
    }

    const currentCart = prevState.prevCart;
    const newCart = nextProps.cart;
    const newCartReceived = objectsDeepNotEqualComparison(currentCart, newCart);

    const currentGuestTokens = prevState.prevGuestTokens;
    const newGustTokens = newCart.orders.map(order => order.guest_token);
    const newGuestTokensReceived = objectsDeepNotEqualComparison(currentGuestTokens, newGustTokens);


    if (newCartReceived || newGuestTokensReceived) {
      nextProps.setCartManagementPartial({
        shoppingCart: newCart,
        shoppingCartGuestTokens: newGustTokens,
      });
      return {
        prevCart: newCart,
        prevGuestTokens: newGustTokens,
      };
    }

    return null;
  }

  state = {
    prevCart: null, // eslint-disable-line
  };

  render = () => (
    <React.Fragment>
      <CartAssignUserMutation />
      {this.props.children}
    </React.Fragment>
  );
}

CartInfoLoader.propTypes = {
  children: PropTypes.element.isRequired,
};

const CartInfoWithDataWrapper = WithCartData(CartInfoLoader);

// eslint-disable-next-line react/prop-types
const QueryDataExtractor = ({ queryResult, ...rest }) => (
  <CartInfoWithDataWrapper cart={queryResult && queryResult.cart} {...rest} />
);

// NOTE : withRouter here is mandatory. Since without it, the updates on
//        URL changes will be blocked all the way down
export default withRouter(
  withCartInfo(
    withRootQuery(
      QueryDataExtractor,
      graphql`
        query CartInfoLoaderQuery($guest_tokens: [String]) {
          cart(guest_tokens: $guest_tokens) {
            ...WithCartData_cart
          }
        }
      `,
      QueryDataExtractor,
      props => ({
        guest_tokens: props.shoppingCartGuestTokens,
      }),
      { force: true },
    ),
  ),
);

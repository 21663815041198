/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import PromoPanel from './PromoPanel';
import EmptyPromos from './EmptyPromos';

const MyPromos = ({ queryResult }) => (
  <Column spaceBetween={2}>
    {queryResult?.current_user_details?.current_user?.promotions.map(promo => (
      <PromoPanel key={promo.ref_id} promo={promo} />
    ))}
    {queryResult?.current_user_details?.current_user?.promotions.length === 0 ? <EmptyPromos /> : null}
  </Column>
);

const MyPromosQuery = graphql`
  query MyPromosQuery {
    current_user_details{
      current_user {
        ref_id
        promotions {
          ref_id
          name
          code
          description
          expires_at
          starts_at
          display_name
          actions
          vendor {
            ref_id
            name
          }
        }
      }
      errors {
        field
        messages
        code
      }
  }
  }
`;

MyPromos.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
};

export default withRootQuery(MyPromos, MyPromosQuery);

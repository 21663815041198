import React from 'react';
import { withRouter } from 'react-router-dom';
import { translate, I18nContext } from 'react-i18next';
import Media from 'react-media';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import EcommerceMainContainer from '~/modules/ecommerceCore/components/EcommerceMainContainer';
import AccountManagementModals from '~/modules/accountManagement/containers/AccountManagementModals';
import ModalRoute from '~/modules/core/components/Modal/ModalRoute';
import ModalTrackerProvider from '~/modules/core/utils/modalHelpers/ModalTrackerProvider';
import { mediaQueryMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withModalTracker from '~/modules/core/utils/modalHelpers/withModalTracker';
import PagesLayout from './PagesLayout';

const ResponsiveRoot = withModalTracker(withDirection(PagesLayout));

class Layout extends React.Component {
  componentDidMount = () => {
    this.i18nextProviderValue = { i18n: i18next };
  }

  render = () => {
    const { i18n } = this.props;
    return (
      <EcommerceMainContainer>
        <I18nContext.Provider value={this.i18nextProviderValue}>
          <ModalTrackerProvider>
            <Media query={mediaQueryMax('tablet')}>
              {isTabletOrMobile => <ResponsiveRoot isTabletOrMobile={isTabletOrMobile} i18n={i18n} />}
            </Media>
            <ModalRoute path="/accountManagement/" component={AccountManagementModals} />
          </ModalTrackerProvider>
        </I18nContext.Provider>
      </EcommerceMainContainer>
    );
  }
}

Layout.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
};

export default translate('eCommerceCore')(translate('eCommerceCoreUI')(withRouter(Layout)));

/**
 * @flow
 * @relayHash df7d1a2ccb7b1daaa1902223fe6db238
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FollowingListQueryVariables = {||};
export type FollowingListQueryResponse = {|
  +current_user_details: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
    +current_user: ?{|
      +followed_shops: ?$ReadOnlyArray<?{|
        +id: string,
        +logo_url: ?string,
        +ref_id: string,
        +name: ?string,
        +rating_count: ?number,
        +avg_rating: ?number,
        +follow: ?boolean,
        +followed: ?boolean,
        +track: ?string,
        +city: ?{|
          +name: ?string
        |},
        +country: ?{|
          +name: ?string
        |},
        +featured: ?boolean,
        +departments: ?$ReadOnlyArray<?{|
          +id: string,
          +name: string,
        |}>,
        +track_sub_departments: ?$ReadOnlyArray<?{|
          +id: string,
          +name: string,
          +track_department_id: string,
          +ref_id: string,
        |}>,
      |}>
    |},
  |}
|};
export type FollowingListQuery = {|
  variables: FollowingListQueryVariables,
  response: FollowingListQueryResponse,
|};
*/


/*
query FollowingListQuery {
  current_user_details {
    errors {
      field
      messages
      code
    }
    current_user {
      followed_shops {
        id
        logo_url
        ref_id
        name
        rating_count
        avg_rating
        follow
        followed
        track
        city {
          name
          id
        }
        country {
          name
          id
        }
        featured
        departments {
          id
          name
        }
        track_sub_departments {
          id
          name
          track_department_id
          ref_id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    }
  ]
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "followed",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "follow",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logo_url",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "track",
  "args": null,
  "storageKey": null
},
v10 = [
  v4
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "featured",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "departments",
  "storageKey": null,
  "args": null,
  "concreteType": "TrackDepartment",
  "plural": true,
  "selections": [
    v2,
    v4
  ]
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "track_sub_departments",
  "storageKey": null,
  "args": null,
  "concreteType": "TrackSubDepartment",
  "plural": true,
  "selections": [
    v2,
    v4,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "track_department_id",
      "args": null,
      "storageKey": null
    },
    v3
  ]
},
v14 = [
  v4,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "FollowingListQuery",
  "id": null,
  "text": "query FollowingListQuery {\n  current_user_details {\n    errors {\n      field\n      messages\n      code\n    }\n    current_user {\n      followed_shops {\n        id\n        logo_url\n        ref_id\n        name\n        rating_count\n        avg_rating\n        follow\n        followed\n        track\n        city {\n          name\n          id\n        }\n        country {\n          name\n          id\n        }\n        featured\n        departments {\n          id\n          name\n        }\n        track_sub_departments {\n          id\n          name\n          track_department_id\n          ref_id\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FollowingListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "current_user_details",
        "storageKey": null,
        "args": null,
        "concreteType": "UserDetails",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "followed_shops",
                "storageKey": null,
                "args": null,
                "concreteType": "Shop",
                "plural": true,
                "selections": [
                  v1,
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "city",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "State",
                    "plural": false,
                    "selections": v10
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "country",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Country",
                    "plural": false,
                    "selections": v10
                  },
                  v11,
                  v12,
                  v13
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FollowingListQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "current_user_details",
        "storageKey": null,
        "args": null,
        "concreteType": "UserDetails",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "followed_shops",
                "storageKey": null,
                "args": null,
                "concreteType": "Shop",
                "plural": true,
                "selections": [
                  v1,
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "city",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "State",
                    "plural": false,
                    "selections": v14
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "country",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Country",
                    "plural": false,
                    "selections": v14
                  },
                  v11,
                  v12,
                  v13
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '97602f0c7c2cfc821c2f2b8002a750f5';
module.exports = node;

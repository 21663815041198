import React from 'react';
import t, { maybe } from 'tcomb-form';
import _ from 'lodash';

import Inwani from './Inwani';

import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { getGlobalAttrs } from '~/modules/coreUI/components/forms/Template';
import renderError from '~/modules/coreUI/components/forms/Errors';

/* eslint-disable react/no-this-in-sfc */
export class InwaniFormElement extends t.form.Textbox {
  asyncValidate = formValues => this.inwani.validate(formValues);

  getTemplate = () => locals => (
    <Column fullWidth>
      <Inwani
        onRef={(ref) => { this.inwani = ref; }}
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        editable
        onFindInwaniFailed={error => locals.context.onAsyncValidationError('inwani', error)}
        onChange={locals.onChange}
        value={locals.value}
      />
      {renderError(locals)}
    </Column>
  )
}

/* eslint-enable react/no-this-in-sfc */
export const InwaniDataType = maybe(t.struct({
  inwani_street: maybe(t.String),
  inwani_zone: maybe(t.String),
  inwani_building: maybe(t.String),
}));

const pickBasicInwaniFields = inwaniValues => _.pick(inwaniValues, ['inwani_street', 'inwani_zone', 'inwani_building']);
const parseInwaniFields = inwaniValues => _.mapValues(inwaniValues, value => parseInt(value, 10));

export const flattenAndParseInwaniFieldValue = formVariables => ({
  ..._.omit(formVariables, 'inwani'),
  ...parseInwaniFields(pickBasicInwaniFields(formVariables.inwani)),
});

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import styled from 'react-emotion';
import i18next from 'i18next';

import Button from '~/modules/coreUI/components/basic/Button';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import Follow from '~/modules/ecommerceCoreUI/components/productDetails/vendor/buttons/Follow';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';

import ItemOptionsRender from './cardItems/ItemOptionsRender';
import ItemImage from './cardItems/ItemImage';
import ItemRating from './cardItems/ItemRating';
import StockLabel from './cardItems/StockLabel';
import Title from './cardItems/Title';
import SubTitle from './cardItems/SubTitle';
import Location from './cardItems/Location';
import Actions from './cardItems/Actions';
import OriginalPrice from './cardItems/OriginalPrice';
import CurrentPrice from './cardItems/CurrentPrice';
import ItemOfferLabel from './cardItems/ItemOfferLabel';
import ItemStockAmount from './cardItems/ItemStockAmount';
import ItemImageSlider from './cardItems/ItemImageSlider';
import Summary from './cardItems/Summary';

import Layout from './layouts';
import ItemOfferTag, { hasOfferTag } from './cardItems/ItemOfferTag';

const CardMainWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.named.white};
  cursor: pointer;
  border-radius: 5px;
`;

const ItemCard = ({
  itemInfo,
  isVertical,
  isAddToCart,
  isHotDeal,
  isShop,
  isProduct,
  isVendorProduct,
  vendorInfo,
  isAd,
}) => {
  const itemVendorInfo = vendorInfo.vendorID ? {
    ref_id: vendorInfo.vendorID,
    name: vendorInfo.vendorName,
  } : itemInfo.min_variant?.vendor;

  const itemLink = isShop
    ? urlFor('getProductTracksLink', [null, itemInfo.ref_id, itemInfo.name])
    : urlFor('getProductDetailsLink', [
      itemInfo.min_variant.slug || itemInfo.slug,
      isVendorProduct ? 1 : itemInfo.vendor_variants_count,
      itemVendorInfo,
    ]);

  const ProductImageRender = itemInfo.images && itemInfo.images[0];
  const imageToDisplay = isShop ? itemInfo.logo_url : ProductImageRender;

  const actions = isShop ? (
    <Follow
      followed={itemInfo.followed}
      vendorId={itemInfo.ref_id}
    />
  ) : (
    <Actions
      itemInfo={itemInfo}
      isVertical={isVertical}
    />
  );

  const itemOptionsRender = (
    <ItemOptionsRender
      itemInfo={itemInfo}
    />
  );

  const summaryRender = (itemInfo.summary && itemInfo.has_offer) ? (
    <Summary
      itemInfo={itemInfo}
    />
  ) : (
    <span />
  );

  const itemImage = isHotDeal && itemInfo.images.length > 1 && isVertical ? (
    <ItemImageSlider itemInfo={itemInfo} isVertical={isVertical} />
  ) : (
    <ItemImage isAd={isAd} isVertical={isVertical} imgUrl={imageToDisplay} />
  );

  const titleRender = (
    <Title
      itemInfo={isProduct ? itemInfo.min_variant : itemInfo}
    />
  );

  const subTitleRender = isShop && (
    <SubTitle
      itemInfo={itemInfo}
    />
  );
  const locationRender = isShop && itemInfo.city && itemInfo.country && (
    <Location
      itemInfo={itemInfo}
    />
  );

  const originalPrice = ((itemInfo.has_discount || itemInfo.has_offer) && !!itemInfo.original_price) ? (
    <OriginalPrice
      itemInfo={isProduct ? itemInfo.min_variant : itemInfo}
    />
  ) : <span />;

  const currentPrice = !isShop ? (
    <CurrentPrice
      itemInfo={isProduct ? itemInfo.min_variant : itemInfo}
    />
  ) : <span />;

  const rating = (
    <ItemRating
      itemInfo={itemInfo}
    />
  );

  const offerLabel = isHotDeal ? (
    <ItemStockAmount
      itemInfo={isProduct ? itemInfo.min_variant : itemInfo}
    />
  ) : (
    <ItemOfferLabel
      itemInfo={itemInfo}
    />
  );

  const stockLabelRender = (
    <StockLabel
      itemInfo={isProduct ? itemInfo.min_variant : itemInfo}
    />
  );
  const itemOfferTag = hasOfferTag(itemInfo) && (
    <ItemOfferTag
      itemInfo={itemInfo}
      isHotDeal={isHotDeal}
      isProduct={isProduct}
      isShop={isShop}
    />
  );

  const addToCartButton = isAddToCart ? (
    <Button
      md
      block
      secondary
      semiBold
    >
      {i18next.t('ecommerceOrder:Cart.buyNow')}
    </Button>
  ) : <span />;

  const PreparedCard = (
    <CardMainWrapper isVertical={isVertical}>
      <ExternalLink url={itemLink}>
        <Layout
          isVertical={isVertical}
          tag={itemOfferTag}
          actions={actions}
          imageRenderer={itemImage}
          title={titleRender}
          subTitle={subTitleRender}
          location={locationRender}
          originalPrice={originalPrice}
          offerLabel={offerLabel}
          rating={rating}
          currentPrice={currentPrice}
          itemOptions={itemOptionsRender}
          stockLabel={stockLabelRender}
          addToCartButton={addToCartButton}
          Summary={summaryRender}
        />
      </ExternalLink>
    </CardMainWrapper>
  );
  return PreparedCard;
};

ItemCard.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
  isVertical: PropTypes.bool,
  isAddToCart: PropTypes.bool,
  isVendorProduct: PropTypes.bool,
  vendorInfo: PropTypes.shape({
    vendorID: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  isHotDeal: PropTypes.bool,
  isShop: PropTypes.bool,
  isProduct: PropTypes.bool,
};

ItemCard.defaultProps = {
  isVertical: true,
  isAddToCart: false,
  isVendorProduct: false,
  vendorInfo: {},
  isHotDeal: false,
  isShop: false,
  isProduct: false,
};

export default translate('ecommerceRecommended')(ItemCard);

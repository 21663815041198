import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import PanelHeader from '~/modules/coreUI/components/layouts/PanelHeader';

const Container = withMedia(styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.named.inverted};
  ${props => !props.borderLessHeader && css`
    border: 1px solid ${props.theme.borders.color.light};
    border-radius: ${props.theme.borders.radius.normal}px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  `};
  ${props => props.customStyle && props.customStyle(props)};
`);

const Header = withMedia(styled.div`
  padding: ${props => props.theme.paddings.large}px ${props => props.theme.paddings.xLarge}px;
  background-color: ${props => props.theme.colors.backgroundColor};
  border-radius: 5px 5px 0 0;

  ${props => props.customStyle && props.customStyle(props)};
`);

const collapseStyle = css`
  max-height: 0;
`;

const Body = withMedia(styled.div`
  padding: ${props => props.theme.new.spacer * 1.5}px;

  overflow: hidden;
  transition-property: max-height;
  transition-duration: 0.5s;

  ${props => props.customStyle && props.customStyle(props)};

  ${props => props.collapsed && collapseStyle};
`);

const Footer = withMedia(styled.div`
  padding-right: ${props => props.theme.new.spacer * 2.5}px;
  padding-left: ${props => props.theme.new.spacer * 2.5}px;
  padding-top: ${props => props.theme.new.spacer * 1.5}px;
  padding-bottom: ${props => props.theme.new.spacer * 1.5}px;

  ${props => props.media.xsmall
    && css`
      padding: ${props.theme.new.spacer * 1.5}px;
    `};

  ${props => props.customStyle && props.customStyle(props)};
`);

const BodyAndFooterContainer = styled(Box)`
  border-radius: 0 0 5px 5px;
`;

class Panel extends Component {
  state = {
    collapsed: false,
  };

  collapse = (collapsable) => {
    if (collapsable) {
      this.setState(prevState => ({ collapsed: !prevState.collapsed }));
    }
  };

  render = () => {
    const {
      header,
      body,
      footer,
      collapsable,
      containerStyle,
      headerStyle,
      bodyStyle,
      footerStyle,
      media,
      headerActions,
      headerTitle,
      borderLessHeader,
      borderLessBody,
    } = this.props;
    return (
      <Container customStyle={containerStyle} media={media} borderLessHeader={borderLessHeader}>
        {header ? (
          <Header onClick={() => this.collapse(collapsable)} customStyle={headerStyle} media={media}>
            {header}
          </Header>
        ) : (
          <PanelHeader
            borderLess={borderLessHeader}
            title={headerTitle}
            actions={headerActions}
          />
        )}
        <Spacer size={0.5} />
        <BodyAndFooterContainer fullWidth bordered={borderLessHeader && !borderLessBody} borderColor="lightGray" stretchAligned>
          <Body collapsed={this.state.collapsed} customStyle={bodyStyle} media={media}>
            {body}
          </Body>
          {footer && (
            <Fragment>
              <Separator
                spacerSize="0"
                separatorWeight="thin"
                separatorLength="full"
              />
              <Footer customStyle={footerStyle} media={media}>
                {footer}
              </Footer>
            </Fragment>
          )}
        </BodyAndFooterContainer>
      </Container>
    );
  };
}

Panel.defaultProps = {
  footer: null,
  collapsable: false,
  containerStyle: null,
  headerStyle: null,
  bodyStyle: null,
  footerStyle: null,
  media: null,
  borderLessHeader: false,
  borderLessBody: false,
  header: null,
  headerActions: null,
  headerTitle: '',
};

Panel.propTypes = {
  header: PropTypes.element,
  body: PropTypes.element.isRequired,
  footer: PropTypes.element,
  collapsable: PropTypes.bool,
  containerStyle: PropTypes.func,
  headerStyle: PropTypes.func,
  bodyStyle: PropTypes.func,
  footerStyle: PropTypes.func,
  media: PropTypes.shape({}),
  headerActions: PropTypes.PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    iconName: PropTypes.string,
    iconAfterName: PropTypes.string,
    onClicked: PropTypes.func.isRequired,
  })),

  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  borderLessHeader: PropTypes.bool,
  borderLessBody: PropTypes.bool,
};

export default withMedia(Panel);

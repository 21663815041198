/**
 * @flow
 * @relayHash 014bf23f5a0f65a793822cad2c442ddb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RecommendedProductCard_productInfo$ref = any;
export type RecommendedProductQueryVariables = {||};
export type RecommendedProductQueryResponse = {|
  +recommended_products: ?{|
    +higlighted: ?$ReadOnlyArray<?{|
      +$fragmentRefs: RecommendedProductCard_productInfo$ref
    |}>,
    +result: ?$ReadOnlyArray<?{|
      +$fragmentRefs: RecommendedProductCard_productInfo$ref
    |}>,
  |}
|};
export type RecommendedProductQuery = {|
  variables: RecommendedProductQueryVariables,
  response: RecommendedProductQueryResponse,
|};
*/


/*
query RecommendedProductQuery {
  recommended_products {
    higlighted {
      ...RecommendedProductCard_productInfo
      id
    }
    result {
      ...RecommendedProductCard_productInfo
      id
    }
  }
}

fragment RecommendedProductCard_productInfo on Product {
  id
  slug
  vendor_variants_count
  min_variant {
    vendor {
      name
      ref_id
      id
    }
    slug
    name
    ref_id
    root_variant_id
    discount_expire_at
    remaining_usage_percentage
    total_on_hand
    price
    original_price
    id
  }
  gift_image {
    url_large
    url_original
  }
  gift_text
  gift_included
  ref_id
  images
  name
  avg_rating
  rating_count
  price
  discount
  has_discount
  has_offer
  hot_deal
  wish_listed
  root_variant {
    ref_id
    wish_listed
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "FragmentSpread",
    "name": "RecommendedProductCard_productInfo",
    "args": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
},
v7 = [
  v1,
  v2,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "vendor_variants_count",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "min_variant",
    "storageKey": null,
    "args": null,
    "concreteType": "Variant",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "discount_expire_at",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vendor",
        "storageKey": null,
        "args": null,
        "concreteType": "Shop",
        "plural": false,
        "selections": [
          v1,
          v3,
          v2
        ]
      },
      v1,
      v3,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "root_variant_id",
        "args": null,
        "storageKey": null
      },
      v4,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "remaining_usage_percentage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "total_on_hand",
        "args": null,
        "storageKey": null
      },
      v5,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "original_price",
        "args": null,
        "storageKey": null
      },
      v2
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "gift_image",
    "storageKey": null,
    "args": null,
    "concreteType": "ImageAttachment",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url_large",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url_original",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "gift_text",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "gift_included",
    "args": null,
    "storageKey": null
  },
  v3,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "images",
    "args": null,
    "storageKey": null
  },
  v4,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avg_rating",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "rating_count",
    "args": null,
    "storageKey": null
  },
  v5,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "discount",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "has_discount",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "has_offer",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "hot_deal",
    "args": null,
    "storageKey": null
  },
  v6,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "root_variant",
    "storageKey": null,
    "args": null,
    "concreteType": "Variant",
    "plural": false,
    "selections": [
      v3,
      v6,
      v2
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RecommendedProductQuery",
  "id": null,
  "text": "query RecommendedProductQuery {\n  recommended_products {\n    higlighted {\n      ...RecommendedProductCard_productInfo\n      id\n    }\n    result {\n      ...RecommendedProductCard_productInfo\n      id\n    }\n  }\n}\n\nfragment RecommendedProductCard_productInfo on Product {\n  id\n  slug\n  vendor_variants_count\n  min_variant {\n    vendor {\n      name\n      ref_id\n      id\n    }\n    slug\n    name\n    ref_id\n    root_variant_id\n    discount_expire_at\n    remaining_usage_percentage\n    total_on_hand\n    price\n    original_price\n    id\n  }\n  gift_image {\n    url_large\n    url_original\n  }\n  gift_text\n  gift_included\n  ref_id\n  images\n  name\n  avg_rating\n  rating_count\n  price\n  discount\n  has_discount\n  has_offer\n  hot_deal\n  wish_listed\n  root_variant {\n    ref_id\n    wish_listed\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RecommendedProductQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "higlighted",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v0
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v0
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RecommendedProductQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "higlighted",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v7
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": v7
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d69bddb0ab382ffd2b93b2553ccb230';
module.exports = node;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import DesktopPanelFooter from './DesktopPanelFooter';
import TabletPanelFooter from './TabletPanelFooter';
import MobilePanelFooter from './MobilePanelFooter';

const OrderPanelHeader = props => (
  <Fragment>
    {props.media.minDesktop && <DesktopPanelFooter {...props} />}
    {props.media.tablet && <TabletPanelFooter {...props} />}
    {props.media.maxMobile && <MobilePanelFooter {...props} />}
  </Fragment>
);

OrderPanelHeader.defaultProps = {
  media: null,
};

OrderPanelHeader.propTypes = {
  media: PropTypes.shape({
    minDesktop: PropTypes.bool,
    tablet: PropTypes.bool,
    maxMobile: PropTypes.bool,
  }),
};

export default withMedia(OrderPanelHeader);

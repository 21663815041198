import React from 'react';
import PropTypes from 'prop-types';

import i18next from 'i18next';

import SocialMediaSignInMutation, {
  SocialMediaSignInMutationRoot,
} from '~/modules/accountManagement/containers/panels/socialMedia/SocialMediaSignInMutation';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';

import withUserInfo, { getUserInfoFromLoginResponse } from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

import Loader from '~/modules/coreUI/components/basic/Loader';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import { prepareLocalMessages, setLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';

import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';

class SocialMediaLanding extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount = () => {
    const { getSocialMediaData } = this.props;

    const mutationVariables = getSocialMediaData();

    if (mutationVariables) {
      commitMutation(
        this.props.environment,
        SocialMediaSignInMutation,
        SocialMediaSignInMutationRoot,
        mutationVariables,
        this.onMutationSuccess,
        this.onMutationError,
        this.onMutationLoading,
      );
    }
  }

  onMutationError = (error) => {
    const { getSocialMediaData, history } = this.props;
    getSocialMediaData(true);
    if (error) {
      const errorMessage = prepareLocalMessages(error);
      setLocalMessageFor(PathRedirectKeysMapper.login, errorMessage);
    }

    navigateToModal(history, '/accountManagement/login');
  };

  onMutationSuccess = (result) => {
    const {
      history, updateUserInfo, saveCurrentPath, getSocialMediaData,
    } = this.props;

    const {
      user, errors,
    } = result.socialmedia_signin;

    const foundEmail = result.socialmedia_signin.found_email;

    if (errors) {
      getSocialMediaData();
      setLocalMessageFor(PathRedirectKeysMapper.login, errors);

      navigateToModal(history, '/accountManagement/login');
    }

    if (user) {
      updateUserInfo({
        ...getUserInfoFromLoginResponse(result.socialmedia_signin, true),
      });

      getSocialMediaData(true);

      const message = i18next.t('accountManagement:SocialMedia.SuccessfullyLoggedIn');
      setLocalMessageFor(PathRedirectKeysMapper.showSuccess, message);

      navigateToModal(history, '/accountManagement/showSuccess');
    } else {
      const { provider } = getSocialMediaData();
      if (foundEmail) {
        saveCurrentPath(
          PathRedirectKeysMapper.showSuccess,
          '/accountManagement/connectSocialMedia',
        );

        const message = i18next.t('accountManagement:SocialMedia.ToConnectLoginFirst', { provider });
        setLocalMessageFor(PathRedirectKeysMapper.login, message);

        // Redirect User to LogIn, after LogIn Successful Connect Social
        navigateToModal(history, '/accountManagement/login');
      } else {
        saveCurrentPath(
          PathRedirectKeysMapper.showSuccess,
          '/accountManagement/connectSocialMedia',
        );

        const message = i18next.t('accountManagement:SocialMedia.ThereIsNoAccountConnectedWith', { provider });
        setLocalMessageFor(PathRedirectKeysMapper.signup, message);

        // Redirect to SignUp, after SignUp Successful Connect Social
        navigateToModal(history, '/accountManagement/signup');
      }
    }
  };

  onMutationLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  render = () => {
    const { isLoading } = this.state;
    return isLoading ? (
      <Column fullWidth fullHeight centerAligned centerJustified style={{ position: 'absolute' }}>
        <div>
          <Loader />
          <Label md>{i18next.t('accountManagement:SocialMedia.ConnectingWithSocialMedia')}</Label>
        </div>
      </Column>
    ) : (
      <React.Fragment />
    );
  };
}

SocialMediaLanding.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  saveCurrentPath: PropTypes.func.isRequired,
  getSocialMediaData: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withRelayEnvironment(
  withUserInfo(
    withAlertMsg(
      SocialMediaLanding,
    ),
  ),
);

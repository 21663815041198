import React from 'react';
import i18next from 'i18next';
import styled from 'react-emotion';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { BasicButton } from '~/modules/ecommerceCoreUI/components/basic/BasicButton';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const HeadingContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  height: 110px;
  text-align: center;
`;
const LabelStyle = styled.div`
  color: ${props => props.theme.colors.named.white};
  font-size: ${props => props.theme.fonts.sizes.small}px;
  text-transform: capitalize;
`;
const Icon = styled.i`
  font-size: 40px;
`;
const Avatar = styled.img`
  object-fit: cover;
  width: ${props => 4 * props.theme.new.spacer}px;
  height: ${props => 4 * props.theme.new.spacer}px;
  overflow: hidden;
  border-radius: 50%;
`;
const Image = styled.img`
  display: inline-block;
  width: ${props => 5.3 * props.theme.new.spacer}px;
`;
const Heading = props => (
  <HeadingContainer>
    {props.authenticated ? (
      <React.Fragment>
        {props.userInfo.avatar ? (
          <Avatar src={props.userInfo.avatar} alt={props.userInfo.avatar} />
        ) : (
          <Icon className="icon-userloggedin" />
        )}
        <LabelStyle>{`${props.userInfo.firstName} ${props.userInfo.lastName}`}</LabelStyle>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Image
          src={
          props.direction === 'rtl'
            ? `/${imagesPathName}/Header/sideMenu-logo.png`
            : `/${imagesPathName}/Header/sideMenu-logo-en.png`
          }
          alt="logo"
        />
        <BasicButton
          primary
          onClick={() => navigateToModal(props.history, '/accountManagement/login')}
        >
          {`${i18next.t('ecommerceCoreUI:logIn')} / ${i18next.t('ecommerceCoreUI:signUp')}`}
        </BasicButton>
      </React.Fragment>
    )}
  </HeadingContainer>
);
Heading.propTypes = PropTypes.shape({
  authenticated: PropTypes.boolen,
  userInfo: PropTypes.object,
}).isRequired;
export default withDirection(withUserInfo(withRouter(Heading)));

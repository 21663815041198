import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash/fp';
import { css } from 'emotion';
import { withTheme } from 'emotion-theming';

import Select from 'react-select';
import RootQuery from '~/modules/core/utils/relayHelpers/RootQuery';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const selectStyles = props => ({
  control: (provided, state) => css`
    ${provided};
    padding: 1px 5px;
    border-width: 1px 1px 1px 1px;
    border-radius: ${2.5 * props.theme.new.spacer}px;
    border-color: ${props.theme.new.inputs.placeholderColor};
    background: none;
    box-shadow: none;
    &:hover {
      border-color: ${props.theme.new.inputs.placeholderColor};
    }
    &:active {
      border-color: ${props.theme.new.colors.named.primary};
    }
    ${state.isFocused && css`
      border-color: ${props.theme.new.colors.named.primary};
      &:hover {
        border-color: ${props.theme.new.colors.named.primary};
      }
    `};
    ${state.isDisabled && css`
       background-color: ${props.theme.new.colors.backgrounds.lightPanelHeader};
    `};
  `,
  container: provided => css`
    ${provided};
    width: 100%;
  `,
  dropdownIndicator: (provided, state) => css`
    ${provided};
    color: ${props.theme.new.inputs.placeholderColor};
    ${state.isFocused && css`
      color: ${props.theme.new.colors.named.primary};
      &:hover {
        color: ${props.theme.new.colors.named.primary};
      }
    `};
  `,
  option: (provided, state) => css`
    ${provided};
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &:hover {
      background-color: ${props.theme.new.colors.backgrounds.panel};
    }
    ${state.isFocused && css`
      background-color: ${props.theme.new.colors.backgrounds.panel};
    `};
  `,
  placeholder: provided => css`
    ${provided};
    padding: 8px 2px;
    margin: 0px;
    color: ${props.theme.new.inputs.placeholderColor};
  `,
  input: provided => css`
    ${provided};
    padding: ${0.8 * props.theme.new.spacer}px ${0.5 * props.theme.new.spacer}px;
  `,
  menu: provided => css`
    ${provided};
    background-color: white;
    border: solid 1px ${props.theme.new.inputs.placeholderColor};
    box-shadow: 0px 2px 6px ${props.theme.new.inputs.placeholderColor};
    &::before {
        position: absolute;
        height: ${0.8 * props.theme.new.spacer}px;
        width: ${0.8 * props.theme.new.spacer}px;
        z-index: 2;
        transform: rotate(134deg);
        content: '';
        left: ${5 * props.theme.new.spacer}px;
      ${props.menuPlacement === 'bottom' && css`
        top: ${-0.4 * props.theme.new.spacer}px;
        background: white;
        border-left: solid 1px ${props.theme.new.inputs.placeholderColor};
        border-bottom: solid 1px ${props.theme.new.inputs.placeholderColor};
      `};
      ${(props.menuPlacement === 'top') && css`
        bottom: ${-0.4 * props.theme.new.spacer}px;
        background: white;
        border-right: solid 1px ${props.theme.new.inputs.placeholderColor};
        border-top: solid 1px ${props.theme.new.inputs.placeholderColor};
      `};
    }
  `,
});

const DropDown = (props) => {
  const {
    query,
    transformQueryResult,
    variables,
    ...restProps
  } = props;
  const commonProps = {
    onChange: value => restProps.onChange(value && value.value),
    styles: selectStyles({ theme: props.theme, media: props.media, menuPlacement: props.menuPlacement ? props.menuPlacement : 'bottom' }),
    isDisabled: restProps.isDisabled,
    blurInputOnSelectboolean: true,
    menuPlacement: restProps.menuPlacement ? restProps.menuPlacement : 'bottom',
  };
  const valueFromOptions = options => options.find(option => isEqual(restProps.value, option.value));

  return (
    query ? (
      <RootQuery
        query={query}
        variables={variables}
        render={(queryResult) => {
          const optionsFromServer = transformQueryResult(queryResult) || [];
          return (
            <Select
              {...restProps}
              {...commonProps}
              options={optionsFromServer}
              value={valueFromOptions(optionsFromServer)}
              isLoading={!queryResult}
            />
          );
        }}
        {...restProps}
      />
    ) : (
      <Select
        {...restProps}
        {...commonProps}
        value={valueFromOptions(restProps.options)}
      />
    )
  );
};

DropDown.defaultProps = {
  value: '',
  isLoading: false,
  tabIndex: null,
  isDisabled: false,
};

DropDown.propTypes = {
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
};

export default withMedia(withTheme(DropDown));

import React from 'react';
import LuckyWheelContext from './LuckyWheelContext';

export default WrappedComponent => props => (
  <LuckyWheelContext.Consumer>
    {value => (
      <WrappedComponent {...props} {...value}>
        {props.children}
      </WrappedComponent>
    )}
  </LuckyWheelContext.Consumer>
);

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type PaginationData_pageInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RefundList_queryResult$ref: FragmentReference;
export type RefundList_queryResult = {|
  +refund_requests: ?{|
    +page_info: ?{|
      +$fragmentRefs: PaginationData_pageInfo$ref
    |},
    +refunds: ?$ReadOnlyArray<?{|
      +ref_id: string,
      +refunded_at: ?string,
      +created_at: ?string,
      +number: ?string,
      +refund_status: ?string,
      +progress_bar: ?$ReadOnlyArray<?{|
        +status: ?string,
        +name: ?string,
      |}>,
      +vendor: ?{|
        +ref_id: string,
        +name: ?string,
        +rating_count: ?number,
        +avg_rating: ?number,
      |},
      +refund_request_items: ?$ReadOnlyArray<?{|
        +refund_status: ?string,
        +quantity: ?number,
        +line_item: ?{|
          +id: string,
          +ref_id: string,
          +eligible_refund: ?boolean,
          +price: number,
          +variant: {|
            +display_price: ?string,
            +slug: ?string,
            +vendor_variants_count: ?number,
            +name: string,
            +rating_count: ?number,
            +avg_rating: ?number,
            +images_attachments: $ReadOnlyArray<?{|
              +url_small: string
            |}>,
            +option_values: ?$ReadOnlyArray<?{|
              +id: string,
              +presentation: ?string,
            |}>,
          |},
        |},
      |}>,
    |}>,
  |},
  +$refType: RefundList_queryResult$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "refund_status",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RefundList_queryResult",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "input",
      "type": "RequndListInput",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "refund_requests",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input",
          "type": "RequndListInput"
        }
      ],
      "concreteType": "RequestResult",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "page_info",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "PaginationData_pageInfo",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "refunds",
          "storageKey": null,
          "args": null,
          "concreteType": "RefundRequestResult",
          "plural": true,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "refunded_at",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "created_at",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "number",
              "args": null,
              "storageKey": null
            },
            v1,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "progress_bar",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderStates",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                v2
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "vendor",
              "storageKey": null,
              "args": null,
              "concreteType": "Shop",
              "plural": false,
              "selections": [
                v0,
                v2,
                v3,
                v4
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "refund_request_items",
              "storageKey": null,
              "args": null,
              "concreteType": "RefundRequestItemResult",
              "plural": true,
              "selections": [
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "line_item",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "LineItem",
                  "plural": false,
                  "selections": [
                    v5,
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "eligible_refund",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "price",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "variant",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Variant",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "display_price",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "slug",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "vendor_variants_count",
                          "args": null,
                          "storageKey": null
                        },
                        v2,
                        v3,
                        v4,
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "images_attachments",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ImageAttachment",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "url_small",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "option_values",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "OptionValue",
                          "plural": true,
                          "selections": [
                            v5,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "presentation",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3cbb55794229983366269b211b15637';
module.exports = node;

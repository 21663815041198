import React from 'react';

import styled, { css } from 'react-emotion';

import { Trans, translate } from 'react-i18next';
import { Container } from 'semantic-ui-react';

import PropTypes from 'prop-types';
import {
  Tabs, TabPanel, Tab, TabList,
} from '~/modules/coreUI/components/layouts/Tabs';
import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import {
  tabListStyle,
  tabsContainer,
  tabStyle,
  activeTabStyle,
  activeContentStyle,
} from '~/modules/ecommerceCoreUI/components/basic/VerticalTabs';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { DepartmentTabsTitle } from '../../components/basic/Labels';
import TrackContent from './TrackContent';

const TitleContainer = withDirection(styled.div`
  padding: 10.5px ${props => props.theme.paddings.large}px;
  margin: 1px;
  ${props => (props.direction === 'rtl'
    ? css`
        text-align: right;
      `
    : css`
        text-align: left;
      `
  )}
  background-color: #f9f9f9;
  border: solid 1px ${props => props.theme.colors.invertedLabels.important};
`);

const ProductsTracksDesktop = ({
  taxons,
  getSelectedDepartment,
  selectDepartment,
  vendorID,
  vendorName,
}) => (
  <Container>
    <Tabs
      styles={tabsContainer}
      defaultIndex={getSelectedDepartment()}
      onSelect={tabIndex => selectDepartment(taxons[tabIndex])}
    >
      <TabList styles={tabListStyle}>
        <TitleContainer>
          <DepartmentTabsTitle>
            {' '}
            <Trans i18nKey="ProductsTracks.tabsTitle" />
          </DepartmentTabsTitle>
        </TitleContainer>
        {taxons
          && taxons.map(taxon => (
            <Tab styles={tabStyle} activeStyles={activeTabStyle} key={taxon.id}>
              {taxon.name}
            </Tab>
          ))}
      </TabList>
      <LargeSpacer />
      {taxons
        && taxons.map(taxon => (
          <TabPanel activeStyles={activeContentStyle} key={taxon.id}>
            <TrackContent taxon={taxon} vendorID={vendorID} vendorName={vendorName} />
          </TabPanel>
        ))}
    </Tabs>
  </Container>
);

ProductsTracksDesktop.defaultProps = {
  vendorID: null,
  vendorName: null,
};

ProductsTracksDesktop.propTypes = {
  taxons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      ref_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logo: PropTypes.string,
    }),
  ).isRequired,
  getSelectedDepartment: PropTypes.func.isRequired,
  selectDepartment: PropTypes.func.isRequired,
  vendorID: PropTypes.string,
  vendorName: PropTypes.string,
};

export default translate('ecommerceProducts')(ProductsTracksDesktop);

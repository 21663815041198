/* eslint-disable camelcase, no-tabs, indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'react-emotion';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { MediumLabel } from '~/modules/coreUI/components/basic/Labels';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import withLuckyWheelInfo from './withLuckyWheelInfo';

const SPIN_SECONDS = 10;

const getItemSpan = optionsCount => 1 / optionsCount * 360;

const getEndingItemNumber = spinMutationResult => spinMutationResult?.spin_wheel?.wheel_no;

const getWheelSpinEndAngle = (spinMutationResult, optionsCount) => getItemSpan(optionsCount) * (getEndingItemNumber(spinMutationResult) - 1);

const getItemAngle = (itemNumber, optionsCount) => (
  (getItemSpan(optionsCount) * (itemNumber - 1))
);

const rotation = endAngle => keyframes`
  0% { transform: rotate(0deg); }
  100% {
    transform: rotate(-${(8 * 360) + endAngle}deg);
  }
`;
const WheelCircle = withDirection(styled.div`
  position: relative;
  ${props => (props.spinningStarted && css`
    animation: ${rotation(props.endAngle, props.direction)} ${SPIN_SECONDS}s forwards cubic-bezier(.57,0,0,1);
  `)}
`);

const WheelItemsWrapper = withDirection(styled.div`
  position: absolute;
  top: 50%;
  width: 50%;
  ${props => (props.direction === 'rtl'
    ? css`
        right: 50%;
      `
    : css`
        left: 50%;
  `)}
`);
const Item = withDirection(styled.div`
  ${props => (props.direction === 'rtl'
    ? css`
        transform: rotate(${props.itemAngle}deg) translate(80%);
        transform-origin: top right;
      `
    : css`
        transform: rotate(${props.itemAngle + 180}deg) translate(80%) scale(-1);
        transform-origin: top left;
  `)}
  text-align: center;
`);

const ItemHolder = withDirection(styled.div`
  position: absolute;
  top: -10px;
  ${props => (props.direction === 'rtl'
    ? css`
        right: 0;
      `
    : css`
        left: 0;
  `)}
`);

const MediumLabelStyle = styled(MediumLabel)`
  color: ${props => props.theme.colors.named.white};
`;

class WheelItems extends React.Component {
  componentDidUpdate = () => {
    if (this.props.loading === true && this.props.spinningStarted === false) {
      this.props.setSpinningStarted();
      setTimeout(() => {
        this.props.handleToggleSpinningEnded();
      }, SPIN_SECONDS * 1000);
    }
  }

  render = () => {
    const { queryResult, spinWheelResult, spinningStarted } = this.props;
    const optionsCount = queryResult?.lucky_wheel_components.length;
    return (
      <WheelCircle
        spinningStarted={spinningStarted}
        endAngle={getWheelSpinEndAngle(spinWheelResult, optionsCount)}
      >
        <img src={`/${imagesPathName}/Wheel.png`} alt="lucky wheel" />
        <WheelItemsWrapper>
          {optionsCount !== 0 && (
            queryResult?.lucky_wheel_components.map(slice => (
              <Item key={slice.number} itemAngle={getItemAngle(slice.number, optionsCount)}>
                <ItemHolder>
                  <MediumLabelStyle>{slice.display_name}</MediumLabelStyle>
                </ItemHolder>
              </Item>
            )
          ))}
        </WheelItemsWrapper>
      </WheelCircle>
    );
  };
}
WheelItems.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  spinningStarted: PropTypes.bool.isRequired,
  spinWheelResult: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  handleToggleSpinningEnded: PropTypes.func.isRequired,
  setSpinningStarted: PropTypes.func.isRequired,
};
export default withRootQuery(
  withDirection(withLuckyWheelInfo(WheelItems)),
  graphql`
    query WheelItemsQuery {
      lucky_wheel_components {
        description
        display_name
        number
      }
    }
  `,
);

import React from 'react';
import i18next from 'i18next';
import { Container } from 'semantic-ui-react';
import styled from 'react-emotion';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import SectionHeader from '~/modules/coreUI/components/layouts/SectionHeader';
import TabsContainer from './TabsContainer';

const RecommendedContainer = styled.div`
  background-image: url(/${imagesPathName}/Recommeded/recommended-bg@2x.png);
  background-repeat: no-repeat;
  background-position: unset;
  background-size: contain;
  ${cssMediaMax.tablet`
     background-size: 25%;
  `}
  ${cssMediaMax.mobile`
     background-size: 30%;
  `}
    ${cssMediaMax.xsmall`
     background-size: 65%;
  `}
`;

class RecommendedSection extends React.PureComponent { // eslint-disable-line
  render = () => (
    <RecommendedContainer>
      <Container>
        <Spacer size={4} />
        <SectionHeader
          title={i18next.t('ecommerceRecommended:RecommendedShopsAndProducts.recommendedTitle')}
          subtitle={i18next.t('ecommerceRecommended:RecommendedShopsAndProducts.recommendedSubTitle')}
        />
        <Spacer size={2} />
        <TabsContainer />
      </Container>
    </RecommendedContainer>
  );
}

export default RecommendedSection;

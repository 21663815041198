/**
 * @flow
 * @relayHash f3827afedf3e5f32791442bfb56a53b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarkMessageAsReadMutationVariables = {|
  thread_id: string
|};
export type MarkMessageAsReadMutationResponse = {|
  +read_messages: ?{|
    +threads_with_unread_messages_count: ?number,
    +thread: ?{|
      +created_at: ?any,
      +created_at_str: ?string,
      +status: ?string,
      +status_string: ?string,
      +id: string,
      +ref_id: string,
      +number: ?string,
      +unread_messages_count: ?number,
      +messages: ?$ReadOnlyArray<?{|
        +attachments: ?$ReadOnlyArray<?string>,
        +attachments_object: ?$ReadOnlyArray<?{|
          +name: ?string,
          +type: ?string,
          +url_original: ?string,
          +ref_id: string,
          +id: string,
        |}>,
        +body: ?string,
        +created_at: ?any,
        +created_at_str: ?string,
        +id: string,
        +is_current_user_message: ?boolean,
        +ref_id: string,
      |}>,
      +thread_topic: ?{|
        +id: string,
        +ref_id: string,
        +title: ?string,
      |},
      +vendor: ?{|
        +logo: ?{|
          +url_original: ?string,
          +name: ?string,
        |},
        +ref_id: string,
        +id: string,
        +name: ?string,
      |},
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type MarkMessageAsReadMutation = {|
  variables: MarkMessageAsReadMutationVariables,
  response: MarkMessageAsReadMutationResponse,
|};
*/


/*
mutation MarkMessageAsReadMutation(
  $thread_id: ID!
) {
  read_messages(thread_id: $thread_id) {
    threads_with_unread_messages_count
    thread {
      created_at
      created_at_str
      status
      status_string
      id
      ref_id
      number
      unread_messages_count
      messages {
        attachments
        attachments_object {
          name
          type
          url_original
          ref_id
          id
        }
        body
        created_at
        created_at_str
        id
        is_current_user_message
        ref_id
      }
      thread_topic {
        id
        ref_id
        title
      }
      vendor {
        logo {
          url_original
          name
        }
        ref_id
        id
        name
      }
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "thread_id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created_at",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created_at_str",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url_original",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "read_messages",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "thread_id",
        "variableName": "thread_id",
        "type": "ID!"
      }
    ],
    "concreteType": "ReadMessagesResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "threads_with_unread_messages_count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thread",
        "storageKey": null,
        "args": null,
        "concreteType": "UserThread",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status_string",
            "args": null,
            "storageKey": null
          },
          v3,
          v4,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "number",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unread_messages_count",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "messages",
            "storageKey": null,
            "args": null,
            "concreteType": "UserMessage",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "attachments",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "attachments_object",
                "storageKey": null,
                "args": null,
                "concreteType": "MessageAttachmentDetails",
                "plural": true,
                "selections": [
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  v6,
                  v1,
                  v3
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "body",
                "args": null,
                "storageKey": null
              },
              v2,
              v4,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "is_current_user_message",
                "args": null,
                "storageKey": null
              },
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "thread_topic",
            "storageKey": null,
            "args": null,
            "concreteType": "UserThreadTopic",
            "plural": false,
            "selections": [
              v3,
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vendor",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "logo",
                "storageKey": null,
                "args": null,
                "concreteType": "BasicImage",
                "plural": false,
                "selections": [
                  v6,
                  v5
                ]
              },
              v1,
              v3,
              v5
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MarkMessageAsReadMutation",
  "id": null,
  "text": "mutation MarkMessageAsReadMutation(\n  $thread_id: ID!\n) {\n  read_messages(thread_id: $thread_id) {\n    threads_with_unread_messages_count\n    thread {\n      created_at\n      created_at_str\n      status\n      status_string\n      id\n      ref_id\n      number\n      unread_messages_count\n      messages {\n        attachments\n        attachments_object {\n          name\n          type\n          url_original\n          ref_id\n          id\n        }\n        body\n        created_at\n        created_at_str\n        id\n        is_current_user_message\n        ref_id\n      }\n      thread_topic {\n        id\n        ref_id\n        title\n      }\n      vendor {\n        logo {\n          url_original\n          name\n        }\n        ref_id\n        id\n        name\n      }\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MarkMessageAsReadMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v7
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkMessageAsReadMutation",
    "argumentDefinitions": v0,
    "selections": v7
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a7d3ea6143d8c0e73b5c1e04c0c3005';
module.exports = node;

import React from 'react';
import i18next from 'i18next';
import styled from 'react-emotion';
import {
  SectionHeaderSmall,
  SectionHeaderSubtitleSmall,
} from '~/modules/ecommerceCoreUI/components/basic/Labels';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';

import PointGuide from './PointGuide';
import EarnPointsDetails from './EarnPointsDetails';

const EarnPointsWrapper = styled.div`
  grid-gap: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
  ${cssMediaMax.xsmall`
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  `}
`;
const EarnPoints = () => (
  <React.Fragment>
    <Separator separatorLength="full" />
    <Spacer size={1.5} />
    <Column>
      <SectionHeaderSmall>{i18next.t('ecommerceOrder:Loyalty.earnPoints')}</SectionHeaderSmall>
      <Spacer size={0.5} />
      <SectionHeaderSubtitleSmall>{i18next.t('ecommerceOrder:Loyalty.learnPoints')}</SectionHeaderSubtitleSmall>
    </Column>
    <Spacer size={2.5} />
    <EarnPointsWrapper>
      {EarnPointsDetails.map(earn => (
        <PointGuide
          points={earn.point}
          how={i18next.t(earn.for)}
          details={i18next.t(earn.details)}
          actions={earn.actions}
          key={earn.for}
        />
      ))}
    </EarnPointsWrapper>
    <Spacer size={1.5} />
    <Separator separatorLength="full" />
  </React.Fragment>
);

export default EarnPoints;

const WishlistTrans = {
  En: {
    emptyHeader: 'Your Wish list is empty !',
    emptyLabel: 'Seems like you haven’t added any items in your wish list yet.',
    viewProducts: 'Browse Products',
  },
  Ar: {
    emptyHeader: 'ليس لديك منتجات ترغبها حتى الآن!',
    emptyLabel: 'ألق نظرة على منتجاتنا وابدأ بإضافتها إلى قائمة رغباتك',
    viewProducts: 'عرض المنتجات',
  },
};
export default WishlistTrans;

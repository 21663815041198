import React from 'react';
import { Trans, translate } from 'react-i18next';
import styled, { css } from 'react-emotion';
import { Header, LargeLabel } from '~/modules/coreUI/components/basic/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const headerCustomStyle = props => css`
  color: ${props.theme.new.colors.labels.inverted.primary};
  font-weight: 900;
  ${cssMediaMax.tablet`
    font-size: ${props.theme.new.fonts.sizes.xxl}px;
  `}
  ${cssMediaMax.xsmall`
    font-size: ${props.theme.new.fonts.sizes.xl}px;
  `}
`;
const ladelCustomStyle = props => css`
  display: block;
  color : ${props.theme.new.colors.labels.inverted.normal};
  ${cssMediaMax.tablet`
    font-size: ${props.theme.new.fonts.sizes.sm}px;
  `}
  ${cssMediaMax.xsmall`
    font-size: ${props.theme.new.fonts.sizes.xs}px;
    text-align: center;
  `}
`;
const ColumnWrapper = styled(Column)`
  ${cssMediaMax.xsmall`
    align-items: center;
  `}
`;
const FeaturedShops = () => (
  <ColumnWrapper leftAligned spaceBetween="0.7">
    <Header customStyle={headerCustomStyle}>
      <Trans i18nKey="FeaturedShops.title" />
    </Header>
    <LargeLabel customStyle={ladelCustomStyle}>
      <Trans i18nKey="FeaturedShops.subTitle" />
    </LargeLabel>
  </ColumnWrapper>
);

export default translate('eCommerceShops')(FeaturedShops);

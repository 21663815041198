/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import i18next from 'i18next';

import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { MarkMessageAsReadMutation, MarkMessageAsReadMutationRoot } from '../messagesQuery/MarkMessageAsRead';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';

import Status from './MessageStatus';
import OwnerMessageLogo, { isUnreadMessage } from './OwnerMessageLogo';

const MessageDetailsLink = styled(Row)`
  width: 100%;
  padding: ${props => 1.5 * props.theme.new.spacer}px 0px;
  cursor: ${props => !props.isDetails && 'pointer'};
`;

const StatusLabel = styled(Label)`
  text-transform: capitalize;
`;

const OwnerName = withMedia(styled(Label)`
  cursor: pointer;
  ${props => props.media.maxMobile && css`
      display: inline-block;
      white-space: nowrap;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
  `}
`);

const DateLabel = styled(Label)`
  direction: ltr;
`;

const MessageWrapper = styled(Column)`
  background-color: ${props => (props.isUnread ? 'rgba(51, 168, 255, 0.05)' : 'unset')};
  &&:hover{
    box-shadow: ${props => !props.isDetails && '0px 1px 2px 0px rgba(0, 0, 0, 0.15)'};
  }
`;


class Message extends Component {
  goToMessageDetails = (messageId) => {
    if (!this.props.isDetails) {
      externalRedirect(urlFor('getMessageDetailsLink', [messageId]));
    }
  };

  redirectToShop = (vendor) => {
    if (this.props.isDetails && vendor && !this.props.isDropDown) {
      externalRedirect(urlFor('getProductTracksLink', [null, vendor.ref_id, vendor.name]));
    }
  };

  onMutationSuccess = (threadId) => {
    this.goToMessageDetails(threadId);
  }

  onMutationError = (threadId) => {
    this.goToMessageDetails(threadId);
  }

  markMessageAsRead = (threadId) => {
    if (!this.props.isDetails) {
      commitMutation(
        this.props.environment,
        MarkMessageAsReadMutation,
        MarkMessageAsReadMutationRoot,
        {
          thread_id: threadId,
        },
        () => { this.onMutationSuccess(threadId); },
        () => { this.onMutationError(threadId); },
        this.onMutationLoading,
      );
    }
  };

  paddingNumber = () => (this.props.isDetails ? 0 : 0.5);


  render() {
    const {
      thread,
      isDropDown,
      isDetails,
    } = this.props;
    return (
      <MessageWrapper
        pl={[this.paddingNumber(), 0.5, 1.5, 1.5]}
        pr={[this.paddingNumber(), 0.5, 1.5, 1.5]}
        isDetails={isDetails}
        isUnread={!!isUnreadMessage(thread?.unread_messages_count)}
        fullWidth
      >
        <MessageDetailsLink isDetails={isDetails} onClick={() => this.markMessageAsRead(thread.ref_id)}>
          <Row
            pl={[this.paddingNumber(), 0.5, 1, 1]}
            pr={[this.paddingNumber(), 0.5, 1, 1]}
            spaceBetween={1}
            fullWidth
          >
            <OwnerMessageLogo thread={thread} />
            <Column spaceBetween={0.5} leftAligned fullWidth>
              <Row fullWidth spaceBetweenJustified spaceBetween={0.5}>
                <OwnerName
                  sm
                  semiBold
                  important
                  onClick={() => this.redirectToShop(thread.vendor)}
                >
                  {thread?.vendor ? thread?.vendor?.name : i18next.t('ecommerceOrder:notificationsAndMessages.aykMallName')}
                </OwnerName>
                <Row spaceBetween={0.5}>
                  <Status status={thread?.status} />
                  {!isDropDown && <StatusLabel emphasized xs>{thread?.status_string}</StatusLabel>}
                </Row>
              </Row>
              <Label xs>
                {i18next.t('ecommerceOrder:notificationsAndMessages.refNoLabel')}
                {thread?.number}
              </Label>
              <Row fullWidth spaceBetweenJustified spaceBetween={0.5}>
                <Label emphasized xs>{thread?.thread_topic?.title}</Label>
                {!isDetails && <DateLabel xs>{thread?.created_at_str}</DateLabel>}
              </Row>
            </Column>
          </Row>
        </MessageDetailsLink>
        {!isDetails && <Separator spacerSize={0.001} separatorLength="full" />}
      </MessageWrapper>
    );
  }
}

Message.defaultProps = {
  thread: {},
  isDropDown: false,
  isDetails: false,
};

Message.propTypes = {
  thread: PropTypes.shape({}),
  environment: PropTypes.shape({}).isRequired,
  isDropDown: PropTypes.bool,
  isDetails: PropTypes.bool,
};

export default withRelayEnvironment(withDirection(Message));

import React from 'react';
import i18next from 'i18next';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';

const FormMutation = graphql`
  mutation LoginFormMutation($user_signin: String!, $password: String!, $remember_me: Boolean!) {
    signin_user(user_signin: $user_signin, password: $password, remember_me: $remember_me) {
      token
      client_id
      expiry
      user {
        ...WithUserData_user @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;

class LoginForm extends React.Component {
  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  getValue = () => this.form.getValue();

  submitForm = () => {
    this.form.submitForm();
  };

  render = () => {
    const {
      customLayout,
      onFormError,
      onFormSuccess,
      onFormLoading,
      userInfo,
      getSocialMediaData,
    } = this.props;

    const SMData = getSocialMediaData();
    let email;
    const rememberMe = userInfo && userInfo.rememberMe;

    if (SMData) {
      email = SMData.email || (userInfo && userInfo.email);
    }

    return (
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={onFormError}
        onFormSuccess={onFormSuccess}
        onFormLoading={onFormLoading}
        mutation={FormMutation}
        mutationRoot="signin_user"
        options={{
          customLayout,
          initialFormValue: {
            user_signin: email,
            remember_me: rememberMe,
          },
          fields: [
            {
              name: 'user_signin',
              displayName: i18next.t('accountManagement:Login.EmailMobileNumber'),
              placeholder: i18next.t('accountManagement:Login.EmailMobileNumber'),
              input_type: 'textbox',
              type: 'RequiredString',
            },
            {
              name: 'password',
              displayName: i18next.t('accountManagement:Login.password'),
              placeholder: i18next.t('accountManagement:Login.password'),
              input_type: 'password',
              type: 'RequiredString',
            },
            {
              name: 'remember_me',
              label: i18next.t('accountManagement:Login.RememberMe'),
              input_type: 'checkbox',
              type: 'Boolean',
            },
          ],
        }}
      />
    );
  };
}

LoginForm.propTypes = PropTypes.shape({
  onFormError: PropTypes.func.isRequired,
  onFormSuccess: PropTypes.func.isRequired,
  onFormLoading: PropTypes.func.isRequired,
}).isRequired;

export default withUserInfo(LoginForm);

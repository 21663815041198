/* eslint-disable lines-between-class-members */
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import AddressForm from '../forms/AddressForm';
import Button from '~/modules/coreUI/components/basic/Button';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import withAddressesPanelsInfo from '../context/withAddressesPanelsInfo';
import AddressListEntry from './AddressListEntry';

const FormRow = styled(Row)`
  border-bottom: ${props => (!props.showBorderBottom ? `1px solid ${props.theme.new.colors.labels.borders.normal.subtle}` : 'none')};
  border-radius: 0px;
`;

export const ButtonsRow = styled(Row)`
  ${props => props.media.maxMobile && css`
     justify-content: center;
  `}
`;

const SaveButton = props => (
  <Button size={['sm']} secondary width="120px" loading={props.isLoading} {...props}>
    {i18next.t('ecommerceOrder:Checkout.saveButton')}
  </Button>
);

const CancelButton = props => (
  <Button size={['sm']} primary inverted width="120px" {...props}>
    {i18next.t('ecommerceOrder:Checkout.cancelButton')}
  </Button>
);

class AddressList extends Component {
  state = {
    currentEditableAddressId: null,
  };

  onEditAddressStarted = (addressId) => {
    this.setState({ currentEditableAddressId: addressId });
    this.props.onEditingStarted();
  }

  onEditAddressDone = () => {
    this.setState({ currentEditableAddressId: null });
    this.props.onEditingDone();
  }

  getAddressInfoForID = addressId => this.props.addresses.find(address => address.ref_id === addressId);

  isBilling = () => this.props.type === 'billing';

  selectedAddressId = () => (this.isBilling() ? this.props.selectedBillingAddressId : this.props.selectedShippingAddressId);
  changeSelectedAddress = () => (this.isBilling() ? this.props.changeSelectedBillingAddress : this.props.changeSelectedShippingAddress);

  onError = error => this.setState({ panelError: error });
  onLoading = loading => this.setState({ isLoading: loading });

  onSaveClicked = () => this.editAddressForm.submitForm();

  hasSavedAddresses = () => this.props.hasPreviousAddresses;

  renderForm = () => (
    <Column maxWidth={60} fullWidth pl={1.5} pr={1.5} pb={2.5}>
      {this.state.panelError && (
        <PanelAlert
          type="error"
          inverted
          title={i18next.t('ecommerceOrder:Checkout.checkFollowingError')}
          errors={this.state.panelError}
        />
      )}
      <AddressForm
        onRef={(ref) => { this.editAddressForm = ref; }}
        isEditingMode
        onError={this.onError}
        onLoading={this.onLoading}
        onSuccess={this.onEditAddressDone}
        addressMutationInputName="address_attributes"
        editableAddressInfo={this.getAddressInfoForID(this.state.currentEditableAddressId)}
        isUpdate={this.hasSavedAddresses()}
      />
      <ButtonsRow media={this.props.media} fullWidth rightJustified spaceBetween={2} paddingRight={[0, 0, 0.7]}>
        <CancelButton isLoading={this.state.isLoading} onClicked={this.onEditAddressDone} />
        <SaveButton isLoading={this.state.isLoading} onClicked={this.onSaveClicked} />
      </ButtonsRow>
    </Column>
  );

  render() {
    const {
      addresses,
      type,
    } = this.props;
    const { currentEditableAddressId } = this.state;
    return addresses && addresses.map((addressInfo, index) => (
      <React.Fragment key={addressInfo.ref_id}>
        {currentEditableAddressId === addressInfo.ref_id ? (
          <FormRow showBorderBottom={index === addresses.length - 1} centerJustified>
            {this.renderForm()}
          </FormRow>
        ) : (
          <AddressListEntry
            type={type}
            addressInfo={addressInfo}
            isLast={index === addresses.length - 1}
            isSelected={addressInfo.ref_id === this.selectedAddressId()}
            onAddressSelected={this.changeSelectedAddress()}
            onEditAddressStarted={this.onEditAddressStarted}
            selectable={this.props.addressSelectionEnabled}
            deletable={this.props.addressDeletionEnabled}
          />
        )}
        <Spacer size={1.5} />
      </React.Fragment>
    ));
  }
}

AddressList.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeSelectedShippingAddress: PropTypes.func.isRequired,
  changeSelectedBillingAddress: PropTypes.func.isRequired,
  selectedShippingAddressId: PropTypes.string.isRequired,
  selectedBillingAddressId: PropTypes.string.isRequired,
  hasPreviousAddresses: PropTypes.bool.isRequired,
  addressSelectionEnabled: PropTypes.bool.isRequired,
  addressDeletionEnabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onEditingStarted: PropTypes.func.isRequired,
  onEditingDone: PropTypes.func.isRequired,
  media: PropTypes.shape({
    minDesktop: PropTypes.bool,
  }).isRequired,
};

export default withMedia(withAddressesPanelsInfo(AddressList));

import React from 'react';
import styled from 'react-emotion';
import i18next from 'i18next';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import getFirstNChilren from '~/modules/core/utils/jsHelpers/getFirstNChildrenInArray';
import { TabLabel } from '~/modules/ecommerceCoreUI/components/basic/Labels';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const FlexLink = styled(ExternalLink)`
  display: flex;
  align-items: center;
`;
const getOpenAllDepartmentsIcon = () => (
  <FlexLink key="productTracks" url={urlFor('getProductTracksLink')}>
    <Spacer size={2.5} />
    <img title={i18next.t('aykLayout:ToolTip.moreImage')} alt="more" src={`/${imagesPathName}/Header/more.png`} />
  </FlexLink>
);

const DepartmentsLinks = ({ queryResult }) => {
  if (!queryResult) {
    return [];
  }
  const { taxons } = queryResult;
  let departmentsArray = [];
  const MAX_LINKS_TO_SHOW = 6;
  const firstNChildren = getFirstNChilren(taxons, MAX_LINKS_TO_SHOW);

  departmentsArray = firstNChildren.map(taxon => (
    <FlexLink key={taxon.ref_id} url={urlFor('getDepartmentLink', [taxon.ref_id, taxon.name])}>
      <Row>
        <Spacer size={2.5} />
        <TabLabel>{taxon.name}</TabLabel>
      </Row>
    </FlexLink>
  ));

  const openAllDepartmentsIcon = taxons.length > MAX_LINKS_TO_SHOW ? getOpenAllDepartmentsIcon() : [];

  return [...departmentsArray, ...[openAllDepartmentsIcon]];
};

DepartmentsLinks.propTypes = {
  queryResult: PropTypes.shape({}),
};

export default withRootQuery(
  DepartmentsLinks,
  graphql`
    query DepartmentsLinksQuery {
      taxons(type: department) {
        id
        ref_id
        name
      }
    }
  `,
  DepartmentsLinks,
);

import { graphql } from 'react-relay';

export const ConnectSocialAccountMutationRoot = 'connect_social_account';

export default graphql`
  mutation ConnectSocialAccountMutation(
    $token: String!
    $secret: String
    $email: String
    $pid: String
    $provider: AvailableSocialMedia!
  ) {
    connect_social_account(
      token: $token
      secret: $secret
      email: $email
      pid: $pid
      provider: $provider
    ) {
      errors {
        code
        field
        messages
      }
      status
    }
  }
`;

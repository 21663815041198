import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { Container } from 'semantic-ui-react';
import styled from 'react-emotion';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import AdSystemCard from './AdSystemCard';

const RowWrapper = styled(Row)`
  ${cssMediaMax.tablet`
    flex-direction: column;
  `}
`;
const AdSystemWrapper = styled.div`
  background: #faf9f9;
`;
const CardWrapper = styled.div`
  display: block;
  width: 33.3%;
`;
const AdSystem = ({ queryResult, media }) => (
  <AdSystemWrapper>
    {queryResult && queryResult.advertisements.length >= 3
      && media.minDesktop && (
        <Container>
          <Spacer size={2.5} />
          <RowWrapper topAligned spaceBetween={2.5}>
            {queryResult.advertisements.map(Advertising => (
              <CardWrapper key={Advertising.id}>
                <AdSystemCard {...Advertising} />
              </CardWrapper>
            ))}
          </RowWrapper>
          <Spacer size={2.5} />
        </Container>
    )}
  </AdSystemWrapper>
);
AdSystem.propTypes = {
  queryResult: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    button_text: PropTypes.string,
    title_color: PropTypes.string,
    button_color: PropTypes.string,
    button_text_color: PropTypes.string,
    large_photo_object: PropTypes.shape({
      url_original: PropTypes.string,
    }),
  }),
  media: PropTypes.objectOf(PropTypes.any).isRequired,
};

AdSystem.defaultProps = {
  queryResult: null,
};

export default withMedia(withRootQuery(
  AdSystem,
  graphql`
    query AdSystemQuery {
      advertisements(limit: 3, ad_section: system_ad, kind: web) {
        ...WithAdvertisingData_Advertising @relay(mask: false)
      }
    }
  `,
  AdSystem,
));

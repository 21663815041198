import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const NavImage = styled.img`
  width: 42px;
  height: 42px;
  margin: auto;
  padding: 5px;

  object-fit: cover;
  object-position: center;

  border-width: ${props => props.theme.borders.size.bold + 2}px;
  border-radius: ${props => props.theme.borders.radius.normal}px;
`;

const NavSliderImage = ({ image }) => <NavImage src={image} alt={image} />;

NavSliderImage.propTypes = {
  image: PropTypes.string.isRequired,
};

export default NavSliderImage;

import ReactGA from 'react-ga';
import { removeLocaleFromPath } from '~/modules/ecommerceCore/utils/changeLanguage';
import TagManager from 'react-gtm-module';

export const initializeGA = () => {
  ReactGA.initialize('UA-134877913-1');
  ReactGA.plugin.require('ecommerce');

  TagManager.initialize({
    gtmId: 'GTM-KHK8BLQ',
  });
};

export const pageVisited = (pathname) => {
  const path = removeLocaleFromPath(pathname);
  ReactGA.pageview(path, path);
};

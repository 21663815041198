import i18next from 'i18next';
import ToolTip from './tooltip';
import breadcrumbs from './breadcrumbs';

const loadLocales = () => {
  i18next.addResourceBundle(
    'en',
    'aykLayout',
    {
      metadata: {
        appName: 'BADR Archetype',
        displayName: 'Ayk Layout',
        description: 'Contains the header, footer and content layout, for Ayk',
      },
      home: {
        menuTitle: 'Ayk Layout',
      },
      homeTitle: 'Home',
      HeaderTrackOrders: 'Track Your Orders',
      HeaderAccount: 'My Account',
      HeaderSupport: 'Technical Support',
      HeaderPrize: 'Daily Prize',
      submenu: {
        tracks: 'Tracks',
        currency: 'Currency',
        language: 'Language',
      },
      HeaderSubMenuTracks: 'Tracks',
      HeaderSubMenuCurrency: 'Currency',
      HeaderSubMenuLanguage: 'Language',

      HeaderProductItems: 'No Items',

      MostPopular: {
        title: 'Most Popular',
        subtitle: 'Enjoy our popular products',
      },
      NewArrivals: {
        title: 'New Arrivals',
        subtitle: 'Check our New Arrival Products',
      },

      header: {
        allProducts: 'Products',
        shopsLoading: 'Loading...',
        search: 'Search',
      },
      verifyAccount: {
        verifyButton: 'Verify',
        verifyAccount: 'Verify your account to enjoy shopping',
      },
      ToolTip: {
        ...ToolTip.En,
      },
      breadcrumbs: {
        ...breadcrumbs.En,
      },
    },
    true,
    true,
  );

  i18next.addResourceBundle(
    'ar',
    'aykLayout',
    {
      metadata: {
        appName: 'BADR Archetype',
        displayName: 'Ayk Layout',
        description: 'Contains the header, footer and content layout, for Ayk',
      },
      home: {
        menuTitle: 'Ayk Layout',
      },
      homeTitle: 'الصفحة الرئيسية',
      HeaderTrackOrders: 'تتبع طلبك',
      HeaderAccount: 'حسابي',
      HeaderSupport: 'الدعم الفنى',
      HeaderPrize: 'Daily Prize',

      HeaderSubMenuTrack: 'الاقسام',
      HeaderSubMenuCountry: 'العملة',
      HeaderSubMenuLanguage: 'اللغة',

      HeaderProductItems: 'العربة فارغة',
      submenu: {
        tracks: 'الاقسام',
        currency: 'العملة',
        language: 'اللغة',
      },
      MostPopular:
      {
        title: 'الأكثر شعبية',
        subtitle: 'تتمتع لدينا المنتجات الشعبية',
      },
      NewArrivals:
      {
        title: 'القادمون الجدد',
        subtitle: 'تحقق لدينا المنتجات وصول جديدة',
      },

      header: {
        allProducts: 'المنتجات',
        shopsLoading: 'تحميل...',
        search: 'بحث',
      },
      verifyAccount: {
        verifyButton: 'تفعيل',
        verifyAccount: 'قم بتفعيل حسابك و استمتع بالتسوق',
      },
      ToolTip: {
        ...ToolTip.Ar,
      },
      breadcrumbs: {
        ...breadcrumbs.Ar,
      },
    },
    true,
    true,
  );

  i18next.loadNamespaces('aykLayout');
};

export default loadLocales;

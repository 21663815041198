import { graphql } from 'react-relay';

export const BuySmartCartMutationRoot = 'add_smart_cart';

export const BuySmartCartMutation = graphql`
    mutation BuySmartCartMutation($smart_cart_id: String) {
      add_smart_cart_to_cart(smart_cart_id: $smart_cart_id) {
        errors {
          code
          field
          messages
        }
        cart {
          ...WithCartData_cart @relay(mask: false)
        }
      }
    }
  `;

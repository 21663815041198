/* eslint-disable jsx-a11y/anchor-is-valid, function-paren-newline */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Shiitake from 'shiitake';
import i18next from 'i18next';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { ItemOptions, ItemOption } from '~/modules/ecommerceOrder/components/ItemOptions';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';

import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import ProductsLeftInStockCounter from './areas/ProductsLeftInStockCounter';
import Delete from './areas/Delete';
import AddToCart from './areas/AddToCart';
import ItemQuantity from './areas/ItemQuantity';
import ItemImage from './areas/ItemImage';
import Price from './areas/Price';
import ShortMessagesError from './areas/ShortMessagesError';

import LayoutMobile from './LayoutMobile';
import LayoutDesktop from './LayoutDesktop';
import LayoutDropdown from './LayoutDropdown';

const SMART_CART_MAX_ITEMS = 100;

const LineItem = (props) => {
  // eslint-disable-next-line no-nested-ternary
  const Layout = props.isDropdown
    ? LayoutDropdown
    : props.media.minDesktop
      ? LayoutDesktop
      : LayoutMobile;

  const { lineItem } = props;
  let shortErrors = !props.isSmartCart && lineItem.warnings.filter(warning => warning.field.includes('short_message'));
  shortErrors = _.flattenDeep(shortErrors);

  const priceShortErrors = !props.isSmartCart && (
    shortErrors
      .filter(warning => warning.field.includes('price'))
      .map(warning => warning));

  const nonPriceShortErrors = !props.isSmartCart && (
    shortErrors
      .filter(warning => !warning.field.includes('price'))
      .map(warning => warning));

  // FIXME: Should be implemented as /* lineItem.variant.total_on_hand === 0 */
  //        But the following workaround is due to a bug in the backend.
  const outOfStockWarningExists = !props.isSmartCart && (
    _.some(
      lineItem.warnings,
      warning => warning.field === 'out_of_stock_warning_short_message',
    ));

  const getLeftInStockWarning = () => {
    const totalNumber = lineItem.variant.total_on_hand;
    const isActiveShop = lineItem.variant.vendor.active;

    if (isActiveShop) {
      if (totalNumber > 0 && totalNumber < 4) {
        return (
          <ProductsLeftInStockCounter stockItemsLength={totalNumber} />
        );
      }

      if (totalNumber > 3) {
        return (
          <ProductsLeftInStockCounter stockItemsLength={0} />
        );
      }
    }

    return null;
  };

  return (
    <Layout
      itemImage={props.isSmartCart ? (
        <ExternalLink url={urlFor('getProductDetailsLink', [lineItem.variant.slug, lineItem.variant.vendor_variants_count, lineItem.variant.min_variant?.vendor])}>
          <ItemImage src={lineItem.variant.images[0] || ''} />
        </ExternalLink>
      ) : (
        <ItemImage src={lineItem.variant.images[0] || ''} />
      )} // TODO: TRASH_DATA_WORKAROUND : Backend is supposed to ensure validity of this
      itemLabel={props.isSmartCart ? (
        <ExternalLink url={urlFor('getProductDetailsLink', [lineItem.variant.slug, lineItem.variant.vendor_variants_count, lineItem.variant.min_variant?.vendor])}>
          <Label semiBold important size={props.isDropdown ? 'sm' : ['sm', 'sm', 'md']}>
            <Shiitake lines={2}>{lineItem.variant.name}</Shiitake>
          </Label>
        </ExternalLink>
      ) : (
        <Label semiBold important size={props.isDropdown ? 'sm' : ['sm', 'sm', 'md']}>
          <Shiitake lines={2}>{lineItem.variant.name}</Shiitake>
        </Label>
      )}
      vendorLabel={(
        <Label size={props.isDropdown ? 'xs' : ['xs', 'xs', 'sm']}>
          <Shiitake lines={2}>{lineItem.variant.vendor.name}</Shiitake>
        </Label>
     )}
      itemOptions={(
        <ItemOptions spaceBetween={0.5}>
          {lineItem.variant.option_values
            && lineItem.variant.option_values.map(option => (
              <ItemOption key={option.id} isDropdown={props.isDropdown}>
                {option.presentation}
              </ItemOption>
            ))}
        </ItemOptions>
      )}
      price={<Price isTotalPrice={false} isDropdown={props.isDropdown} value={lineItem.variant.price} />}
      totalPrice={<Price isTotalPrice value={lineItem.amount} />}
      priceWarnings={priceShortErrors && priceShortErrors.length > 0 ? (
        <ShortMessagesError isDropdown={props.isDropdown} warnings={priceShortErrors} />
      ) : (
        <span />
      )}
      itemQuantity={(!props.isSmartCart || props.isCurrentUserSmartCart) ? (
        <ItemQuantity
          isDropdown={props.isDropdown}
          quantity={lineItem.quantity}
          warnings={!props.isSmartCart ? lineItem.warnings : null}
          variantId={lineItem.variant.ref_id}
          disabled={lineItem.variant.total_on_hand === 0}
          totalOnHand={props.isSmartCart ? SMART_CART_MAX_ITEMS : lineItem.variant.total_on_hand}
          smartCartId={props.smartCartId}
          variantServices={lineItem.variant_services}
        />
      ) : (
        <Column>
          <Spacer size={0.5} />
          <Row spaceBetween={0.5}>
            <Label primary>{lineItem.quantity}</Label>
            <Label>
              {i18next.t('ecommerceOrder:smartCart.itemsLabel')}
            </Label>
          </Row>
        </Column>
      )}
      actions={(
        <Row spaceBetween={0.5}>
          {(props.isCurrentUserSmartCart || !props.isSmartCart) && (
            <Delete
              isDropdown={props.isDropdown}
              variantId={lineItem.variant.ref_id}
              guestToken={lineItem.guest_token}
              smartCartId={props.smartCartId}
              variantServices={lineItem.variant_services}
            />
          )}
          {props.isSmartCart && props.isCurrentUserSmartCart && <Separator vertical spacerSize={1} separatorLength="xLarge" />}
          {props.isSmartCart && (
            <AddToCart
              isDropdown={props.isDropdown}
              lineItem={lineItem}
            />
          )}
        </Row>
      )}
      nonPriceWarnings={nonPriceShortErrors && nonPriceShortErrors.length > 0 ? (
        <ShortMessagesError
          isDropdown={props.isDropdown}
          warnings={nonPriceShortErrors}
          isOutOfStockWarning={outOfStockWarningExists}
        />
      ) : (
        <span />
      )}
      leftInStockWarning={!props.isSmartCart ? getLeftInStockWarning() : null}
    />
  );
};

LineItem.defaultProps = {
  isDropdown: false,
  smartCartId: null,
  isSmartCart: false,
  isCurrentUserSmartCart: true,
};

LineItem.propTypes = {
  lineItem: PropTypes.shape({}).isRequired,
  media: PropTypes.shape({
    minDesktop: PropTypes.bool.isRequired,
  }).isRequired,
  isDropdown: PropTypes.bool,
  smartCartId: PropTypes.string,
  isSmartCart: PropTypes.bool,
  isCurrentUserSmartCart: PropTypes.bool,
};

export default withMedia(LineItem);

import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import ItemCard from '~/modules/coreUI/components/itemCard';
import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

const ProductCard = ({ productInfo, isVertical }) => (
  <ItemCard
    isProduct
    isVertical={isVertical}
    itemInfo={productInfo}
  />
);

ProductCard.propTypes = {
  productInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    discount: PropTypes.number,
  }).isRequired,
  isVertical: PropTypes.bool,
};

ProductCard.defaultProps = {
  isVertical: true,
};

export default withFragmentContainer(
  ProductCard,
  graphql`
    fragment RecommendedProductCard_productInfo on Product {
      id
      slug
      vendor_variants_count
      min_variant {
        vendor{
          name
          ref_id
        }
        slug
        name
        ref_id
        root_variant_id
        discount_expire_at
        remaining_usage_percentage
        total_on_hand
        price
        original_price
      }
      gift_image {
        url_large
        url_original
      }
      gift_text
      gift_included
      ref_id
      images
      name
      avg_rating
      rating_count
      price
      discount
      has_discount
      has_offer
      hot_deal
      wish_listed
      root_variant {
        ref_id
        wish_listed
      }
    }
  `,
);

import React, { Component } from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Column } from '~/modules/coreUI/components/legacies/Columns';
import Counter from '~/modules/coreUI/components/basic/Counter';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import { getTokenFromLocalStorage } from '~/modules/core/utils/orderManagementHelpers/shoppingCartLocalStorageUtils';
import { notifyUnexpectedError } from '~/modules/ecommerceOrder/containers/shoppingCart/utils/ErrorsProcessor';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';

const Mutation = graphql`
  mutation ItemQuantityMutation($input: CartItemInput!) {
    cart_add_item(input: $input) {
      cart {
        ...WithCartData_cart @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;

const MutationRoot = 'cart_add_item';

class ItemQuantity extends Component {
  state = {
    accumulator: 0,
    loading: false,
  };

  quantityMutation = _.debounce(() => {
    const { accumulator } = this.state;
    const sign = accumulator > 0 ? 1 : -1;
    let quantityDiff = accumulator;

    if (this.props.quantity > this.props.totalOnHand) {
      quantityDiff = (this.props.quantity - this.props.totalOnHand) * sign;
    } else if (this.props.quantity < 1) {
      quantityDiff = -1 * this.props.quantity + 1;
    }

    commitMutation(
      this.props.environment,
      Mutation,
      MutationRoot,
      {
        input: {
          vendor_variant_id: this.props.variantId,
          quantity: quantityDiff,
          guest_tokens: getTokenFromLocalStorage(),
          smart_cart_id: this.props.smartCartId,
          variant_service_ids: this.getArrayOfVariantServices(),
        },
      },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
    this.setState({ accumulator: 0 });
  }, 500);

  getArrayOfVariantServices = () => {
    const variantServices = _.map(this.props.variantServices, element => element.ref_id);
    return variantServices;
  }

  onMutationError = (errors) => {
    this.setState({
      loading: false,
    });
    notifyUnexpectedError(this.props, errors);
  };

  onMutationSuccess = () => {
    this.setState({
      loading: false,
    });
  };

  onMutationLoading = () => {
    this.setState({
      loading: true,
    });
  };

  increaseCounter = () => {
    this.increaseAccumulator();
    this.quantityMutation();
  };

  decreaseCounter = () => {
    this.decreaseAccumulator();
    this.quantityMutation();
  };

  increaseAccumulator = () => {
    this.setState((prevState) => {
      if (this.props.quantity + prevState.accumulator <= this.props.totalOnHand - 1) {
        return { accumulator: prevState.accumulator + 1 };
      }
      return {};
    });
  };

  decreaseAccumulator = () => {
    this.setState((prevState) => {
      if (this.props.quantity + prevState.accumulator >= 1) {
        return { accumulator: prevState.accumulator - 1 };
      }
      return {};
    });
  };

  render() {
    return (
      <Column centerJustified centerAligned spaceBetween={1}>
        <Counter
          loading={this.state.loading}
          size={this.props.isDropdown ? 'xs' : ['xs', 'xs', 'lg']}
          value={this.props.quantity}
          min={1}
          disabled={this.props.disabled}
          max={this.props.totalOnHand}
          increase={this.increaseCounter}
          decrease={this.decreaseCounter}
          minWidth={this.props.isDropdown ? 7.5 : [7.5, 7.5, 10.5]}
        />
      </Column>
    );
  }
}

ItemQuantity.defaultProps = {
  disabled: false,
  totalOnHand: undefined,
  smartCartId: null,
  variantServices: [],
};

ItemQuantity.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  variantServices: PropTypes.arrayOf(PropTypes.shape({})),
  quantity: PropTypes.number.isRequired,
  variantId: PropTypes.string.isRequired,
  smartCartId: PropTypes.string,
  totalOnHand: PropTypes.number,
  isDropdown: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default withAlertMsg(withCartInfo(
  withRelayEnvironment(
    ItemQuantity,
  ),
));

import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';

import BrandsSlider from './BrandsSlider';

const BrandsSliderItems = styled.div`
  a {
    width: 50% !important;
    display: inline-block;
  }
  img {
    width: 100% !important;
    cursor: pointer;
  }
`;

class Brands extends React.PureComponent {
  render = () => {
    const { queryResult, isLoading } = this.props;
    return (
      <BrandsSliderItems>
        <Spacer size={2.5} />
        {!isLoading && (
          <BrandsSlider>
            {queryResult
              && queryResult.list_brands.map(
                brand => brand.logo_url && (
                <ExternalLink
                  url={urlFor('getBrandLink', [brand.ref_id, brand.name])}
                  target="_self"
                  key={brand.id}
                >
                  <img src={brand.logo_url} alt={brand.logo_url} title={brand.name} />
                </ExternalLink>
                ),
              )}
          </BrandsSlider>
        )}
        <Spacer size={[2.5, 2.5, 2.5, 4]} />
      </BrandsSliderItems>
    );
  }
}

Brands.propTypes = {
  queryResult: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
};

Brands.defaultProps = {
  queryResult: null,
};
/* eslint-disable function-paren-newline */
export default withRootQuery(
  Brands,
  graphql`
    query BrandsQuery {
      list_brands {
        id
        ref_id
        name
        logo_url
      }
    }
  `,
  Brands,
);

const ShopsDetailsTrans = {
  En: {
    bestSellerTitle: 'Best Sellers',
    bestSellerSubTitle: 'Check the best seller products in our shop',
    departmentTitle: 'Our Products',
    departmentSubTitle: 'Choose between different products departments',
    shareTitle: 'Share',
    policy: 'Our Policy',
    about: 'About Us',
    shippingMethods: 'Shipping Method(s)',
    paymentMethods: 'Payment Method(s)',
    shopInfoTitle: 'Shop Info',
    brands: 'Brands',
    viewAllProducts: 'View All',
    subDepartmentPlaceholder: 'Select Sub Department',
    questionText: 'Have a Question?',
    askShopLink: 'Ask Shop',
  },
  Ar: {
    bestSellerTitle: 'الأكثر مبيعًا',
    bestSellerSubTitle: 'ألق نظرة على المنتجات الأكثر مبيعًا في محلنا',
    departmentTitle: 'منتجاتنا',
    departmentSubTitle: 'اختر من بين أقسام المنتجات المختلفة',
    shareTitle: 'مشاركة',
    policy: 'سياساتنا',
    about: 'من نحن؟',
    shippingMethods: 'طرق الشحن',
    paymentMethods: 'طرق الدفع',
    shopInfoTitle: 'معلومات عن المحل',
    brands: 'العلامات التجارية',
    viewAllProducts: 'عرض الكل',
    subDepartmentPlaceholder: 'اختر القسم الفرعي',
    questionText: 'هل لديك سؤال؟',
    askShopLink: 'اسأل المحل',
  },
};
export default ShopsDetailsTrans;

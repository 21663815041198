/* eslint-disable react/no-unused-prop-types, no-unused-vars, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import LuckyWheelBoxDescription from './LuckyWheelBoxDescription';
import Wheel from './Wheel';

const Wrapper = styled(Row)`
  height: 100vh;
  background: ${props => props.theme.colors.named.white};
  width: ${props => 65 * props.theme.new.spacer}px;
  transition: 0.3s ease-in-out;
`;

class LuckyWheelBox extends Component {
  state = {
    spinWheelDataResult: {},
    spin: false,
  }

  handlePassSpinWheelDataResult = (spinResult) => {
    this.setState({ spinWheelDataResult: spinResult });
  }

  handlePassSpinning = (spin) => {
    this.setState({ spin });
  }

  render() {
    const { spinWheelDataResult, spin } = this.state;
    return (
      <Wrapper>
        <LuckyWheelBoxDescription
          spinWheelResult={this.handlePassSpinWheelDataResult}
          spinning={this.handlePassSpinning}
        />
        <Wheel
          spinWheelDataResult={
            spinWheelDataResult.length !== 0 && spinWheelDataResult
          }
          spin={spin}
        />
      </Wrapper>
    );
  }
}

export default LuckyWheelBox;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecommendedShopCard_shopInfo$ref: FragmentReference;
export type RecommendedShopCard_shopInfo = {|
  +id: string,
  +ref_id: string,
  +logo_url: ?string,
  +track: ?string,
  +name: ?string,
  +follow: ?boolean,
  +avg_rating: ?number,
  +rating_count: ?number,
  +city: ?{|
    +name: ?string
  |},
  +country: ?{|
    +name: ?string
  |},
  +featured: ?boolean,
  +departments: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
  |}>,
  +$refType: RecommendedShopCard_shopInfo$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  v1
];
return {
  "kind": "Fragment",
  "name": "RecommendedShopCard_shopInfo",
  "type": "Shop",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avg_rating",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo_url",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "track",
      "args": null,
      "storageKey": null
    },
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "follow",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ref_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rating_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "city",
      "storageKey": null,
      "args": null,
      "concreteType": "State",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "country",
      "storageKey": null,
      "args": null,
      "concreteType": "Country",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "featured",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "departments",
      "storageKey": null,
      "args": null,
      "concreteType": "TrackDepartment",
      "plural": true,
      "selections": [
        v0,
        v1
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '535f617ccf040c8468b38ac7c9910c0f';
module.exports = node;

const last = arr => arr[arr.length - 1];

function objectToMultilineString(object) {
  return Object.entries(object)
    .map(([key, value]) => value && `${key}: ${value}`)
    .filter(Boolean)
    .join('\n');
}

function createPromise(fn) {
  return (...args) => new Promise((resolve, reject) => {
    fn(...args, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export { last, createPromise, objectToMultilineString };

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import withLuckyWheelInfo from './withLuckyWheelInfo';

const Icon = withDirection(styled.i`
  background: ${props => props.theme.colors.named.white};
  color: ${props => props.theme.colors.primary};
  padding: ${props => props.theme.paddings.medium}px ${props => props.theme.paddings.large - 2}px;
  font-size: ${props => props.theme.fonts.sizes.xLarge}px;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  ${props => (props.direction === 'rtl'
    ? css`
      border-top-right-radius: ${props.theme.borders.radius.normal}px;
      border-bottom-right-radius: ${props.theme.borders.radius.normal}px;
    `
    : css`
      border-top-left-radius: ${props.theme.borders.radius.normal}px;
      border-bottom-left-radius: ${props.theme.borders.radius.normal}px;
    `
  )}
`);
const GiftIcon = ({ handleToggleLuckyWheel }) => (
  <Icon onClick={() => handleToggleLuckyWheel()} className="el-icon el-icon-fortuen-gift" />
);

GiftIcon.propTypes = {
  handleToggleLuckyWheel: PropTypes.func.isRequired,
};

export default withLuckyWheelInfo(GiftIcon);

/* eslint-disable lines-between-class-members */
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Toggle from '~/modules/ecommerceCoreUI/components/basic/Toggle';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';
import { ToggleText } from '~/modules/ecommerceOrder/components/bacis/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import AddressForm from '../forms/AddressForm';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

import withAddressesPanelsInfo from '../context/withAddressesPanelsInfo';

import AddressList from '../list/AddressList';
import { bodyStyle } from './AddressPanelStyles';

const ToggleShipingContainer = withMedia(styled(Row)`
  padding: ${props => 2 * props.theme.new.spacer}px ${props => 1.5 * props.theme.new.spacer}px;
  padding-top: 0px;
  margin-bottom: ${props => 1.5 * props.theme.new.spacer}px;
  border-bottom: ${props => (!props.isChecked ? `1px solid ${props.theme.new.colors.labels.borders.normal.subtle}` : 'none')};
  border-radius: 0px;
  ${props => props.media.maxMobile && css`
     padding: ${2 * props.theme.new.spacer}px ${1.5 * props.theme.new.spacer}px;
  `}
`);

const FormButton = props => (
  <Button sm width={12} {...props}>
    {i18next.t(`ecommerceOrder:${props.i18Key}`)}
  </Button>
);

class AddressPanel extends Component {
  state = {
    newAddressFormVisible: false,
    editingModeActive: false,
    panelError: null,
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  resetFormError = () => this.setState({ panelError: null });

  hideAddNewAddressForm = () => {
    this.props.notifyAlert({
      messageText: i18next.t('ecommerceOrder:Checkout.successMessage'),
      type: AlertTypes.success,
      opacity: 1,
      toastID: 'address_success_toast',
    });
    this.hideAddressForm();
  }

  hideAddressForm = () => {
    this.setState({ newAddressFormVisible: false, panelError: null });
  }

  showAddNewAddressForm = () => this.setState({ newAddressFormVisible: true, editingModeActive: false });

  onEditingStarted = () => this.setState({ editingModeActive: true, newAddressFormVisible: false });
  onEditingDone = () => this.setState({ editingModeActive: false });

  onError = error => this.setState({ panelError: error });
  onLoading = loading => this.setState({ isLoading: loading });

  toggleSameAsShipping = (isChecked) => {
    this.setState({
      panelError: null,
      newAddressFormVisible: false,
      editingModeActive: false,
    });

    this.props.toggleIsSameAsShipping(isChecked);
  }

  isFormDirty = () => this.newAddressForm && this.newAddressForm.isFormDirty();

  validateForm = () => this.newAddressForm.validateForm();
  submitForm = (async = false) => this.newAddressForm.submitForm(async);

  isBilling = () => this.props.type === 'billing';
  hasSavedAddresses = () => this.props.hasPreviousAddresses;
  shouldShowNewAddressForm = () => (!this.state.editingModeActive && this.state.newAddressFormVisible) || !this.hasSavedAddresses();

  renderSameAsShippingToggle = () => (
    <ToggleShipingContainer isChecked={this.props.isSameAsShipping} row spaceBetweenJustified fullWidth>
      <ToggleText>
        {i18next.t('ecommerceOrder:Checkout.toggleText')}
      </ToggleText>
      <Toggle isChecked={this.props.isSameAsShipping} onChange={this.toggleSameAsShipping} />
    </ToggleShipingContainer>
  );

  renderAddNewAddressForm = () => this.shouldShowNewAddressForm() && (
    <Column spaceBetween={1} centerJustified>
      <Column maxWidth={60} fullWidth pl={1.5} pr={1.5}>
        {this.state.panelError && (
          <PanelAlert
            type="error"
            inverted
            title={i18next.t('ecommerceOrder:Checkout.checkFollowingError')}
            errors={this.state.panelError}
          />
        )}
        <AddressForm
          onRef={(ref) => { this.newAddressForm = ref; }}
          onLoading={this.onLoading}
          onError={this.onError}
          onSuccess={this.hideAddNewAddressForm}
          addressMutationInputName={this.isBilling() ? 'bill_address' : 'ship_address'}
          isUpdate={this.hasSavedAddresses()}
          saveAsDefaultIsDisabled={false}
          isBilling={this.isBilling()}
        />
        {this.hasSavedAddresses() && (
          <Row fullWidth rightJustified spaceBetween={2} paddingRight={[0, 0, 0, 0.7]} justifyContent={['center', 'center', 'flex-end']}>
            <FormButton primary inverted i18Key="Checkout.cancelButton" onClicked={this.hideAddressForm} />
            <FormButton secondary loading={this.state.isLoading} i18Key="Checkout.addButton" onClicked={this.submitForm} />
          </Row>
        )}
      </Column>
      {this.hasSavedAddresses() && (
        <Separator separatorColorTone="light" vertical={false} separatorLength="full" />
      )}
    </Column>
  );

  renderAddressList = () => this.hasSavedAddresses() && (
    <AddressList
      addresses={this.props.addresses}
      type={this.props.type}
      onEditingStarted={this.onEditingStarted}
      onEditingDone={this.onEditingDone}
      isUpdate
    />
  )

  isAnyFormOpen = () => this.state.newAddressFormVisible || this.state.editingModeActive;

  isBillingPanelActive = () => this.isBilling() && this.props.isSameAsShipping;

  getAddressPanelHeaderTitle = () => (this.isBilling()
    ? i18next.t('ecommerceOrder:Checkout.billingAddressTitle')
    : i18next.t('ecommerceOrder:Checkout.shippingAddressTitle')
  );

  getAddressPanelHeaderActions = () => ((this.hasSavedAddresses() && !(this.isBillingPanelActive() || (this.isAnyFormOpen()))) ? [{
    label: <Label secondary size={['sm', 'sm', 'lg', 'lg']}>{i18next.t('ecommerceOrder:Checkout.addNewAddress')}</Label>,
    onClicked: this.showAddNewAddressForm,
    iconName: 'el-icon el-icon-add',
  }] : []);

  shouldShowSameAsShippingToggle = () => this.isBilling() && (
    !this.hasSavedAddresses()
    || (this.hasSavedAddresses() && this.props.showSameAsShippingToggleAfterFirstTime)
  );

  render =() => (
    <Panel
      borderLessHeader={this.props.borderLessHeader}
      bodyStyle={bodyStyle}
      headerTitle={(
        <Label important size={['sm', 'sm', 'lg', 'lg']}>
          {this.getAddressPanelHeaderTitle()}
        </Label>
      )}
      headerActions={
        this.getAddressPanelHeaderActions()
      }
      body={(
        <React.Fragment>
          {this.shouldShowSameAsShippingToggle() && this.renderSameAsShippingToggle()}
          {(!this.isBilling() || !this.props.isSameAsShipping) && (
            <React.Fragment>
              {this.renderAddNewAddressForm()}
              {this.renderAddressList()}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    />
  );
}

AddressPanel.defaultProps = {
  showSameAsShippingToggleAfterFirstTime: true,
  borderLessHeader: false,
};

AddressPanel.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRef: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  toggleIsSameAsShipping: PropTypes.func.isRequired,
  hasPreviousAddresses: PropTypes.bool.isRequired,
  isSameAsShipping: PropTypes.bool.isRequired,
  showSameAsShippingToggleAfterFirstTime: PropTypes.bool,
  borderLessHeader: PropTypes.bool,
  notifyAlert: PropTypes.func.isRequired,
};

export default withAlertMsg(withMedia(withAddressesPanelsInfo(AddressPanel)));

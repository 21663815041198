const ToolTipTrans = {
  En: {
    search: 'Search',
    AYK: 'Ayk Mall',
  },
  Ar: {
    search: 'بحث',
    AYK: 'أيك مول',
  },
};
export default ToolTipTrans;

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';

import ProceedToCheckoutButton from '~/modules/ecommerceOrder/containers/shoppingCart/layouts/areas/ProceedToCheckoutButton';

import { Box, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const LayoutDesktop = (props) => {
  if (!props.cartItemsTable && !props.emptyStatePage) {
    return null;
  }
  return (
    <SemanticContainer>
      <Column fullWidth stretchAligned>
        <Spacer size={3} />

        {props.header}

        {props.emptyStatePage ? (
          <React.Fragment>
            <Spacer size={4} />

            {props.emptyStatePage}

            <Spacer size={4} />

            <Box centerAligned>{props.continueShoppingButton}</Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.errorsAlert && (
              <React.Fragment>
                <Spacer size={2.5} />

                {props.errorsAlert}
              </React.Fragment>
            )}

            <Spacer size={2.5} />

            {props.cartItemsTable}

            <Spacer size={2.5} />

            <Box fullWidth rightAligned>
              {
                props.dataLoaded
                  ? <ProceedToCheckoutButton />
                  : null
              }
            </Box>
          </React.Fragment>
        )}

        <Spacer size={6} />
      </Column>
    </SemanticContainer>
  );
};

LayoutDesktop.propTypes = {
  cartItemsTable: PropTypes.objectOf(PropTypes.any),
  header: PropTypes.element.isRequired,
  errorsAlert: PropTypes.element,
  emptyStatePage: PropTypes.element,
  continueShoppingButton: PropTypes.element.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
};
LayoutDesktop.defaultProps = {
  cartItemsTable: null,
  emptyStatePage: null,
  errorsAlert: null,
};
export default LayoutDesktop;

/* eslint-disable indent */
import React from 'react';
import styled, { css } from 'react-emotion';
import PropType from 'prop-types';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { Label as SemanticTag } from 'semantic-ui-react';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import withOmitedProps from '~/modules/core/utils/cssHelpers/withOmitedProps';

const colors = {
  hotOffer: '#fd464b',
  hasDiscount: '#fdc627',
  offer: '#2adf1c',
};

const StyledTag = withMedia(withDirection(styled(withOmitedProps(SemanticTag, ['forceMediaQuery']))`
  &&& {
    min-height: 30px;
    max-height: 30px;
    height: 30px;
    ${props => props.media.tablet && css`
      height: 24px;
      min-height: auto;
      max-height: auto;
    `}
    ${props => props.media.xsmall && css`
      height: 20px;
      min-height: auto;
      max-height: auto;
    `}
    display: inline-block;
    background-color: ${props => props.backgroundcolor};

    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    ${props => (props.direction === 'rtl'
        ? css`
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          `
        : css`
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          `)
    };
    margin-top: 0;
    padding: 0px 1em;
    span {
      text-transform: capitalize;
      font-weight: ${props => props.theme.fonts.weights.semiBold};
    }

    &:after {
      box-shadow: none;
      ${props => (props.direction === 'rtl'
          ? css`
              left: -2.5px !important;
            `
          : css`
              right: -2.5px !important;
            `)
      };
      ${props => props.media.xsmall && css`
        top: 60%;
      `}
      left: auto;
      width: 4px;
      height: 4px;
    }

    &:before {
      width: 20px;
      height: 20px;
      ${props => props.media.tablet && css`
        width: 17px;
        height: 17px;
      `}
      ${props => props.media.xsmall && css`
        width: 14px;
        height: 14px;
      `}
      ${props => (props.direction === 'rtl'
          ? css`
              left: 0;
            `
          : css`
              right: 0;
            `)
      };
    }

    span:after {
      content: '';
      border: 1px dashed #f5f5f5;
      ${props => (props.direction === 'rtl'
          ? css`
              right: 6px;
            `
          : css`
              left: 6px;
            `)
      };
      position: absolute;
      height: 100%;
      top: 0;
      opacity: 0.75;
    }
  }
`));

const OfferTagText = styled(Label)`
  white-space: nowrap;
`;

const ProductTag = ({ offerType, children }) => (
  <StyledTag backgroundcolor={colors[offerType]} tag>
    <Box fullHeight centerJustified>
      <OfferTagText size={['xxs', 'xs']} inverted important>
        {children}
      </OfferTagText>
    </Box>
  </StyledTag>
);

ProductTag.propTypes = PropType.shape({
  offerType: PropType.oneOf(['hotOffer', 'hasDiscount', 'offer']).isRequired,
  children: PropType.element.isRequired,
}).isRequired;

export default ProductTag;

/* eslint react/require-default-props: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { infereColor } from '~/modules/coreUI/utils/infereStyle';

const handleDefault = (input, default_) => input || default_;

const Wraper = styled(LinearLayout)`
  position: relative;

  border-width: ${props => props.borderWidth || props.theme.inputs.borderWidth}px;
  border-style: ${props => props.borderStyle};
  border-radius: ${props => props.borderRadius || props.theme.inputs.radius}px;
  background-color: ${props => (props.isDisabled ? props.theme.new.colors.backgrounds.lightPanelHeader : '')};
  border-color: ${props => infereColor(props, (props.focused ? handleDefault(props.borderColorActive, props.theme.inputs.borderColorActive) : props.theme.inputs.borderColor))};

  box-sizing: border - box;
  box-shadow: ${props => (props.focused && props.borderGlow ? `0 0 8px 0 ${infereColor(props, props.borderColorAcitve || props.theme.inputs.borderColorActive)}` : 'none')};

  transition: 0.1s;
  outline: none;
`;

const Input = styled.input`
  width: 100%; /* TO BE COMPATIBLE WITH ANY CONTAINER */

  padding-left: ${props => props.inputHorizontalPadding || props.theme.inputs.padding.left}px;
  padding-right: ${props => props.inputHorizontalPadding || props.theme.inputs.padding.right}px;
  padding-top: ${props => props.inputHorizontalPadding || props.theme.inputs.padding.top}px;
  padding-bottom: ${props => props.inputHorizontalPadding || props.theme.inputs.padding.bottom}px;

  order: ${props => (props.iconPosition === 'right' ? '1' : '3')};

  border: 0;
  border-radius: ${props => props.borderRadius || props.theme.inputs.radius}px;
  background-color: ${props => (props.disabled ? props.theme.new.colors.backgrounds.lightPanelHeader : '')};
  outline: none;

  color: ${props => infereColor(props, props.inputColor || props.theme.inputs.color)};
  font-size: ${props => props.inputFontSize || props.theme.inputs.fontSize}px;
  ::placeholder {
    color: ${props => infereColor(props, props.placeholderColor || props.theme.inputs.placeholderColor)};
    font-size: ${props => props.placeholderSize || props.theme.inputs.fontSize}px;
    opacity: 1; /* Firefox */
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 60px white inset;
  }
`;

const NewSpacer = styled(Spacer)`
   order: ${props => (props.iconPosition === 'right' ? '3' : '1')};
`;

const getIconFillColor = props => (
  props.disabled
    ? props.theme.inputs.placeholderColor
    : handleDefault(props.iconColorActive, props.theme.inputs.borderColorActive)
);

const getToggleIconFillColor = props => (
  !props.toggled || props.disabled
    ? props.theme.inputs.placeholderColor
    : handleDefault(props.iconColorActive, props.theme.inputs.borderColorActive)
);

const Icon = styled.i`
  order: 2;

  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  font-size: ${props => props.iconFontSize || props.theme.inputs.fontSize}px;

  color: ${props => infereColor(props, getIconFillColor(props))};
  ${props => (props.password || props.toggleable) && css`
    color: ${infereColor(props, getToggleIconFillColor(props))};
  `};

  transition: 0.1s;
  outline: none;
`;

class TextBox extends Component {
  state = {
    focused: false,
    type: this.props.password ? 'password' : 'text',
    toggled: false,
  };

  onIconClick = () => {
    if (this.props.password || this.props.toggleable) {
      // TODO : Generalize as "toggleable"
      this.setState((prevState) => {
        const newToggleState = !prevState.toggled;

        if (this.props.onIconClick) {
          this.props.onIconClick(newToggleState);
        }

        return {
          type: prevState.type === 'text' ? 'password' : 'text',
          toggled: newToggleState,
        };
      });
    } else if (this.props.onIconClick) {
      this.props.onIconClick();
    }
  }

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  }

  handleBlur = () => {
    this.setState({
      focused: false,
    });
  }

  handleChange = (evt) => {
    if (this.props.onChange) {
      this.props.onChange(evt);
    }
  }

  handleKeyPress = (event) => {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
    }
  }

  render = () => {
    const { onIconClick, ...inputProp } = this.props;
    return (
      <Wraper fullWidth row centerAligned topJustified focused={this.state.focused} {...inputProp} tabIndex={-1}>
        <Input
          {...inputProp}
          type={this.state.type}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onInput={this.handleChange}
          disabled={this.props.isDisabled}
          onKeyPress={this.handleKeyPress}
        />
        {this.props.icon && (
          <React.Fragment>
            <Icon
              disabled={this.props.iconDisabled}
              iconPosition={this.props.iconPosition}
              iconFontSize={this.props.iconFontSize}
              iconColorActive={this.props.iconColorActive}
              password={this.props.password}
              className={this.props.icon}
              aria-hidden="true"
              tabIndex={-1}
              toggled={this.state.toggled}
              toggleable={this.props.toggleaonClickble}
              title={this.props.iconTitle}
              onClick={this.onIconClick}
            />
            <NewSpacer iconPosition={this.props.iconPosition} size={1.5} />
          </React.Fragment>
        )}
      </Wraper>
    );
  }
}

TextBox.propTypes = {
  tooltipContent: PropTypes.string,

  borderWidth: PropTypes.string,
  borderStyle: PropTypes.string,
  borderColorActive: PropTypes.string,
  borderRadius: PropTypes.number,
  borderGlow: PropTypes.bool,

  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconPadding: PropTypes.number,
  iconColorActive: PropTypes.string,

  inputHorizontalPadding: PropTypes.number,
  inputVerticalPadding: PropTypes.number,
  inputColor: PropTypes.number,
  inputFontSize: PropTypes.number,

  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  placeholderSize: PropTypes.number,

  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,

  password: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

TextBox.defaultProps = {
  tooltipContent: '',

  borderStyle: 'solid',
  borderGlow: false,

  icon: '',
  iconPosition: 'left',

  password: false,

  isDisabled: false,

  onKeyPress: null,
};

export default TextBox;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import Media from 'react-media';
import { Container } from 'semantic-ui-react';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import ItemsListSpinner from '~/modules/ecommerceCoreUI/components/listViewLayout/ItemsListSpinner';

import RecommendedTabs from './RecommendedTabs';
import RecommendedSectionResponsive from './RecommendedSectionResponsive';

class TabsContainer extends React.PureComponent {
  render = () => {
    const { queryResult, isLoading } = this.props;
    if (isLoading) {
      return (
        <Container>
          <ItemsListSpinner vertical oneRow itemsCount={7} />
        </Container>
      );
    }
    return (
      <Media query={mediaQueryMin('desktop')}>
        {matches => (matches ? (
          <RecommendedTabs tracks={queryResult.tracks} />
        ) : (
          <RecommendedSectionResponsive tracks={queryResult.tracks} />
        ))
        }
      </Media>
    );
  }
}
TabsContainer.propTypes = {
  queryResult: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    track_type: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
};
TabsContainer.defaultProps = {
  queryResult: null,
};
export default withRootQuery(
  TabsContainer,
  graphql`
    query TabsContainerQuery {
      tracks(input: { only_has_recommended: true }) {
        id
        name
        track_type
      }
    }
  `,
  TabsContainer,
);

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';


const MobileLayout = props => (
  <Column onClick={() => externalRedirect(urlFor('getSmartCartDetailsLink', [props.smartCart?.ref_id]))} leftAligned>
    <Spacer />
    {props.smartName}
    <Spacer size={0.5} />
    {props.noOfItems}
    <Spacer />
    {props.totalPrice}
    <Spacer size={1.5} />
    {props.actions}
    <Spacer size={1.5} />
  </Column>
);

MobileLayout.defaultProps = {
  smartCart: null,
};

MobileLayout.propTypes = {
  noOfItems: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  smartName: PropTypes.element.isRequired,
  totalPrice: PropTypes.element.isRequired,
  smartCart: PropTypes.shape({
    ref_id: PropTypes.string,
  }),
};


export default MobileLayout;

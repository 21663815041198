/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecommendedProductCard_productInfo$ref: FragmentReference;
export type RecommendedProductCard_productInfo = {|
  +id: string,
  +slug: ?string,
  +vendor_variants_count: ?number,
  +min_variant: ?{|
    +vendor: ?{|
      +name: ?string,
      +ref_id: string,
    |},
    +slug: ?string,
    +name: string,
    +ref_id: string,
    +root_variant_id: ?string,
    +discount_expire_at: ?any,
    +remaining_usage_percentage: ?number,
    +total_on_hand: ?number,
    +price: number,
    +original_price: number,
  |},
  +gift_image: ?{|
    +url_large: string,
    +url_original: string,
  |},
  +gift_text: ?string,
  +gift_included: ?boolean,
  +ref_id: string,
  +images: $ReadOnlyArray<?string>,
  +name: string,
  +avg_rating: ?number,
  +rating_count: ?number,
  +price: ?number,
  +discount: ?number,
  +has_discount: ?boolean,
  +has_offer: ?boolean,
  +hot_deal: ?boolean,
  +wish_listed: ?boolean,
  +root_variant: ?{|
    +ref_id: string,
    +wish_listed: ?boolean,
  |},
  +$refType: RecommendedProductCard_productInfo$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RecommendedProductCard_productInfo",
  "type": "Product",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "vendor_variants_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "min_variant",
      "storageKey": null,
      "args": null,
      "concreteType": "Variant",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "vendor",
          "storageKey": null,
          "args": null,
          "concreteType": "Shop",
          "plural": false,
          "selections": [
            v0,
            v1
          ]
        },
        v2,
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "root_variant_id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "discount_expire_at",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "remaining_usage_percentage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total_on_hand",
          "args": null,
          "storageKey": null
        },
        v3,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "original_price",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "gift_image",
      "storageKey": null,
      "args": null,
      "concreteType": "ImageAttachment",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url_large",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url_original",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gift_text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gift_included",
      "args": null,
      "storageKey": null
    },
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "images",
      "args": null,
      "storageKey": null
    },
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avg_rating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rating_count",
      "args": null,
      "storageKey": null
    },
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "discount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "has_discount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "has_offer",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hot_deal",
      "args": null,
      "storageKey": null
    },
    v4,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "root_variant",
      "storageKey": null,
      "args": null,
      "concreteType": "Variant",
      "plural": false,
      "selections": [
        v1,
        v4
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c39b065cb6647d5d6884936f2a79fad';
module.exports = node;

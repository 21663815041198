/* eslint-disable */
import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Column } from '~/modules/coreUI/components/legacies/Columns';
import { Label } from '~/modules/coreUI/components/legacies/Labels';
import assert from '~/modules/core/utils/jsHelpers/assert';
import Icon from '~/modules/coreUI/components/basic/Icon';
// import { Spacer } from '~/modules/coreUI/components/legacies/Spacers';

const getBorderWeight = props => {
  if (props.borderLess) {
    return 0;
  }
  return props.theme.buttons.border || 1;
};

const getBackgroundColor = props => {
  if (props.disabled) {
    if (props.inverted) {
      return props.theme.buttons.disabled.backgroundColor.inverted;
    }
    return props.theme.buttons.disabled.backgroundColor.normal;
  }

  if (props.inverted) {
    return props.theme.colors.named.white;
  }

  return props.primary ? props.theme.colors.primary : props.theme.colors.secondary;
};

const getColor = props => {
  if (!props.inverted) {
    return props.theme.colors.named.white;
  }

  return props.primary ? props.theme.colors.primary : props.theme.colors.secondary;
};

const getHoverColor = props => {
  if (!props.inverted) {
    return props.theme.colors.named.white;
  }

  return props.primary ? props.theme.colors.primaryHover : props.theme.colors.secondaryHover;
};

const getClickedColor = props => {
  if (!props.inverted) {
    return props.theme.colors.named.white;
  }

  return props.primary ? props.theme.colors.primaryClicked : props.theme.colors.secondaryClicked;
};

const getHoverBackgroundColor = props => {
  if (props.disabled) {
    if (props.inverted) {
      return props.theme.buttons.disabled.backgroundColor.inverted;
    }
    return props.theme.buttons.disabled.backgroundColor.normal;
  }

  if (props.inverted) {
    return props.theme.colors.named.white;
  }

  return props.primary ? props.theme.colors.primaryHover : props.theme.colors.secondaryHover;
};

const getClickedBackgorundColor = props => {
  if (props.disabled) {
    if (props.inverted) {
      return props.theme.buttons.disabled.backgroundColor.inverted;
    }
    return props.theme.buttons.disabled.backgroundColor.normal;
  }

  if (props.inverted) {
    return props.theme.colors.named.white;
  }

  return props.primary ? props.theme.colors.primaryClicked : props.theme.colors.secondaryClicked;
};

const semiCircleBorderRadius = props => {
  if (props.semiCircle) {
    return '50vh';
  }

  return `${props.radius ? props.radius : props.theme.buttons.radius}px`;
};

const getPadding = props => {
  if (props.borderLess) {
    return 0;
  } else if (props.padding) {
    return props.padding;
  } else if (props.fontSize) {
    return props.theme.buttons.padding * (props.fontSize / props.theme.buttons.fontSize);
  }
  return props.theme.buttons.padding;
};

const ButtonLabelStyle = props => css`
  font-size: ${props.fontSize || props.theme.buttons.fontSize}px;

  color: ${getColor(props)};

  &:hover {
    color: ${getHoverColor(props)};
  }

  &:active {
    color: ${getClickedColor(props)};
  }
`;

// Must be of relative position for the loading icon to be drawn correctly
const Button = styled(Column)`
  width: '100%';
  position: relative;

  background-color: ${props => getBackgroundColor(props)};
  border: ${props => getBorderWeight(props)}px solid;
  border-color: ${props => getColor(props)};
  border-radius: ${props => semiCircleBorderRadius(props)};

  padding: ${props => getPadding(props)}px;
  padding-left: ${props => props.paddingX || getPadding(props) * 1.2}px;
  padding-right: ${props => props.paddingX || getPadding(props) * 1.2}px;

  cursor: pointer;

  &:hover {
    color: ${props => getHoverColor(props)};
    background-color: ${props => getHoverBackgroundColor(props)};
    border-color: ${props => getHoverColor(props)};
  }

  &:active {
    color: ${props => getClickedColor(props)};
    background-color: ${props => getClickedBackgorundColor(props)};
    border-color: ${props => getClickedColor(props)};
  }
`;

const HiddenActualButton = styled.button`
  opacity: 0;
  position: absolute;

  &:focus + div {
    color: ${props => getHoverColor(props)};
    background: ${props => getHoverBackgroundColor(props)};
    border-color: ${props => getHoverColor(props)};

    span {
      color: ${props => getHoverColor(props)};
    }
  }
`;

const ButtonLoadingIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: ${props => props.theme.buttons.padding}px;
  height: 100%;
`;

const ButtonContainer = styled.div`
  width: ${props => (props.block ? '100%' : props.width)};
`;

const omitNotRelatedProps = props => _.omit(props, 'onClicked');

export class BasicButton extends React.Component {
  componentDidMount = () => {
    // FIXME : The reason for the following work around, is that onClick would be called on the
    //         External component first, and thus causes the onClick being called twice
    // eslint-disable-next-line react/prop-types
    assert(!this.props.onClick, "onClick shouldn't be used on BasicButton, use onClicked instead");
  };

  onClick = e => {
    if (this.props.onClicked) {
      this.props.onClicked(e);
    }
  };

  render = () => (
    <ButtonContainer {...omitNotRelatedProps(this.props)}>
      <HiddenActualButton {...omitNotRelatedProps(this.props)} />
      <Button
        {...omitNotRelatedProps(this.props)}
        onClick={e => this.onClick(e)}
        centerAligned
        centerJustify
        width={this.props.width}
      >
        {this.props.loading && <ButtonLoadingIcon icon={faSpinner} spin />}
        <Label {...omitNotRelatedProps(this.props)} customStyle={ButtonLabelStyle}>
          {this.props.iconName && (
            <React.Fragment>
              <Icon className={this.props.iconName} />
              {/* TODO: Need to use spacer! */}
              <span>{'\t'}</span>
              {/* <Spacer size={1} /> */}
            </React.Fragment>
          )}
          {this.props.children}
        </Label>
      </Button>
    </ButtonContainer>
  );
}

BasicButton.defaultProps = {
  fontSize: null,
  borderLess: false,
  loading: false,
  width: null,
  iconName: null,
  onClicked: null,
};

BasicButton.propTypes = {
  fontSize: PropTypes.number,
  borderLess: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  loading: PropTypes.bool,
  width: PropTypes.string,
  onClicked: PropTypes.func,
  iconName: PropTypes.string,
};

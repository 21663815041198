const ToolTipTrans = {
  En: {
    location: 'Location',
    delete: 'Delete',
    edit: 'Edit',
  },
  Ar: {
    location: 'الموقع',
    delete: 'حذف',
    edit: 'تعديل',
  },
};
export default ToolTipTrans;

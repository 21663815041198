import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const customIconStyle = css`
  font-size: 37px;
  ${cssMediaMax.xsmall`
    font-size: 25px;
  `};
`;
class AykIconOnlyButton extends React.PureComponent {
  render = () => {
    const {
      iconName,
      isVertical,
      title,
      onClick,
    } = this.props;
    return (
      <IconOnlyButton iconName={iconName} isVertical={isVertical} title={title} customIconStyle={customIconStyle} onClick={onClick} />
    );
  }
}

AykIconOnlyButton.propTypes = PropTypes.shape({
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string,
  isVertical: PropTypes.bool,
  onClick: PropTypes.func,
}).isRequired;

export default AykIconOnlyButton;

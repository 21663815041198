/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import MembershipsDetails from './MembershipsDetails';
import { Row, Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { LargeLabel, XSmallLabel, MediumLabel } from '~/modules/coreUI/components/legacies/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { kIntergerFormatter } from './KFormatterUtily';

const MemberShip = styled(Box)`
  border: 1px solid ${props => props.cardColor};
  border-radius: 5px;
  width: 225px;
  height: 89px;
  text-align: center;
  opacity: 0.5;
  &&.active {
    opacity: 1;
  }
  ${cssMediaMax.tablet`
    border: none;
    flex-direction: column;
  `}
`;
const RowWrapper = styled(Row)`
  ${cssMediaMax.tablet`
    flex-direction: column;
  `}
`;
const LabelCustomStyle = styled(LargeLabel)`
  display: block;
  text-align: center;
  color: ${props => props.textColor};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.small}px;
  `}
`;
const MembershipImage = styled.img`
  display: inline-block;
  width: ${props => 4 * props.theme.new.spacer}px;
  ${props => cssMediaMax.tablet`
    width: ${5.7 * props.theme.new.spacer}px;
  `}
  ${props => cssMediaMax.xsmall`
    width: ${4 * props.theme.new.spacer}px;
  `}
`;
const Points = styled(MediumLabel)`
  color: ${props => props.cardColor};
  text-transform: capitalize;
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.small - 1}px;
  `}
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.xSmall}px;
  `}
`;
const StartFrom = styled(XSmallLabel)`
  display: inline-block;
  margin: 0 5px;
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.xxSmall}px;
  `}
`;
const renderPointString = point => (
  <React.Fragment>
    {point > 1
      ? <React.Fragment>{i18next.t('ecommerceOrder:Loyalty.points')}</React.Fragment>
      : <React.Fragment>{i18next.t('ecommerceOrder:Loyalty.point')}</React.Fragment>
    }
  </React.Fragment>
);

const AykMemberShip = ({
  queryResult: {
    ayk_loyalty_program: {
      silver_start_at,
      gold_start_at,
      diamond_start_at,
    },
  },
  degree,
  media,
}) => (
  <React.Fragment>
    <Row spaceAroundJustified>
      {MembershipsDetails.map(Membership => (
        <MemberShip
          centerJustified
          cardColor={Membership.backgroundColor}
          textColor={Membership.textColor}
          className={Membership.type === degree && 'active'}
          spaceBetween={0.5}
          key={Membership.text}
        >
          <MembershipImage src={Membership.crownSrc} alt="crown" />
          <LabelCustomStyle color={Membership.textColor}>
            {media.maxTablet ? i18next.t(Membership.smallText) : i18next.t(Membership.text)}
          </LabelCustomStyle>
          <RowWrapper spaceBetween={0.5}>
            <Points color={Membership.textColor}>
              {Membership.type === 'silver' && (
                <React.Fragment>
                  <StartFrom>{i18next.t('ecommerceCoreUI:Product.startFrom')}</StartFrom>
                  {kIntergerFormatter(silver_start_at)}
                  {' '}
                  {renderPointString(silver_start_at)}
                </React.Fragment>
              )}
              {Membership.type === 'gold' && (
                <React.Fragment>
                  <StartFrom>{i18next.t('ecommerceCoreUI:Product.moreThan')}</StartFrom>
                  {kIntergerFormatter(gold_start_at)}
                  {' '}
                  {renderPointString(gold_start_at)}
                </React.Fragment>
              )}
              {Membership.type === 'diamond' && (
                <React.Fragment>
                  <StartFrom>{i18next.t('ecommerceCoreUI:Product.moreThan')}</StartFrom>
                  {kIntergerFormatter(diamond_start_at)}
                  {' '}
                  {renderPointString(diamond_start_at)}
                </React.Fragment>
              )}
            </Points>
          </RowWrapper>
        </MemberShip>
      ))}
    </Row>
    <Spacer size={1.5} />
  </React.Fragment>
);
AykMemberShip.propTypes = {
  degree: PropTypes.string.isRequired,
  media: PropTypes.shape({}).isRequired,
  queryResult: PropTypes.shape({}),
};

AykMemberShip.defaultProps = {
  queryResult: [],
};

export default withMedia(AykMemberShip);

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Media from 'react-media';
import styled from 'react-emotion';

import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import Button from '~/modules/coreUI/components/basic/Button';
import { XXXXXLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import { CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import Panel, { PanelRoot } from '~/modules/accountManagement/components/basic/Panel';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { navigateToModal, scrollCurrentModalToTop } from '~/modules/core/utils/modalHelpers';
import withUserInfo, { getUserInfoFromLoginResponse } from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import { getLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';

import SignupForm from './SignupForm';

const InputLayout = styled(PanelRoot)`
  display: flex;
  align-items: stretch;
`;

const DesktopFormLayout = locals => (
  <CenterAlignedRow>
    <CenterAlignedColumn>
      <Row fullWidth>
        <InputLayout>
          <div>{locals.inputs.first_name}</div>
        </InputLayout>
        <Spacer size={1.5} />
        <InputLayout>
          <div>{locals.inputs.last_name}</div>
        </InputLayout>
      </Row>
      <Row fullWidth>
        <InputLayout>
          <div>{locals.inputs.email}</div>
        </InputLayout>
        <Spacer size={1.5} />
        <InputLayout>
          <div>{locals.inputs.password}</div>
        </InputLayout>
      </Row>
      <Row fullWidth>
        <InputLayout>
          <div>{locals.inputs.country}</div>
        </InputLayout>
        <Spacer size={1.5} />
        <InputLayout>
          <div>{locals.inputs.mobile_number}</div>
        </InputLayout>
      </Row>
      <Row fullWidth centerJustified>
        <InputLayout>
          <div>{locals.inputs.gender}</div>
        </InputLayout>
      </Row>
    </CenterAlignedColumn>
    <Separator vertical spacerSize={4} separatorLength="full" />
    <InputLayout>
      <div>{locals.inputs.newsletter_subscription}</div>
      <Button
        block
        primary
        lg
        loading={locals.context.isLoading}
        tabIndex={13} // eslint-disable-line jsx-a11y/tabindex-no-positive
        onClicked={() => locals.context.onSubmit()}
      >
        {i18next.t('accountManagement:Login.CreateAnAccount')}
      </Button>
      <XXXXXLargeSpacer />
    </InputLayout>
  </CenterAlignedRow>
);

class SignupPanel extends React.Component {
  state = {
    panelError: getLocalMessageFor(PathRedirectKeysMapper.signup) || null,
    isLoading: false,
  };

  onSuccess = (response) => {
    const {
      history,
      updateUserInfo,
      saveCurrentPath,
      redirectIfPathExists,
    } = this.props;

    if (!this.form || !response || !response.create_user) {
      return;
    }
    updateUserInfo({
      ...getUserInfoFromLoginResponse(response.create_user, true),
    });

    const pathData = redirectIfPathExists(PathRedirectKeysMapper.showSuccess);
    if (pathData) {
      saveCurrentPath(
        PathRedirectKeysMapper.pathAfterConnectSocialMedia,
        '/accountManagement/VerifyAccountEmailOrSms',
      );
      navigateToModal(history, pathData.name);
    } else {
      navigateToModal(history, '/accountManagement/VerifyAccountEmailOrSms');
    }
  };

  onError = (error) => {
    this.setState({ panelError: error });
    scrollCurrentModalToTop();
  };

  setLoadingState = (isLoading) => {
    this.setState({ isLoading });
  };

  render = () => {
    const { isLoading, panelError } = this.state;

    return (
      <Media query={mediaQueryMin('desktop')}>
        {isOnDesktop => (
          <Panel
            titleLabel={i18next.t('accountManagement:SignUp.Register')}
            subTitleLabel={i18next.t('accountManagement:SignUp.JoinOurCommunity')}
            error={panelError}
            panelWidth={isOnDesktop ? '100%' : null}
          >
            <React.Fragment>
              <Spacer size={3} />
              <SignupForm
                onRef={(ref) => {
                  this.form = ref;
                }}
                customLayout={isOnDesktop ? DesktopFormLayout : null}
                customInputsContainer={isOnDesktop ? null : InputLayout}
                onFormError={error => this.onError(error)}
                onFormSuccess={response => this.onSuccess(response)}
                onFormLoading={loading => this.setLoadingState(loading)}
              />
              {!isOnDesktop && (
                <InputLayout>
                  <Button
                    block
                    primary
                    lg
                    loading={isLoading}
                    onClicked={() => this.form.submitForm()}
                  >
                    {i18next.t('accountManagement:Login.CreateAnAccount')}
                  </Button>
                </InputLayout>
              )}
            </React.Fragment>
          </Panel>
        )}
      </Media>
    );
  };
}

SignupPanel.propTypes = PropTypes.shape({
  panelContentContainer: PropTypes.element,
}).isRequired;

export default withRelayEnvironment(withUserInfo(SignupPanel));

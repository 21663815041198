import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

import { graphql } from 'react-relay';

export default function VariantData(WrappedComponent) {
  return withFragmentContainer(
    WrappedComponent,
    graphql`
      fragment VariantData_variantInfo on Variant {
        product_type
        id
        ref_id
        images
        summary
        images_attachments {
          url_product
        }
        name
        avg_rating
        rating_count
        price
        slug
        vendor_variants_count
        discount_expire_at
        remaining_usage_percentage
        gift_image{
          url_large
          url_original
        }
        gift_text
        gift_included
        min_variant{
          vendor{
            name
            ref_id
          }
          slug
          name
          ref_id
          root_variant_id
          discount_expire_at
          remaining_usage_percentage
          total_on_hand
          price
          original_price
        }
        has_offer
        hot_deal
        has_discount
        wish_listed
        root_variant_id
        root_variant {
          ref_id
          wish_listed
        }
        original_price
        discount
        total_on_hand
        option_values {
          id
          presentation
        }
        vendor {
          id
          ref_id
          name
          logo_url
          avg_rating
          rating_count
          followed
          grouped_shipping_methods {
            code
            title
            description
          }
          payment_methods {
            id
            ref_id
            name
            description
            type
            code
          }
        }
      }
    `,
  );
}

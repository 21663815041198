import { graphql } from 'relay-runtime';

export const LuckyWheelMutationRoot = 'spin_wheel';

export const LuckyWheelMutation = graphql`
  mutation LuckyWheelMutation($email: String) {
    spin_wheel(email: $email) {
      wheel_description
      wheel_name
      wheel_no
      won
      promo
      errors {
        field
        messages
        code
      }
    }
  }
`;
export default LuckyWheelMutation;

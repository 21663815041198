import _ from 'lodash';

const persist = (dataKey) => {
  // Workaround to force the browser to persist !!!
  // https://stackoverflow.com/questions/13292744/why-isnt-localstorage-persisting-in-chrome
  localStorage.getItem(dataKey);
  sessionStorage.getItem(dataKey);
};

const getFirstLine = (str) => {
  if (str && str.indexOf) {
    const breakIndex = str.indexOf('\n');

    // consider that there can be line without a break
    if (breakIndex === -1) {
      return str;
    }

    return str.substr(0, breakIndex);
  }

  return str;
};

export const prepareLocalMessages = (messages) => {
  if (!messages) {
    return null;
  }

  let oneLineMessage = '';
  if (typeof messages === 'object' && (messages.message || messages.token || messages.email)) {
    oneLineMessage = getFirstLine([].concat(messages.message || messages.token || messages.email).join('\n'));
  } else {
    oneLineMessage = getFirstLine(messages);
  }

  return oneLineMessage;
};

export const setLocalMessageFor = (path, message) => {
  let savedMessages = sessionStorage.getItem('localMessages') || {};

  if (savedMessages) {
    savedMessages = _.isString(savedMessages) ? JSON.parse(savedMessages) : savedMessages;
    savedMessages[path] = prepareLocalMessages(message);
    sessionStorage.setItem('localMessages', JSON.stringify(savedMessages));
    persist('localMessages');
  }

  return savedMessages;
};

export const getLocalMessageFor = (path) => {
  let savedMessages = sessionStorage.getItem('localMessages');

  if (savedMessages) {
    savedMessages = _.isString(savedMessages) ? JSON.parse(savedMessages) : savedMessages;
    if (!savedMessages[path]) {
      return null;
    }
    const message = savedMessages[path];
    savedMessages = _.omit(savedMessages, path);
    sessionStorage.setItem('localMessages', JSON.stringify(savedMessages));
    persist('localMessages');

    return message;
  }

  return null;
};

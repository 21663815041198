import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { GridArea } from '~/modules/coreUI/components/layouts/helpers/GridLayout';

const DropdownGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template:
    'itemImage                itemLabel           itemLabel           actions' max-content
    'itemImage                vendorLabel         vendorLabel         actions' max-content
    'itemImage                itemOptions         itemOptions         actions' auto
    'itemImage                price               itemQuantity        actions' auto
    / max-content 1fr 1fr auto;

  grid-column-gap: ${props => 1 * props.theme.new.spacer}px;
  grid-row-gap: ${props => 0.5 * props.theme.new.spacer}px;
`;

class LayoutDropdown extends Component {
  state = {};

  render() {
    return (
      <DropdownGrid
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <GridArea gridArea="itemImage">{this.props.itemImage}</GridArea>
        <GridArea gridArea="itemLabel" fullWidth leftAligned>
          {this.props.itemLabel}
        </GridArea>
        <GridArea gridArea="vendorLabel" fullWidth leftAligned>
          {this.props.vendorLabel}
        </GridArea>
        <GridArea gridArea="itemOptions" bottomJustified leftAligned>
          {this.props.itemOptions}
        </GridArea>
        <GridArea gridArea="price" row leftJustified centerAligned spaceBetween={0.5}>
          {this.props.price}
          {this.props.priceWarnings}
        </GridArea>
        <GridArea gridArea="itemQuantity" row leftJustified centerAligned spaceBetween={0.5}>
          {this.props.itemQuantity}
          {this.props.nonPriceWarnings}
        </GridArea>
        <GridArea gridArea="actions" centerJustified rightAligned pr={0.5}>
          {this.props.actions}
        </GridArea>
      </DropdownGrid>
    );
  }
}

LayoutDropdown.propTypes = {
  price: PropTypes.element.isRequired,
  priceWarnings: PropTypes.element.isRequired,
  itemQuantity: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  itemImage: PropTypes.element.isRequired,
  itemOptions: PropTypes.element.isRequired,
  itemLabel: PropTypes.element.isRequired,
  vendorLabel: PropTypes.element.isRequired,
  nonPriceWarnings: PropTypes.element.isRequired,
};

export default LayoutDropdown;

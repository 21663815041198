import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';

import { CONTROL_TYPES, CONTROL_MODES, SIZE_PROP_NAMES } from '~/modules/coreUI/utils/infereStyle';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const CircleIcon = styled(Button)`
  z-index: 1;
  cursor: unset;
`;
const StyleLinehight = {
  'line-height': '37px',
};
const EndMarks = styled.div`
  height: ${props => props.size || props.theme.new.spacer}px;
  width: ${props => props.size || props.theme.new.spacer}px;

  z-index: 2;

  border-radius: 50%;
  background: ${props => props.theme.new.colors.buttons[props.type].background};
  content: '';
`;

const BackgroundLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;

  background: ${props => props.theme.new.colors.buttons[props.type].background};
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
`;

const StepLabel = styled(Label)`
  position: absolute;
  
  ${props => (props.labelPosition === 'top') && css`
    top: -6px;
  `};
  ${props => (props.labelPosition === 'bottom') && css`
    bottom: 0;
  `};
`;

const TypesPriority = {
  inProgress: 4,
  error: 3,
  done: 2,
  todo: 1,
};

const getPrecedentStep = (currentStep, nextStep, isLastStep) => {
  if (isLastStep) {
    return currentStep;
  }

  let precendent = currentStep;
  if (TypesPriority[nextStep.state] > TypesPriority[currentStep.state]) {
    precendent = nextStep;
  }
  return precendent;
};

const getLabelColor = (type) => {
  if (type === 'disabled') {
    return 'normal';
  }
  return type;
};

const Nbsp = () => (
  <Label>
    &nbsp;
  </Label>
);

const isLinePritable = (stepsPosition, isLastStep, isFirstStep) => {
  if ((isLastStep && stepsPosition === 'start')) {
    return false;
  }
  if ((isFirstStep && stepsPosition === 'end')) {
    return false;
  }
  return true;
};

const Step = props => (
  <Column
    fullWidth={isLinePritable(props.stepsPosition, props.isLastStep, props.isFirstStep)}
    rightJustified
    position="relative"
    onClick={props.onClick}
  >
    <Row
      fullWidth={isLinePritable(props.stepsPosition, props.isLastStep, props.isFirstStep)}
      fullHeight
      rightJustified={props.stepsPosition === 'end'}
      spaceBetweenJustified={props.endMarker && props.stepsPosition === 'center'}
      centerJustified={props.stepsPosition === 'center'}
      leftJustified={props.stepsPosition === 'start'}
      position="relative"
    >
      {isLinePritable(props.stepsPosition, props.isLastStep, props.isFirstStep) && (
        <BackgroundLine state={props.state} type={props.type} />
      )}
      {(props.endMarker && props.stepsPosition === 'center') && (
        <EndMarks
          size={!props.isFirstStep ? '0' : undefined}
          state={props.state}
          type={props.type}
        />
      )}
      <Column position="relative" fullHeight>
        <StepLabel
          size={props.labelSize}
          nowrap
          align="center"
          semiBold
          color={getLabelColor(props.type)}
          labelPosition={props.labelPosition}
        >
          {props.label}
        </StepLabel>
        <Nbsp {...props} />
        <Spacer size={0.5} />
        <CircleIcon
          style={StyleLinehight}
          fullRound
          state={props.state}
          mode={props.mode}
          type={props.type}
          pxRatio={1.6}
          size={props.stepSize}
          {...props.stepButtonProps}
        >
          {props.content ? props.content : <span>{props.index + 1}</span>}
        </CircleIcon>
        <Spacer size={0.5} />
        <Nbsp {...props} />
      </Column>
      {(props.endMarker && props.stepsPosition === 'center') && (
        <EndMarks
          state={props.state}
          type={getPrecedentStep(props.currentStep, props.nextStep, props.isLastStep).type || 'primary'}
        />
      )}
    </Row>
  </Column>
);

Step.defaultProps = {
  content: null,
  onClick: () => {},
  type: 'primary',
  mode: 'normal',
  labelPosition: 'top',
  labelSize: ['md'],
  stepSize: ['md'],
  stepsPosition: 'end',
  endMarker: null,
};

Step.propTypes = {
  label: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf(['top', 'bottom']),
  labelSize: PropTypes.arrayOf(PropTypes.oneOf(SIZE_PROP_NAMES)),
  stepSize: PropTypes.arrayOf(PropTypes.oneOf(SIZE_PROP_NAMES)),
  stepsPosition: PropTypes.oneOf(['start', 'center', 'end']),
  content: PropTypes.node,
  state: PropTypes.oneOf(['inProgress', 'todo', 'done', 'error']).isRequired,
  type: PropTypes.oneOf(CONTROL_TYPES),
  mode: PropTypes.oneOf(CONTROL_MODES),
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  isFirstStep: PropTypes.bool.isRequired,
  endMarker: PropTypes.oneOf(['ellipse']),
};

export default Step;

/**
 * @flow
 * @relayHash 1d86442d5ac549bc3119c99570051ffa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SocialNetworkQueryVariables = {||};
export type SocialNetworkQueryResponse = {|
  +social_networks: ?{|
    +android_application: ?string,
    +facebook_url: ?string,
    +instagram_url: ?string,
    +ios_application: ?string,
    +mobile_app_image: ?{|
      +url_original: ?string,
      +name: ?string,
      +type: ?string,
    |},
    +twitter_url: ?string,
    +youtube_url: ?string,
  |}
|};
export type SocialNetworkQuery = {|
  variables: SocialNetworkQueryVariables,
  response: SocialNetworkQueryResponse,
|};
*/


/*
query SocialNetworkQuery {
  social_networks {
    android_application
    facebook_url
    instagram_url
    ios_application
    mobile_app_image {
      url_original
      name
      type
    }
    twitter_url
    youtube_url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "social_networks",
    "storageKey": null,
    "args": null,
    "concreteType": "SocialNetwork",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "android_application",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "facebook_url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "instagram_url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ios_application",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mobile_app_image",
        "storageKey": null,
        "args": null,
        "concreteType": "ImageDetails",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url_original",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "twitter_url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "youtube_url",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SocialNetworkQuery",
  "id": null,
  "text": "query SocialNetworkQuery {\n  social_networks {\n    android_application\n    facebook_url\n    instagram_url\n    ios_application\n    mobile_app_image {\n      url_original\n      name\n      type\n    }\n    twitter_url\n    youtube_url\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SocialNetworkQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "SocialNetworkQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '917a23328c1da88a95bcdccbfb2a2664';
module.exports = node;

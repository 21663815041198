import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { XXLargeLabel, MediumLabel } from '~/modules/coreUI/components/basic/Labels';
import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const CardWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  height: ${props => props.theme.new.spacer * 11.3}px;
`;

const headerCustomStyle = props => css`
  font-size: ${props.theme.new.fonts.sizes.lg}px;
  font-weight: ${props.theme.new.fonts.weights.bold};
  color: ${props.color};
  text-transform: capitalize;
`;
const SubHeaderCustomStyle = props => css`
  font-size: ${props.theme.new.fonts.sizes.sm}px;
  color: ${props.color};
  text-transform: capitalize;
`;
const ExternalLinkStyle = styled(ExternalLink)`
  display: block;
  width: 100%;
`;
const ImageWrapper = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const RowWrapper = styled(Row)`
  padding: ${props => props.theme.paddings.xxLarge}px;
`;
const AdSystemCard = props => (
  <ExternalLinkStyle key={props.id} url={props.url} target="_self">
    <CardWrapper>
      <RowWrapper topAligned spaceBetween={2.5}>
        <ImageWrapper image={props.large_photo_object.url_original} />
        <Column leftAligned spaceBetween={0.5}>
          {props.title && (
            <XXLargeLabel color={props.title_color} customStyle={headerCustomStyle}>
              {props.title}
            </XXLargeLabel>
          )}
          {props.sub_title_1 && (
            <MediumLabel color={props.sub_title_1_color} customStyle={SubHeaderCustomStyle}>
              {props.sub_title_1}
            </MediumLabel>
          )}
        </Column>
      </RowWrapper>
    </CardWrapper>
  </ExternalLinkStyle>
);
AdSystemCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
  sub_title_1: PropTypes.string,
  sub_title_1_color: PropTypes.string,
  title_color: PropTypes.string,
  large_photo_object: PropTypes.shape({
    url_original: PropTypes.string,
  }),
};

AdSystemCard.defaultProps = {
  title: null,
  sub_title_1_color: null,
  sub_title_1: null,
  url: null,
  title_color: null,
  large_photo_object: null,
};

export default AdSystemCard;

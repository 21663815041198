import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';

import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import generateSequenceFromTo from '~/modules/core/utils/jsHelpers/generateSequence';
import ItemSpinner from './ItemSpinner';

const ItemsListUI = styled.div`
  grid-gap: 10px;
  display: grid;

  ${props => props.vertical
    && css`
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    `}
  ${cssMediaMax.tablet
    && css`
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  `}
  ${cssMediaMax.mobile
    && css`
      grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
  `}
  ${cssMediaMax.xsmall
    && css`
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  `}
  ${props => props.vertical === false
    && css`
      grid-template-columns: repeat(1, 1fr);
    `}
`;

const OneLineList = styled(Row)`
    overflow-x: hidden;
`;

const ItemsListSpinner = ({ vertical, oneRow, itemsCount }) => {
  const ItemList = oneRow ? OneLineList : ItemsListUI;
  const width = oneRow ? 260 : null;
  return (
    <ItemList vertical={vertical} spaceBetween={1}>
      {generateSequenceFromTo(0, itemsCount).map(index => (
        <ItemSpinner key={index * Math.random()} vertical={vertical} width={width} />
      ))}
    </ItemList>
  );
};

ItemsListSpinner.propTypes = {
  vertical: PropTypes.bool,
  oneRow: PropTypes.bool,
  itemsCount: PropTypes.number,
};
ItemsListSpinner.defaultProps = {
  vertical: true,
  oneRow: false,
  itemsCount: 10,
};

export default ItemsListSpinner;

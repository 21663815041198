/* eslint-disable camelcase */
/* eslint-env browser */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Media from 'react-media';

import TwoLevelsHeader from '~/modules/core/layouts/advancedTwoLevels';
import { XXXXLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import HomePageLogo from '~/modules/coreUI/components/projects/HomePageLogo';
import DropDownBurger from '~/modules/ecommerceCoreUI/components/header/departments/DropDownBurger';
import NavProductsItems from '~/modules/ecommerceCoreUI/components/header/headerActions/NavProductsItems';
import NavWishList from '~/modules/ecommerceCoreUI/components/header/headerActions/NavWishList';
import NavUserProfile from '~/modules/ecommerceCoreUI/components/header/headerActions/NavUserProfile';
import NavNotification from '~/modules/ecommerceCoreUI/components/header/headerActions/NavNotifications/NavNotification';
import NavLanguage from '~/modules/ecommerceCoreUI/components/header/headerActions/NavLanguage';
import NavCurrency from '~/modules/ecommerceCoreUI/components/header/headerActions/NavCurrency';
import NavProductsSearch from '~/modules/ecommerceCoreUI/components/header/headerActions/NavProductsSearch';
import DepartmentsLinks from '~/modules/ecommerceCoreUI/components/header/departments/DepartmentsLinks';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import SocialMedia from '~/modules/ecommerceCoreUI/components/header/headerActions/SocialMedia';

import { desktopTopHeaderStyles, desktopBottomHeaderStyles } from './Header.styles';


const MainHeader = styled.div`
  background: #f8f7f7 !important;
`;

class Header extends React.PureComponent {
  constructor(props) {
    setTimeout(() => {
      var h1 = document.getElementById('contentHeader');
      var contentHeader = localStorage.getItem('contentHeader');
      h1.innerHTML = contentHeader;
    }, 10000);
    super(props);

    this.menuInfo = {
      desktop: {
        top: {
          left: [
            {
              itemRenderer: <HomePageLogo />,
              targeturl: this.props.direction === 'rtl' ? '/ar' : '/',
            },
            {
              itemRenderer: <h1 id="contentHeader"></h1>,
            },
          ],
          center: [
            {
              itemRenderer: <NavProductsSearch />,
            },
          ],
          right: [
            {
              itemRenderer: <NavProductsItems />,
            },
            {
              verticalSeparator: true,
              separatorColorTone: 'light',
            },
            {
              itemRenderer: <NavWishList />,
            },
            {
              verticalSeparator: true,
              separatorColorTone: 'light',
            },
            {
              itemRenderer: <NavUserProfile />,
            },
            {
              verticalSeparator: true,
              separatorColorTone: 'light',
            },
            {
              itemRenderer: <NavNotification />,
            },
            {
              verticalSeparator: true,
              separatorColorTone: 'light',
            },
            {
              itemRenderer: <NavLanguage />,
            },
            {
              verticalSeparator: true,
              separatorColorTone: 'light',
            },
            {
              itemRenderer: <NavCurrency />,
            },
          ],
        },
        bottom: {
          left: [
            {
              itemRenderer: <DropDownBurger />,
            },
            {
              itemRenderer: <DepartmentsLinks />,
            },
          ],
          right: [
            {
              itemRenderer: <SocialMedia />,
            },
            {
              horizontalSpacer: true,
              spacerWidth: 'medium',
            },
          ],
        },
      },
      mobile: {
        top: {
          left: [],
          right: [],
        },
        bottom: {
          left: [],
          right: [],
        },
      },
    };
  }

  render = () => (
    <MainHeader>
      <TwoLevelsHeader
        desktopTopHeaderStyles={desktopTopHeaderStyles}
        desktopBottomHeaderStyles={desktopBottomHeaderStyles}
        menuInfo={this.menuInfo}
      />
      <Media query={mediaQueryMin('desktop')}>{matches => matches && <XXXXLargeSpacer />}</Media>
    </MainHeader>
  );
}

Header.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default withDirection(Header);

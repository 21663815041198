import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const TabLabel = styled(Label)`
  display: inline-block;
  width: 50%;
  text-align: center;
  padding-top: ${props => 2.5 * props.theme.new.spacer}px;
  height: ${props => 6 * props.theme.new.spacer}px;
  border-bottom: ${props => (props.hasBorder ? '2px solid #33a8ff' : 'none')};
  cursor: pointer;
`;

class CustomTabs extends Component {
  state={
    activeTab: this.props.tabs[0].name,
  }

  tabIsActive = currentTab => this.state.activeTab === currentTab;

  changeActiveTab = (currentTab) => {
    this.setState({ activeTab: currentTab });
  }

  render() {
    const { tabs } = this.props;
    const { activeTab } = this.state;

    return (
      <React.Fragment>
        <Row fullWidth>
          {tabs.map(tab => (
            <TabLabel
              key={tab.name}
              hasBorder={this.tabIsActive(tab.name)}
              semiBold
              primary={this.tabIsActive(tab.name)}
              onClick={() => this.changeActiveTab(tab.name)}
            >
              {tab.label}
            </TabLabel>
          ))}
        </Row>

        {tabs.map(tab => (
          activeTab === tab.name && (
            <React.Fragment key={tab.name}>
              {tab.content}
            </React.Fragment>
          )
        ))}
      </React.Fragment>
    );
  }
}

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
};

export default CustomTabs;

/* eslint-env browser */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Icon from '~/modules/coreUI/components/basic/Icon';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const ContentWrapper = styled(ExternalLink)`
  color: ${props => props.theme.new.colors.labels.normal.emphasized};
  display: block;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${props => props.color};
    font-weight: 600;
  }
  span {
    font-size: ${props => props.theme.new.fonts.sizes.md + 2.5}px;
    ${props => cssMediaMax.mobile`
      font-size: ${props.theme.new.fonts.sizes.md - 2}px;
    `}
    ${props => cssMediaMax.xsmall`
      font-size: ${props.theme.new.fonts.sizes.xxs - 1}px;
      line-height: 1.1;
    `}
  }
`;

const ItemHolder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(-50%,-50%) rotate(-${props => props.rotationDegree}deg);
`;

const RotationDegrees = {
  healthPark: 23,
  homemade: 55,
  products: 90,
  shops: 123,
  eduPark: 157,
};

const ColumnWrapper = styled(Column)`
  font-size: ${props => props.theme.fonts.sizes.small - 0.5}px;
  text-align: center;
  position: absolute;

  width: 100%;
  transform: rotate(${props => props.rotationDegree}deg) translate(75%);
  transform-origin: top left;
`;

const IconStyle = styled(Icon)`
  display: inline-block;
  text-align: center;
  font-size: ${props => 5.9 * props.theme.new.spacer}px;
  color: ${props => props.color};
  ${props => cssMediaMax.mobile`
    font-size: ${4 * props.theme.new.spacer}px;
  `}
  ${props => cssMediaMax.xsmall`
    font-size: ${1.9 * props.theme.new.spacer}px;
  `}
`;

const TrackItem = ({
  url,
  id,
  onMouseEnter,
  onMouseLeave,
  trackIcon,
  trackColor,
  trackName,
  trackImg,
  imageAlt,
  trackSvg,
}) => (
  <ColumnWrapper
    id={id}
    color={trackColor}
    rotationDegree={RotationDegrees[id]}
  >
    <ItemHolder className="item-holder" rotationDegree={RotationDegrees[id]}>
      <ContentWrapper
        url={url}
        color={trackColor}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="track-icon-container">
          {trackSvg}
        </div>
        <Spacer size={[0, 0.5]} />
        <span>{i18next.t(trackName)}</span>
      </ContentWrapper>
    </ItemHolder>
  </ColumnWrapper>
);
TrackItem.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  trackColor: PropTypes.string.isRequired,
  trackName: PropTypes.string.isRequired,
  trackIcon: PropTypes.string.isRequired,
  trackImg: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  trackSvg: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default TrackItem;

/* eslint-disable import/prefer-default-export */
import styled, { css } from 'react-emotion';
import {
  XXLargeLabel,
  LargeLabel,
  MediumLabel,
  SmallLabel,
  XSmallLabel,
} from '~/modules/coreUI/components/legacies/Labels';

import {
  MinorDetailsLabel,
  ImportantMinorDetailsLabel,
} from '~/modules/ecommerceCoreUI/components/basic/Labels';

import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

// --------------------- OFFERS ---------------------//
export const OfferCountDownTimerLabel = styled(XXLargeLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const OfferCountDownTimerDetails = MinorDetailsLabel;

export const OfferRemainingAvailabilityLabel = styled(ImportantMinorDetailsLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const ProductCardOfferHintLabel = MinorDetailsLabel;

export const OfferTagLabel = styled(SmallLabel)`
  color: ${props => props.theme.colors.named.white};
`;

// ------------------- RATINGS ---------------------//
export const RatingsCountLabel = styled(ImportantMinorDetailsLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  vertical-align: top;

  ${props => props.styles && props.styles(props)};
`;

// ----------------- ProductInfo -------------------//
export const ProductNameLabel = styled(LargeLabel)`
  color: ${props => props.theme.colors.labels.important};
`;

export const ReviewsCountLabel = styled(XSmallLabel)`
  color: ${props => props.theme.colors.labels.important};
`;

export const OldPriceLabel = styled(MediumLabel)`
  margin-top: 5px;
  text-decoration: ${props => (!props.showDash ? 'none' : 'line-through')};
  color: ${props => props.theme.colors.labels.subtle};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const CurrentPriceLabel = withDirection(styled(LargeLabel)`
  margin-top: 15px;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.sizes.large}px;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  ${props => (props.direction === 'rtl'
    ? css`
      text-align: right;
    `
    : css`
      text-align: left;
    `
  )}
`);

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';
import Slider from 'react-slick';

const NavSliderStyle = styled(Slider)`
  width: 170px;
  margin: auto !important;
  ${props => props.imagesNumber === 1
    && css`
      display: none;
    `};
  margin-bottom: 20px; /* TO Accomodate for the sliding dots */

  .slick-track {
    display: inline-block;
  }
  .slick-list {
    text-align: center;
  }
  .slick-cloned {
    ${props => props.imagesNumber <= 2
      && css`
        display: none;
      `};
  }
  .slick-slide {
    > div {
      outline: none;
    }
    img {
      margin: auto;
      border: ${props => props.theme.borders.size.thin}px solid #cfcfcf;
      border-radius: ${props => props.theme.borders.radius.normal}px;
      opacity: 0.4;
    }
  }
  .slick-slide.slick-current {
    img {
      border: ${props => props.theme.borders.size.thin}px solid
        ${props => props.theme.colors.primary};
      opacity: 1;
    }
  }
`;
const NavSlider = ({
  innerRef,
  afterChange,
  children,
  childrenCount,
  onClick,
}) => (
  <div onClick={onClick}>
    <NavSliderStyle
      innerRef={innerRef}
      infinite
      slidesToShow={3}
      slidesToScroll={1}
      swipeToSlide={false}
      arrows={false}
      focusOnSelect
      afterChange={afterChange}
      imagesNumber={childrenCount}
    >
      {children}
    </NavSliderStyle>
  </div>
);

NavSlider.propTypes = {
  afterChange: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  childrenCount: PropTypes.number.isRequired,
};

export default NavSlider;

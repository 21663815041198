/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { graphql } from 'relay-runtime';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import NotificationsCountLabel from '~/modules/coreUI/components/basic/NotificationsCountLabel';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

import {
  Tab, Tabs, TabList, TabPanel,
} from '~/modules/coreUI/components/layouts/Tabs';

import Messages from './messages/index';
import Notifications from './notifications/Notifications';

const tabsStyles = () => css`
  width: 100%;
`;

const tabStyles = props => css`
  position: relative;
  width: ${20 * props.theme.new.spacer}px;
  height: ${4.2 * props.theme.new.spacer}px;
  text-align: center;
  bottom: -1px;
  padding-right: ${1.5 * props.theme.new.spacer}px;
  padding-left: ${1.5 * props.theme.new.spacer}px;
  font-size: ${1.8 * props.theme.new.spacer}px;
  white-space: nowrap;
  border: 1px solid ${props.theme.new.colors.buttons.passive.background};
  border-bottom: 0px;
  border-radius: ${0.5 * props.theme.new.spacer}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  ${(props.media.xsmall) && css`
     width: 49.5%;
     font-size: ${props.theme.new.fonts.sizes.sm}px;
  `}
  ${props.direction === 'rtl'
    ? css`
        margin-right: 0px;
      `
    : css`
        margin-left: 0px;
  `}
  &&:last-child{
      ${props.direction === 'ltr'
    ? css`
        margin-right: 0px;
      `
    : css`
        margin-left: 0px;
    `}
  }
`;

const tabListStyles = props => css`
  ${props.direction === 'rtl'
    ? css`
        text-align: right;
        padding-right: 0;
      `
    : css`
        text-align: left;
        padding-left: 0;
   `}
`;

const activeTab = props => css`
  background-color: ${props.theme.new.colors.named.inverted};
  border-bottom: 1px solid transparent;
`;

const activeTabPanelStyles = props => css`
  min-height: ${40 * props.theme.new.spacer}px;
  padding-top: ${1.5 * props.theme.new.spacer}px;
  padding-bottom: ${1.5 * props.theme.new.spacer}px;
  background-color: white;
  border: 1px solid ${props.theme.new.colors.buttons.passive.background};
  border-radius: ${0.5 * props.theme.new.spacer}px;
  ${props.direction === 'rtl'
    ? css`
        border-top-right-radius: 0;
      `
    : css`
        border-top-left-radius: 0;
   `}
   ${(props.media.xsmall) && css`
      border-radius: 0;
  `}
`;

const getTabIndex = (tabIndex) => {
  let tabName = 'notifications';
  if (tabIndex === 1) {
    tabName = 'messages';
  }
  return tabName;
};

const getTabName = (tabName) => {
  let tabIndex = 0;
  if (tabName === 'messages') {
    tabIndex = 1;
  }
  return tabIndex;
};

const selectTab = (tabIndex, history) => {
  history.push(urlFor('accountSettings', ['notificationsAndMessages', getTabIndex(tabIndex)]));
};

const notificationAndMessagesTabs = ({
  direction,
  media,
  queryResult,
  match,
  history,
}) => (
  <Tabs
    onSelect={tabIndex => selectTab(tabIndex, history)}
    selectedIndex={getTabName(match.params.tabIndex)}
    styles={tabsStyles}
  >
    <TabList direction={direction} styles={tabListStyles}>
      <Tab direction={direction} media={media} styles={tabStyles} activeStyles={activeTab}>
        {queryResult?.thread_list?.unread_notifications_count > 0 ? (
          `${i18next.t('ecommerceOrder:notificationsAndMessages.notificationsTabLabel')} (${queryResult?.thread_list?.unread_notifications_count})`
        ) : (
          i18next.t('ecommerceOrder:notificationsAndMessages.notificationsTabLabel')
        )}
      </Tab>
      <Tab direction={direction} media={media} styles={tabStyles} activeStyles={activeTab}>
        {i18next.t('ecommerceOrder:notificationsAndMessages.messagesTabLabel')}
        {' '}
        <NotificationsCountLabel unreadMessagesCount={queryResult?.thread_list?.threads_with_unread_messages_count} />
      </Tab>
    </TabList>

    <TabPanel direction={direction} media={media} activeStyles={activeTabPanelStyles}>
      <Notifications />
    </TabPanel>
    <TabPanel direction={direction} media={media} activeStyles={activeTabPanelStyles}>
      <Messages />
    </TabPanel>
  </Tabs>
);

notificationAndMessagesTabs.defaultProps = {
  media: null,
  queryResult: {},
};

notificationAndMessagesTabs.propTypes = {
  media: PropTypes.shape({
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  }),
  direction: PropTypes.string.isRequired,
  queryResult: PropTypes.shape({
    thread_list: PropTypes.shape({
      threads_with_unread_messages_count: PropTypes.number,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      tabIndex: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

const NotificationAndMessagesTabsQuery = graphql`
  query NotificationAndMessagesTabsQuery {
    thread_list{
      threads_with_unread_messages_count
      unread_notifications_count
    }
  }
`;

export default withRouter(withDirection(withMedia(withRootQuery(
  notificationAndMessagesTabs,
  NotificationAndMessagesTabsQuery,
  null,
  null,
  { force: true },
))));

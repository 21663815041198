import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'react-relay';

import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

import DepartmentPanLinkListGroup from './DepartmentPanLinkListGroup';

const SubDepartmentsList = ({
  subDepartmentInfo,
  departmentId,
  departmentName,
  hideDepartments,
}) => (
  <DepartmentPanLinkListGroup
    taxonList={subDepartmentInfo.children}
    groupName={subDepartmentInfo.name}
    departmentId={departmentId}
    departmentName={departmentName}
    hideDepartments={hideDepartments}
  />
);

SubDepartmentsList.propTypes = {
  subDepartmentInfo: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  departmentId: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  hideDepartments: PropTypes.func.isRequired,
};

export default withFragmentContainer(
  SubDepartmentsList,
  graphql`
    fragment SubDepartmentsList_subDepartmentInfo on Taxon {
      name
      children {
        # Categories
        ...TaxonLink_taxonInfo
      }
    }
  `,
);

import React from 'react';
import PropTypes from 'prop-types';

import Media from 'react-media';
import { mediaQueryMax } from '~/modules/core/utils/cssHelpers/cssMedia';

import NavLanguageMobile from './NavLanguage/NavLanguageMobile';
import NavLanguageDesktop from './NavLanguage/NavLanguageDesktop';

const NavLanguage = () => (
  <Media query={mediaQueryMax('tablet')}>
    {matches => (matches ? (
      <NavLanguageMobile />
    ) : (
      <NavLanguageDesktop />
    ))
    }
  </Media>
);

NavLanguage.defaultProps = {
  userInfo: {
    settings: {
      language: 'en',
    },
  },
};

NavLanguage.propTypes = {
  userInfo: PropTypes.shape({
    settings: PropTypes.shape({
      language: PropTypes.string,
    }),
  }),
};

export default NavLanguage;

import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

// import 'firebase/auth';
import 'firebase/database';

import FirebaseContext from './FirebaseContext';

import config from '~/firebase.config';

const defaultApp = firebase.initializeApp(config);

const FirebaseProvider = props => (
  <FirebaseContext.Provider value={{ firebase: defaultApp }}>
    {props.children}
  </FirebaseContext.Provider>
);

FirebaseProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default FirebaseProvider;

import React from 'react';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';
import { pushToCompare } from '~/modules/ecommerceProducts/containers/compare/compareUtility';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import { Label } from '~/modules/coreUI/components/basic/Labels';

const customIconStyle = css`
  font-size: 37px;
  ${cssMediaMax.xsmall`
    font-size: 25px;
  `};
`;

const RemoveCompareButton = props => (
  <IconOnlyButton
    iconName="icon-compare-circle"
    customIconStyle={customIconStyle}
    title={i18next.t('ecommerceCoreUI:ToolTipTrans.compare')}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      pushToCompare(
        props,
        () => {
          props.notifyAlert({
            component: () => (
              <Label inverted important semiBold>
                <i className="el-icon el-icon-error" />
                &nbsp;
                {i18next.t('ecommerceProducts:compare.errorMessage')}
              </Label>
            ),
            type: AlertTypes.error,
            opacity: 1,
            toastID: 'RemoveCompareButton_error_toast',
          });
        },
      );
    }}
  />
);

RemoveCompareButton.propTypes = {
  media: PropTypes.shape({ minDesktop: PropTypes.bool }).isRequired,
  notifyAlert: PropTypes.func.isRequired,
};

export default withAlertMsg(withRouter(withMedia(withTheme(RemoveCompareButton))));

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import {
  Row,
  Column,
  Box,
} from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const CardContainer = withDirection(styled(Row)`
  &:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1) !important;
  }
  direction: ${props => (props.direction === 'rtl' ? 'rtl' : 'ltr')};
  border-radius: ${props => props.theme.borders.radius.normal}px;
`);


const HorizontalXsmallLayout = ({
  imageRenderer,
  title,
  showBorder,
  tag,
  rating,
  offerLabel,
  originalPrice,
  actions,
  currentPrice,
  addToCartButton,
}) => (
  <CardContainer stretchAligned bordered={showBorder} thin light padding={1} spaceBetween={1}>
    <Box topAligned>
      {imageRenderer}
    </Box>
    <Column spaceBetween={0.5} fullWidth>
      <Row fullWidth topAligned>
        {tag}
        <Spacer size={0.5} grow />
        {actions}
      </Row>
      {title}
      <Row bottomAligned fullWidth spaceBetweenJustified>
        <Column leftAligned fullWidth spaceBetween={0.5}>
          {rating}
          <Column leftAligned spaceBetween={0.5}>
            {currentPrice}
            {originalPrice}
          </Column>
        </Column>
        <Spacer size={0.5} grow />
        {offerLabel}
        <Spacer />
      </Row>
      { addToCartButton && (
        <Box fullWidth rightAligned>
          {addToCartButton}
        </Box>
      )}
    </Column>
  </CardContainer>
);
HorizontalXsmallLayout.propTypes = {
  actions: PropTypes.element.isRequired,
  tag: PropTypes.element,
  offerLabel: PropTypes.element.isRequired,
  rating: PropTypes.element.isRequired,
  imageRenderer: PropTypes.element.isRequired,
  title: PropTypes.element.isRequired,
  originalPrice: PropTypes.element.isRequired,
  currentPrice: PropTypes.element.isRequired,
  addToCartButton: PropTypes.element,
  showBorder: PropTypes.bool,
};

HorizontalXsmallLayout.defaultProps = {
  showBorder: true,
  tag: null,
  addToCartButton: null,
};
export default HorizontalXsmallLayout;

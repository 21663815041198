import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';

import withOmitedProps from '~/modules/core/utils/cssHelpers/withOmitedProps';

const Link = ({
  to, href, children, ...rest
}) => (
  <ReactRouterLink to={to} href={href} {...rest}>
    {children}
  </ReactRouterLink>
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

const BasicLink = styled(withOmitedProps(Link, ['linkStyles']))`
  width: 100%;
  font-size: ${props => props.size || props.theme.fonts.sizes.small}px;
  cursor: pointer;

  ${props => props.linkStyles && props.linkStyles(props)} &:focus {
    outline: none;
  }
`;

const PrimaryStyleLink = styled(withOmitedProps(BasicLink, ['hoverLinkStyles']))`
  color: ${props => props.theme.colors.labels.emphasized};

  &:hover {
    color: ${props => props.theme.colors.labels.important};
      ${props => props.hoverLinkStyles && props.hoverLinkStyles(props)};
  }

  &:active {
    color: ${props => props.theme.colors.labels.important};
      ${props => props.hoverLinkStyles && props.hoverLinkStyles(props)};
  }
`;

const SecondaryStyleLink = styled(withOmitedProps(BasicLink, ['hoverLinkStyles']))`
  color: ${props => props.theme.colors.secondary};

  &:hover {
    color: ${props => props.theme.colors.secondaryHover};
      ${props => props.hoverLinkStyles && props.hoverLinkStyles(props)};
  }

  &:active {
    color: ${props => props.theme.colors.secondaryClicked};
      ${props => props.hoverLinkStyles && props.hoverLinkStyles(props)};
  }
`;

const ElementLink = (props) => {
  const {
    primary, linkStyles, children, hoverLinkStyles, targeturl, size,
  } = props;

  const LinkElement = primary ? PrimaryStyleLink : SecondaryStyleLink;

  return (
    <LinkElement
      linkStyles={linkStyles}
      hoverLinkStyles={hoverLinkStyles}
      size={size}
      to={targeturl}
      href={targeturl}
    >
      {children}
    </LinkElement>
  );
};

ElementLink.defaultProps = {
  size: null,
  hoverLinkStyles: null,
  primary: false,
  linkStyles: null,
  children: null,
};

ElementLink.propTypes = {
  primary: PropTypes.bool,
  linkStyles: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.number,
  targeturl: PropTypes.string.isRequired,
  hoverLinkStyles: PropTypes.func,
};

export default ElementLink;

import React from 'react';
import PropTypes from 'prop-types';
import './styles/css/App.css';
import { withRouter } from 'react-router-dom';

import { pageVisited } from '~/modules/ecommerceCore/utils/googleAnalytics';

// import Layout from './modules/core/layouts/simple/Layout';
// import Layout from './modules/AykLayout/layouts/AykLayout';
// import Layout from './modules/BADRLibLayout/layouts/Layout';
import Layout from './modules/ecommerceCore/layouts/Layout';

// TODO : Use the following few lines to debug the non-necessary re-renders
// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update'); // eslint-disable-line
//   whyDidYouUpdate(React);
// }

let unlisten = null;

const App = ({ history }) => {
  if (unlisten) {
    unlisten();
  }
  unlisten = history.listen((location) => {
    pageVisited(location.pathname);
  });

  return <Layout />;
};

App.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(App);

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled, { css } from 'react-emotion';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import {
  MediumLabel,
  XSmallLabel,
  SmallLabel,
  LargeLabel,
} from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import SquareImageCard from '~/modules/ecommerceCoreUI/components/basic/SquareImageCard';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

import Ribbon from './Ribbon';
import Crown from './Crown';
import MembershipsDetails from './MembershipsDetails';
import { kFormatter } from './KFormatterUtily';

const PointsCardWrapper = styled(Column)`
  padding: ${props => props.theme.paddings.large}px;
  ${props => cssMediaMax.xsmall`
    padding: ${props.theme.paddings.medium}px;
  `}
`;
const ShopCard = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
`;

const RowWrapper = styled(Row)`
  padding: ${props => props.theme.paddings.medium}px ${props => props.theme.paddings.large}px;
  background-color: #f9f9f9;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  ${props => cssMediaMax.xsmall`
    padding: ${props.theme.paddings.medium}px;
  `}

`;
const StrongItem = styled.span`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: capitalize;
`;
const Logo = styled.div`
  width: ${props => 8.5 * props.theme.new.spacer}px;
`;
const RibbonContent = css`
  position: absolute;
`;
const Degree = styled(MediumLabel)`
  color: ${props => props.color};
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.small}px;
  `}
`;
const ShopName = styled(LargeLabel)`
  color: #000;
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.medium}px;
  `}
`;
const XSmallWrapper = styled(XSmallLabel)`
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.xxSmall}px;
  `}
`;
const SmallLabelWrapper = styled(SmallLabel)`
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.xSmall}px;
  `}
`;
const CapitalizeText = styled.span`
  text-transform: capitalize;
`;
const ShopPointsCard = ({
  queryResult: {
    degree,
    points_to_next_level,
    points,
    active_gifts_count,
    points_to_next_gift,
    vendor: {
      name,
      logo_url,
    },
  },
}) => (
  <div>
    {MembershipsDetails.map(Memberships => (
      <React.Fragment key={Memberships.type + degree}>
        {Memberships.type === degree && (
          <ShopCard>
            <PointsCardWrapper spaceBetweenJustified>
              <Row width="100%" grow topAligned>
                <Ribbon customeStyle={RibbonContent} size="small" degree={degree} />
                <Logo>
                  <SquareImageCard imageBackground={logo_url} />
                </Logo>
                <Spacer />
                <Row topAligned width="100%" grow spaceBetweenJustified cenerAligned>
                  <Column width="100%" leftAligned>
                    <StrongItem><ShopName>{name}</ShopName></StrongItem>
                    <Spacer size={0.5} />
                    <Row grow width="100%" spaceBetweenJustified spaceBetween={0.5}>
                      <Column leftAligned>
                        <StrongItem>
                          <Degree color={Memberships.textColor}>{i18next.t(Memberships.text)}</Degree>
                        </StrongItem>
                        <Spacer size={0.3} />
                        <SmallLabelWrapper>
                          <StrongItem>
                            <CapitalizeText>
                              {`${kFormatter(points)} `}
                            </CapitalizeText>
                          </StrongItem>
                          {i18next.t('ecommerceOrder:Loyalty.pointsEarned')}
                        </SmallLabelWrapper>
                      </Column>
                      <Crown crownLink={Memberships.crownSrc} size="small" />
                    </Row>
                    <Spacer />
                    <XSmallWrapper>
                      {(degree === 'silver' || degree === 'gold') ? (
                        <CapitalizeText>
                          {`${kFormatter(points_to_next_level)} `}
                        </CapitalizeText>
                      ) : (
                        <Spacer size={1.3} />
                      )}
                      {degree === 'silver' && i18next.t('ecommerceOrder:Loyalty.pointsLeftGold') }
                      {degree === 'gold' && i18next.t('ecommerceOrder:Loyalty.pointsLeftDiamond') }
                    </XSmallWrapper>
                  </Column>
                </Row>
              </Row>
            </PointsCardWrapper>
            <RowWrapper width="100%" spaceBetweenJustified leftAligned>
              {active_gifts_count !== 0 && (
                <Row>
                  <XSmallWrapper>
                    <i className="el-icon el-icon-gift-promo" />
                  </XSmallWrapper>
                  <Spacer size={0.5} />
                  <XSmallWrapper>
                    <StrongItem>{`${active_gifts_count} `}</StrongItem>
                    {i18next.t('ecommerceOrder:Loyalty.gifts')}
                  </XSmallWrapper>
                </Row>
              )}
              <XSmallWrapper>
                <CapitalizeText>
                  {`${kFormatter(points_to_next_gift)} `}
                </CapitalizeText>
                {i18next.t('ecommerceOrder:Loyalty.pointsLeftGift')}
              </XSmallWrapper>
            </RowWrapper>
          </ShopCard>
        )}
      </React.Fragment>
    ))}
  </div>
);

ShopPointsCard.propTypes = {
  queryResult: PropTypes.shape({}),
};

ShopPointsCard.defaultProps = {
  queryResult: [],
};

export default ShopPointsCard;

import React, { Component } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import SmartCartAlert from '~/modules/ecommerceOrder/components/smartCard/SmartCartAlert';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import Icon from '~/modules/coreUI/components/basic/Icon';
import { AddToCartMutationRoot, AddToCartMutation } from '~/modules/ecommerceOrder/containers/smartCart/smartCartMutations/AddToCart';
import { notifyUnexpectedError } from '~/modules/ecommerceOrder/containers/shoppingCart/utils/ErrorsProcessor';

const AddToCartButton = styled(Icon)`
  font-size: ${props => (props.isDropdown ? props.theme.fonts.sizes.xLarge : props.theme.new.fonts.sizes.xxl)}px;
  color: ${props => props.theme.colors.labels.subtle};
  cursor: pointer;
  ${props => cssMediaMax.mobile`
    font-size: ${props.theme.fonts.sizes.xLarge}px;
  `};
`;

class AddToCart extends Component {
  state = {
    loading: false,
  };

  onMutationError = (errors) => {
    this.setState({
      loading: false,
    });
    notifyUnexpectedError(this.props, errors);
  };

  onMutationSuccess = () => {
    this.setState({
      loading: false,
    });

    this.props.notifyAlert({
      component: () => (
        <SmartCartAlert lineItem={this.props.lineItem} />
      ),
      type: AlertTypes.success,
      opacity: 1,
      toastID: `Smart_Cart_${this.props.lineItem.variant.ref_id}_success_toast`,
    });
  };

  onMutationLoading = () => {
    this.setState({
      loading: true,
    });
  };

  AddItemToCart = () => {
    commitMutation(
      this.props.environment,
      AddToCartMutation,
      AddToCartMutationRoot,
      {
        input: {
          vendor_variant_id: parseInt(this.props.lineItem.variant.ref_id, 10),
          quantity: this.props.lineItem.quantity,
        },
      },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
  };

  render() {
    return (
      <Column>
        <Row>
          <AddToCartButton
            className="el-icon el-icon-Add-bag-32"
            onClick={this.AddItemToCart}
            title={i18next.t('ecommerceOrder:smartCart.addToCartTooltip')}
          />
          <Spacer size={0.5} />
        </Row>
        {this.state.loading && <PulseLoader size={5} color="rgba(0,0,0,0.2)" />}
      </Column>
    );
  }
}

AddToCart.propDefaults = {
  isDropdown: false,
};

AddToCart.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  lineItem: PropTypes.shape({
    variant: PropTypes.shape({
      ref_id: PropTypes.string.isRequired,
    }).isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  notifyAlert: PropTypes.func.isRequired,
};

export default withAlertMsg(withCartInfo(
  withRelayEnvironment(
    AddToCart,
  ),
));

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import {
  SmallLabel,
  XXLargeLabel,
  MediumLabel,
  LargeLabel,
} from '~/modules/coreUI/components/basic/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const PointsCircle = styled(Column)`
  width: ${props => 8.5 * props.theme.new.spacer}px;
  height: ${props => 8.5 * props.theme.new.spacer}px;
  border-radius: 50%;
  border: solid;
  border-width: 1px;
  border-color: ${props => props.theme.new.borders.color.lightGray};
  margin: auto;
  ${props => cssMediaMax.xsmall`
    width: ${7 * props.theme.new.spacer}px;
    height: ${7 * props.theme.new.spacer}px;
  `}
`;
const PointNumber = styled(XXLargeLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  color: ${props => props.theme.colors.labels.important};
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.large}px;
  `}
`;
const Wrapper = styled.div`
  text-align: center;
`;
const PointFor = styled(MediumLabel)`
  display: block;
  text-align: center;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  color: ${props => props.theme.colors.labels.important};
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.small}px;
  `}
`;
const PointDetails = styled(SmallLabel)`
  display: block;
  width: ${props => 17.5 * props.theme.new.spacer}px;
  text-align: center;
  margin: auto;
  line-height: 1.3;
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.xxSmall}px;
    width: auto;
  `}
`;
const Link = styled(ExternalLink)`
  color: ${props => props.theme.colors.secondary};
  &:hover {
    color: ${props => props.theme.colors.secondaryHover};
  }

  &:active {
    color: ${props => props.theme.colors.secondaryClicked};
  }
  span {
    font-size: ${props => props.theme.fonts.sizes.large}px;
  }
`;
const LargeLabelWrapper = styled(LargeLabel)`
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.xSmall}px;
  `}
`;
const MediumLabelWrapper = styled(MediumLabel)`
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.xxSmall}px;
  `}
`;
const BointGuide = ({
  points,
  how,
  details,
  actions,
}) => (
  <Wrapper>
    <PointsCircle centerJustified>
      <PointNumber>{points}</PointNumber>
      <Spacer size={0.5} />
      <SmallLabel>
        {points > 1
          ? i18next.t('ecommerceOrder:Loyalty.points')
          : i18next.t('ecommerceOrder:Loyalty.point')
        }
      </SmallLabel>
    </PointsCircle>
    <Spacer size={1.2} />
    <PointFor>{how}</PointFor>
    <Spacer size={0.5} />
    <PointDetails>
      {`${details} `}
      <Spacer size={0.5} />
      <React.Fragment>
        {actions.map((action, index) => (
          <React.Fragment key={action.url}>
            <LargeLabelWrapper>
              <Link href={action.url} url={action.url}>
                {i18next.t(action.trigger)}
              </Link>
            </LargeLabelWrapper>
            {actions.length > 1 && (actions.length !== index + 2 && actions.length !== index + 1) && <MediumLabelWrapper>, </MediumLabelWrapper>}
            {actions.length > 1 && (actions.length === index + 2) && <MediumLabelWrapper>{` ${i18next.t('ecommerceOrder:Loyalty.or')} `}</MediumLabelWrapper>}
          </React.Fragment>
        ))}
      </React.Fragment>
    </PointDetails>
  </Wrapper>
);

BointGuide.propTypes = {
  points: PropTypes.number.isRequired,
  how: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BointGuide;

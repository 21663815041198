/* eslint-disable lines-between-class-members, react/prop-types, camelcase, no-nested-ternary */
/* eslint-env browser */
/* eslint-disable */
import React from 'react';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import { flattenAndParseInwaniFieldValue } from '~/modules/ecommerceCoreUI/components/addresses/inwani/InwaniFormElement';
import { flattenAndParseGoogleMapFieldValue } from '~/modules/ecommerceCoreUI/components/addresses/map/GoogleMapFormElement';
import { flattenAndParseCityCountryFieldValue } from '~/modules/ecommerceCoreUI/components/addresses/city/CityFormElement';
import withAddressesPanelsInfo from '~/modules/ecommerceCoreUI/containers/addresses/context/withAddressesPanelsInfo';
import CurrentUserQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/CurrentUserQuery';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import EditAddressMutation from './mutations/EditAddressMutation';
import NewAddressMutation from './mutations/NewAddressMutation';
import DesktopFormLayout from './layouts/DesktopFormLayout';
import MobileFormLayout from './layouts/MobileFormLayout';
import addressFormFields from './AddressFormFields';

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.formFields = addressFormFields(this.props, !this.props.hasPreviousAddresses, false);
  }

  initialValues = () => {
    const { isEditingMode } = this.props;
    const info = isEditingMode ? this.props.editableAddressInfo : this.props.userInfo;
    const currentUser = this.props.queryResult?.current_user_details?.current_user;

    const countryId = isEditingMode
      ? info?.country?.accept_delivery_and_shipping ? info?.country?.ref_id : null
      : currentUser?.country?.ref_id;

    const stateId = isEditingMode
      ? info?.country?.accept_delivery_and_shipping ? info?.state?.ref_id : null
      : currentUser?.city?.ref_id;

    const countryCode = isEditingMode
      ? info?.country?.phone_code
      : currentUser?.country?.phone_code;

    const phone = isEditingMode
      ? `(+${info.phone_code}) ${info.phone}`
      : `(+${countryCode}) ${currentUser?.phone_number}`;

    const firstname = isEditingMode
      ? info?.firstname
      : currentUser?.first_name;

    const lastname = isEditingMode
      ? info?.lastname
      : currentUser?.last_name;

    const states_required = true;

    return {
      firstname,
      lastname,
      phone,
      country: {
        country_id: countryId,
        country_code: countryCode,
      },
      state: {
        country_id: countryId,
        state_id: stateId,
        states_required: states_required
      },
      address1: info?.address1,
      save_as_default: info?.save_as_default,
      map: {
        lat: info?.lat,
        lng: info?.lng,
      },
      inwani: {
        inwani_building: info?.inwani_building?.toString(),
        inwani_street: info?.inwani_street?.toString(),
        inwani_zone: info?.inwani_zone?.toString(),
      },
    };
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  validateForm = () => this.form.validateFormAsync();
  submitForm = (async = false) => this.form.submitForm(async);

  onFormError = error => this.props.onError(error);
  onFormLoading = isLoading => this.props.onLoading(isLoading);
  onFormSuccess = (response) => {
    const responseData = this.props.isEditingMode
      ? response && response.create_user_address
      : response && response.update_user_address;

    if (this.props.onSuccess) {
      this.props.onSuccess(responseData);
    }
  }

  isFormDirty = () => this.form.isDirty;

  formLayout = () => (this.props.media.minTablet ? DesktopFormLayout : MobileFormLayout);
  additionalInput = () => (this.props.isEditingMode
    ? { address_id: parseInt(this.props.editableAddressInfo.ref_id, 10) }
    : !this.props.hasPreviousAddresses && { use_shipping: this.props.isSameAsShipping });

  render = () => (
    <RelayForm
      onRef={(ref) => {
        this.form = ref;
      }}
      onFormError={this.onFormError}
      onFormSuccess={this.onFormSuccess}
      onFormLoading={this.onFormLoading}

      mutationRoot={this.props.isEditingMode ? 'update_user_address' : 'create_user_address'}
      mutation={this.props.isEditingMode ? EditAddressMutation : NewAddressMutation}
      options={{
        customLayout: this.formLayout(),
        initialFormValue: this.initialValues(),
        fields: this.formFields,
      }}
      context={{
        isUpdate: this.props.isUpdate,
        isBilling: this.props.isBilling,
      }}
      getSubmissionVariables={formVariables => ({
        data: {
          [this.props.addressMutationInputName]: {
            ...flattenAndParseInwaniFieldValue(
              flattenAndParseGoogleMapFieldValue(
                flattenAndParseCityCountryFieldValue(formVariables))), // eslint-disable-line function-paren-newline
          },
          ...this.additionalInput(),
        },
      })}
    />
  );
}

export default withDirection(withMedia(
  withUserInfo(
    withAddressesPanelsInfo(
      withRootQuery(
        AddressForm,
        CurrentUserQuery,
        null,
        null,
        { force: true },
      ),
    ),
  ),
));

/**
 * @flow
 * @relayHash ebe1f3592c0548c8d5a07118bc145687
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TabsContainerQueryVariables = {||};
export type TabsContainerQueryResponse = {|
  +tracks: $ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +track_type: ?string,
  |}>
|};
export type TabsContainerQuery = {|
  variables: TabsContainerQueryVariables,
  response: TabsContainerQueryResponse,
|};
*/


/*
query TabsContainerQuery {
  tracks(input: {only_has_recommended: true}) {
    id
    name
    track_type
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "tracks",
    "storageKey": "tracks(input:{\"only_has_recommended\":true})",
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "only_has_recommended": true
        },
        "type": "TrackFilterInput"
      }
    ],
    "concreteType": "Track",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "track_type",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TabsContainerQuery",
  "id": null,
  "text": "query TabsContainerQuery {\n  tracks(input: {only_has_recommended: true}) {\n    id\n    name\n    track_type\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TabsContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "TabsContainerQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3aa63f6cfc09fe0106c5b096c47c3d1';
module.exports = node;

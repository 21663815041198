import { graphql } from 'react-relay';

export const EditSmartCartMutationRoot = 'edit_smart_cart';

export const EditSmartCartMutation = graphql`
    mutation EditSmartCartMutation($input: SmartCartInputQuery, $id: ID) {
      edit_smart_cart(input: $input, id: $id) {
        errors {
          code
          field
          messages
        }
        current_user {
          ...UserInfoFragment @relay(mask: false)
        }
      }
    }
  `;

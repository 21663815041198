import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import ItemCard from '~/modules/coreUI/components/itemCard';
import EmptyRecentViewed from './EmptyRecentViewed';

const RecentViewed = ({ queryResult }) => (
  <React.Fragment>
    {queryResult && queryResult.recently_viewed && queryResult.recently_viewed.variants.length > 0 ? (
      queryResult.recently_viewed.variants.map(variantInfo => (
        <React.Fragment key={variantInfo.id}>
          <ItemCard
            key={variantInfo.id}
            isVertical={false}
            itemInfo={variantInfo}
          />
          <Spacer size={1.5} />
        </React.Fragment>
      ))
    ) : (
      <EmptyRecentViewed />
    )}
  </React.Fragment>
);

RecentViewed.propTypes = {
  queryResult: PropTypes.shape({}),
};
RecentViewed.defaultProps = {
  queryResult: [],
};
export default withRootQuery(
  RecentViewed,
  graphql`
    query RecentViewedQuery {
      recently_viewed(page:{limit: 10}){
        variants{
          ...VariantData_variantInfo @relay(mask: false)
        }
        errors {
          field
          messages
          code
        }
      }
    }
  `,
);

import styled from 'react-emotion';

const SquareImageCard = styled.div`
  width: 100%;
  height: 0px;
  padding-top: 100%;
  background-image: url(${props => props.imageBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: ${props => props.theme.borders.radius.normal}px;
`;

export default SquareImageCard;

import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

const PlusSign = styled.span`
  position: relative;
  font-size: ${props => props.theme.new.fonts.sizes.xs}px;
  top: ${props => -0.4 * props.theme.new.spacer}px;
`;

const MAXIMUM_NUMBER_OF_MESSAGES = 99;

const NotificationsCountLabel = ({ unreadMessagesCount }) => (
  unreadMessagesCount > MAXIMUM_NUMBER_OF_MESSAGES ? (
    <span>
      {MAXIMUM_NUMBER_OF_MESSAGES}
      <PlusSign>+</PlusSign>
    </span>
  ) : (
    !!unreadMessagesCount && ` (${unreadMessagesCount})`
  ));

NotificationsCountLabel.propTypes = {
  unreadMessagesCount: PropTypes.number.isRequired,
};


export default NotificationsCountLabel;

import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Icon from '~/modules/coreUI/components/basic/Icon';

const getWarningMessage = warning => warning && warning.messages && warning.messages.join(', ');

const ErrorIcon = ({ warnings, isDropdown }, props) => warnings.length > 0 && (
<ErrorIconStyle
  error
  size={['md', 'md', 'md', 'xs']}
  className="icon-error"
  title={getWarningMessage(warnings[0])}
  isDropdown={isDropdown}
  {...props}
/>
);
const ErrorIconStyle = styled(Icon)`
  ${props => props.isDropdown
    && css`
      color: ${props.theme.new.colors.labels.normal.normal} !important;
      :hover {
        color: ${props.theme.new.colors.named.error} !important;
      }
    `};
`;
ErrorIcon.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
  ).isRequired,
};

const ShortMessagesError = ({
  media,
  warnings,
  isDropdown,
  isOutOfStockWarning,
}, props) => warnings
  && warnings.length > 0 && (
    <Row spaceBetween={0.5}>
      <ErrorIcon warnings={warnings} isDropdown={isDropdown} {...props} />
      {
        (isOutOfStockWarning
        || media.minDesktop)
        && !isDropdown && (
          <Label xs error>
            {getWarningMessage(warnings[0])}
          </Label>
        )}
    </Row>
);

ShortMessagesError.defaultProps = {
  isOutOfStockWarning: false,
};

ShortMessagesError.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
  ).isRequired,
  isDropdown: PropTypes.bool.isRequired,
  isOutOfStockWarning: PropTypes.bool,
};

export default withMedia(ShortMessagesError);

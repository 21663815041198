import React from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import styled from 'react-emotion';
import Media from 'react-media';

import { mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import { CurrentPriceLabel } from '~/modules/ecommerceCoreUI/components/basic/ProductLabels';
import { TopAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import { LeftAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import { LargeSpacer, SmallSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const CardStyleWrapper = styled.div`
  padding: 0px;
`;

const ProductImage = styled.img`
  width: ${props => 5 * props.theme.paddings.medium}px;
  height: ${props => 5 * props.theme.paddings.medium}px;
  border-radius: ${props => props.theme.borders.radius.small}px;
  object-fit: cover;
`;

const TextStyle = styled.div`
  font-size: ${props => props.theme.fonts.sizes.small}px;
  font-weight: ${props => props.theme.new.fonts.weights.semiBold};
`;

const RowVerticalAligned = styled(TopAlignedRow)`
  align-items: center;
`;

const SearchCard = ({
  name, image, price, onClick,
}) => (
  <CardStyleWrapper onClick={() => onClick()}>
    <LargeSpacer />
    <RowVerticalAligned>
      <ProductImage src={image} alt="" />
      <Spacer />
      <LeftAlignedColumn>
        <SmallSpacer />
        <TextStyle>
          <Media query={mediaQueryMin('desktop')}>
            {matches => (matches ? (
              <LinesEllipsis text={name} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
            ) : (
              <LinesEllipsis text={name} maxLine="1" ellipsis="..." trimRight basedOn="letters" />
            ))
            }
          </Media>
        </TextStyle>
        {price && (
          <CurrentPriceLabel>
            <TextStyle>{price}</TextStyle>
          </CurrentPriceLabel>
        )}
        <SmallSpacer />
      </LeftAlignedColumn>
    </RowVerticalAligned>
    <LargeSpacer />
  </CardStyleWrapper>
);

SearchCard.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}.isRequired;

export default SearchCard;

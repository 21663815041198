/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

const LineItemLayout = props => (
  <Fragment>
    {props.media.xsmall ? <MobileLayout {...props} /> : <DesktopLayout {...props} />}
  </Fragment>
);

export default withMedia(LineItemLayout);

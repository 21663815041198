import _ from 'lodash';

export const flattenAndParseCityCountryFieldValue = formVariables => ({ // eslint-disable-line import/prefer-default-export
  ..._.omit(formVariables, ['state', 'country']),
  state_id: formVariables.state.state_id,
  country_id: formVariables.country.country_id,
});

export const flattenAndParseCountryFieldValue = formVariables => ({ // eslint-disable-line import/prefer-default-export
  ..._.omit(formVariables, 'country'),
  country_id: formVariables.country.country_id,
});

const DailyDealsTrans = {
  En: {
    deailsOfDay: 'Deals of the Day',
    enjoyOurDeals: 'Discounts, offers, and hot deals',
    hotDeals: 'Hot Deals',
    offers: 'Bundle Offers',
    discounts: 'Discounts',
  },
  Ar: {
    deailsOfDay: 'صفقات اليوم',
    enjoyOurDeals: 'خصومات وعروض وصفقات مذهلة',
    hotDeals: 'صفقات مذهلة',
    offers: 'عروض الحزمة',
    discounts: 'خصومات',
  },
};

export default DailyDealsTrans;

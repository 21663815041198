import React from 'react';
import i18next from 'i18next';
import { Accordion } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { AccordionMainList } from './DropdownContainer';

const DropdownElement = ({
  CurrencyContent,
  LanguageContent,
  DepartmentContent,
  AccountContent,
  TracksContent,
}) => {
  const rootPanels = [
    {
      title: i18next.t('accountManagement:Profile.myAccount'),
      content: { key: 'panel-1', content: AccountContent },
      key: 'panel-1',
    },
    {
      title: i18next.t('ecommerceCoreUI:HeaderSubMenuTracks'),
      content: { key: 'panel-2', content: TracksContent },
      key: 'panel-2',
    },
    {
      title: i18next.t('ecommerceCoreUI:productsDepartments'),
      content: { key: 'panel-3', content: DepartmentContent },
      key: 'panel-3',
    },
    {
      title: i18next.t('ecommerceCoreUI:HeaderSubMenuLanguage'),
      content: { key: 'panel-4', content: LanguageContent },
      key: 'panel-4',
    },
    {
      title: i18next.t('ecommerceCoreUI:HeaderSubMenuCurrency'),
      content: { key: 'panel-5', content: CurrencyContent },
      key: 'panel-5',
    },
  ];
  return (
    <AccordionMainList>
      <Accordion panels={rootPanels} inverted />
    </AccordionMainList>
  );
};

DropdownElement.propTypes = {
  CurrencyContent: PropTypes.element.isRequired,
  LanguageContent: PropTypes.element.isRequired,
  DepartmentContent: PropTypes.element.isRequired,
  AccountContent: PropTypes.element.isRequired,
  TracksContent: PropTypes.element.isRequired,
};

export default DropdownElement;

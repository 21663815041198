/* eslint-disable camelcase, react/no-unused-state, quote-props, no-useless-return  */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';

import GiftIcon from './GiftIcon';
import DarkBackground from './DarkBackground';
import LuckyWheelBox from './LuckyWheelBox';
import LuckyWheelWrapper from './LuckyWheelWrapper';
import LuckyWheelContext from './LuckyWheelContext';

class LuckyWheel extends Component {
  state = {
    showLuckyWheel: false,
    spinWheelResult: [],
    loading: false,
    spinningStarted: false,
    spinningEnded: false,
    showSpinDescription: true,
    hideTrigger: false,
    visitorPlayTime: false,
  }

  componentWillMount = () => {
    const wheelNextPlayTime = JSON.parse(localStorage.getItem('wheelNextPlayTime'));
    if (!this.props.authenticated && wheelNextPlayTime !== null) {
      this.hideWheelTrigger();
    }
  }

  componentDidUpdate = () => {
    const wheelNextPlayTime = JSON.parse(localStorage.getItem('wheelNextPlayTime'));
    if (this.props.authenticated && wheelNextPlayTime !== null && this.state.visitorPlayTime) {
      this.setState({ hideTrigger: false, visitorPlayTime: false });
    }
  }

  hideWheelTrigger = () => {
    const wheelNextPlayTime = JSON.parse(localStorage.getItem('wheelNextPlayTime'));
    const today = new Date();
    const todayPlayTime = today.setDate(today.getDate());
    if (!this.props.authenticated && wheelNextPlayTime !== null && todayPlayTime < wheelNextPlayTime) {
      this.setState({ hideTrigger: true, visitorPlayTime: true });
    } else {
      this.setState({ hideTrigger: true });
    }
  }

  setSpinningStarted = () => {
    this.setState({ spinningStarted: true });
  }

  handleResetWheel = () => {
    if (this.state.spinWheelResult.length !== 0) {
      setTimeout(() => {
        this.setState({
          showSpinDescription: true,
          spinWheelResult: [],
          loading: false,
          spinningStarted: false,
          hideTrigger: false,
          visitorPlayTime: false,
        });
        this.hideWheelTrigger();
      }, 1000);
    }
  }

  handleToggleLuckyWheel = () => {
    this.setState(state => ({ showLuckyWheel: !state.showLuckyWheel }), () => this.handleResetWheel());
  }

  setLuckyWheelLoading = () => {
    this.setState({ loading: true, showSpinDescription: false });
  }

  setWheelPlayTimeToLocalStorage = () => {
    const today = new Date();
    const wheelNextPlayTime = today.setDate(today.getDate() + 1);

    localStorage.setItem('wheelNextPlayTime', JSON.stringify(wheelNextPlayTime));
  }

  handleSpinWheelResult = (spinWheelResult) => {
    this.setState(({ spinWheelResult }));
    this.setWheelPlayTimeToLocalStorage();
  }

  handleToggleSpinningEnded = () => {
    this.setState({ spinningEnded: false, loading: false });
  }

  render() {
    const { showLuckyWheel, hideTrigger } = this.state;
    const { media, queryResult } = this.props;
    return (
      <LuckyWheelContext.Provider value={{
        loading: this.state.loading,
        spinningStarted: this.state.spinningStarted,
        spinWheelResult: this.state.spinWheelResult,
        handleToggleLuckyWheel: this.handleToggleLuckyWheel,
        showSpinDescription: this.state.showSpinDescription,
        setLuckyWheelLoading: this.setLuckyWheelLoading,
        handleSpinWheelResult: this.handleSpinWheelResult,
        handleToggleSpinningEnded: this.handleToggleSpinningEnded,
        setSpinningStarted: this.setSpinningStarted,
      }}
      >
        {media.minDesktop
          && queryResult?.lucky_wheel_eligible.show_user_daily_chance
          && queryResult?.lucky_wheel_eligible.show_wheel
          && !hideTrigger
          && (
            <LuckyWheelWrapper centerAligned visible={showLuckyWheel}>
              <GiftIcon />
              <LuckyWheelBox />
              {showLuckyWheel && <DarkBackground />}
            </LuckyWheelWrapper>
          )
        }
      </LuckyWheelContext.Provider>
    );
  }
}

LuckyWheel.propTypes = {
  media: PropTypes.shape({}).isRequired,
  queryResult: PropTypes.shape({}).isRequired,
  authenticated: PropTypes.bool.isRequired,
};

export default withRootQuery(
  withUserInfo(withMedia(LuckyWheel)),
  graphql`
    query LuckyWheelQuery {
      lucky_wheel_eligible {
        show_user_daily_chance
        show_wheel
      }
    }
  `,
);

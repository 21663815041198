import React from 'react';
import PropTypes from 'prop-types';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';

const Price = props => (
  <Label primary={props.isTotalPrice || props.media.maxTablet} centerAligned semiBold size={props.isDropdown ? 'md' : ['sm', 'sm', 'md', 'xl']}>
    {props.value}
    {' '}
    {currentCurrencyDisplayName()}
  </Label>
);

Price.defaultProps = {
  isDropdown: false,
  isTotalPrice: false,
};

Price.propTypes = {
  value: PropTypes.number.isRequired,
  isDropdown: PropTypes.bool,
  isTotalPrice: PropTypes.bool,
  media: PropTypes.shape({
    maxTablet: PropTypes.bool,
  }).isRequired,
};

export default withMedia(Price);

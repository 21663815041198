/**
 * @flow
 * @relayHash 1025cf8fe5d005c84dd8bf9577406c3c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CitiesDropdownQueryVariables = {|
  CountryID?: ?string
|};
export type CitiesDropdownQueryResponse = {|
  +countries: $ReadOnlyArray<?{|
    +iso3: ?string,
    +states_required: ?boolean,
    +states: $ReadOnlyArray<?{|
      +name: ?string,
      +ref_id: string,
    |}>,
  |}>
|};
export type CitiesDropdownQuery = {|
  variables: CitiesDropdownQueryVariables,
  response: CitiesDropdownQueryResponse,
|};
*/


/*
query CitiesDropdownQuery(
  $CountryID: ID
) {
  countries(id: $CountryID) {
    iso3
    states_required
    states {
      name
      ref_id
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "CountryID",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "CountryID",
    "type": "ID"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "iso3",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "states_required",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CitiesDropdownQuery",
  "id": null,
  "text": "query CitiesDropdownQuery(\n  $CountryID: ID\n) {\n  countries(id: $CountryID) {\n    iso3\n    states_required\n    states {\n      name\n      ref_id\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CitiesDropdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "countries",
        "storageKey": null,
        "args": v1,
        "concreteType": "Country",
        "plural": true,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "states",
            "storageKey": null,
            "args": null,
            "concreteType": "State",
            "plural": true,
            "selections": [
              v4,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CitiesDropdownQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "countries",
        "storageKey": null,
        "args": v1,
        "concreteType": "Country",
        "plural": true,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "states",
            "storageKey": null,
            "args": null,
            "concreteType": "State",
            "plural": true,
            "selections": [
              v4,
              v5,
              v6
            ]
          },
          v6
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d06c19ede27286c6799e9aa86bfcd7d';
module.exports = node;

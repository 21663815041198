import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import DesktopPanelHeader from './DesktopPanelHeader';
import MobilePanelHeader from './MobilePanelHeader';

const OrderPanelHeader = props => (
  <Fragment>
    {props.media.maxMobile ? <MobilePanelHeader {...props} /> : <DesktopPanelHeader {...props} />}
  </Fragment>
);

OrderPanelHeader.defaultProps = {
  media: null,
};

OrderPanelHeader.propTypes = {
  media: PropTypes.shape({
    maxMobile: PropTypes.bool,
  }),
};

export default withMedia(OrderPanelHeader);

/* eslint-disable camelcase */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { Spacer, LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { SmallTitle } from '~/modules/ecommerceCoreUI/components/basic/Labels';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import SocialNetworkQuery from '~/modules/ecommerceCoreUI/components/footer/SocialNetworkQuery';
import { LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const SocialMediaWrapper = styled(LinearLayout)`
  i {
    font-size: 25px;
  }
  a {
    color: ${props => props.theme.colors.primary};
  }
`;

const SocialMediaItem = styled.div`
  display: flex;
`;
const SocialMediaTitle = styled(SmallTitle)`
  text-transform: capitalize;
`;

const NavSocialMedia = ({
  queryResult,
  isCentered,
  title,
  // media,
}) => (
  <SocialMediaWrapper centerJustified={isCentered} centerAligned={isCentered} leftAligned={!isCentered}>
    {queryResult.length !== 0 && title && (
      <React.Fragment>
        <SocialMediaTitle>
          {title}
        </SocialMediaTitle>
        <LargeSpacer size={12} />
      </React.Fragment>
    )}

    <SocialMediaItem>
      {queryResult?.social_networks?.facebook_url && (
        <React.Fragment>
          <ExternalLink
            url={queryResult?.social_networks?.facebook_url}
            target="_blank"
            title={i18next.t('ecommerceCoreUI:ToolTipTrans.facebook')}
          >
            <i className="icon-fb" />
          </ExternalLink>
          <Spacer />
        </React.Fragment>
      )}
      {queryResult?.social_networks?.twitter_url && (
        <React.Fragment>
          <ExternalLink
            url={queryResult?.social_networks?.twitter_url}
            target="_blank"
            title={i18next.t('ecommerceCoreUI:ToolTipTrans.twitter')}
          >
            <i className="icon-twitter" />
          </ExternalLink>
          <Spacer />
        </React.Fragment>
      )}
      {queryResult?.social_networks?.youtube_url && (
        <React.Fragment>
          <ExternalLink
            url={queryResult?.social_networks?.youtube_url}
            target="_blank"
            title={i18next.t('ecommerceCoreUI:ToolTipTrans.youtube')}
          >
            <i className="icon-shape-26" />
          </ExternalLink>
          <Spacer />
        </React.Fragment>
      )}
      {queryResult?.social_networks?.instagram_url && (
        <ExternalLink
          url={queryResult?.social_networks?.instagram_url}
          target="_blank"
          title={i18next.t('ecommerceCoreUI:ToolTipTrans.instagram')}
        >
          <i className="icon-instagrame" />
        </ExternalLink>
      )}
    </SocialMediaItem>
  </SocialMediaWrapper>
);

NavSocialMedia.propTypes = {
  title: PropTypes.string,
  isCentered: PropTypes.bool,
  queryResult: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

NavSocialMedia.defaultProps = {
  title: null,
  isCentered: false,
  queryResult: [],
};

export default withRootQuery(
  withMedia(NavSocialMedia),
  SocialNetworkQuery,
  NavSocialMedia,
  null,
  { force: true },
);

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { css } from 'emotion';
import _ from 'lodash';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import ItemImage from '~/modules/ecommerceCoreUI/components/basic/ItemImage';

const refundAttachementStyles = () => css`
  border-radius: 30px;
`;

const keyLabelStyles = css`min-width: 120px;`;

/* eslint-disable react/prop-types */
const KeyValueTable = withMedia(({ keyValue }) => (
  <Column topAligned spaceBetween={1}>
    {Object.keys(_.pickBy(keyValue)).map(k => (
      <Row key={k}>
        <Label important sm semiBold nowrap className={keyLabelStyles}>{k}</Label>
        <Label emphasized sm>{keyValue[k]}</Label>
      </Row>
    ))}
  </Column>
));
/* eslint-enable react/prop-types */

const RefundReasons = props => (
  <Row fullWidth spaceBetweenJustified topAligned>
    <KeyValueTable
      keyValue={{
        [i18next.t('ecommerceOrder:refund.refundReason')]: props.refundReason,
        [i18next.t('ecommerceOrder:refund.detailedReason')]: props.detailedReason,
        [i18next.t('ecommerceOrder:refund.myNote')]: props.myNote,
        [i18next.t('ecommerceOrder:refund.shopNote')]: props.shopNote,
      }}
    />
    <ItemImage minWidth={6.5} minHeight={6.5} src={props.refundAttachement} customStyle={refundAttachementStyles} />
  </Row>
);

RefundReasons.defaultProps = {
  refundReason: null,
  detailedReason: null,
  myNote: null,
  shopNote: null,
  refundAttachement: null,
};

RefundReasons.propTypes = {
  refundReason: PropTypes.string,
  detailedReason: PropTypes.string,
  myNote: PropTypes.string,
  shopNote: PropTypes.string,
  refundAttachement: PropTypes.string,
};

export default RefundReasons;

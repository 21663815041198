/* eslint-disable import/prefer-default-export */
import styled from 'react-emotion';
import { css } from 'emotion';
import { Input } from 'semantic-ui-react';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Row } from '~/modules/coreUI/components/legacies/Rows';

export const SimpleDropdownTriggerStyles = props => css`
  font-size: ${props.theme.fonts.sizes.xSmall}px;
  line-height: ${props.theme.fonts.sizes.xSmall}px;
`;
export const SimpleCurrencyDropdownTriggerStyles = props => css`
  width: 25px;
  ${SimpleDropdownTriggerStyles(props)};
`;

export const SearchDropdownTriggerStyles = props => css`
  color: ${props.theme.colors.labels.normal};
  font-size: ${props.theme.fonts.sizes.small}px;
  font-weight: ${props.theme.fonts.weights.semiBold};
  line-height: ${props.theme.fonts.sizes.small}px;
`;

export const StyledHeaderDropdownContainer = styled.div`
  .chevron {
    padding-left: ${props => 0.35 * props.theme.fonts.sizes.xSmall}px;
    padding-top: 1px;
    font-size: ${props => 0.75 * props.theme.fonts.sizes.xSmall}px;
  }
`;

export const StyledProductSearchDropdownContainer = styled.div`
  flex-grow: 1;

  .dropdown {
    display: flex;
    height: 40px;
    flex-direction: row;
    justify-content: center;
    background: white;
    border-bottom-left-radius: ${props => props.theme.borders.radius.normal}px;
    border-top-left-radius: ${props => props.theme.borders.radius.normal}px;
    align-items: center;
  }

  .chevron {
    font-size: ${props => 0.75 * props.theme.fonts.sizes.xSmall}px;
    padding-left: ${props => 0.75 * props.theme.fonts.sizes.xSmall}px;
    padding-top: 1px;
  }
`;

export const StyledProductsSearch = styled(Row)`
  flex-grow: 1;
  border: solid;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  border-width: ${props => props.theme.borders.size.thin}px;
  border-color: ${props => props.theme.borders.color.light};
`;

export const StyledProductsSearchInput = styled(Input)`
  &&&&& {
    height: 40px;
    flex-grow: 4;

    ${cssMediaMax.xsmall`
      width: 100%;
      flex-grow: 1;
    `} input {
      padding: 6px;
      padding-left: 15px;
      font-size: 14px;
      border: 0;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      border-left: solid;
      border-width: ${props => props.theme.borders.size.thin}px;
      border-color: ${props => props.theme.borders.color.light};
    }

    button {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: white;

      ${props => cssMediaMax.tablet`
         background-color: ${props.theme.colors.primary};
         color: ${props.theme.colors.named.white};
      `} i {
        height: 16px;

        &:after {
          width: 2px;
          position: absolute;
          top: 8px;
          bottom: 8px;
          right: 37px;
          background-color: ${props => props.theme.borders.color.light};
          content: '';
        }
      }
    }
  }
`;

import React from 'react';
import styled from 'react-emotion';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import Image from '~/modules/coreUI/components/basic/Image';

const LogoImage = styled(Image)`
  padding: 0px;
  width: unset;
  height: unset;
`;

const PopupLogo = props => (
  <LogoImage
    src={
      props.i18n.language === 'en'
        ? `/${imagesPathName}/-e-logo-en-lg.png`
        : `/${imagesPathName}/logo-AYK-ar.png`
    }
  />
);

PopupLogo.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
};

export default translate()(PopupLogo);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { translate, Interpolate } from 'react-i18next';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { SmallLabel } from '~/modules/coreUI/components/legacies/Labels';

import withPaginationData from './PaginationData';

const getLastElement = pageInfo => (pageInfo.limit * pageInfo.current_page > pageInfo.items_count
  ? pageInfo.items_count
  : pageInfo.limit * pageInfo.current_page);

const getFirstElement = pageInfo => pageInfo.limit * (pageInfo.current_page - 1) + 1;

const SmallLabelUI = styled(SmallLabel)`
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.xSmall}px;
  `};
`;

const isPaginationActive = pageInfo => pageInfo && pageInfo.items_count > pageInfo.limit;

const PaginationTextInfo = ({ pageInfo }) => (isPaginationActive(pageInfo) ? (
  <SmallLabelUI>
    <Interpolate
      i18nKey="PaginationResult.ShowResults"
      start={pageInfo && getFirstElement(pageInfo)}
      end={pageInfo && getLastElement(pageInfo)}
      total={pageInfo && pageInfo.items_count}
    />
  </SmallLabelUI>
) : (
  <SmallLabelUI>
    <Interpolate
      i18nKey={
            pageInfo && pageInfo.items_count === 1
              ? 'PaginationResult.ShowSinglePageResult'
              : 'PaginationResult.ShowSinglePageResults'
          }
      total={pageInfo && pageInfo.items_count}
    />
  </SmallLabelUI>
));

PaginationTextInfo.propTypes = {
  refetchData: PropTypes.shape({}).isRequired,
}.isRequired;

export default translate('ecommerceCoreUI')(withPaginationData(PaginationTextInfo));

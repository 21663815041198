import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

const Loader = {
  load: (callback) => {
    // TODO fallback should be userinfo language
    i18next
      .use(reactI18nextModule)
      .use(LngDetector)
      .init(
        {
          fallbackLng: 'en',
          interpolation: {
            // React already does escaping
            escapeValue: false,
          },
          whitelist: ['en', 'ar'],
          // lng: 'en', // 'en' | 'es'
          detection: {
            // order and from where user language should be detected
            order: ['path', 'localStorage'],
            lookupFromPathIndex: 0,
            lookupLocalStorage: 'i18nextLng',
          },
        },
        callback,
      );
    return i18next;
  },
};

export default Loader;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { graphql } from 'react-relay';

import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';
import injectInBetween from '~/modules/core/utils/jsHelpers/injectElementBetweenChildElements';
import Loader from '~/modules/coreUI/components/basic/Loader';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import PaginationBox from '~/modules/coreUI/components/pagination/PaginationBox';
import PaginationTextInfo from '~/modules/coreUI/components/pagination/PaginationTextInfo';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import RefundPanel from './RefundPanel';
import EmptyRefundList from './EmptyRefundList';

const RefundList = ({ queryResult }) => ((queryResult.refund_requests.refunds !== null && queryResult.refund_requests.refunds.length > 0) ? (
  <Fragment>
    <Row fullWidth className="PaginatedListContainer"><PaginationTextInfo /></Row>
    <Spacer size={1.5} />
    {injectInBetween(
      queryResult.refund_requests.refunds.map((refund, i) => <RefundPanel refund={refund} key={refund.ref_id} collapsed={i !== 0} />),
      <Spacer size={1.5} />,
      true,
    )}
    <Spacer size={1} />
    <Row fullWidth centerJustified><PaginationBox /></Row>
    <Spacer size={1.3} />
  </Fragment>
) : <EmptyRefundList />);

RefundList.defaultProps = {
  queryResult: null,
};

RefundList.propTypes = {
  queryResult: PropTypes.shape({}),
};

const fragment = {
  queryResult: graphql`
    fragment RefundList_queryResult on Query @argumentDefinitions(input: { type: "RequndListInput" }) {
      refund_requests (input: $input) {
        page_info {
          ...PaginationData_pageInfo
        }
        refunds {
          ref_id
          refunded_at
          created_at
          number
          refund_status
          progress_bar {
            status
            name
          }
          vendor {
            ref_id
            name
            rating_count
            avg_rating
          }
          refund_request_items {
            refund_status
            quantity
            line_item {
              id
              ref_id
              eligible_refund
              price
              variant {
                display_price
                slug
                vendor_variants_count
                name
                rating_count
                avg_rating
                images_attachments {
                  url_small
                }
                option_values {
                  id
                  presentation
                }
              }
            }
          }
        }
      }
    }
  `,
};

const query = graphql`
  query RefundListQuery ($input: RequndListInput) {
    ...RefundList_queryResult @arguments(input: $input)
  }
`;

export default withRefetchQuery(
  RefundList,
  query,
  fragment,
  Loader,
  () => ({
    input: {
      page: {
        offset: 0,
        limit: 15,
      },
    },
  }),
  null,
  {
    pageInfo: queryResult => _.cloneDeep(queryResult.refund_requests.page_info),
  },
  true,
  null,
  { force: true },
);

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaxonLink_taxonInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type DepartmentBrands_topBrands$ref: FragmentReference;
export type DepartmentBrands_topBrands = $ReadOnlyArray<{|
  +$fragmentRefs: TaxonLink_taxonInfo$ref,
  +$refType: DepartmentBrands_topBrands$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DepartmentBrands_topBrands",
  "type": "Taxon",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "TaxonLink_taxonInfo",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '133194e51969aa92facc4f9ddcffd185';
module.exports = node;

// eslint-disable-next-line
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';

import { CenteredParagraphPanelContent } from '~/modules/accountManagement/components/basic/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import {
  LargeSpacer,
  XLargeSpacer,
  XXLargeSpacer,
} from '~/modules/coreUI/components/legacies/Spacers';
import Image from '~/modules/coreUI/components/basic/Image';
import Panel from '~/modules/accountManagement/components/basic/Panel';
import withUserInfo, { getUserInfoFromLoginResponse } from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import { LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/legacies/Labels';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import VerifyByEmailForm from './VerifyAccountForm';

const RecoverPasswordImage = styled(Image)`
  height: unset;
  width: unset;
  max-height: 80px;
`;

class VerifyAccount extends React.Component {
  state = {
    panelError: null,
    isLoading: false,
  };

  onSuccess = (response) => {
    const { history, updateUserInfo } = this.props;

    if (!this.form || !response || !response.confirm_email) {
      return;
    }

    updateUserInfo({
      ...getUserInfoFromLoginResponse(response.confirm_email, true),
    });

    navigateToModal(history, '/accountManagement/showSuccess');
  };

  onError = error => this.setState({ panelError: error });

  setLoadingState = (isLoading) => {
    this.setState({ isLoading });
  };

  render = () => {
    const { panelError } = this.state;
    const isLoading = this.state.isLoading || this.props.isLoading;

    return (
      <Panel
        titleLabel={i18next.t('accountManagement:verifyAccount.HeaderTitle')}
        subTitleLabel={i18next.t('accountManagement:verifyAccount.SubTitle')}
        error={panelError || this.props.error}
      >
        <Spacer size={2} />
        <RecoverPasswordImage
          src={this.props.imageSrc}
          srcSet={this.props.imageSrcSet}
        />
        <LargeSpacer />
        <XLargeSpacer />
        <XLargeSpacer />
        <CenteredParagraphPanelContent>
          {this.props.contentText}
        </CenteredParagraphPanelContent>
        <XLargeSpacer />
        <VerifyByEmailForm
          ref={(ref) => {
            this.form = ref;
          }}
          onFormError={error => this.onError(error)}
          onFormSuccess={response => this.onSuccess(response)}
          onFormLoading={loading => this.setLoadingState(loading)}
        />
        <Button primary block lg loading={isLoading} onClicked={() => this.form.submitForm()}>
          {i18next.t('accountManagement:verifyAccount.ButtonCreateAccount')}
        </Button>
        <XXLargeSpacer />
        <LinearLayout row fullWidth centerAligned>
          <Label>
            {i18next.t('accountManagement:verifyAccount.Didreceivethecodeyet')}
            &nbsp;
            <a style={{ cursor: 'pointer', fontWeight: '600' }} onClick={this.props.onResendClicked}>
              {i18next.t('accountManagement:verifyAccount.Resendit')}
            </a>
          </Label>
        </LinearLayout>
      </Panel>
    );
  };
}

VerifyAccount.defaultProps = {
  error: null,
};

VerifyAccount.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageSrcSet: PropTypes.string.isRequired,
  onResendClicked: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  contentText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default withUserInfo(
  withRelayEnvironment(
    withRouter(VerifyAccount),
  ),
);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Shiitake from 'shiitake';

const PositionedParent = styled.div`
  position: relative;
`;

const StyledSpan = styled.div`
  ${props => props.customStyle && props.customStyle(props)};
`;

class EllipisWithTooltip extends React.Component {
  componentDidUpdate = () => {
    this.addHiddenOverflow();
  };

  getInnerText = () => (this.rootElement ? this.rootElement.textContent : null);

  checkOverflow = () => (this.rootElement ? this.rootElement.scrollWidth > this.rootElement.clientWidth : false);

  addHiddenOverflow = () => {
    const addOverflow = this.checkOverflow();
    if (addOverflow) {
      this.rootElement.firstChild.style.overflow = 'hidden';
    }
  };

  render() {
    const { children, customTextStyle, textOverflow } = this.props;

    return (
      <PositionedParent>
        <div>
          <StyledSpan
            hasOverflow={this.checkOverflow()}
            textOverflow={textOverflow}
            customStyle={customTextStyle}
            title={children}
          >
            <Shiitake lines={1} throttleRate={200}>
              {children}
            </Shiitake>
          </StyledSpan>
        </div>
      </PositionedParent>
    );
  }
}

EllipisWithTooltip.propTypes = PropTypes.shape({
  color: PropTypes.string,
  children: PropTypes.any.isRequired,
}).isRequired;

EllipisWithTooltip.defaultTypes = {
  color: '#555',
};

export default EllipisWithTooltip;

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isLocationModal, closeCurrentModal } from '~/modules/core/utils/modalHelpers';

import ModalTrackerContext from './ModalTrackerContext';

class ModalTrackerProvider extends React.Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount = () => {
    this.myLocation = this.props.history.location;
    this.historyUnblock = this.props.history.listen((location) => { // Handle closure of Modals that doesn't depend on routes
      const isModal = isLocationModal(location);
      if (!isModal) {
        this.setState({
          isModalOpen: false,
        });
      }
    });
  }

  closeCurrentModal = () => {
    const { history } = this.props;

    closeCurrentModal(history);

    this.setState({
      isModalOpen: false,
    });
  };

  onModalOpened = () => {
    if (!this.state.isModalOpen) {
      this.setState({
        isModalOpen: true,
      });
    }
  };

  render = () => (
    <ModalTrackerContext.Provider
      value={{
        isModalOpen: this.state.isModalOpen,
        closeCurrentModal: this.closeCurrentModal,
        onModalOpened: this.onModalOpened,
      }}
    >
      {this.props.children}
    </ModalTrackerContext.Provider>
  );
}

ModalTrackerProvider.propTypes = PropTypes.shape({
  children: PropTypes.shape({}),
}).isRequired;

export default withRouter(ModalTrackerProvider);

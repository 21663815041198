import React from 'react';
import PropTypes from 'prop-types';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import NavCurrencyDesktop from './NavCurrency/NavCurrencyDesktop';

const getCurrencyList = userInfo => (!userInfo || !userInfo.settings ? [] : userInfo.settings.supported_currencies);

const NavCurrency = (props) => {
  const currencyView = (
    <NavCurrencyDesktop
      options={getCurrencyList(props.userInfo)}
    />
  );

  return <div>{currencyView}</div>;
};

NavCurrency.propTypes = {
  userInfo: PropTypes.shape({
    settings: PropTypes.shape({
      supported_currencies: PropTypes.array.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  }),
};
NavCurrency.defaultProps = {
  userInfo: null,
};
export default withUserInfo(NavCurrency);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { graphql } from 'react-relay';

import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';
import injectInBetween from '~/modules/core/utils/jsHelpers/injectElementBetweenChildElements';
import Loader from '~/modules/coreUI/components/basic/Loader';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import PaginationBox from '~/modules/coreUI/components/pagination/PaginationBox';
import PaginationTextInfo from '~/modules/coreUI/components/pagination/PaginationTextInfo';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import OrderPanel from './orderPanel/OrderPanel';
import EmptyOrdersList from './EmptyOrdersList';

const OrdersList = ({
  queryResult: {
    completed_orders: { orders },
  },
}) => ((orders !== null && orders.length > 0) ? (
  <Fragment>
    <Row fullWidth className="PaginatedListContainer"><PaginationTextInfo /></Row>
    <Spacer size={1.5} />
    {injectInBetween(
      orders.map((order, i) => <OrderPanel order={order} key={order.id} collapsed={i !== 0} />),
      <Spacer size={1.5} />,
      true,
    )}
    <Spacer size={1} />
    <Row fullWidth centerJustified><PaginationBox /></Row>
    <Spacer size={1.3} />
  </Fragment>
) : <EmptyOrdersList />);

OrdersList.defaultProps = {
  queryResult: null,
};

OrdersList.propTypes = {
  queryResult: PropTypes.shape({}),
};

const fragment = {
  queryResult: graphql`
    fragment OrdersList_queryResult on Query @argumentDefinitions(input: { type: "OrdersListInput" }) {
      completed_orders (input: $input) {
        page_info {
          ...PaginationData_pageInfo
        }
        errors {
          field
          messages
          code
        }
        orders {
          eligible_refund
          progress_bar {
            status
            name
          }
          product_type
          delivery_date
          tracking_number
          id
          created_at
          notes
          admin_rejection_note
          shipment_state
          state
          number
          vendor {
            name
            rating_count
            avg_rating
            ref_id
          }
          line_items {
            id
            ref_id
            eligible_refund
            variant {
              slug
              vendor_variants_count
              name
              rating_count
              avg_rating
              images_attachments {
                url_small
              }
              option_values {
                id
                presentation
              }
            }
            quantity
          }
        }
        errors {
          field
          messages
          code
        }
      }
    }
  `,
};

const query = graphql`
  query OrdersListQuery ($input: OrdersListInput) {
    ...OrdersList_queryResult @arguments(input: $input)
  }
`;

export default withRefetchQuery(
  OrdersList,
  query,
  fragment,
  Loader,
  () => ({
    input: {
      page: {
        offset: 0,
        limit: 15,
      },
    },
  }),
  null,
  {
    pageInfo: queryResult => _.cloneDeep(queryResult.completed_orders.page_info),
  },
  true,
  null,
  { force: true },
);

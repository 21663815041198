/**
 * @flow
 * @relayHash 460e2454a0cefb12806b96b0a353d4af
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LuckyWheelMutationVariables = {|
  email?: ?string
|};
export type LuckyWheelMutationResponse = {|
  +spin_wheel: ?{|
    +wheel_description: ?string,
    +wheel_name: ?string,
    +wheel_no: ?string,
    +won: ?boolean,
    +promo: ?string,
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type LuckyWheelMutation = {|
  variables: LuckyWheelMutationVariables,
  response: LuckyWheelMutationResponse,
|};
*/


/*
mutation LuckyWheelMutation(
  $email: String
) {
  spin_wheel(email: $email) {
    wheel_description
    wheel_name
    wheel_no
    won
    promo
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "spin_wheel",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email",
        "type": "String"
      }
    ],
    "concreteType": "SpinWheelResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "wheel_description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "wheel_name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "wheel_no",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "won",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "promo",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "LuckyWheelMutation",
  "id": null,
  "text": "mutation LuckyWheelMutation(\n  $email: String\n) {\n  spin_wheel(email: $email) {\n    wheel_description\n    wheel_name\n    wheel_no\n    won\n    promo\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LuckyWheelMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "LuckyWheelMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5bcb0fc229e722b1549308dd2e0c2155';
module.exports = node;

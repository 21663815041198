import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { GridArea } from '~/modules/coreUI/components/layouts/helpers/GridLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const DesktopGrid = styled.div`
  display: grid;
  grid-template:
    'itemImage      column          itemQuantity'  auto
    'itemImage      column          .'             auto
    'itemImage      column          refundButton'  auto
    'refundReasons  refundReasons   refundReasons' auto
    / 100px auto auto;

  grid-column-gap: ${props => props.theme.new.spacer}px;
  grid-row-gap: ${props => props.theme.new.spacer}px;
  width: 100%;
`;

const itemNameContainer = css`
  max-width: 570px;
`;

const DesktopLayout = props => (
  <Row spaceBetweenJustified centerAligned fullWidth>
    <DesktopGrid>
      <GridArea gridArea="column" leftAligned spaceBetween={1}>
        <div className={itemNameContainer}>{props.itemName}</div>
        {props.itemOptions}
        {props.itemRating}
        {props.itemPrice}
        {props.counter}
      </GridArea>
      <GridArea gridArea="itemImage">{props.itemImage}</GridArea>
      {props.itemQuantity && (
        <GridArea gridArea="itemQuantity" rightAligned>
          <Row>
            <Separator vertical separatorLength="full" />
            {props.itemQuantity}
          </Row>
        </GridArea>
      )}
      <GridArea gridArea="refundButton" rightAligned>
        {props.refundButton}
        {props.cancelButton}
        {props.refundStatus}
      </GridArea>
      {props.refundReasons ? (
        <GridArea gridArea="refundReasons" leftAligned>
          <Spacer size={1} />
          {props.refundReasons}
        </GridArea>
      ) : null}
    </DesktopGrid>
    {props.checkbox}
  </Row>
);

DesktopLayout.defaultProps = {
  refundButton: undefined,
  itemQuantity: undefined,
  itemOptions: undefined,
  itemRating: undefined,
  itemPrice: undefined,
  counter: undefined,
  checkbox: undefined,
  refundStatus: undefined,
  refundReasons: undefined,
  cancelButton: undefined,
};

DesktopLayout.propTypes = {
  itemName: PropTypes.element.isRequired,
  itemImage: PropTypes.element.isRequired,
  itemQuantity: PropTypes.element,
  itemOptions: PropTypes.element,
  itemRating: PropTypes.element,
  itemPrice: PropTypes.element,
  counter: PropTypes.element,
  refundButton: PropTypes.element,
  checkbox: PropTypes.element,
  refundStatus: PropTypes.element,
  refundReasons: PropTypes.element,
  cancelButton: PropTypes.element,
};

export default DesktopLayout;

import { graphql } from 'react-relay';

export const ResendEmailConfirmationCodeMutationRoot = 'send_confirmation_code';

export default graphql`
  mutation ResendEmailConfirmationCodeMutation($email: String!) {
    send_confirmation_code(email: $email) {
      errors {
        field
        messages
        code
      }
      sent
    }
  }
`;

/* eslint-env browser */
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import googleMapKey from '~/modules/ecommerceCore/utils/googleMapKey';
import Geocode from 'react-geocode';
import Autocomplete from "react-google-autocomplete";

const MapWrapper = styled.div`
  min-height: ${props => props.theme.new.spacer * 19}px;
  width: 100%;
  position: relative;
`;
class MapContainer extends Component {
  componentDidMount = () => {
    if (this.props.autoDetectLocation) {
      this.getcurrentLocation();
    }
  };
  getSearchLocation = (place) => {
    if(place.geometry) {
      if(place.geometry.location){
        this.onLocationChange(place.geometry.location.lat(), place.geometry.location.lng());
      }
    }else {
      this.getcurrentLocation();
    }
  };
  getcurrentLocation = () => {
    if (navigator && navigator.geolocation) {
      console.log('navigator', navigator)
      navigator.geolocation.getCurrentPosition(this.showPosition);
    }
  };

  showPosition = position => this.onLocationChange(position.coords.latitude, position.coords.longitude);

  addMarker = (location, map) => {
    this.onLocationChange(location.lat(), location.lng());
    map.panTo(location);
  };

  onLocationChange = (lat, lng) => {
    this.props.onChange({ lat, lng });

    Geocode.setApiKey(googleMapKey);
    Geocode.fromLatLng(lat, lng).then(response => this.props.onChange({
      lat,
      lng,
      address1: response.results[0].formatted_address,
    }));
  }

  lat = () => this.props.value && this.props.value.lat;

  lng = () => this.props.value && this.props.value.lng;

  render = () => (
    <Map
      google={this.props.google}
      zoom={14}
      style={{
        height: '190px',
        width: '100%',
        borderRadius: '5px',
        left: '0',
      }}
      onClick={(t, map, c) => this.addMarker(c.latLng, map)}
      scrollwheel
      initialCenter={{
        lat: this.lat(),
        lng: this.lng(),
      }}
      center={{
        lat: this.lat(),
        lng: this.lng(),
      }}
    >
      <Autocomplete
        style={{
          position:'absolute',
          top:0,
        }}
        apiKey={googleMapKey}
        onPlaceSelected={(place) => {
          this.getSearchLocation(place)
        }}
      />
      {this.lat() && (
        <Marker
          onClick={this.onMarkerClick}
          position={{
            lat: this.lat(),
            lng: this.lng(),
          }}
          icon={{
            url: `/${imagesPathName}/Artboard.png`,
            anchor: new window.google.maps.Point(32, 32),
            scaledSize: new window.google.maps.Size(64, 64),
          }}
        />
      )}
    </Map>
  );
}

MapContainer.defaultProps = {
  autoDetectLocation: true,
};

MapContainer.propTypes = {
  google: PropTypes.shape({}).isRequired,
  autoDetectLocation: PropTypes.bool,
  value: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onChange: PropTypes.func.isRequired,
};

MapContainer.defaultProps = {
  value: null,
};

const MapWithGoogleAPIWrapper = GoogleApiWrapper({
  apiKey: googleMapKey,
})(MapContainer);

// Workaround for the root div, added by the lib 'GoogleApiWrapper'
export default props => <MapWrapper><MapWithGoogleAPIWrapper {...props} /></MapWrapper>;

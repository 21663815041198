/**
 * @flow
 * @relayHash df5e590b4c9659b433b5fd9d46ba12df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MobileCurrenciesListQueryVariables = {||};
export type MobileCurrenciesListQueryResponse = {|
  +system_configuration_settings: ?{|
    +supported_currencies: ?$ReadOnlyArray<?string>
  |}
|};
export type MobileCurrenciesListQuery = {|
  variables: MobileCurrenciesListQueryVariables,
  response: MobileCurrenciesListQueryResponse,
|};
*/


/*
query MobileCurrenciesListQuery {
  system_configuration_settings {
    supported_currencies
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "system_configuration_settings",
    "storageKey": null,
    "args": null,
    "concreteType": "ConfigurationSettings",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supported_currencies",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MobileCurrenciesListQuery",
  "id": null,
  "text": "query MobileCurrenciesListQuery {\n  system_configuration_settings {\n    supported_currencies\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MobileCurrenciesListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "MobileCurrenciesListQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd074598483e3679430ce3077ffc7e0c1';
module.exports = node;

import React from 'react';
import CartInfoContext from './CartInfoContext';

export default function withCartInfo(WrappedComponent) {
  return function render(props) {
    return (
      <CartInfoContext.Consumer>
        {cartManagement => (
          <WrappedComponent
            shoppingCart={cartManagement && cartManagement.shoppingCart}
            shoppingCartErrors={cartManagement && cartManagement.errors}
            shoppingCartGuestTokens={cartManagement && cartManagement.shoppingCartGuestTokens}

            authenticatedCartInfoReady={cartManagement && cartManagement.authenticatedCartInfoReady}
            setShoppingCart={cartManagement && cartManagement.setShoppingCart}
            setCartManagementPartial={cartManagement && cartManagement.setCartManagementPartial}

            {...props}
          />
        )}
      </CartInfoContext.Consumer>
    );
  };
}

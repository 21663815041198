import { graphql } from 'react-relay';

const NewAddressMutation = graphql`
  mutation NewAddressMutation($data: UserAddressInput) {
    create_user_address(input: $data) {
      user {
        shipping_addresses_previous {
          ...WithAddressData_addressInfo @relay(mask: false)
        }
        billing_addresses_previous {
          ...WithAddressData_addressInfo @relay(mask: false)
        }
        cart {
          ...WithCartData_cart @relay(mask: false)
        }
      }
      errors{
        field
        messages
        code
      }
    }
  }
`;

export default NewAddressMutation;

/**
 * @flow
 * @relayHash 0a0686c3131daefa994c684c96c0925b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MyPromosQueryVariables = {||};
export type MyPromosQueryResponse = {|
  +current_user_details: ?{|
    +current_user: ?{|
      +ref_id: string,
      +promotions: ?$ReadOnlyArray<?{|
        +ref_id: string,
        +name: ?string,
        +code: ?string,
        +description: ?string,
        +expires_at: ?any,
        +starts_at: ?any,
        +display_name: ?string,
        +actions: ?$ReadOnlyArray<?string>,
        +vendor: ?{|
          +ref_id: string,
          +name: ?string,
        |},
      |}>,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type MyPromosQuery = {|
  variables: MyPromosQueryVariables,
  response: MyPromosQueryResponse,
|};
*/


/*
query MyPromosQuery {
  current_user_details {
    current_user {
      ref_id
      promotions {
        ref_id
        name
        code
        description
        expires_at
        starts_at
        display_name
        actions
        vendor {
          ref_id
          name
          id
        }
        id
      }
      id
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expires_at",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "starts_at",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "display_name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actions",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    },
    v2
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MyPromosQuery",
  "id": null,
  "text": "query MyPromosQuery {\n  current_user_details {\n    current_user {\n      ref_id\n      promotions {\n        ref_id\n        name\n        code\n        description\n        expires_at\n        starts_at\n        display_name\n        actions\n        vendor {\n          ref_id\n          name\n          id\n        }\n        id\n      }\n      id\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MyPromosQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "current_user_details",
        "storageKey": null,
        "args": null,
        "concreteType": "UserDetails",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "promotions",
                "storageKey": null,
                "args": null,
                "concreteType": "Promotion",
                "plural": true,
                "selections": [
                  v0,
                  v1,
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "vendor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Shop",
                    "plural": false,
                    "selections": [
                      v0,
                      v1
                    ]
                  }
                ]
              }
            ]
          },
          v8
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyPromosQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "current_user_details",
        "storageKey": null,
        "args": null,
        "concreteType": "UserDetails",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "promotions",
                "storageKey": null,
                "args": null,
                "concreteType": "Promotion",
                "plural": true,
                "selections": [
                  v0,
                  v1,
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "vendor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Shop",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      v9
                    ]
                  },
                  v9
                ]
              },
              v9
            ]
          },
          v8
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8c4f8c78ed26ebe4e0b3ee2e5309409';
module.exports = node;

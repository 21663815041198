/**
 * @flow
 * @relayHash fedcd0bca425170417a1ed0ded22d3f1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LuckyWheelQueryVariables = {||};
export type LuckyWheelQueryResponse = {|
  +lucky_wheel_eligible: ?{|
    +show_user_daily_chance: ?boolean,
    +show_wheel: ?boolean,
  |}
|};
export type LuckyWheelQuery = {|
  variables: LuckyWheelQueryVariables,
  response: LuckyWheelQueryResponse,
|};
*/


/*
query LuckyWheelQuery {
  lucky_wheel_eligible {
    show_user_daily_chance
    show_wheel
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "lucky_wheel_eligible",
    "storageKey": null,
    "args": null,
    "concreteType": "LuckyWheel",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "show_user_daily_chance",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "show_wheel",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "LuckyWheelQuery",
  "id": null,
  "text": "query LuckyWheelQuery {\n  lucky_wheel_eligible {\n    show_user_daily_chance\n    show_wheel\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LuckyWheelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "LuckyWheelQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d22507d59504866a51b0bc03ccec1e8';
module.exports = node;

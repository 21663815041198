const OfferTagTrans = {
  En: {
    hotDeal: 'Hot Deal',
    offer: 'Bundle Offer',
    discount: '{{discount}}% Off',
    sale: 'SALE',
  },
  Ar: {
    hotDeal: 'صفقة مذهلة',
    offer: 'عرض الحزمة',
    discount: '{{discount}}% خصم',
    sale: 'تخفيضات',
  },
};

export default OfferTagTrans;

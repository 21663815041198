/* eslint-disable camelcase,jsx-a11y/anchor-is-valid */
import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import Shiitake from 'shiitake';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import ItemImage from '~/modules/ecommerceCoreUI/components/basic/ItemImage';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';

const itemImageStyle = css`
  width: 70px;
  height: 70px;
`;

const labelStyle = css`
  max-width: 200px;
`;

const imageURL = images_attachments => images_attachments
  && images_attachments.length > 0
  && images_attachments[0].url_product;

const SmartCartAlert = ({
  lineItem: {
    variant: {
      name,
      images_attachments, // eslint-disable-line camelcase
    },
    total,
    quantity,
  },
}) => (
  <Column leftAligned spaceBetween={1}>
    <Row>
      <Label inverted important semiBold md>
        {i18next.t('ecommerceCoreUI:Product.newItem')}
      </Label>
    </Row>
    <Row topAligned spaceBetween={1}>
      <Column spaceBetween={1}>
        <ItemImage src={imageURL(images_attachments)} className={itemImageStyle} />
      </Column>
      <Column leftAligned spaceBetween={0.5}>
        <Label inverted sm emphasized className={labelStyle}>
          <Shiitake lines={2}>{name}</Shiitake>
        </Label>
        <Label inverted sm emphasized>
          {quantity}
          {i18next.t('ecommerceOrder:smartCart.itemQuantityLabel')}
        </Label>
        <Label inverted semiBold sm emphasized>{`${total} ${currentCurrencyDisplayName()}`}</Label>
      </Column>
    </Row>
  </Column>
);

SmartCartAlert.propTypes = {
  lineItem: PropTypes.shape({}).isRequired,
};

export default SmartCartAlert;

import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import ItemCard from '~/modules/coreUI/components/itemCard';
import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

class ShopCard extends React.PureComponent {
  render = () => {
    const {
      shopInfo, isVertical,
    } = this.props;
    return (
      <ItemCard
        isShop
        isVertical={isVertical}
        itemInfo={shopInfo}
      />
    );
  }
}

ShopCard.propTypes = {
  shopInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired,
    rating_count: PropTypes.number,
    avg_rating: PropTypes.number.isRequired,
    follow: PropTypes.bool,
    track: PropTypes.string.isRequired,
  }).isRequired,
  isVertical: PropTypes.bool,
};

ShopCard.defaultProps = {
  isVertical: true,
};

export default withFragmentContainer(
  ShopCard,
  graphql`
    fragment RecommendedShopCard_shopInfo on Shop {
      id
      ref_id
      logo_url
      track
      name
      follow
      avg_rating
      rating_count
      city {
        name
      }
      country {
        name
      }
      featured
      departments {
        id
        name
      }
    }
  `,
);

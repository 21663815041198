/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import qs from 'qs';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';

import {
  CenteredParagraphPanelContent,
  EmailSentTitle,
} from '~/modules/accountManagement/components/basic/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import { XLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import Image from '~/modules/coreUI/components/basic/Image';
import Panel from '~/modules/accountManagement/components/basic/Panel';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import VerifyByEmailForm from './RecoverVerifyByEmailForm';
import { RecoverPasswordFormMutationRoot, RecoverPasswordFormMutation } from '../recoverPassword/RecoverPasswordForm';


const IMAGE_PATH = `/${imagesPathName}/AccountManagement`;

const RecoverPasswordImage = styled(Image)`
  height: unset;
  width: unset;
  max-height: 80px;
`;

class VerficationCodeEmail extends React.Component {
  state = {
    panelError: null,
    isLoading: false,
    isResendMutationLoading: false,
  };

  onSuccess = () => {
    const { history, updateUserInfoTempPartial, userInfo } = this.props;

    if (!this.form) {
      return;
    }

    updateUserInfoTempPartial({
      email: userInfo.email,
      code: this.form.getValue().code,
    });

    navigateToModal(history, '/accountManagement/recoverpassword/CreateNewPassword');
  };

  onError = error => this.setState({ panelError: error });

  setLoadingState = (isLoading) => {
    this.setState({ isLoading });
  };

  getVerificationMedium = (props) => {
    const DEFAULT_MEDIUM = 'email';
    let queryString = props.location.search;
    queryString = queryString.length > 0 && queryString[0] === '?'
      ? queryString.substr(1)
      : queryString;

    const params = qs.parse(queryString);
    if (!params || !params.verificationMedia) {
      return DEFAULT_MEDIUM;
    }
    return localStorage.getItem('verificationMedia');
  }


  onResendClicked = () => {
    commitMutation(
      this.props.environment,
      RecoverPasswordFormMutation,
      RecoverPasswordFormMutationRoot,
      {
        user_signin: this.props.userInfo?.email,
      },
      null,
      null,
      loading => this.setState({ isResendMutationLoading: loading }),
    );
  }

  render = () => {
    const { isLoading, panelError } = this.state;
    const { userInfo } = this.props;

    let currentPanelError = panelError;
    if (!userInfo || !userInfo.email) {
      currentPanelError = i18next.t('accountManagement:verifyAccount.emailError');
    }

    const verificationMedium = localStorage.getItem('verificationMedia');
    return (
      <Panel
        titleLabel={i18next.t('accountManagement:recoverPassword.panelTitle')}
        subTitleLabel={i18next.t('accountManagement:recoverPassword.panelSubtitle')}
        error={currentPanelError}
      >
        <Spacer size={2} />
        <RecoverPasswordImage
          src={verificationMedium === 'sms'
            ? `${IMAGE_PATH}/SMSImages.png`
            : `${IMAGE_PATH}/Email.png`}
        />
        <XLargeSpacer />
        <EmailSentTitle>
          {verificationMedium === 'sms' ? (
            i18next.t('accountManagement:forgetPassVerifyEmailSMS.SMSSecondTitle')
          ) : (
            i18next.t('accountManagement:forgetPassVerifyEmailSMS.EmailSecondTitle')
          )}
        </EmailSentTitle>
        <XLargeSpacer />
        <CenteredParagraphPanelContent>
          {verificationMedium === 'sms' ? (
            i18next.t('accountManagement:verifyAccount.SMSTxt')
          ) : (
            i18next.t('accountManagement:verifyAccount.EmailTxt')
          )}
        </CenteredParagraphPanelContent>
        <XLargeSpacer />
        <VerifyByEmailForm
          ref={(ref) => {
            this.form = ref;
          }}
          addiontalMutationVariables={{
            email: userInfo && userInfo.email,
          }}
          onFormError={error => this.onError(error)}
          onFormSuccess={response => this.onSuccess(response)}
          onFormLoading={loading => this.setLoadingState(loading)}
        />
        <Button block primary lg loading={isLoading} onClicked={() => this.form.submitForm()}>
          {i18next.t('accountManagement:recoverPassword.resetButton')}
        </Button>
        <Spacer />
        <Row fullWidth centerJustified>
          <Label sm>
            {i18next.t('accountManagement:verifyAccount.Didreceivethecodeyet')}
            &nbsp;
            {this.state.isResendMutationLoading ? (
              i18next.t('accountManagement:verifyAccount.sending')
            ) : (
              <a style={{ cursor: 'pointer', fontWeight: '600' }} onClick={this.onResendClicked}>
                {i18next.t('accountManagement:verifyAccount.Resendit')}
              </a>
            )}
          </Label>
        </Row>
      </Panel>
    );
  };
}

VerficationCodeEmail.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape().isRequired,
  environment: PropTypes.shape().isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  updateUserInfoTempPartial: PropTypes.func.isRequired,
};

export default withUserInfo(withRelayEnvironment(withRouter(VerficationCodeEmail)));

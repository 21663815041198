/* eslint-disable react/no-array-index-key */
import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { Trans, translate } from 'react-i18next';
import styled from 'react-emotion';
import { Route, withRouter, Switch } from 'react-router-dom';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { LOADING_PLACEHOLDER } from '~/modules/coreUI/components/legacies/Dropdown';
import PRODUCT_SEARCH_PATH from '~/modules/ecommerceCore/routes/ProductSearchPath';
import SHOP_SEARCH_PATH from '~/modules/ecommerceCore/routes/ShopSearchPath';
import objectsDeepNotEqualComparison from '~/modules/core/utils/jsHelpers/objectsDeepComparison';

import DepartmentsDropdown from './DepartmentsDropdown';
import DropdownProducts from './DropdownProducts';
import DropdownShops from './DropdownShops';

const ALL_PRODUCTS = {
  text: <Trans i18nKey="header.allProducts" />,
  value: 'all-products',
};

const DepartmentsDropdownContainer = styled(DepartmentsDropdown)`
  min-width: 150px;
  > div > div {
    width: 100%;
  }
`;

const DepartmentSearchComponent = ({
  department, // eslint-disable-line react/prop-types
  productSearchString, // eslint-disable-line react/prop-types
  shopSearchString, // eslint-disable-line react/prop-types
}) => {
  if (department === LOADING_PLACEHOLDER.value) {
    return null;
  }
  return department === ALL_PRODUCTS.value ? (
    <DropdownProducts searchString={productSearchString} />
  ) : (
    <DropdownShops department={department} searchString={shopSearchString} />
  );
};

class NavSearch extends React.Component {
  static getDerivedStateFromProps(nextProps, currentState) {
    const newSearchParams = {
      department: (nextProps && nextProps.match.params.trackId) || ALL_PRODUCTS.value,
      productSearchString: nextProps && nextProps.match.params.productSearchString,
      shopSearchString: nextProps && nextProps.match.params.shopSearchString,
    };

    const newParamsReceived = objectsDeepNotEqualComparison(
      newSearchParams,
      currentState.prevSearchParams,
    );

    if (newParamsReceived) {
      return {
        searchParams: newSearchParams,
        prevSearchParams: newSearchParams,
      };
    }

    return null;
  }

  state = {
    searchParams: {
      department: ALL_PRODUCTS.value,
      productSearchString: '',
      shopSearchString: '',
    },
    prevSearchParams: null, // eslint-disable-line react/no-unused-state
  };

  onDepartmentChanged = (department) => {
    if (department !== this.state?.searchParams?.department) {
      this.setState({
        searchParams: {
          department,
          productSearchString: '',
          shopSearchString: '',
        },
      });
    }
  }

  getDepartments = (queryResult) => {
    if (!queryResult) {
      return [...[ALL_PRODUCTS], ...[LOADING_PLACEHOLDER]];
    }
    return [
      ...[ALL_PRODUCTS],
      ...queryResult.tracks.map(track => ({
        text: track.name,
        value: track.track_type,
      })),
    ];
  };

  render = () => {
    const { queryResult } = this.props;
    return (
      <React.Fragment>
        <DepartmentsDropdownContainer
          departments={this.getDepartments(queryResult)}
          onDepartmentChanged={this.onDepartmentChanged}
          selectedValue={this.state.searchParams && this.state.searchParams.department}
        />
        <DepartmentSearchComponent
          department={this.state.searchParams && this.state.searchParams.department}
          productSearchString={
            this.state.searchParams && this.state.searchParams.productSearchString
          }
          shopSearchString={this.state.searchParams && this.state.searchParams.shopSearchString}
        />
      </React.Fragment>
    );
  };
}

NavSearch.propTypes = {
  queryResult: PropTypes.shape({
    tracks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
}.isRequired;

const NavSearchWithQuery = translate('ecommerceCoreUI')(
  withRootQuery(
    NavSearch,
    graphql`
      query NavSearchQuery {
        tracks {
          track_type
          name
        }
      }
    `,
    NavSearch,
  ),
);

const searchRoutes = [SHOP_SEARCH_PATH, PRODUCT_SEARCH_PATH, '/:locale(ar|en)?/'];
const RouteWrapper = () => (
  <Switch>
    {searchRoutes.map(route => (
      <Route strict path={route} render={props => <NavSearchWithQuery {...props} />} key={route} />
    ))}
  </Switch>
);

export default withRouter(RouteWrapper);

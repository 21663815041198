import {
  graphql,
} from 'relay-runtime';

export const MarkMessageAsReadMutationRoot = 'read_messages';

export const MarkMessageAsReadMutation = graphql`
  mutation MarkMessageAsReadMutation($thread_id: ID!) {
    read_messages(thread_id: $thread_id) {
      threads_with_unread_messages_count
      thread{
        ...ThreadFragment_queryResult @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;

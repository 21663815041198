import React from 'react';
import i18next from 'i18next';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Tracks from '~/modules/ecommerceCoreUI/components/Tracks';


const DropDownTracks = () => (
  <ul>
    {Tracks.map(item => (
      <li key={item.name}>
        <ExternalLink url={item.url}>
          <Row>
            <div className="track-mobile-icon">{item.activeIcon}</div>
            <Spacer size={0.8} />
            {i18next.t(item.name)}
          </Row>
        </ExternalLink>
      </li>
    ))}
  </ul>
);

export default DropDownTracks;

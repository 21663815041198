import { graphql } from 'relay-runtime';

const SocialNetworkQuery = graphql`
  query SocialNetworkQuery {
    social_networks {
      android_application
      facebook_url
      instagram_url
      ios_application
      mobile_app_image{
        url_original
        name
        type
      }
      twitter_url
      youtube_url
    }
  }
`;


export default SocialNetworkQuery;

import { isOnInternetExplorerEdge } from '~/modules/core/utils/jsHelpers/userAgentDetector';

const modalMarker = '__modal';

export const isLocationModal = (location) => {
  const modalMarkerIndex = location.pathname.search(modalMarker);
  if (modalMarkerIndex >= 0) {
    return true;
  }
  return false;
};

export const scrollCurrentModalToTop = () => {
  if (isOnInternetExplorerEdge()) {
    // scroll method isn't defined on Edge
    document.getElementsByClassName('ReactModal__Content')[0].scrollTop = 0;
  } else {
    document.getElementsByClassName('ReactModal__Content')[0].scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export const extractModalPartFromLocation = (location) => {
  const currentPath = location.pathname;
  const modalMarkerIndex = currentPath.search(modalMarker);
  const modifiedCurrentPath = modalMarkerIndex >= 0
    ? currentPath.substring(modalMarkerIndex, currentPath.length)
    : currentPath;

  return {
    ...location,
    pathname: modifiedCurrentPath,
  };
};

export const removeModalPartFromPath = (path) => {
  const modalMarkerIndex = path.search(modalMarker);
  return modalMarkerIndex >= 0 ? path.substring(0, modalMarkerIndex) : path;
};

export const removeModalPartFromLocation = (location) => {
  const currentPath = location.pathname;
  const modalMarkerIndex = currentPath.search(modalMarker);
  const modifiedCurrentPath = modalMarkerIndex >= 0 ? currentPath.substring(0, modalMarkerIndex) : currentPath;

  return {
    ...location,
    pathname: modifiedCurrentPath,
  };
};

export const makeModalFullPath = (location, path) => {
  // TODO: Generalize more, by double checking on the path's
  //      format (removing extra '/' or adding missing ones)
  const cleanedLocation = removeModalPartFromLocation(location, path);
  const { pathname } = cleanedLocation;

  const res = `${pathname}${modalMarker}${path}${location.search}`;
  return res;
};

export const navigateToModal = (history, path) => {
  // TODO: Generalize more, by double checking on the path's
  //      format (removing extra '/' or adding missing ones)
  const fullPath = makeModalFullPath(history.location, path);
  history.push(fullPath);
};

export const closeCurrentModal = (history) => {
  const isModal = isLocationModal(history.location);
  if (!isModal) {
    return;
  }

  const cleanedLocation = removeModalPartFromLocation(history.location);
  if (history.location !== cleanedLocation.pathname) {
    history.push(`${cleanedLocation.pathname}${history.location.search}`);
  }
};

export const makeModalPath = path => `${modalMarker}${path}`;

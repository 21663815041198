import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import InputSelect from '~/modules/coreUI/components/basic/select/InputSelect';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import getCityStatus from '~/statusRequired';

class CitiesDropdown extends React.Component {
  getSortedCities = () => (this.props.queryResult && this.props.queryResult.countries.length > 0
    ? this.props.queryResult.countries[0].states
      .slice()
      .sort((c1, c2) => c1.name && c1.name.localeCompare(c2.name))
    : []);

  getCitiesOptions = () => this.getSortedCities().map(city => ({
    label: city.name,
    value: city.ref_id,
  }));

  render = () => {
    const {
      onChange, value, tabIndex, placeholder,
    } = this.props;
    getCityStatus(this.props.queryResult && this.props.queryResult.countries[0].states_required);
    const CitiesOptions = this.getCitiesOptions();
    return (
      <InputSelect
        showInput={false}
        value={value.state_id}
        tabIndex={tabIndex}
        isLoading={this.props.isLoading}
        onChange={newValue => onChange({
          country_id: value.country_id,
          state_id: newValue,
        })}
        showImageOnButton={false}
        getSelectedItemLabel={entry => `${entry.label}`}
        getSelectedItemImage={() => null}
        placeholder={placeholder}
        options={CitiesOptions}
      />
    );
  };
}

CitiesDropdown.defaultProps = {
  value: '',
  isLoading: false,
  tabIndex: null,
  queryResult: {
    countries: {
      states: [],
    },
  },
};

CitiesDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  queryResult: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        iso3: PropTypes.string.isRequired,
        states_required: PropTypes.bool.isRequired,
        states: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            ref_id: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
  }),
};

export default withRootQuery(
  CitiesDropdown,
  graphql`
    query CitiesDropdownQuery($CountryID: ID) {
      countries(id: $CountryID) {
        iso3
        states_required
        states {
          name
          ref_id
        }
      }
    }
  `,
  CitiesDropdown,
  props => ({
    CountryID: props.value && props.value.country_id,
  }),
);

const ToolTipTrans = {
  En: {
    facebook: 'Facebook',
    twitter: 'Twitter',
    youtube: 'You Tube',
    instagram: 'Instagram',
    googlePlus: 'Google Plus',
    moreImage: 'More',
  },
  Ar: {
    facebook: 'فيسبوك',
    twitter: 'تويتر',
    youtube: 'يوتيوب',
    instagram: 'انستجرام',
    googlePlus: 'جوجل+',
    moreImage: 'المزيد',
  },
};
export default ToolTipTrans;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import TabPanelItem from './TabPanelItem';
import HotDeals from './HotDeals';
import Offers from './Offers';
import Discounts from './Discounts';

const DailyDealsWrapper = styled.div`
  background-color: #f9f9f9;
  text-align: center;
`;

class Tabs extends Component {
  state = {
    activeTabKey: null,
  }

  changeTab = (activeTab) => {
    this.setState({
      activeTabKey: activeTab,
    });
  }

  componentWillMount = () => {
    if (this.props.hotDeals.length > 3) {
      this.setState({ activeTabKey: 'hotDeal' });
    } else if (this.props.offers.length > 3) {
      this.setState({ activeTabKey: 'offer' });
    } else if (this.props.discounts.length > 3) {
      this.setState({ activeTabKey: 'discount' });
    }
  }

  render = () => {
    const { activeTabKey } = this.state;
    const { offers, hotDeals, discounts } = this.props;
    return (
      <DailyDealsWrapper>
        <Row centerJustified centerAligned spaceBetween={2}>
          {hotDeals.length > 3 && (
            <TabPanelItem
              text={i18next.t('ecommerceCoreUI:DailyDeals.hotDeals')}
              icon="hot-deals"
              onClick={() => this.changeTab('hotDeal')}
              className={activeTabKey === 'hotDeal' ? 'active' : null}
            />
          )}
          {offers.length > 3 && (
            <TabPanelItem
              text={i18next.t('ecommerceCoreUI:DailyDeals.offers')}
              icon="offers"
              onClick={() => this.changeTab('offer')}
              className={activeTabKey === 'offer' ? 'active' : null}
            />
          )}
          {discounts.length > 3 && (
            <TabPanelItem
              text={i18next.t('ecommerceCoreUI:DailyDeals.discounts')}
              icon="discount"
              onClick={() => this.changeTab('discount')}
              className={activeTabKey === 'discount' ? 'active' : null}
            />
          )}
        </Row>
        {activeTabKey === 'hotDeal' && <HotDeals hotDeals={hotDeals} />}
        {activeTabKey === 'offer' && <Offers offers={offers} />}
        {activeTabKey === 'discount' && <Discounts discounts={discounts} />}
      </DailyDealsWrapper>
    );
  }
}

Tabs.propTypes = {
  hotDeals: PropTypes.arrayOf(PropTypes.any),
  offers: PropTypes.arrayOf(PropTypes.any),
  discounts: PropTypes.arrayOf(PropTypes.any),
};

Tabs.defaultProps = {
  hotDeals: [],
  offers: [],
  discounts: [],
};

export default Tabs;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import { getTokenFromLocalStorage } from '~/modules/core/utils/orderManagementHelpers/shoppingCartLocalStorageUtils';

const Mutation = graphql`
  mutation CartAssignUserMutation($guest_tokens: [String!]!) {
    order_assign_user(guest_tokens: $guest_tokens) {
      orders {
        ...WithOrderData_orders
      }
      cart {
        ...WithCartData_cart @relay(mask: false)
      }
      errors {
        code
        field
        messages
      }
    }
  }`;

const MutationRoot = 'order_assign_user';

class CartAssignUserMutation extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { authenticatedPrev } = prevState;
    const {
      setCartManagementPartial, authenticatedCartInfoReady, authenticated,
    } = nextProps;

    if (authenticated && !authenticatedPrev) {
      commitMutation(
        nextProps.environment,
        Mutation,
        MutationRoot,
        {
          guest_tokens: getTokenFromLocalStorage(),
        },
        (response) => {
          const newCart = response.order_assign_user.cart;
          const newGustTokens = newCart.orders.map(order => order.guest_token);
          setCartManagementPartial({
            shoppingCart: newCart,
            authenticatedCartInfoReady: true,
            shoppingCartGuestTokens: newGustTokens,
          });
        },
      );
    } else if (!authenticated && authenticatedCartInfoReady) {
      setCartManagementPartial({
        authenticatedCartInfoReady: false,
      });
    }

    return {
      authenticatedPrev: authenticated,
    };
  }

  state = {
    authenticatedPrev: this.props.authenticated, // eslint-disable-line
  };

  render() {
    return <React.Fragment />;
  }
}

CartAssignUserMutation.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  environment: PropTypes.shape({}).isRequired,
};

export default withRouter(
  withCartInfo(
    withUserInfo(
      withRelayEnvironment(
        CartAssignUserMutation,
      ),
    ),
  ),
);

/**
 * @flow
 * @relayHash 342f43bcd043efc54b67c51d267fcb2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Product_productInfo$ref = any;
export type SectionsListQueryVariables = {||};
export type SectionsListQueryResponse = {|
  +sections: $ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
    +sub_title: ?string,
    +target: ?string,
    +products: ?$ReadOnlyArray<?{|
      +$fragmentRefs: Product_productInfo$ref
    |}>,
    +shops: ?$ReadOnlyArray<?{|
      +id: string,
      +logo_url: ?string,
      +ref_id: string,
      +name: ?string,
      +rating_count: ?number,
      +avg_rating: ?number,
      +follow: ?boolean,
      +followed: ?boolean,
      +track: ?string,
      +city: ?{|
        +name: ?string
      |},
      +country: ?{|
        +name: ?string
      |},
      +featured: ?boolean,
      +departments: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
      |}>,
      +track_sub_departments: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
        +track_department_id: string,
        +ref_id: string,
      |}>,
    |}>,
  |}>
|};
export type SectionsListQuery = {|
  variables: SectionsListQueryVariables,
  response: SectionsListQueryResponse,
|};
*/


/*
query SectionsListQuery {
  sections {
    id
    title
    sub_title
    target
    products {
      ...Product_productInfo
      id
    }
    shops {
      id
      logo_url
      ref_id
      name
      rating_count
      avg_rating
      follow
      followed
      track
      city {
        name
        id
      }
      country {
        name
        id
      }
      featured
      departments {
        id
        name
      }
      track_sub_departments {
        id
        name
        track_department_id
        ref_id
      }
    }
  }
}

fragment Product_productInfo on Product {
  id
  slug
  vendor_variants_count
  min_variant {
    vendor {
      name
      ref_id
      id
    }
    slug
    name
    ref_id
    root_variant_id
    discount_expire_at
    remaining_usage_percentage
    total_on_hand
    price
    original_price
    id
  }
  ref_id
  images
  name
  avg_rating
  rating_count
  price
  original_price
  discount
  has_discount
  has_offer
  hot_deal
  wish_listed
  root_variant {
    root_variant_id
    ref_id
    wish_listed
    id
  }
  variants {
    id
    ref_id
    option_values {
      id
      ref_id
      option_type_name
    }
  }
  related_products {
    images
    name
    id
  }
  gift_image {
    url_large
    url_original
  }
  gift_text
  gift_included
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sub_title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "target",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "followed",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "follow",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logo_url",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "track",
  "args": null,
  "storageKey": null
},
v12 = [
  v6
],
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "featured",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "departments",
  "storageKey": null,
  "args": null,
  "concreteType": "TrackDepartment",
  "plural": true,
  "selections": [
    v0,
    v6
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "track_sub_departments",
  "storageKey": null,
  "args": null,
  "concreteType": "TrackSubDepartment",
  "plural": true,
  "selections": [
    v0,
    v6,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "track_department_id",
      "args": null,
      "storageKey": null
    },
    v5
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "root_variant_id",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original_price",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "images",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
},
v22 = [
  v6,
  v0
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SectionsListQuery",
  "id": null,
  "text": "query SectionsListQuery {\n  sections {\n    id\n    title\n    sub_title\n    target\n    products {\n      ...Product_productInfo\n      id\n    }\n    shops {\n      id\n      logo_url\n      ref_id\n      name\n      rating_count\n      avg_rating\n      follow\n      followed\n      track\n      city {\n        name\n        id\n      }\n      country {\n        name\n        id\n      }\n      featured\n      departments {\n        id\n        name\n      }\n      track_sub_departments {\n        id\n        name\n        track_department_id\n        ref_id\n      }\n    }\n  }\n}\n\nfragment Product_productInfo on Product {\n  id\n  slug\n  vendor_variants_count\n  min_variant {\n    vendor {\n      name\n      ref_id\n      id\n    }\n    slug\n    name\n    ref_id\n    root_variant_id\n    discount_expire_at\n    remaining_usage_percentage\n    total_on_hand\n    price\n    original_price\n    id\n  }\n  ref_id\n  images\n  name\n  avg_rating\n  rating_count\n  price\n  original_price\n  discount\n  has_discount\n  has_offer\n  hot_deal\n  wish_listed\n  root_variant {\n    root_variant_id\n    ref_id\n    wish_listed\n    id\n  }\n  variants {\n    id\n    ref_id\n    option_values {\n      id\n      ref_id\n      option_type_name\n    }\n  }\n  related_products {\n    images\n    name\n    id\n  }\n  gift_image {\n    url_large\n    url_original\n  }\n  gift_text\n  gift_included\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SectionsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sections",
        "storageKey": null,
        "args": null,
        "concreteType": "section",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "products",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "Product_productInfo",
                "args": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shops",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": [
              v4,
              v0,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "city",
                "storageKey": null,
                "args": null,
                "concreteType": "State",
                "plural": false,
                "selections": v12
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "country",
                "storageKey": null,
                "args": null,
                "concreteType": "Country",
                "plural": false,
                "selections": v12
              },
              v13,
              v14,
              v15
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SectionsListQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "sections",
        "storageKey": null,
        "args": null,
        "concreteType": "section",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "products",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "discount",
                "args": null,
                "storageKey": null
              },
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "vendor_variants_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "min_variant",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "discount_expire_at",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "vendor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Shop",
                    "plural": false,
                    "selections": [
                      v6,
                      v5,
                      v0
                    ]
                  },
                  v6,
                  v5,
                  v16,
                  v17,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "remaining_usage_percentage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "total_on_hand",
                    "args": null,
                    "storageKey": null
                  },
                  v18,
                  v19,
                  v0
                ]
              },
              v5,
              v20,
              v6,
              v8,
              v7,
              v18,
              v19,
              v17,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "has_discount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "has_offer",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hot_deal",
                "args": null,
                "storageKey": null
              },
              v21,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "root_variant",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": false,
                "selections": [
                  v16,
                  v5,
                  v21,
                  v0
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "variants",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": true,
                "selections": [
                  v0,
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "option_values",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OptionValue",
                    "plural": true,
                    "selections": [
                      v0,
                      v5,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "option_type_name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "related_products",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": true,
                "selections": [
                  v20,
                  v6,
                  v0
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "gift_image",
                "storageKey": null,
                "args": null,
                "concreteType": "ImageAttachment",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url_large",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url_original",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "gift_text",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "gift_included",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shops",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": [
              v4,
              v0,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "city",
                "storageKey": null,
                "args": null,
                "concreteType": "State",
                "plural": false,
                "selections": v22
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "country",
                "storageKey": null,
                "args": null,
                "concreteType": "Country",
                "plural": false,
                "selections": v22
              },
              v13,
              v14,
              v15
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e0d6d144f3c3d50b04a900bbe96e0d83';
module.exports = node;

const SignUpTrans = {
  En: {
    Register: 'Sign Up',
    ArrowBack: '<',
    Back: 'Back',
    Gender: 'I\'m',
    AfterRegisteringyouwillrecieveanemail:
      ' You can unsubscribe anytime from your account settings.',
    RegisterFornewsLetter: 'Join Our Newsletter',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Nationality: 'Nationality',
    Email: 'Email',
    Phone: 'Mobile',
    Mobile: 'Mobile Number',
    AlreadyHaveanaccount: 'Already have an account?',
    JoinOurCommunity: 'Join our community',
    haveAccount: 'Don\'t have an account?',
  },
  Ar: {
    Register: 'الاشتراك',
    ArrowBack: '>',
    Back: 'العودة',
    Gender: 'أنا',
    AfterRegisteringyouwillrecieveanemail: ' يمكنك إلغاء الاشتراك في أي وقت من إعدادات حسابك.',
    RegisterFornewsLetter: 'انضم إلى قائمتنا البريدية',
    FirstName: 'الاسم الأول',
    LastName: 'الاسم الأخير',
    Nationality: 'الجنسية',
    Email: 'البريد الإلكتروني',
    Phone: 'الهاتف الجوال',
    Mobile: 'رقم الجوال',
    AlreadyHaveanaccount: 'هل لديك حساب؟',
    JoinOurCommunity: 'انضم إلى مجتمعنا',
    haveAccount: 'ليس لديك حساب؟',
  },
};
export default SignUpTrans;

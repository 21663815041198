import React from 'react';
import { PropTypes } from 'prop-types';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { BasicButton } from '~/modules/ecommerceCoreUI/components/basic/BasicButton';
import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';

import Heading from './Heading';
import { InnerPageDropdownElement } from './DropdownContainer';
import {
  SideMenuContainer, Item, Logout, SocialMediaContent, logout,
} from './SideMenu';

const InnerSideMenu = props => (
  <SideMenuContainer>
    <Heading />
    <Item>
      {' '}
      <ExternalLink url="/">
        <span>{i18next.t('ecommerceCoreUI:homeTitle')}</span>
      </ExternalLink>
      {' '}
    </Item>
    <InnerPageDropdownElement history={props.history} />
    {props.authenticated ? (
      <Logout>
        <LargeSpacer />
        <BasicButton primary onClick={e => logout(e, props.invalidateUserAndLogout)}>
          {i18next.t('ecommerceCoreUI:logout')}
        </BasicButton>
        <LargeSpacer />
      </Logout>
    ) : (
      ' '
    )}
    <LargeSpacer />
    <SocialMediaContent isCentered />
  </SideMenuContainer>
);

InnerSideMenu.propTypes = {
  invalidateUserAndLogout: PropTypes.func,
  authenticated: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({}).isRequired,
  }).isRequired,
};

InnerSideMenu.defaultProps = {
  invalidateUserAndLogout: ' ',
  authenticated: ' ',
};

export default withRouter(withUserInfo(InnerSideMenu));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

import {
  graphql,
} from 'react-relay';

import { LargeSpacer, XXLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import BannerImage from '~/modules/ecommerceCoreUI/components/basic/BannerImage';
import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';
import { scrollToContainerTopById } from '~/modules/core/utils/jsHelpers/ScrollToTop';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';

import parameterize from 'parameterize';

import { SubDepartmentName } from '../../components/basic/Labels';
import CategoriesCards from './CategoriesCards';
import BrandsCard from './BrandsCards';

class TrackContent extends Component {
// ({ taxon, vendorID, vendorName }) => (z

  componentDidMount() {
    this.jumpToHash();
  }

  // eslint-disable-next-line
  jumpToHash() {
    const { hash } = window.location;
    if (hash !== '') {
      const id = hash.replace('#', '');
      scrollToContainerTopById(id);
    }
  }

  render() {
    const { taxon, vendorID, vendorName } = this.props;

    return taxon && (
      <LinearLayout fullWidth leftAligned>
        {taxon.logo && <BannerImage imgSrc={taxon.logo} imgName={taxon.name} />}
        { taxon.children
          && taxon.children.map(taxonInfo => (
            <React.Fragment key={taxonInfo.id}>
              <XXLargeSpacer />
              <SubDepartmentName id={parameterize(taxonInfo.name)}>
                {taxonInfo.name}
              </SubDepartmentName>
              <LargeSpacer />
              <CategoriesCards
                categoriesInfo={taxonInfo}
                vendorID={vendorID}
                vendorName={vendorName}
              />
              {taxon.children.length > 1 && (
                <Separator separatorLength="full" />
              )}
            </React.Fragment>
          ))
        }

        <XXLargeSpacer />

        { taxon.top_brands
          && taxon.top_brands.length > 0
          && (
            <React.Fragment>
              <SubDepartmentName id={parameterize(i18next.t('ecommerceProducts:ProductsTracks.brandsTitle'))}>
                <Trans i18nKey="ProductsTracks.brandsTitle" />
              </SubDepartmentName>
              <LargeSpacer />
              <BrandsCard
                brandsInfo={taxon.top_brands}
                departmentId={taxon.ref_id}
                departmentName={taxon.name}
                vendorID={vendorID}
                vendorName={vendorName}
              />
            </React.Fragment>
          )
        }
      </LinearLayout>
    );
  }
}

TrackContent.defaultProps = {
  vendorID: null,
  vendorName: null,
};

TrackContent.propTypes = {
  taxon: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logo: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    top_brands: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  vendorID: PropTypes.string,
  vendorName: PropTypes.string,
};

export default withFragmentContainer(
  TrackContent,
  graphql`
    fragment TrackContent_taxon on Taxon @argumentDefinitions(vendor_id: { type: "ID" }) {
      ref_id
      name
      logo
      children(vendor_id: $vendorID) {
        name
        id
        ...CategoriesCards_categoriesInfo @arguments(vendor_id: $vendorID)
      }
      top_brands(vendor_id: $vendorID) {
        ...BrandsCards_brandsInfo
      }
    }
  `,
);

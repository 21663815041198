import React from 'react';
import { css } from 'emotion';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';

const separatorStyles = css`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const ViewAllLink = ({ tabName }) => (
  <ExternalLink url={urlFor('getNotificationAndMessagesLink', [tabName])}>
    {tabName === 'notifications' && (
      <div className={separatorStyles}><Separator separatorLength="full" spacerSize="0" /></div>
    )}
    <Row centerJustified fullWidth customStyles={() => css`height: 40px;`}>
      <Label secondary sm>{i18next.t('ecommerceOrder:notificationsAndMessages.viewAllLabel')}</Label>
    </Row>
  </ExternalLink>
);

ViewAllLink.propTypes = {
  tabName: PropTypes.string.isRequired,
};

export default ViewAllLink;

/* eslint-disable indent */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'react-emotion';
import Dropdown from 'react-dropdown';
import { translate } from 'react-i18next';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const DropdownContainer = withDirection(styled.div`
  .Dropdown-control {
    display: flex;
    background: ${props => props.theme.colors.named.white};
    border: 0px;
    cursor: pointer;
  }

  .Dropdown-menu {
    display: table;
    max-height: fit-content;
    overflow-y: unset;
    margin-top: ${props => props.theme.paddings.medium}px;
    border-color: ${props => props.theme.borders.color.normal};
    ${props => (props.direction === 'rtl'
      ? css`
          text-align: right;
        `
      : css`
          text-align: left;
        `
    )}
  }

  .Dropdown-menu:after{
    display: block;
    width: ${props => 4 * props.theme.paddings.xSmall}px;
    height: ${props => 4 * props.theme.paddings.xSmall}px;
    visibility: visible;
    position: absolute;
    z-index: 2;
    transform: rotate(45deg);
    ${props => (props.direction === 'rtl'
        ? css`
            right: ${props.theme.paddings.large}px;
            left: auto;
          `
        : css`
            left: ${props.theme.paddings.large}px;
            right: auto;
          `)}
    top: ${props => -2 * props.theme.paddings.xSmall}px;
    margin: 0;
    background: ${props => props.theme.colors.named.white};
    box-shadow: -1px -1px 0 0 ${props => props.theme.borders.color.normal};
    pointer-events: none;
    content: '';
  }
  .Dropdown-arrow {
    ${props => (props.direction === 'rtl'
      ? css`
          left: ${props.theme.paddings.medium}px;
          right: auto;
        `
      : css`
          right: ${props.theme.paddings.medium}px;
        `
    )}
  }
  .Dropdown-arrow, .is-open .Dropdown-arrow {
    text-align: center;
    border-color: transparent;
    border-style: solid;
    border-width: 0px;
    display: inline-block;
    width: ${props => props.theme.paddings.xLarge}px;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    top: ${props => props.theme.paddings.medium}px;
  }

  .Dropdown-arrow:before {
    font-size: ${props => props.theme.fonts.sizes.large}px;
    content: "\\f107";
  }

  .is-open .Dropdown-arrow:before {
    font-size: ${props => props.theme.fonts.sizes.large}px;
    content: "\\f106";
  }
  .Dropdown-option.is-selected {
    position:relative;
    z-index:3;
    background-color: rgba(0, 0, 0, 0.03);
  }

  .Dropdown-option {
    cursor: pointer;
    &:hover {
      position:relative;
      z-index:3;
      background-color: ${props => props.theme.borders.color.light};
    }
  }
  ${props => props.customStyles && props.customStyles(props)};
`);

const DropdownCustom = ({
  options,
  currentValue,
  placeholder,
  onChange,
  arrowClosed,
  arrowOpen,
  customStyles,
}) => (
  <DropdownContainer customStyles={customStyles}>
    <Dropdown
      options={options}
      onChange={onChange}
      value={currentValue}
      placeholder={placeholder}
      arrowClosed={arrowClosed}
      arrowOpen={arrowOpen}
    />
  </DropdownContainer>
);

DropdownCustom.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  arrowClosed: PropTypes.shape({}),
  arrowOpen: PropTypes.shape({}),
  customStyles: PropTypes.func,
}.isRequired;

export default translate('coreUI')(DropdownCustom);

/**
 * @flow
 * @relayHash 07150a29c51866356655aa7abadff689
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TwitterCallbackQueryVariables = {|
  oauthConfirmUrl: string
|};
export type TwitterCallbackQueryResponse = {|
  +twitter_callback: ?{|
    +authorized_url: ?string,
    +secret: ?string,
    +token: ?string,
    +errors: ?$ReadOnlyArray<?{|
      +code: ?number,
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
    |}>,
  |}
|};
export type TwitterCallbackQuery = {|
  variables: TwitterCallbackQueryVariables,
  response: TwitterCallbackQueryResponse,
|};
*/


/*
query TwitterCallbackQuery(
  $oauthConfirmUrl: String!
) {
  twitter_callback(oauth_confirm_url: $oauthConfirmUrl) {
    authorized_url
    secret
    token
    errors {
      code
      field
      messages
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "oauthConfirmUrl",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "twitter_callback",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "oauth_confirm_url",
        "variableName": "oauthConfirmUrl",
        "type": "String!"
      }
    ],
    "concreteType": "TwitterCallbackResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "authorized_url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "secret",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TwitterCallbackQuery",
  "id": null,
  "text": "query TwitterCallbackQuery(\n  $oauthConfirmUrl: String!\n) {\n  twitter_callback(oauth_confirm_url: $oauthConfirmUrl) {\n    authorized_url\n    secret\n    token\n    errors {\n      code\n      field\n      messages\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TwitterCallbackQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "TwitterCallbackQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9841098e5bf301b1f33bae3de94ceeb4';
module.exports = node;

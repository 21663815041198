import t, { maybe } from 'tcomb-form';
import i18next from 'i18next';
import _ from 'lodash';

import FormLayout from './FormLayout';
import Templates from './Template';

t.String.getValidationErrorMessage = () => 'value is invalid';

export const defineSubtype = (type, predicate, getValidationErrorMessage, name) => {
  const Subtype = t.refinement(type, predicate, name);
  Subtype.getValidationErrorMessage = getValidationErrorMessage;
  return Subtype;
};

const specialCharactersFormat = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;

const digitsFormat = /\d+/g;

const removeCountryCodeFromPhoneNumber = phoneNumber => phoneNumber && phoneNumber.replace(/\(\+?\d+\)/, '').trim();

const removeInitialZerosFromNumber = phoneNumber => phoneNumber && phoneNumber.replace(/^0+/g, '');

const isValidPhoneNumber = (val) => {
  let cleanedVal = removeCountryCodeFromPhoneNumber(val);
  cleanedVal = removeInitialZerosFromNumber(cleanedVal);
  if (val?.split(')')[0].split('+')[1] === '974') {
    return parseInt(cleanedVal, 10).toString() === cleanedVal && cleanedVal.length === 8 && !cleanedVal.match(specialCharactersFormat);
  }
  return parseInt(cleanedVal, 10).toString() === cleanedVal && cleanedVal.length <= 12 && cleanedVal.length >= 6 && !cleanedVal.match(specialCharactersFormat);
};

const isValidOptionalPhoneNumber = (val) => {
  let cleanedVal = removeCountryCodeFromPhoneNumber(val);
  cleanedVal = removeInitialZerosFromNumber(cleanedVal);
  if (cleanedVal.length === 0) return true;
  if (val?.split(')')[0].split('+')[1] === '974') {
    return parseInt(cleanedVal, 10).toString() === cleanedVal && cleanedVal.length === 8 && !cleanedVal.match(specialCharactersFormat);
  }
  return parseInt(cleanedVal, 10).toString() === cleanedVal && cleanedVal.length <= 12 && cleanedVal.length >= 6 && !cleanedVal.match(specialCharactersFormat);
};

const validatePhoneNumber = (val, isRequired) => {
  let phoneNumber = removeCountryCodeFromPhoneNumber(val);
  phoneNumber = removeInitialZerosFromNumber(phoneNumber);

  if (isRequired && (!phoneNumber || phoneNumber.trim().length === 0)) {
    return i18next.t('coreUI:inputErrors.missing');
  }
  if (val.split(')')[0].split('+')[1] === '974' && phoneNumber.length !== 8) {
    return i18next.t('coreUI:inputErrors.containNumber');
  } if (val.split(')')[0].split('+')[1] !== '974' && (phoneNumber.length < 6 || phoneNumber.length > 12)) {
    return i18next.t('coreUI:inputErrors.containNumberRange');
  }
  if (!isValidPhoneNumber(phoneNumber)) {
    return i18next.t('coreUI:inputErrors.notNumber');
  }
  return null;
};

const RequiredString = defineSubtype(
  maybe(t.String),
  val => val && val.trim().length > 0,
  (val) => {
    if (!val || val.trim().length === 0) {
      return i18next.t('coreUI:inputErrors.missing');
    }
    if (val.trim().length < 3 || val.trim().length > 255) {
      return i18next.t('coreUI:inputErrors.countryCode');
    }
    return null;
  },
  'RequiredString',
);

const Password = defineSubtype(
  maybe(t.String),
  val => val && val.trim().length >= 6 && val.trim().length <= 20,
  (val) => {
    if (!val || val.trim().length === 0) {
      return i18next.t('coreUI:inputErrors.missing');
    }
    if (val.trim().length < 6) {
      return i18next.t('coreUI:inputErrors.shortPassword');
    }
    if (val.trim().length > 20) {
      return i18next.t('coreUI:inputErrors.longPassword');
    }
    return null;
  },
  'Password',
);

const RequiredStringWithMinimum = defineSubtype(
  maybe(t.String),
  val => val && val.trim().length > 2,
  (val) => {
    if (!val || val.trim().length === 0) {
      return i18next.t('coreUI:inputErrors.missing');
    }
    if (val.trim().length < 3 || val.trim().length > 255) {
      return i18next.t('coreUI:inputErrors.countryCode');
    }
    return null;
  },
  'RequiredStringWithMinimum',
);

const RequiredStringWithoutNumber = defineSubtype(
  maybe(t.String),
  val => val && val.trim().length > 2 && !val.match(digitsFormat) && !val.match(specialCharactersFormat),
  (val) => {
    if (!val || val.trim().length === 0) {
      return i18next.t('coreUI:inputErrors.missing');
    }
    if (val.trim().length < 3 || val.trim().length > 255) {
      return i18next.t('coreUI:inputErrors.countryCode');
    }
    if (val.match(digitsFormat) || val.match(specialCharactersFormat)) {
      return i18next.t('coreUI:inputErrors.stringOnly');
    }
    return null;
  },
  'RequiredStringWithoutNumber',
);

const RequiredNumber = defineSubtype(
  maybe(t.String),
  val => isValidPhoneNumber(val),
  val => validatePhoneNumber(val, true),
  'RequiredNumber',
);

const RequiredEnum = defineSubtype(
  maybe(t.String),
  val => val && val.trim().length > 0,
  (val) => {
    if (!val || val.trim().length === 0) {
      return i18next.t('coreUI:inputErrors.missing');
    }
    return null;
  },
  'RequiredEnum',
);

const Email = defineSubtype(
  RequiredString,
  (val) => {
    // eslint-disable-next-line
    const emailMatch =
      val
      && val.search(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    return emailMatch >= 0;
  },
  () => i18next.t('coreUI:inputErrors.notValid'),
  'Email',
);

const State = t.struct({
  state_id: maybe(t.String),
  country_id: maybe(t.String),
});

const Country = t.struct({
  country_code: maybe(t.String),
  country_id: maybe(t.String),
});

State.getTcombFormFactory = (/* options */) => t.form.Textbox;

Country.getTcombFormFactory = (/* options */) => t.form.Textbox;

const RequiredState = defineSubtype(
  maybe(State),
  val => val && val.state_id && val.state_id.trim().length > 0,
  (val) => {
    if (!(val && val.state_id && val.state_id.trim().length > 0)) {
      return i18next.t('coreUI:inputErrors.stateMissing');
    }
    return null;
  },
  'RequiredState',
);

const RequiredCountry = defineSubtype(
  maybe(Country),
  val => val && val.country_id && val.country_id.trim().length > 0,
  (val) => {
    if (!(val && val.country_id && val.country_id.trim().length > 0)) {
      return i18next.t('coreUI:inputErrors.countryMissing');
    }
    return null;
  },
  'RequiredCountry',
);

const OptionalState = maybe(State);
const OptionalCountry = maybe(Country);

const OptionalString = t.maybe(t.String);

const RequiredAlphanumeric = defineSubtype(
  RequiredString,
  (val) => {
    const alphanumeric = '^[\u0621-\u064A\u0660-\u0669a-zA-Z0-9 ]+$';
    const match = val?.match(alphanumeric);
    return !!match;
  },
  () => i18next.t('coreUI:inputErrors.notValid'),
  'RequiredAlphanumeric',
);

const OptionalNumber = defineSubtype(
  maybe(t.String),
  val => isValidOptionalPhoneNumber(val),
  val => validatePhoneNumber(val),
  'OptionalNumber',
);

const Types = {
  Password,
  OptionalString,
  RequiredString,
  RequiredAlphanumeric,
  RequiredStringWithMinimum,
  RequiredStringWithoutNumber,
  RequiredNumber,
  OptionalNumber,
  RequiredEnum,
  Email,
  RequiredState,
  OptionalState,
  RequiredCountry,
  Boolean: t.Boolean,
  OptionalCountry,
};

export const getTcombOptionsFromRawOptions = (rawOptions) => {
  const tcombOptions = {
    template: rawOptions.customLayout || FormLayout,
    auto: 'placeholders',
    fields: {},
  };

  rawOptions.fields.forEach((option) => {
    const factoryOrTemplate = option.factory ? ({
      factory: option.factory,
    }) : ({
      template: option.template || Templates[option.input_type],
    });

    tcombOptions.fields[option.name] = {
      ...factoryOrTemplate,
      attrs: {
        ...option,
      },
      label: option.label,
      error: null,
      hasError: false,
    };
  });

  return tcombOptions;
};

export const getTcombTypesFromRawOptions = (rawOptions) => {
  const tcombTypesObject = {};

  rawOptions.fields.forEach((option) => {
    tcombTypesObject[option.name] = _.isString(option.type)
      ? Types[option.type]
      : option.type;
  });

  return t.struct(tcombTypesObject);
};

/**
 * @flow
 * @relayHash b1740aa07125ab15e03df7cc08e31e83
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WheelItemsQueryVariables = {||};
export type WheelItemsQueryResponse = {|
  +lucky_wheel_components: ?$ReadOnlyArray<?{|
    +description: ?string,
    +display_name: ?string,
    +number: ?number,
  |}>
|};
export type WheelItemsQuery = {|
  variables: WheelItemsQueryVariables,
  response: WheelItemsQueryResponse,
|};
*/


/*
query WheelItemsQuery {
  lucky_wheel_components {
    description
    display_name
    number
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "lucky_wheel_components",
    "storageKey": null,
    "args": null,
    "concreteType": "LuckyWheelItem",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "display_name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "number",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "WheelItemsQuery",
  "id": null,
  "text": "query WheelItemsQuery {\n  lucky_wheel_components {\n    description\n    display_name\n    number\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WheelItemsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "WheelItemsQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f367977120dc9c2bdb6f9eb1fe66c76a';
module.exports = node;

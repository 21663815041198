import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartInfoContext from './CartInfoContext';
import { insertTokensIntoLocalStorage, getTokenFromLocalStorage } from './shoppingCartLocalStorageUtils';

class CartInfoProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartManagement: {
        shoppingCart: null,
        shoppingCartGuestTokens: getTokenFromLocalStorage(),
        setShoppingCart: this.setShoppingCart,
        setCartManagementPartial: this.setCartManagementPartial,
      },
    };
  }

  setShoppingCart = shoppingCart => this.setState(prevState => ({
    cartManagement: {
      ...prevState.cartManagement,
      shoppingCart,
    },
  }));

  setCartManagementPartial = (updates) => {
    if (updates.shoppingCartGuestTokens) {
      insertTokensIntoLocalStorage(updates.shoppingCartGuestTokens);
    }

    this.persist('guest_tokens');

    this.setState(prevState => ({
      cartManagement: {
        ...prevState.cartManagement,
        ...updates,
      },
    }));
  };

  persist = (dataKey) => {
    // Workaround to force the browser to persist !!!
    // https://stackoverflow.com/questions/13292744/why-isnt-localstorage-persisting-in-chrome
    localStorage.getItem(dataKey);
    sessionStorage.getItem(dataKey);
  };

  render = () => <CartInfoContext.Provider value={this.state.cartManagement}>{this.props.children}</CartInfoContext.Provider>;
}

CartInfoProvider.propTypes = PropTypes.shape({
  children: PropTypes.shape({}),
}).isRequired;

export default CartInfoProvider;

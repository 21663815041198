import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import ShopPointsCard from './ShopPointsCard';

const ShopPointsWrapper = styled.div`
  grid-gap: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  ${cssMediaMax.xsmall`
    grid-template-columns: repeat(auto-fill, minmax(98%, 1fr));
  `}
`;
const ShopsPoints = ({ queryResult }) => (
  <ShopPointsWrapper>
    {queryResult.vendor_loyalty_programs.map(shop => (
      <ShopPointsCard queryResult={shop} key={shop.id} />
    ))}
  </ShopPointsWrapper>
);

ShopsPoints.propTypes = {
  queryResult: PropTypes.shape({}),
};

ShopsPoints.defaultProps = {
  queryResult: [],
};

export default ShopsPoints;

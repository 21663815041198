/* eslint-disable react/prop-types, camelcase */
import React, { Fragment } from 'react';
import styled from 'react-emotion';
import i18next from 'i18next';

import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';
import withNotifications from '~/modules/ecommerceOrder/containers/notificationAndMessages/notifications/withNotifications';

const NotificationSign = styled.span`
  width:${props => props.theme.new.spacer}px;
  height: ${props => props.theme.new.spacer}px;
  position: absolute;
  z-index: 1;
  right: 1px;
  background-color: #d32f2f;
  border-radius: 50%;
`;

const NotificationIcon = props => (
  <Fragment>
    {props.notifications?.unseen_count ? <NotificationSign /> : null}
    <IconOnlyButton
      primary
      iconName={props.notifications?.unseen_count ? 'el-icon el-icon-Bell' : 'el-icon el-icon-bell-line'}
      size={28}
      title={i18next.t('ecommerceCoreUI:ToolTipTrans.notification')}
      onClick={props.showNotificationsMenu}
    />
  </Fragment>
);

export default withNotifications(NotificationIcon, `notifications/users/${JSON.parse(localStorage.getItem('userInfo'))?.ref_id}`);

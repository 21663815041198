import React from 'react';

import { Switch, Route } from 'react-router-dom';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';

import InnerSideMenu from './InnerSideMenu';
import SideMenu from './SideMenu';

export const SideMenuContent = () => (
  <Switch>
    <Route exact path="/:locale(ar|en)?/CheckoutSteps/:stepName" component={InnerSideMenu} />
    <Route component={SideMenu} />
  </Switch>
);

export default withUserInfo(SideMenuContent);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { DeleteSmartCartMutation, DeleteSmartCartMutationRoot } from '../smartCartMutations/DeleteSmartCart';
import ModalWrapper from '~/modules/ecommerceOrder/components/smartCard/ModalWrapper';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withModalTracker from '~/modules/core/utils/modalHelpers/withModalTracker';
import { notifyUnexpectedError } from '~/modules/ecommerceOrder/containers/shoppingCart/utils/ErrorsProcessor';

const ActionsContainer = styled(Row)`
  position: absolute;
  bottom: 25px;
  right: 25px;
`;
class DeleteSmartCartPopup extends Component {
  state = {
    isLoading: false,
  }

  onCloseModal = () => {
    this.props.onCloseModal();
  }

  closePopup = () => {
    this.onCloseModal();
    this.props.closeCurrentModal();
  }

  onMutationSuccess = (response) => {
    if (!response.remove_smart_cart.errors) {
      this.onCloseModal();
    }
  };

  onMutationLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  onMutationError = (errors) => {
    this.setState({
      isLoading: false,
    });
    notifyUnexpectedError(this.props, errors);
  };

  deleteSmartCart = (smartCartId) => {
    commitMutation(
      this.props.environment,
      DeleteSmartCartMutation,
      DeleteSmartCartMutationRoot,
      { id: smartCartId },
      this.onMutationSuccess,
      null,
      this.onMutationLoading,
    );
  }

  render() {
    const { isLoading } = this.state;
    const { smartCart } = this.props;
    return (
      <ModalWrapper onCloseModal={this.onCloseModal}>
        <Column spaceBetween={2.5} fullWidth leftAligned>
          <Label important>
            {i18next.t('ecommerceOrder:smartCart.deleteMessage')}
            {' '}
            {smartCart.name}
          </Label>
          <ActionsContainer rightJustified rightAligned spaceBetween={1}>
            <Button width="120px" passive fontSize={18.5} onClicked={this.closePopup}>
              {i18next.t('ecommerceOrder:smartCart.cancelButton')}
            </Button>
            <Button loading={isLoading} width="120px" secondary fontSize={18.5} onClicked={() => this.deleteSmartCart(smartCart.ref_id)}>
              {i18next.t('ecommerceOrder:smartCart.deleteButton')}
            </Button>
          </ActionsContainer>
        </Column>
      </ModalWrapper>
    );
  }
}

DeleteSmartCartPopup.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  environment: PropTypes.shape({}).isRequired,
  smartCart: PropTypes.shape({}).isRequired,
  media: PropTypes.shape({
    maxTablet: PropTypes.bool,
  }).isRequired,
  closeCurrentModal: PropTypes.func.isRequired,
};

export default withModalTracker(withMedia(withRelayEnvironment(DeleteSmartCartPopup)));

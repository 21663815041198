/**
 * @flow
 * @relayHash 3029dcf4a87c30e6f02593ef2038fa54
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InwaniInput = {
  zone?: ?number,
  street?: ?number,
  building?: ?number,
};
export type InwaniQueryVariables = {|
  input?: ?InwaniInput
|};
export type InwaniQueryResponse = {|
  +inwani: ?{|
    +address: ?string,
    +country: ?string,
    +lat: ?string,
    +lng: ?string,
  |}
|};
export type InwaniQuery = {|
  variables: InwaniQueryVariables,
  response: InwaniQueryResponse,
|};
*/


/*
query InwaniQuery(
  $input: InwaniInput
) {
  inwani(input: $input) {
    address
    country
    lat
    lng
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "InwaniInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "inwani",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "InwaniInput"
      }
    ],
    "concreteType": "InwaniResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "country",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lat",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lng",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "InwaniQuery",
  "id": null,
  "text": "query InwaniQuery(\n  $input: InwaniInput\n) {\n  inwani(input: $input) {\n    address\n    country\n    lat\n    lng\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "InwaniQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "InwaniQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c5cac9747bb4f7b3ef9ae86c4af620a';
module.exports = node;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { XXLargeLabel, MediumLabel } from '~/modules/coreUI/components/basic/Labels';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

import AdRecommendedCard from './AdRecommendedCard';

const CardWrapper = styled.div`
  display: block;
`;
const AdRecommended = ({ queryResult, AdTrack }) => (
  <React.Fragment>
    {queryResult && queryResult.advertisements !== 0 &&
      queryResult.advertisements.map(Advertising => (
        <CardWrapper key={Advertising.id}>
          <AdRecommendedCard AdTrack={AdTrack} {...Advertising} />
        </CardWrapper>
    ))}
  </React.Fragment>
);

AdRecommended.propTypes = {
  queryResult: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    button_text: PropTypes.string,
    title_color: PropTypes.string,
    button_color: PropTypes.string,
    button_text_color: PropTypes.string,
    large_photo: PropTypes.shape({
      url_original: PropTypes.string,
    }),
  }),
};

AdRecommended.defaultProps = {
  queryResult: null,
};

export default withRootQuery(
  AdRecommended,
  graphql`
    query AdRecommendedQuery($AdTrack: AdTrack) {
      advertisements( limit: 1, ad_section: recommended, kind: web, track: $AdTrack) {
        ...WithAdvertisingData_Advertising @relay(mask: false)
      }
    }
  `,
  AdRecommended,
  props => ({
    AdTrack: props.AdTrack,
  }),
);

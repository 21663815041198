import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import LinesEllipsis from 'react-lines-ellipsis';
import { graphql } from 'react-relay';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import SquareImageCard from '~/modules/ecommerceCoreUI/components/basic/SquareImageCard';
import BasicLink from '~/modules/ecommerceCoreUI/components/basic/Link';
import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const CardName = styled(Label)`
  width: 100%;
  text-transform: capitalize;
`;

const CardSubTitle = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: ${props => props.theme.colors.labels.emphasized};
  white-space: nowrap;
`;

const TrackCardContainer = styled(LinearLayout)`
  width: 100%;
  border-radius: ${props => props.theme.borders.radius.normal}px;
`;
const SquareImageCardWrapper = styled(SquareImageCard)`
  background-size: contain;
  &:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1) !important;
  }
`;

const TrackCard = ({
  cardInfo, departmentId, departmentName, vendorID, vendorName, media,
}) => (
  <TrackCardContainer column leftAligned leftJustified>
    <BasicLink
      targeturl={urlFor('getTaxonLink', [
        cardInfo,
        departmentId,
        departmentName,
        vendorID,
        vendorName,
      ])}
      primary
    >
      <SquareImageCardWrapper imageBackground={cardInfo.icon} />
      <Spacer size={0.5} />
      <CardName size="sm">
        { media.minDesktop ? (
          <LinesEllipsis
            text={cardInfo.name}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        ) : (
          <CardSubTitle>{cardInfo.name}</CardSubTitle>
        )}
      </CardName>
      <Spacer size={1.5} />
    </BasicLink>
  </TrackCardContainer>
);

TrackCard.defaultProps = {
  vendorID: null,
  vendorName: null,
  departmentName: null,
  departmentId: null,
  media: null,
};

TrackCard.propTypes = {
  cardInfo: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    ref_id: PropTypes.string.isRequired,
  }).isRequired,
  departmentId: PropTypes.string,
  departmentName: PropTypes.string,
  vendorID: PropTypes.string,
  vendorName: PropTypes.string,
  media: PropTypes.shape({
    minDesktop: PropTypes.bool,
  }),
};

export default withMedia(withFragmentContainer(
  TrackCard,
  graphql`
    fragment TrackCard_cardInfo on Taxon {
      id
      name
      logo
      icon
      ref_id
      type
    }
  `,
));

/* eslint-disable function-paren-newline, react/no-redundant-should-component-update */
import React from 'react';
import _ from 'lodash';

import Loader from '~/modules/coreUI/components/basic/Loader';
import RootQuery from './RootQuery';


export default function WithRootQuery(
  WrappedComponent,
  query,
  LoaderComponent,
  variables,
  cacheConfig = null,
) {
  return props => (
    <RootQuery
      {..._.omit(['children'], props)}
      query={query}
      variables={_.isFunction(variables) ? variables(props) : variables}
      cacheConfig={cacheConfig}
      render={(queryResult) => {
        if (process.isStyleguidistActive) {
          return <WrappedComponent {...props} />;
        }
        if (queryResult || LoaderComponent === WrappedComponent) {
          return (
            <WrappedComponent
              {...props}
              queryResult={queryResult}
              isLoading={!queryResult}
            />
          );
        }
        if (LoaderComponent) {
          return <LoaderComponent {...props} isLoading />;
        }
        return <Loader />;
      }}
    />
  );
}

import React from 'react';
import i18next from 'i18next';
import styled, { css } from 'react-emotion';

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';

const Icon = withDirection(styled.i`
  display: inline-block;
  ${props => (props.direction === 'rtl'
    && css`
        transform: rotate(-180deg);
  `)}
`);

const LinkContainer = styled(Row)`
  cursor: pointer;
`;

const AskAykLink = () => (
  <Row pl={[1, 1, 1.5, 1.5]} pr={[1, 1, 1.5, 1.5]} spaceBetweenJustified fullWidth>
    <Label size={['xs', 'xs', 'md', 'md']}>
      {i18next.t('ecommerceOrder:notificationsAndMessages.emptyMessagesSubTitle')}
    </Label>
    <LinkContainer onClick={() => externalRedirect(urlFor('getAskAykLink'))}>
      <Label size={['xs', 'xs', 'md', 'md']} semiBold secondary sm>
        {i18next.t('ecommerceOrder:askAyk.askAykTitle')}
       &nbsp;
        <Icon className="el-icon el-icon-right" />
      </Label>
    </LinkContainer>
  </Row>
);

export default AskAykLink;

/* eslint-disable no-spaced-func, func-call-spacing */
import S from 'sanctuary';
// import { S_logWithPrefix } from '~/modules/core/utils/jsHelpers/functional'; // eslint-disable-line camelcase
import i18next from 'i18next';

const fallbackToQAR = currency => currency || 'QAR';

const getCurrencyFromLocalStorage = () => localStorage.getItem('currency');
const getCurrentCurrency = () => {
  const currentCurrency = getCurrencyFromLocalStorage();
  if (!currentCurrency || currentCurrency === 'undefined') {
    return null;
  }
  return currentCurrency;
};

const saveCurrencyInLocalStorage = currency => currency && currency.trim() !== 'undefined' && localStorage.setItem('currency', currency);

export const currentCurrency = () => S.pipe([
  getCurrentCurrency,
  fallbackToQAR,
])(null);

export const getCurrencyDisplayName = currency => i18next.t(`coreUI:currency.${currency}`);
export const currentCurrencyDisplayName = () => getCurrencyDisplayName(currentCurrency());

const newCurrencyReceived = currency => (currency === currentCurrency() ? S.Left (null) : S.Right (currency));

const changeCurrency = newCurrency => S.pipe([
  S.of (S.Either),
  S.chain (locale => newCurrencyReceived(locale)),
  S.map (saveCurrencyInLocalStorage),
  S.isRight,
]) (newCurrency);

export default changeCurrency;

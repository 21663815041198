/**
 * @flow
 * @relayHash fb670ccffe4284644b69f020dda4eb00
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type commitSeenMutationMutationVariables = {||};
export type commitSeenMutationMutationResponse = {|
  +mark_notifications_seen: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>
  |}
|};
export type commitSeenMutationMutation = {|
  variables: commitSeenMutationMutationVariables,
  response: commitSeenMutationMutationResponse,
|};
*/


/*
mutation commitSeenMutationMutation {
  mark_notifications_seen {
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mark_notifications_seen",
    "storageKey": null,
    "args": null,
    "concreteType": "MarkseenResult",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "commitSeenMutationMutation",
  "id": null,
  "text": "mutation commitSeenMutationMutation {\n  mark_notifications_seen {\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "commitSeenMutationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "commitSeenMutationMutation",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bacbad494253ef9659b9ac29b586181c';
module.exports = node;

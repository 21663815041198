/**
 * @flow
 * @relayHash 5305f99cfe164513006663548e37d5eb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResendSMSConfirmationCodeMutationVariables = {||};
export type ResendSMSConfirmationCodeMutationResponse = {|
  +send_confirmation_sms: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
    +sent: ?boolean,
  |}
|};
export type ResendSMSConfirmationCodeMutation = {|
  variables: ResendSMSConfirmationCodeMutationVariables,
  response: ResendSMSConfirmationCodeMutationResponse,
|};
*/


/*
mutation ResendSMSConfirmationCodeMutation {
  send_confirmation_sms {
    errors {
      field
      messages
      code
    }
    sent
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "send_confirmation_sms",
    "storageKey": null,
    "args": null,
    "concreteType": "SendConfirmationSms",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sent",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ResendSMSConfirmationCodeMutation",
  "id": null,
  "text": "mutation ResendSMSConfirmationCodeMutation {\n  send_confirmation_sms {\n    errors {\n      field\n      messages\n      code\n    }\n    sent\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ResendSMSConfirmationCodeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "ResendSMSConfirmationCodeMutation",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '713c6fcb26a469cad7ba96493fcc79ea';
module.exports = node;

/* eslint-disable camelcase,jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import changeCase from 'change-case';
import { css } from 'emotion';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import { Column, LinearLayout, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';

import Header from '~/modules/ecommerceOrder/containers/ordersList/orderPanel/header';
import Body from '~/modules/ecommerceOrder/containers/ordersList/orderPanel/body';
import Footer from '~/modules/ecommerceOrder/containers/ordersList/orderPanel/footer';
import OrderSteps from '~/modules/ecommerceOrder/containers/ordersList/OrderSteps';

const panelBodyStyles = () => css`
  padding: 0;
`;

const bodyWrapperStyles = () => css`
  padding: 15px;
`;

const OrderPanel = props => (
  <Panel
    header={(
      <Header
        orderNumberLabel={(
          <Label lg emphasized>
            {i18next.t('ecommerceOrder:refund.refundNo')}
          </Label>
        )}
        orderNumberValue={(
          <Label lg important semiBold>
            {props.refund.number}
          </Label>
        )}
        orderDate={(
          <Fragment>
            <Label sm>{i18next.t('ecommerceOrder:refund.refundDate')}</Label>
            <Spacer size={1} />
            <Label sm important>
              {moment(props.refund.created_at).format('DD MMM YYYY')}
            </Label>
          </Fragment>
        )}
        orderStatus={(
          <Fragment>
            <Label sm>{i18next.t('ecommerceOrder:refund.status')}</Label>
            <Spacer size={1} />
            <Label lg primary semiBold>
              {changeCase.titleCase(props.refund.refund_status)}
            </Label>
          </Fragment>
        )}
        orderSteps={<OrderSteps progressBar={props.refund.progress_bar.map(p => (p.name === 'Partially Accepted' ? ({ ...p, name: 'Partially' }) : p))} />}
      />
    )}
    body={!props.paymentInfo ? (
      <div className={bodyWrapperStyles()}>
        <Body
          {...props.refund}
          collapsed={props.collapsed}
          collapseble={props.collapseble}
          line_items={props.refund.refund_request_items.map(x => ({ ...x.line_item, ...x }))}
          lineItemProps={{
            showRefundButton: false,
            showRefundStatus: true,
            showReasons: props.showReasons,
            showPrice: props.showPrice,
          }}
        />
      </div>
    ) : (
      <Fragment>
        <div className={bodyWrapperStyles()}>
          <LinearLayout
            stretchJustified={!props.media.minDesktop}
            stretchAligned={props.media.minDesktop}
            fullWidth
            fullHeight
            row={props.media.minDesktop}
          >
            <Column width={props.media.minDesktop ? '65%' : '100%'} stretchAligned>
              <Body
                {...props.refund}
                collapsed={props.collapsed}
                collapseble={props.collapseble}
                line_items={props.refund.refund_request_items.map(x => ({ ...x.line_item, ...x }))}
                lineItemProps={{
                  showRefundButton: false,
                  showRefundStatus: true,
                  showReasons: props.showReasons,
                  showPrice: props.showPrice,
                }}
              />
            </Column>
            <Separator separatorLength="full" vertical={props.media.minDesktop} />
            {props.paymentInfo}
          </LinearLayout>
        </div>
        <Separator separatorLength="full" spacerSize="0" />
        {props.vendorComment ? (
          <div className={bodyWrapperStyles()}>
            <Row spaceBetween={1.5}>
              <Label important sm>{i18next.t('ecommerceOrder:refund.shopNote')}</Label>
              <Label emphasized sm>{props.vendorComment}</Label>
            </Row>
          </div>
        ) : null}
      </Fragment>
    )}
    footer={(props.refund.refunded_at || props.showRefundButton) ? (
      <Footer
        {...props.refund}
        orderDeliveryDate={
          props.refund.refunded_at ? (
            <Fragment>
              <Label sm>
                {i18next.t('ecommerceOrder:refund.refundedDate')}
              </Label>
              <Spacer size={1} />
              <Label sm important>
                {moment(props.refund.refunded_at).format('DD MMM YYYY')}
              </Label>
            </Fragment>
          ) : null
        }
        // TODO: Will uncomment when it's implemented by the backend
        // orderTrackingNumber={(
        //   <Fragment>
        //     <Label sm>{i18next.t('ecommerceOrder:refund.trackingNo')}</Label>
        //     <Spacer size={1} />
        //     <Label sm important>{props.refund.tracking_number}</Label>
        //   </Fragment>
        // )}
        orderDetailsButton={props.showRefundButton ? (
          <ExternalLink url={urlFor('getRefundDetailsLink', [props.refund.number])}>
            <Label md secondary nowrap>
              <i className="el-icon el-icon-refund-details" />
              {' '}
              {i18next.t('ecommerceOrder:refund.refundDetails')}
            </Label>
          </ExternalLink>
        ) : null}
      />
    ) : null}
    bodyStyle={panelBodyStyles}
  />
);

OrderPanel.defaultProps = {
  collapsed: true,
  collapseble: true,
  showRefundButton: true,
  showReasons: false,
  showPrice: false,
  paymentInfo: null,
  vendorComment: null,
};

OrderPanel.propTypes = {
  refund: PropTypes.shape({}).isRequired,
  collapsed: PropTypes.bool,
  collapseble: PropTypes.bool,
  showRefundButton: PropTypes.bool,
  showReasons: PropTypes.bool,
  showPrice: PropTypes.bool,
  paymentInfo: PropTypes.node,
  vendorComment: PropTypes.string,
};

export default withMedia(OrderPanel);

import React from 'react';
import styled from 'react-emotion';
import { TopAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import { LeftAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import { LargeSpacer, SmallSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import Loader from '~/modules/coreUI/components/basic/GradientLoader';

const ProductImage = styled.div`
  width: ${props => 5 * props.theme.new.spacer}px;
  height: ${props => 5 * props.theme.new.spacer}px;
`;

const TextStyle1 = styled.div`
  width: ${props => 20 * props.theme.new.spacer}px;
  height: ${props => 0.75 * props.theme.new.spacer}px;
`;

const TextStyle2 = styled.div`
  width: ${props => 10 * props.theme.new.spacer}px;
  height: ${props => 0.75 * props.theme.new.spacer}px;
`;

const RowVerticalAligned = styled(TopAlignedRow)`
  align-items: center;
`;

const SearchCardLoader = () => (
  <div>
    <LargeSpacer />
    <RowVerticalAligned>
      <Loader>
        <ProductImage />
      </Loader>
      <LargeSpacer />
      <LeftAlignedColumn>
        <Loader>
          <TextStyle1 />
        </Loader>
        <SmallSpacer />
        <Loader>
          <TextStyle2 />
        </Loader>
      </LeftAlignedColumn>
    </RowVerticalAligned>
    <LargeSpacer />
  </div>
);

export default SearchCardLoader;

import React from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import sendErrorToCloudwatch from '.';

import ErrorPage from '~/modules/ecommerceCoreUI/components/errorPages/ErrorPage';

function stackFormatter(stack) {
  return stack
    .match(/in [\w()]+/gi)
    .map(s => s.replace(/^in\s*/, ''))
    .join(' -> ');
}

class ErrorBoundaryComponent extends React.Component {
  state = { error: null, errorId: null }

  componentDidCatch(error, info) {
    const id = cuid();
    this.setState({ error, errorId: id });
    console.log('ERROR Info', info);
    console.log('Error', error);

    sendErrorToCloudwatch({
      id,
      error: error.message,
      stack: stackFormatter(info.componentStack),
    });
  }

  render() {
    const { error, errorId } = this.state;
    const { children } = this.props;
    return error
      ? <ErrorPage errorId={errorId} />
      : children;
  }
}
ErrorBoundaryComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundaryComponent;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'react-emotion';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';

import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';
import Button from '~/modules/coreUI/components/basic/Button';
import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import AccountItems from './AccountItems';

const DropdownContentItems = styled(Dropdown)`
  &.dropdown > .menu {
    left: auto;
    right: -145px;
    width: 317px;
    padding: 0 25px 12px;
    > .item {
      padding: 0 !important;
      border-bottom: 1px solid rgba(235, 235, 235, 0.6);
      color: ${props => props.theme.colors.labels.normal};
      a {
        padding: 12px 0 !important;
        display: block;
        color: ${props => props.theme.colors.labels.normal};
      }
      &:hover {
        background: transparent;
        color: ${props => props.theme.colors.labels.important};
        a {
          color: ${props => props.theme.colors.labels.important};
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    > .item:first-child, > .item:last-child {
      padding: 12px 0 !important;
    }
  }
`;
const UserName = withDirection(styled.div`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => (props.direction === 'rtl'
    ? css`
        text-align: right;
      `
    : css`
      text-align: left;
  `)}
`);
const UserloggedinContent = styled.div`
  font-size: 16px;
  color: #000;
  text-transform: capitalize;

`;
const Icon = styled.i`
  font-size: 30px;
  color: #b2b2b2;
`;
const AvatarDropdown = styled.img`
  object-fit: cover;
  width: ${props => 3 * props.theme.new.spacer}px;
  height: ${props => 3 * props.theme.new.spacer}px;
  overflow: hidden;
  border-radius: 50%;
`;
const AvatarHeader = styled.img`
  object-fit: cover;
  width: ${props => 2.8 * props.theme.new.spacer}px;
  height: ${props => 2.8 * props.theme.new.spacer}px;
  overflow: hidden;
  border-radius: 50%;
`;
const DropdownItemRender = (item, name) => (
  <CenterAlignedRow>
    <i className={item.icon} />
    <LargeSpacer size={8} />
    {name}
  </CenterAlignedRow>
);

const NavUserProfile = (props) => {
  const logout = (e, invalidateUserAndLogout) => {
    e.stopPropagation();
    invalidateUserAndLogout();
  };
  let userImage = '';
  // eslint-disable-next-line
  if (props.authenticated) {
    userImage = (
      <React.Fragment>
        {props.userInfo.avatar ? (
          <AvatarHeader src={props.userInfo.avatar} alt="avatar" />
        ) : (
          <IconOnlyButton
            primary
            title={i18next.t('ecommerceCoreUI:ToolTipTrans.userProfile')}
            iconName="icon-userloggedin"
            size={28}
          />
        )}
      </React.Fragment>
    );
  } else {
    userImage = (
      <IconOnlyButton
        primary
        title={i18next.t('ecommerceCoreUI:ToolTipTrans.userProfile')}
        iconName="icon-user"
        size={28}
      />
    );
  }

  return (
    <div className="user-profile">
      <DropdownContentItems trigger={userImage} pointing className="set-currency" icon={null}>
        <Dropdown.Menu>
          <Dropdown.Item>
            <LargeSpacer />
            {props.authenticated ? (
              <UserloggedinContent>
                <Row spaceBetween={0.9}>
                  {props.userInfo.avatar ? (
                    <AvatarDropdown src={props.userInfo.avatar} alt="avatar" />
                  ) : (
                    <Icon className="icon-userloggedin" />
                  )}
                  <UserName>
                    <span title={`${props.userInfo.firstName} ${props.userInfo.lastName}`}>
                      {`${props.userInfo.firstName} ${props.userInfo.lastName}`}
                    </span>
                  </UserName>
                </Row>
              </UserloggedinContent>
            ) : (
              <Row leftJustified>
                <Button
                  sm
                  inverted
                  fullRound
                  onClicked={() => navigateToModal(props.history, '/accountManagement/login')}
                >
                  {`${i18next.t('ecommerceCoreUI:logIn')} / ${i18next.t('ecommerceCoreUI:signUp')}`}
                </Button>
              </Row>
            )}
            <LargeSpacer />
          </Dropdown.Item>
          {AccountItems.map((item) => {
            const name = item.name || i18next.t(item.title);
            return (
              <React.Fragment key={item.name || item.title}>
                {(item.guestView || props.authenticated) && (
                  <Dropdown.Item key={name}>
                    {(item.guestAccessible || props.authenticated) ? (
                      <ExternalLink url={item.url}>
                        {DropdownItemRender(item, name)}
                      </ExternalLink>
                    ) : (
                      <ExternalLink
                        onClick={() => {
                          props.saveCurrentPath(
                            PathRedirectKeysMapper.showSuccess,
                            false,
                            item.url,
                            true,
                          );
                          navigateToModal(props.history, '/accountManagement/login');
                        }}
                      >
                        {DropdownItemRender(item, name)}
                      </ExternalLink>
                    )}
                  </Dropdown.Item>
                )}
              </React.Fragment>
            );
          })}
          <React.Fragment>
            {props.authenticated && (
              <Dropdown.Item>
                <Row leftJustified>
                  <Button
                    sm
                    inverted
                    fullRound
                    onClicked={e => logout(e, props.invalidateUserAndLogout)}
                  >
                    {i18next.t('ecommerceCoreUI:logout')}
                  </Button>
                </Row>
              </Dropdown.Item>
            )}
          </React.Fragment>
        </Dropdown.Menu>
      </DropdownContentItems>
    </div>
  );
};

NavUserProfile.propTypes = PropTypes.shape({
  location: PropTypes.string.isRequired,
}).isRequired;

export default withUserInfo(withRouter(NavUserProfile));

import { graphql } from 'relay-runtime';
import { getTwitteroAuthConfirmUrl } from '~/modules/accountManagement/utilities/socialMediaData';

const TwitterCallbackQuery = graphql`
  query TwitterCallbackQuery($oauthConfirmUrl: String!) {
    twitter_callback(oauth_confirm_url: $oauthConfirmUrl) {
      authorized_url
      secret
      token
      errors {
        code
        field
        messages
      }
    }
  }
`;

export const variables = {
  oauthConfirmUrl: getTwitteroAuthConfirmUrl(),
};

export default TwitterCallbackQuery;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { ErrorLabel, Label } from '~/modules/coreUI/components/basic/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import TextBox from '~/modules/coreUI/components/basic/TextBox';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { CreateSmartCartMutation, CreateSmartCartMutationRoot } from '../smartCartMutations/CreateSmartCart';
import { EditSmartCartMutation, EditSmartCartMutationRoot } from '../smartCartMutations/EditSmartCart';
import ModalWrapper from '~/modules/ecommerceOrder/components/smartCard/ModalWrapper';
import Icon from '~/modules/coreUI/components/basic/Icon';
import withModalTracker from '~/modules/core/utils/modalHelpers/withModalTracker';
import { getFirstError } from '~/modules/ecommerceOrder/containers/shoppingCart/utils/ErrorsProcessor';

class AddEditSmartModal extends Component {
  state={
    isLoading: false,
    ErrorsPanel: null,
    smartCartName: this.props.smartCartName || '',
  }

  onMutationError = (errors) => {
    const addOrEditErrors = getFirstError(errors);
    this.setState({ ErrorsPanel: addOrEditErrors });
  };

  onMutationSuccess = (response) => {
    if (this.props.edit) {
      if (response.edit_smart_cart.errors) {
        this.setState({ ErrorsPanel: response.edit_smart_cart.errors[0].messages[0] });
      } else {
        this.closePopup();
      }
    } else if (response.add_smart_cart.errors) {
      this.setState({ ErrorsPanel: response.add_smart_cart.errors[0].messages[0] });
    } else {
      this.closePopup();
    }
  };

  onMutationLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  addToSmartCart = () => {
    const smartCartName = this.state.smartCartName; // eslint-disable-line prefer-destructuring

    commitMutation(
      this.props.environment,
      CreateSmartCartMutation,
      CreateSmartCartMutationRoot,
      {
        input: {
          name: smartCartName.replace(/(^[\s]+|[\s]+$)/g, ''),
        },
      },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
  }

  editSmartCart = () => {
    const smartCartName = this.state.smartCartName; // eslint-disable-line prefer-destructuring

    commitMutation(
      this.props.environment,
      EditSmartCartMutation,
      EditSmartCartMutationRoot,
      {
        input: {
          name: smartCartName.replace(/(^[\s]+|[\s]+$)/g, ''),
        },
        id: this.props.smartCartId,
      },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
  }

  createSmartCart = (event) => {
    this.setState({ smartCartName: event.target.value });
  }

  onCloseModal = () => {
    this.props.onCloseModal(false);
  }

  closePopup = () => {
    this.onCloseModal();
    this.props.closeCurrentModal();
  }

  render() {
    const {
      isLoading,
      ErrorsPanel,
      smartCartName,
    } = this.state;

    return (
      <ModalWrapper onCloseModal={() => this.onCloseModal()}>
        <Column fullWidth spaceBetween={2.5} leftAligned>
          <Label important semiBold>
            {this.props.edit
              ? i18next.t('ecommerceOrder:smartCart.editSmartCartTitle')
              : i18next.t('ecommerceOrder:smartCart.createSmartCartTitle')}
          </Label>
          <Column leftAligned fullWidth spaceBetween={1}>
            <TextBox
              value={smartCartName}
              placeholder={this.props.edit
                ? i18next.t('ecommerceOrder:smartCart.editSmartCartTitle')
                : i18next.t('ecommerceOrder:smartCart.createSmartCartPlaceholder')
             }
              onChange={this.createSmartCart}
            />
            {ErrorsPanel && (
              <Row>
                <ErrorLabel sm>
                  <Icon size={['sm']} className="el-icon el-icon-error" />
                  {' '}
                  {ErrorsPanel}
                </ErrorLabel>
              </Row>
            )}
          </Column>
          <Row fullWidth rightAligned rightJustified spaceBetween={1}>
            <Button width="120px" passive fontSize={18.5} onClicked={this.closePopup}>
              {i18next.t('ecommerceOrder:smartCart.cancelButton')}
            </Button>
            <Button
              disabled={!smartCartName}
              loading={isLoading}
              width="120px"
              secondary
              fontSize={18.5}
              onClicked={this.props.edit ? this.editSmartCart : this.addToSmartCart}
            >
              {this.props.edit
                ? i18next.t('ecommerceOrder:smartCart.saveButton')
                : i18next.t('ecommerceOrder:smartCart.createButton')
              }
            </Button>
          </Row>
        </Column>
      </ModalWrapper>
    );
  }
}

AddEditSmartModal.defaultProps = {
  smartCartName: '',
  smartCartId: null,
  edit: false,
};

AddEditSmartModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  environment: PropTypes.shape({}).isRequired,
  smartCartName: PropTypes.string,
  smartCartId: PropTypes.string,
  edit: PropTypes.bool,
  closeCurrentModal: PropTypes.func.isRequired,
};

export default withModalTracker(withRelayEnvironment(AddEditSmartModal));

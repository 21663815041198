/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import WheelLogo from './WheelLogo';
import WheelArrow from './WheelArrow';
import WheelItems from './WheelItems';

const Content = styled.div`
  position: relative;
`;
const WheelWrapper = withDirection(styled.div`
  position: absolute;
  ${props => (props.direction === 'rtl'
    ? css`
        left: -29.5%;
      `
    : css`
        right: -29.5%;
  `)}
`);
const Wheel = ({ spin, spinWheelDataResult }) => (
  <WheelWrapper>
    <Content>
      <WheelLogo />
      <WheelArrow />
      <WheelItems spin={spin} spinWheelDataResult={spinWheelDataResult} />
    </Content>
  </WheelWrapper>
);

Wheel.propTypes = {
  spin: PropTypes.bool.isRequired,
  spinWheelDataResult: PropTypes.shape({}).isRequired,
};

export default Wheel;

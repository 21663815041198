const RecoverPasswordTrans = {
  En: {
    panelTitle: 'Recover Your Password',
    panelSubtitle: 'Follow the steps to reset your password',
    Button: 'Request Password Recovery',
    Enteryourusernameemailaddress: ' 1. Enter your email or mobile number.',
    secondParagraph: ' 2. Check your inbox or messages for the verification code.',
    thirdParagraph: ' 3. Use your code to verify your identity and create a new password.',
    footer_BackTo: 'Back',
    footer_Link: 'Log In',
    resetButton: 'Reset Your Password',
  },
  Ar: {
    panelTitle: 'استعادة كلمة المرور الخاصة بك',
    panelSubtitle: 'اتبع الخطوات لإعادة تعيين كلمة المرور الخاصة بك',
    Button: 'طلب استعادة كلمة المرور',
    Enteryourusernameemailaddress: ' ١. أدخل بريدك الإلكتروني أو رقم هاتفك الجوال.',
    secondParagraph: ' ٢. راجع بريدك الوارد أو رسائلك لمعرفة رمز التحقق.',
    thirdParagraph:
    ' ٣. استخدم الرمز الخاص بك لتأكيد هويتك وإنشاء كلمة مرور جديدة.',
    footer_BackTo: 'العودة',
    footer_Link: 'تسجيل الدخول',
    resetButton: 'إعادة ضبط كلمة المرور',
  },
};
export default RecoverPasswordTrans;

import React from 'react';
import PropTypes from 'prop-types';

import ConnectSocialAccountMutation, {
  ConnectSocialAccountMutationRoot,
} from '~/modules/accountManagement/containers/panels/socialMedia/ConnectSocialAccountMutation';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

import Loader from '~/modules/coreUI/components/basic/Loader';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import { setLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';

class ConnectSocialMedia extends React.Component {
  constructor(props) {
    super(props);

    const { getSocialMediaData } = props;
    const mutationVariables = getSocialMediaData(true);
    if (mutationVariables) {
      commitMutation(
        this.props.environment,
        ConnectSocialAccountMutation,
        ConnectSocialAccountMutationRoot,
        mutationVariables,
        this.onMutationSuccess,
        this.onMutationError,
        this.onMutationLoading,
      );
    }
  }

  state = {
    isLoading: true,
  };

  onMutationError = (error) => {
    const { history } = this.props;

    if (error) {
      setLocalMessageFor(PathRedirectKeysMapper.login, error);
    }

    navigateToModal(history, '/accountManagement/login');
  };

  onMutationSuccess = (result) => {
    const { history, redirectIfPathExists } = this.props;

    const { status, errors } = result.connect_social_account;

    if (errors) {
      setLocalMessageFor(PathRedirectKeysMapper.login, errors);
      navigateToModal(history, '/accountManagement/login');
    }

    if (status) {
      const pathData = redirectIfPathExists(PathRedirectKeysMapper.pathAfterConnectSocialMedia);
      if (pathData) {
        navigateToModal(history, pathData.name);
      } else {
        navigateToModal(history, '/accountManagement/showSuccess');
      }
    }
  };

  onMutationLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  render = () => {
    const { isLoading } = this.state;
    return isLoading ? (
      <Column fullWidth fullHeight centerAligned centerJustified style={{ position: 'absolute' }}>
        <Loader />
      </Column>
    ) : null;
  };
}

ConnectSocialMedia.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  getSocialMediaData: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  redirectIfPathExists: PropTypes.func.isRequired,
};

export default withRelayEnvironment(withUserInfo(ConnectSocialMedia));

const ProfileInfoTrans = {
  En: {
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    language: 'Language',
    currency: 'Currency',
    languages: {
      en: 'English',
      ar: 'العربية',
    },
    personalInfo: 'Personal Information',
    generalSettings: 'General Settings',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    currentPasswordFormLabel: 'Current Password',
    newPasswordFormLabel: 'New Password',
    confirmPasswordFormLabel: 'Confirm Password',
    passwordChangedSuccessfully: 'Password changed successfully',
    settingsChangedSuccessfully: 'Settings changed successfully',
    saveAddressButtonLabel: 'Save Addresses',
    newsletterSubscription: 'Receive Newsletter',
    receivePushNotifications: 'Receive Push Notifications',
    myAccount: 'My Account',
    subtitle: 'Manage my account, profile, and lists',
    Orders: {
      emptyHeader: 'You don\'t have orders yet!',
      emptyLabel: 'Enjoy shopping and make your first order now!',
      emptyButton: 'Discover Products',
    },
    email: 'Email',
    nationality_name: 'Nationality',
    mobile_number: 'Mobile',
    gender: 'Gender',
    birthdate_timestamp: 'Date of Birth',
    birthdate: 'Date of Birth',
    country_name: 'Country',
    city_name: 'City',
    short_address: 'Address',
    first_name: 'First Name',
    last_name: 'Last Name',
    change: 'Change',
    upload: 'Upload',
    club_name: 'Club',
    phone_provider_name: 'Network Provider',
    school_name: 'University',
    insurance_company_name: 'Insurance Company',
    employer_name: 'Employer',
    uploadLabel: 'No file chosen',
    uploadButtonText: 'Choose',
    providerPlaceholder: 'Select Network Provider',
    clubPlaceholder: 'Select Club',
    employerPlaceholder: 'Select Employer',
    insurancePlaceholder: 'Select Insurance Company',
    schoolPlaceholder: 'Select University',
    uploadImageLabel: 'Affiliation Proof',
  },
  Ar: {
    edit: 'تعديل',
    save: 'حفظ',
    cancel: 'إلغاء',
    language: 'اللغة',
    currency: 'العملة',
    languages: {
      en: 'English',
      ar: 'العربية',
    },
    personalInfo: 'البيانات الشخصية',
    generalSettings: 'الإعدادات العامة',
    changePassword: 'تغيير كلمة المرور',
    currentPassword: 'كلمة المرور الحالية',
    newPassword: 'كلمة المرور الجديدة',
    confirmPassword: 'تأكيد كلمة المرور',
    currentPasswordFormLabel: 'كلمة المرور الحالية',
    newPasswordFormLabel: 'كلمة المرور الجديدة',
    confirmPasswordFormLabel: 'تأكيد كلمة المرور',
    passwordChangedSuccessfully: 'تم تغيير كلمة المرور بنجاح',
    settingsChangedSuccessfully: 'تم تغيير الإعدادات بنجاح',
    saveAddressButtonLabel: 'حفظ العناوين',
    newsletterSubscription: 'استقبال النشرة البريدية',
    receivePushNotifications: 'استقبال إشعارات الجوال',
    myAccount: 'حسابي',
    subtitle: 'إدارة حسابي والملف الشخصي والقوائم',
    Orders:
    {
      emptyHeader: 'ليس لديك طلبات حتى الآن!',
      emptyLabel: 'استمتع بالتسوق وقم الآن بطلبك الأول!',
      emptyButton: 'اكتشف المنتجات',
    },
    email: 'البريد الإلكتروني',
    nationality_name: 'الجنسية',
    mobile_number: 'الهاتف الجوال',
    gender: 'النوع',
    birthdate_timestamp: 'تاريخ الميلاد',
    birthdate: 'تاريخ الميلاد',
    country_name: 'الدولة',
    city_name: 'المدينة',
    short_address: 'العنوان',
    first_name: 'الاسم الأول',
    last_name: 'الاسم الأخير',
    change: 'تغيير',
    upload: 'رفع',
    club_name: 'النادي',
    phone_provider_name: 'مزود الشبكة',
    school_name: 'الجامعة',
    insurance_company_name: 'شركة التأمين',
    employer_name: 'جهة العمل',
    uploadLabel: 'لم تقم باختيار ملف',
    uploadButtonText: 'اختر',
    providerPlaceholder: 'اختر مزود الشبكة',
    clubPlaceholder: 'اختر النادي',
    employerPlaceholder: 'اختر جهة العمل',
    insurancePlaceholder: 'اختر شركة التأمين',
    schoolPlaceholder: 'اختر الجامعة',
    uploadImageLabel: 'إثبات الانتماء',
  },
};
export default ProfileInfoTrans;

/**
 * @flow
 * @relayHash 327ef96b4e3503458c94e07371e3aeb8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NavSearchQueryVariables = {||};
export type NavSearchQueryResponse = {|
  +tracks: $ReadOnlyArray<?{|
    +track_type: ?string,
    +name: ?string,
  |}>
|};
export type NavSearchQuery = {|
  variables: NavSearchQueryVariables,
  response: NavSearchQueryResponse,
|};
*/


/*
query NavSearchQuery {
  tracks {
    track_type
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "track_type",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NavSearchQuery",
  "id": null,
  "text": "query NavSearchQuery {\n  tracks {\n    track_type\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NavSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tracks",
        "storageKey": null,
        "args": null,
        "concreteType": "Track",
        "plural": true,
        "selections": [
          v0,
          v1
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NavSearchQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tracks",
        "storageKey": null,
        "args": null,
        "concreteType": "Track",
        "plural": true,
        "selections": [
          v0,
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ebd1c3f004c27bd24ac7774fd848ba5';
module.exports = node;

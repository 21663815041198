import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const Wrapper = withDirection(styled(Row)`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 4;
  transition: 0.5s ease-in-out;
  ${props => (props.visible
    ? css`
      right: 0;
    `
    : css`
      right: -${65 * props.theme.new.spacer}px;
    `
  )}
  ${props => (props.direction === 'rtl' && props.visible
    && css`
        left: 0;
        right: auto;
  `)}
  ${props => (props.direction === 'rtl' && !props.visible
    && css`
        left: -${65 * props.theme.new.spacer}px;
        right: auto;
  `)}
`);

const LuckyWheelWrapper = ({ children, visible }) => (
  <Wrapper visible={visible}>{ children }</Wrapper>
);

LuckyWheelWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default LuckyWheelWrapper;

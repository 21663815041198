import fp from 'lodash/fp';
import S from 'sanctuary';

export const logWithPrefix = fp.curry((prefix, str) => {
  console.log(`${prefix}${str}`); // eslint-disable-line
  return str;
});

export const S_logWithPrefix = fp.curry((prefix, str) => { // eslint-disable-line camelcase
  console.log(`${prefix} ${S.show(str)}`); // eslint-disable-line
  return str;
});


export const prefixWith = fp.curry((prefix, str) => `${prefix}${str}`);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Counter from '~/modules/coreUI/components/basic/Counter';

class OrderCounter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      counter: this.props.initialCount,
    };
  }

  increaseCounter = () => this.setState(
    prevState => ({ counter: prevState.counter ? prevState.counter + 1 : this.props.initialCount }),
    () => { this.props.onChange(this.state.counter); },
  );

  decreaseCounter = () => this.setState(
    prevState => ({ counter: prevState.counter ? prevState.counter - 1 : this.props.initialCount }),
    () => { this.props.onChange(this.state.counter); },
  );

  render = () => (
    <Counter
      size={['xs', 'xs', 'lg']}
      value={this.state.counter}
      min={1}
      disabled={this.props.disabled}
      max={this.props.initialCount}
      increase={this.increaseCounter}
      decrease={this.decreaseCounter}
      minWidth={[7.5, 7.5, 10.5]}
    />
  )
}

OrderCounter.propTypes = {
  initialCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default OrderCounter;

import React from 'react';
import { translate, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';
import ProductsTrack from './productsTrack/ProductsTracks';

const HomePage = () => (
  <React.Fragment>
    <Container text>
      <br />
      <React.Fragment>
        <Header size="huge">
          <Trans i18nKey="metadata.displayName" />
        </Header>
        <Header.Subheader>
          <Trans i18nKey="metadata.description" />
        </Header.Subheader>
      </React.Fragment>
    </Container>
    <ProductsTrack />
  </React.Fragment>
);

export default translate('ecommerceRecommended')(HomePage);

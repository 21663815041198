import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

export const LabelItem = withDirection(styled(Row)`
  padding: ${props => props.theme.new.spacer}px ${props => 1.5 * props.theme.new.spacer}px;
  border: 1px dashed ${props => props.theme.new.colors.labels.normal.primary};
  ${props => (props.direction === 'rtl'
    ? css`
        border-top-right-radius: ${0.5 * props.theme.new.spacer}px;
        border-bottom-right-radius: ${0.5 * props.theme.new.spacer}px;
      `
    : css`
        border-top-left-radius: ${0.5 * props.theme.new.spacer}px;
        border-bottom-left-radius: ${0.5 * props.theme.new.spacer}px;
  `)}
  height: 38px;
  ${props => cssMediaMax.xsmall`
    padding: ${props.theme.new.spacer}px;
  `}
`);

export const OfferContainer = withDirection(styled(Row)`
  padding: ${props => props.theme.new.spacer}px ${props => 1.5 * props.theme.new.spacer}px;
  border: 1px dashed ${props => props.theme.new.colors.labels.borders.normal.normal};
  ${props => (props.direction === 'rtl'
    ? css`
        border-top-left-radius: ${0.5 * props.theme.new.spacer}px;
        border-bottom-left-radius: ${0.5 * props.theme.new.spacer}px;
        border-right: none;
      `
    : css`
        border-top-right-radius: ${0.5 * props.theme.new.spacer}px;
        border-bottom-right-radius: ${0.5 * props.theme.new.spacer}px;
        border-left: none;
  `)}
  height: 38px;
  flex-grow: 1;
  ${props => cssMediaMax.xsmall`
    padding: ${props.theme.new.spacer}px;
  `}
`);

const LabelWithAction = ({ label, content, action }) => (
  <Row centerJustified width="100%">
    <LabelItem>
      {label}
    </LabelItem>
    <OfferContainer spaceBetween={1} spaceBetweenJustified>
      <Label semiBold sm>
        {content}
      </Label>
      {action}
    </OfferContainer>
  </Row>
);

LabelWithAction.propTypes = {
  label: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  action: PropTypes.element.isRequired,
};

export default LabelWithAction;

/* eslint-disable lines-between-class-members */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';
import i18next from 'i18next';
import PulseLoader from 'react-spinners/PulseLoader';

import GridLayout, { GridItem } from '~/modules/coreUI/components/layouts/helpers/GridLayout';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { NameLabel, RegionLabel, Address } from '~/modules/ecommerceOrder/components/bacis/Labels';
import CircleRadioButton from '~/modules/ecommerceOrder/components/bacis/CircleRadioButton';
import openGoogleMap from '~/modules/ecommerceCore/utils/locationRedirect';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import deleteAddressesMutation from '../forms/mutations/DeleteAddressMutation';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const FullWidthLayoutGrid = styled(GridLayout)`
  width: 100%;
`;

const iconStyle = props => css`
  ${(props.direction === 'rtl'
    ? css`
      transform: rotate(-90deg);
       `
    : css`
      transform: rotate(0deg);
    `)}
`;

const gridTemplate = `
  "title icon"
  / auto 1fr
`;

const LeftGridItem = styled(GridItem)`
  justify-self: end;
`;

const UserInfo = styled.div`
  padding: 0px ${props => 1.5 * props.theme.new.spacer}px;
  border-bottom: ${props => (props.hasBorderBottom ? 1 : 0)}px solid ${props => props.theme.new.colors.labels.borders.normal.subtle};
  ${props => props.media.maxMobile && css`
     margin: 0px ${props.theme.new.spacer}px;
     padding: 0px;
     width: unset;
  `}
`;

const AddressData = styled(Column)`
  padding: ${props => 1.5 * props.theme.new.spacer}px ${props => props.theme.new.spacer}px;
`;

/* eslint-disable react/prop-types, camelcase */
const TitleHeader = withDirection(props => (
  <Row
    centerAligned
    p={1.5}
    spaceBetweenJustified
    backgroundColor="panel"
    leftBordered={props.direction === 'ltr' && (props.isSelected || (!props.selectable && props.setAsDefault))}
    rightBordered={props.direction === 'rtl' && (props.isSelected || (!props.selectable && props.setAsDefault))}
    borderWeight="normal"
    borderColor="secondary"
  >
    {props.children}
  </Row>
));
/* eslint-enable ract/prop-types */

class AddressListEntry extends Component {
  state = {
    deletionInProgress: false,
  }
  customStyle = () => {
    let newCustomStyle = {};
    if (this.props.isSelected) {
      newCustomStyle = {
        borderLeft: '2px solid #fb9410',
      };
    }
    return newCustomStyle;
  };

  editAddresses = addressID => this.props.onEditAddressStarted(addressID);

  deleteAddresses = (addressID) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const response = confirm(i18next.t('ecommerceOrder:Checkout.addressDeleteConfirmation'));

    if (response) {
      deleteAddressesMutation(
        this.props.environment,
        parseInt(addressID, 10),
        loading => this.setState({ deletionInProgress: loading }),
      );
    }
  }

  addressInfo = () => this.props.addressInfo;

  render = () => {
    const {
      addressInfo,
      type,
      media,
      isLast,
      isSelected,
      onAddressSelected,
      selectable,
      editable,
      deletable,
      direction,
    } = this.props;
    return (
      <React.Fragment>
        <UserInfo isLast={isLast} media={media} hasBorderBottom={!isLast}>
          <TitleHeader direction={direction} isSelected={isSelected} selectable={selectable} setAsDefault={addressInfo?.save_as_default}>
            <GridItem gridArea="title">
              <NameLabel size={['sm', 'sm', 'lg', 'lg']}>
                {addressInfo.full_name}
              </NameLabel>
            </GridItem>
            {selectable ? (
              <LeftGridItem gridArea="icon">
                <CircleRadioButton
                  value={addressInfo.ref_id}
                  isChecked={isSelected}
                  name={type}
                  changeRadioButtonValue={onAddressSelected}
                />
              </LeftGridItem>
            ) : (
              null
            )}
            {!selectable && addressInfo.save_as_default && (
              <Label sm>{`(${i18next.t('ecommerceOrder:Checkout.defaultAddress')})`}</Label>
            )}
          </TitleHeader>
          <AddressData leftAligned fullWidth>
            {editable ? (
              <RegionLabel size={['sm', 'sm', 'md', 'md']}>{addressInfo.address1}</RegionLabel>
            ) : (
              <Row>
                <RegionLabel size={['sm', 'sm', 'md', 'md']}>{addressInfo.address1}</RegionLabel>
                <Separator vertical separatorColorTone="light" separatorLength="large" />
                <IconOnlyButton
                  size={20}
                  iconName="icon-location"
                  title={i18next.t('ecommerceOrder:Tooltip.location')}
                  onClick={() => openGoogleMap(addressInfo.lat, addressInfo.lng)}
                />
              </Row>
            )}
            <Spacer size={0.5} />
            <Address size={['sm', 'sm', 'md', 'md']}>
              {addressInfo.state != null && (
                addressInfo.state.name
              )}
              {addressInfo.state != null && (' , ')}
              {addressInfo.country.name}
            </Address>
            <Spacer />
            <FullWidthLayoutGrid gridTemplate={gridTemplate}>
              <GridItem gridArea="title">
                <Address size={['sm', 'sm', 'md', 'md']}>{`+${addressInfo.phone_code} ${' '} ${addressInfo.phone}`}</Address>
              </GridItem>
              {!editable ? (
                null
              ) : (
                <LeftGridItem gridArea="icon">
                  <Row>
                    <IconOnlyButton
                      size={20}
                      iconName="icon-location"
                      title={i18next.t('ecommerceOrder:Tooltip.location')}
                      onClick={() => openGoogleMap(addressInfo.lat, addressInfo.lng)}
                    />
                    <Separator vertical separatorColorTone="light" separatorLength="full" />
                    <IconOnlyButton
                      size={20}
                      iconName="icon-edit"
                      title={i18next.t('ecommerceOrder:Tooltip.edit')}
                      onClick={() => this.editAddresses(addressInfo.ref_id)}
                      customIconStyle={iconStyle}
                      direction={direction}
                    />
                    {deletable && !addressInfo.save_as_default && (
                      <React.Fragment>
                        <Separator vertical separatorColorTone="light" separatorLength="full" />
                        <Column>
                          <IconOnlyButton
                            size={20}
                            iconName="el-icon el-icon-delete-solid"
                            title={i18next.t('ecommerceOrder:Tooltip.delete')}
                            onClick={() => this.deleteAddresses(addressInfo.ref_id)}
                          />
                          {this.state.deletionInProgress && (
                            <PulseLoader size={2} />
                          )}
                        </Column>
                      </React.Fragment>
                    )}
                  </Row>
                </LeftGridItem>
              )}
            </FullWidthLayoutGrid>
          </AddressData>
        </UserInfo>
      </React.Fragment>
    );
  };
}

AddressListEntry.defaultProps = {
  addressInfo: {},
  type: null,
  isLast: null,
  selectable: true,
  editable: true,
  deletable: false,
  isSelected: false,
  onEditAddressStarted: null,
  onAddressSelected: null,
};

AddressListEntry.propTypes = {
  environment: PropTypes.objectOf(PropTypes.any).isRequired,
  addressInfo: PropTypes.shape({}),
  type: PropTypes.string,
  onEditAddressStarted: PropTypes.func,
  onAddressSelected: PropTypes.func,
  isLast: PropTypes.bool,
  isSelected: PropTypes.bool,
  media: PropTypes.shape({}).isRequired,
  selectable: PropTypes.bool,
  deletable: PropTypes.bool,
  editable: PropTypes.bool,
};

export default withDirection(withMedia(withRelayEnvironment(AddressListEntry)));

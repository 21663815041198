const RecentViewedTrans = {
  En: {
    emptyHeader: 'You don\'t have products viewed yet!',
    emptyLabel: 'Reach your last viewed products easily',
    viewProducts: 'View Products',
  },
  Ar: {
    emptyHeader: 'ليس لديك منتجات شاهدتها حتى الآن!',
    emptyLabel: 'يمكنك الوصول إلى آخر المنتجات التي شاهدتها بسهولة',
    viewProducts: 'عرض المنتجات',
  },
};
export default RecentViewedTrans;

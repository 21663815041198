import { graphql } from 'react-relay';

export const CreateSmartCartMutationRoot = 'add_smart_cart';

export const CreateSmartCartMutation = graphql`
    mutation CreateSmartCartMutation($input: SmartCartInputQuery) {
      add_smart_cart(input: $input) {
        errors {
          code
          field
          messages
        }
        current_user {
          ...UserInfoFragment @relay(mask: false)
        }
        smart_cart{
          ...WithCartData_cart @relay(mask: false)
        }
      }
    }
  `;

import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import TwoLevelsHeader from '~/modules/core/layouts/advancedTwoLevels';
import HomePageLogo from '~/modules/coreUI/components/projects/HomePageLogo';
import NavUserProfile from '~/modules/ecommerceCoreUI/components/header/headerActions/NavUserProfile';
import NavLanguage from '~/modules/ecommerceCoreUI/components/header/headerActions/NavLanguage';
import NavCurrency from '~/modules/ecommerceCoreUI/components/header/headerActions/NavCurrency';
import SecuredCheckout from '~/modules/ecommerceCoreUI/components/header/headerActions/SecuredCheckout';

import { desktopTopHeaderStyles, desktopBottomHeaderStyles } from './Header.styles';

const MainHeader = styled.div`
  background: #f8f7f7 !important;
`;

const getCurrencyList = userInfo => (!userInfo || !userInfo.settings ? [] : userInfo.settings.supported_currencies);

const getCurrentCurrency = userInfo => (!userInfo || !userInfo.settings ? '' : userInfo.settings.currency);

const getLanguage = userInfo => (!userInfo || !userInfo.settings ? '' : userInfo.settings.language);

const InnerHeader = ({ userInfo }) => {
  const menuInfo = {
    desktop: {
      top: {
        left: [
          {
            itemRenderer: <HomePageLogo />,
            targeturl: '/',
          },
        ],
        right: [
          {
            itemRenderer: <SecuredCheckout />,
          },
          {
            verticalSeparator: true,
            separatorColorTone: 'normal',
          },
          {
            itemRenderer: <NavUserProfile />,
          },
          {
            verticalSeparator: true,
            separatorColorTone: 'normal',
          },
          {
            itemRenderer: <NavLanguage currentLanguage={getLanguage(userInfo)} />,
          },
          {
            verticalSeparator: true,
            separatorColorTone: 'normal',
          },
          {
            itemRenderer: (
              <NavCurrency
                currencyList={getCurrencyList(userInfo)}
                currentCurrency={getCurrentCurrency(userInfo)}
              />
            ),
          },
        ],
        center: [],
      },
      bottom: {
        left: [],
        right: [],
      },
    },
    mobile: {
      top: {
        left: [],
        right: [],
      },
      bottom: {
        left: [],
        right: [],
      },
    },
  };

  return (
    <MainHeader>
      <TwoLevelsHeader
        desktopTopHeaderStyles={desktopTopHeaderStyles}
        desktopBottomHeaderStyles={desktopBottomHeaderStyles}
        menuInfo={menuInfo}
      />
    </MainHeader>
  );
};

InnerHeader.propTypes = {
  userInfo: PropTypes.shape({}).isRequired,
};

export default withUserInfo(InnerHeader);

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type PaginationData_pageInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrdersList_queryResult$ref: FragmentReference;
export type OrdersList_queryResult = {|
  +completed_orders: ?{|
    +page_info: ?{|
      +$fragmentRefs: PaginationData_pageInfo$ref
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
    +orders: ?$ReadOnlyArray<?{|
      +eligible_refund: ?boolean,
      +progress_bar: ?$ReadOnlyArray<?{|
        +status: ?string,
        +name: ?string,
      |}>,
      +product_type: ?string,
      +delivery_date: ?string,
      +tracking_number: ?string,
      +id: string,
      +created_at: ?string,
      +notes: ?string,
      +admin_rejection_note: ?string,
      +shipment_state: ?string,
      +state: string,
      +number: ?string,
      +vendor: {|
        +name: ?string,
        +rating_count: ?number,
        +avg_rating: ?number,
        +ref_id: string,
      |},
      +line_items: $ReadOnlyArray<?{|
        +id: string,
        +ref_id: string,
        +eligible_refund: ?boolean,
        +variant: {|
          +slug: ?string,
          +vendor_variants_count: ?number,
          +name: string,
          +rating_count: ?number,
          +avg_rating: ?number,
          +images_attachments: $ReadOnlyArray<?{|
            +url_small: string
          |}>,
          +option_values: ?$ReadOnlyArray<?{|
            +id: string,
            +presentation: ?string,
          |}>,
        |},
        +quantity: number,
      |}>,
    |}>,
  |},
  +$refType: OrdersList_queryResult$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eligible_refund",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrdersList_queryResult",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "input",
      "type": "OrdersListInput",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "completed_orders",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input",
          "type": "OrdersListInput"
        }
      ],
      "concreteType": "CompletedOrders",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "page_info",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "PaginationData_pageInfo",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "errors",
          "storageKey": null,
          "args": null,
          "concreteType": "Error",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "field",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "messages",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "code",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orders",
          "storageKey": null,
          "args": null,
          "concreteType": "Order",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notes",
              "args": null,
              "storageKey": null
            },
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "product_type",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "delivery_date",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "tracking_number",
              "args": null,
              "storageKey": null
            },
            v1,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "created_at",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "progress_bar",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderStates",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                v2
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "admin_rejection_note",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "shipment_state",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "state",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "number",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "vendor",
              "storageKey": null,
              "args": null,
              "concreteType": "Shop",
              "plural": false,
              "selections": [
                v2,
                v3,
                v4,
                v5
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "line_items",
              "storageKey": null,
              "args": null,
              "concreteType": "LineItem",
              "plural": true,
              "selections": [
                v1,
                v5,
                v0,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "variant",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Variant",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "slug",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "vendor_variants_count",
                      "args": null,
                      "storageKey": null
                    },
                    v2,
                    v3,
                    v4,
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "images_attachments",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ImageAttachment",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "url_small",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "option_values",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "OptionValue",
                      "plural": true,
                      "selections": [
                        v1,
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "presentation",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e5dd4d6da6cd15708fd483930d2d2e8';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import TrackWelcomeMessage from './TrackWelcomeMessage';

const Logo = styled.img`
  margin: 0 auto;
  display: block;
  height: ${props => 5.9 * props.theme.new.spacer}px;
  ${props => cssMediaMax.mobile`
    height: ${4.3 * props.theme.new.spacer}px;
  `}
  ${props => cssMediaMax.xsmall`
    height: ${2.6 * props.theme.new.spacer}px;
  `}
`;
const TrackHeading = styled.div`
  width: 40%;
  margin: auto;
  position: absolute;
  top: 25px;
  left: 10%;
  right: 10%;
  ${cssMediaMax.tablet`
    top: 13%;
  `}
`;

const TrackHeadingArea = ({ trackWelcomeMessage, direction }) => (
  <TrackHeading>
    <Logo
      src={
      direction === 'rtl'
        ? `/${imagesPathName}/Header/sideMenu-logo.png`
        : `/${imagesPathName}/Header/sideMenu-logo-en.png`
      }
      alt="logo"
    />
    <Spacer size={2.4} />
    <TrackWelcomeMessage text={trackWelcomeMessage} />
  </TrackHeading>
);

TrackHeadingArea.propTypes = {
  direction: PropTypes.string.isRequired,
  trackWelcomeMessage: PropTypes.string,
};

TrackHeadingArea.defaultProps = {
  trackWelcomeMessage: '',
};
export default withDirection(TrackHeadingArea);

const RecommendedShopsAndProductsTrans = {
  En: {
    recommendedTitle: 'May You Like',
    recommendedSubTitle: 'Handpicked from the Mall',
    shopNowButton: 'Shop Now',
    followButton: 'Follow',
    followingButton: 'Following',
    productsTab: 'Products',
    dropdownLabel: 'Please Select Category',
  },
  Ar: {
    recommendedTitle: 'ستنال إعجابك',
    recommendedSubTitle: 'منتقاة بعناية من المول',
    shopNowButton: 'تسوق الآن',
    followButton: 'تابع',
    followingButton: 'متابَع',
    productsTab: 'المنتجات',
    dropdownLabel: 'يرجى تحديد الفئة',
  },
};
export default RecommendedShopsAndProductsTrans;

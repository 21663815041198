import React from 'react';
import AddressesContext from './AddressesContext';

export default WrappedComponent => props => (
  <AddressesContext.Consumer>
    {value => (
      <WrappedComponent {...props} {...value}>
        {props.children}
      </WrappedComponent>
    )}
  </AddressesContext.Consumer>
);

/**
 * @flow
 * @relayHash 51a6a7d54a008d3219d13b8ce8f8eb4f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DepartmentsQueryVariables = {||};
export type DepartmentsQueryResponse = {|
  +taxons: $ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +ref_id: string,
    +top_brands: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
      +ref_id: string,
    |}>,
    +children: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
      +ref_id: string,
      +children: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
        +type: string,
        +ref_id: string,
      |}>,
    |}>,
  |}>
|};
export type DepartmentsQuery = {|
  variables: DepartmentsQueryVariables,
  response: DepartmentsQueryResponse,
|};
*/


/*
query DepartmentsQuery {
  taxons(type: department) {
    id
    name
    ref_id
    top_brands {
      id
      name
      ref_id
    }
    children {
      id
      name
      ref_id
      children {
        id
        name
        type
        ref_id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "taxons",
    "storageKey": "taxons(type:\"department\")",
    "args": [
      {
        "kind": "Literal",
        "name": "type",
        "value": "department",
        "type": "TaxonKind"
      }
    ],
    "concreteType": "Taxon",
    "plural": true,
    "selections": [
      v0,
      v1,
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "top_brands",
        "storageKey": null,
        "args": null,
        "concreteType": "Taxon",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "children",
        "storageKey": null,
        "args": null,
        "concreteType": "Taxon",
        "plural": true,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "children",
            "storageKey": null,
            "args": null,
            "concreteType": "Taxon",
            "plural": true,
            "selections": [
              v0,
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DepartmentsQuery",
  "id": null,
  "text": "query DepartmentsQuery {\n  taxons(type: department) {\n    id\n    name\n    ref_id\n    top_brands {\n      id\n      name\n      ref_id\n    }\n    children {\n      id\n      name\n      ref_id\n      children {\n        id\n        name\n        type\n        ref_id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DepartmentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "DepartmentsQuery",
    "argumentDefinitions": [],
    "selections": v3
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d1a751d3bfb93088728f50a79474583';
module.exports = node;

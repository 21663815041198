import { graphql } from 'relay-runtime';

const ClubQuery = graphql`
  query ClubQuery {
    clubs {
      ref_id
      name
      id
    }
  }
`;


export default ClubQuery;

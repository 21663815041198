import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '~/modules/ecommerceCoreUI/components/basic/tooltip';

const Link = props => (
  <a href={props.url || '#'} target={props.target} {...props}>
    {props.children}
  </a>
);
const ExternalLink = props => (props.title ? <Tooltip title={props.title}>{Link(props)}</Tooltip> : Link(props));

export default ExternalLink;

ExternalLink.defaultProps = {
  title: '',
};

ExternalLink.propTypes = {
  title: PropTypes.string,
};

Link.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
};

Link.defaultProps = {
  target: '',
};

/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
// import i18next from 'i18next';


import SectionsList from '~/modules/ecommerceCoreUI/containers/SectionsList';
// import MostPopular from '~/modules/ecommerceCoreUI/components/mostPopular/MostPopular';
// import NewArrivals from '~/modules/ecommerceCoreUI/components/newArrivals/NewArrivals';
import Brands from '~/modules/ecommerceCoreUI/components/brands/Brands';
import HomeMainSlider from '~/modules/ecommerceCoreUI/components/HomeMainSlider/HomeMainSlider';
import RecommendedSection from '~/modules/ecommerceHome/containers/recommended/RecommendedSection';
import FeaturedShops from '~/modules/ecommerceShops/components/featuredShops/FeaturedShops';
import AdLevel1Card from '~/modules/ecommerceCoreUI/components/advertisement/AdLevel1Card';
import AdLevel2Card from '~/modules/ecommerceCoreUI/components/advertisement/AdLevel2Card';
import AdSystem from '~/modules/ecommerceCoreUI/components/advertisement/AdSystem';
import HomeTracks from '~/modules/ecommerceCoreUI/components/homeTracks/HomeTracks';
import DailyDeals from '~/modules/ecommerceCoreUI/components/dailyDeals/DailyDeals';
import LuckyWheel from '~/modules/ecommerceCoreUI/components/luckyWheel/LuckyWheel';

class HomePage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render = () => (
    <React.Fragment>
      <HomeMainSlider />
      <AdSystem />
      <HomeTracks />
      <DailyDeals />
      <AdLevel1Card />
      {/* <MostPopular headerTitle={i18next.t('aykLayout:MostPopular.title')} headerSubtitle={i18next.t('aykLayout:MostPopular.subtitle')} /> */}
      <FeaturedShops />
      {/* <NewArrivals headerTitle={i18next.t('aykLayout:NewArrivals.title')} headerSubtitle={i18next.t('aykLayout:NewArrivals.subtitle')} /> */}
      <RecommendedSection />
      <SectionsList />
      <Brands />
      <AdLevel2Card />
      <LuckyWheel />
    </React.Fragment>
  );
}

export default withRouter(HomePage);

/* eslint-env browser */
/* eslint-disable */

import './global';

import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import { ThemeProvider } from 'emotion-theming';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-dropdown/style.css';
import { BrowserRouter as Router } from 'react-router-dom';

import '~/index.css';
import App from '~/App';
import {register} from '~/registerServiceWorker';
import i18nextLoader from '~/i18n'; // initialized i18next instance
import ModulesLoader from '~/modules/core/utils/modulesLoader';
import AlertProvider from '~/modules/core/utils/alertHelpers/AlertProvider';
import modulesConfig from '~/modulesConfig';
import RelayProvider from '~/modules/core/utils/relayHelpers/RelayProvider';
import Theme from '~/modules/ecommerceCore/Theme';
import MediaProvider from '~/modules/core/utils/mediaHelpers/MediaProvider';
import UserInfoProvider from '~/modules/core/utils/accessManagementHelpers/UserInfoProvider';
import UserSettingsLoader from '~/modules/core/utils/accessManagementHelpers/UserSettingsLoader';
import CartInfoProvider from '~/modules/core/utils/orderManagementHelpers/CartInfoProvider';
import CartInfoLoader from '~/modules/core/utils/orderManagementHelpers/CartInfoLoader';
import FirebaseProvider from '~/modules/ecommerceOrder/containers/notificationAndMessages/notifications/FirebaseProvider';
import { initializeGA, pageVisited } from '~/modules/ecommerceCore/utils/googleAnalytics';

import './styles/font-icons/font/el-icon.css';
import ErrorBoundaryComponent from './errorBoundaries/ErrorBoundaryComponent';
import ServerErrorContextProvider from './contextApi/contexts/serverErrorContext';

initializeGA();
pageVisited(window.location.pathname);

const renderApp = (modules, locale) => {
  if(locale === 'ar'){
    document.getElementById('root').classList.add('ar')
  }else{
    document.getElementById('root').classList.remove('ar')
  };
  ReactDOM.render(
    <ErrorBoundaryComponent>
      <ServerErrorContextProvider>
      <MediaProvider>
        <Router>
          <ThemeProvider theme={Theme}>
            <AlertProvider>
              <UserInfoProvider>
                <CartInfoProvider>
                  <RelayProvider locale={locale}>
                    <ModulesLoader.Context.Provider value={modules}>
                      <UserSettingsLoader>
                        <CartInfoLoader>
                          <FirebaseProvider>
                            <App />
                          </FirebaseProvider>
                        </CartInfoLoader>
                      </UserSettingsLoader>
                    </ModulesLoader.Context.Provider>
                  </RelayProvider>
                </CartInfoProvider>
              </UserInfoProvider>
            </AlertProvider>
          </ThemeProvider>
        </Router>
      </MediaProvider>
      </ServerErrorContextProvider>
    </ErrorBoundaryComponent>,
    document.getElementById('root'),
  );
  register();
};

i18nextLoader.load((err) => {
  if (err) throw err;
  /* 'http://localhost:4000',
  {
      Authorization: '',
  },
  */
  const modules = ModulesLoader.loadModules(modulesConfig);
  renderApp(modules, i18next.language);
  return null;
});

const StaticPageTrans = {
  En: {
    contactUs: {
      succeeded: {
        title: 'Your message sent successfully',
        subtitle: 'Thank you! we will contact you soon',
        back: 'Back to Home',
      },
      title: 'Contact Us',
      label: 'Feel free to contact us',
      inputs: {
        fullName: {
          label: 'Full Name',
          placeholder: 'Full Name',
        },
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        phoneNumber: {
          label: 'Mobile',
          placeholder: 'Mobile Number',
        },
        message: {
          label: 'Message',
          placeholder: 'Type your message',
        },
      },
      send: 'Submit',
      cancel: 'Cancel',
    },
    joinBusiness: {
      succeeded: {
        title: 'Your request sent successfully',
        subtitle: 'Thank you for choosing us! we will contact you soon',
        back: 'Back to Home',
      },
      title: 'Join as a Business',
      label: 'Join us, build your shop and get more tools for selling online',
      inputs: {
        title: {
          label: 'Business Name',
          placeholder: 'Business Name',
        },
        activityType: {
          label: 'Business Activities',
          placeholder: 'Select your activities',
        },
        otherActivityType: {
          label: 'Other Business Activities',
          placeholder: 'Add your activities',
        },
        country: {
          label: 'Country',
          placeholder: 'Country',
        },
        city: {
          label: 'City',
          placeholder: 'City',
        },
        address: {
          label: 'Address',
          placeholder: 'Address',
        },
        contactName: {
          label: 'Contact Person Name',
          placeholder: 'Contact Person Name',
        },
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        phoneNumber: {
          label: 'Mobile',
          placeholder: 'Mobile Number',
        },
        details: {
          label: 'Business Details',
          placeholder: 'Tell us about your business',
        },
      },
      send: 'Submit',
      cancel: 'Cancel',
    },
  },
  Ar: {
    contactUs: {
      succeeded: {
        title: 'تم إرسال رسالتك بنجاح',
        subtitle: 'شكرًا! سوف نتواصل معكم قريبًا',
        back: 'العودة للصفحة الرئيسية',
      },
      title: 'تواصل معنا',
      label: 'لا تتردد بالتواصل معنا',
      inputs: {
        fullName: {
          label: 'الاسم بالكامل',
          placeholder: 'الاسم بالكامل',
        },
        email: {
          label: 'البريد الإلكتروني',
          placeholder: 'البريد الإلكتروني',
        },
        phoneNumber: {
          label: 'الهاتف الجوال',
          placeholder: 'رقم الجوال',
        },
        message: {
          label: 'الرسالة',
          placeholder: 'اكتب رسالتك',
        },
      },
      send: 'تقديم',
      cancel: 'إلغاء',
    },
    joinBusiness: {
      succeeded: {
        title: 'تم إرسال طلبك بنجاح',
        subtitle: 'شكرا لاختيارنا! سوف نتواصل معكم قريبًا',
        back: 'العودة للصفحة الرئيسية',
      },
      title: 'الانضمام كعمل تجاري',
      label: 'انضم إلينا، وقم بإنشاء محلك، واحصل على المزيد من الأدوات للبيع عبر الإنترنت',
      inputs: {
        title: {
          label: 'الاسم التجاري',
          placeholder: 'الاسم التجاري',
        },
        activityType: {
          label: 'الأنشطة التجارية',
          placeholder: 'اختر أنشطتك',
        },
        otherActivityType: {
          label: 'أنشطة تجارية أخرى',
          placeholder: 'أضف أنشطتك',
        },
        country: {
          label: 'الدولة',
          placeholder: 'الدولة',
        },
        city: {
          label: 'المدينة',
          placeholder: 'المدينة',
        },
        address: {
          label: 'العنوان',
          placeholder: 'العنوان',
        },
        contactName: {
          label: 'اسم مسئول التواصل',
          placeholder: 'اسم مسئول التواصل',
        },
        email: {
          label: 'البريد الإلكتروني',
          placeholder: 'البريد الإلكتروني',
        },
        phoneNumber: {
          label: 'الهاتف الجوال',
          placeholder: 'رقم الجوال',
        },
        details: {
          label: 'تفاصيل العمل التجاري',
          placeholder: 'أخبرنا عن عملك التجاري',
        },
      },
      send: 'تقديم',
      cancel: 'إلغاء',
    },
  },
};

export default StaticPageTrans;

import React from 'react';
import PropTypes from 'prop-types';
import Shiitake from 'shiitake';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';


const Summary = ({ itemInfo }) => (
  <Row fullWidth>
    <Label sm>
      <Shiitake lines={2}>{itemInfo.summary}</Shiitake>
    </Label>
  </Row>
);

Summary.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default Summary;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import styled, { css } from 'react-emotion';

import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import TrackCard from '../../components/productsTrack/TrackCard';

const BrandsContainer = withMedia(styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${props => props.theme.new.spacer}px;
  grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
  ${props => props.media.maxTablet && css`
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  `}
`);

const BrandsCards = ({
  brandsInfo, departmentId, departmentName, vendorID, vendorName,
}) => (
  <BrandsContainer>
    {brandsInfo
      && brandsInfo.map(brand => (
        <TrackCard
          cardInfo={brand}
          id={brand.id}
          key={brand.id}
          departmentId={departmentId}
          departmentName={departmentName}
          vendorID={vendorID}
          vendorName={vendorName}
        />
      ))}
  </BrandsContainer>
);

BrandsCards.defaultProps = {
  vendorID: null,
  vendorName: null,
  brandsInfo: null,
};

BrandsCards.propTypes = {
  brandsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logo: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  departmentId: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  vendorID: PropTypes.string,
  vendorName: PropTypes.string,
};

export default withFragmentContainer(
  BrandsCards,
  graphql`
    fragment BrandsCards_brandsInfo on Taxon @relay(plural: true) {
      id
      ...TrackCard_cardInfo
    }
  `,
);

const ProductTracksTrans = {
  En: {
    brandsTitle: 'Brands',
    tabsTitle: 'Departments',
  },
  Ar: {
    brandsTitle: 'العلامات التجارية',
    tabsTitle: 'الأقسام',
  },
};
export default ProductTracksTrans;

/**
 * @flow
 * @relayHash c93fe13fb79e0db676ab5b3eb94c1ad4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MessagesList_queryResult$ref = any;
export type ThreadType = "in_progress" | "open" | "resolved" | "%future added value";
export type ThreadListInput = {
  status?: ?ThreadType,
  page?: ?PageInfoInput,
};
export type PageInfoInput = {
  limit?: ?number,
  offset?: ?number,
};
export type MessagesWrapperQueryVariables = {|
  input?: ?ThreadListInput
|};
export type MessagesWrapperQueryResponse = {|
  +$fragmentRefs: MessagesList_queryResult$ref
|};
export type MessagesWrapperQuery = {|
  variables: MessagesWrapperQueryVariables,
  response: MessagesWrapperQueryResponse,
|};
*/


/*
query MessagesWrapperQuery(
  $input: ThreadListInput
) {
  ...MessagesList_queryResult_2VV6jB
}

fragment MessagesList_queryResult_2VV6jB on Query {
  thread_list(input: $input) {
    threads_with_unread_messages_count
    threads {
      created_at
      created_at_str
      status
      status_string
      id
      ref_id
      number
      unread_messages_count
      messages {
        attachments
        attachments_object {
          name
          type
          url_original
          ref_id
          id
        }
        body
        created_at
        created_at_str
        id
        is_current_user_message
        ref_id
      }
      thread_topic {
        id
        ref_id
        title
      }
      vendor {
        logo {
          url_original
          name
        }
        ref_id
        id
        name
      }
    }
    page_info {
      ...PaginationData_pageInfo
    }
    errors {
      field
      messages
      code
    }
  }
}

fragment PaginationData_pageInfo on PageInfo {
  current_page
  items_count
  limit
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ThreadListInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created_at",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created_at_str",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url_original",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MessagesWrapperQuery",
  "id": null,
  "text": "query MessagesWrapperQuery(\n  $input: ThreadListInput\n) {\n  ...MessagesList_queryResult_2VV6jB\n}\n\nfragment MessagesList_queryResult_2VV6jB on Query {\n  thread_list(input: $input) {\n    threads_with_unread_messages_count\n    threads {\n      created_at\n      created_at_str\n      status\n      status_string\n      id\n      ref_id\n      number\n      unread_messages_count\n      messages {\n        attachments\n        attachments_object {\n          name\n          type\n          url_original\n          ref_id\n          id\n        }\n        body\n        created_at\n        created_at_str\n        id\n        is_current_user_message\n        ref_id\n      }\n      thread_topic {\n        id\n        ref_id\n        title\n      }\n      vendor {\n        logo {\n          url_original\n          name\n        }\n        ref_id\n        id\n        name\n      }\n    }\n    page_info {\n      ...PaginationData_pageInfo\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n\nfragment PaginationData_pageInfo on PageInfo {\n  current_page\n  items_count\n  limit\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MessagesWrapperQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "MessagesList_queryResult",
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MessagesWrapperQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thread_list",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "ThreadListInput"
          }
        ],
        "concreteType": "UserThreadInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "threads_with_unread_messages_count",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "threads",
            "storageKey": null,
            "args": null,
            "concreteType": "UserThread",
            "plural": true,
            "selections": [
              v1,
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status_string",
                "args": null,
                "storageKey": null
              },
              v3,
              v4,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "unread_messages_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "messages",
                "storageKey": null,
                "args": null,
                "concreteType": "UserMessage",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "attachments",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "attachments_object",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MessageAttachmentDetails",
                    "plural": true,
                    "selections": [
                      v5,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      v6,
                      v1,
                      v3
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "body",
                    "args": null,
                    "storageKey": null
                  },
                  v2,
                  v4,
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "is_current_user_message",
                    "args": null,
                    "storageKey": null
                  },
                  v1
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "thread_topic",
                "storageKey": null,
                "args": null,
                "concreteType": "UserThreadTopic",
                "plural": false,
                "selections": [
                  v3,
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "vendor",
                "storageKey": null,
                "args": null,
                "concreteType": "Shop",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "logo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BasicImage",
                    "plural": false,
                    "selections": [
                      v6,
                      v5
                    ]
                  },
                  v1,
                  v3,
                  v5
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "page_info",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "current_page",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "items_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "limit",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "errors",
            "storageKey": null,
            "args": null,
            "concreteType": "Error",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "field",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "messages",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "code",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d26037ebd47e129ec813e4ffada0454';
module.exports = node;

import i18next from 'i18next';
import RecommendedShopsAndProducts from './RecommendedShopsAndProducts';

const loadLocales = () => {
  i18next.addResourceBundle(
    'en',
    'ecommerceRecommended',
    {
      metadata: {
        name: 'ecommerceRecommended',
        displayName: 'recommended Module',
        description: 'This module is used to show recommended shops and products',
      },
      RecommendedShopsAndProducts: {
        ...RecommendedShopsAndProducts.En,
      },
    },
    true,
    true,
  );

  i18next.addResourceBundle(
    'ar',
    'ecommerceRecommended',
    {
      metadata: {
        name: 'ecommerceRecommended',
        displayName: 'recommended Module',
        description: 'This module is used to show recommended shops and products',
      },
      home: {
        menuTitle: 'recommended shops and products',
      },
      RecommendedShopsAndProducts: {
        ...RecommendedShopsAndProducts.Ar,
      },
    },
    true,
    true,
  );

  i18next.loadNamespaces('ecommerceRecommended');
};

export default loadLocales;

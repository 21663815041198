import { graphql } from 'relay-runtime';

import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';

const MarkSeenMutation = graphql`
  mutation commitSeenMutationMutation {
    mark_notifications_seen {
      errors {
        field
        messages
        code
      }
    }
  }
`;

export default (env, onCompletion) => {
  commitMutation(
    env,
    MarkSeenMutation,
    'mark_notifications_seen',
    null,
    onCompletion,
    onCompletion,
    () => {},
  );
};

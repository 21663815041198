/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Product_productInfo$ref: FragmentReference;
export type Product_productInfo = {|
  +id: string,
  +slug: ?string,
  +vendor_variants_count: ?number,
  +min_variant: ?{|
    +vendor: ?{|
      +name: ?string,
      +ref_id: string,
    |},
    +slug: ?string,
    +name: string,
    +ref_id: string,
    +root_variant_id: ?string,
    +discount_expire_at: ?any,
    +remaining_usage_percentage: ?number,
    +total_on_hand: ?number,
    +price: number,
    +original_price: number,
  |},
  +ref_id: string,
  +images: $ReadOnlyArray<?string>,
  +name: string,
  +avg_rating: ?number,
  +rating_count: ?number,
  +price: ?number,
  +original_price: ?number,
  +discount: ?number,
  +has_discount: ?boolean,
  +has_offer: ?boolean,
  +hot_deal: ?boolean,
  +wish_listed: ?boolean,
  +root_variant: ?{|
    +root_variant_id: ?string,
    +ref_id: string,
    +wish_listed: ?boolean,
  |},
  +variants: ?$ReadOnlyArray<?{|
    +id: string,
    +ref_id: string,
    +option_values: ?$ReadOnlyArray<?{|
      +id: string,
      +ref_id: string,
      +option_type_name: ?string,
    |}>,
  |}>,
  +related_products: ?$ReadOnlyArray<?{|
    +images: $ReadOnlyArray<?string>,
    +name: string,
  |}>,
  +gift_image: ?{|
    +url_large: string,
    +url_original: string,
  |},
  +gift_text: ?string,
  +gift_included: ?boolean,
  +$refType: Product_productInfo$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "root_variant_id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original_price",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "images",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Product_productInfo",
  "type": "Product",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "vendor_id",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "discount",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "vendor_variants_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "min_variant",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "vendor_id",
          "variableName": "vendor_id",
          "type": "ID"
        }
      ],
      "concreteType": "Variant",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "vendor",
          "storageKey": null,
          "args": null,
          "concreteType": "Shop",
          "plural": false,
          "selections": [
            v1,
            v2
          ]
        },
        v3,
        v1,
        v2,
        v4,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "discount_expire_at",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "remaining_usage_percentage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total_on_hand",
          "args": null,
          "storageKey": null
        },
        v5,
        v6
      ]
    },
    v2,
    v7,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avg_rating",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rating_count",
      "args": null,
      "storageKey": null
    },
    v5,
    v6,
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "has_discount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "has_offer",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hot_deal",
      "args": null,
      "storageKey": null
    },
    v8,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "root_variant",
      "storageKey": null,
      "args": null,
      "concreteType": "Variant",
      "plural": false,
      "selections": [
        v4,
        v2,
        v8
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "variants",
      "storageKey": null,
      "args": null,
      "concreteType": "Variant",
      "plural": true,
      "selections": [
        v0,
        v2,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "option_values",
          "storageKey": null,
          "args": null,
          "concreteType": "OptionValue",
          "plural": true,
          "selections": [
            v0,
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "option_type_name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "related_products",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": true,
      "selections": [
        v7,
        v1
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "gift_image",
      "storageKey": null,
      "args": null,
      "concreteType": "ImageAttachment",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url_large",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url_original",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gift_text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gift_included",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '07f454f295008e74c15a4b41687b6f83';
module.exports = node;

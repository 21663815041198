import React from 'react';
import PropTypes from 'prop-types';
import { ItemOptions, ItemOption } from '~/modules/ecommerceOrder/components/ItemOptions';

const ItemOptionsRender = ({ itemInfo }) => (
  <React.Fragment>
    {itemInfo.option_values && (
      <ItemOptions spaceBetween={1}>
        {itemInfo.option_values.map(option => (
          <ItemOption key={option.id}>{option.presentation}</ItemOption>
        ))}
      </ItemOptions>
    )}
  </React.Fragment>
);

ItemOptionsRender.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default ItemOptionsRender;

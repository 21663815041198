/* eslint-disable import/prefer-default-export */
import styled from 'react-emotion';
import { css } from 'emotion';

import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const getColor = (props) => {
  if (props.color) {
    if (props.theme.colors.labels[props.color]) {
      return props.theme.colors.labels[props.color];
    }
    return props.color;
  }
  return props.theme.colors.labels.normal;
};

export const Label = withDirection(styled.span`
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  color: ${props => getColor(props)};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  line-height: ${props => (props.paragraph ? 1.3 : 1)};

  text-align: ${props => props.align
    || (props.direction === 'rtl'
      ? css`
          right;
        `
      : css`
          left;
        `)};

  a {
    color: ${props => props.theme.colors.link};
  }

  ${props => props.customStyle && props.customStyle(props)};
`);

export const XXXLargeLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xxxLarge}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const XXLargeLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xxLarge}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const XLargeLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xLarge}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const LargeLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.large}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const MediumLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.medium}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const SmallLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.small}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const XSmallLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xSmall}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const XXSmallLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xxSmall}px;

  ${props => props.customStyle && props.customStyle(props)};
`;
export const XXXSmallLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xxxSmall}px;

  ${props => props.customStyle && props.customStyle(props)};
`;

export const ErrorLabel = styled.div`
  font-size: ${props => props.theme.fonts.sizes.xxSmall}px;
  color: ${props => props.theme.colors.error};

  ${props => props.customStyle && props.customStyle(props)};
`;

import React from 'react';
import PropTypes from 'prop-types';

import ItemCard from '~/modules/coreUI/components/itemCard';
import withVariantData from '~/modules/ecommerceCoreUI/containers/VariantData';

const Variant = ({
  variantInfo, isVertical, isAd, variantVendorInfo, isVendorProduct, isHotDeal,
}) => (
  <ItemCard
    isVariant
    isAd={isAd}
    isVertical={isVertical}
    itemInfo={variantInfo}
    isVendorProduct={isVendorProduct}
    vendorInfo={variantVendorInfo}
    isHotDeal={isHotDeal}
  />
);

Variant.propTypes = {
  variantInfo: PropTypes.shape({}).isRequired,
  variantVendorInfo: PropTypes.shape({
    vendorID: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  isVertical: PropTypes.bool,
  isHotDeal: PropTypes.bool,
  isAd: PropTypes.bool,
  isVendorProduct: PropTypes.bool,
};

Variant.defaultProps = {
  isVertical: true,
  isAd: false,
  isHotDeal: false,
  isVendorProduct: false,
  variantVendorInfo: {},
};

export default withVariantData(Variant);

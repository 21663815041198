/* eslint-disable indent */
import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import ReactTooltip from 'react-tooltip';
import i18next from 'i18next';
import { Container } from 'semantic-ui-react';
import { graphql } from 'react-relay';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';
import { SectionHeader } from '~/modules/ecommerceCoreUI/components/basic/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { ErrorLabel } from '~/modules/coreUI/components/legacies/Labels';
import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import { currentLocale } from '~/modules/ecommerceCore/utils/changeLanguage';

const FormMutation = graphql`
  mutation NewsletterMutation($email: String!) {
    newsletter_subscribe(email: $email) {
      errors {
        field
        messages
        code
      }
    }
  }
`;

const NewsletterWrapper = styled.div`
  position: relative;
  .InputLayout {
    width: 100% !important;
  }
  .form-group.form-group-depth-1.form-group-email {
    width: 50%;
    position: relative;
    ${cssMediaMax.tablet`
      width: 100%;
    `}
  }
`;

const SectionHeaderContent = styled(SectionHeader)`
  ${cssMediaMax.tablet`
     display: block;
     text-align: center;
     font-size: 24px;
  `};
`;

const NORMAL_ICON = 'icon-send';
const SUCCESS_ICON = 'fas fa-check';

class Newsletter extends Component {
  state = {
    panelError: null,
    isLoading: false,
    currentIcon: NORMAL_ICON,
    currentIconSize: this.props.theme.inputs.fontSize + 4,
  };

  onSuccess = () => {
    const { updateUserInfoTempPartial, theme } = this.props;

    this.setState({
      currentIcon: SUCCESS_ICON,
      currentIconSize: theme.inputs.fontSize + 6,
    });

    this.props.notifyAlert({
      messageText: i18next.t('accountManagement:Login.success'),
      type: AlertTypes.success,
      opacity: 1,
      toastID: 'Avatar_success_toast',
    });

    setTimeout(() => {
      this.setState({
        currentIcon: NORMAL_ICON,
        currentIconSize: theme.inputs.fontSize + 4,
        panelError: '',
      });
      this.form.resetForm();
    }, 2000);

    if (!this.form) {
      return;
    }

    updateUserInfoTempPartial({
      email: this.form.getValue().email,
    });
  };

  onError = (error) => {
    if (error && error.generalErrors) {
      if (error.generalErrors.Email === 'Invalid Email Format.' && currentLocale() === 'en') {
        this.setState({ panelError: 'Please enter a valid email!' });
      } else if (error.generalErrors.Email === 'Invalid Email Format.' && currentLocale() === 'ar') {
        this.setState({ panelError: 'يرجى إدخال بريد إلكتروني صحيح!' });
      }
    }
  };

  setLoadingState = (isLoading) => {
    this.setState({ isLoading });
  };

  submitForm = () => {
    if ((this.form.getValue() && this.form.getValue().email) === null) {
      this.setState({ panelError: '' });
    }
    this.form.submitForm();
  };

  render() {
    const { isLoading, panelError } = this.state;
    return (
      <NewsletterWrapper>
        <Container>
          <ReactTooltip />
          <SectionHeaderContent>
            {i18next.t('ecommerceCoreUI:Newsletter.title')}
          </SectionHeaderContent>
          <ErrorLabel>{panelError}</ErrorLabel>
          <LargeSpacer />
          <RelayForm
            onRef={(ref) => {
              this.form = ref;
            }}
            onFormError={error => this.onError(error)}
            onFormSuccess={response => this.onSuccess(response)}
            onFormLoading={loading => this.setLoadingState(loading)}
            mutationRoot="newsletter_subscribe"
            options={{
              initialFormValue: {
                email: '',
              },
              fields: [
                {
                  name: 'email',
                  displayName: i18next.t('accountManagement:Profile.email'),
                  placeholder: i18next.t('ecommerceCoreUI:ToolTipTrans.newsletterPlaceHolder'),
                  input_type: 'textbox',
                  type: 'RequiredString',
                  icon: this.state.currentIcon,
                  onIconClick: () => this.submitForm(),
                  iconTitle: i18next.t('ecommerceCoreUI:ToolTipTrans.newsletterButton'),
                  iconDisabled: isLoading,
                  iconPosition: 'right',
                  iconFontSize: this.state.currentIconSize,
                },
              ],
            }}
            mutation={FormMutation}
          />
        </Container>
      </NewsletterWrapper>
    );
  }
}
Newsletter.propTypes = {
  updateUserInfoTempPartial: PropTypes.func.isRequired,
  notifyAlert: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    inputs: PropTypes.shape({
      fontSize: PropTypes.number,
    }),
  }).isRequired,
};

export default withUserInfo(withDirection(withTheme(withAlertMsg(Newsletter))));

import React from 'react';
import PropTypes from 'prop-types';
import AykPoints from './AykPoints';
import EarnPoints from './EarnPoints';

const AllPoints = ({ queryResult }) => (
  <div>
    <AykPoints queryResult={queryResult} />
    <EarnPoints />
  </div>
);

AllPoints.propTypes = {
  queryResult: PropTypes.shape({}),
};

AllPoints.defaultProps = {
  queryResult: [],
};

export default AllPoints;

/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import ItemCard from '~/modules/coreUI/components/itemCard';
import ItemsCardsSlider from '~/modules/ecommerceCoreUI/components/basic/ItemsCardsSlider';

const HotDeals = ({ hotDeals }) => (
  <React.Fragment>
    {hotDeals && hotDeals.length > 3 && (
      <ItemsCardsSlider>
        {hotDeals.map(variantInfo => (
          <ItemCard
            key={variantInfo.id}
            itemInfo={variantInfo}
            isVertical
            isHotDeal
          />
        ))
        }
      </ItemsCardsSlider>
    )}
  </React.Fragment>
);

HotDeals.propTypes = {
  hotDeals: PropTypes.arrayOf(PropTypes.any),
};

HotDeals.defaultProps = {
  hotDeals: null,
};

export default HotDeals;

import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';

const FormMutation = graphql`
  mutation VerifyAccountFormMutation($confirmation_token: String!) {
    confirm_email(confirmation_token: $confirmation_token) {
      token
      client_id
      expiry
      user {
        ...WithUserData_user @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;

class VerifyAccountForm extends React.Component {
  submitForm = () => {
    this.form.submitForm();
  };

  render = () => {
    const { onFormError, onFormSuccess, onFormLoading } = this.props;

    return (
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={onFormError}
        onFormSuccess={onFormSuccess}
        onFormLoading={onFormLoading}
        mutationRoot="confirm_email"
        options={{
          fields: [
            {
              name: 'confirmation_token',
              placeholder: i18next.t('accountManagement:verifyAccount.PlaceholderTxt'),
              displayName: i18next.t('accountManagement:verifyAccount.confirmationCodeText'),
              input_type: 'textbox',
              type: 'RequiredString',
            },
          ],
        }}
        mutation={FormMutation}
      />
    );
  };
}

VerifyAccountForm.propTypes = PropTypes.shape({
  onFormError: PropTypes.func.isRequired,
  onFormSuccess: PropTypes.func.isRequired,
  onFormLoading: PropTypes.func.isRequired,
}).isRequired;

export default VerifyAccountForm;

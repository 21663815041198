import React from 'react';
import styled from 'react-emotion';
import { Container } from 'semantic-ui-react';
import i18next from 'i18next';

const CopyrightWrapper = styled.div`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.named.white};
  height: 55px;
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts.sizes.small}px;
  @media only screen and (min-width: 992px) and (max-width: 1199px){
    .paddingFooter{
      padding: 0 ${props => props.theme.paddings.large}px !important;
    }
  }
`;

const Copyright = () => (
  <CopyrightWrapper>
    <Container className="paddingFooter">{i18next.t('ecommerceCoreUI:Footer.copyrights')}</Container>
  </CopyrightWrapper>
);

export default Copyright;

import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Box, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const OfferLabelContainer = withMedia(styled(Box)`
  width: ${props => 5.8 * props.theme.new.spacer}px;
  height: ${props => 5 * props.theme.new.spacer}px;
  ${props => props.media.xsmall && css`
    width: ${3.8 * props.theme.new.spacer}px;
    height: ${3.3 * props.theme.new.spacer}px;
  `};

  img {
    max-width: 100%;
  }
`);

const OfferLabelText = styled(Label)`
  text-align: center;
  text-transform: uppercase;
`;

const OfferLabelBottomText = styled(Label)`
  white-space: nowrap;
`;

const OfferLabel = ({ text, offer }) => (
  <Column centerAligned spaceBetween={0.4}>
    <OfferLabelContainer p={0.5} centerAligned centerJustified bordered borderRadius="small">
      <OfferLabelText semiBold size={['xs', 'sm']}>{offer}</OfferLabelText>
    </OfferLabelContainer>
    <OfferLabelBottomText subtle xxs>{text}</OfferLabelBottomText>
  </Column>
);

OfferLabel.propTypes = PropTypes.shape({
  text: PropTypes.string.isRequired,
  offer: PropTypes.element.isRequired,
}).isRequired;

export default OfferLabel;

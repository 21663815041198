import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import SectionHeader from '~/modules/coreUI/components/layouts/SectionHeader';
import { Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const DropdownHeaderLabelStyle = props => css`
  font-size: ${props.theme.new.fonts.sizes.lg}px;
  color: ${props.theme.new.colors.labels.normal.important};
`;
const DropdownSubHeaderLabelStyle = props => css`
  font-size: ${props.theme.new.fonts.sizes.md}px;
`;
const DropdownHeaderStyle = () => css`
  align-items: flex-start;
`;

const DropdownHeader = props => (
  <SectionHeader
    {...props}
    headerStyle={DropdownHeaderStyle}
    headerLabelStyle={DropdownHeaderLabelStyle}
    subHeaderLabelStyle={DropdownSubHeaderLabelStyle}
    emphasizedTextStyle={DropdownSubHeaderLabelStyle}
  />
);

const CartPageHeader = ({ title, subtitle, isDropdown }) => (isDropdown ? (
  <Box fullWidth bottomBordered leftAligned paddingBottom={2} borderRadius="0">
    <DropdownHeader title={title} subtitle={subtitle} />
  </Box>
) : (
  <SectionHeader title={title} subtitle={subtitle} />
));

CartPageHeader.defaultProps = {
  isDropdown: false,
};

CartPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isDropdown: PropTypes.bool,
};

export default CartPageHeader;

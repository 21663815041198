import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import Step from './Step';

const ProgressBar = props => (
  <Row
    stretchAligned
    stretchJustified
    fullWidth
    stepsNumber={props.steps.length}
  >
    {props.steps.map((step, index) => (
      <Step
        {...step}
        labelPosition={props.labelPosition}
        stepsPosition={props.stepsPosition}
        endMarker={props.endMarker}
        key={step.label}
        index={index}
        isLastStep={index === props.steps.length - 1}
        isFirstStep={index === 0}
        currentStep={props.steps[index]}
        nextStep={props.steps[index + 1]}
      />
    ))}
  </Row>
);

ProgressBar.defaultProps = {
  labelPosition: 'top',
  endMarker: null,
  stepsPosition: 'end',
};

ProgressBar.propTypes = {
  labelPosition: PropTypes.oneOf(['top', 'bottom']),
  stepsPosition: PropTypes.oneOf(['start', 'center', 'end']),
  endMarker: PropTypes.oneOf(['ellipse']),
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ProgressBar;

/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { mediaSizesMax, cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import SlickSlider from '~/modules/ecommerceCoreUI/components/slickSlider/SlickSlider';

const SliderWrapper = styled.div`
  background: #fff;
  height: ${props => 46.4 * props.theme.new.spacer}px;
  ${props => cssMediaMax.tablet`
    height: ${32.5 * props.theme.new.spacer}px;
  `}
  ${props => cssMediaMax.xsmall`
    height: ${19 * props.theme.new.spacer}px;
  `};
  .slick-slider {
    padding-bottom: 50px;
    height: 100%;
    .slick-list {
      height: 100%;
    }
    .slick-slide {
      width: ${props => 140 * props.theme.new.spacer}px;
      height: 100%;
      opacity: 0.5;
      float: left !important;
    }
    .slick-slide.slick-center.slick-active {
      opacity: 1;
    }
    .slick-slide img {
      width: 100%;
    }
    .slick-dots {
      margin: 20px 0;
      bottom: 0;
    }
    ${props => cssMediaMax.desktop`
      .slick-slide {
        width: ${120 * props.theme.new.spacer}px;
      }
    `}
    ${props => cssMediaMax.smallDesktop`
      .slick-slide {
        width: ${110 * props.theme.new.spacer}px;
      }
    `}
    ${cssMediaMax.tablet`
      .slick-slide {
        opacity: 1;
      }
    `}
  }
`;

const HomeSlider = ({ childrenCount, children }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    useTransform: false,
    centerMode: childrenCount.length === 1 ? false : true,
    dots: true,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: mediaSizesMax.tablet,
        settings: {
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
  };
  return (
    <SliderWrapper>
      <SlickSlider settings={settings}>{children}</SlickSlider>
    </SliderWrapper>
  );
};

export default HomeSlider;

HomeSlider.propTypes = {
  children: PropTypes.node.isRequired,
  childrenCount: PropTypes.arrayOf(PropTypes.any).isRequired,
};

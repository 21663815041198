import i18next from 'i18next';
import ToolTipTrans from './tooltip';

const loadLocales = () => {
  i18next.addResourceBundle(
    'en',
    'coreUI',
    {
      metadata: {
        name: 'coreUI',
        displayName: 'Core Ui Module',
        description: 'The most common and basic UI elements',
      },
      home: {
        menuTitle: 'Core Ui',
      },
      ToolTipTrans: {
        ...ToolTipTrans.En,
      },
      inputErrors: {
        missing: 'is missing!',
        shortPassword: 'should be at least 6 characters!',
        longPassword: 'should be at most 20 characters!',
        countryCode: 'should be at least 3 and at most 255 characters!',
        stringOnly: 'should be string!',
        notNumber: 'isn\'t number!',
        containNumber: 'should be 8 digits!',
        containNumberRange: 'should be from 6 to 12 digits!',
        notValid: 'is invalid!',
        stateMissing: 'is required!',
        countryMissing: 'is required!',
      },
      currency: {
        QAR: 'QAR',
        USD: 'USD',
        SAR: 'SAR',
        EGP: 'EGP',
      },
      male: 'Male',
      female: 'Female',
      search: 'Search...',
      select: 'Select',
    },
    true,
    true,
  );
  i18next.addResourceBundle(
    'ar',
    'coreUI',
    {
      metadata: {
        name: 'coreUI',
        displayName: 'Core Ui Module',
        description: 'The most common and basic UI elements',
      },
      home: {
        menuTitle: 'Core Ui',
      },
      ToolTipTrans: {
        ...ToolTipTrans.Ar,
      },
      search: 'بحث...',
      select: 'اختر',
      inputErrors: {
        missing: 'غير موجود!',
        shortPassword: 'يجب أن لا تقل عن ٦ أحرف!',
        longPassword: 'يجب أن لا تزيد عن ٢٠ حرفًا!',
        numberCount: 'يجب أن لا يقل عن ٣ أحرف وأن لا يزيد عن ٢٥٥ حرفًا!',
        stringOnly: 'يجب أن يكون حروفًا!',
        notNumber: 'ليس رقمًا!',
        containNumber: 'يجب أن يكون ٨ أرقام!',
        containNumberRange: 'يجب أن يكون من ٦ إلى ١٢ رقمًا!',
        notValid: 'غير صالح!',
        stateMissing: 'مطلوبة!',
        countryMissing: 'مطلوبة!',
      },
      currency: {
        QAR: 'ر.ق.',
        USD: 'دولار',
        SAR: 'ر.س.',
        EGP: 'جنيه',
      },
      male: 'ذكر',
      female: 'أنثي',
    },
    true,
    true,
  );

  i18next.loadNamespaces('coreUI');
};

export default loadLocales;

const VerificationTrans = {
  En: {
    HeaderTitle: 'Verify Your Account',
    Resendit: 'Resend It',
    Didreceivethecodeyet: ' Didn\'t receive the code yet?',
    VerifyBySMS: ' By SMS  ',
    VerifyByMail: 'By E-Mail ',
    SubTitle: ' Necessary step to active your account',
    PlaceholderTxt: 'Add Code Here',
    confirmationCodeText: 'Verification Code',
    SendSuccessfully: 'Sent Successfully',
    Select: 'Select',
    verifyParagraph:
      ' Activate your account by one of these methods, and enjoy shopping with us',
    VerifyEmailTxt:
      'You will receive an email with a verification code.',
    VerifySMSTxt:
      'You will receive a message on your mobile with a verification code.',
    footerTxt: 'Already have an account? ',
    footerLink: 'Log In',
    ButtonCreateAccount: 'Verify Your Account',
    SMSTxt: 'The verification code has been sent to your mobile, check your messages',
    EmailTxt: 'The verification code has been sent to your email, check your inbox',
    emailError: 'Email not found, go back and re-enter your email or contact Help Center',
    sending: 'Sending...',
  },
  Ar: {
    HeaderTitle: 'التحقق من حسابك',
    Resendit: 'أرسله مرة أخرى',
    Didreceivethecodeyet: ' لم تستلم الرمز بعد؟',
    VerifyBySMS: ' بالرسائل القصيرة ',
    VerifyByMail: ' بالبريد الإلكتروني ',
    SubTitle: ' خطوة ضرورية لتفعيل حسابك',
    PlaceholderTxt: 'أضف الرمز هنا',
    SendSuccessfully: 'تم الإرسال بنجاح',
    Select: 'اختر',
    verifyParagraph: ' قم بتفعيل حسابك بإحدى هذه الطرق، واستمتع بالتسوق معنا',
    VerifyEmailTxt: 'ستصلك رسالة بريد إلكتروني مع رمز التحقق.',
    VerifySMSTxt: 'ستصلك رسالة على هاتفك الجوال مع رمز التحقق.',
    footerTxt: 'هل لديك حساب؟',
    confirmationCodeText: 'شيفرة التأكيد',
    footerLink: 'تسجيل الدخول',
    ButtonCreateAccount: 'التحقق من حسابك',
    SMSTxt: 'تم إرسال رمز التحقق إلى هاتفك الجوال، يرجى مراجعة الرسائل',
    EmailTxt: 'تم إرسال رمز التحقق إلى بريدك الإلكتروني، يرجى مراجعة البريد الوارد',
    emailError: 'لم يتم العثور على البريد الإلكتروني، يرجى إعادة إدخال بريدك الإلكتروني أو التواصل مع مركز المساعدة',
    sending: 'إرسال...',
  },
};
export default VerificationTrans;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';

function LongMessagesError({
  warnings,
  title,
  iconClass,
}) {
  let errors = warnings
    .filter(warning => !warning.field?.includes('short_message'))
    .map(warning => warning);

  errors = _.flatten(_.flattenDeep(errors).map(error => error.messages || error));

  return errors.length > 0 && (
    <PanelAlert
      type="error"
      iconClass={iconClass}
      inverted
      title={title}
      errors={errors}
    />
  );
}

LongMessagesError.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.shape({})]),
      ),
    }),
  ).isRequired,
};

export default LongMessagesError;

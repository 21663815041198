import React from 'react';
import PropTypes from 'prop-types';
import ReactDropDownAutoComplete from '~/modules/coreUI/components/basic/autoCompleteDropdown';
import SearchCard from '~/modules/ecommerceCoreUI/components/SearchCard/SearchCard';
import styled from 'react-emotion';
import { css } from 'emotion';
import { Interpolate, translate } from 'react-i18next';
import i18next from 'i18next';
import { MediumLabel } from '~/modules/coreUI/components/legacies/Labels';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import SearchCardLoader from '~/modules/ecommerceCoreUI/components/SearchCard/SearchCardLoader';
import SearchResultButton from './SearchResultButton';

const DropdownAutoCompleteStyled = styled(ReactDropDownAutoComplete)`
  &&&&& {
    height: 40px;
    flex-grow: 4;
    ${cssMediaMax.xsmall`
      border: none;
    `};
  }
`;
const Style = withDirection(styled.div`
  height: 40px;
  flex-grow: 4;

  ${cssMediaMax.xsmall`
    width: 100%;
    flex-grow: 1;
    border: 0px;
  `}
  .input-field {
    ${props => props.isLoading
      && css`
        pointer-events: none;
      `}
  }
  .input-field ::-ms-clear {
    display: none;
  }
  .autocomplete-field.has-icon input[type="text"]{
    border-radius: 0;
    ${props => (props.direction === 'rtl'
    ? css`
        padding-left: ${props.theme.new.spacer * 4}px;
        padding-right: 10px;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: rgba(0,0,0,0.1);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-left: none;
      `
    : css`
        padding-right: ${props.theme.new.spacer * 4}px;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: rgba(0,0,0,0.1);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: none;

    `)}
  }
  .ui.top.left.pointing.dropdown>.menu .ui.dropdown .menu>.item {
    ${props => (props.direction === 'rtl'
    && css`
      text-align: right;
    `)}
  }
  .autocomplete-field .autocomplete-list {
    top:43px;
    border-radius: ${props => props.theme.borders.radius.normal}px;
    box-shadow: none;
  }

  .autocomplete-field .autocomplete-list.show{
    display: table;
    max-height: fit-content;
  }

  .autocomplete-field .autocomplete-list > div {
    padding: 0px;
    border: 0px;
    background: transparent;
  }
  .autocomplete-field .autocomplete-list > div.isFocus {
    background: ${props => props.theme.borders.color.light};
  }

  .autocomplete-field .autocomplete-list > div > div {
    margin-left: ${props => props.theme.paddings.large}px;
    margin-right: ${props => props.theme.paddings.large}px;
    border-bottom-width: ${props => props.theme.borders.size.thin}px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.borders.color.light};
  }

  .autocomplete-field .icon-search {
    height: 40px;
    width: 40px;
    color: ${props => props.theme.borders.color.normal};
    ${props => cssMediaMax.tablet`
      color: ${props.theme.colors.named.white};
      background-color: ${props.theme.colors.primary};
      border: 0px;
    `}
    ${props => (props.direction === 'rtl'
    ? css`
        left: 0;
        right: auto;
        border-bottom-left-radius: ${props.theme.borders.radius.normal}px;
        border-top-left-radius: ${props.theme.borders.radius.normal}px;
        border-right-width: ${props.theme.borders.size.thin}px;
        border-right-style: solid;
        border-right-color: ${props.theme.borders.color.light};
      `
    : css`
        border-bottom-right-radius: ${props.theme.borders.radius.normal}px;
        border-top-right-radius: ${props.theme.borders.radius.normal}px;
        border-left-width: ${props.theme.borders.size.thin}px;
        border-left-style: solid;
        border-left-color: ${props.theme.borders.color.light};
    `)}
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fa-search:before {
    content: "\f002";
    position: relative;
    top: 7px;
  }
  .form-control {
    outline: none;
    margin: 0px;
    border: 0px;
    ${props => (props.direction === 'rtl'
    ? css`
        padding-right: ${props.theme.paddings.medium}px;
        border-left-width: ${props.theme.borders.size.thin}px;
        border-left-style: solid;
        border-left-color: ${props.theme.borders.color.light};
        border-bottom-right-radius: ${props.theme.borders.radius.normal}px;
        border-top-right-radius: ${props.theme.borders.radius.normal}px;
      `
    : css`
        padding-left: ${props.theme.paddings.medium}px;
        border-right-width: ${props.theme.borders.size.thin}px;
        border-right-style: solid;
        border-right-color: ${props.theme.borders.color.light};
        border-bottom-left-radius: ${props.theme.borders.radius.normal}px;
        border-top-left-radius: ${props.theme.borders.radius.normal}px;
    `)}
  }
  .autocomplete-field .autocomplete-list > div:last-child:hover {
    background: transparent;
    cursor: default;
  }
  .autocomplete-field .autocomplete-list > div:last-child.isFocus {
    background: transparent;
  }
`);

const LabelStyle = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.paddings.large}px;
`;

const NoResultContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: #f9f9f9;
  border-bottom-width: 0px !important;
  border-radius: 5px;
  cursor: default;
`;
const NoResultLabel = styled(MediumLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  cursor: default;
`;

class SearchDropDown extends React.Component {
  static getDerivedStateFromProps(nextProps, currentState) {
    if (nextProps.searchString !== currentState.prevSearchString) {
      if (nextProps.search) {
        nextProps.search(nextProps.searchStringl);
      }

      return {
        editField: nextProps.searchString,
        prevSearchString: nextProps.searchString,
      };
    }

    return null;
  }

  state = {
    editField: this.props.searchString,
    prevSearchString: this.props.searchString, // eslint-disable-line react/no-unused-state
  };

  getElement = item => (
    <React.Fragment>
      {item.id === 'button-displayed' ? (
        <SearchResultButton
          onClicked={() => this.props.onButtonClick(this.state.editField)}
          itemsCount={this.props.itemsCount}
        />
      ) : (
        <SearchCard
          onClick={() => this.props.onItemClick(item)}
          name={item.name}
          image={item.image}
          price={item.price}
        />
      )}
    </React.Fragment>
  );

  getEditFieldValue = () => this.state.editField || '';

  render = () => (
    <Style isLoading={this.props.isLoading}>
      <DropdownAutoCompleteStyled
        getItemValue={this.getEditFieldValue}
        className="form-control"
        id="name"
        name="name"
        placeholder={i18next.t('ecommerceCoreUI:QuickSearch.Placeholder')}
        data={
          this.props.listResult ? this.props.listResult.concat([{ id: 'button-displayed' }]) : []
        }
        renderItem={item => (
          <div>
            {this.props.isLoading ? (
              <React.Fragment>
                <SearchCardLoader />
                <SearchCardLoader />
                <SearchCardLoader />
                <SearchCardLoader />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.state.editField.length > 1 && (
                  <React.Fragment>
                    {this.props.itemsCount < 1 ? (
                      <NoResultContainer>
                        <LabelStyle>
                          <NoResultLabel>
                            <Interpolate
                              i18nKey="QuickSearch.NoResult"
                              editField={this.state.editField}
                            />
                          </NoResultLabel>
                        </LabelStyle>
                      </NoResultContainer>
                    ) : (
                      this.getElement(item)
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        )}
        icon="search"
        iconClick={() => this.props.iconClick(this.state.editField)}
        value={this.state.editField}
        onChange={(val) => {
          // WORKAROUND : We had to do this, since we need an immediate change to take effect
          // So that the method, getEditFieldValue, could see the right value
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.editField = val;
          this.setState({ editField: val });
          if (val.length > 1) {
            this.props.search(val);
          }
        }}
        onEnter={val => (val.slug || val.id ? this.props.onEnter(val) : this.props.onEnter(this.state.editField))
        }
      />
    </Style>
  );
}

SearchDropDown.propTypes = {
  listResult: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  iconClick: PropTypes.func.isRequired,
  itemsCount: PropTypes.string.isRequired,
}.isRequired;

export default translate('ecommerceCoreUI')(SearchDropDown);

/**
 * @flow
 * @relayHash 89bd7e482de145c0f9bda89874151e87
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VerifyAccountFormMutationVariables = {|
  confirmation_token: string
|};
export type VerifyAccountFormMutationResponse = {|
  +confirm_email: ?{|
    +token: ?string,
    +client_id: ?string,
    +expiry: ?string,
    +user: ?{|
      +id: string,
      +ref_id: string,
      +first_name: ?string,
      +last_name: ?string,
      +email: ?string,
      +verified: ?boolean,
      +avatar: ?string,
      +default_lang: ?string,
      +default_currency: ?string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type VerifyAccountFormMutation = {|
  variables: VerifyAccountFormMutationVariables,
  response: VerifyAccountFormMutationResponse,
|};
*/


/*
mutation VerifyAccountFormMutation(
  $confirmation_token: String!
) {
  confirm_email(confirmation_token: $confirmation_token) {
    token
    client_id
    expiry
    user {
      id
      ref_id
      first_name
      last_name
      email
      verified
      avatar
      default_lang
      default_currency
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "confirmation_token",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "confirm_email",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "confirmation_token",
        "variableName": "confirmation_token",
        "type": "String!"
      }
    ],
    "concreteType": "ConfirmEmail",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "client_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expiry",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ref_id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "first_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "last_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verified",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_lang",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_currency",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "VerifyAccountFormMutation",
  "id": null,
  "text": "mutation VerifyAccountFormMutation(\n  $confirmation_token: String!\n) {\n  confirm_email(confirmation_token: $confirmation_token) {\n    token\n    client_id\n    expiry\n    user {\n      id\n      ref_id\n      first_name\n      last_name\n      email\n      verified\n      avatar\n      default_lang\n      default_currency\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "VerifyAccountFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "VerifyAccountFormMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd8df4ff7925b994a113afb77aec7b05d';
module.exports = node;

/* eslint-env browser */
/* eslint-disable */
import React from 'react';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

const Tracks = [
  {
    id: 'products',
    name: 'ecommerceCoreUI:TracksTrans.products',
    icon: 'el-icon el-icon-products',
    url: urlFor('getProductTracksLink'),
    color: '#33a8ff',
    welcomeMessage: 'ecommerceCoreUI:TracksTrans.productsWelcomeMessage',
    imagePath: `/${imagesPathName}/TrackImgs/Products.png`,
    alt: 'Products',
    trackSvgIcon: 
      <svg className="product-svg" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g id="products-new" transform="translate(2.007 -1.61)">
          <rect id="Rectangle_10" data-name="Rectangle 10" width="64" height="64" transform="translate(-2.007 1.61)" fill="rgba(255,255,255,0)"/>
          <g id="Group_13" data-name="Group 13" transform="translate(-2.002 7.61)">
            <path id="Path_51" data-name="Path 51" d="M11.159,16.6a6.759,6.759,0,0,0,6.6-6.559V4.52a1.24,1.24,0,0,0-2.44,0v5.519a4.32,4.32,0,1,1-8.639,0V4.52a1.2,1.2,0,0,0-1.2-1.2,1.24,1.24,0,0,0-1.2,1.2v5.519A6.719,6.719,0,0,0,11.159,16.6Z" transform="translate(12.839 3.519)" fill="#33a8ff"/>
            <rect id="Rectangle_11" data-name="Rectangle 11" width="35.2" height="6.4" transform="translate(22.395 26.401)" fill="#a1d7ff"/>
            <path id="Path_52" data-name="Path 52" d="M42.4,26.167h0l-1.84-22.8a1.92,1.92,0,0,0-1.92-1.76H9.88A1.92,1.92,0,0,0,8,3.37L4.2,50.325h-3a1.2,1.2,0,0,0-1.2,1.2,1.16,1.16,0,0,0,1.2,1.2H62.794a1.16,1.16,0,0,0,1.2-1.2,1.2,1.2,0,0,0-1.2-1.2h-2.8v-22.2a1.92,1.92,0,0,0-1.92-1.96h-1.72m-2.36,0H44.516m-.96,2.4H57.475v5.719H22.558V28.567ZM6.6,50.325,10.279,4.01h28L40,26.167H22.158a1.96,1.96,0,0,0-1.96,1.96v22.2Zm16,0V36.686H57.515V50.325Z" transform="translate(0 -1.61)" fill="#33a8ff"/>
          </g>
        </g>
      </svg>,
      activeIcon: 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="products-new" transform="translate(2.005 -1.612)" opacity="1">
            <rect id="Rectangle_10" data-name="Rectangle 10" width="16" height="16" transform="translate(-2.005 1.612)" fill="rgba(255,255,255,0)"/>
            <g id="products" transform="translate(-2.005 2.002)">
              <path id="Path_115" data-name="Path 115" d="M6,6.64A1.69,1.69,0,0,0,7.65,5V3.62a.31.31,0,0,0-.61,0V5A1.08,1.08,0,0,1,4.88,5V3.62a.3.3,0,0,0-.3-.3.31.31,0,0,0-.3.3V5A1.68,1.68,0,0,0,6,6.64Z" fill="#fff"/>
              <path id="Path_116" data-name="Path 116" d="M10.6,7.75h0l-.46-5.7a.48.48,0,0,0-.48-.44H2.47A.48.48,0,0,0,2,2.05L1.05,13.79H.3a.3.3,0,0,0-.3.3.29.29,0,0,0,.3.3H15.7a.29.29,0,0,0,.3-.3.3.3,0,0,0-.3-.3H15V8.24a.48.48,0,0,0-.48-.49h-.43m-.59,0H11.13m-.24.6h3.48V9.78H5.64V8.35ZM1.65,13.79,2.57,2.21h7L10,7.75H5.54a.49.49,0,0,0-.49.49v5.55Zm4,0V10.38h8.73v3.41Z" fill="#fff"/>
            </g>
          </g>
        </svg>,
      index: 0
  },
  {
    id: 'shops',
    name: 'ecommerceCoreUI:TracksTrans.shops',
    icon: 'el-icon el-icon-shops',
    url: urlFor('getShopTracksLink'),
    color: '#00bfa5',
    welcomeMessage: 'ecommerceCoreUI:TracksTrans.shopsWelcomeMessage',
    imagePath: `/${imagesPathName}/TrackImgs/Stores.png`,
    alt: 'Stores',
    trackSvgIcon: 
      <svg className="shop-svg" xmlns="http://www.w3.org/2000/svg" width="64.001" height="64" viewBox="0 0 64.001 64">
        <g id="shops_new" data-name="shops new" transform="translate(-1 -2.45)">
          <rect id="Rectangle_12" data-name="Rectangle 12" width="64" height="64" transform="translate(1 2.45)" fill="rgba(255,255,255,0)"/>
          <g id="shops-09" transform="translate(1.001 4.08)">
            <path id="Path_133" data-name="Path 133" d="M22.769,10.38H10.41a1.92,1.92,0,0,0-1.88,1.88V21.1a1.88,1.88,0,0,0,1.88,1.88H22.769a1.88,1.88,0,0,0,1.88-1.84V12.26a1.88,1.88,0,0,0-1.88-1.88Zm-.36,10.479H10.81v-8h11.6Z" transform="translate(25.59 30.027)" fill="#00bfa5"/>
            <path id="Path_135" data-name="Path 135" d="M11.139,12.318H52.584l7.128,4.339v5.82l-3.1,4.972L49.9,29.641l-5.143-1.022-4.283-3.084-6.217,3.7-3.392.4L25.153,27.45l-2.325-1.914-6.094,3.7H12.105L6.286,26.274,4.556,23.03l-.629-3.68.629-4.045Z" fill="#00edcd"/>
            <path id="Path_134" data-name="Path 134" d="M62.8,58.886h-5.08V27.328a9.919,9.919,0,0,0,3-6.08v-5.12a1.68,1.68,0,0,0-.72-1.56l-5.48-2.92-1.48-.76h0a6.12,6.12,0,0,0,1.32-3.72v-.8a6,6,0,0,0-6-6H15.641a6,6,0,0,0-6,6v.88a6.12,6.12,0,0,0,1.32,3.64h0l-6.76,3.6a1.68,1.68,0,0,0-.96,1.56v4.96a10,10,0,0,0,3.12,6.48v31.4H1.2a1.2,1.2,0,0,0-1.2,1.2,1.12,1.12,0,0,0,1.12,1.2H62.8a1.16,1.16,0,0,0,1.2-1.12h0a1.2,1.2,0,0,0-1.2-1.28ZM12,6.37a4,4,0,0,1,3.64-3.72H48.359a4,4,0,0,1,3.72,3.72v.88a4,4,0,0,1-3.72,3.72H15.641A4,4,0,0,1,12,7.25ZM26.6,58.886H16V36.928H26.6Zm28.758,0H29V36.408a1.88,1.88,0,0,0-1.88-1.88H15.641a1.88,1.88,0,0,0-1.88,1.88V58.886H8.642V29.128a10.959,10.959,0,0,0,5.2,1.36h.76a11.039,11.039,0,0,0,8.519-4,10.879,10.879,0,0,0,8.359,4h.8a11,11,0,0,0,8.479-4,10.919,10.919,0,0,0,8.4,4h.76a11.319,11.319,0,0,0,5.44-1.4Zm-5.44-30.678h-.76a8,8,0,0,1-8-6.44h-.32a8.439,8.439,0,0,1-8.559,6.44h-.8a8,8,0,0,1-8-6.44H23a8.439,8.439,0,0,1-8.4,6.44h-.76a8,8,0,0,1-8.319-7.2v-4.6l6-3.16H52.479l6,3.2v4.56a8.4,8.4,0,0,1-8.559,7.2Z" transform="translate(0 0)" fill="#00bfa5"/>
          </g>
        </g>
      </svg>,
      activeIcon: 
        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="15.942" viewBox="0 0 16.001 15.942">
          <g id="shops_new" data-name="shops new" transform="translate(-1 -2.585)" opacity="1">
            <rect id="Rectangle_12" data-name="Rectangle 12" width="15.942" height="15.942" transform="translate(1 2.585)" fill="rgba(255,255,255,0)"/>
            <g id="shops" transform="translate(1.001 2.215)">
              <path id="Path_117" data-name="Path 117" d="M12.09,10.38H9a.48.48,0,0,0-.47.47v2.21a.47.47,0,0,0,.47.47h3.09a.47.47,0,0,0,.47-.46V10.85a.47.47,0,0,0-.47-.47ZM12,13H9.1V11H12Z" fill="#fff"/>
              <path id="Path_118" data-name="Path 118" d="M15.7,15H14.43V7.11a2.48,2.48,0,0,0,.75-1.52V4.31A.42.42,0,0,0,15,3.92l-1.37-.73L13.26,3h0a1.53,1.53,0,0,0,.33-.93v-.2a1.5,1.5,0,0,0-1.5-1.5H3.91a1.5,1.5,0,0,0-1.5,1.5v.22A1.53,1.53,0,0,0,2.74,3h0l-1.69.9a.42.42,0,0,0-.24.39V5.53a2.5,2.5,0,0,0,.78,1.62V15H.3a.3.3,0,0,0-.3.3.28.28,0,0,0,.28.3H15.7a.29.29,0,0,0,.3-.28h0a.3.3,0,0,0-.3-.32ZM3,1.87A1,1,0,0,1,3.91.94h8.18a1,1,0,0,1,.93.93v.22a1,1,0,0,1-.93.93H3.91A1,1,0,0,1,3,2.09ZM6.65,15H4V9.51H6.65Zm7.19,0H7.25V9.38a.47.47,0,0,0-.47-.47H3.91a.47.47,0,0,0-.47.47V15H2.16V7.56a2.74,2.74,0,0,0,1.3.34h.19a2.76,2.76,0,0,0,2.13-1,2.72,2.72,0,0,0,2.09,1h.2a2.75,2.75,0,0,0,2.12-1,2.73,2.73,0,0,0,2.1,1h.19a2.83,2.83,0,0,0,1.36-.35ZM12.48,7.33h-.19a2,2,0,0,1-2-1.61h-.08A2.11,2.11,0,0,1,8.07,7.33h-.2a2,2,0,0,1-2-1.61H5.75a2.11,2.11,0,0,1-2.1,1.61H3.46a2,2,0,0,1-2.08-1.8V4.38l1.5-.79H13.12l1.5.8V5.53a2.1,2.1,0,0,1-2.14,1.8Z" fill="#fff"/>
            </g>
          </g>
        </svg>,
      index: 1
  },
  {
    id: 'homemade',
    name: 'ecommerceCoreUI:TracksTrans.homemade',
    icon: 'el-icon el-icon-home-made',
    url: urlFor('getShopTracksLink', ['homemade']),
    color: '#f85c6b',
    welcomeMessage: 'ecommerceCoreUI:TracksTrans.homemadeWelcomeMessage',
    imagePath: `/${imagesPathName}/TrackImgs/Homemade.png`,
    alt: 'Homemade',
    trackSvgIcon: 
      <svg className="homemade-svg" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g id="Homemage-new" transform="translate(2.007 -1.61)">
          <rect id="Rectangle_10" data-name="Rectangle 10" width="64" height="64" transform="translate(-2.007 1.61)" fill="rgba(255,255,255,0)"/>
          <path id="Union_1" data-name="Union 1" d="M-752.484-996.566l-23.462-24.295-23.461,24.295a4.5,4.5,0,0,1-6.363.112,4.5,4.5,0,0,1-.111-6.363l29.523-30.572.413.4.413-.4,29.523,30.572a4.5,4.5,0,0,1-.111,6.363,4.486,4.486,0,0,1-3.126,1.263A4.485,4.485,0,0,1-752.484-996.566Z" transform="translate(806.106 1037.154)" fill="#ffa3ac"/>
          <g id="home" transform="translate(-2.025 3.07)">
            <path id="Path_136" data-name="Path 136" d="M34.3,1.541a3.083,3.083,0,0,0-2.322-1h0a3.163,3.163,0,0,0-2.322,1L1.387,31.088a5.525,5.525,0,0,0,.04,7.327,5.045,5.045,0,0,0,6.966,0V57.832H2.708c-.24,0-.761.561-.761,1.2a1.161,1.161,0,0,0,1.2,1.2H60.8a1.161,1.161,0,0,0,1.2-1.2c0-.641-.52-1.2-1.641-1.2h-4.8V38.414h0a4.684,4.684,0,0,0,3.483,1.521,4.844,4.844,0,0,0,3.683-1.682A5.245,5.245,0,0,0,64,34.411a5.325,5.325,0,0,0-1.521-3.283ZM17.842,57.872V38.414H27.29V57.872Zm35.472,0H29.532V37.854a1.8,1.8,0,0,0-1.762-1.8H17.362a1.762,1.762,0,0,0-1.762,1.8V57.872h-5V36.132l20.7-21.739h0a.881.881,0,0,1,1.321,0l20.7,21.579ZM34.137,12.911a3.043,3.043,0,0,0-2.162-.961h0a3.363,3.363,0,0,0-2.2.961L6.912,36.933a2.843,2.843,0,0,1-2,.841,2.762,2.762,0,0,1-2.6-1.8,3,3,0,0,1,.6-3.2L31.134,3.1a1.121,1.121,0,0,1,1.6,0L46.267,17.235h0L60.84,32.489a3.283,3.283,0,0,1,.881,1.882,3.363,3.363,0,0,1-.841,2.4,2.843,2.843,0,0,1-4,0Z" transform="translate(0 0)" fill="#f15d6c"/>
            <path id="Path_137" data-name="Path 137" d="M20.638,9.41H10.391A1.761,1.761,0,0,0,8.63,11.171V21.458a1.761,1.761,0,0,0,1.761,1.761H20.638a1.8,1.8,0,0,0,1.8-1.761V11.171a1.8,1.8,0,0,0-1.8-1.761ZM20.2,21.018H10.831V11.772H20.2Z" transform="translate(25.869 26.645)" fill="#f15d6c"/>
          </g>
        </g>
      </svg>,
      activeIcon: 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="Homemage-new" transform="translate(2.306 -1.31)" opacity="1">
            <rect id="Rectangle_10" data-name="Rectangle 10" width="16" height="16" transform="translate(-2.306 1.311)" fill="rgba(255,255,255,0)"/>
            <g id="homemade" transform="translate(-2.324 0.771)">
              <path id="Path_119" data-name="Path 119" d="M8.58.79A.77.77,0,0,0,8,.54H8a.79.79,0,0,0-.58.25L.36,8.17A1.38,1.38,0,0,0,.37,10a1.26,1.26,0,0,0,1.74,0v4.85H.69c-.06,0-.19.14-.19.3a.29.29,0,0,0,.3.3H15.2a.29.29,0,0,0,.3-.3c0-.16-.13-.3-.41-.3h-1.2V10h0a1.17,1.17,0,0,0,.87.38,1.21,1.21,0,0,0,.92-.42A1.31,1.31,0,0,0,16,9a1.33,1.33,0,0,0-.38-.82ZM4.47,14.86V10H6.83v4.86Zm8.86,0H7.39v-5a.45.45,0,0,0-.44-.45H4.35a.44.44,0,0,0-.44.45v5H2.66V9.43L7.83,4h0a.22.22,0,0,1,.33,0l5.17,5.39ZM8.54,3.63A.76.76,0,0,0,8,3.39H8a.84.84,0,0,0-.55.24l-5.71,6a.71.71,0,0,1-.5.21.69.69,0,0,1-.65-.45.75.75,0,0,1,.15-.8L7.79,1.18a.28.28,0,0,1,.4,0l3.38,3.53h0l3.64,3.81a.82.82,0,0,1,.22.47.84.84,0,0,1-.21.6.71.71,0,0,1-1,0Z" fill="#fff"/>
              <path id="Path_120" data-name="Path 120" d="M11.63,9.41H9.07a.44.44,0,0,0-.44.44v2.57a.44.44,0,0,0,.44.44h2.56a.45.45,0,0,0,.45-.44V9.85a.45.45,0,0,0-.45-.44Zm-.11,2.9H9.18V10h2.34Z" fill="#fff"/>
            </g>
          </g>
        </svg>,
      index: 2
  },
  {
    id: 'healthPark',
    name: 'ecommerceCoreUI:TracksTrans.healthy',
    icon: 'el-icon el-icon-health',
    url: urlFor('getShopTracksLink', ['healthPark']),
    color: '#857fff',
    welcomeMessage: 'ecommerceCoreUI:TracksTrans.healthParkWelcomeMessage',
    imagePath: `/${imagesPathName}/TrackImgs/Health.png`,
    alt: 'Health Park',
    trackSvgIcon: 
      <svg className="health-svg" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g id="Healthpark-new" transform="translate(2.007 -1.61)">
          <rect id="Rectangle_10" data-name="Rectangle 10" width="64" height="64" transform="translate(-2.007 1.61)" fill="rgba(255,255,255,0)"/>
          <g id="healthcare-06" transform="translate(-2.007 2.985)">
            <rect id="Rectangle_26" data-name="Rectangle 26" width="12" height="20" transform="translate(26 38.625)" fill="#cbc9ff"/>
            <path id="Path_138" data-name="Path 138" d="M62.88,57.485H59.2v-43a1.8,1.8,0,0,0-1.8-1.8h-8V6.765a1.8,1.8,0,0,0-1.32-1.72L32.44.685a1.8,1.8,0,0,0-.92,0l-15.8,4.36a1.76,1.76,0,0,0-1.28,1.72v5.92h-8a1.8,1.8,0,0,0-1.8,1.8v43H1.12a1.12,1.12,0,0,0,0,2.24H62.88a1.12,1.12,0,0,0,0-2.24Zm-48.44,0H7.04V47.365h7.4Zm-7.4-12.36v-30.2h7.4v30.2ZM24,57.485H16.68V47.365H24Zm0-12.36H16.68V30.6A18.52,18.52,0,0,0,24,38.125Zm13.64,12.36H26.28v-18.4a37.12,37.12,0,0,0,5.16,1.72h1.04a32,32,0,0,0,5.24-1.76Zm0-20.88A26.76,26.76,0,0,1,32,38.685a29.24,29.24,0,0,1-5.68-2.04A22.758,22.758,0,0,1,24,35.365a15.64,15.64,0,0,1-6.4-7.68,14.72,14.72,0,0,1-1-4.32,12,12,0,0,1,0-1.6h0V7.085L32,2.845l15.36,4.24v14.68a14.32,14.32,0,0,1-1.04,5.92,16.28,16.28,0,0,1-1.12,2.44,16.4,16.4,0,0,1-5.2,5.2,16.681,16.681,0,0,1-2.28,1.28Zm9.6,20.88H40V47.365h7.4Zm0-12.36H40v-7a18.92,18.92,0,0,0,7.32-7.6Zm9.64,12.36H49.56V47.365h7.4Zm0-12.36H49.56v-30.2h7.4Z" transform="translate(0 0)" fill="#857fff"/>
            <path id="Path_139" data-name="Path 139" d="M15.651,4.364a6.4,6.4,0,0,0-8.32.76,6.76,6.76,0,0,0,0,9.36l8.32,8.6,8.32-8.6a6.76,6.76,0,0,0,0-9.32,6.4,6.4,0,0,0-8.32-.8Z" transform="translate(16.349 7.641)" fill="#cbc9ff"/>
          </g>
        </g>
      </svg>,
      activeIcon: 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="Healthpark-new" transform="translate(0 -1.125)" opacity="1">
            <rect id="Rectangle_13" data-name="Rectangle 13" width="16" height="16" transform="translate(0 1.125)" fill="#fff" opacity="0"/>
            <g id="healthcare" transform="translate(0 0.5)">
              <path id="Path_121" data-name="Path 121" d="M15.72,14.84H14.8V4.09a.45.45,0,0,0-.45-.45h-2V2.16a.45.45,0,0,0-.33-.43L8.11.64a.45.45,0,0,0-.23,0L3.93,1.73a.44.44,0,0,0-.32.43V3.64h-2a.45.45,0,0,0-.45.45V14.84H.28a.28.28,0,0,0,0,.56H15.72a.28.28,0,0,0,0-.56Zm-12.11,0H1.76V12.31H3.61ZM1.76,11.75V4.2H3.61v7.55ZM6,14.84H4.17V12.31H6Zm0-3.09H4.17V8.12A4.63,4.63,0,0,0,6,10Zm3.41,3.09H6.57v-4.6a9.28,9.28,0,0,0,1.29.43h.26a8,8,0,0,0,1.31-.44Zm0-5.22A6.69,6.69,0,0,1,8,10.14a7.31,7.31,0,0,1-1.42-.51A5.69,5.69,0,0,1,6,9.31,3.91,3.91,0,0,1,4.4,7.39a3.68,3.68,0,0,1-.25-1.08,3,3,0,0,1,0-.4h0V2.24L8,1.18l3.84,1.06V5.91a3.58,3.58,0,0,1-.26,1.48A4.07,4.07,0,0,1,11.3,8,4.1,4.1,0,0,1,10,9.3a4.17,4.17,0,0,1-.57.32Zm2.4,5.22H10V12.31h1.85Zm0-3.09H10V10a4.73,4.73,0,0,0,1.83-1.9Zm2.41,3.09H12.39V12.31h1.85Zm0-3.09H12.39V4.2h1.85Z" fill="#fff"/>
              <path id="Path_122" data-name="Path 122" d="M8,3.47a1.6,1.6,0,0,0-2.08.19A1.69,1.69,0,0,0,5.92,6L8,8.15,10.08,6a1.69,1.69,0,0,0,0-2.33A1.6,1.6,0,0,0,8,3.47ZM9.68,5.58,8,7.34,6.3,5.58a1.13,1.13,0,0,1,0-1.53,1,1,0,0,1,1.48,0L8,4.26l.21-.21h0a1,1,0,0,1,1.46,0,1.11,1.11,0,0,1,.01,1.53Z" fill="#fff"/>
            </g>
          </g>
        </svg>,
      index: 3
  },
  {
    id: 'eduPark',
    name: 'ecommerceCoreUI:TracksTrans.education',
    icon: 'el-icon el-icon-education',
    url: urlFor('getShopTracksLink', ['eduPark']),
    color: '#ffba00',
    welcomeMessage: 'ecommerceCoreUI:TracksTrans.educationParkWelcomeMessage',
    imagePath: `/${imagesPathName}/TrackImgs/Edu.png`,
    alt: 'Edu Park',
    trackSvgIcon:
      <svg className="edu-svg" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g id="Edupark_new" data-name="Edupark new" transform="translate(-1 -2.45)">
          <rect id="Rectangle_12" data-name="Rectangle 12" width="64" height="64" transform="translate(1 2.45)" fill="rgba(255,255,255,0)"/>
          <g id="tracks_new_icons-11" data-name="tracks new icons-11" transform="translate(1 3.12)">
            <path className="f-path" id="Path_128" data-name="Path 128" d="M33,13.894,7.576,23.375v8.532h51V23.375Z" transform="translate(-1 -3.12)" fill="#ffe7a8"/>
            <path id="Path_116" data-name="Path 116" d="M2.5,7.67V37.429H19.34V18.75a5.16,5.16,0,0,1,10.32,0v18.68h16.92V7.67ZM14.7,30.189c0,.76-.76,1.32-1.72,1.36h-4a1.64,1.64,0,0,1-1.76-1.36V14.91a1.64,1.64,0,0,1,1.76-1.36h4a1.6,1.6,0,0,1,1.72,1.36Zm27.159,0c0,.76-.76,1.32-1.72,1.36h-4a1.56,1.56,0,0,1-1.72-1.36V14.91c0-.76.76-1.32,1.72-1.36h4a1.6,1.6,0,0,1,1.72,1.36Z" transform="translate(7.5 22.02)" fill="none"/>
            <path id="Path_117" data-name="Path 117" d="M13.92,3.15a1.28,1.28,0,0,1,0-1.2l.52-.84H8.28V3.99h6.16Z" transform="translate(24.84 2.341)" fill="none"/>
            <path id="Path_118" data-name="Path 118" d="M10.19,9.71a2.92,2.92,0,0,0-2.92,2.92v18.68h5.84V12.63a2.92,2.92,0,0,0-2.92-2.92Z" transform="translate(21.81 28.14)" fill="none"/>
            <rect id="Rectangle_23" data-name="Rectangle 23" width="3.04" height="14.56" transform="translate(16.96 37.29)" fill="none"/>
            <rect id="Rectangle_24" data-name="Rectangle 24" width="3.04" height="14.56" transform="translate(44.12 37.29)" fill="none"/>
            <path id="Path_119" data-name="Path 119" d="M50.5,12.03,26.179,3.15l-24.239,9v6.84H50.5ZM26.179,14.91a2.72,2.72,0,1,1,2.72-2.72,2.72,2.72,0,0,1-2.72,2.72Z" transform="translate(5.82 8.46)" fill="none"/>
            <path id="Path_120" data-name="Path 120" d="M62.879,15.11H58a1.76,1.76,0,0,1,1.76,1.76H4.28A1.76,1.76,0,0,1,6,15.11H1.121A1.12,1.12,0,0,0,0,16.23a1.08,1.08,0,0,0,1.12,1.12H62.879A1.08,1.08,0,0,0,64,16.23,1.12,1.12,0,0,0,62.879,15.11Z" transform="translate(0 44.339)" fill="#ffba00"/>
            <path id="Path_121" data-name="Path 121" d="M10.04,4.73a2.72,2.72,0,1,0,2.72,2.72,2.68,2.68,0,0,0-2.72-2.72Z" transform="translate(21.96 13.2)" fill="#ffba00"/>
            <path id="Path_122" data-name="Path 122" d="M16.2,9.144h-4a1.559,1.559,0,0,0-1.72,1.36v15.28a1.56,1.56,0,0,0,1.72,1.36h4a1.559,1.559,0,0,0,1.72-1.36V10.5A1.6,1.6,0,0,0,16.2,9.144Zm-.48,16.28H12.56V10.864H15.6Z" transform="translate(31.44 26.425)" fill="#ffba00"/>
            <path id="Path_123" data-name="Path 123" d="M9.44,9.145h-4A1.64,1.64,0,0,0,3.68,10.5v15.28a1.64,1.64,0,0,0,1.76,1.36h4a1.559,1.559,0,0,0,1.72-1.36V10.5A1.6,1.6,0,0,0,9.44,9.145Zm-.48,16.28H5.92V10.865H8.96Z" transform="translate(11.04 26.425)" fill="#ffba00"/>
            <path id="Path_124" data-name="Path 124" d="M6.99,15.67Z" transform="translate(20.97 46.019)" fill="#ffba00"/>
            <path id="Path_125" data-name="Path 125" d="M11.87,9.14a5.2,5.2,0,0,0-5.16,5.2v18.68H8.95V14.34a2.92,2.92,0,0,1,5.84,0v18.68h2.24V14.34a5.16,5.16,0,0,0-5.16-5.2Z" transform="translate(20.13 26.43)" fill="#ffba00"/>
            <path id="Path_126" data-name="Path 126" d="M53.609,27.45h.72V20.21a1.72,1.72,0,0,0-1.12-1.64L28.889,9.65V8.571h8a1.16,1.16,0,0,0,1.12-.84,1.4,1.4,0,0,0,0-.28,1.76,1.76,0,0,0,0-.6l-1.2-1.96,1.24-1.88a1.32,1.32,0,0,0,0-.52,1.16,1.16,0,0,0-1.12-1.16h-8.12a1.12,1.12,0,0,0-1.04-1,1.16,1.16,0,0,0-1.2,1.04V9.65l-24,8.96a1.8,1.8,0,0,0-1.16,1.64v7.2h.72v2.24H3.65V59.449H5.77V29.69h44V59.449h2.2V29.69h1.56Zm-24.719-24h6.16l-.52.84a1.28,1.28,0,0,0,0,1.2l.52.84h-6.16Zm23.2,24H3.53V20.61l24.239-9,24.279,8.88Z" transform="translate(4.23)" fill="#ffba00"/>
            <path id="Path_127" data-name="Path 127" d="M1.07,16.87H56.549a1.76,1.76,0,0,0-1.76-1.76h-52a1.76,1.76,0,0,0-1.72,1.76Z" transform="translate(3.21 44.339)" fill="#ffba00"/>
            <rect id="Rectangle_25" data-name="Rectangle 25" width="57.319" height="2.24" rx="0.28" transform="translate(3.32 27.45)" fill="#ffba00"/>
          </g>
        </g>
      </svg>,
      activeIcon: 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="Edupark_new" data-name="Edupark new" transform="translate(-0.931 -2.381)" opacity="1">
            <rect id="Rectangle_12" data-name="Rectangle 12" width="16" height="16" transform="translate(0.931 2.382)" fill="rgba(255,255,255,0)"/>
            <g id="edupark" transform="translate(0.932 2.052)">
              <path id="Path_123" data-name="Path 123" d="M2.5,7.67v7.44H6.71V10.44a1.29,1.29,0,0,1,2.58,0v4.67h4.23V7.67ZM5.55,13.3c0,.19-.19.33-.43.34h-1a.41.41,0,0,1-.44-.34V9.48a.41.41,0,0,1,.44-.34h1a.4.4,0,0,1,.43.34Zm6.79,0c0,.19-.19.33-.43.34h-1a.39.39,0,0,1-.43-.34V9.48c0-.19.19-.33.43-.34h1a.4.4,0,0,1,.43.34Z" fill="none"/>
              <path id="Path_124" data-name="Path 124" d="M9.69,1.62a.32.32,0,0,1,0-.3l.13-.21H8.28v.72H9.82Z" fill="none"/>
              <path id="Path_125" data-name="Path 125" d="M8,9.71a.73.73,0,0,0-.73.73v4.67H8.73V10.44A.73.73,0,0,0,8,9.71Z" fill="none"/>
              <rect id="Rectangle_22" data-name="Rectangle 22" width="0.76" height="3.64" transform="translate(4.24 9.57)" fill="none"/>
              <rect id="Rectangle_23" data-name="Rectangle 23" width="0.76" height="3.64" transform="translate(11.03 9.57)" fill="none"/>
              <path id="Path_126" data-name="Path 126" d="M14.08,5.37,8,3.15,1.94,5.4V7.11H14.08ZM8,6.09a.68.68,0,1,1,.68-.68A.68.68,0,0,1,8,6.09Z" fill="none"/>
              <path id="Path_127" data-name="Path 127" d="M15.72,15.11H14.5a.44.44,0,0,1,.44.44H1.07a.44.44,0,0,1,.43-.44H.28a.28.28,0,0,0-.28.28.27.27,0,0,0,.28.28H15.72a.27.27,0,0,0,.28-.28A.28.28,0,0,0,15.72,15.11Z" fill="#fff"/>
              <path id="Path_128" data-name="Path 128" d="M8,4.73a.68.68,0,1,0,.68.68A.67.67,0,0,0,8,4.73Z" fill="#fff"/>
              <path id="Path_129" data-name="Path 129" d="M11.91,9.14h-1a.39.39,0,0,0-.43.34V13.3a.39.39,0,0,0,.43.34h1a.39.39,0,0,0,.43-.34V9.48A.4.4,0,0,0,11.91,9.14Zm-.12,4.07H11V9.57h.76Z" fill="#fff"/>
              <path id="Path_130" data-name="Path 130" d="M5.12,9.14h-1a.41.41,0,0,0-.44.34V13.3a.41.41,0,0,0,.44.34h1a.39.39,0,0,0,.43-.34V9.48A.4.4,0,0,0,5.12,9.14ZM5,13.21H4.24V9.57H5Z" fill="#fff"/>
              <path id="Path_131" data-name="Path 131" d="M6.99,15.67Z" fill="#fff"/>
              <path id="Path_132" data-name="Path 132" d="M8,9.14a1.3,1.3,0,0,0-1.29,1.3v4.67h.56V10.44a.73.73,0,0,1,1.46,0v4.67h.56V10.44A1.29,1.29,0,0,0,8,9.14Z" fill="#fff"/>
              <path id="Path_133" data-name="Path 133" d="M14.46,7.11h.18V5.3a.43.43,0,0,0-.28-.41L8.28,2.66V2.39h2a.29.29,0,0,0,.28-.21.35.35,0,0,0,0-.07.44.44,0,0,0,0-.15l-.3-.49L10.57,1a.33.33,0,0,0,0-.13.29.29,0,0,0-.28-.29H8.26A.28.28,0,0,0,8,.33a.29.29,0,0,0-.3.26V2.66L1.7,4.9a.45.45,0,0,0-.29.41v1.8h.18v.56h.38v7.44H2.5V7.67h11v7.44h.55V7.67h.39Zm-6.18-6H9.82l-.13.21a.32.32,0,0,0,0,.3l.13.21H8.28Zm5.8,6H1.94V5.4L8,3.15l6.07,2.22Z" fill="#fff"/>
              <path id="Path_134" data-name="Path 134" d="M1.07,15.55H14.94a.44.44,0,0,0-.44-.44H1.5a.44.44,0,0,0-.43.44Z" fill="#fff"/>
              <rect id="Rectangle_24" data-name="Rectangle 24" width="14.33" height="0.56" rx="0.28" transform="translate(0.83 7.11)" fill="#fff"/>
            </g>
          </g>
        </svg>,
      index: 4
  },

];

export default Tracks;

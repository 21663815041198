import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

import AykMemberShip from './AykMemberShip';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import AykPointsCard from './AykPointsCard';
import AykGiftsInfo from './AykGiftsInfo';

const ColumnWrapper = styled(Column)`
  width: ${props => 46 * props.theme.new.spacer}px;
  margin: auto;
  ${props => cssMediaMax.xsmall`
    width: ${29 * props.theme.new.spacer}px;
  `}
`;
const AykPoints = ({ queryResult }) => (
  <Column stretchAligned>
    <AykMemberShip queryResult={queryResult} degree={queryResult.ayk_loyalty_program.degree} />
    <ColumnWrapper stretchAligned>
      <Spacer size={1.5} />
      <AykPointsCard queryResult={queryResult} />
      {queryResult.ayk_loyalty_program.active_gifts_count !== 0 && (
        <React.Fragment>
          <Spacer size={[1.5, 1.5, 3]} />
          <AykGiftsInfo giftsNumber={queryResult.ayk_loyalty_program.active_gifts_count} />
        </React.Fragment>
      )}
      <Spacer size={[1, 1, 2]} />
    </ColumnWrapper>
  </Column>
);

AykPoints.propTypes = {
  queryResult: PropTypes.shape({}),
};

AykPoints.defaultProps = {
  queryResult: [],
};

export default AykPoints;

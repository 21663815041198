/* eslint-disable react/prop-types, camelcase */
import React from 'react';
import i18next from 'i18next';
import t, { maybe } from 'tcomb-form';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import SchoolsQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/SchoolsQuery';
import ClubQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/ClubQuery';
import EmployerQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/EmployerQuery';
import InsuranceCompaniesQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/InsuranceCompaniesQuery';
import PhoneProvidersQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/PhoneProvidersQuery';

const FormLabel = props => (
  <Row pl={1.5} pr={1.5} pb={0.3} fullWidth>
    <Label sm important>{props.children}</Label>
    {props.required && (
      <Label sm error>*</Label>
    )}
  </Row>
);

export default userInfo => ([
  {
    name: 'first_name',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.first_name')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.first_name'),
    displayName: i18next.t('accountManagement:Profile.first_name'),
    input_type: 'textbox',
    type: 'RequiredStringWithMinimum',
  },
  {
    name: 'last_name',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.last_name')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.last_name'),
    displayName: i18next.t('accountManagement:Profile.last_name'),
    input_type: 'textbox',
    type: 'RequiredStringWithMinimum',
  },
  {
    name: 'email',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.email')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.email'),
    displayName: i18next.t('accountManagement:Profile.email'),
    input_type: 'textbox',
    type: 'Email',
  },
  {
    name: 'nationality',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.nationality_name')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.nationality_name'),
    displayName: i18next.t('accountManagement:Profile.nationality_name'),
    input_type: 'country',
    type: 'RequiredCountry',
  },
  {
    name: 'mobile_number',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.mobile_number')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.mobile_number'),
    displayName: i18next.t('accountManagement:Profile.mobile_number'),
    input_type: 'phoneNumber',
    type: 'RequiredNumber',
  },
  {
    name: 'gender',
    displayName: i18next.t('accountManagement:Profile.gender'),
    input_type: 'gender',
    required: true,
    type: 'RequiredEnum',
  },
  {
    name: 'birthdate_timestamp',
    label: <FormLabel>{i18next.t('accountManagement:Profile.birthdate_timestamp')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.birthdate_timestamp'),
    displayName: i18next.t('accountManagement:Profile.birthdate_timestamp'),
    input_type: 'datepicker',
    type: maybe(t.Number),
    initialValue: userInfo.birthdate_timestamp,
    order: ['D', 'M', 'YY'],
  },
  {
    name: 'country',
    label: <FormLabel>{i18next.t('accountManagement:Profile.country_name')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.country_name'),
    displayName: i18next.t('accountManagement:Profile.country_name'),
    input_type: 'country',
    type: 'RequiredCountry',
    getUpdatesOnFormValueChange: (value, path) => (path && path[0] === 'country'
      ? ({
        $set: { country_code: value.country.country_code, country_id: value.country.country_id },
      }) : (
        null
      )
    ),
  },
  {
    name: 'city',
    label: <FormLabel>{i18next.t('accountManagement:Profile.city_name')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.city_name'),
    displayName: i18next.t('accountManagement:Profile.city_name'),
    input_type: 'city',
    type: 'OptionalState',
    getUpdatesOnFormValueChange: (value, path) => (path && path[0] === 'country'
      ? ({ // reset the selected city (Will cause cities to reload)
        $set: { state_id: null, country_id: value.country.country_id },
      }) : (
        null // no-updates
      )
    ),
  },
  {
    name: 'short_address',
    label: <FormLabel>{i18next.t('accountManagement:Profile.short_address')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.short_address'),
    displayName: i18next.t('accountManagement:Profile.short_address'),
    input_type: 'textbox',
    type: 'OptionalString',
  }, {
    name: 'phone_provider_id',
    label: <FormLabel>{i18next.t('accountManagement:Profile.phone_provider_name')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.phone_provider_name'),
    placeholder: i18next.t('accountManagement:Profile.providerPlaceholder'),
    input_type: 'dropDown',
    query: PhoneProvidersQuery,
    transformQueryResult: providersList => (
      providersList && providersList.phone_providers.length > 0 && providersList.phone_providers.map(phone => ({
        label: phone.name,
        value: phone.ref_id,
      }))),
    type: 'OptionalString',
  }, {
    name: 'phone_provider_attachment',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.uploadImageLabel')}</FormLabel>,
    input_type: 'uploadImage',
    type: 'OptionalString',
    isVisible: locals => locals.context.currentFormValues.phone_provider_id,
  },
  {
    name: 'school_id',
    label: <FormLabel>{i18next.t('accountManagement:Profile.school_name')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.school_name'),
    placeholder: i18next.t('accountManagement:Profile.schoolPlaceholder'),
    input_type: 'dropDown',
    query: SchoolsQuery,
    transformQueryResult: queryResult => (
      queryResult?.schools.length > 0 && queryResult.schools.map(school => ({
        label: school.name,
        value: school.ref_id,
      }))),
    type: 'OptionalString',
  }, {
    name: 'school_attachment',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.uploadImageLabel')}</FormLabel>,
    input_type: 'uploadImage',
    type: 'OptionalString',
    isVisible: locals => locals.context.currentFormValues.school_id,
  }, {
    name: 'employer_id',
    label: <FormLabel>{i18next.t('accountManagement:Profile.employer_name')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.employer_name'),
    placeholder: i18next.t('accountManagement:Profile.employerPlaceholder'),
    input_type: 'dropDown',
    query: EmployerQuery,
    transformQueryResult: queryResult => (
      queryResult?.employers.length > 0 && queryResult.employers.map(employer => ({
        label: employer.name,
        value: employer.ref_id,
      }))),
    type: 'OptionalString',
  }, {
    name: 'employer_attachment',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.uploadImageLabel')}</FormLabel>,
    input_type: 'uploadImage',
    type: 'OptionalString',
    isVisible: locals => locals.context.currentFormValues.employer_id,
  }, {
    name: 'insurance_company_id',
    label: <FormLabel>{i18next.t('accountManagement:Profile.insurance_company_name')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.insurance_company_name'),
    placeholder: i18next.t('accountManagement:Profile.insurancePlaceholder'),
    input_type: 'dropDown',
    query: InsuranceCompaniesQuery,
    transformQueryResult: queryResult => (
      queryResult?.insurance_companies.length > 0 && queryResult.insurance_companies.map(company => ({
        label: company.name,
        value: company.ref_id,
      }))),
    type: 'OptionalString',
    menuPlacement: 'top',
  }, {
    name: 'insurance_company_attachment',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.uploadImageLabel')}</FormLabel>,
    input_type: 'uploadImage',
    type: 'OptionalString',
    isVisible: locals => locals.context.currentFormValues.insurance_company_id,
  }, {
    name: 'club_id',
    label: <FormLabel>{i18next.t('accountManagement:Profile.club_name')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.club_name'),
    placeholder: i18next.t('accountManagement:Profile.clubPlaceholder'),
    input_type: 'dropDown',
    query: ClubQuery,
    transformQueryResult: queryResult => (
      queryResult?.clubs.length > 0 && queryResult.clubs.map(club => ({
        label: club.name,
        value: club.ref_id,
      }))),
    type: 'OptionalString',
    menuPlacement: 'top',
  }, {
    name: 'club_attachment',
    label: <FormLabel required>{i18next.t('accountManagement:Profile.uploadImageLabel')}</FormLabel>,
    input_type: 'uploadImage',
    type: 'OptionalString',
    isVisible: locals => locals.context.currentFormValues.club_id,
  },
]);

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import changeCurrency, { getCurrencyDisplayName } from '../../../../ecommerceCore/utils/changeCurrency';

const onChange = (value) => {
  const currencyChanged = changeCurrency(value);
  if (currencyChanged) {
    window.location.reload();
  }
};

const MobileCurrenciesList = ({ queryResult }) => (
  <ul>
    {queryResult.system_configuration_settings.supported_currencies.map(currency => (
      // eslint-disable-next-line
      <li key={currency} onClick={() => { onChange(currency); }}>{getCurrencyDisplayName(currency)}</li>
    ))}
  </ul>
);

MobileCurrenciesList.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
};

export default withRootQuery(
  MobileCurrenciesList,
  graphql`
    query MobileCurrenciesListQuery {
      system_configuration_settings {
        supported_currencies
      }
    }
  `,
);

/**
 * @flow
 * @relayHash 7d62eb1bc424e087a16b1fc444d1b7d3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RefundList_queryResult$ref = any;
export type RequndListInput = {
  page?: ?PageInfoInput
};
export type PageInfoInput = {
  limit?: ?number,
  offset?: ?number,
};
export type RefundListQueryVariables = {|
  input?: ?RequndListInput
|};
export type RefundListQueryResponse = {|
  +$fragmentRefs: RefundList_queryResult$ref
|};
export type RefundListQuery = {|
  variables: RefundListQueryVariables,
  response: RefundListQueryResponse,
|};
*/


/*
query RefundListQuery(
  $input: RequndListInput
) {
  ...RefundList_queryResult_2VV6jB
}

fragment RefundList_queryResult_2VV6jB on Query {
  refund_requests(input: $input) {
    page_info {
      ...PaginationData_pageInfo
    }
    refunds {
      ref_id
      refunded_at
      created_at
      number
      refund_status
      progress_bar {
        status
        name
      }
      vendor {
        ref_id
        name
        rating_count
        avg_rating
        id
      }
      refund_request_items {
        refund_status
        quantity
        line_item {
          id
          ref_id
          eligible_refund
          price
          variant {
            display_price
            slug
            vendor_variants_count
            name
            rating_count
            avg_rating
            images_attachments {
              url_small
            }
            option_values {
              id
              presentation
            }
            id
          }
        }
        id
      }
      id
    }
  }
}

fragment PaginationData_pageInfo on PageInfo {
  current_page
  items_count
  limit
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequndListInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "refund_status",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RefundListQuery",
  "id": null,
  "text": "query RefundListQuery(\n  $input: RequndListInput\n) {\n  ...RefundList_queryResult_2VV6jB\n}\n\nfragment RefundList_queryResult_2VV6jB on Query {\n  refund_requests(input: $input) {\n    page_info {\n      ...PaginationData_pageInfo\n    }\n    refunds {\n      ref_id\n      refunded_at\n      created_at\n      number\n      refund_status\n      progress_bar {\n        status\n        name\n      }\n      vendor {\n        ref_id\n        name\n        rating_count\n        avg_rating\n        id\n      }\n      refund_request_items {\n        refund_status\n        quantity\n        line_item {\n          id\n          ref_id\n          eligible_refund\n          price\n          variant {\n            display_price\n            slug\n            vendor_variants_count\n            name\n            rating_count\n            avg_rating\n            images_attachments {\n              url_small\n            }\n            option_values {\n              id\n              presentation\n            }\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment PaginationData_pageInfo on PageInfo {\n  current_page\n  items_count\n  limit\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RefundListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "RefundList_queryResult",
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RefundListQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "refund_requests",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "RequndListInput"
          }
        ],
        "concreteType": "RequestResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "page_info",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "current_page",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "items_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "limit",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "refunds",
            "storageKey": null,
            "args": null,
            "concreteType": "RefundRequestResult",
            "plural": true,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "refunded_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "created_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "progress_bar",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderStates",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  v3
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "vendor",
                "storageKey": null,
                "args": null,
                "concreteType": "Shop",
                "plural": false,
                "selections": [
                  v1,
                  v3,
                  v4,
                  v5,
                  v6
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "refund_request_items",
                "storageKey": null,
                "args": null,
                "concreteType": "RefundRequestItemResult",
                "plural": true,
                "selections": [
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quantity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "line_item",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LineItem",
                    "plural": false,
                    "selections": [
                      v6,
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "eligible_refund",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "price",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "variant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Variant",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "display_price",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "slug",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "vendor_variants_count",
                            "args": null,
                            "storageKey": null
                          },
                          v3,
                          v4,
                          v5,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "images_attachments",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ImageAttachment",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "url_small",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "option_values",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "OptionValue",
                            "plural": true,
                            "selections": [
                              v6,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "presentation",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          v6
                        ]
                      }
                    ]
                  },
                  v6
                ]
              },
              v6
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '326a5a0960e5409ca1c143e0dcb1dd17';
module.exports = node;

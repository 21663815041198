/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Loader from '~/modules/coreUI/components/basic/Loader';
import SpinBoxDescription from './SpinBoxDescription';
import CongratsBoxDescription from './CongratsBoxDescription';
import SorryBoxDescription from './SorryBoxDescription';
import withLuckyWheelInfo from './withLuckyWheelInfo';

const Wrapper = styled(Column)`
  padding: ${props => 2 * props.theme.paddings.large}px;
  text-align: center;
  width: 54%;
  > span {
    text-align: center;
    line-height: 1.5;
  }
`;

const LuckyWheelBoxDescription = ({ loading, spinWheelResult, showSpinDescription }) => (
  <Wrapper stretchAligned>
    {loading && <Loader />}
    {showSpinDescription === true && (
      <SpinBoxDescription />
    )}
    {!loading && spinWheelResult.length !== 0 && spinWheelResult.spin_wheel?.won === true && (
      <CongratsBoxDescription
        text={spinWheelResult?.spin_wheel?.wheel_name}
        description={spinWheelResult?.spin_wheel?.wheel_description}
        promo={spinWheelResult?.spin_wheel?.promo}
      />
    )}
    {!loading && spinWheelResult.length !== 0 && spinWheelResult.spin_wheel?.won === false && (
      <SorryBoxDescription
        description={spinWheelResult?.spin_wheel?.wheel_description}
      />
    )}
  </Wrapper>
);

LuckyWheelBoxDescription.propTypes = {
  spinWheelResult: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  loading: PropTypes.bool.isRequired,
  showSpinDescription: PropTypes.bool.isRequired,
};
export default withLuckyWheelInfo(LuckyWheelBoxDescription);

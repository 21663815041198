/* eslint-disable react/prop-types, camelcase */
import React from 'react';
import { graphql } from 'react-relay';
import _ from 'lodash';
import { css } from 'emotion';


import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';
import Loader from '~/modules/coreUI/components/basic/Loader';

import NotificationsList from './NotificationsList';
import EmptyNotifications from './EmptyNotifications';
import ViewAllLink from './ViewAllLink';

const ElectricLoader = () => <Loader height="calc(400px - 60px)" />;

const containerStyles = () => css`
  max-height: calc(400px - 60px);
  overflow-y: auto;
`;

const Notifications = ({ queryResult }) => (queryResult?.notifications.result?.length > 0 ? (
  <div className={containerStyles()}>
    <NotificationsList notifications={queryResult?.notifications.result} fromDropdown />
    {queryResult?.notifications.notifications_count < 12 ? null : (
      <ViewAllLink tabName="notifications" />
    )}
  </div>
) : (
  <EmptyNotifications />
));

const fragment = {
  queryResult: graphql`
    fragment DropdownNotifications_queryResult on Query
      @argumentDefinitions(input: { type: "NotificationInput" }) {
        notifications(input: $input) {
          notifications_count
          unread_count
          result {
            ref_id
            created_at
            key
            read
            subject
            topic
            redirect_to
          }
          page_info {
            ...PaginationData_pageInfo
          }
      }
    }
  `,
};

const query = graphql`
  query DropdownNotificationsQuery($input: NotificationInput) {
    ...DropdownNotifications_queryResult @arguments(input: $input)
  }
`;

export default withRefetchQuery(
  Notifications,
  query,
  fragment,
  ElectricLoader,
  () => ({
    input: {
      page: {
        offset: 0,
        limit: 12,
      },
    },
  }),
  null,
  {
    pageInfo: queryResult => _.cloneDeep(queryResult.notifications.page_info),
  },
  true,
  null,
  { force: true },
);

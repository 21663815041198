import styled from 'react-emotion';
import { MediumLabel, LargeLabel, XLargeLabel } from '~/modules/coreUI/components/basic/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

export const SubDepartmentName = styled(LargeLabel)`
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: capitalize;
`;

export const DepartmentTabsTitle = styled(MediumLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const ShopHeaderLabel = styled(MediumLabel)`
  color: ${props => props.theme.colors.labels.emphasized};
  flex-wrap: wrap;
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.xSmall}px;
  `};
`;

export const ResponsiveShopNameTitle = styled(XLargeLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: bold;
  text-transform: capitalize;
`;

import { graphql } from 'relay-runtime';

const SeoConfigurationsQuery = graphql`
  query SeoConfigurationsQuery {
    seo_configurations{
    title
    meta_description
    meta_keywords
    alternate_name
    content_header
    }
    }
`;


export default SeoConfigurationsQuery;

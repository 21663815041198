import { graphql } from 'react-relay';

const EditAddressMutation = graphql`
mutation EditAddressMutation($data: UserAddressHandler) {
  update_user_address(input: $data) {
    errors {
      field
      messages
      code
    }
    user {
      billing_addresses_previous{
        ...WithAddressData_addressInfo @relay(mask: false)
      }
      shipping_addresses_previous{
        ...WithAddressData_addressInfo @relay(mask: false)
      }
      cart{
        ...WithCartData_cart @relay(mask: false)
      }
    }
  }
}
`;

export default EditAddressMutation;

import React from 'react';
import styled, { css } from 'react-emotion';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Icon from '~/modules/coreUI/components/basic/Icon';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import cuid from 'cuid';

const List = withDirection(styled.ul`
  margin: 0px;
  ${props => (props.direction === 'rtl'
    ? css`
        padding-right: 0px;
          `
    : css`
        padding-left: 0px;
  `)};
  list-style-position: inside;
`);

const ListItem = withDirection(styled.li`
  & span {
    ${props => (props.direction === 'rtl'
    ? css`
        margin-right: ${-1 * props.theme.new.spacer}px;
          `
    : css`
        margin-left: ${-1 * props.theme.new.spacer}px;
  `)};
  }
`);

const Title = styled(Row)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

const getErrorsList = (errors) => {
  if (!errors) {
    return [];
  }
  if (_.isString(errors)) {
    return [errors];
  }
  if (_.isArray(errors)) {
    return errors;
  }
  if (errors.generalErrors && _.isString(errors.generalErrors)) {
    return [errors.generalErrors];
  }
  if (errors.generalErrors && _.isArray(errors.generalErrors)) {
    return errors.generalErrors.map(error => getErrorsList(error));
  }
  if (errors.generalErrors && _.isObject(errors.generalErrors)) {
    return _.values(errors.generalErrors);
  }
  if (_.isObject(errors)) {
    if (errors.messages[0] || errors.message) {
      return errors.messages[0] || errors.message;
    }
    if (_.values(errors)) {
      const errorEntries = _.values(errors);
      return _.flatten(errorEntries.map(entry => (_.isString(entry) ? [entry] : entry)));
    }
  }
  return [];
};

export default function PanelAlert(props) {
  const errors = getErrorsList(props.errors);
  return (
    <Row
      boxType={props.type || 'default'}
      fullWidth
      topAligned
      padding={1.5}
      spaceBetween={1}
      inverted={props.inverted}
      bordered
      borderRadius={props.borderRadius}
    >
      <Icon className={props.iconClass} color={props.type} />
      <Column topJustified leftAligned spaceBetween={1}>
        {props.title && <Title>{props.title}</Title> }
        {errors.length > 1
          ? (
            <List>
              {getErrorsList(props.errors).map(error => (
                <ListItem key={cuid()}>
                  <span>{error}</span>
                </ListItem>
              ))}
            </List>
          ) : (
            <span>
              {(_.isArray(errors) && errors.length === 1 && errors[0]) || JSON.stringify(errors)}
            </span>
          )
        }
      </Column>
    </Row>
  );
}
PanelAlert.defaultProps = {
  inverted: false,
  type: 'default',
  title: '',
  iconClass: 'icon-error',
  borderRadius: '0',
};

PanelAlert.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.shape({})]),
      ),
      PropTypes.string,
      PropTypes.object,
      PropTypes.shape({}),
    ],
  ).isRequired,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'default']),
  inverted: PropTypes.bool,
  iconClass: PropTypes.string,
  borderRadius: PropTypes.string,
};

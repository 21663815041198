import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';

const Tooltip = props => (
  <Media query={mediaQueryMin('desktop')}>
    {matches => (matches || props.showOnMobile ? (
      <div title={props.title}>{props.children}</div>
    ) : (
      <div>{props.children}</div>
    ))
    }
  </Media>
);

Tooltip.defaultTypes = {
  showOnMobile: false,
};

Tooltip.propTypes = PropTypes.shape({
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  title: PropTypes.string.isRequired,
  showOnMobile: PropTypes.bool,
}).isRequired;

export default Tooltip;

const FollowingTrans = {
  En: {
    emptyHeader: 'You don\'t have shops followed yet!',
    emptyLabel: 'Check our shops and start following',
    viewShops: 'View Shops',
  },
  Ar: {
    emptyHeader: 'ليس لديك محلات تتابعها حتى الآن!',
    emptyLabel: 'ألق نظرة على محلاتنا وابدأ بالمتابعة',
    viewShops: 'عرض المحلات',
  },
};
export default FollowingTrans;

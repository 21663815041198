export function scrollToContainerTop(className) {
  document.getElementsByClassName(className)[0].scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
}

export function scrollToContainerTopById(id) {
  const element = document.getElementById(id);
  if (element !== null) {
    element.scrollIntoView();
  }
}

export function scrollElementIntoViewById(id, options) {
  const element = document.getElementById(id);
  if (element !== null) {
    element.scrollIntoView(options);
  }
}

export function scrollHtmltoTop() {
  document.getElementsByTagName('html')[0].scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
}

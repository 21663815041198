import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import { Column, Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';

import LineItem from '~/modules/ecommerceOrder/containers/shoppingCart/lineItem/LineItem';
import LineItemLayoutDesktop from '~/modules/ecommerceOrder/containers/shoppingCart/lineItem/LayoutDesktop';
import CartTotal from './CartTotal';
import GrandTotalLabel from './GrandTotalLabel';

const lineItemRequiredFields = [
  'price',
  'itemQuantity',
  'actions',
  'itemImage',
  'itemOptions',
  'itemLabel',
  'vendorLabel',
  'totalPrice',
];

const defaultValuesForRequiredLineItemFields = lineItemRequiredFields.reduce(
  (accumulator, field) => ({ ...accumulator, [field]: <span /> }),
  {},
);

const renderLineItems = (lineItems, isDropdown, smallSpacer, smartCartId, isSmartCart, isCurrentUserSmartCart) => lineItems.map(lineItem => (
  <React.Fragment key={lineItem.id}>
    <LineItem lineItem={lineItem} isSmartCart={isSmartCart} isCurrentUserSmartCart={isCurrentUserSmartCart} smartCartId={smartCartId} isDropdown={isDropdown} />
    <Separator separatorLength="full" spacerSize={smallSpacer ? 1 : 1.5} />
  </React.Fragment>
));

const HeaderCellContainer = styled(Box)`
  z-index: 1;
`;

const TableHeaderEntry = props => (
  <HeaderCellContainer fullWidth centerAligned>
    <Label emphasized md>
      {
        props.children //eslint-disable-line
      }
    </Label>
  </HeaderCellContainer>
);

export const HeaderBackground = styled.div`
  width: 100%;
  height: ${props => props.theme.new.spacer * 4 + 7}px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: ${props => props.theme.new.colors.backgrounds.panel};
  border: ${props => props.theme.borders.size.bold + 2}px solid
    ${props => props.theme.colors.named.white};
  border-bottom: 0;
  border-radius: ${props => 0.5 * props.theme.new.spacer}px;
`;

const TableHeader = () => (
  <React.Fragment>
    <HeaderBackground />
    <LineItemLayoutDesktop
      {...defaultValuesForRequiredLineItemFields}
      rowGap={0}
      itemImage={<TableHeaderEntry>&nbsp;</TableHeaderEntry>}
      itemLabel={<TableHeaderEntry>{i18next.t('ecommerceOrder:Cart.Items.product')}</TableHeaderEntry>}
      price={<TableHeaderEntry>{i18next.t('ecommerceOrder:Cart.Items.price')}</TableHeaderEntry>}
      itemQuantity={<TableHeaderEntry>{i18next.t('ecommerceOrder:Cart.Items.quantity')}</TableHeaderEntry>}
      totalPrice={<TableHeaderEntry>{i18next.t('ecommerceOrder:Cart.Items.totalPrice')}</TableHeaderEntry>}
      actions={<TableHeaderEntry>&nbsp;</TableHeaderEntry>}
    />
  </React.Fragment>
);

// eslint-disable-next-line react/prop-types
const TableFooter = ({ cartTotal }) => (
  <LineItemLayoutDesktop
    {...defaultValuesForRequiredLineItemFields}
    rowGap={0}
    itemImage={(
      <Box fullWidth leftAligned>
        <GrandTotalLabel>{i18next.t('ecommerceOrder:Cart.total')}</GrandTotalLabel>
      </Box>
)}
    totalPrice={<GrandTotalLabel primary>{`${cartTotal} ${currentCurrencyDisplayName()}`}</GrandTotalLabel>}
  />
);

const TableContainer = styled(Column)`
  position: relative;
  border-radius: ${props => 0.5 * props.theme.new.spacer}px !important;
`;

const CartItemsTable = ({
  lineItems, cartTotal, isDropdown, media, smartCartId, isSmartCart, isCurrentUserSmartCart,
}) => (media.minDesktop && !isDropdown ? (
  <TableContainer fullWidth bordered padding={1.5}>
    <TableHeader />
    <Spacer size={2.5} />
    {renderLineItems(lineItems, isDropdown, false, smartCartId, isSmartCart, isCurrentUserSmartCart)}
    <TableFooter cartTotal={cartTotal} />
  </TableContainer>
) : (
  <Column fullWidth>
    <Spacer size={1.5} />
    {renderLineItems(lineItems, isDropdown, true, smartCartId, isSmartCart, isCurrentUserSmartCart)}
    {isDropdown ? undefined : <CartTotal cartTotal={cartTotal} />}
  </Column>
));

CartItemsTable.defaultProps = {
  isDropdown: false,
  smartCartId: null,
  isSmartCart: false,
  isCurrentUserSmartCart: true,
};

CartItemsTable.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cartTotal: PropTypes.number.isRequired,
  media: PropTypes.shape({}).isRequired,
  isDropdown: PropTypes.bool,
  smartCartId: PropTypes.string,
  isSmartCart: PropTypes.bool,
  isCurrentUserSmartCart: PropTypes.bool,
};

export default withMedia(CartItemsTable);

/* eslint-disable react/prop-types */
import React from 'react';
import i18next from 'i18next';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const FormLabel = props => (
  <Row pl={1.5} pb={0.3} fullWidth>
    <Label sm important>{props.children}</Label>
    {props.required && (
      <Label sm error>*</Label>
    )}
  </Row>
);

export default () => ([
  {
    name: 'current_password',
    label: <FormLabel>{i18next.t('accountManagement:Profile.currentPasswordFormLabel')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.currentPassword'),
    placeholder: 'Current Password',
    input_type: 'password',
    type: 'Password',
  },
  {
    name: 'new_password',
    label: <FormLabel>{i18next.t('accountManagement:Profile.newPasswordFormLabel')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.newPassword'),
    placeholder: i18next.t('accountManagement:Profile.newPassword'),
    input_type: 'password',
    type: 'Password',
  },
  {
    name: 'password_confirmation',
    label: <FormLabel>{i18next.t('accountManagement:Profile.confirmPasswordFormLabel')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.confirmPassword'),
    placeholder: 'Confirm Password',
    input_type: 'password',
    type: 'Password',
  },
]);

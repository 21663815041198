import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import styled from 'react-emotion';

import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

import TrackCard from '../../components/productsTrack/TrackCard';
import CardsContainer from './CardsContainer';

const SubDepartmentCategories = styled.div`
  ${CardsContainer};
`;

const CategoriesCards = ({ categoriesInfo, vendorID, vendorName }) => (
  <SubDepartmentCategories>
    {categoriesInfo.children
      && categoriesInfo.children.map(cardInfo => (
        <TrackCard
          cardInfo={cardInfo}
          // eslint-disable-next-line no-underscore-dangle
          key={cardInfo.__id}
          vendorID={vendorID}
          vendorName={vendorName}
        />
      ))}
  </SubDepartmentCategories>
);

CategoriesCards.defaultProps = {
  vendorID: null,
  vendorName: null,
};

CategoriesCards.propTypes = {
  categoriesInfo: PropTypes.shape({
    id: PropTypes.string,
    ref_id: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  vendorID: PropTypes.string,
  vendorName: PropTypes.string,
};

export default withFragmentContainer(
  CategoriesCards,
  graphql`
    fragment CategoriesCards_categoriesInfo on Taxon
      @argumentDefinitions(vendor_id: { type: "ID" }) {
      children(vendor_id: $vendorID) {
        ...TrackCard_cardInfo
      }
    }
  `,
);

import { css } from 'react-emotion';

export const bodyStyle = props => css`
  padding: ${1.5 * props.theme.new.spacer}px 0px;
  padding-bottom: 0px;
`;

// CODE_REVIEW : Remove and check all similar usages
export const headerStyle = props => css`
  font-weight: 600;
  border: 3px solid ${props.theme.new.colors.labels.inverted.primary};
  border-radius: ${0.5 * props.theme.new.spacer}px;
  ${props.media.maxMobile && css`
     padding: ${2 * props.theme.new.spacer}px ${1.5 * props.theme.new.spacer}px;
  `}
`;

import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';

import { scrollToContainerTopById } from '~/modules/core/utils/jsHelpers/ScrollToTop';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const ProductRating = props => (
  <div onClick={() => { scrollToContainerTopById('reviewForm'); }}> {/* eslint-disable-line */}
    <Row centerAligned>
      <Rating
        initialRating={props.rating}
        readonly
        emptySymbol={<img src={`/${imagesPathName}/star-empty.png`} alt="icon" />}
        fullSymbol={<img src={`/${imagesPathName}/star.png`} alt="icon" />}
        direction={props.direction}
      />
      <Spacer size={1} />
      <Label secondary xs>{`( ${props.ratingCount || 0} )`}</Label>
    </Row>
  </div>
);

ProductRating.propTypes = {
  rating: PropTypes.number.isRequired,
  ratingCount: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
};

export default withDirection(ProductRating);

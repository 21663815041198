/**
 * @flow
 * @relayHash 34e58a384faf34eb190d1164de862c7c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdTrack = "education_park" | "health_park" | "homemade" | "products" | "shops" | "%future added value";
export type AdRecommendedQueryVariables = {|
  AdTrack?: ?AdTrack
|};
export type AdRecommendedQueryResponse = {|
  +advertisements: $ReadOnlyArray<?{|
    +button_color: string,
    +button_text: string,
    +button_text_color: string,
    +id: string,
    +large_photo_object: ?{|
      +url_original: ?string
    |},
    +ref_id: string,
    +title: string,
    +title_color: string,
    +sub_title_1: string,
    +sub_title_1_color: string,
    +url: string,
  |}>
|};
export type AdRecommendedQuery = {|
  variables: AdRecommendedQueryVariables,
  response: AdRecommendedQueryResponse,
|};
*/


/*
query AdRecommendedQuery(
  $AdTrack: AdTrack
) {
  advertisements(limit: 1, ad_section: recommended, kind: web, track: $AdTrack) {
    button_color
    button_text
    button_text_color
    id
    large_photo_object {
      url_original
    }
    ref_id
    title
    title_color
    sub_title_1
    sub_title_1_color
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "AdTrack",
    "type": "AdTrack",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "advertisements",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "ad_section",
        "value": "recommended",
        "type": "AdSections"
      },
      {
        "kind": "Literal",
        "name": "kind",
        "value": "web",
        "type": "AdKind"
      },
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1,
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "track",
        "variableName": "AdTrack",
        "type": "AdTrack"
      }
    ],
    "concreteType": "Advertisement",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ref_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_text_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "large_photo_object",
        "storageKey": null,
        "args": null,
        "concreteType": "ImageDetails",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url_original",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_text",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_title_1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_title_1_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AdRecommendedQuery",
  "id": null,
  "text": "query AdRecommendedQuery(\n  $AdTrack: AdTrack\n) {\n  advertisements(limit: 1, ad_section: recommended, kind: web, track: $AdTrack) {\n    button_color\n    button_text\n    button_text_color\n    id\n    large_photo_object {\n      url_original\n    }\n    ref_id\n    title\n    title_color\n    sub_title_1\n    sub_title_1_color\n    url\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AdRecommendedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "AdRecommendedQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4cd0135ecf38807c027a22170f056719';
module.exports = node;

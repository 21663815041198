import t from 'tcomb-form';
import _ from 'lodash';
import React from 'react';
import Checkbox from '~/modules/coreUI/components/legacies/Checkbox';
import { XSmallLabel } from '~/modules/coreUI/components/legacies/Labels';
import { XSmallSpacer, SmallSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { TopAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import PhoneNumber from '~/modules/coreUI/components/compound/PhoneNumber';
import CountriesDropdown from '~/modules/coreUI/components/compound/CountriesDropdown';
import CitiesDropdown from '~/modules/coreUI/components/compound/CitiesDropdown';
import Toggle from '~/modules/coreUI/components/basic/Toggle';
import TextAreaInput from '~/modules/coreUI/components/basic/TextArea';

import DatePicker from '~/modules/coreUI/components/compound/DatePicker';

import Gender from '~/modules/coreUI/components/compound/Gender';
import TextBox from '~/modules/coreUI/components/basic/TextBox';
import UploadImage from '~/modules/coreUI/components/basic/UploadImage/UploadImage';
import DropDown from '~/modules/coreUI/components/basic/Dropdown';

import renderError from './Errors';

// TODO : Change to a more suitable name (i.e. global state)
export const getGlobalAttrs = locals => ({
  onKeyUp: locals.context.onKeyUp,
  tabIndex: locals.attrs && locals.attrs.tabIndex,
});

export default {
  textbox: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <TextBox
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        value={locals.value}
        placeholder={locals.attrs.placeholder}
        selectedValue={locals.attrs.selectedValue}
      />
    ),
    renderError: locals => renderError(locals),
  }),
  password: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <TextBox
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        value={locals.value}
        placeholder={locals.attrs.placeholder}
        password
        icon="fas fa-eye fa-lg"
        iconPosition="right"
      />
    ),
    renderError: locals => renderError(locals),
  }),
  phoneNumber: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <PhoneNumber
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        value={locals.value}
        onChange={(value) => {
          locals.onChange(value);
        }}
      />
    ),
    renderError: locals => renderError(locals),
  }),
  country: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <CountriesDropdown
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        value={locals.value}
        onChange={value => locals.onChange(value)}
        placeholder={locals.attrs.placeholder}
      />
    ),
    renderError: locals => renderError(locals),
  }),
  city: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <CitiesDropdown
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        value={locals.value}
        onChange={value => locals.onChange(value)}
      />
    ),
    renderError: locals => renderError(locals),
  }),
  dropDown: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <DropDown
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        value={locals.value}
        onChange={value => locals.onChange(value)}
      />
    ),
    renderError: locals => renderError(locals),
  }),
  gender: t.form.Form.templates.radio.clone({
    renderRadios: locals => (
      <Gender
        {...getGlobalAttrs(locals)}
        required={locals.attrs.required}
        value={locals.value}
        onChange={locals.onChange}
      />),
    renderError: locals => renderError(locals),
  }),
  checkbox: t.form.Form.templates.checkbox.clone({
    renderCheckbox: (locals) => {
      const attrs = t.form.Form.templates.checkbox.getAttrs(locals);

      return (
        <React.Fragment>
          {locals.attrs.showAsToggle ? (
            // TODO : Handle the same attributes as the usual Checkbox
            <Toggle
              {...getGlobalAttrs(locals)}
              {...attrs}
              bold={attrs.boldLabel}
              semiBold={attrs.semiBoldLabel}
              labelText={attrs.label}
              isChecked={locals.value}
              onChange={locals.onChange}
              isDisabled={attrs.isDisabled && (_.isFunction(attrs.isDisabled)
                ? attrs.isDisabled(locals)
                : attrs.isDisabled
              )}
            />
          ) : (
            <Checkbox
              {...getGlobalAttrs(locals)}
              elemID={attrs.id}
              bold={attrs.importantLabel}
              label={attrs.label}
              fontSize={attrs.fontSize}
              {...attrs}
            />
          )}
          {attrs.checkboxNote && (
            <React.Fragment>
              <SmallSpacer />
              <TopAlignedRow>
                <XSmallSpacer />
                <XSmallLabel color="subtle" paragraph>
                  {attrs.checkboxNote}
                </XSmallLabel>
              </TopAlignedRow>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    },
    renderError: locals => renderError(locals),
  }),
  datepicker: t.form.Form.templates.date.clone({
    renderDate: locals => (
      <DatePicker
        {...getGlobalAttrs(locals)}
        value={locals.value}
        onChange={locals.onChange}
        {...locals.attrs}
      />),
    renderError: locals => renderError(locals),
  }),
  textarea: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <TextAreaInput
        {...getGlobalAttrs(locals)}
        onKeyUp={null} // WORKAROUND to prevent submit form when press enter
        onChange={locals.onChange}
        inputValue={locals.value}
        value={locals.value}
        placeholder={locals.attrs.placeholder}
        {...locals.attrs}
      />
    ),
    renderError: locals => renderError(locals),
  }),
  uploadImage: t.form.Form.templates.textbox.clone({
    renderInput: locals => (
      <UploadImage
        {...getGlobalAttrs(locals)}
        {...locals.attrs}
        {...locals}
        onChange={locals.onChange}
        value={locals.value}
      />
    ),
    renderError: locals => renderError(locals),
  }),
};

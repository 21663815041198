import React from 'react';
import { graphql } from 'react-relay';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

const TaxonLink = ({ taxonInfo, departmentId, departmentName }) => (
  <List.Item refid={taxonInfo.ref_id}>
    {/* <ExternalLink url={getTaxonLink(taxonInfo.ref_id, taxonInfo.name, taxonInfo.type)}> */}
    <ExternalLink url={urlFor('getTaxonLink', [taxonInfo, departmentId, departmentName])}>
      {taxonInfo.name}
    </ExternalLink>
  </List.Item>
);

TaxonLink.propTypes = {
  taxonInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    ref_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  departmentId: PropTypes.string,
  departmentName: PropTypes.string,
};

TaxonLink.defaultProps = {
  departmentId: null,
  departmentName: null,
};

export default withFragmentContainer(
  TaxonLink,
  graphql`
    fragment TaxonLink_taxonInfo on Taxon {
      id
      ref_id
      name
      type
    }
  `,
);

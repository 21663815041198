/**
 * @flow
 * @relayHash 8fff5da36a361192347b02c0b8062faf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecoverPasswordFormMutationVariables = {|
  user_signin: string
|};
export type RecoverPasswordFormMutationResponse = {|
  +forgot_password_send_code: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
    +message: ?string,
    +verification_via: ?string,
  |}
|};
export type RecoverPasswordFormMutation = {|
  variables: RecoverPasswordFormMutationVariables,
  response: RecoverPasswordFormMutationResponse,
|};
*/


/*
mutation RecoverPasswordFormMutation(
  $user_signin: String!
) {
  forgot_password_send_code(user_signin: $user_signin) {
    errors {
      field
      messages
      code
    }
    message
    verification_via
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "user_signin",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "forgot_password_send_code",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user_signin",
        "variableName": "user_signin",
        "type": "String"
      }
    ],
    "concreteType": "ForgotPassword",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "verification_via",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RecoverPasswordFormMutation",
  "id": null,
  "text": "mutation RecoverPasswordFormMutation(\n  $user_signin: String!\n) {\n  forgot_password_send_code(user_signin: $user_signin) {\n    errors {\n      field\n      messages\n      code\n    }\n    message\n    verification_via\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RecoverPasswordFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "RecoverPasswordFormMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a77f5b9fa4d41d7865211b129c5d6c8';
module.exports = node;

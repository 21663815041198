/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import styled from 'react-emotion';
import i18next from 'i18next';

import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { SmallTitle } from '~/modules/ecommerceCoreUI/components/basic/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const FooterLinksItems = styled.div`
  ${cssMediaMax.tablet`
    width: 230px;
  `};
`;

const ExternalLinkStyle = styled(ExternalLink)`
  color: ${props => props.theme.colors.labels.normal};
  font-size: ${props => props.theme.fonts.sizes.small}px;
  &:hover,
  &:active {
    color: ${props => props.theme.colors.labels.important};
  }
`;
const FooterLinks = ({ links, title }) => (
  <FooterLinksItems key={title}>
    {title ? (
      <React.Fragment>
        <SmallTitle>{title}</SmallTitle>
        <LargeSpacer size={12} />
      </React.Fragment>
    ) : (
      ' '
    )}
    {links.map(({
      name,
      key,
      id,
      url,
    }) => (
      <React.Fragment key={(name || i18next.t(key)) + id}>
        <ExternalLinkStyle url={url}>{i18next.t(name) || i18next.t(key)}</ExternalLinkStyle>
        <LargeSpacer size={12} />
      </React.Fragment>
    ))}
  </FooterLinksItems>
);

export default translate('ecommerceCoreUI')(FooterLinks);

FooterLinks.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.any,
      title: PropTypes.any,
    }),
  ).isRequired,
};

FooterLinks.defaultProps = {
  title: ' ',
};

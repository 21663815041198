import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import NavProductsItems from '~/modules/ecommerceCoreUI/components/header/headerActions/NavProductsItems';
import SecuredCheckout from '~/modules/ecommerceCoreUI/components/header/headerActions/SecuredCheckout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import { MenuContainer, BurgerMenuIcon, ImageLogo } from './HeaderMobile';

class HeaderMobile extends Component {
  showSideMenu = () => {
    document.getElementById('root').classList.toggle('root-animation');
    document.getElementsByTagName('body')[0].classList.toggle('side-menu-open');
  };

  render() {
    return (
      <MenuContainer>
        <Menu.Item className="menu-btn">
          <BurgerMenuIcon name="bars" onClick={this.showSideMenu} />
        </Menu.Item>
        <Menu.Item>
          <Link to="/" href="/">
            <ImageLogo
              src={
                this.props.direction === 'rtl'
                  ? `/${imagesPathName}/Header/logo-header.png`
                  : `/${imagesPathName}/Header/logo-en-lg.png`
                }
              className="logo"
            />
          </Link>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <SecuredCheckout />
          </Menu.Item>
          <Menu.Item>
            <NavProductsItems />
          </Menu.Item>
        </Menu.Menu>
      </MenuContainer>
    );
  }
}
HeaderMobile.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default withDirection(HeaderMobile);

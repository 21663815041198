import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'react-relay';

import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

import DepartmentPanLinkListGroup from './DepartmentPanLinkListGroup';

const DepartmentBrands = ({
  topBrands, departmentId, departmentName, hideDepartments,
}) => (
  <DepartmentPanLinkListGroup
    taxonList={topBrands}
    groupName="Brands"
    departmentId={departmentId}
    departmentName={departmentName}
    hideDepartments={hideDepartments}
  />
);

DepartmentBrands.propTypes = {
  topBrands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  departmentId: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  hideDepartments: PropTypes.func.isRequired,
};

export default withFragmentContainer(
  DepartmentBrands,
  graphql`
    fragment DepartmentBrands_topBrands on Taxon @relay(plural: true) {
      ...TaxonLink_taxonInfo
    }
  `,
);

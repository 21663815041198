/**
 * @flow
 * @relayHash a69a847c20487b34d2f334a2012c2272
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecommendedSectionResponsiveQueryVariables = {||};
export type RecommendedSectionResponsiveQueryResponse = {|
  +recommended_products: ?{|
    +result: ?$ReadOnlyArray<?{|
      +name: string
    |}>
  |}
|};
export type RecommendedSectionResponsiveQuery = {|
  variables: RecommendedSectionResponsiveQueryVariables,
  response: RecommendedSectionResponsiveQueryResponse,
|};
*/


/*
query RecommendedSectionResponsiveQuery {
  recommended_products {
    result {
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RecommendedSectionResponsiveQuery",
  "id": null,
  "text": "query RecommendedSectionResponsiveQuery {\n  recommended_products {\n    result {\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RecommendedSectionResponsiveQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              v0
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RecommendedSectionResponsiveQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '44b11351e55d901e04fac080e5290b32';
module.exports = node;

const ShopCardTrans = {
  En: {
    followButton: 'Follow',
    followingButton: 'Following',
  },
  Ar: {
    followButton: 'تابع',
    followingButton: 'متابَع',
  },
};
export default ShopCardTrans;

/**
 * @flow
 * @relayHash 61f10fc06841d97779ebe218c16f8032
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrdersList_queryResult$ref = any;
export type OrdersListInput = {
  page?: ?PageInfoInput
};
export type PageInfoInput = {
  limit?: ?number,
  offset?: ?number,
};
export type OrdersListQueryVariables = {|
  input?: ?OrdersListInput
|};
export type OrdersListQueryResponse = {|
  +$fragmentRefs: OrdersList_queryResult$ref
|};
export type OrdersListQuery = {|
  variables: OrdersListQueryVariables,
  response: OrdersListQueryResponse,
|};
*/


/*
query OrdersListQuery(
  $input: OrdersListInput
) {
  ...OrdersList_queryResult_2VV6jB
}

fragment OrdersList_queryResult_2VV6jB on Query {
  completed_orders(input: $input) {
    page_info {
      ...PaginationData_pageInfo
    }
    errors {
      field
      messages
      code
    }
    orders {
      eligible_refund
      progress_bar {
        status
        name
      }
      product_type
      delivery_date
      tracking_number
      id
      created_at
      notes
      admin_rejection_note
      shipment_state
      state
      number
      vendor {
        name
        rating_count
        avg_rating
        ref_id
        id
      }
      line_items {
        id
        ref_id
        eligible_refund
        variant {
          slug
          vendor_variants_count
          name
          rating_count
          avg_rating
          images_attachments {
            url_small
          }
          option_values {
            id
            presentation
          }
          id
        }
        quantity
      }
    }
  }
}

fragment PaginationData_pageInfo on PageInfo {
  current_page
  items_count
  limit
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "OrdersListInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eligible_refund",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OrdersListQuery",
  "id": null,
  "text": "query OrdersListQuery(\n  $input: OrdersListInput\n) {\n  ...OrdersList_queryResult_2VV6jB\n}\n\nfragment OrdersList_queryResult_2VV6jB on Query {\n  completed_orders(input: $input) {\n    page_info {\n      ...PaginationData_pageInfo\n    }\n    errors {\n      field\n      messages\n      code\n    }\n    orders {\n      eligible_refund\n      progress_bar {\n        status\n        name\n      }\n      product_type\n      delivery_date\n      tracking_number\n      id\n      created_at\n      notes\n      admin_rejection_note\n      shipment_state\n      state\n      number\n      vendor {\n        name\n        rating_count\n        avg_rating\n        ref_id\n        id\n      }\n      line_items {\n        id\n        ref_id\n        eligible_refund\n        variant {\n          slug\n          vendor_variants_count\n          name\n          rating_count\n          avg_rating\n          images_attachments {\n            url_small\n          }\n          option_values {\n            id\n            presentation\n          }\n          id\n        }\n        quantity\n      }\n    }\n  }\n}\n\nfragment PaginationData_pageInfo on PageInfo {\n  current_page\n  items_count\n  limit\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OrdersListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OrdersList_queryResult",
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrdersListQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "completed_orders",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "OrdersListInput"
          }
        ],
        "concreteType": "CompletedOrders",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "page_info",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "current_page",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "items_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "limit",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "errors",
            "storageKey": null,
            "args": null,
            "concreteType": "Error",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "field",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "messages",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "code",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orders",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "notes",
                "args": null,
                "storageKey": null
              },
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "product_type",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "delivery_date",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tracking_number",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "created_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "progress_bar",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderStates",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  v3
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "admin_rejection_note",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shipment_state",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "state",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "vendor",
                "storageKey": null,
                "args": null,
                "concreteType": "Shop",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "line_items",
                "storageKey": null,
                "args": null,
                "concreteType": "LineItem",
                "plural": true,
                "selections": [
                  v2,
                  v6,
                  v1,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "variant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Variant",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "vendor_variants_count",
                        "args": null,
                        "storageKey": null
                      },
                      v3,
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "images_attachments",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ImageAttachment",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "url_small",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "option_values",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OptionValue",
                        "plural": true,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "presentation",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      v2
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quantity",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1923669f1bd17af3a56d8d399a329647';
module.exports = node;

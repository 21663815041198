import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { graphql } from 'react-relay';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import HomeSlider from './HomeSlider';

const View = styled.img`
  height: 400px;
  object-fit: cover;
  border: 1px solid ${props => props.theme.borders.color.light};
  border-radius: ${props => props.theme.borders.radius.large}px;
  ${cssMediaMax.tablet`
    height: 261px;
  `}
  ${cssMediaMax.xsmall`
    height: 138px;
    border: none;
    border-radius: 0;
  `};
`;
const ViewDetails = withDirection(styled.div`
  position: absolute;
  ${props => (props.direction === 'rtl'
    ? css`
      right: ${props.theme.new.spacer * 6}px;
      text-align: right;
      align-items: flex-end;
    `
    : css`
      left: ${props.theme.new.spacer * 6}px;
      text-align: left;
      align-items: flex-start;
  `)}
  top: 0;
  height: 100%;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  ${cssMediaMax.tablet`
    width: 70%;
  `};
  ${props => (props.direction === 'rtl'
    ? cssMediaMax.tablet`
        right: 20px;
      `
    : cssMediaMax.tablet`
        left: 20px;
      `
  )}
`);
const Item = styled.div`
  position: relative;
  padding: 0 10px;
  ${cssMediaMax.xsmall`
    padding: 0;
  `};
  &:focus {
    border: none;
    outline: 0;
  }
`;
const Header = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.theme.fonts.sizes.xxxLarge - 6}px;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: capitalize;
  line-height: 1.1;
  ${cssMediaMax.tablet`
    font-size: 36px;
  `};
  ${cssMediaMax.mobile`
    font-size: 30px;
  `};
  ${cssMediaMax.xsmall`
    font-size: 20px;
  `};
`;
const SubHeader = styled.div`
  text-transform: capitalize;
  color: ${props => props.color};
  font-size: ${props => props.theme.new.fonts.sizes.header}px;
  letter-spacing: -0.4px;
  line-height: 1.1;
  ${cssMediaMax.tablet`
    font-size: 25px;
  `};
  ${cssMediaMax.mobile`
    font-size: 20px;
  `};
  ${cssMediaMax.xsmall`
    font-size: 18px;
  `};
`;
const SmallHeader = styled.div`
  text-transform: capitalize;
  color: ${props => props.color};
  font-size: ${props => props.theme.new.fonts.sizes.xxl}px;
  letter-spacing: -0.4px;
  line-height: 1.1;
  ${cssMediaMax.tablet`
    font-size: 20px;
  `};
  ${cssMediaMax.mobile`
    font-size: 18px;
  `};
  ${cssMediaMax.xsmall`
    font-size: 14px;
  `};
`;
const Button = styled.button`
  text-transform: capitalize;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  font-size: 18px;
  border-radius: 5px;
  border: none;
  padding: 11px 18px;
  cursor: pointer;
  ${cssMediaMax.xsmall`
    font-size: 13px;
    padding: 9px 15px;
  `};
`;

class HomeMainSlider extends React.PureComponent {
  render = () => {
    const { queryResult, isLoading } = this.props;
    return (
      <React.Fragment>
        <Spacer size={[0, 2.5]} />
        {!isLoading && (
          <HomeSlider childrenCount={queryResult.slides}>
            {queryResult
              && queryResult.slides
              && queryResult.slides.map(slide => (
                <Item key={slide.id}>
                  <ExternalLink url={slide.url} target="_self">
                    <View src={slide.large_photo_object.url_original} alt="" />
                    <ViewDetails className="details">
                      <Spacer size={[1, 1, 1, 7]} />
                      {slide.title && (
                        <React.Fragment>
                          <Header color={slide.title_color}>{slide.title}</Header>
                          <Spacer size={0.2} />
                        </React.Fragment>
                      )}
                      {slide.sub_title_1 && (
                        <React.Fragment>
                          <SubHeader color={slide.sub_title_1_color}>{slide.sub_title_1}</SubHeader>
                          <Spacer size={0.5} />
                        </React.Fragment>
                      )}
                      {slide.sub_title_2 && (
                        <React.Fragment>
                          <SmallHeader color={slide.sub_title_2_color}>{slide.sub_title_2}</SmallHeader>
                          <Spacer size={[1, 1, 1, 2.2]} />
                        </React.Fragment>
                      )}
                      {slide.button_text && (
                        <Button bg={slide.button_color} color={slide.button_text_color}>
                          {slide.button_text}
                        </Button>
                      )}
                    </ViewDetails>
                  </ExternalLink>
                </Item>
              ))}
          </HomeSlider>
        )}
      </React.Fragment>
    );
  }
}
HomeMainSlider.propTypes = {
  queryResult: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
};
HomeMainSlider.defaultProps = {
  queryResult: null,
};
export default withRootQuery(
  HomeMainSlider,
  graphql`
    query HomeMainSliderQuery {
      slides {
        button_color
        button_text
        button_text_color
        featured_shop
        id
        large_photo_object {
          url_original
        }
        ref_id
        sub_title_1
        sub_title_1_color
        sub_title_2
        sub_title_2_color
        title
        title_color
        url
      }
    }
  `,
  HomeMainSlider,
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import i18next from 'i18next';

import Button from '~/modules/coreUI/components/basic/Button';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import ImageExtentions from '~/modules/ecommerceCore/utils/imageExtentions';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

const Input = withDirection(styled.input`
  height: 100%;
  width: ${props => 10.7 * props.theme.new.spacer}px;
  position: absolute;
  top: 0;
  opacity: 0;
  ${props => (props.direction === 'rtl'
    ? css`
       left: auto;
       right: 0;
      `
    : css`
      left: 0;
      right: auto;
    `)}
`);

const CustomUploadImage = styled(Row)`
   position: relative;
   padding: ${props => 0.8 * props.theme.new.spacer}px;
   border: 1px solid ${props => props.theme.inputs.borderColor};
   border-radius: 100vh;
`;

const UploadLabel = styled(Label)`
  width: ${props => 16.7 * props.theme.new.spacer}px;
  height: ${props => 2 * props.theme.new.spacer}px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MEGA_BYTES = 1024 * 1024;

class UploadImage extends Component {
  state = {
    uploadedImageName: this.props.value || i18next.t('accountManagement:Profile.uploadLabel'),
  }

  notifyErrorAlert = (error) => {
    this.props.notifyAlert({
      component: () => (
        <Label inverted important semiBold>
          <i className="el-icon el-icon-error" />
          &nbsp;
          {error}
        </Label>
      ),
      type: AlertTypes.error,
      opacity: 1,
      toastID: 'Upload_image_error_toast',
    });
  }

  onChange = (e) => {
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      if (ImageExtentions.exec(e.target.files[0].name)) {
        if (e.target.files[0].size <= 5 * MEGA_BYTES) {
          reader.onload = (event) => {
            this.props.onChange(event.target.result);
          };
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ uploadedImageName: e.target.files[0].name });
        } else {
          e.target.value = '';
          this.notifyErrorAlert(i18next.t('ecommerceOrder:askAyk.messageOfimageMaxSize'));
        }
      } else {
        e.target.value = '';
        this.notifyErrorAlert(i18next.t('ecommerceOrder:askAyk.messageOfNotValidExtenstion'));
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <CustomUploadImage>
          <Button fullRound passive sm width="97px">{i18next.t('accountManagement:Profile.uploadButtonText')}</Button>
          <Input
            type="file"
            onChange={this.onChange}
            accept="image/*"
            name="myfile"
          />
          <Spacer size={0.5} />
          <UploadLabel important size="sm">
            {this.state.uploadedImageName}
          </UploadLabel>
        </CustomUploadImage>
      </React.Fragment>
    );
  }
}

UploadImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  notifyAlert: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default withAlertMsg(UploadImage);

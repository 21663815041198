/**
 * @flow
 * @relayHash ccfae8b55be2b2fd617e33a71cb8a03c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecommendedTabsQueryVariables = {||};
export type RecommendedTabsQueryResponse = {|
  +tracks: $ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +track_type: ?string,
  |}>,
  +recommended_products: ?{|
    +result: ?$ReadOnlyArray<?{|
      +name: string
    |}>
  |},
|};
export type RecommendedTabsQuery = {|
  variables: RecommendedTabsQueryVariables,
  response: RecommendedTabsQueryResponse,
|};
*/


/*
query RecommendedTabsQuery {
  tracks(input: {only_has_recommended: true}) {
    id
    name
    track_type
  }
  recommended_products {
    result {
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tracks",
  "storageKey": "tracks(input:{\"only_has_recommended\":true})",
  "args": [
    {
      "kind": "Literal",
      "name": "input",
      "value": {
        "only_has_recommended": true
      },
      "type": "TrackFilterInput"
    }
  ],
  "concreteType": "Track",
  "plural": true,
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "track_type",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RecommendedTabsQuery",
  "id": null,
  "text": "query RecommendedTabsQuery {\n  tracks(input: {only_has_recommended: true}) {\n    id\n    name\n    track_type\n  }\n  recommended_products {\n    result {\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RecommendedTabsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              v1
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RecommendedTabsQuery",
    "argumentDefinitions": [],
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_products",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              v1,
              v0
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff04f7cfbcce655a78bb16ab5b3d69f6';
module.exports = node;

const MembershipsDetails = [
  {
    text: 'ecommerceOrder:Loyalty.silverMembership',
    smallText: 'ecommerceOrder:Loyalty.silver',
    crownSrc: `/${imagesPathName}/silver-crown.png`,
    type: 'silver',
    backgroundColor: '#adadad',
    textColor: '#6a6a6a',
    cardBackgroundColor: 'linear-gradient(229deg, rgba(237, 237, 237, 0.5), rgba(195, 195, 195, 0.5))',
  },
  {
    text: 'ecommerceOrder:Loyalty.goldMembership',
    smallText: 'ecommerceOrder:Loyalty.gold',
    crownSrc: `/${imagesPathName}/gold-crown.png`,
    type: 'gold',
    backgroundColor: '#e5c462',
    textColor: '#cca659',
    cardBackgroundColor: 'linear-gradient(229deg, rgba(299, 196, 97, 0.5), rgba(178, 128, 27, 0.5))',

  },
  {
    text: 'ecommerceOrder:Loyalty.diamondMembership',
    smallText: 'ecommerceOrder:Loyalty.diamond',
    crownSrc: `/${imagesPathName}/diamond-crown.png`,
    type: 'diamond',
    backgroundColor: '#6986be',
    textColor: '#6986be',
    cardBackgroundColor: 'linear-gradient(229deg, rgba(229, 239, 255, 0.5), rgba(147, 176, 223, 0.5))',
  },

];

export default MembershipsDetails;

import React from 'react';
import styled, { css } from 'react-emotion';
import { withRouter } from 'react-router-dom';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Icon from '~/modules/coreUI/components/basic/Icon';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';

import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';
import DeleteSmartCart from '../smartCartActions/DeleteSmartCart';
import BuySmartCart from '../smartCartActions/BuySmartCart';


const SmartNameLabel = withMedia(styled(Label)`
  display: inline-block;
  width: ${props => 30 * props.theme.new.spacer}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover{
    color: ${props => props.theme.new.colors.labels.normal.important}
  }
  ${props => props.media.maxMobile && css`
     width: ${25 * props.theme.new.spacer}px;
  `}
`);

const AddProductLink = withMedia(styled(Label)`
  display: inline-block;
  width: ${props => 11.8 * props.theme.new.spacer}px;
`);

export const cursorPoiner = css`
  cursor: pointer;
`;

const redirectToProductsPage = (e) => {
  e.stopPropagation();
  e.preventDefault();
  externalRedirect(urlFor('getProductTracksLink'));
};

const SmartCartListLayout = (props) => {
  const Layout = props.media.minDesktop
    ? DesktopLayout
    : MobileLayout;

  return (
    <Layout
      smartCart={props.smartCart}
      smartName={(
        <SmartNameLabel className={cursorPoiner} semiBold>
          {props.smartCart.name}
        </SmartNameLabel>
     )}
      noOfItems={(
        <Row spaceBetween={0.5}>
          <Label className={cursorPoiner} primary>
            {props.smartCart.quantity}
          </Label>
          <Label className={cursorPoiner}>
            {i18next.t('ecommerceOrder:smartCart.itemsLabel')}
          </Label>
        </Row>
     )}
      totalPrice={(
        <Label className={cursorPoiner} primary semiBold size={['lg', 'lg', 'lg', 'lg']}>
          {props.smartCart.amount}
          {' '}
          {currentCurrencyDisplayName()}
        </Label>
     )}
      actions={(
        <Row fullWidth spaceBetween={1} rightJustified={props.media.minDesktop} spaceBetweenJustified={!props.media.minDesktop}>
          <DeleteSmartCart smartCart={props.smartCart} />
          {props.media.minDesktop && <Separator vertical spacerSize={0.5} separatorLength={props.smartCart.quantity !== 0 ? 'large' : 'full'} />}
          {props.smartCart.quantity !== 0 ? (
            <BuySmartCart smartCart={props.smartCart} />
          ) : (
            <AddProductLink onClick={event => redirectToProductsPage(event)}>
              <Row centerJustified>
                <Icon title={i18next.t('ecommerceOrder:smartCart.addProductLinkText')} secondary className="el-icon el-icon-products" />
                <Spacer size={0.5} />
                <Label semiBold secondary>
                  {i18next.t('ecommerceOrder:smartCart.addProductLinkText')}
                </Label>
              </Row>
            </AddProductLink>
          )}
        </Row>
      )}
    />
  );
};

SmartCartListLayout.propTypes = {
  media: PropTypes.shape({
    minDesktop: PropTypes.bool.isRequired,
  }).isRequired,
  smartCart: PropTypes.shape().isRequired,
};


export default withRouter(withUserInfo(withCartInfo(withMedia(SmartCartListLayout))));

import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import injectElementBetweenChildElements from '~/modules/core/utils/jsHelpers/injectElementBetweenChildElements';
import { boxColorsStyles, responsiveStyle } from '~/modules/coreUI/utils/infereStyle';
import spaceStyles from '~/modules/coreUI/utils/styleSystem';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import Spacer from './Spacer';

// eslint-disable-next-line react/prop-types
const getIntraItemsSpacer = props => (props.spaceBetween
  ? <Spacer size={props.spaceBetween} />
  : null
);

const getBorderColor = (props) => {
  let color = 'light';
  if (props.borderColor) {
    color = props.borderColor;
  }

  return props.theme.borders.color[color] || props.theme.new.colors.named[color] || color;
};

const getBackgroundColor = (props) => {
  let color = null;
  if (props.backgroundColor) {
    color = props.backgroundColor;
  }

  return props.theme.new.colors.backgrounds[color] || color;
};

const getBorderWeight = (props) => {
  let weight = 'thin';
  if (props.borderWeight) {
    weight = props.borderWeight;
  }

  return props.theme.borders.size[weight];
};

const getBorderRadius = (props) => {
  let radius = '0';
  if (props.borderRadius) {
    radius = props.borderRadius;
  }

  return props.theme.borders.radius[radius] || radius;
};

const getJustifyContent = (props) => {
  if (props.spaceEvenlyJustified) {
    return 'space-evenly';
  }
  if (props.spaceAroundJustified) {
    return 'space-around';
  }
  if (props.spaceBetweenJustified) {
    return 'space-between';
  }
  if (props.stretchJustified) {
    return 'stretch';
  }
  if (props.topJustified) {
    return 'flex-start';
  }
  if (props.centerJustified) {
    return 'center';
  }
  if (props.bottomJustified) {
    return 'flex-end';
  }
  if (props.leftJustified) {
    return 'flex-start';
  }
  if (props.rightJustified) {
    return 'flex-end';
  }
  return null;
};

const getAlignItems = (props) => {
  if (props.stretchAligned) {
    return 'stretch';
  }
  if (props.centerAligned) {
    return 'center';
  }
  if (props.topAligned) {
    return 'flex-start';
  }
  if (props.bottomAligned) {
    return 'flex-end';
  }
  if (props.leftAligned) {
    return 'flex-start';
  }
  if (props.rightAligned) {
    return 'flex-end';
  }
  if (props.baselineAligned) {
    return 'baseline';
  }
  return null;
};

const getFlexWrap = (props) => {
  if (props.wrap) {
    return 'wrap';
  }
  if (props.wrapReverse) {
    return 'wrap-reverse';
  }

  return 'nowrap';
};

const StyledLinearLayout = withDirection(withMedia(styled.div`
  width: ${props => (props.fullWidth ? '100%' : props.width)};
  height: ${props => (props.fullHeight ? '100%' : props.height)};
  box-sizing: border-box;

  display: flex;
  position: ${props => props.position};
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  flex-grow: ${props => (props.grow ? 1 : null)};
  flex-wrap: ${props => getFlexWrap(props)};
  flex-shrink: ${props => props.shrink};
  justify-content: ${props => getJustifyContent(props) || 'flex-start'};
  ${props => responsiveStyle(props, 'justifyContent', justifyContentValue => css`
    justify-content: ${justifyContentValue};
  `)};

  align-items: ${props => getAlignItems(props) || 'center'};
  ${props => responsiveStyle(props, 'alignItems', alignItemsValue => css`
    align-items: ${alignItemsValue};
  `)};

  border: ${props => props.bordered && `solid ${getBorderWeight(props)}px ${getBorderColor(props)}`};
  border-radius: ${props => getBorderRadius(props)}px;
  border-top: ${props => props.topBordered && `solid ${getBorderWeight(props)}px ${getBorderColor(props)}`};
  border-left: ${props => props.leftBordered && `solid ${getBorderWeight(props)}px ${getBorderColor(props)}`};
  border-bottom: ${props => props.bottomBordered && `solid ${getBorderWeight(props)}px ${getBorderColor(props)}`};
  border-right: ${props => props.rightBordered && `solid ${getBorderWeight(props)}px ${getBorderColor(props)}`};

  padding: ${props => props.padding * props.theme.new.spacer}px;
  ${props => (props.direction === 'rtl'
    ? css`
        padding-left: ${props.paddingRight * props.theme.new.spacer}px;
        padding-right: ${props.paddingLeft * props.theme.new.spacer}px;
          `
    : css`
        padding-left: ${props.paddingLeft * props.theme.new.spacer}px;
        padding-right: ${props.paddingRight * props.theme.new.spacer}px;
    `)};

  padding-top: ${props => props.paddingTop * props.theme.new.spacer}px;
  padding-bottom: ${props => props.paddingBottom * props.theme.new.spacer}px;
  background-color: ${props => getBackgroundColor(props)};

  ${props => spaceStyles(props)}
  ${props => boxColorsStyles(props)}
  ${props => props.customStyles && props.customStyles(props)}
`));

export const LinearLayout = props => (
  <StyledLinearLayout {...props}>
    {injectElementBetweenChildElements(
      props.children, // eslint-disable-line react/prop-types
      getIntraItemsSpacer(props),
      true,
    )}
  </StyledLinearLayout>
);

export const Column = props => <LinearLayout column {...props} />;

export const Row = props => <LinearLayout row {...props} />;

export const Box = props => <LinearLayout {...props} />;

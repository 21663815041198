import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { GridArea } from '~/modules/coreUI/components/layouts/helpers/GridLayout';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const MobileGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template:
    'itemImage                itemLabel           actions' max-content
    'itemImage                vendorLabel         actions' max-content
    'itemImage                itemOptions         actions' auto
    'itemQuantity             price               actions' auto
    / max-content auto auto;

  grid-column-gap: ${props => 1 * props.theme.new.spacer}px;
  grid-row-gap: ${props => 0.5 * props.theme.new.spacer}px;
`;

class LayoutMobile extends Component {
  state = {};

  render() {
    return (
      <MobileGrid>
        <GridArea gridArea="itemImage">{this.props.itemImage}</GridArea>
        <GridArea gridArea="itemLabel" fullWidth leftAligned>
          {this.props.itemLabel}
        </GridArea>
        <GridArea gridArea="vendorLabel" fullWidth leftAligned>
          {this.props.vendorLabel}
        </GridArea>
        <GridArea gridArea="itemOptions" bottomJustified leftAligned>
          {this.props.itemOptions}
        </GridArea>
        <GridArea gridArea="price" pt={[0.5, 0.5, 0.75]} leftAligned spaceBetween={0.5}>
          <Row leftJustified centerAligned spaceBetween={0.5}>
            {this.props.price}
            {this.props.priceWarnings}
          </Row>
          <Row leftJustified centerAligned spaceBetween={0.5}>
            {this.props.leftInStockWarning}
            {this.props.nonPriceWarnings}
          </Row>
        </GridArea>
        <GridArea gridArea="itemQuantity" leftAligned>
          {this.props.itemQuantity}
        </GridArea>
        <GridArea gridArea="actions" rightJustified rightAligned>
          {this.props.actions}
        </GridArea>
      </MobileGrid>
    );
  }
}

LayoutMobile.defaultProps = {
  priceWarnings: null,
  nonPriceWarnings: null,
  leftInStockWarning: null,
};

LayoutMobile.propTypes = {
  price: PropTypes.element.isRequired,
  priceWarnings: PropTypes.element,
  itemQuantity: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  leftInStockWarning: PropTypes.element,
  itemImage: PropTypes.element.isRequired,
  itemOptions: PropTypes.element.isRequired,
  itemLabel: PropTypes.element.isRequired,
  vendorLabel: PropTypes.element.isRequired,
  nonPriceWarnings: PropTypes.element,
};

export default LayoutMobile;

import { graphql } from 'relay-runtime';

const InsuranceCompaniesQuery = graphql`
  query InsuranceCompaniesQuery {
    insurance_companies {
      id
      name
      ref_id
    }
  }
`;


export default InsuranceCompaniesQuery;

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import {
  XLargeLabel,
  XXLargeLabel,
  MediumLabel,
  LargeLabel,
  SmallLabel,
} from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { kFormatter } from './KFormatterUtily';
import Ribbon from './Ribbon';
import Crown from './Crown';
import CardSeparator from './CardSeparator';
import CardLogo from './CardLogo';
import MembershipsDetails from './MembershipsDetails';

const PointsCardWrapper = styled(Column)`
  margin: auto;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  text-align: center;
  background: ${props => props.cardBackgroundColor};
  overflow: hidden;
`;
const Qr = styled.img`
  width: ${props => 7 * props.theme.new.spacer}px;
`;
const RowWrapper = styled(Row)`
  padding: ${props => props.theme.paddings.medium}px ${props => props.theme.paddings.large}px;
  ${props => cssMediaMax.xsmall`
    padding: ${props.theme.paddings.medium}px
  `}
`;
const StrongItem = styled.span`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  text-transform: capitalize;
`;
const MediumLabelWrapper = styled(MediumLabel)`
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.xSmall}px;
  `}
  color: ${props => props.color};
`;
const XLargeLabelWrapper = styled(XLargeLabel)`
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.medium}px;
  `}
  color: ${props => props.color};
`;
const XXLargeLabelWrapper = styled(XXLargeLabel)`
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.large + 2}px;
  `}
  color: ${props => props.color};
`;
const LargeLabelWrapper = styled(LargeLabel)`
  text-transform: capitalize;
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.medium}px;
  `}
  color: ${props => props.color};
`;

const CapitalizeText = styled.span`
  text-transform: capitalize;
`;
const SmallLabelStyle = styled(SmallLabel)`
  color: ${props => props.color};
`;
const AykPointsCard = ({
  queryResult: {
    ayk_loyalty_program: {
      degree,
      points_to_next_level,
      points,
      active_gifts_count,
      points_to_next_gift,
      qr_code: {
        url_thumb,
      },
    },
    last_name,
    first_name,
  },
}) => (
  <Row>
    {MembershipsDetails.map(Memberships => (
      <React.Fragment key={Memberships.type}>
        {Memberships.type === degree && (
        <PointsCardWrapper width="100%" key={Memberships.type} cardBackgroundColor={Memberships.cardBackgroundColor} spaceBetweenJustified>
          <Row width="100%" spaceBetweenJustified topAligned>
            <Ribbon degree={degree} />
            <div>
              <Spacer size={[1, 1, 1.2]} />
              <StrongItem>
                <XLargeLabelWrapper color={Memberships.textColor}>{i18next.t(Memberships.text)}</XLargeLabelWrapper>
              </StrongItem>
              <Spacer size={0.5} />
              <Column spaceBetween={[1, 1, 1.5]}>
                <div>
                  <XXLargeLabelWrapper color={Memberships.textColor}>
                    <StrongItem>
                      <CapitalizeText>
                        {`${kFormatter(points)} `}
                      </CapitalizeText>
                    </StrongItem>
                  </XXLargeLabelWrapper>
                  <XLargeLabelWrapper color={Memberships.textColor}>
                    {i18next.t('ecommerceOrder:Loyalty.pointsEarned')}
                  </XLargeLabelWrapper>
                </div>
                <Crown crownLink={Memberships.crownSrc} />
              </Column>
            </div>
            <CardLogo color={Memberships.textColor} />
          </Row>
          {degree !== 'diamond' ? (
            <React.Fragment>
              <Spacer size={1.5} />
              <MediumLabelWrapper color={Memberships.textColor}>
                {(degree === 'silver' || degree === 'gold') && (
                  <CapitalizeText>
                    {`${kFormatter(points_to_next_level)} `}
                  </CapitalizeText>
                )}
                {degree === 'silver' && i18next.t('ecommerceOrder:Loyalty.pointsLeftGold') }
                {degree === 'gold' && i18next.t('ecommerceOrder:Loyalty.pointsLeftDiamond') }
              </MediumLabelWrapper>
            </React.Fragment>
          ) : (
            <Spacer size={[1.5, 1.5, 2.5]} />
          )}
          <Spacer />
          <CardSeparator color={Memberships.textColor} />
          <RowWrapper width="100%" spaceBetweenJustified leftAligned>
            <Column leftAligned spaceBetweenJustified height="70px">
              {active_gifts_count !== 0 && (
                <React.Fragment>
                  <Row>
                    <SmallLabelStyle color={Memberships.textColor}>
                      <i className="el-icon el-icon-gift-promo" />
                    </SmallLabelStyle>
                    <Spacer size={0.5} />
                    <XLargeLabelWrapper color={Memberships.textColor}>
                      <StrongItem>{`${active_gifts_count} `}</StrongItem>
                      {i18next.t('ecommerceOrder:Loyalty.gifts')}
                    </XLargeLabelWrapper>
                  </Row>
                  <Spacer size={0.5} />
                </React.Fragment>
              )}
              <MediumLabelWrapper color={Memberships.textColor}>
                <CapitalizeText>
                  {`${kFormatter(points_to_next_gift)} `}
                </CapitalizeText>
                {i18next.t('ecommerceOrder:Loyalty.pointsLeftGift')}
              </MediumLabelWrapper>
              <Spacer />
              <LargeLabelWrapper color={Memberships.textColor}>{`${first_name} ${last_name}`}</LargeLabelWrapper>
            </Column>
            <Qr src={url_thumb} alt="qr" />
          </RowWrapper>
        </PointsCardWrapper>
        )}
      </React.Fragment>
    ))}
  </Row>
);

AykPointsCard.propTypes = {
  queryResult: PropTypes.shape({}),
};

AykPointsCard.defaultProps = {
  queryResult: [],
};

export default withDirection(AykPointsCard);

import React from 'react';
import styled, { css } from 'react-emotion';
import i18next from 'i18next';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import EmptyStatePage from '~/modules/coreUI/components/layouts/emptyStatePage';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

const Icon = withDirection(styled.i`
  display: inline-block;
  ${props => (props.direction === 'rtl' && css`
      transform: rotate(-180deg);
  `)}
`);

const LinkWrapper = styled(Label)`
  cursor: pointer;
`;

const EmptyMessages = () => (
  <React.Fragment>
    <EmptyStatePage
      imagePath={`/${imagesPathName}/faq.png`}
      contentHeader={i18next.t('ecommerceOrder:notificationsAndMessages.emptyMessagesTitle')}
      content={i18next.t('ecommerceOrder:notificationsAndMessages.emptyMessagesSubTitle')}
    />
    <Spacer size={2.5} />
    <LinkWrapper onClick={() => externalRedirect(urlFor('getAskAykLink'))}>
      <Label semiBold secondary sm>
        {i18next.t('ecommerceOrder:askAyk.askAykTitle')}
          &nbsp;
        <Icon className="el-icon el-icon-right" />
      </Label>
    </LinkWrapper>
  </React.Fragment>
);

EmptyMessages.defaultProps = {
  edit: false,
  continueShopping: false,
};

export default EmptyMessages;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import ItemsCardsSlider from '~/modules/ecommerceCoreUI/components/basic/ItemsCardsSlider';
import SectionHeader from '~/modules/coreUI/components/layouts/SectionHeader';

import Product from './Product';
import Shop from '~/modules/ecommerceShops/containers/shopCard/Shop';

class SectionsList extends React.PureComponent {
  render = () => {
    const { queryResult } = this.props;

    const sectionProducts = section => section.products
      && section.products.map(product => <Product isVertical productInfo={product} key={product} />);

    const shopsSections = section => section.shops && section.shops.map(shop => <Shop isVertical shopInfo={shop} key={shop} />);

    return (
      queryResult
      && queryResult.sections
      && queryResult.sections.map(
        section => (
          <CenterAlignedColumn key={section.id}>
            <Spacer size={4} />
            <SectionHeader
              title={section.title}
              subtitle={section.sub_title}
            />
            <ItemsCardsSlider>
              { section.target === 'products' ? sectionProducts(section) : shopsSections(section) }
            </ItemsCardsSlider>
          </CenterAlignedColumn>
        ),
      )
    );
  };
}

SectionsList.propTypes = {
  queryResult: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        sub_title: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }),
};
SectionsList.defaultProps = {
  queryResult: null,
};
export default withRootQuery(
  SectionsList,
  graphql`
    query SectionsListQuery {
      sections {
        id
        title
        sub_title
        target
        products {
          ...Product_productInfo
        }
        shops {
          ...Shop_shopInfo @relay(mask: false)
        }
      }
    }
  `,
  SectionsList,
);

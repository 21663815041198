import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Icon from '~/modules/coreUI/components/basic/Icon';

const Location = ({ itemInfo }) => (
  <Row spaceBetween={0.5}>
    <Icon
      size={1.5}
      normal
      className="icon-location"
    />
    <Label sm>
      {itemInfo.city.name}
      ,
    </Label>
    <Label sm>{itemInfo.country.name}</Label>
  </Row>
);

Location.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default Location;

const WrongPages = {
  En: {
    oops: 'Oooops!',
    pageNotFoundText: ' The page you requested couldn’t be found.',
    unauthenticatedText: 'You need to log in to access this page',
    unathorizedText: 'You are not allowed to view this page',
    serverErrorText: 'Something Went Wrong',
    errorTitle: 'Error',
    errorTypeOfWrongRoute: '404',
    errorTypeOfServerError: '500',
    errorCodeText: 'Error Code',

  },
  Ar: {
    oops: 'نأسف!',
    pageNotFoundText: 'الصفحة التي تبحث عنها غير موجودة.',
    unauthenticatedText: 'قم بتسجيل الدخول للوصول إلى محتوى الصفحة',
    unathorizedText: 'أنت غير مصرح لك باستخدام هذه الصفحة',
    serverErrorText: 'هناك خطأ ما',
    errorTitle: 'خطأ',
    errorTypeOfWrongRoute: '404',
    errorTypeOfServerError: '500',
    errorCodeText: 'رقم الخطأ',
  },
};

export default WrongPages;

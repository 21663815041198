import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import ItemCard from '~/modules/coreUI/components/itemCard';
import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

const Shop = ({ shopInfo, isVertical, isAd }) => (
  <ItemCard isShop isVertical={isVertical} itemInfo={shopInfo} isAd={isAd} />
);

Shop.propTypes = {
  shopInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rating_count: PropTypes.number,
    avg_rating: PropTypes.number.isRequired,
    follow: PropTypes.bool,
    track: PropTypes.string,
  }),
  isVertical: PropTypes.bool,
  isAd: PropTypes.bool,
};

Shop.defaultProps = {
  isVertical: true,
  isAd: false,
  shopInfo: {
    track: null,
    follow: false,
  },
};

export default withFragmentContainer(
  Shop,
  graphql`
    fragment Shop_shopInfo on Shop {
      id
      logo_url
      ref_id
      name
      rating_count
      avg_rating
      follow
      followed
      track
      city{
        name
      }
      country {
        name
      }
      featured
      departments {
        id
        name
      }
      track_sub_departments {
        id
        name
        track_department_id
        ref_id
      }
    }
  `,
);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';
import { SectionHeader } from '~/modules/ecommerceCoreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import {
  XLargeLabel,
  SmallLabel,
  MediumLabel,
  LargeLabel,
  Label,
} from '~/modules/coreUI/components/basic/Labels';
import Icon from '~/modules/coreUI/components/basic/Icon';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import CloseLuckyWheelBox from './CloseLuckyWheelBox';

const CongratsText = styled.div`
  position: relative;
`;
const Img = styled.img`
  max-width: 100%;
`;
const SectionHeaderWrapper = styled(Row)`
  position: absolute;
  top: 0;
  height: ${props => 10.5 * props.theme.new.spacer}px;
  width: 100%;
`;
const SectionHeaderStyle = styled(SectionHeader)`
  text-align: center;
  width: 100%;
`;
const XLargeLabelStyle = styled(XLargeLabel)`
  color: ${props => props.theme.colors.labels.important};
`;
const MediumLabelStyle = styled(MediumLabel)`
  color: rgba(0,0,0,0.7);
`;
const Action = styled(Row)`
  cursor: pointer;
  i {
    color: ${props => props.theme.colors.secondary};
  }
  span {
    font-weight: ${props => props.theme.fonts.weights.semiBold};
  }
`;
const Input = styled.input`
  border: none;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  font-size: ${props => 1 + props.theme.new.fonts.sizes.lg}px;
  display: inline-block;
  width: ${props => 14.3 * props.theme.new.spacer}px;
  text-align: center;
`;
const copyCode = (notifyAlert) => {
  const copyText = document.getElementById('referring_code');
  copyText.select();
  document.execCommand('copy');
  notifyAlert({
    component: () => (
      <Label inverted important semiBold>
        {i18next.t('accountManagement:MyPromos.copied')}
      </Label>
    ),
    type: AlertTypes.success,
    opacity: 1,
    toastID: 'luckyWheel_clicked_toast',
  });
};

const CongratsBoxDescription = ({
  text,
  description,
  notifyAlert,
  promo,
}) => (
  <React.Fragment>
    <CongratsText>
      <SectionHeaderWrapper bottomAligned>
        <SectionHeaderStyle>
          {i18next.t('ecommerceCoreUI:luckyWheel.congrats')}
        </SectionHeaderStyle>
      </SectionHeaderWrapper>
      <Img src={`/${imagesPathName}/pom.png`} alt="pom" />
    </CongratsText>
    <Spacer />
    <XLargeLabelStyle semiBold>
      {` ${i18next.t('ecommerceCoreUI:luckyWheel.luckyDay')} ${text} ${i18next.t('ecommerceCoreUI:luckyWheel.coupon')}`}
    </XLargeLabelStyle>
    <Spacer />
    <SmallLabel>{description}</SmallLabel>
    <Spacer size={2.5} />
    <Spacer />
    <Row centerJustified spaceBetween={0.5}>
      <MediumLabelStyle>{i18next.t('ecommerceCoreUI:luckyWheel.promoCode')}</MediumLabelStyle>
      <Input
        readOnly
        id="referring_code"
        value={promo}
      />
    </Row>
    <Spacer />
    <Action centerJustified spaceBetween={0.5} onClick={() => copyCode(notifyAlert)}>
      <Icon className="el-icon el-icon-copy" />
      <LargeLabel semiBold secondary>{i18next.t('accountManagement:MyPromos.copyCode')}</LargeLabel>
    </Action>
    <Spacer size={4} />
    <CloseLuckyWheelBox text={i18next.t('ecommerceCoreUI:luckyWheel.close')} />
  </React.Fragment>
);
CongratsBoxDescription.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  promo: PropTypes.string.isRequired,
  notifyAlert: PropTypes.func.isRequired,
};

export default withAlertMsg(CongratsBoxDescription);

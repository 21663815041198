import React from 'react';
import styled from 'react-emotion';
import { Container } from 'semantic-ui-react';
import Media from 'react-media';
import i18next from 'i18next';

import { mediaQueryMax, cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

import InnerSocialMediaFooter from '~/modules/ecommerceCoreUI/components/header/headerActions/SocialMedia';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import InnerFooterLinks from './InnerFooterLinks';

const linkList2 = [
  { key: 'ecommerceCoreUI:Footer.termsConditions', url: urlFor('getStaticPageLink', ['terms_conditions']) },
  { key: 'ecommerceCoreUI:Footer.privacyPolicy', url: urlFor('getStaticPageLink', ['privacy_policy']) },
  { key: 'ecommerceCoreUI:Footer.return', url: urlFor('getStaticPageLink', ['return_and_refund_condition']) },
  { key: 'ecommerceCoreUI:Footer.faqs', url: urlFor('getStaticPageLink', ['support']) },
];

const linkList3 = [
  { key: 'ecommerceCoreUI:Footer.about', url: urlFor('getStaticPageLink', ['about_us']) },
  { key: 'ecommerceCoreUI:Footer.contact', url: urlFor('getStaticPageLink', ['contact_us']) },
  { key: 'ecommerceCoreUI:Footer.ask', url: urlFor('getAskAykLink') },
  { key: 'ecommerceCoreUI:Footer.join', url: urlFor('getJoinBusinessLink') },
];

const linkListResponsive1 = [
  { key: 'ecommerceCoreUI:Footer.termsConditions', url: urlFor('getStaticPageLink', ['terms_conditions']) },
  { key: 'ecommerceCoreUI:Footer.privacyPolicy', url: urlFor('getStaticPageLink', ['privacy_policy']) },
];

const linkListResponsive2 = [
  { key: 'ecommerceCoreUI:Footer.return', url: urlFor('getStaticPageLink', ['return_and_refund_condition']) },
  { key: 'ecommerceCoreUI:Footer.faqs', url: urlFor('getStaticPageLink', ['support']) },
];

const linkListResponsive3 = [{ key: 'ecommerceCoreUI:Footer.ask', url: '' }, { key: 'ecommerceCoreUI:Footer.about', url: urlFor('getStaticPageLink', ['about_us']) }];

const linkListResponsive4 = [{ key: 'ecommerceCoreUI:Footer.contact', url: urlFor('getStaticPageLink', ['contact_us']) }, { key: 'ecommerceCoreUI:Footer.join', url: urlFor('getJoinBusinessLink') }];

const FooterWrapper = styled.div`
  width: 100%;
  padding: ${props => props.theme.paddings.xxLarge}px 0;
  background: #faf9f9;
  ${props => cssMediaMax.tablet`
    padding: ${props.theme.paddings.xLarge}px 0;
  `};
`;

const SocialMediaItems = styled(InnerSocialMediaFooter)`
  margin-top: ${props => props.theme.new.spacer}px !important;
  margin-left: 0px !important;
  ${props => cssMediaMax.tablet`
    margin-top: 0px !important;
      i {
        font-size: ${props.theme.fonts.sizes.xLarge + 3}px;
      }
    `};
`;

const renderForDesktop = () => (
  <FooterWrapper>
    <Container>
      <Column>
        <Row fullWidth>
          <InnerFooterLinks links={linkList2} />
        </Row>
        <Row fullWidth>
          <InnerFooterLinks links={linkList3} />
        </Row>
        <SocialMediaItems />
      </Column>
    </Container>
  </FooterWrapper>
);

const renderForMobile = () => (
  <FooterWrapper>
    <Container>
      <Spacer />
      <SocialMediaItems title={i18next.t('ecommerceCoreUI:Footer.follow')} />
      <Spacer size={2} />
      <Column>
        <Column fullWidth>
          <InnerFooterLinks links={linkListResponsive1} />
        </Column>
        <Column fullWidth>
          <InnerFooterLinks links={linkListResponsive2} />
        </Column>
        <Column fullWidth>
          <InnerFooterLinks links={linkListResponsive3} />
        </Column>
        <Row fullWidth>
          <InnerFooterLinks links={linkListResponsive4} />
        </Row>
      </Column>
    </Container>
  </FooterWrapper>
);

const FooterContainer = () => <Media query={mediaQueryMax('mobile')}>{matches => (matches ? renderForMobile() : renderForDesktop())}</Media>;
export default FooterContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import i18next from 'i18next';

import 'whatwg-fetch';
import 'url-search-params-polyfill';

import Loader from '~/modules/coreUI/components/basic/Loader';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

import { navigateToModal } from '~/modules/core/utils/modalHelpers';

class TwitterLogin extends React.Component {
  popup = null;

  polling = null;

  // To Check if POPUP is Done
  flag = true;

  componentDidMount = () => {
    const { isTriggered } = this.props;
    if (isTriggered) {
      this.getRequestToken();
    } else {
      this.polling(this.popup);
    }
  };

  getRequestToken = () => {
    const { errors } = this.props.queryResult.twitter_callback;
    if (errors && errors.length) {
      const errorMessage = errors[0].messages.length
        ? errors[0].messages[0]
        : i18next.t('accountManagement:SocialMedia.ErrorinConnectingtoTwitter');
      return this.props.onFailure(errorMessage);
    }

    try {
      this.popup = this.openPopup();
      if (this.popup) {
        const oAuthToken = this.props.queryResult
          ? this.props.queryResult.twitter_callback.token
          : '';
        if (oAuthToken) {
          this.popup.location = `https://api.twitter.com/oauth/authenticate?oauth_token=${oAuthToken}&force_login=${
            this.props.forceLogin
          }`;
          this.polling(this.popup);
        }
      } else {
        const error = i18next.t('accountManagement:SocialMedia.PleaseAllowPopup');
        this.props.notifyAlert({ messageText: error, type: AlertTypes.error, toastID: 'TwitterLogin_error_toast' });
        return this.props.onFailure();
      }
    } catch (error) {
      this.popup.close();
      return this.props.onFailure(error);
    }

    return null;
  };

  getOauthToken = (oauthVerifier, oauthToken) => {
    const { history } = this.props;
    navigateToModal(history, `/accountManagement/callback/${oauthToken}/${oauthVerifier}`);
  };

  closeDialog = (cb, oAuthVerifier, oAuthToken) => {
    clearInterval(this.polling);
    this.flag = false;
    this.popup.close();
    this.popup = null;

    if (cb) {
      cb(oAuthVerifier, oAuthToken);
    }
  };

  pollingIntervalFunc = () => {
    if (this.flag && (!this.popup || this.popup.closed || this.popup.closed === undefined)) {
      this.closeDialog();
      const errorMsg = i18next.t('accountManagement:SocialMedia.Popuphasbeenclosedbyuser');
      this.props.onFailure(errorMsg);
    }

    try {
      if (
        !this.popup.location.hostname.includes('api.twitter.com')
        && !(this.popup.location.hostname === '')
      ) {
        if (this.popup.location.search) {
          const query = new URLSearchParams(this.popup.location.search);

          const oAuthToken = query.get('oauth_token');
          const oAuthVerifier = query.get('oauth_verifier');

          this.closeDialog(this.getOauthToken, oAuthVerifier, oAuthToken);

          return false;
        }

        this.closeDialog();

        return this.props.onFailure(
          new Error(`OAuth redirect has occurred but no query or hash parameters were found.
          They were either not set during the redirect, or were removed—typically by a
          routing library—before Twitter react component could read it.`),
        );
      }

      if (this.flag) {
        this.polling(this.popup);
      }
    } catch (error) {
      // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
      // A hack to get around same-origin security policy errors in IE.
    }

    return null;
  };

  polling = () => {
    if (this.flag) {
      this.polling = setInterval(this.pollingIntervalFunc, 500);
    }
  };

  openPopup = () => {
    const w = this.props.dialogWidth;
    const h = this.props.dialogHeight;
    /* eslint-disable */
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    /* eslint-enable */
    const popup = window.open(
      '',
      '',
      `
      toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      scrollbars=no,
      resizable=no,
      copyhistory=no,
      width=${w},
      height=${h},
      top=${top},
      left=${left}`,
    );
    return popup;
  };

  render() {
    return (
      <Column fullWidth fullHeight centerJustified>
        <Loader />
      </Column>
    );
  }
}

TwitterLogin.propTypes = {
  onFailure: PropTypes.func.isRequired,
  notifyAlert: PropTypes.func.isRequired,
  dialogWidth: PropTypes.number,
  dialogHeight: PropTypes.number,
  forceLogin: PropTypes.bool,
  isTriggered: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  queryResult: PropTypes.shape({
    twitter_callback: PropTypes.shape({
      token: PropTypes.string.isRequired,
      errors: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

TwitterLogin.defaultProps = {
  dialogWidth: 600,
  dialogHeight: 400,
  forceLogin: false,
  isTriggered: false,
};

export default withRouter(withAlertMsg(TwitterLogin));

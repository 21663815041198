/* eslint-disable lines-between-class-members, camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { LinearLayout, Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Button from '~/modules/coreUI/components/basic/Button';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import AddressesContext from './context/AddressesContext';
import getNthElementInArray from '~/modules/core/utils/jsHelpers/getNthArrayElement';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';
import { notifyUnexpectedError } from '~/modules/ecommerceOrder/containers/shoppingCart/utils/ErrorsProcessor';

import AddressPanel from './panel/AddressPanel';
import saveSelectedAddressesMutation from './forms/mutations/SaveSelectedAddressesMutation';
import { scrollHtmltoTop } from '~/modules/core/utils/jsHelpers/ScrollToTop';

const cart = props => props.currentUserInfo.cart;

const SaveAndContinueButton = withMedia(props => (
  <Button xl secondary block={props.media.maxMobile} {...props}>
    {props.label || i18next.t('accountManagement:Profile.saveAddressButtonLabel')}
  </Button>
));

class Addresses extends Component {
  state = {
    loading: false,
    isSameAsShipping: null,
    selectedShippingAddressId: null,
    selectedBillingAddressId: null,
  };

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  onMutationError = (errors) => {
    notifyUnexpectedError(this.props, errors);
  }

  getIsSameAsShipping = () => (this.state.isSameAsShipping === null
    ? (!cart(this.props) && !this.hasPreviousAddresses())
      || !!(cart(this.props) && cart(this.props).same_as_shipping)
    : this.state.isSameAsShipping);

  getSelectedShippingAddressId = () => (this.state.selectedShippingAddressId === null // CODE_REVIEW : Might be a bug, check this
    ? ((cart(this.props) && cart(this.props).selected_shipping_address?.ref_id)
      || (this.getFirstSavedShippingAddress() && this.getFirstSavedShippingAddress().ref_id))
    : this.state.selectedShippingAddressId);

  getSelectedBillingAddressId = () => (this.state.selectedBillingAddressId === null // CODE_REVIEW : Might be a bug, check this
    ? ((cart(this.props) && cart(this.props).selected_billing_address?.ref_id)
      || (this.getFirstSavedBillingAddress() && this.getFirstSavedBillingAddress().ref_id))
    : this.state.selectedBillingAddressId);

  changeSelectedShippingAddress = (value) => {
    this.setState({ selectedShippingAddressId: value }, () => {
      saveSelectedAddressesMutation(
        this.props.environment,
        this.getSelectedShippingAddressId(),
        null,
        false,
        this.getIsSameAsShipping(),
        null,
        null,
        this.onMutationError,
      );
    });
  }

  changeSelectedBillingAddress = (value) => {
    this.setState({ selectedBillingAddressId: value }, () => {
      saveSelectedAddressesMutation(
        this.props.environment,
        null,
        this.getSelectedBillingAddressId(),
        false,
        null,
        null,
        null,
        this.onMutationError,
      );
    });
  }

  changeIsSameAsShipping = (isChecked) => {
    this.setState({ isSameAsShipping: !!isChecked }, () => {
      saveSelectedAddressesMutation(
        this.props.environment,
        this.getSelectedShippingAddressId(),
        null,
        false,
        this.getIsSameAsShipping(),
        null,
        null,
        this.onMutationError,
      );
    });
  }

  shippingIsFormDirty = () => this.shippingAddresses.isFormDirty();
  billingIsFormDirty = () => this.billingAddresses && this.billingAddresses.isFormDirty();
  isFormsDirty = () => this.shippingIsFormDirty() || this.billingIsFormDirty();

  validateShippingForm = () => this.shippingAddresses.validateForm();
  validateBillingForm = () => (!this.getIsSameAsShipping() ? this.billingAddresses.validateForm() : true);

  submitAddresses = async () => {
    const shippingFormValid = await this.validateShippingForm();
    const billingFormValid = await this.validateBillingForm();
    const isSameAsShipping = this.getIsSameAsShipping();

    if (billingFormValid && shippingFormValid) {
      this.setState({ loading: true });
      this.shippingAddresses
        .submitForm(true)
        .then(() => {
          if (!isSameAsShipping) {
            return this.billingAddresses.submitForm(true);
          }
          return true;
        })
        .then(() => {
          if (this.props.addressSelectionEnabled) {
            return this.saveSelectedAddresses();
          }
          if (!this.props.showSameAsShippingToggleAfterFirstTime) { // Inside user profile
            this.changeIsSameAsShipping(false);
          }
          return this.props.onSelectedAddressesSaved();
        });
    }
  }

  onSaveAddressMutationError = (errors) => {
    scrollHtmltoTop();
    this.setState({ panelError: errors.cart });
  }

  saveSelectedAddresses = () => saveSelectedAddressesMutation(
    this.props.environment,
    this.getSelectedShippingAddressId(),
    null,
    true,
    this.getIsSameAsShipping(),
    this.props.onSelectedAddressesSaved,
    loading => this.setState({ loading }),
    errors => this.onSaveAddressMutationError(errors),
  )

  getFirstSavedShippingAddress = () => getNthElementInArray(this.props.currentUserInfo.shipping_addresses_previous, 0);
  getFirstSavedBillingAddress = () => getNthElementInArray(this.props.currentUserInfo.billing_addresses_previous, 0);

  hasPreviousAddresses = () => !!this.getFirstSavedShippingAddress() && !!this.getFirstSavedBillingAddress();
  shouldShowSideBySide = () => {
    if (this.props.forceInitialMode && this.props.forceInitialMode === 'column') {
      return false;
    }

    return (!this.hasPreviousAddresses() && this.props.media.minDesktop);
  }

  onSaveAndContinueClicked = () => (this.hasPreviousAddresses()
    ? this.saveSelectedAddresses()
    : this.submitAddresses());

  render = () => (
    <AddressesContext.Provider value={{
      changeSelectedShippingAddress: this.changeSelectedShippingAddress,
      changeSelectedBillingAddress: this.changeSelectedBillingAddress,
      toggleIsSameAsShipping: this.changeIsSameAsShipping,
      selectedShippingAddressId: this.getSelectedShippingAddressId(),
      selectedBillingAddressId: this.getSelectedBillingAddressId(),
      addressSelectionEnabled: this.props.addressSelectionEnabled,
      addressDeletionEnabled: this.props.addressDeletionEnabled,
      showSameAsShippingToggleAfterFirstTime: this.props.showSameAsShippingToggleAfterFirstTime,
      hasPreviousAddresses: this.hasPreviousAddresses(),
      isSameAsShipping: this.getIsSameAsShipping(),
    }}
    >
      <Column fullWidth spaceBetween={2.5}>
        {this.state.panelError && (
        <PanelAlert
          type="error"
          inverted
          title={i18next.t('ecommerceOrder:Checkout.checkFollowingError')}
          errors={this.state.panelError}
        />
        )}
        <LinearLayout topAligned fullWidth stretchAligned spaceBetween={2.5} row={this.shouldShowSideBySide()}>
          <AddressPanel
            onRef={(ref) => { this.shippingAddresses = ref; }}
            type="shipping"
            addresses={this.props.currentUserInfo.shipping_addresses_previous}
            {...this.props.panelExtraProps}
          />
          <AddressPanel
            onRef={(ref) => { this.billingAddresses = ref; }}
            type="billing"
            addresses={this.props.currentUserInfo.billing_addresses_previous}
            {...this.props.panelExtraProps}
          />
        </LinearLayout>
        <Row fullWidth justifyContent={['center', 'center', 'flex-end']}>
          {(this.props.addressSelectionEnabled || !this.hasPreviousAddresses()) && (
            <SaveAndContinueButton loading={this.state.loading} onClicked={this.onSaveAndContinueClicked} label={this.props.saveButtonLabel} />
          )}
        </Row>
      </Column>
    </AddressesContext.Provider>
  )
}

Addresses.defaultProps = {
  addressSelectionEnabled: true,
  addressDeletionEnabled: false,
  showSameAsShippingToggleAfterFirstTime: true,
  forceInitialMode: null,
  panelExtraProps: null,
  onRef: null,
  saveButtonLabel: null,
};

Addresses.propTypes = {
  currentUserInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelectedAddressesSaved: PropTypes.func.isRequired,
  environment: PropTypes.objectOf(PropTypes.any).isRequired,
  onRef: PropTypes.func,
  addressSelectionEnabled: PropTypes.bool,
  addressDeletionEnabled: PropTypes.bool,
  showSameAsShippingToggleAfterFirstTime: PropTypes.bool,
  media: PropTypes.shape({
    maxMobile: PropTypes.bool,
    minDesktop: PropTypes.bool.isRequired,
  }).isRequired,
  forceInitialMode: PropTypes.string,
  panelExtraProps: PropTypes.objectOf(PropTypes.any),
  saveButtonLabel: PropTypes.string,
};

export default withMedia(withRelayEnvironment(Addresses));

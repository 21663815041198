/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql } from 'relay-runtime';

import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';
import Loader from '~/modules/coreUI/components/basic/Loader';
import PaginationBox from '~/modules/coreUI/components/pagination/PaginationBox';
import PaginationTextInfo from '~/modules/coreUI/components/pagination/PaginationTextInfo';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

import MessagesListFragment from '../messagesQuery/MessagesList';
import Message from './Message';
import EmptyMessages from './EmptyMessages';
import AskAykLink from './AskAykLink';

const MessagesWrapper = ({ queryResult }) => (
  <Column className="PaginatedListContainer" fullWidth>
    {queryResult?.thread_list?.threads.length > 0 ? (
      <Column leftAligned fullWidth>
        <AskAykLink />
        <Spacer size={1.5} />
        <Row pl={[1, 1, 1.5, 1.5]} pr={[1, 1, 1.5, 1.5]}>
          <PaginationTextInfo />
        </Row>
        <Spacer size={1.5} />
        <Column fullWidth>
          {queryResult?.thread_list?.threads.map(thread => (
            <Message key={thread.ref_id} thread={thread} />
          ))}
        </Column>
        <Row fullWidth centerJustified pl={[1, 1, 2.5, 2.5]} pr={[1, 1, 2.5, 2.5]}>
          <PaginationBox />
        </Row>
      </Column>
    ) : (
      <Column>
        <Spacer size={4} />
        <EmptyMessages />
        <Spacer size={4} />
      </Column>
    )}
  </Column>
);

const messageListQuery = graphql`
  query MessagesWrapperQuery($input: ThreadListInput) {
    ...MessagesList_queryResult @arguments(input: $input)
  }
`;

MessagesWrapper.defaultProps = {
  queryResult: {},
};

MessagesWrapper.propTypes = {
  queryResult: PropTypes.shape({}),
};

export default withRefetchQuery(
  MessagesWrapper,
  messageListQuery,
  MessagesListFragment,
  Loader,
  () => ({
    input: {
      page: {
        offset: 0,
        limit: 12,
      },
    },
  }),
  null,
  {
    pageInfo: queryResult => _.cloneDeep(queryResult.thread_list.page_info),
  },
);

import React from 'react';
import i18next from 'i18next';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';

import GrandTotalLabel from './GrandTotalLabel';

// eslint-disable-next-line react/prop-types
const CartTotal = ({ cartTotal }) => (
  <Row fullWidth spaceBetweenJustified>
    <GrandTotalLabel>{i18next.t('ecommerceOrder:Cart.total')}</GrandTotalLabel>
    <GrandTotalLabel primary>{`${cartTotal} ${currentCurrencyDisplayName()}`}</GrandTotalLabel>
  </Row>
);

export default CartTotal;

/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */

export const showDate = (props) => {
  if (props?.delivery_date === null) {
    return false;
  }

  if (props?.product_type === 'Service') {
    if (props?.shipment_state === 'ready' || props?.shipment_state === 'recieved') {
      return false;
    }
  }

  return true;
};

import React, { Component } from 'react';
import { withTheme } from 'emotion-theming';
import styled from 'react-emotion';
import { css } from 'emotion';
import PulseLoader from 'react-spinners/PulseLoader';

import PropTypes from 'prop-types';
import { Row, Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Button from '~/modules/coreUI/components/basic/Button';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const StyledButton = withDirection(styled(Button)`
  ${props => (props.leftButton && css`
      border-bottom-right-radius: ${props.direction === 'rtl' ? 0.5 * props.theme.new.spacer : '0'}px;
      border-top-right-radius: ${props.direction === 'rtl' ? 0.5 * props.theme.new.spacer : '0'}px;
      border-bottom-left-radius: ${props.direction === 'ltr' ? 0.5 * props.theme.new.spacer : '0'}px;
      border-top-left-radius: ${props.direction === 'ltr' ? 0.5 * props.theme.new.spacer : '0'}px;
  `)};

  ${props => (props.rightButton && css`
      border-bottom-right-radius: ${props.direction === 'ltr' ? 0.5 * props.theme.new.spacer : '0'}px;
      border-top-right-radius: ${props.direction === 'ltr' ? 0.5 * props.theme.new.spacer : '0'}px;
      border-bottom-left-radius: ${props.direction === 'rtl' ? 0.5 * props.theme.new.spacer : '0'}px;
      border-top-left-radius: ${props.direction === 'rtl' ? 0.5 * props.theme.new.spacer : '0'}px;
  `)};
`);

const CounterButtonLabel = styled.span`
  min-width: 1em;
  text-align: center;
`;

const CounterButton = withTheme(props => (
  <StyledButton
    inverted
    tight
    pxRatio={1}
    colors={{
      lineColor: props.disabled
        ? props.theme.new.colors.labels.normal.hint
        : props.theme.new.colors.labels.normal.emphasized,
      borderColor: props.theme.borders.color.light,
      backgroundColor: props.theme.colors.named.white,
    }}
    size={props.size}
    {...props}
  />
));

const LoaderContainer = styled.div`
  position: absolute;
  bottom: -2px;
`;

const LabelContainer = styled(Box)`
  position: relative;
`;

class Counter extends Component {
  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.value !== prevState.prevValue) {
      return {
        value: newProps.value,
        prevValue: newProps.value,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  state = {
    value: null,
    prevValue: null, // eslint-disable-line
  };

  decrease = () => {
    this.setState(
      prevState => ({
        value: prevState.value - 1,
      }),
      () => this.props.decrease(),
    );
  };

  increase = () => {
    this.setState(
      prevState => ({
        value: prevState.value + 1,
      }),
      () => this.props.increase(),
    );
  };

  plusIconIsDisabled = () => (this.props.max);


  render() {
    return (
      <Row stretchAligned centerJustified {...this.props}>
        <CounterButton
          leftButton
          size={this.props.size}
          onClicked={this.decrease}
          disabled={this.props.disabled || this.state.value <= this.props.min}
        >
          <CounterButtonLabel>-</CounterButtonLabel>
        </CounterButton>
        <LabelContainer centerJustified grow topBordered bottomBordered borderRadius="0">
          <Label size={this.props.size} emphasized>
            {this.state.value}
          </Label>
          {this.props.loading && (
            <LoaderContainer>
              <PulseLoader size={5} color="rgba(0,0,0,0.2)" />
            </LoaderContainer>
          )}
        </LabelContainer>
        <CounterButton
          rightButton
          size={this.props.size}
          onClicked={this.increase}
          disabled={this.props.disabled || this.state.value >= this.plusIconIsDisabled()}
        >
          <CounterButtonLabel>+</CounterButtonLabel>
        </CounterButton>
      </Row>
    );
  }
}

Counter.defaultProps = {
  loading: false,
  size: null,
  disabled: false,
};

Counter.propTypes = {
  loading: PropTypes.bool,
  increase: PropTypes.func.isRequired,
  decrease: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ]),
};

export default withMedia(withTheme(Counter));

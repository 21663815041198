const OrderTrans = {
  En: {
    orderNo: 'Order No.',
    orderDetails: 'Order Details',
    cancelOrder: 'Cancel Order',
    showDetails: 'Show Details',
    hideDetails: 'Hide Details',
    orderDate: 'Order Date',
    status: 'Status',
    appointmentDate: 'Appointment Date',
    deliveryDate: 'Delivery Date',
    notes: 'Notes',
    trackingNo: 'Tracking No.',
    items: 'Items',
    returnToOrders: 'Return to My Orders',
    subTotal: 'Total Amount',
    paymentMethod: 'Payment Method',
    shippingMethod: 'Shipping Method',
    refundOrder: 'Refund Order',
  },
  Ar: {
    orderNo: 'رقم الطلب',
    orderDetails: 'تفاصيل الطلب',
    cancelOrder: 'إلغاء الطلب',
    showDetails: 'إظهار التفاصيل',
    hideDetails: 'إخفاء التفاصيل',
    orderDate: 'تاريخ الطلب',
    status: 'الحالة',
    appointmentDate: 'تاريخ الموعد',
    deliveryDate: 'تاريخ التسليم',
    notes: 'ملاحظات',
    trackingNo: 'رقم التتبع',
    items: 'عناصر',
    returnToOrders: 'العودة إلى طلباتي',
    subTotal: 'المبلغ الإجمالي',
    paymentMethod: 'طريقة الدفع',
    shippingMethod: 'طريقة الشحن',
    refundOrder: 'إرجاع الطلب',
  },
};
export default OrderTrans;

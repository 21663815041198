/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import {
  Row,
  Column,
  Box,
} from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const CardContainer = withDirection(styled(Row)`
  &:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1) !important;
  }
  direction: ${props => (props.direction === 'rtl' ? 'rtl' : 'ltr')};
  border-radius: ${props => props.theme.borders.radius.normal}px;
`);

const OFFER_TAG_LINE_HEIGHT = '46px';

const HorizontalDesktopLayout = ({
  imageRenderer,
  title,
  showBorder,
  tag,
  rating,
  offerLabel,
  itemOptions,
  originalPrice,
  stockLabel,
  actions,
  currentPrice,
  addToCartButton,
  Summary,
}) => (
  <CardContainer stretchAligned bordered={showBorder} thin light padding={1.5} spaceBetween={1}>
    <Box topAligned>
      {imageRenderer}
    </Box>
    <Column leftAligned fullWidth>
      {tag && (
        <Row fullWidth topAligned height={OFFER_TAG_LINE_HEIGHT}>
          {tag}
        </Row>
      )}
      {title}
      <Spacer />
      {rating}
      <Spacer />
      {itemOptions}
      {Summary}
      <Spacer grow />
      <Column leftAligned spaceBetween={0.5}>
        {currentPrice}
        {originalPrice}
      </Column>
    </Column>
    <Column rightAligned spaceBetweenJustified>
      <Column height={OFFER_TAG_LINE_HEIGHT}>
        {actions}
      </Column>
      {stockLabel}
      <Spacer grow />
      {offerLabel}
      { addToCartButton && (
        <Box pt={1}>
          {addToCartButton}
        </Box>
      )}
    </Column>
  </CardContainer>
);
HorizontalDesktopLayout.propTypes = {
  stockLabel: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  tag: PropTypes.element,
  offerLabel: PropTypes.element.isRequired,
  itemOptions: PropTypes.element.isRequired,
  rating: PropTypes.element.isRequired,
  imageRenderer: PropTypes.element.isRequired,
  title: PropTypes.element.isRequired,
  originalPrice: PropTypes.element.isRequired,
  currentPrice: PropTypes.element.isRequired,
  addToCartButton: PropTypes.element,
  showBorder: PropTypes.bool,
  Summary: PropTypes.element.isRequired,
};

HorizontalDesktopLayout.defaultProps = {
  showBorder: true,
  tag: null,
  addToCartButton: null,
};
export default HorizontalDesktopLayout;

/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { mediaSizesMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import SlickSlider from '~/modules/ecommerceCoreUI/components/slickSlider/SlickSlider';

const SliderWrapper = styled.div`
  .slick-slider {
    margin: 0 -5px;
    .slick-dots {
      bottom: -39px;
      margin-bottom: 33px;
      li button:before {
        color: #fff !important;
        opacity: 0.4 !important;
      }
      li.slick-active button:before {
        color: #fff !important;
        opacity: 1 !important;
      }
    }
    .slick-slide > div {
      margin: 0px 5px 38px !important;
      text-align: left;
    }
  }
`;
class FeaturedShopsSlider extends React.PureComponent {
  render = () => {
    const { children } = this.props;
    const settings = {
      dots: true,
      speed: 500,
      infinite: children.length <= 4 ? false : true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: mediaSizesMax.tablet,
          settings: {
            slidesToShow: 3,
            infinite: children.length <= 3 ? false : true,
            arrows: false,
          },
        },
        {
          breakpoint: mediaSizesMax.mobile,
          settings: {
            slidesToShow: 2,
            infinite: children.length <= 2 ? false : true,
            arrows: false,
          },
        },
        {
          breakpoint: mediaSizesMax.xsmall,
          settings: {
            slidesToShow: 1,
            infinite: true,
            arrows: false,
          },
        },
      ],
    };
    return (
      <SliderWrapper>
        <SlickSlider settings={settings}>{children}</SlickSlider>
      </SliderWrapper>
    );
  };
}
FeaturedShopsSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeaturedShopsSlider;

/* eslint-disable react/prop-types */
import React from 'react';
import { css } from 'emotion';
import moment from 'moment';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import injectInBetween from '~/modules/core/utils/jsHelpers/injectElementBetweenChildElements';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import MarkReadMutation from './mutations/MarkReadMutation';
import NotificationIcon from './NotificationIcon';

const notificationRowStyles = (isRead, media) => css`
  padding-right: 25px;
  padding-left: 25px;
  
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  }

  ${!isRead ? css`background-color: rgba(51, 168, 255, 0.1);` : null};
  ${media.minDesktop ? null : (css`
    padding-right: 10px;
    padding-left: 10px;
  `)}
`;

const separatorStyles = css`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const onMutationSuccess = () => {};

const onMutationError = () => {};

const onMutationLoading = () => {};

const commitReadNotification = (id, env) => {
  commitMutation(
    env,
    MarkReadMutation,
    'mark_as_read',
    {
      notification_id: id,
    },
    onMutationSuccess,
    onMutationError,
    onMutationLoading,
  );
};

const truncate = (str, maxChars) => {
  if (str.length > maxChars) {
    return `${str.substring(0, maxChars)}...`;
  }
  return str;
};

const NotificationsList = ({
  notifications,
  environment,
  fromDropdown,
  media,
}) => (
  <Column fullWidth>
    <Spacer size={1.5} />
    {injectInBetween(
      notifications.map(n => (
        <ExternalLink url={n.redirect_to} className={css`width: 100%;`} key={n.ref_id}>
          <Row
            fullWidth
            centerAligned
            paddingTop={1.5}
            paddingBottom={1.5}
            customStyles={() => notificationRowStyles(n.read, media)}
            onClick={() => { commitReadNotification(n.ref_id, environment); }}
          >
            <NotificationIcon isRead={n.read} navKey={n.key} />
            <Spacer size={2} />
            <Column fullWidth leftAligned>
              <Row fullWidth spaceBetweenJustified>
                <Label xs important semiBold>{n.subject}</Label>
                <Label xs>{moment(n.created_at).format('h:mm A - DD/MM/YYYY')}</Label>
              </Row>
              <Spacer size={0.5} />
              {fromDropdown ? (
                <Label xs emphasized>{truncate(n.topic, 80)}</Label>
              ) : (
                <pre><Label prewrap xs emphasized>{n.topic}</Label></pre>
              )}
            </Column>
          </Row>
        </ExternalLink>
      )),
      <div className={separatorStyles}><Separator separatorLength="full" spacerSize="0" /></div>,
      true,
    )}
  </Column>
);


export default withRelayEnvironment(withMedia(NotificationsList));

import React from 'react';
import i18next from 'i18next';
import styled from 'react-emotion';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  PanelContentLabel,
  PanelContentMinorLabel,
} from '~/modules/accountManagement/components/basic/Labels';
import Button from '~/modules/coreUI/components/basic/Button';
import { MediumSpacer, XLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Column } from '~/modules/coreUI/components/legacies/Columns';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';

import PopupLogo from '~/modules/accountManagement/components/basic/PopupLogo';

const MediumLogo = styled(PopupLogo)`
  height: unset;
  width: unset;
  max-width: 80px;
`;

const ImageContainer = styled(Column)`
  width: 115px;
`;

const PanelImage = () => (
  <ImageContainer grow centerAligned>
    <MediumLogo />
  </ImageContainer>
);

const SignUpFormPanel = (props) => {
  const ContentContainer = props.panelContentContainer;
  const PanelContainer = props.panelContainer;
  const { history } = props;

  return (
    <Media query={mediaQueryMin('desktop')}>
      {matched => (
        <PanelContainer
          showHeader={matched}
          titleLabel={i18next.t('accountManagement:SignUp.Register')}
          subTitleLabel={i18next.t('accountManagement:SignUp.JoinOurCommunity')}
        >
          <ContentContainer>
            {matched && <PanelImage />}
            <PanelContentLabel>{i18next.t('accountManagement:SignUp.haveAccount')}</PanelContentLabel>
            <XLargeSpacer />
            <Button
              block
              primary
              lg
              inverted
              onClicked={() => navigateToModal(history, '/accountManagement/signup')}
            >
              {i18next.t('accountManagement:Login.CreateAnAccount')}
            </Button>
            <MediumSpacer />
            <PanelContentMinorLabel>
              {/* Placeholder to justify the Registeration to be similar to the login */}
              &nbsp;
            </PanelContentMinorLabel>
          </ContentContainer>
        </PanelContainer>
      )}
    </Media>
  );
};

SignUpFormPanel.propTypes = PropTypes.shape({
  panelContentContainer: PropTypes.element,
}).isRequired;

export default withRouter(SignUpFormPanel);

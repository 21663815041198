/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VariantData_variantInfo$ref: FragmentReference;
export type VariantData_variantInfo = {|
  +product_type: ?string,
  +id: string,
  +ref_id: string,
  +images: $ReadOnlyArray<?string>,
  +summary: ?string,
  +images_attachments: $ReadOnlyArray<?{|
    +url_product: string
  |}>,
  +name: string,
  +avg_rating: ?number,
  +rating_count: ?number,
  +price: number,
  +slug: ?string,
  +vendor_variants_count: ?number,
  +discount_expire_at: ?any,
  +remaining_usage_percentage: ?number,
  +gift_image: ?{|
    +url_large: string,
    +url_original: string,
  |},
  +gift_text: ?string,
  +gift_included: ?boolean,
  +min_variant: ?{|
    +vendor: ?{|
      +name: ?string,
      +ref_id: string,
    |},
    +slug: ?string,
    +name: string,
    +ref_id: string,
    +root_variant_id: ?string,
    +discount_expire_at: ?any,
    +remaining_usage_percentage: ?number,
    +total_on_hand: ?number,
    +price: number,
    +original_price: number,
  |},
  +has_offer: ?boolean,
  +hot_deal: ?boolean,
  +has_discount: ?boolean,
  +wish_listed: ?boolean,
  +root_variant_id: ?string,
  +root_variant: ?{|
    +ref_id: string,
    +wish_listed: ?boolean,
  |},
  +original_price: number,
  +discount: ?number,
  +total_on_hand: ?number,
  +option_values: ?$ReadOnlyArray<?{|
    +id: string,
    +presentation: ?string,
  |}>,
  +vendor: ?{|
    +id: string,
    +ref_id: string,
    +name: ?string,
    +logo_url: ?string,
    +avg_rating: ?number,
    +rating_count: ?number,
    +followed: ?boolean,
    +grouped_shipping_methods: ?$ReadOnlyArray<?{|
      +code: ?string,
      +title: ?string,
      +description: ?string,
    |}>,
    +payment_methods: $ReadOnlyArray<?{|
      +id: string,
      +ref_id: string,
      +name: string,
      +description: ?string,
      +type: string,
      +code: ?string,
    |}>,
  |},
  +$refType: VariantData_variantInfo$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discount_expire_at",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "remaining_usage_percentage",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "root_variant_id",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total_on_hand",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original_price",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "VariantData_variantInfo",
  "type": "Variant",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "gift_image",
      "storageKey": null,
      "args": null,
      "concreteType": "ImageAttachment",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url_large",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url_original",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "product_type",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "images",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "summary",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "images_attachments",
      "storageKey": null,
      "args": null,
      "concreteType": "ImageAttachment",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url_product",
          "args": null,
          "storageKey": null
        }
      ]
    },
    v1,
    v2,
    v3,
    v4,
    v5,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "vendor_variants_count",
      "args": null,
      "storageKey": null
    },
    v6,
    v7,
    v8,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gift_text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gift_included",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "min_variant",
      "storageKey": null,
      "args": null,
      "concreteType": "Variant",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "vendor",
          "storageKey": null,
          "args": null,
          "concreteType": "Shop",
          "plural": false,
          "selections": [
            v1,
            v0
          ]
        },
        v5,
        v1,
        v0,
        v9,
        v6,
        v7,
        v10,
        v4,
        v11
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "has_offer",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hot_deal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "has_discount",
      "args": null,
      "storageKey": null
    },
    v12,
    v9,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "root_variant",
      "storageKey": null,
      "args": null,
      "concreteType": "Variant",
      "plural": false,
      "selections": [
        v0,
        v12
      ]
    },
    v11,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "discount",
      "args": null,
      "storageKey": null
    },
    v10,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "option_values",
      "storageKey": null,
      "args": null,
      "concreteType": "OptionValue",
      "plural": true,
      "selections": [
        v8,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "presentation",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vendor",
      "storageKey": null,
      "args": null,
      "concreteType": "Shop",
      "plural": false,
      "selections": [
        v8,
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "logo_url",
          "args": null,
          "storageKey": null
        },
        v2,
        v3,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "followed",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "grouped_shipping_methods",
          "storageKey": null,
          "args": null,
          "concreteType": "VendorGroupedShipmentResult",
          "plural": true,
          "selections": [
            v13,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            v14
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "payment_methods",
          "storageKey": null,
          "args": null,
          "concreteType": "PaymentMethod",
          "plural": true,
          "selections": [
            v8,
            v0,
            v1,
            v14,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            },
            v13
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab63eddd31ff314db7600a8d14da1e5f';
module.exports = node;

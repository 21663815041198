import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';
import Slider from 'react-slick';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const MainSliderStyle = styled(Slider)`
  height: ${props => 19 * props.theme.new.spacer}px;
  ${props => (props.isVertical
    ? css`
      width: 100%;
    `
    : css`
      width: ${19.5 * props.theme.new.spacer}px;
    `
  )}
  .slick-slide {
    max-width: 100%;
    visibility: hidden;
  }

  .slick-slide.slick-active {
    background: #fff;
    display: block;
    visibility: visible;
    > div {
      > div {
        border-style: none;
        border-color: ${props => props.theme.borders.color.normal};
        outline: none;

        ${props => props.media.minDesktop
          && css`
            border-width: ${props.theme.borders.size.thin}px;
            border-style: solid;
          `};
      }
    }
  }
`;

const MainSlider = ({
  innerRef,
  media,
  afterChange,
  children,
  isVertical,
}) => (
  <MainSliderStyle
    infinite
    innerRef={innerRef}
    arrows={false}
    media={media}
    afterChange={afterChange}
    isVertical={isVertical}
    speed={300}
  >
    {children}
  </MainSliderStyle>
);

MainSlider.propTypes = {
  afterChange: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  media: PropTypes.shape({}).isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isVertical: PropTypes.bool,
};
MainSlider.defaultProps = {
  isVertical: true,
};

export default withMedia(MainSlider);

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';

import { setLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';

import TwitterCallbackQuery, { variables } from './TwitterCallbackQuery';

import TwitterLogin from './TwitterLogin';

class TriggerTwitterLogin extends React.Component {
  onLoginFailure = (error) => {
    const { getSocialMediaData, history } = this.props;

    getSocialMediaData(true);

    if (error) {
      setLocalMessageFor(PathRedirectKeysMapper.login, error);
    }

    navigateToModal(history, '/accountManagement/login');
  };

  render = () => {
    const customHeaders = {};
    customHeaders.mode = 'no-cors';
    return (
      <TwitterLogin
        onFailure={this.onLoginFailure}
        customHeaders={customHeaders}
        isTriggered
        {...this.props}
      />
    );
  };
}

TriggerTwitterLogin.propTypes = {
  getSocialMediaData: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(
  withUserInfo(withRootQuery(TriggerTwitterLogin, TwitterCallbackQuery, null, variables)),
);

/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import i18next from 'i18next';

import {
  SmallParagraph,
  DepartmentTitle,
  BlackDepartmentTitle,
  BigTitle,
} from '~/modules/ecommerceCoreUI/components/basic/Labels';
import { BasicButton } from '~/modules/coreUI/components/legacies/Button';
import { SmallSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

const ButtonContainer = styled.div`
  width: 60%;
`;

const Wrapper = withDirection(styled(Row)`
  width: 50%;
  height: ${props => 20 * props.theme.new.spacer}px;
  position: absolute;
  bottom: 9%;
  ${props => (props.direction === 'rtl'
    ? css`
        left: 0;
      `
    : css`
        right: 0;
      `
  )}
`);

const DepartmentsCountContainer = withDirection(styled.div`
  width: 50%;
  height: ${props => 20 * props.theme.new.spacer}px;
`);

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  width: 50%;
  height: ${props => 20 * props.theme.new.spacer}px;
`;

const redirectToDepartment = (name, id) => {
  externalRedirect(urlFor('getDepartmentLink', [id, name]));
};

const DepartmentCountSection = ({
  mainDepartmentCount,
  departmentName,
  departmentId,
  description,
  image,
}) => (
  <Wrapper spaceBetween={2}>
    <ImageWrapper>
      {image && <Image src={image} />}
    </ImageWrapper>
    <DepartmentsCountContainer>
      <DepartmentTitle>{i18next.t('ecommerceCoreUI:departmentInfo.moreThan')}</DepartmentTitle>
      <SmallSpacer />
      <BigTitle>{mainDepartmentCount}</BigTitle>
      <SmallSpacer />
      <BlackDepartmentTitle>
        {' '}
        {departmentName}
        {' '}
      </BlackDepartmentTitle>
      <SmallSpacer />
      <SmallParagraph>{description}</SmallParagraph>
      <SmallSpacer />
      <ButtonContainer>
        <BasicButton
          block
          secondary
          onClicked={() => redirectToDepartment(departmentName, departmentId)}
        >
          {i18next.t('ecommerceCoreUI:departmentInfo.discover')}
        </BasicButton>
      </ButtonContainer>
    </DepartmentsCountContainer>
  </Wrapper>
);

DepartmentCountSection.defaultProps = {
  description: null,
  image: null,
};

DepartmentCountSection.propTypes = {
  mainDepartmentCount: PropTypes.number.isRequired,
  departmentName: PropTypes.string.isRequired,
  departmentId: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({}),
};

export default DepartmentCountSection;

/**
 * @flow
 * @relayHash d04b81aa783b764f8592950f268fa36d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LoginFormMutationVariables = {|
  user_signin: string,
  password: string,
  remember_me: boolean,
|};
export type LoginFormMutationResponse = {|
  +signin_user: ?{|
    +token: ?string,
    +client_id: ?string,
    +expiry: ?string,
    +user: ?{|
      +id: string,
      +ref_id: string,
      +first_name: ?string,
      +last_name: ?string,
      +email: ?string,
      +verified: ?boolean,
      +avatar: ?string,
      +default_lang: ?string,
      +default_currency: ?string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type LoginFormMutation = {|
  variables: LoginFormMutationVariables,
  response: LoginFormMutationResponse,
|};
*/


/*
mutation LoginFormMutation(
  $user_signin: String!
  $password: String!
  $remember_me: Boolean!
) {
  signin_user(user_signin: $user_signin, password: $password, remember_me: $remember_me) {
    token
    client_id
    expiry
    user {
      id
      ref_id
      first_name
      last_name
      email
      verified
      avatar
      default_lang
      default_currency
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "user_signin",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "remember_me",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "signin_user",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "remember_me",
        "variableName": "remember_me",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "user_signin",
        "variableName": "user_signin",
        "type": "String"
      }
    ],
    "concreteType": "SigninPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "client_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expiry",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ref_id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "first_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "last_name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verified",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_lang",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "default_currency",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "LoginFormMutation",
  "id": null,
  "text": "mutation LoginFormMutation(\n  $user_signin: String!\n  $password: String!\n  $remember_me: Boolean!\n) {\n  signin_user(user_signin: $user_signin, password: $password, remember_me: $remember_me) {\n    token\n    client_id\n    expiry\n    user {\n      id\n      ref_id\n      first_name\n      last_name\n      email\n      verified\n      avatar\n      default_lang\n      default_currency\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LoginFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginFormMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '417e8bcc402cd45caba75b37f525d74d';
module.exports = node;

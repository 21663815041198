const TabsTrans = {
  En: {
    Profile: 'Profile',
    Orders: 'Orders',
    Refunds: 'Refunds',
    ShoppingCart: 'Shopping Cart',
    RecentlyViewed: 'Recently Viewed',
    Notifications: 'Notifications & Messages',
    Wishlist: 'Wishlist',
    SmartCart: 'Smart Carts',
    Following: 'Following List',
    Loyalty: 'Loyalty Program',
    Coupons: 'Coupons',
    Settings: 'Settings',
    Invite: 'Invite Friends',
    CustomerService: 'Help Center',
  },
  Ar: {
    Profile: 'الملف الشخصي',
    Orders: 'الطلبات',
    Refunds: 'استعادة المبالغ',
    ShoppingCart: 'عربة التسوق',
    RecentlyViewed: 'المشاهدات الأخيرة',
    Notifications: 'الإشعارات والرسائل',
    Wishlist: 'قائمة الرغبات',
    SmartCart: 'العربات الذكية',
    Following: 'قائمة المتابعة',
    Loyalty: 'برنامج الولاء',
    Coupons: 'الكوبونات',
    Settings: 'الإعدادات',
    Invite: 'دعوة الأصدقاء',
    CustomerService: 'مركز المساعدة',
  },
};
export default TabsTrans;

import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import { GridArea } from '~/modules/coreUI/components/layouts/helpers/GridLayout';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const MobileGrid = styled.div`
  display: grid;
  grid-template:
    'itemImage      column' auto
    'itemImage      column' auto
    'itemImage      column' auto
    'itemImage      column' auto
    / 75px auto;

  grid-column-gap: ${props => props.theme.new.spacer}px;
  grid-row-gap: ${props => props.theme.new.spacer}px;
`;

const MobileLayout = props => (
  <Row spaceBetweenJustified centerAligned fullWidth>
    <MobileGrid>
      <GridArea gridArea="column" leftAligned spaceBetween={1}>
        {props.itemName}
        {props.itemQuantity && <Row>{props.itemQuantity}</Row>}
        {props.itemOptions}
        {props.itemRating}
        {props.itemPrice}
        {props.counter}
        {props.refundButton}
        {props.refundStatus}
        {props.cancelButton}
      </GridArea>
      <GridArea gridArea="itemImage">{props.itemImage}</GridArea>
    </MobileGrid>
    {props.checkbox}
  </Row>
);

MobileLayout.defaultProps = {
  refundButton: undefined,
  itemQuantity: undefined,
  itemOptions: undefined,
  itemRating: undefined,
  itemPrice: undefined,
  counter: undefined,
  refundStatus: undefined,
  checkbox: undefined,
  cancelButton: undefined,
};

MobileLayout.propTypes = {
  itemName: PropTypes.element.isRequired,
  itemImage: PropTypes.element.isRequired,
  itemQuantity: PropTypes.element,
  itemOptions: PropTypes.element,
  itemRating: PropTypes.element,
  itemPrice: PropTypes.element,
  refundButton: PropTypes.element,
  counter: PropTypes.element,
  refundStatus: PropTypes.element,
  cancelButton: PropTypes.element,
  checkbox: PropTypes.element,
};

export default MobileLayout;

import React from 'react';
import Media from 'react-media';
import styled from 'react-emotion';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import FloatingIcon from '~/modules/ecommerceCoreUI/components/floatingIcon/FloatingIcon';

import { mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import ProductTracksResponsive from './ProductTracksResponsive';
import ProductsTracksDesktop from './ProductsTracksDesktop';

const TrackContainer = styled.div`
  width: auto;
`;

class ProductTracksTabs extends React.Component {
  getSelectedDepartment = () => {
    const { trackId } = this.props.match.params;
    const taxonIds = this.props.queryResult.taxons.map(t => t.ref_id);
    const departmentIndex = _.indexOf(taxonIds, trackId);
    // if not found return the first department
    return departmentIndex === -1 ? 0 : departmentIndex;
  };

  selectDepartment = (department) => {
    const { history } = this.props;
    const { vendorID, vendorName } = this.props.match.params;

    history.push(urlFor('getProductTracksLink', [department, vendorID, vendorName]));
  };

  render() {
    return (
      <TrackContainer>
        <FloatingIcon {...this.props} />
        <Media query={mediaQueryMin('desktop')}>
          {matches => (matches ? (
            <ProductsTracksDesktop
              taxons={this.props.queryResult.taxons}
              getSelectedDepartment={this.getSelectedDepartment}
              selectDepartment={this.selectDepartment}
              vendorID={this.props.match.params.vendorID}
              vendorName={this.props.match.params.vendorName}
            />
          ) : (
            <ProductTracksResponsive
              taxons={this.props.queryResult.taxons}
              getSelectedDepartment={this.getSelectedDepartment}
              selectDepartment={this.selectDepartment}
              vendorID={this.props.match.params.vendorID}
              vendorName={this.props.match.params.vendorName}
            />
          ))
          }
        </Media>
      </TrackContainer>
    );
  }
}

ProductTracksTabs.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      trackId: PropTypes.string,
      vendorID: PropTypes.string,
      vendorName: PropTypes.string,
    }).isRequired,
  }).isRequired,
  queryResult: PropTypes.shape({
    taxons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        ref_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        logo: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default withRouter(
  withRootQuery(
    ProductTracksTabs,
    graphql`
      query ProductsTracksDesktopQuery($vendorID: ID) {
        taxons(type: department, vendor_id: $vendorID) {
          id
          ref_id
          name
          ...TrackContent_taxon @arguments(vendor_id: $vendorID)
        }
      }
    `,
    null,
    props => ({
      vendorID: props.match.params.vendorID,
    }),
  ),
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Button from '~/modules/coreUI/components/basic/Button';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import Icon from '~/modules/coreUI/components/basic/Icon';
import { notifyUnexpectedError } from '~/modules/ecommerceOrder/containers/shoppingCart/utils/ErrorsProcessor';

import { BuySmartCartMutationRoot, BuySmartCartMutation } from '../smartCartMutations/BuySmartCart';

class BuySmartCart extends Component {
  state={
    isLoading: false,
  }

  onMutationSuccess = () => {
    externalRedirect('/cart');
  };

  onMutationLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  onMutationError = (errors) => {
    this.setState({
      isLoading: false,
    });
    notifyUnexpectedError(this.props, errors);
  };

  BuySmartCart = (e, smartCartId) => {
    e.stopPropagation();
    e.preventDefault();
    commitMutation(
      this.props.environment,
      BuySmartCartMutation,
      BuySmartCartMutationRoot,
      { smart_cart_id: smartCartId },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
  }

  render() {
    const { smartCart } = this.props;
    const { isLoading } = this.state;

    return (
      <Button onClicked={event => this.BuySmartCart(event, smartCart.ref_id)} loading={isLoading} semiBold sm secondary width="118px">
        <Icon className="el-icon el-icon-buy-now" />
        {i18next.t('ecommerceOrder:smartCart.buySmartCart')}
      </Button>
    );
  }
}

BuySmartCart.propTypes = {
  smartCart: PropTypes.shape({}),
  environment: PropTypes.shape({}).isRequired,
};

BuySmartCart.defaultProps = {
  smartCart: {},
};

export default withRelayEnvironment(withAlertMsg(BuySmartCart));

import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import ItemCard from '~/modules/coreUI/components/itemCard';
import withFragmentContainer from '~/modules/core/utils/relayHelpers/withFragmentContainer';

const Product = ({
  productInfo, isVertical, isAd, vendorInfo, isVendorProduct, isHotDeal,
}) => (
  <ItemCard
    isProduct
    isVendorProduct={isVendorProduct}
    isAd={isAd}
    isVertical={isVertical}
    itemInfo={productInfo}
    vendorInfo={vendorInfo}
    isHotDeal={isHotDeal}
  />
);

Product.propTypes = {
  productInfo: PropTypes.shape({}).isRequired,
  vendorInfo: PropTypes.shape({
    vendorID: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  isVertical: PropTypes.bool,
  isHotDeal: PropTypes.bool,
  isAd: PropTypes.bool,
  isVendorProduct: PropTypes.bool,
};

Product.defaultProps = {
  isVertical: true,
  isAd: false,
  isHotDeal: false,
  vendorInfo: {},
  isVendorProduct: false,
};
export default withFragmentContainer(
  Product,
  graphql`
    fragment Product_productInfo on Product @argumentDefinitions(vendor_id: { type: "ID" }) {
      id
      slug
      vendor_variants_count
      min_variant(vendor_id: $vendor_id) {
        vendor{
          name
          ref_id
        }
        slug
        name
        ref_id
        root_variant_id
        discount_expire_at
        remaining_usage_percentage
        total_on_hand
        price
        original_price
      }
      ref_id
      images
      name
      avg_rating
      rating_count
      price
      original_price
      discount
      has_discount
      has_offer
      hot_deal
      wish_listed
      root_variant {
        root_variant_id
        ref_id
        wish_listed
      }
      variants {
        id
        ref_id
        option_values {
          id
          ref_id
          option_type_name
        }
      }
      related_products {
        images
        name
      }
      gift_image {
        url_large
        url_original
      }
      gift_text
      gift_included
    }
  `,
);

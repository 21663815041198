import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { withRouter } from 'react-router-dom';

import changeLanguage from '~/modules/ecommerceCore/utils/changeLanguage';
import Button from '~/modules/coreUI/components/basic/Button';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';

import getFormFields from './EditUserSettingsFormFields';
import EditUserSettingsFormLayout from './EditUserSettingsFormLayout';
import EditUserInfoMutation from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/EditUserInfoMutation';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import { flattenAndParseCountryFieldValue } from '~/modules/ecommerceCoreUI/components/addresses/city/CityFormElement';
import changeCurrency from '~/modules/ecommerceCore/utils/changeCurrency';

class EditUserSettingsForm extends React.Component {
  state = {
    isLoading: false,
    errors: null,
  };

  constructor(props) {
    super(props);
    this.formFields = getFormFields(props.userInfo);
  }

  onSuccess = (response) => {
    this.setState({ errors: null, isLoading: false });

    changeCurrency(response.update_user_info.result.default_currency);
    const languageChanged = changeLanguage(
      response.update_user_info.result.default_lang,
      this.props.history.location,
    );

    if (!languageChanged) { // since otherwise, we will refresh the whole page immediately
      this.props.notifyAlert({
        messageText: i18next.t('accountManagement:Profile.settingsChangedSuccessfully'),
        type: AlertTypes.success,
        opacity: 1,
        toastID: 'EditUserSettingsForm_success_toast',
      });
      window.location.reload();
    }
  };

  onError = errors => this.setState({ errors, isLoading: false });

  onLoading = isLoading => this.setState({ isLoading });

  submitForm = () => this.form.submitForm();

  getInitialValues = userInfo => ({
    ...userInfo,
    country: {
      country_code: userInfo.country_code,
      country_id: userInfo.country_id,
    },
  });

  render = () => (
    <Column showHeader={false} width="100%" maxWidth={60} spaceBetween={1.5}>
      {this.state.errors && (
        <PanelAlert type="error" inverted errors={this.state.errors} />
      )}
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={error => this.onError(error)}
        onFormSuccess={response => this.onSuccess(response)}
        onFormLoading={loading => this.onLoading(loading)}
        mutationRoot="update_user_info"
        mutation={EditUserInfoMutation}
        options={{
          customLayout: this.props.media.minTablet && EditUserSettingsFormLayout,
          initialFormValue: this.getInitialValues(this.props.userInfo),
          fields: this.formFields,
        }}
        getSubmissionVariables={formVariables => ({
          input: {
            ...flattenAndParseCountryFieldValue(formVariables),
          },
        })}
      />
      <Row fullWidth rightJustified spaceBetween={2}>
        <Button
          secondary
          loading={this.state.isLoading}
          width="120px"
          onClicked={() => this.submitForm()}
        >
          {i18next.t('accountManagement:Profile.save')}
        </Button>
      </Row>
    </Column>
  );
}

EditUserSettingsForm.propTypes = {
  notifyAlert: PropTypes.func.isRequired,
  media: PropTypes.shape({
    minTablet: PropTypes.bool,
  }).isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({}),
  }).isRequired,
};

export default withMedia(withAlertMsg(withRouter(EditUserSettingsForm)));

/**
 * @flow
 * @relayHash f5f370a6b0be09e7c016962a7aba096f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NotificationAndMessagesTabsQueryVariables = {||};
export type NotificationAndMessagesTabsQueryResponse = {|
  +thread_list: ?{|
    +threads_with_unread_messages_count: ?number,
    +unread_notifications_count: ?number,
  |}
|};
export type NotificationAndMessagesTabsQuery = {|
  variables: NotificationAndMessagesTabsQueryVariables,
  response: NotificationAndMessagesTabsQueryResponse,
|};
*/


/*
query NotificationAndMessagesTabsQuery {
  thread_list {
    threads_with_unread_messages_count
    unread_notifications_count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "thread_list",
    "storageKey": null,
    "args": null,
    "concreteType": "UserThreadInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "threads_with_unread_messages_count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unread_notifications_count",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NotificationAndMessagesTabsQuery",
  "id": null,
  "text": "query NotificationAndMessagesTabsQuery {\n  thread_list {\n    threads_with_unread_messages_count\n    unread_notifications_count\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NotificationAndMessagesTabsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "NotificationAndMessagesTabsQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '60f58a159f971624de5c8f4290a872e8';
module.exports = node;

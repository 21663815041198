import React from 'react';
import { graphql } from 'react-relay';
import { Accordion } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import styled from 'react-emotion';

import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import urlFor from '../../../../ecommerceCore/utils/UrlConcatination';

const AccordionList = styled.div`
  background: #148ce5;
  ul {
    padding-left: 0;
    margin: 0;
    li {
      padding: 7px 46px !important;
      font-size: ${props => props.theme.fonts.sizes.small}px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      text-transform: capitalize;
      img {
        width: 15px;
        position: relative;
        top: 2px;
        margin-right: 8px;
      }
    }
  }
`;

const taxonsItemPanels = (taxonData, departmentId, departmentName) => taxonData
  && taxonData.map(taxonInfo => (
    <AccordionList key={taxonInfo.name}>
      <ul>
        <li>
          <ExternalLink url={urlFor('getTaxonLink', [taxonInfo, departmentId, departmentName])}>
            {taxonInfo.name}
          </ExternalLink>
        </li>
      </ul>
    </AccordionList>
  ));

const topBrands = (brandsList, departmentId, departmentName) => [
  {
    key: departmentId,
    title: 'Brands',
    content: {
      key: 'Brands-content',
      content: taxonsItemPanels(brandsList, departmentId, departmentName),
    },
  },
];

const departmentsPanel = categories => categories.children
  && categories.children.map(category => ({
    key: category.id,
    title: category.name,
    content: {
      key: `${category.name}-content`,
      content: taxonsItemPanels(category.children),
    },
  }));

function departmentsContent(taxon) {
  return (
    <React.Fragment>
      <Accordion.Accordion panels={departmentsPanel(taxon)} />
      <Accordion.Accordion panels={topBrands(taxon.top_brands, taxon.ref_id, taxon.name)} />
    </React.Fragment>
  );
}
const mainDepartmentsPanels = queryResult => queryResult.taxons
  && queryResult.taxons.map(taxon => ({
    key: taxon.id,
    title: taxon.name,
    content: {
      key: `${taxon.name}-content`,
      content: departmentsContent(taxon),
    },
  }));

function Departments({ queryResult }) {
  return <Accordion.Accordion panels={mainDepartmentsPanels(queryResult)} />;
}

Departments.propTypes = {
  queryResult: PropTypes.shape({
    taxons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        ref_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        top_brands: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ),
        children: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            children: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
              }),
            ),
          }),
        ),
      }),
    ),
  }).isRequired,
};

export default withRootQuery(
  Departments,
  graphql`
    query DepartmentsQuery {
      taxons(type: department) {
        id
        name
        ref_id
        top_brands {
          id
          name
          ref_id
        }
        children {
          id
          name
          ref_id
          children {
            id
            name
            type
            ref_id
          }
        }
      }
    }
  `,
);

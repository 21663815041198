import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';
import { Label } from '~/modules/coreUI/components/basic/Labels';

const getStockLabel = (stockItemsLength) => {
  if (stockItemsLength > 3) {
    return i18next.t('ecommerceCoreUI:Stock.in');
  }
  if (stockItemsLength <= 3 && stockItemsLength > 0) {
    return i18next.t('ecommerceCoreUI:Stock.only', { stockItemsLength });
  }
  return i18next.t('ecommerceCoreUI:Stock.out');
};

const NoWrapStockLabel = styled(Label)`
  white-space: nowrap;
`;

const StockLabel = ({ itemInfo }) => (
  <NoWrapStockLabel sm primary size={['xs', 'xs', 'md']}>
    {getStockLabel(itemInfo.total_on_hand)}
  </NoWrapStockLabel>
);
StockLabel.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};
export default StockLabel;

import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import i18next from 'i18next';
import { Container } from 'semantic-ui-react';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import {
  Tabs, TabPanel, Tab, TabList,
} from '~/modules/coreUI/components/layouts/Tabs';
import { LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import ItemsListSpinner from '~/modules/ecommerceCoreUI/components/listViewLayout/ItemsListSpinner';

import RecommededProduct from './RecommendedProduct';
import RecommededShop from './RecommendedShop';

const tabListStyle = () => css`
  margin-top: 0;
`;
const tabsContainer = () => css`
  width: 100%;
  .ui.container {
    width: 100%;
    ${cssMediaMax.tablet`
        padding: 0;
    `};
  }
`;

const tabStyle = props => css`
  height: unset;
  text-align: center;
  margin-right: ${props.theme.paddings.xxxxxLarge}px;
  padding: 8px ${props.theme.paddings.xLarge}px;
  background-color: ${props.theme.colors.invertedLabels.important};
  font-size: ${props.theme.fonts.sizes.medium}px;
  opacity: 1;
  text-transform: capitalize;
  outline: none;
`;

const activeTabStyle = props => css`
  text-align: center;
  color: ${props.theme.colors.invertedLabels.important};
  background-color: ${props.theme.colors.primary};
  border-radius: 50vh;
  outline: none;
`;

const activeContentStyle = () => css`
  display: flex;
  justify-content: center;
  background-color: transparent;
`;

class RecommendedTabs extends React.PureComponent {
  render = () => {
    const { queryResult, isLoading } = this.props;
    const MIN_NUMBER_OF_PRODUCTS = 3;

    if (isLoading) {
      return (
        <Container>
          <ItemsListSpinner vertical oneRow itemsCount={7} />
        </Container>
      );
    }

    return (
      <Tabs styles={tabsContainer}>
        <TabList styles={tabListStyle}>
          {queryResult.recommended_products
            && queryResult.recommended_products.result.length > MIN_NUMBER_OF_PRODUCTS && (
              <Tab styles={tabStyle} activeStyles={activeTabStyle}>
                {i18next.t('ecommerceRecommended:RecommendedShopsAndProducts.productsTab')}
              </Tab>
          )}
          {queryResult.tracks
            && queryResult.tracks.map(track => (
              <Tab key={track.id} styles={tabStyle} activeStyles={activeTabStyle}>
                {track.name}
              </Tab>
            ))}
        </TabList>
        <LargeSpacer />
        {queryResult.recommended_products
          && queryResult.recommended_products.result.length > MIN_NUMBER_OF_PRODUCTS && (
            <TabPanel activeStyles={activeContentStyle}>
              <RecommededProduct />
            </TabPanel>
        )}

        {queryResult.tracks
          && queryResult.tracks.map(track => (
            <TabPanel key={track.id} activeStyles={activeContentStyle}>
              <RecommededShop trackname={track.track_type} />
            </TabPanel>
          ))}
      </Tabs>
    );
  }
}

RecommendedTabs.propTypes = {
  queryResult: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
};
RecommendedTabs.defaultProps = {
  queryResult: null,
};
export default withRootQuery(
  RecommendedTabs,
  graphql`
    query RecommendedTabsQuery {
      tracks(input: { only_has_recommended: true }) {
        id
        name
        track_type
      }
      recommended_products {
        result {
          name
        }
      }
    }
  `,
  RecommendedTabs,
);

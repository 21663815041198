import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: ${props => props.theme.new.spacer * 5.3}px;
  height: ${props => props.theme.new.spacer * 2.8}px;
  outline: none;
  ${props => props.toggleContainerStyle && props.toggleContainerStyle(props)};
`;

const ToggleSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.buttons.disabled.backgroundColor.inverted};
  border-radius: 50vh;
  transition: 0.4s;
  cursor: pointer;

  ${props => props.toggleSliderStyle && props.toggleSliderStyle(props)};
`;

const ToggleKnob = styled.div`
  position: absolute;
  height: ${props => props.theme.new.spacer * 2.2}px;
  width: ${props => props.theme.new.spacer * 2.2}px;
  left: 2px;
  bottom: 3px;
  background-color: ${props => props.theme.colors.invertedLabels.important};
  border-radius: 50%;
  transition: 0.4s;
  content: '';
`;

const Input = styled.input`
  display: none;

  &&:checked + div {
    background-color: ${props => props.theme.colors.secondary};
  }
  &&:focus + div {
    box-shadow: 0 0 1px ${props => props.theme.colors.secondary};
  }
  &&:checked + div + div {
    transform: translateX(26px);
  }

  ${props => props.toggleInputStyle && props.toggleInputStyle(props)};
`;

class Toggle extends Component {
  state = {
    isChecked: this.props.isChecked || false,
  };

  handleChange = () => {
    this.setState(prevState => ({ isChecked: !prevState.isChecked }), () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.isChecked);
      }
    });
  };

  onClick = () => {
    if (this.props.isDisabled && this.props.onDisabledClickMessage) {
      this.props.notifyAlert({
        messageText: this.props.onDisabledClickMessage,
        type: AlertTypes.error,
        opacity: 1,
        toastID: 'Toggle_error_toast',
      });
    }
  }

  render() {
    const {
      labelText,
      toggleSliderStyle,
      toggleContainerStyle,
      toggleInputStyle,
      isDisabled,
    } = this.props;
    const { isChecked } = this.state;
    return (
      <Row fullWidth centerAligned spaceBetweenJustified spaceBetween={0.5}>
        {labelText && (
          <Label
            bold={this.props.bold}
            semiBold={this.props.semiBoldLabel}
          >
            {labelText}
          </Label>
        )}
        <ToggleContainer toggleContainerStyle={toggleContainerStyle} onClick={this.onClick}>
          <Input
            type="checkbox"
            value={isChecked}
            checked={isChecked}
            onChange={this.handleChange}
            toggleInputStyle={toggleInputStyle}
            disabled={isDisabled}
          />
          <ToggleSlider toggleSliderStyle={toggleSliderStyle} />
          <ToggleKnob />
        </ToggleContainer>
      </Row>
    );
  }
}

Toggle.propTypes = PropTypes.shape({
  isChecked: PropTypes.bool.isRequired,
  labelText: PropTypes.string,
  notifyAlert: PropTypes.func.isRequired,
}).isRequired;

export default withAlertMsg(Toggle);

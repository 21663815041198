const FeaturedShopsTrans = {
  En: {
    title: 'Featured Shops',
    subTitle: 'Discover some of our featured shops',
  },
  Ar: {
    title: 'محلات مميزة',
    subTitle: 'اكتشف بعض محلاتنا المميزة',
  },
};
export default FeaturedShopsTrans;

import React from 'react';
import styled from 'react-emotion';

const MainImage = styled.img`
  display: block;
  width: 100%;
`;

const MainBackground = () => (
  <MainImage src={`/${imagesPathName}/homeTracks.png`} alt="Tracks" />
);

export default MainBackground;

/* eslint-env browser */
/* eslint-disable */
import React from 'react';
import styled from 'react-emotion';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import Image from '~/modules/coreUI/components/basic/Image';

const LogoImage = styled(Image)`
  padding: 0px;
`;

const HomePageLogo = props => {
  setTimeout(() => {
    var logo = document.getElementById('logoImg');
    var altText = localStorage.getItem('seoAlt');
    logo.setAttribute("alt",altText);
  }, 10000);
  return (
    <LogoImage
      id="logoImg"
      src={
        props.i18n.language === 'en'
          ? `/${imagesPathName}/Header/logo-en-lg.png`
          : `/${imagesPathName}/Header/logo-header.png`
      }
    />
  );
};

HomePageLogo.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
};

export default translate()(HomePageLogo);

/**
 * @flow
 * @relayHash 21dcd4093f1484e6194173367d5f45d1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DepartmentsLinksQueryVariables = {||};
export type DepartmentsLinksQueryResponse = {|
  +taxons: $ReadOnlyArray<?{|
    +id: string,
    +ref_id: string,
    +name: string,
  |}>
|};
export type DepartmentsLinksQuery = {|
  variables: DepartmentsLinksQueryVariables,
  response: DepartmentsLinksQueryResponse,
|};
*/


/*
query DepartmentsLinksQuery {
  taxons(type: department) {
    id
    ref_id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "taxons",
    "storageKey": "taxons(type:\"department\")",
    "args": [
      {
        "kind": "Literal",
        "name": "type",
        "value": "department",
        "type": "TaxonKind"
      }
    ],
    "concreteType": "Taxon",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ref_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DepartmentsLinksQuery",
  "id": null,
  "text": "query DepartmentsLinksQuery {\n  taxons(type: department) {\n    id\n    ref_id\n    name\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DepartmentsLinksQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "DepartmentsLinksQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12dc7ec633ee69ba0a9d05f0e1081969';
module.exports = node;

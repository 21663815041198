import i18next from 'i18next';
import ProductsTracks from './ProductTracks';
import Compare from './Compare';

const loadLocales = () => {
  i18next.addResourceBundle(
    'en',
    'ecommerceProducts',
    {
      metadata: {
        name: 'ecommerceProducts',
        displayName: 'recommended Module',
        description: 'This module is used to show products tracks',
      },
      ProductsTracks: {
        ...ProductsTracks.En,
      },
      compare: {
        ...Compare.En,
      },
    },
    true,
    true,
  );

  i18next.addResourceBundle(
    'ar',
    'ecommerceProducts',
    {
      metadata: {
        name: 'ecommerceProducts',
        displayName: 'recommended Module',
        description: 'This module is used to show products tracks',
      },
      home: {
        menuTitle: 'recommended shops and products',
      },
      ProductsTracks: {
        ...ProductsTracks.Ar,
      },
      compare: {
        ...Compare.Ar,
      },
    },
    true,
    true,
  );

  i18next.loadNamespaces('ecommerceProducts');
};

export default loadLocales;

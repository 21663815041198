import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import EmptyStatePage from '~/modules/coreUI/components/layouts/emptyStatePage';
import SmartCartEmptyStateActions from '../smartCartActions/SmartCartEmptyStateActions';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const EmptyPage = props => (
  <React.Fragment>
    <EmptyStatePage
      imagePath={`/${imagesPathName}/-e-smart.png`}
      contentHeader={!props.continueShopping ? i18next.t('ecommerceOrder:smartCart.emptySmartCart') : i18next.t('ecommerceOrder:smartCart.emptySmartCartDetails')}
      content={!props.continueShopping ? i18next.t('ecommerceOrder:smartCart.noSmartCartsText') : i18next.t('ecommerceOrder:smartCart.noSmartCartsDetailsText')}
    />
    <Spacer size={2.5} />
    <SmartCartEmptyStateActions edit={props.edit} continueShopping={props.continueShopping} />
  </React.Fragment>
);

EmptyPage.defaultProps = {
  edit: false,
  continueShopping: false,
};

EmptyPage.propTypes = {
  edit: PropTypes.bool,
  continueShopping: PropTypes.bool,
};

export default EmptyPage;

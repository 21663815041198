const LuckyWheelTrans = {
  En: {
    spin: 'Spin',
    spinAndWin: 'Spin & Win!',
    stayPlay: 'Start play if you feel lucky!',
    haveChance: 'You have a chance to win from Ayk Mall!',
    spinOnce: '*You can spin the wheel once per day!',
    winCoupon: '*If you win you can use the coupon before the end of the day only or Specific time!',
    email: 'Add Your Email Here',
    notLucky: 'No, I do not feel lucky',
    missingEmail: 'Email is missing',
    notValidEmail: 'Email is not valid',
    congrats: 'Congratulations!',
    luckyDay: 'This is your lucky day, you won',
    notLuckyDay: 'You are not lucky today! you can try tomorrow.',
    coupon: 'coupon',
    close: 'Close',
    promoCode: 'Promo code',
  },
  Ar: {
    spin: 'لف',
    spinAndWin: 'تدور واربح!',
    stayPlay: 'ابدأ اللعب إذا كنت محظوظًا!',
    haveChance: 'لديك الفرصة للربح من مول ايك',
    spinOnce: '*يمكنك تدوير العجلة مرة واحده فى اليوم',
    winCoupon: '*اذا ربحت يمكنك استخدام القسيمة قبل نهاية اليوم او فى وقت محدد',
    email: 'اضف البريد الالكترونى هنا',
    notLucky: 'لا, انا لا اشغر بالحظ اليوم',
    missingEmail: 'من فضلك ادخل الايميل',
    notValidEmail: 'البريد الالكترونى غير صالح',
    congrats: 'مبروك!',
    luckyDay: 'هذا يوم حظك, انت ربحت',
    notLuckyDay: 'انت لست محظوظ اليوم! يمكنك اعادة المحاولة غدا',
    coupon: 'كوبون',
    close: 'اغلاق',
    promoCode: 'كود الكوبون',
  },
};

export default LuckyWheelTrans;

const RefundTrans = {
  En: {
    refundNo: 'Refund No.',
    refundDate: 'Refund Placed On',
    refundDetails: 'Refund Details',
    showDetails: 'Show Details',
    hideDetails: 'Hide Details',
    status: 'Status',
    refundedDate: 'Refunded date',
    trackingNo: 'Tracking No.',
    items: 'Items',
    emptyRefunds: 'No Refunds Yet !',
    refundReturns: 'Refunds & Returns',
    refundItem: 'Refund item',
    refundOrder: 'Refund Order',
    cancelOrder: 'Cancel Order',
    cancelService: 'Cancel Service',
    refundReason: 'Refund Reason',
    detailedReason: 'Detailed Reason',
    myNote: 'My Note',
    shopNote: 'Shop Note',
    refundAmount: 'Refund Amount',
    returnPaymentMethod: 'Return Payment Method',
    returnShippingMethod: 'Return Shipping Method',
    bankName: 'Bank Name',
    accountName: 'Account Name',
    accountNumber: 'Account Number',
    IBANNumber: 'IBAN Number',
    nonRefundableProduct: 'Non Refundable Product',

    whyRefund: 'Why do you want to refund ?',
    itemHasProblem: 'The item has a problem',
    dontWantItem: 'I don’t want the item',
    detailedReasonPlaceholder: 'Select detailed reason',
    comment: 'Comment',
    optional: 'Optional',
    writeYourComment: 'Write your comment',
    uploadImage: 'Upload Product Image',
    onlinePayment: 'Online Payment',
    onlinePaymentMessage: 'We will review your request, if request is accepted you will receive your money back on your card',
    bankAccount: 'Bank account',
    fillBankCreds: 'Fill Bank Credentials',
    bankNamePlaceholder: 'Enter your bank name in En/Ar',
    accountNameField: 'Bank Account Name',
    accountNamePlaceholder: 'Enter your bank account name in En.',
    acountNumberField: 'Bank Account Number',
    accountNumberPlaceholder: 'Enter your bank account number',
    swiftCodeField: 'SWIFT Code',
    swiftCodePlaceholder: 'Enter your SWIFT code',
    ibanNumberField: 'IBAN Number',
    ibanNumberPlaceholder: 'Enter your IBAN number',
    pickupLabel: 'Return item(s) to shop and pick up cash',
    pickupMessage: 'Please return item(s) to shop to review. If request is accepted, you will receive verification code on your email, please show it to the shop to take your money back.',
    myOrdersHeader: 'My Orders',
    refundOrderRequest: 'Refund Order Request',
    refundItemRequest: 'Refund Item Request',
    cancel: 'Cancel',
    submit: 'Submit',
    orLabel: 'Or',
  },
  Ar: {
    refundNo: 'رقم طلب الإرجاع',
    refundDetails: 'تفاصيل الاسترجاع',
    showDetails: 'اظهار التفاصيل',
    hideDetails: 'اخفاء التفاصيل',
    refundDate: 'تاريخ الاسترجاع',
    status: 'الحالة',
    refundedDate: 'موعد التسليم',
    trackingNo: 'تتبع الى',
    items: 'طلبات',
    emptyRefunds: 'لا يوجد استرجاعات',
    refundReturns: 'العودة إلى الاسترجاعات',
    refundItem: 'ارجاع المنتج',
    refundOrder: 'ارجاع الطلب',
    cancelOrder: 'الغاء الطلب',
    cancelService: 'الغاء الخدمة',
    refundReason: 'سبب الارجاع',
    detailedReason: 'السبب المفصل',
    myNote: 'ملاحظاتي',
    shopNote: 'ملاحظات المتجر',
    refundAmount: 'قيمة الارجاع',
    returnPaymentMethod: 'طريقة الدفع',
    returnShippingMethod: 'طريقة الشحن',
    bankName: 'اسم البنك',
    accountName: 'اسم الحساب',
    accountNumber: 'رقم الحساب',
    IBANNumber: 'رقم الأيبان',
    nonRefundableProduct: 'منتج غير قابل للاسترجاع',

    whyRefund: 'لماذا تريد الارجاع ؟',
    itemHasProblem: 'المنتج به مشكلة',
    dontWantItem: 'لا أريد المنتج',
    detailedReasonPlaceholder: 'اختر السبب المفصل',
    comment: 'التعليق',
    optional: 'اختياري',
    writeYourComment: 'اكتب تعليقك',
    uploadImage: 'ارفع صورة المنتج',
    onlinePayment: 'الدفع أونلاين',
    onlinePaymentMessage: 'سنراجع طلبك، وبعد الموافقة عليه ستسترد أموالك على بطاقة الائتمان المستخدمة في الدفع وفقًا للمدة المقررة في الشروط والأحكام.',
    bankAccount: 'الحساب البنكي',
    fillBankCreds: 'أدخل بياناتك البنكية',
    bankNamePlaceholder: 'أدخل اسم البنك بالإنجليزية أو بالعربية',
    accountNameField: 'اسم الحساب البنكي',
    accountNamePlaceholder: 'أدخل اسم حسابك البنكي بالإنجليزيةة',
    acountNumberField: 'رقم الحساب البنكي',
    accountNumberPlaceholder: 'أدخل رقم حسابك البنكي',
    swiftCodeField: 'رمز السويفت',
    swiftCodePlaceholder: 'أدخل رمز السويفت',
    ibanNumberField: 'رقم الأيبان',
    ibanNumberPlaceholder: 'أدخل رقم الأيبان',
    pickupLabel: 'أرجع المنتج إلى المحل وتسلم أموالك بنفسك',
    pickupMessage: 'يرجى إرجاع المنتج (المنتجات) إلى المحل للمراجعة. إذا تم قبول الطلب سيصلك رمز التحقق على البريد الإلكتروني، يرجى إبرازه للمحل حتى تستعيد المبلغ نقدًا.',
    myOrdersHeader: 'طلباتي',
    refundOrderRequest: 'طلب استرجاع الطلب',
    refundItemRequest: 'طلب استرجاع المنتج',
    cancel: 'إلغاء',
    submit: 'إرسال',
    orLabel: 'أو',
  },
};
export default RefundTrans;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Trans, Interpolate, translate } from 'react-i18next';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import {
  XXXXXLargeSpacer,
  XXLargeSpacer,
  LargeSpacer,
} from '~/modules/coreUI/components/legacies/Spacers';
import { XXLargeLabel, XLargeLabel } from '~/modules/coreUI/components/legacies/Labels';
import { CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import Image from '~/modules/coreUI/components/basic/Image';

const XXLargeLabelStyled = styled(XXLargeLabel)`
  text-align: center;
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  letter-spacing: 0.6px;
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.xLarge}px;
  `};
`;

const XLargeLabelStyled = styled(XLargeLabel)`
  text-align: center;
  color: ${props => props.theme.colors.labels.important};
  letter-spacing: 0.6px;
  ${props => cssMediaMax.tablet`
    padding: 0 ${props.theme.paddings.xLarge}px;
    font-size: ${props.theme.fonts.sizes.large}px;
    line-height: 1.5;
  `} ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.medium}px;
  `};
`;

const ImageStyled = styled(Image)`
  width: 200px;
  height: 170px;
  ${cssMediaMax.tablet`
    width: 152px;
    height: 127px;
  `} text-align: center;
`;
const HintSearchText = styled(XLargeLabel)`
  ${props => cssMediaMax.tablet`
    padding: 0 ${props.theme.paddings.xLarge}px;  
    text-align: center;
    font-size: ${props.theme.fonts.sizes.large}px;
    line-height: 1.5;
  `} ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.fonts.sizes.medium}px;
  `};
`;
const SearchStringText = styled(XLargeLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  color: ${props => props.theme.colors.labels.important};
  ${props => cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.large}px;
  `};
`;
// TODO: check image size and icon or not

const EmptyResultPage = ({ searchString, isProduct, isVariant }) => (
  <React.Fragment>
    <Spacer size={4} />
    <CenterAlignedColumn>
      <XXLargeLabelStyled>
        {isProduct || isVariant ? (
          <Trans i18nKey="NoProductsResults.NoProductsFound" />
        ) : (
          <Trans i18nKey="NoShopsResults.NoShopsFound" />
        )}
      </XXLargeLabelStyled>
      <XXXXXLargeSpacer />
      {isProduct || isVariant ? (
        <ImageStyled src={`/${imagesPathName}/no-products@3x.png`} />
      ) : (
        <ImageStyled src={`/${imagesPathName}/no-shops@3x.png`} />
      )}
      <XXLargeSpacer />
      <XLargeLabelStyled>
        <Interpolate i18nKey="NoProductsResults.SorryNoResultFound" />
        {searchString && (
          <React.Fragment>
            <Interpolate i18nKey="NoProductsResults.for" />
            <SearchStringText>{`“${searchString}”`}</SearchStringText>
          </React.Fragment>
        )}
        &nbsp;!
      </XLargeLabelStyled>
      <LargeSpacer />
      <HintSearchText>
        {isProduct || isVariant ? (
          <Trans i18nKey="NoProductsResults.ChangeSearchCriteria" />
        ) : (
          <Trans i18nKey="NoShopsResults.ChangeSearchCriteria" />
        )}
      </HintSearchText>
    </CenterAlignedColumn>
    <Spacer size={6} />
  </React.Fragment>
);

EmptyResultPage.propTypes = {
  searchString: PropTypes.string,
  isProduct: PropTypes.bool.isRequired,
  isVariant: PropTypes.bool.isRequired,
};
EmptyResultPage.defaultProps = {
  searchString: null,
};

export default translate('ecommerceCoreUI')(EmptyResultPage);

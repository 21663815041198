import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { currentCurrencyDisplayName } from '~/modules/ecommerceCore/utils/changeCurrency';

const CurrentPrice = ({ itemInfo }) => (
  <Label primary size={['sm', 'xl']} semiBold>
    {`${itemInfo.price} ${currentCurrencyDisplayName()}`}
  </Label>
);

CurrentPrice.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default CurrentPrice;

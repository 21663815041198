import React from 'react';
import styled from 'react-emotion';

import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

export const InputContainer = styled.div`
  width: 100%;
`;

export const MapWrapper = styled(Row)`
  width: 100%;
`;

const shouldShowInwani = locals => locals.inputs.inwani.props.options.attrs.isVisible(locals);

const DesktopFormLayout = locals => (
  <Column fullWidth centerJustified pt={2}>
    <Row fullWidth spaceBetween={1.5}>
      <InputContainer>{locals.inputs.firstname}</InputContainer>
      <InputContainer>{locals.inputs.lastname}</InputContainer>
    </Row>
    <InputContainer>{locals.inputs.phone}</InputContainer>
    <Row fullWidth spaceBetween={1.5}>
      <InputContainer>{locals.inputs.country}</InputContainer>
      <InputContainer>{locals.inputs.state}</InputContainer>
    </Row>
    <Row stretchAligned fullWidth spaceBetween={2}>
      {shouldShowInwani(locals) && (
        <InputContainer>{locals.inputs.inwani}</InputContainer>
      )}
      <InputContainer>{locals.inputs.map}</InputContainer>
    </Row>
    <Column fullWidth>
      <InputContainer>{locals.inputs.address1}</InputContainer>
      <InputContainer>{locals.inputs.save_as_default}</InputContainer>
    </Column>
  </Column>
);

export default DesktopFormLayout;

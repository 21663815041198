import React from 'react';
import PropTypes from 'prop-types';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';

const MobilePanelFooter = props => (
  <Column leftAligned spaceBetween={1}>
    <Row>{props.orderDeliveryDate}</Row>
    <Row>{props.orderTrackingNumber}</Row>
    <Row>{props.orderNote}</Row>
    <Row spaceBetweenJustified fullWidth>
      {props.refundOrderButton}
      {props.orderCancelButton}
      {(props.refundOrderButton || props.orderCancelButton) && (
        <Separator vertical separatorLength="full" spacerSize={1.5} />
      )}
      {props.orderDetailsButton}
    </Row>
  </Column>
);

MobilePanelFooter.defaultProps = {
  orderDeliveryDate: null,
  orderTrackingNumber: null,
  refundOrderButton: null,
  orderCancelButton: null,
  orderNote: null,
  orderDetailsButton: null,
};

MobilePanelFooter.propTypes = {
  orderDeliveryDate: PropTypes.element,
  orderTrackingNumber: PropTypes.element,
  refundOrderButton: PropTypes.element,
  orderCancelButton: PropTypes.element,
  orderNote: PropTypes.element,
  orderDetailsButton: PropTypes.element,
};

export default MobilePanelFooter;

/* eslint-disable , no-await-in-loop */
import cuid from 'cuid';
import { createPromise, last, objectToMultilineString } from './utils';
import { retrieveUserInfoFromStorage } from '../modules/core/utils/accessManagementHelpers/userInfoUtils';

// cloudwatch config
AWS.config.region = 'eu-central-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'eu-central-1:9f6454ec-c104-4bac-85b1-1d00ce43422f',
});
const cloudWatchLogs = new AWS.CloudWatchLogs({ apiVersion: '2014-03-28' });

const describeLogStreams = createPromise(
  cloudWatchLogs.describeLogStreams.bind(cloudWatchLogs),
);
const putLogEvents = createPromise(
  cloudWatchLogs.putLogEvents.bind(cloudWatchLogs),
);

async function getSequenceToken() {
  const { logStreams } = await describeLogStreams({
    logGroupName: 'FrontendLogs',
    logStreamNamePrefix: 'production',
  });
  const sequenceToken = last(logStreams).uploadSequenceToken;
  return sequenceToken;
}

const logsQueue = [];
let isSending = false;
async function send() {
  if (isSending) {
    return;
  }
  isSending = true;

  let sequenceToken = await getSequenceToken();
  while (logsQueue.length) {
    const tempLogsQueue = [...logsQueue];
    logsQueue.length = 0;

    const { email, token } = retrieveUserInfoFromStorage() || {};
    ({ nextSequenceToken: sequenceToken } = await putLogEvents({
      logGroupName: 'FrontendLogs',
      logStreamName: 'production',
      sequenceToken,
      logEvents: tempLogsQueue.map(({ id, ...message }) => ({
        timestamp: Date.now(),
        message: objectToMultilineString({
          id: id || cuid(),
          url: window.location,
          email,
          token,
          ...message,
        }),
      })),
    }));
  }

  isSending = false;
}
/**
 * @param {Object.<string, number|string>} message
 */
function sendErrorToCloudwatch(message) {
  // AYK_ENV should added as "production" on build
  if (process.env.AYK_ENV === 'production') {
    console.log('SENDING ERROR LOG');
    logsQueue.push(message);
    send();
  } else {
    // we are in dev or testing
    // should not report any thing
  }
}

export default sendErrorToCloudwatch;

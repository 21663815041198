import React from 'react';
import i18next from 'i18next';
import changeCase from 'change-case';

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import accountSettingsLinks from '~/modules/accountManagement/containers/accountSettings/layout/TabPanelContent';

export default {
  HOME: () => ({
    label: <i className="fa fa-home" />,
    url: '/',
  }),
  SHOPS_TRACK: (trackType, trackName) => ({
    label: changeCase.titleCase(trackName),
    url: urlFor('getShopTracksLink', [changeCase.camelCase(trackType)]),
  }),
  SHOPS_DEPARTEMENT: (departementID, departementName, trackType) => ({
    label: departementName,
    url: urlFor('getShopTracksLink', [changeCase.camelCase(trackType), { name: departementName, ref_id: departementID }]),
  }),
  SHOP: (shopID, shopName) => ({
    label: shopName,
    url: urlFor('getProductTracksLink', [null, shopID, shopName]),
  }),
  CATEGORY: (categoryID, categoryName) => ({
    label: changeCase.titleCase(categoryName),
    url: urlFor('getCategoryLink', [categoryID, categoryName]),
  }),
  VARIANT: (slug, variantName, vendor = {}) => ({
    label: variantName,
    url: urlFor('getProductDetailsLink', [slug, null, vendor]),
  }),
  PRODUCTS_DEPARTEMENT: (departementID, departementName) => ({
    label: departementName,
    url: urlFor('getProductTracksLink', [{ name: departementName, ref_id: departementID }]),
  }),
  PRODUCTS: () => ({
    label: i18next.t('aykLayout:breadcrumbs.products'),
    url: urlFor('getProductTracksLink'),
  }),
  ASK_SHOP: (shopID, shopName) => ({
    label: `${i18next.t('aykLayout:breadcrumbs.ask')} ${shopName}`,
    url: urlFor('getAskShopLink', [shopName, shopID]),
  }),
  ASK_AYK: () => ({
    label: i18next.t('aykLayout:breadcrumbs.askAykMall'),
    url: urlFor('getAskAykLink'),
  }),
  CART: () => ({
    label: i18next.t('aykLayout:breadcrumbs.cart'),
    url: urlFor('getCartLink'),
  }),
  MY_ACCOUNT_HOME: () => ({
    label: i18next.t('aykLayout:breadcrumbs.myAccount'),
    url: urlFor('accountSettings', ['profile']),
  }),
  MY_ACCOUNT: tab => ({
    label: i18next.t(accountSettingsLinks.find(x => x.value === tab).title),
    url: urlFor('accountSettings', [tab]),
  }),
  MY_ACCOUNT_TAB: (tab, tabIndex) => ({
    label: changeCase.titleCase(tabIndex),
    url: urlFor('accountSettings', [tab, tabIndex]),
  }),
  SUPPORT_HOME: () => ({
    label: i18next.t('aykLayout:breadcrumbs.support'),
    url: urlFor('getStaticPageLink', ['support']),
  }),
  SUPPORT_FAQ: page => ({
    label: `${changeCase.titleCase(page)} FAQ`,
    url: urlFor('getFaqDetails', [page]),
  }),
  SUPPORT_POLICY: page => ({
    label: changeCase.titleCase(page),
    url: urlFor('getStaticPageLink', [page]),
  }),
  SEARCH_RESULT: () => ({
    label: i18next.t('aykLayout:breadcrumbs.searchResult'),
    url: '/',
  }),
  TRACKS: track => ({
    label: changeCase.titleCase(track),
    url: !track ? urlFor('getProductTracksLink') : urlFor('getShopTracksLink', [track]),
  }),
};

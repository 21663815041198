import React from 'react';
import i18next from 'i18next';
import { Accordion } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { AccordionMainList } from './DropdownContainer';

const DropdownElement = ({
  CurrencyContent, LanguageContent, AccountContent, TracksContent,
}) => {
  const innerRootPanels = [
    { title: i18next.t('accountManagement:Profile.myAccount'), content: { key: 'panel-1', content: AccountContent } },
    { title: i18next.t('ecommerceCoreUI:HeaderSubMenuTracks'), content: { key: 'panel-2', content: TracksContent } },
    {
      title: i18next.t('ecommerceCoreUI:HeaderSubMenuLanguage'),
      content: { key: 'panel-4', content: LanguageContent },
    },
    {
      title: i18next.t('ecommerceCoreUI:HeaderSubMenuCurrency'),
      content: { key: 'panel-5', content: CurrencyContent },
    },
  ];
  return (
    <AccordionMainList>
      <Accordion panels={innerRootPanels} inverted />
    </AccordionMainList>
  );
};

DropdownElement.propTypes = {
  CurrencyContent: PropTypes.element.isRequired,
  LanguageContent: PropTypes.element.isRequired,
  AccountContent: PropTypes.element.isRequired,
  TracksContent: PropTypes.element.isRequired,
};

export default DropdownElement;

/**
 * @flow
 * @relayHash 1b8102b346c3bfa3aa952145f4866638
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SeoConfigurationsQueryVariables = {||};
export type SeoConfigurationsQueryResponse = {|
  +seo_configurations: ?{|
    +title: ?string,
    +meta_description: ?string,
    +meta_keywords: ?string,
    +alternate_name: ?string,
    +content_header: ?string,
  |}
|};
export type SeoConfigurationsQuery = {|
  variables: SeoConfigurationsQueryVariables,
  response: SeoConfigurationsQueryResponse,
|};
*/


/*
query SeoConfigurationsQuery {
  seo_configurations {
    title
    meta_description
    meta_keywords
    alternate_name
    content_header
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "seo_configurations",
    "storageKey": null,
    "args": null,
    "concreteType": "SeoConfigurations",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "meta_description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "meta_keywords",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "alternate_name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "content_header",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SeoConfigurationsQuery",
  "id": null,
  "text": "query SeoConfigurationsQuery {\n  seo_configurations {\n    title\n    meta_description\n    meta_keywords\n    alternate_name\n    content_header\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SeoConfigurationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "SeoConfigurationsQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d2e400cf7a06ba36ef8984cfcc6223e';
module.exports = node;

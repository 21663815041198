const InviteFriendTrans = {
  En: {
    youEarn: 'Earn 5 points and promo code from Ayk when your friend signed up form your link',
    yourFriendEarn: 'Your friend will earn 5 points after signing up from your link',
    invitationLink: 'Invitation',
    copyLink: 'Copy',
    successMessage: 'Copied to clipboard',
  },
  Ar: {
    youEarn: 'احصل على 5 نقط و رمز ترويجى من ايك عندما يقوم صديق لك بالتسجيل من اللينك الخاص بك',
    yourFriendEarn: 'صديقك سوف يحصل على 5 نقاط عند قيامه بالتسجيل من اللينك الخاص بك',
    invitationLink: 'الدعوة',
    copyLink: 'انسخ',
    successMessage: 'نسخ إلى الحافظة',
  },
};
export default InviteFriendTrans;

const createNewPassword = {
  En: {
    CreateNewPassParagraph:
      'Type a new password, and make sure that you didn\'t use this one before',
    Button: 'Renew Your Password',
    PasswordPlaceHolder: 'New Password',
    ConfirmPasswordPlaceHolder: 'Confirm Password',
  },
  Ar: {
    CreateNewPassParagraph: 'اكتب كلمة مرور جديدة، وتأكد من أنك لم تستخدمها من قبل',
    Button: 'تحديث كلمة المرور',
    PasswordPlaceHolder: 'كلمة المرور الجديدة',
    ConfirmPasswordPlaceHolder: 'تأكيد كلمة المرور',
  },
};
export default createNewPassword;

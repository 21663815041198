import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import {
  MediumLabel,
  XSmallLabel,
} from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withLuckyWheelInfo from './withLuckyWheelInfo';

const RowStyle = styled(Row)`
  cursor: pointer;
`;

const CloseLuckyWheelBox = ({ handleToggleLuckyWheel, text }) => (
  <RowStyle centerJustified spaceBetween={0.5} onClick={() => handleToggleLuckyWheel()}>
    <XSmallLabel>
      <i className="el-icon el-icon-remove" />
    </XSmallLabel>
    <MediumLabel>
      {text}
    </MediumLabel>
  </RowStyle>
);
CloseLuckyWheelBox.propTypes = {
  handleToggleLuckyWheel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default withLuckyWheelInfo(CloseLuckyWheelBox);

import styled from 'react-emotion';

export default styled.div`
  &&& {
    ${null} @media only screen and (min-width: 1200px, max-width: 1450px)
    .container {
      width: 1127px;
      background: red;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    @media only screen and (min-width: 1450px) .container {
      width: 1366px;
      background: blue;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import ItemCard from '~/modules/coreUI/components/itemCard';
import EmptyWishList from './EmptyWishList';

const WishList = ({ queryResult }) => (
  <React.Fragment>
    {queryResult && queryResult.current_user_details.current_user && queryResult.current_user_details.current_user.wished_variants.length > 0 ? (
      queryResult.current_user_details.current_user.wished_variants.map(variantInfo => (
        <React.Fragment key={variantInfo.id}>
          <ItemCard
            key={variantInfo.id}
            isVertical={false}
            isAddToCart
            itemInfo={variantInfo}
          />
          <Spacer size={1.5} />
        </React.Fragment>
      ))
    ) : (
      <EmptyWishList />
    )}
  </React.Fragment>
);
WishList.propTypes = {
  queryResult: PropTypes.shape({}),
};
WishList.defaultProps = {
  queryResult: [],
};
export default withRootQuery(
  WishList,
  graphql`
    query WishListQuery {
      current_user_details{
        current_user{
          ...WishData_variantInfo @relay(mask: false)
        }
        errors {
          field
          messages
          code
        }
      }
    }
  `,
  null,
  null,
  { force: true },
);

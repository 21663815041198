/**
 * @flow
 * @relayHash c2e170fb1982723b926cd1b5b12a4037
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FollowMutationVariables = {|
  vendor_id: string
|};
export type FollowMutationResponse = {|
  +follow: ?{|
    +current_user: ?{|
      +followed_shops: ?$ReadOnlyArray<?{|
        +id: string,
        +logo_url: ?string,
        +ref_id: string,
        +name: ?string,
        +rating_count: ?number,
        +avg_rating: ?number,
        +follow: ?boolean,
        +followed: ?boolean,
        +track: ?string,
        +city: ?{|
          +name: ?string
        |},
        +country: ?{|
          +name: ?string
        |},
        +featured: ?boolean,
        +departments: ?$ReadOnlyArray<?{|
          +id: string,
          +name: string,
        |}>,
        +track_sub_departments: ?$ReadOnlyArray<?{|
          +id: string,
          +name: string,
          +track_department_id: string,
          +ref_id: string,
        |}>,
      |}>
    |},
    +result: ?{|
      +followed: ?boolean
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type FollowMutation = {|
  variables: FollowMutationVariables,
  response: FollowMutationResponse,
|};
*/


/*
mutation FollowMutation(
  $vendor_id: ID!
) {
  follow(vendor_id: $vendor_id) {
    current_user {
      followed_shops {
        id
        logo_url
        ref_id
        name
        rating_count
        avg_rating
        follow
        followed
        track
        city {
          name
          id
        }
        country {
          name
          id
        }
        featured
        departments {
          id
          name
        }
        track_sub_departments {
          id
          name
          track_department_id
          ref_id
        }
      }
      id
    }
    result {
      followed
      id
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vendor_id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "vendor_id",
    "variableName": "vendor_id",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "followed",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "follow",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logo_url",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "track",
  "args": null,
  "storageKey": null
},
v11 = [
  v5
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "featured",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "departments",
  "storageKey": null,
  "args": null,
  "concreteType": "TrackDepartment",
  "plural": true,
  "selections": [
    v3,
    v5
  ]
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "track_sub_departments",
  "storageKey": null,
  "args": null,
  "concreteType": "TrackSubDepartment",
  "plural": true,
  "selections": [
    v3,
    v5,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "track_department_id",
      "args": null,
      "storageKey": null
    },
    v4
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = [
  v5,
  v3
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "FollowMutation",
  "id": null,
  "text": "mutation FollowMutation(\n  $vendor_id: ID!\n) {\n  follow(vendor_id: $vendor_id) {\n    current_user {\n      followed_shops {\n        id\n        logo_url\n        ref_id\n        name\n        rating_count\n        avg_rating\n        follow\n        followed\n        track\n        city {\n          name\n          id\n        }\n        country {\n          name\n          id\n        }\n        featured\n        departments {\n          id\n          name\n        }\n        track_sub_departments {\n          id\n          name\n          track_department_id\n          ref_id\n        }\n      }\n      id\n    }\n    result {\n      followed\n      id\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FollowMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "follow",
        "storageKey": null,
        "args": v1,
        "concreteType": "FollowVendorResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "followed_shops",
                "storageKey": null,
                "args": null,
                "concreteType": "Shop",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "city",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "State",
                    "plural": false,
                    "selections": v11
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "country",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Country",
                    "plural": false,
                    "selections": v11
                  },
                  v12,
                  v13,
                  v14
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": false,
            "selections": [
              v2
            ]
          },
          v15
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FollowMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "follow",
        "storageKey": null,
        "args": v1,
        "concreteType": "FollowVendorResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "followed_shops",
                "storageKey": null,
                "args": null,
                "concreteType": "Shop",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "city",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "State",
                    "plural": false,
                    "selections": v16
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "country",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Country",
                    "plural": false,
                    "selections": v16
                  },
                  v12,
                  v13,
                  v14
                ]
              },
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          },
          v15
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e893faf05696cf1e053c4cc2873f0d5d';
module.exports = node;

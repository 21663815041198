import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';

import withRefetchQuery from '~/modules/core/utils/relayHelpers/withRefetchQuery';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

import SearchDropDown from './SearchDropDown';

export const SHOPS_QUICK_SEARCH_DROPDOWN_KEY = 'shops_quick_search_dropdown';

const getListArray = queryResult => queryResult.shops.shops.map(item => ({
  id: item.ref_id,
  name: item.name,
  image: item.logo_url,
}));

const DropdownShops = ({
  department, queryResult, refetchMethod, isLoading, searchString,
}) => (
  <SearchDropDown
    isLoading={isLoading}
    search={(searchText) => {
      if (searchText !== '') {
        refetchMethod(
          SHOPS_QUICK_SEARCH_DROPDOWN_KEY,
          {
            freetext_search: searchText,
            type: department,
          },
          '^.input',
        );
      }
    }}
    listResult={isLoading ? [] : getListArray(queryResult)}
    itemsCount={isLoading ? 0 : queryResult.shops.page_info.items_count}
    iconClick={val => externalRedirect(urlFor('getQuickSearchShopsLink', [department, val]))}
    onEnter={item => (item.id
      ? externalRedirect(urlFor('getProductTracksLink', [null, item.id, item.name]))
      : externalRedirect(urlFor('getQuickSearchShopsLink', [department, item])))
    }
    onItemClick={item => externalRedirect(urlFor('getProductTracksLink', [null, item.id, item.name]))
    }
    onButtonClick={val => externalRedirect(urlFor('getQuickSearchShopsLink', [department, val]))}
    searchString={searchString}
  />
);

DropdownShops.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
}.isRequired;

const fragment = {
  queryResult: graphql`
    fragment DropdownShops_queryResult on Query
      @argumentDefinitions(input: { type: "ShopQueryInput" }) {
      shops(input: $input) {
        shops {
          logo_url
          name
          ref_id
        }
        page_info {
          items_count
        }
      }
    }
  `,
};

const query = graphql`
  query DropdownShopsQuery($input: ShopQueryInput) {
    ...DropdownShops_queryResult @arguments(input: $input)
  }
`;

export default withRefetchQuery(DropdownShops, query, fragment, DropdownShops, props => ({
  input: {
    type: props.department,
    freetext_search: props.searchString,
    page: {
      limit: 4,
    },
  },
}));

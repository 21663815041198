import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { GridArea } from '~/modules/coreUI/components/layouts/helpers/GridLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const MobileGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template:
    'itemImage        itemDetails       price                 itemQuantity          total       actions' max-content
    'itemImage        itemDetails       priceWarnings         nonPriceWarnings      .           .      ' auto
    / 100px 3fr minmax(150px, 1.5fr) minmax(150px, 1.5fr) 150px 150px;

  grid-column-gap: ${props => props.columnGap * props.theme.new.spacer}px;
  grid-row-gap: ${props => props.rowGap * props.theme.new.spacer}px;
`;

class LayoutDesktop extends Component {
  state = {};

  render() {
    return (
      <MobileGrid columnGap={this.props.columnGap} rowGap={this.props.rowGap}>
        <GridArea gridArea="itemImage">{this.props.itemImage}</GridArea>
        <GridArea gridArea="itemDetails" fullWidth leftAligned>
          {this.props.itemLabel}
          <Spacer size={1} />
          {this.props.vendorLabel}
          {this.props.itemOptions && <Spacer grow />}
          {this.props.itemOptions}
        </GridArea>
        <GridArea gridArea="price" centerAligned centerJustified>
          {this.props.price}
        </GridArea>
        <GridArea gridArea="total" centerAligned centerJustified>
          {this.props.totalPrice}
        </GridArea>
        <GridArea gridArea="priceWarnings" spaceBetween={1}>
          <Spacer size="0" />
          {' '}
          {/* Workaround to force space at top if children exists */}
          {this.props.priceWarnings}
        </GridArea>
        <GridArea gridArea="itemQuantity" centerAligned centerJustified>
          {this.props.itemQuantity}
        </GridArea>
        <GridArea gridArea="nonPriceWarnings" centerAligned spaceBetween={1}>
          <Spacer size="0" />
          {' '}
          {/* Workaround to force space at top if children exists */}
          {this.props.nonPriceWarnings}
          {this.props.leftInStockWarning}
        </GridArea>
        <GridArea gridArea="actions" centerAligned centerJustified>
          {this.props.actions}
        </GridArea>
      </MobileGrid>
    );
  }
}

LayoutDesktop.defaultProps = {
  columnGap: 1,
  rowGap: 0.5,
  priceWarnings: null,
  nonPriceWarnings: null,
  leftInStockWarning: null,
};

LayoutDesktop.propTypes = {
  price: PropTypes.element.isRequired,
  priceWarnings: PropTypes.element,
  itemQuantity: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  leftInStockWarning: PropTypes.element,
  itemImage: PropTypes.element.isRequired,
  itemOptions: PropTypes.element.isRequired,
  itemLabel: PropTypes.element.isRequired,
  vendorLabel: PropTypes.element.isRequired,
  nonPriceWarnings: PropTypes.element,
  totalPrice: PropTypes.element.isRequired,
  columnGap: PropTypes.number,
  rowGap: PropTypes.number,
};

export default LayoutDesktop;

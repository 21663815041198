/* eslint-disable indent */
import React from 'react';
import styled, { css } from 'react-emotion';
import { PropTypes } from 'prop-types';

import changeLanguage from '~/modules/ecommerceCore/utils/changeLanguage';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import DropDownElementAccount from './DropDownElementAccount';
import DropDownTracks from './DropDownTracks';
import MobileCurrenciesList from './MobileCurrenciesList';
import Departments from './Departments';
import DropdownElement from './DropdownElement';
import InnerDropdownElements from './InnerDropdownElements';

const AccordionList = withDirection(styled.div`
  background: #148ce5;
  ul {
    ${props => (props.direction === 'rtl'
      ? css`
        padding-right: 0;
        `
      : css`
        padding-left: 0;
    `)}
    margin: 0;
    li {
      padding: 7px 27px;
      font-size: ${props => props.theme.fonts.sizes.small}px;
      text-transform: capitalize;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      img {
        width: 15px;
        position: relative;
        top: 2px;
      }
    }
  }
`);

export const AccordionMainList = withDirection(styled.div`
  .ui.accordion {
    &:not(.styled) {
      .title ~ .content:not(.ui) {
        padding: 0;
      }

      .accordion .title ~ .content:not(.ui) {
        padding: 0;
      }
    }
    .accordion {
      margin: 0;
    }
  }

  .accordion.ui.inverted {
    .title {
      position: relative;
      padding: 7px 27px;
      font-size: ${props => props.theme.fonts.sizes.small}px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      text-transform: capitalize;
      :after {
        width: 6px;
        height: 6px;
        position: absolute;
        ${props => (props.direction === 'rtl'
          ? css`
            right: 13px;
            `
          : css`
            left: 13px;
        `)}
        top: 15px;
        background-color: ${props => props.theme.colors.named.white};
        border-radius: 50%;
        content: '';
      }
      :before {
        position: absolute;
        ${props => (props.direction === 'rtl'
          ? css`
            left: 3px;
            `
          : css`
            right: 3px;
        `)}
        top: 10px;
        font-size: 10px;
        font-family: fontello, sans-serif;
        content: '\\e811';
      }
      i {
        display: none;
      }
    }
    .title.active:before {
      content: '\\e813';
    }
    .content .title {
      i {
        display: none;
      }
      :after {
        content: initial;
      }
    }
    .content .accordion {
      background: #148ce5;
    }
    .content .content .accordion {
      background: #0d80d5;
      .title {
        padding: 7px 37px;
      }
    }
    .content .content .content .accordion {
      background: #0b73c1;
      .title {
        padding: 7px 47px;
      }
    }
    .content .content .content .content li {
      padding: 7px 57px;
      background: #045fa2;
    }
  }
`);

export const AccountContent = (
  <AccordionList>
    <DropDownElementAccount />
  </AccordionList>
);
// Tracks
export const TracksContent = (
  <AccordionList>
    <DropDownTracks />
  </AccordionList>
);

// Language
export const LanguageContent = ({ history }) => (
  <AccordionList>
    <ul>
      {/* eslint-disable-next-line */ }
    <li className="selected" onClick={() => changeLanguage('en', history.location)}>
      <Row>
        <img src={`/${imagesPathName}/Header/uk.svg`} alt="" key="EN" />
        <Spacer size={0.8} />
        EN
      </Row>
    </li>
      {/* eslint-disable-next-line */ }
    <li className="ar" onClick={() => changeLanguage('ar', history.location)}>
      <Row>
        <img src={`/${imagesPathName}/Header/qr.png`} alt="" key="AR" />
        <Spacer size={0.8} />
        AR
      </Row>
    </li>
    </ul>
  </AccordionList>
 );


// Currency
export const CurrencyContent = (
  <AccordionList>
    <MobileCurrenciesList />
  </AccordionList>
);
export const DepartmentContent = (
  <AccordionList>
    <Departments />
  </AccordionList>
);

export const HomePageDropdownElement = RourteInfo => (
  <DropdownElement
    DepartmentContent={DepartmentContent}
    CurrencyContent={CurrencyContent}
    LanguageContent={<LanguageContent history={RourteInfo.history} />}
    TracksContent={TracksContent}
    AccountContent={AccountContent}
  />
);

export const InnerPageDropdownElement = RourteInfo => (
  <InnerDropdownElements
    CurrencyContent={CurrencyContent}
    LanguageContent={<LanguageContent history={RourteInfo.history} />}
    TracksContent={TracksContent}
    AccountContent={AccountContent}
  />
);

LanguageContent.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

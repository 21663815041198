import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withTheme } from 'emotion-theming';
import styled from 'react-emotion';

import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';

import UserDataTable from './UserDataTable';

const LabelStyle = styled(Label)`
  text-transform: capitalize;
`;

class ShowUserProfile extends React.Component {
  editUserInfo = () => {
    this.toggleEditMode();
  }

  render = () => {
    const { userInfo } = this.props;

    return userInfo && (
      <Column fullWidth spaceBetween={1.5} leftAligned>
        <LabelStyle xxl important semiBold>
          {`${userInfo.first_name} ${userInfo.last_name}`}
        </LabelStyle>
        <UserDataTable
          userInfo={{
            ...userInfo,
            birthdate: !!userInfo.birthdate_timestamp && moment(userInfo.birthdate_timestamp * 1000).format('DD/MM/YY'),
          }}
        />
      </Column>
    );
  }
}
ShowUserProfile.defaultProps = {
  queryResult: null,
};

ShowUserProfile.propTypes = {
  queryResult: PropTypes.shape({
    current_user_details: PropTypes.shape({
      current_user: PropTypes.shape({
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    }),
  }),
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};


export default withTheme(ShowUserProfile);

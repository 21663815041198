import styled, { css } from 'react-emotion';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Button from '~/modules/coreUI/components/basic/Button';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

export const InwaniWrapper = styled.div`
  width: 100%;
  border: solid 1px white;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  box-shadow: 0.5px 0.9px 5px 0 rgba(0, 0, 0, 0.22);
`;

export const InwaniContainer = styled.div`
  width: 100%;
  background-color: #2a3592;
`;

export const Input = styled.input`
  width: ${props => (props.isBuilding ? '40%' : '98%')};
  border: 0px;
  border-bottom: 2px solid ${props => props.theme.new.colors.labels.inverted.subtle}!important;
  background-color: #2a3592 !important;
  color: ${props => props.theme.new.colors.labels.inverted.important} !important;
  font-size: ${props => (props.isBuilding ? props.theme.new.fonts.sizes.header : props.theme.new.fonts.sizes.xs)}px;
  &&:focus {
    outline: none;
    border-bottom: 2px solid ${props => props.theme.new.colors.labels.inverted.important}!important;
  }
  &&::placeholder {
    font-size: ${props => props.theme.new.fonts.sizes.xs}px;
    color: ${props => props.theme.new.colors.labels.inverted.normal};
  }
`;

export const BuildingInfo = styled(Column)`
  padding: ${props => props.theme.new.spacer}px;
  border-bottom: 2px solid ${props => props.theme.new.colors.labels.inverted.important};
  border-radius: 0;
`;

export const ZoneInfo = withDirection(styled(Column)`
  padding: ${props => props.theme.new.spacer}px;
  border-radius: 0;
  ${props => (props.direction === 'rtl'
    ? css`
         border-left: 2px solid ${props.theme.new.colors.labels.inverted.important};
      `
    : css`
         border-right: 2px solid ${props.theme.new.colors.labels.inverted.important};
  `)}
`);

export const StreetInfo = styled(Column)`
  padding: ${props => props.theme.new.spacer}px;
  border-radius: 0;
`;

export const CustomButton = styled(Button)`
  color: ${props => props.theme.new.colors.labels.normal.secondary};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;

import React, { Fragment } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { css } from 'emotion';
import copy from 'copy-to-clipboard';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';
import { Row, Column, LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Button from '~/modules/coreUI/components/basic/Button';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

const getPromoIcon = (actions) => {
  if (actions.length === 1) {
    return `el-icon el-icon-${actions[0]}`;
  }
  return actions.includes('flat_rate') ? 'el-icon el-icon-off-and-shipping' : 'el-icon el-icon-free-percentage';
};

const getDate = (date) => {
  const localizedMoment = moment;
  localizedMoment.locale(i18next.language);
  return localizedMoment(date).format('DD MMM YYYY');
};

const expiryDateRenderer = (promo, media) => (media.minTablet ? (
  <Row>
    <Row>
      <Label sm>{i18next.t('accountManagement:MyPromos.expiryDate')}</Label>
      <Spacer size={0.5} />
      <Label important sm>
        {getDate(promo.expires_at)}
      </Label>
    </Row>
    <Separator vertical separatorLength="full" spacerSize={1.3} />
    <Label>{`${i18next.t('accountManagement:MyPromos.presentedBy')} ${promo.vendor ? promo.vendor.name : 'AYK Mall'}`}</Label>
  </Row>
) : (
  <Fragment>
    <Row>
      <Label sm>{i18next.t('accountManagement:MyPromos.expiryDate')}</Label>
      <Spacer size={0.5} />
      <Label important sm>
        {moment(promo.expires_at).format('DD/MM/YYYY')}
      </Label>
    </Row>
    <Spacer size={1} />
    <Label>{`${i18next.t('accountManagement:MyPromos.presentedBy')} ${promo.vendor ? promo.vendor.name : 'AYK'}`}</Label>
  </Fragment>
));

const PromoPanel = ({ promo, media, notifyAlert }) => (
  <Panel
    header={(
      <Row spaceBetweenJustified>
        <Row>
          <Label primary lg semiBold>
            <i className={getPromoIcon(promo.actions)} />
          </Label>
          <Spacer size={1} />
          <Label primary lg semiBold>{promo.display_name}</Label>
        </Row>
        <img src={`/${imagesPathName}/shape-4.png`} alt={promo.display_name} />
      </Row>
    )}
    body={(
      <Column leftAligned>
        <Label important lg semiBold>{promo.name}</Label>
        <Spacer size={1} />
        {expiryDateRenderer(promo, media)}
        <Spacer size={1.5} />
        <Label md>{promo.description}</Label>
      </Column>
    )}
    footer={(
      <LinearLayout row={media.minTablet} spaceBetweenJustified topAligned={!media.minTablet}>
        <Row>
          <Label md>{i18next.t('accountManagement:MyPromos.promoCode')}</Label>
          <Spacer size={0.5} />
          <Label md important>{promo.code}</Label>
        </Row>
        <Button
          secondary
          sm
          inverted
          borderLess
          onClicked={() => {
            copy(promo.code);
            notifyAlert({
              component: () => (
                <Label inverted important semiBold>
                  {i18next.t('accountManagement:MyPromos.copied')}
                </Label>
              ),
              type: AlertTypes.success,
              opacity: 1,
              toastID: 'PromoPanel_clicked_toast',
            });
          }}
          pxRatio="0"
          backgroundColor="rgba(0, 0, 0, 0)"
          semiBold
        >
          <i className="el-icon el-icon-copy" />
          {i18next.t('accountManagement:MyPromos.copyCode')}
        </Button>
      </LinearLayout>
    )}
    headerStyle={() => css`padding: 0 15px 0 15px;`}
    bodyStyle={() => css`padding: 15px;`}
    footerStyle={() => css`padding: 10px 15px 10px 15px;`}
  />
);

PromoPanel.propTypes = {
  promo: PropTypes.shape({}).isRequired,
  media: PropTypes.shape({}).isRequired,
  notifyAlert: PropTypes.func.isRequired,
};

export default withMedia(withAlertMsg(PromoPanel));

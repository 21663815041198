/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'react-emotion';
import { css } from 'emotion';

import Product from '~/modules/ecommerceCoreUI/containers/Product';
import Variant from '~/modules/ecommerceCoreUI/containers/Variant';
import Shop from '~/modules/ecommerceShops/containers/shopCard/Shop';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import EmptyResultPage from '~/modules/ecommerceCoreUI/components/listViewLayout/EmptyResultPage';
import { SubDepartmentName } from '~/modules/ecommerceShops/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';


const ItemsListViewUI = styled.div`
  grid-gap: 10px;
  display: grid;

  ${props => props.isVertical
    && css`
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    `}
  ${cssMediaMax.xsmall
    && css`
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  `}
  ${cssMediaMax.tablet
    && css`
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  `}
  ${cssMediaMax.smallDesktop
    && css`
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    `}
  ${props => props.isVertical === false
    && css`
      grid-template-columns: repeat(1, 1fr);
    `}
`;
const renderEmptyPage = (isProduct, isVariant, searchString) => (
  <EmptyResultPage isProduct={isProduct} isVariant={isVariant} searchString={searchString} />
);

const renderItemsList = (
  itemsList, isProduct, isVertical, vendorInfo, isVendorProduct, match, isVariant,
) => (
  <ItemsListViewUI isVertical={isVertical}>
    {itemsList
      && itemsList.map((entry) => {
        if (isProduct) {
          return (
            <Product
              isVertical={isVertical}
              // eslint-disable-next-line no-underscore-dangle
              key={entry.__id}
              productInfo={entry}
              vendorInfo={vendorInfo}
              isVendorProduct={isVendorProduct}
              isHotDeal={match.params.taxon === 'hot-deals'}
            />
          );
        }
        if (isVariant) {
          return (
            <Variant
              isVertical={isVertical}
              // eslint-disable-next-line no-underscore-dangle
              key={entry.__id}
              variantInfo={entry}
              isVendorProduct={isVendorProduct}
              variantVendorInfo={vendorInfo}
              isHotDeal={match.params.taxon === 'hot-deals'}
            />
          );
        }
        // eslint-disable-next-line no-underscore-dangle
        return <Shop isVertical shopInfo={entry} key={`shop_${entry.__id}`} />;
      })}
  </ItemsListViewUI>
);

const ItemsListView = ({
  itemsList,
  isProduct,
  isVariant,
  searchString,
  isVertical,
  vendorInfo,
  isVendorProduct,
  match,
  categorizeItemsList,
}) => (
  <React.Fragment>
    {itemsList?.length !== 0 || categorizeItemsList?.length !== 0 ? (
      <React.Fragment>
        {categorizeItemsList && (
          categorizeItemsList.map(category => (
            <React.Fragment>
              <Spacer size={2} />
              <SubDepartmentName>{category.name}</SubDepartmentName>
              <Spacer size={2} />
              {renderItemsList(category.shops, isProduct, isVertical, vendorInfo, isVendorProduct, match, isVariant)}
            </React.Fragment>
          )))
        }
        {itemsList && renderItemsList(itemsList, isProduct, isVertical, vendorInfo, isVendorProduct, match, isVariant)}
      </React.Fragment>
    ) : (
      renderEmptyPage(isProduct, isVariant, searchString)
    )}
  </React.Fragment>
);

ItemsListView.propTypes = {
  itemsList: PropTypes.instanceOf(Array),
  categorizeItemsList: PropTypes.instanceOf(Array),
  isVertical: PropTypes.bool,
  isProduct: PropTypes.bool,
  isVariant: PropTypes.bool,
  searchString: PropTypes.string,
  isVendorProduct: PropTypes.bool,
  vendorInfo: PropTypes.shape({
    vendorID: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  match: PropTypes.shape({}).isRequired,
};
ItemsListView.defaultProps = {
  isVertical: true,
  isVariant: false,
  isProduct: false,
  searchString: null,
  isVendorProduct: false,
  vendorInfo: {},
  categorizeItemsList: [],
  itemsList: [],
};

export default withRouter(ItemsListView);

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TrackCard_cardInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoriesCards_categoriesInfo$ref: FragmentReference;
export type CategoriesCards_categoriesInfo = {|
  +children: ?$ReadOnlyArray<?{|
    +$fragmentRefs: TrackCard_cardInfo$ref
  |}>,
  +$refType: CategoriesCards_categoriesInfo$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CategoriesCards_categoriesInfo",
  "type": "Taxon",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "vendor_id",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "RootArgument",
      "name": "vendorID",
      "type": "ID"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "children",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "vendor_id",
          "variableName": "vendorID",
          "type": "ID"
        }
      ],
      "concreteType": "Taxon",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "TrackCard_cardInfo",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '09420fc3e96f92e8c93c8d4291f268d9';
module.exports = node;

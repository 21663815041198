const SmartCartsTrans = {
  En: {
    emptySmartCart: 'You don’t have Smart Carts Yet!',
    emptySmartCartDetails: 'Your Smart Cart is empty !',
    noSmartCartsText: 'Seems like you have no smart carts yet.',
    noSmartCartsDetailsText: 'Seems like you have no products in your smart cart yet.',
    notAuthorizedHeader: 'You must log in',
    notAuthorizedText: 'You need to log in to view this smart carts',
    goToLoginButton: 'Go to Login',
    editSmartCartTitle: 'Edit Smart Cart Name',
    createSmartCartTitle: 'Create Smart Cart Name',
    createSmartCartPlaceholder: 'Enter Smart Cart Name',
    createNewSmartCartButton: 'Create New Smart Cart',
    cancelButton: 'Cancel',
    saveButton: 'Save',
    createButton: 'Create',
    buySmartCart: 'Buy Now',
    DeleteSmartCartTooltip: 'Delete Smart Cart',
    deleteButton: 'Delete',
    deleteMessage: 'Are you sure you want to delete this Smart Cart',
    continueShoppingButton: 'Continue Shopping',
    EditNameTooltip: 'Edit Name',
    MySmartCartsTitle: 'Smart Cart Name',
    addProductLinkText: 'Add Product',
    itemsLabel: 'items',
    smartCartListLink: 'Smart Cart List',
    shareSmartCartLabel: 'Share Smart Cart',
    addAllToCartButton: 'Add All To Cart',
    buyNowButton: 'Buy Now',
    addToCartTooltip: 'Add To Cart',
    deleteMessageOnSuccess: 'Item deleted successfully',
    deleteProductTooltip: 'Delete Product',
    itemQuantityLabel: 'item(s)',
    newSmartCartItem: 'New Item Added to Smart Cart Successfully',
    smartCartDropdownPlaceholder: 'Search / Create Smart Cart',
    smartCartNameTableHeader: 'Smart Cart Name',
    numberOfItemsTableHeader: 'No. Of Items',
    totalPriceTableHeader: 'Total Price',
  },
  Ar: {
    emptySmartCart: 'ليس لديك سلة ذكية بعد!',
    emptySmartCartDetails: 'سلة التسوق الخاصة بك فارغة!',
    noSmartCartsText: 'يبدو أنه ليس لديك عربات ذكية بعد.',
    noSmartCartsDetailsText: 'يبدو أنه ليس لديك منتجات في سلة التسوق الذكية الخاصة بك حتى الآن.',
    notAuthorizedHeader: 'يجب عليك تسجيل الدخول',
    notAuthorizedText: 'يجب عليك تسجيل الدخول لمشاهدة هذه العربات الذكية',
    goToLoginButton: 'اذهب الى تسجيل الدخول',
    editSmartCartTitle: 'تحرير اسم السلة الذكية',
    createSmartCartTitle: 'إنشاء اسم السلة الذكية',
    createSmartCartPlaceholder: 'أدخل اسم السلة الذكية',
    createNewSmartCartButton: 'إنشاء عربة ذكية جديدة',
    cancelButton: 'الغاء',
    saveButton: 'حفظ',
    createButton: 'انشاء',
    buySmartCart: 'اشتري الآن',
    DeleteSmartCartTooltip: 'حذف العربة الذكية',
    deleteButton: 'حذف',
    deleteMessage: 'هل أنت متأكد من أنك تريد حذف هذه العربة الذكية',
    continueShoppingButton: 'استمرار التسوق',
    EditNameTooltip: 'تعديل الاسم',
    MySmartCartsTitle: 'اسم السلة الذكية',
    addProductLinkText: 'أضف منتج',
    itemsLabel: 'العناصر',
    smartCartListLink: 'قائمة العربة الذكية',
    shareSmartCartLabel: 'شارك العربة الذكية',
    addAllToCartButton: 'أضف الكل إلى السلة',
    buyNowButton: 'اشتري الآن',
    addToCartTooltip: 'أضف إلى السلة',
    deleteMessageOnSuccess: 'تم حذف العنصر بنجاح',
    deleteProductTooltip: 'حذف المنتج',
    itemQuantityLabel: 'العناصر',
    newSmartCartItem: 'تمت إضافة عنصر جديد إلى سلة الذكيه بنجاح',
    smartCartDropdownPlaceholder: 'بحث / إنشاء سلة ذكية',
    smartCartNameTableHeader: 'اسم العربه الذكيه',
    numberOfItemsTableHeader: 'عدد العناصر',
    totalPriceTableHeader: 'السعر الاجمالى',

  },
};
export default SmartCartsTrans;

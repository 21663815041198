/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import ItemsListView from '~/modules/ecommerceCoreUI/components/listViewLayout/ItemsListView';

import EmptyFollowingList from './EmptyFollowingList';

const FollowingList = ({ queryResult, isLoading }) => (
  <React.Fragment>
    {queryResult && queryResult?.current_user_details?.current_user && queryResult.current_user_details.current_user.followed_shops.length > 0 ? (
      <ItemsListView
        itemsList={queryResult.current_user_details.current_user.followed_shops}
        isLoading={isLoading}
      />
    ) : (
      <EmptyFollowingList />
    )}
  </React.Fragment>
);
FollowingList.propTypes = {
  queryResult: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
};
FollowingList.defaultProps = {
  queryResult: [],
};
export default withRootQuery(
  FollowingList,
  graphql`
    query FollowingListQuery {
      current_user_details{
        errors {
          field
          messages
          code
        }
        current_user{
          followed_shops{
            ...Shop_shopInfo @relay(mask: false)
          }
        }
      }
    }
  `,
  null,
  null,
  { force: true },
);

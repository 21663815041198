/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BrandsCards_brandsInfo$ref = any;
type CategoriesCards_categoriesInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TrackContent_taxon$ref: FragmentReference;
export type TrackContent_taxon = {|
  +ref_id: string,
  +name: string,
  +logo: ?string,
  +children: ?$ReadOnlyArray<?{|
    +name: string,
    +id: string,
    +$fragmentRefs: CategoriesCards_categoriesInfo$ref,
  |}>,
  +top_brands: ?$ReadOnlyArray<?{|
    +$fragmentRefs: BrandsCards_brandsInfo$ref
  |}>,
  +$refType: TrackContent_taxon$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "vendor_id",
    "variableName": "vendorID",
    "type": "ID"
  }
];
return {
  "kind": "Fragment",
  "name": "TrackContent_taxon",
  "type": "Taxon",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "vendor_id",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "RootArgument",
      "name": "vendorID",
      "type": "ID"
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ref_id",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "children",
      "storageKey": null,
      "args": v1,
      "concreteType": "Taxon",
      "plural": true,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "CategoriesCards_categoriesInfo",
          "args": [
            {
              "kind": "Variable",
              "name": "vendor_id",
              "variableName": "vendorID",
              "type": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "top_brands",
      "storageKey": null,
      "args": v1,
      "concreteType": "Taxon",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BrandsCards_brandsInfo",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '91f31caf2e6c92a607810c5dc8d4465e';
module.exports = node;

import React from 'react';
import styled from 'react-emotion';

import { PanelRoot } from '~/modules/accountManagement/components/basic/Panel';
import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';


const InputLayout = styled(PanelRoot)`
  display: flex;
  align-items: stretch;
  width: 100%;
`;

const EditUserSettingsFormLayout = locals => (
  <Column topAligned>
    <InputLayout>{locals.inputs.country}</InputLayout>
    <Row fullWidth spaceBetween={1.5}>
      <InputLayout>{locals.inputs.default_lang}</InputLayout>
      <InputLayout>{locals.inputs.default_currency}</InputLayout>
    </Row>
    <InputLayout>{locals.inputs.newsletter_subscription}</InputLayout>
    <InputLayout>{locals.inputs.receive_push_notifications}</InputLayout>
  </Column>
);


export default EditUserSettingsFormLayout;

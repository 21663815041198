/* eslint-disable camelcase */
import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { GridArea } from '~/modules/coreUI/components/layouts/helpers/GridLayout';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import { cursorPoiner } from './Layout';

const MobileGrid = styled.div`
  width: 100%;
  min-height: ${props => 6.2 * props.theme.new.spacer}px;
  display: grid;
  grid-template:
    'smartName        noOfItems       totalPrice             actions' max-content
    'smartName        noOfItems       totalPrice             actions' auto
    / 260px minmax(150px, 1.5fr) minmax(150px, 1.5fr) 200px;

  grid-column-gap: ${props => props.columnGap * props.theme.new.spacer}px;
  grid-row-gap: ${props => props.rowGap * props.theme.new.spacer}px;
  cursor: pointer;
  &:hover{
   background-color: ${props => props.theme.new.colors.backgrounds.panel};
  }
  &&:first-child{
    padding: 0px;
    cursor: auto;
    &:hover{
      background-color: unset;
    }
  }
`;
const SmartCartLink = styled(ExternalLink)`
  width: 100%;
  padding: ${props => 1.5 * props.theme.new.spacer}px;
`;

const SmartCartContainer = styled.div`
  width: 100%;
  padding: ${props => 1.5 * props.theme.new.spacer}px;
`;

class LayoutDesktop extends Component {
  state = {};

  render() {
    const SmartCartWrapper = this.props.isClickable ? SmartCartLink : SmartCartContainer;

    return (
      <SmartCartWrapper url={urlFor('getSmartCartDetailsLink', [this.props.smartCart?.ref_id])}>
        <MobileGrid columnGap={this.props.columnGap} rowGap={this.props.rowGap}>
          <GridArea className={cursorPoiner} centerJustified leftAligned gridArea="smartName">{this.props.smartName}</GridArea>
          <GridArea className={cursorPoiner} centerJustified centerAligned gridArea="noOfItems" fullWidth>
            {this.props.noOfItems}
          </GridArea>
          <GridArea className={cursorPoiner} gridArea="totalPrice" centerAligned centerJustified>
            {this.props.totalPrice}
          </GridArea>
          <GridArea row gridArea="actions" spaceBetween={1} centerAligned centerJustified>
            {this.props.actions}
          </GridArea>
        </MobileGrid>
      </SmartCartWrapper>
    );
  }
}

LayoutDesktop.defaultProps = {
  columnGap: 1,
  rowGap: 0.5,
  smartCart: null,
  isClickable: true,
};

LayoutDesktop.propTypes = {
  noOfItems: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  smartName: PropTypes.element.isRequired,
  totalPrice: PropTypes.element.isRequired,
  columnGap: PropTypes.number,
  rowGap: PropTypes.number,
  smartCart: PropTypes.shape({
    ref_id: PropTypes.string,
  }),
  isClickable: PropTypes.bool,
};

export default LayoutDesktop;

/**
 * @flow
 * @relayHash 8e30754a4501e0f62e5fbc63c36cbc0d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AvailableSocialMedia = "apple" | "facebook" | "google" | "instagram" | "twitter" | "%future added value";
export type ConnectSocialAccountMutationVariables = {|
  token: string,
  secret?: ?string,
  email?: ?string,
  pid?: ?string,
  provider: AvailableSocialMedia,
|};
export type ConnectSocialAccountMutationResponse = {|
  +connect_social_account: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +code: ?number,
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
    |}>,
    +status: ?string,
  |}
|};
export type ConnectSocialAccountMutation = {|
  variables: ConnectSocialAccountMutationVariables,
  response: ConnectSocialAccountMutationResponse,
|};
*/


/*
mutation ConnectSocialAccountMutation(
  $token: String!
  $secret: String
  $email: String
  $pid: String
  $provider: AvailableSocialMedia!
) {
  connect_social_account(token: $token, secret: $secret, email: $email, pid: $pid, provider: $provider) {
    errors {
      code
      field
      messages
    }
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "secret",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pid",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "provider",
    "type": "AvailableSocialMedia!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "connect_social_account",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "pid",
        "variableName": "pid",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider",
        "type": "AvailableSocialMedia!"
      },
      {
        "kind": "Variable",
        "name": "secret",
        "variableName": "secret",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token",
        "type": "String!"
      }
    ],
    "concreteType": "SocialMediaConnectResult",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ConnectSocialAccountMutation",
  "id": null,
  "text": "mutation ConnectSocialAccountMutation(\n  $token: String!\n  $secret: String\n  $email: String\n  $pid: String\n  $provider: AvailableSocialMedia!\n) {\n  connect_social_account(token: $token, secret: $secret, email: $email, pid: $pid, provider: $provider) {\n    errors {\n      code\n      field\n      messages\n    }\n    status\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ConnectSocialAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ConnectSocialAccountMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '80a6bdaab2bbe41bb3f9d1d8981678bd';
module.exports = node;

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import BasicSettings from './basicSettings';
import ChangePaswword from './changePassword';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import CurrentUserQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/CurrentUserQuery';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const UserProfile = props => (
  <Column spaceBetween={1}>
    <BasicSettings
      currentUser={props.queryResult?.current_user_details?.current_user}
    />
    <ChangePaswword
      currentUserInfo={props.queryResult.current_user_details?.current_user}
    />
  </Column>
);

UserProfile.propTypes = {
  queryResult: PropTypes.shape({
    current_user_details: PropTypes.shape({
      current_user: PropTypes.shape({
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default withRootQuery(
  UserProfile,
  CurrentUserQuery,
  null,
  null,
  { force: true },
);

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

const earnPointsDetails = [
  {
    point: 5,
    for: 'ecommerceOrder:Loyalty.forPaid',
    details: 'ecommerceOrder:Loyalty.forAyk',
    actions: [{
      trigger: 'ecommerceOrder:Cart.buyNow',
      url: urlFor('getProductTracksLink'),
    }],
  },
  {
    point: 5,
    for: 'ecommerceOrder:Loyalty.forRateReview',
    details: 'ecommerceOrder:Loyalty.forPurchased',
    actions: [
      {
        trigger: 'ecommerceCoreUI:TracksTrans.products',
        url: urlFor('getProductTracksLink'),
      },
      {
        trigger: 'ecommerceCoreUI:TracksTrans.shops',
        url: urlFor('getShopTracksLink'),
      },
    ],
  },
  {
    point: 5,
    for: 'ecommerceOrder:Loyalty.forFriend',
    details: 'ecommerceOrder:Loyalty.getPromo',
    actions: [{
      trigger: 'ecommerceOrder:Loyalty.inviteFriends',
      url: urlFor('accountSettings', ['invite']),
    }],
  },
  {
    point: 1,
    for: 'ecommerceOrder:Loyalty.forShare',
    details: 'ecommerceOrder:Loyalty.onSocialMedia',
    actions: [
      {
        trigger: 'ecommerceCoreUI:TracksTrans.products',
        url: urlFor('getProductTracksLink'),
      },
      {
        trigger: 'ecommerceCoreUI:TracksTrans.shops',
        url: urlFor('getShopTracksLink'),
      },
      {
        trigger: 'ecommerceCoreUI:Tabs.SmartCart',
        url: urlFor('accountSettings', ['smartcart']),
      },
    ],
  },
];

export default earnPointsDetails;

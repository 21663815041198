import React, { Component } from 'react';
import Button from '~/modules/coreUI/components/basic/Button';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import i18next from 'i18next';

import BasicLink from '~/modules/ecommerceCoreUI/components/basic/Link';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import AddAndEditSmartCartModal from './AddAndEditSmartCartModal';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Icon from '~/modules/coreUI/components/basic/Icon';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const EditIcon = withDirection(styled(Icon)`
  margin-top: ${props => 0.7 * props.theme.new.spacer}px;
  ${props => (props.direction === 'rtl'
    ? css`
          transform: rotate(270deg);
      `
    : css`
          transform: rotate(0deg);
   `)};
`);

const CreateLabel = styled(Label)`
  cursor: pointer;
`;

const CreateLabelContainer = withMedia(styled(Row)`
  ${props => props.media.minDesktop && css`
     position: absolute;
     top: ${0.5 * props.theme.new.spacer}px;
     right: 0;
  `}
  ${props => props.media.maxTablet && css`
     margin-bottom: ${props.theme.new.spacer}px;
  `}
`);

class SmartCartEmptyStateActions extends Component {
  state = {
    showSmartModal: false,
  }

  showSmartCardModal = () => {
    this.setState({ showSmartModal: true });
  }

  closeModal = () => {
    this.setState({ showSmartModal: false });
  }

  renderButton = () => {
    let currentButton = '';
    if (this.props.edit) {
      if (this.props.isCurrentUserSmartCart) {
        currentButton = (
          <EditIcon
            title={i18next.t('ecommerceOrder:smartCart.EditNameTooltip')}
            className="el-icon el-icon-edit-lined"
            onClick={this.showSmartCardModal}
          />
        );
      } else {
        currentButton = <span />;
      }
    } else if (this.props.smartCartsLength === 0) {
      currentButton = (
        <Button width="270px" xl secondary onClicked={this.showSmartCardModal}>
          <Icon className="el-icon el-icon-add" />
          {i18next.t('ecommerceOrder:smartCart.createNewSmartCartButton')}
        </Button>
      );
    } else {
      currentButton = (
        <CreateLabelContainer spaceBetween={0.5} fullWidth rightJustified={this.props.media.minDesktop} leftJustified={!this.props.media.minDesktop}>
          <CreateLabel semiBold secondary md onClick={this.showSmartCardModal}>
            <Icon className="el-icon el-icon-add" />
          </CreateLabel>
          <CreateLabel onClick={this.showSmartCardModal} semiBold secondary md>
            {i18next.t('ecommerceOrder:smartCart.createNewSmartCartButton')}
          </CreateLabel>
        </CreateLabelContainer>
      );
    }
    return currentButton;
  }

  continueShoppingIcon = direction => (direction === 'ltr' ? 'right' : 'left');


  render() {
    const { showSmartModal } = this.state;
    const {
      smartCartName,
      smartCartId,
      edit,
      continueShopping,
      direction,
    } = this.props;

    return (
      <React.Fragment>
        {!continueShopping ? (
          this.renderButton()
        ) : (
          <BasicLink targeturl={urlFor('getProductTracksLink')} secondary>
            <Row centerJustified spaceBetween={0.5}>
              <Label secondary lg semiBold>
                {i18next.t('ecommerceOrder:smartCart.continueShoppingButton')}
              </Label>
              <Icon secondary className={`el-icon el-icon-${this.continueShoppingIcon(direction)}`} />
            </Row>
          </BasicLink>
        )}
        {showSmartModal && (
          <AddAndEditSmartCartModal
            edit={edit}
            continueShopping={continueShopping}
            onCloseModal={this.closeModal}
            smartCartId={smartCartId}
            smartCartName={smartCartName}
          />)}
      </React.Fragment>
    );
  }
}

SmartCartEmptyStateActions.defaultProps = {
  edit: false,
  continueShopping: false,
  smartCartName: '',
  smartCartId: '',
  isCurrentUserSmartCart: true,
  smartCartsLength: 0,
};

SmartCartEmptyStateActions.propTypes = {
  edit: PropTypes.bool,
  continueShopping: PropTypes.bool,
  smartCartName: PropTypes.string,
  smartCartId: PropTypes.string,
  isCurrentUserSmartCart: PropTypes.bool,
  smartCartsLength: PropTypes.number,
  media: PropTypes.shape({
    minDesktop: PropTypes.bool,
  }).isRequired,
  direction: PropTypes.string.isRequired,
};

export default withDirection(withMedia(SmartCartEmptyStateActions));

import {
  graphql,
} from 'relay-runtime';


const ChangeUserPasswordMutation = graphql`
  mutation ChangeUserPasswordMutation($input: PasswordInfoQuery!) {
    update_user_password(input: $input) {
      errors {
        field
        messages
        code
      }
    }
  }
`;


export default ChangeUserPasswordMutation;

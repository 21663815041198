import React from 'react';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const Payment = () => (
  <Row>
    <img src={`/${imagesPathName}/Footer/visa.png`} alt="" />
    <Spacer size={1} />
    <img src={`/${imagesPathName}/Footer/master.png`} alt="" />
    <Spacer size={1} />
    <img src={`/${imagesPathName}/Footer/visa-s.png`} alt="" />
    <Spacer size={1} />
    <img src={`/${imagesPathName}/Footer/mastercard.png`} alt="" />
  </Row>
);

export default Payment;

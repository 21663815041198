import React from 'react';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import InputSelect from '~/modules/coreUI/components/basic/select/InputSelect';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';

class PhoneNumber extends React.Component {
  getSortedCountries = () => (!this.props.queryResult
    ? []
    : this.props.queryResult.list_countries
      .slice()
      .sort((c1, c2) => c1.name.localeCompare(c2.name)));

  getCountriesOptions = () => this.getSortedCountries().map(country => ({
    label: country.name,
    image: <Image src={country.flag} alt={country.flag} />,
    value: `+${country.phone_code}`,
    rightPulledLabel: `+${country.phone_code}`,
    attrs: {
      callingCodes: country.phone_code,
    },
  }));

  render = () => (
    <InputSelect
      showInput
      tabIndex={this.props.tabIndex}
      isLoading={this.props.isLoading}
      value={this.props.value}
      selectButtonMinWidth={this.props.selectButtonMinWidth}
      selectButtonRatio={this.props.selectButtonRatio}
      onChange={(countryCode, phone) => this.props.onChange(`(${countryCode}) ${phone}`)}
      showImageOnButton={false}
      getSelectedItemLabel={entry => `+${entry.attrs.callingCodes}`}
      getSelectedItemImage={entry => entry.image}
      placeholder={this.props.placeholder}
      options={this.getCountriesOptions()}
    />
  );
}

PhoneNumber.defaultProps = {
  value: '',
  isLoading: false,
  tabIndex: null,
  selectButtonRatio: null,
  selectButtonMinWidth: 120,
  placeholder: 'Phone Number',
  queryResult: {
    list_countries: [],
  },
};

PhoneNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  tabIndex: PropTypes.number,
  selectButtonMinWidth: PropTypes.number,
  placeholder: PropTypes.string,
  selectButtonRatio: PropTypes.number,
  queryResult: PropTypes.shape({
    list_countries: PropTypes.arrayOf(
      PropTypes.shape({
        iso3: PropTypes.string,
        name: PropTypes.string.isRequired,
        ref_id: PropTypes.string.isRequired,
        flag: PropTypes.string,
        phone_code: PropTypes.string.isRequired,
      }),
    ),
  }),
};

export default withRootQuery(
  PhoneNumber,
  graphql`
    query PhoneNumberQuery {
      list_countries {
        iso3
        name
        ref_id
        flag
        phone_code
      }
    }
  `,
  PhoneNumber,
);

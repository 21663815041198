/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import styled from 'react-emotion';
import { cssMediaMax, mediaSizesMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import SlickSlider from '~/modules/ecommerceCoreUI/components/slickSlider/SlickSlider';

const SliderWrapper = styled.div`
  background: #fff;
  .ui.container {
    ${cssMediaMax.tablet`
      margin: 0 !important;
      padding: 0;
    `};
  }
  .slick-slider {
    border: ${props => props.theme.borders.size.normal}px solid
      ${props => props.theme.borders.color.light};
    border-radius: ${props => props.theme.borders.radius.normal}px;
    height: 178px;
    ${props => cssMediaMax.tablet`
      border: none;
      background: ${props.theme.colors.backgroundColor};
    `} ${cssMediaMax.xsmall`
      height: 93px;
    `}
    .slick-list {
      max-height: 100%;
    }
    .slick-list,
    .slick-track {
      height: 100%;
    }
    .slick-slide {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
`;
const BrandsSlider = ({ children }) => {
  const settings = {
    infinite: children.length <= 6 ? false : true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: mediaSizesMax.tablet,
        settings: {
          slidesToShow: 4,
          infinite: children.length <= 4 ? false : true,
        },
      },
      {
        breakpoint: mediaSizesMax.xsmall,
        settings: {
          slidesToShow: 3,
          infinite: children.length <= 3 ? false : true,
        },
      },
    ],
  };
  return (
    <SliderWrapper>
      <Container>
        <SlickSlider settings={settings}>{children}</SlickSlider>
      </Container>
    </SliderWrapper>
  );
};

BrandsSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrandsSlider;

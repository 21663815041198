import { graphql } from 'react-relay';

export const SocialMediaSignInMutationRoot = 'socialmedia_signin';

export default graphql`
  mutation SocialMediaSignInMutation(
    $token: String!
    $secret: String
    $email: String
    $pid: String
    $provider: AvailableSocialMedia!
  ) {
    socialmedia_signin(
      token: $token
      secret: $secret
      email: $email
      pid: $pid
      provider: $provider
    ) {
      token
      client_id
      expiry
      found_email
      user {
        ...WithUserData_user @relay(mask: false)
      }
      errors {
        code
        field
        messages
      }
    }
  }
`;

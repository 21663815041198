import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NavProductsSearch from '~/modules/ecommerceCoreUI/components/header/headerActions/NavProductsSearch';

import HeaderMobile from './HeaderMobile';
import InnerMobileHeader from './InnerHeaderMobile';

export const InnerHeaderMobile = () => (
  <React.Fragment>
    <InnerMobileHeader />
    {/* // TODO : this should be moved to Header.js as part of Mobile Menu handling.  */}
  </React.Fragment>
);

export const HomePageHeader = () => (
  <React.Fragment>
    <HeaderMobile />
    {/* // TODO : this should be moved to Header.js as part of Mobile Menu handling.  */}
    <NavProductsSearch />
  </React.Fragment>
);

export const HeaderContent = () => (
  <Switch>
    <Route exact path="/CheckoutSteps" component={InnerHeaderMobile} />
    <Route component={HomePageHeader} />
  </Switch>
);

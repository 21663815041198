import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import { Link, withRouter } from 'react-router-dom';
import Media from 'react-media';

import {
  PanelContentMinorLabel,
  PanelContentSmallLabel,
} from '~/modules/accountManagement/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/legacies/Rows';
import Button from '~/modules/coreUI/components/basic/Button';
import ModalLink from '~/modules/core/components/Modal/ModalLink';
import { XSmallSpacer, LargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { RightAlignedColumn, Column } from '~/modules/coreUI/components/legacies/Columns';
import withUserInfo, { getUserInfoFromLoginResponse } from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import { mediaQueryMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import PopupLogo from '~/modules/accountManagement/components/basic/PopupLogo';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

import { getLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';

import LoginForm from './LoginForm';

const InputLayout = styled.div`
  width: 100%;
`;

const MediumLogo = styled(PopupLogo)`
  height: unset;
  width: unset;
  max-width: 80px;
`;

const PanelImage = () => (
  <Column grow centerAligned>
    <MediumLogo />
  </Column>
);

const CustomFormLayout = locals => (
  <InputLayout>
    <div>{locals.inputs.user_signin}</div>
    <div>{locals.inputs.password}</div>
    <Row spaceBetween topAligned fullWidth>
      <div>{locals.inputs.remember_me}</div>
      <PanelContentSmallLabel>
        <RightAlignedColumn>
          <XSmallSpacer />
          <ModalLink to="/accountManagement/recoverPassword">
            {i18next.t('accountManagement:Login.LostYourPassword')}
          </ModalLink>
        </RightAlignedColumn>
      </PanelContentSmallLabel>
    </Row>
  </InputLayout>
);

class LoginPanel extends React.Component {
  state = {
    panelError: getLocalMessageFor(PathRedirectKeysMapper.login) || null,
    isLoading: false,
  };

  onSuccess = (response) => {
    const { history, updateUserInfo } = this.props;

    if (!this.form || !response || !response.signin_user) {
      return;
    }

    const rememberMe = this.form.getValue().remember_me;

    updateUserInfo({
      ...getUserInfoFromLoginResponse(response.signin_user, rememberMe),
    });

    navigateToModal(history, '/accountManagement/showSuccess');
  };

  onError = (error) => {
    const { invalidateUser } = this.props;

    if (!this.form) {
      return;
    }

    this.setState({ panelError: error });

    if (error) {
      invalidateUser();
    }
  };

  setLoadingState = (isLoading) => {
    this.setState({ isLoading });
  };

  render = () => {
    const { panelContentContainer, panelContainer } = this.props;
    const { isLoading, panelError } = this.state;
    const ContentContainer = panelContentContainer;
    const PanelContainer = panelContainer;

    return (
      <PanelContainer
        titleLabel={i18next.t('accountManagement:Login.login')}
        subTitleLabel={i18next.t('accountManagement:Login.Getinanddescoveryourgift')}
        error={panelError}
      >
        <ContentContainer>
          <Media query={mediaQueryMax('tablet')}>
            {matched => matched && (
            <React.Fragment>
              <PanelImage />
              <Spacer size={3} />
            </React.Fragment>
            )
            }
          </Media>
          <LoginForm
            // FIXME : React sees the LoginForm as a stateless function and complalins when the
            //        Normal 'ref' is used
            onRef={(ref) => {
              this.form = ref;
            }}
            customLayout={CustomFormLayout}
            onFormError={error => this.onError(error)}
            onFormSuccess={response => this.onSuccess(response)}
            onFormLoading={loading => this.setLoadingState(loading)}
          />
          <Button lg block primary loading={isLoading} onClicked={() => this.form.submitForm()}>
            {i18next.t('accountManagement:Login.login')}
          </Button>
          <LargeSpacer />
          <PanelContentMinorLabel>
            <PanelContentSmallLabel>
              {i18next.t('accountManagement:Login.Byloginyouagreetoour')}
            </PanelContentSmallLabel>
            <Link to={urlFor('getStaticPageLink', ['terms_conditions'])}>
              {i18next.t('accountManagement:Login.termsconditions')}
            </Link>
          </PanelContentMinorLabel>
        </ContentContainer>
      </PanelContainer>
    );
  };
}

LoginPanel.propTypes = PropTypes.shape({
  panelContentContainer: PropTypes.element,
  userInfo: PropTypes.shape({}),
}).isRequired;

export default withRelayEnvironment(withUserInfo(withRouter(LoginPanel)));

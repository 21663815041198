import React, { Component } from 'react';
import { graphql } from 'react-relay';
import PulseLoader from 'react-spinners/PulseLoader';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import _ from 'lodash';

import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import withCartInfo from '~/modules/core/utils/orderManagementHelpers/withCartInfo';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import Icon from '~/modules/coreUI/components/basic/Icon';
import { getTokenFromLocalStorage } from '~/modules/core/utils/orderManagementHelpers/shoppingCartLocalStorageUtils';
import { notifyUnexpectedError } from '~/modules/ecommerceOrder/containers/shoppingCart/utils/ErrorsProcessor';

const DeleteButton = styled(Icon)`
  font-size: ${props => (props.isDropdown ? props.theme.fonts.sizes.xLarge : props.theme.new.fonts.sizes.xxl)}px;
  color: ${props => props.theme.colors.labels.subtle};
  cursor: pointer;
  ${props => cssMediaMax.mobile`
    font-size: ${props.theme.fonts.sizes.xLarge}px;
  `};
`;

const Mutation = graphql`
    mutation DeleteMutation(
      $vendor_variant_id: Int!,
      $smart_cart_id: String,
      $variant_service_ids: [String]
,      $guest_tokens: [String]
    ) {
      cart_remove_item(
        vendor_variant_id: $vendor_variant_id,
        smart_cart_id: $smart_cart_id,
        variant_service_ids: $variant_service_ids,
        guest_tokens: $guest_tokens
      ) {
        errors {
          code
          field
          messages
        }
        cart {
          ...WithCartData_cart @relay(mask: false)
        }
      }
    }
  `;

const MutationRoot = 'cart_remove_item';

class Delete extends Component {
  state = {
    loading: false,
  };

  onMutationError = (errors) => {
    this.setState({
      loading: false,
    });
    notifyUnexpectedError(this.props, errors);
  };

  onMutationSuccess = () => {
    this.setState({
      loading: false,
    });
    this.props.notifyAlert({
      component: () => (
        <Label inverted important semiBold>
          {i18next.t('ecommerceOrder:smartCart.deleteMessageOnSuccess')}
        </Label>
      ),
      type: AlertTypes.success,
      opacity: 1,
      toastID: 'Cart_success_toast',
    });
  };

  onMutationLoading = () => {
    this.setState({
      loading: true,
    });
  };

  deleteItem = () => {
    commitMutation(
      this.props.environment,
      Mutation,
      MutationRoot,
      {
        vendor_variant_id: parseInt(this.props.variantId, 10),
        smart_cart_id: this.props.smartCartId,
        variant_service_ids: this.getArrayOfVariantServices(),
        guest_tokens: this.props.smartCartId ? null : getTokenFromLocalStorage(),
      },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
  };


  getArrayOfVariantServices = () => {
    const variantServices = _.map(this.props.variantServices, element => element.ref_id);
    return variantServices;
  }

  render() {
    return (
      <Column>
        <DeleteButton
          isDropdown={this.props.isDropdown}
          className="el-icon el-icon-delete"
          onClick={this.deleteItem}
          title={i18next.t('ecommerceOrder:smartCart.deleteProductTooltip')}
        />
        {this.state.loading && <PulseLoader size={5} color="rgba(0,0,0,0.2)" />}
      </Column>
    );
  }
}

Delete.defaultProps = {
  isDropdown: false,
  smartCartId: null,
  variantServices: [],
};

Delete.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  variantId: PropTypes.string.isRequired,
  isDropdown: PropTypes.bool,
  smartCartId: PropTypes.string,
  notifyAlert: PropTypes.func.isRequired,
  variantServices: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withAlertMsg(withCartInfo(
  withRelayEnvironment(
    Delete,
  ),
));

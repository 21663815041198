/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DropdownProducts_queryResult$ref: FragmentReference;
export type DropdownProducts_queryResult = {|
  +variants_autocomplete_search: ?{|
    +result: ?$ReadOnlyArray<?{|
      +images: $ReadOnlyArray<?string>,
      +name: string,
      +display_price: ?string,
      +id: string,
      +slug: ?string,
      +vendor_variants_count: ?number,
      +min_variant: ?{|
        +vendor: ?{|
          +name: ?string,
          +ref_id: string,
        |}
      |},
    |}>,
    +page_info: ?{|
      +items_count: ?number
    |},
  |},
  +$refType: DropdownProducts_queryResult$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DropdownProducts_queryResult",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "input",
      "type": "VariantsSearchInput",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "variants_autocomplete_search",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input",
          "type": "VariantsSearchInput"
        }
      ],
      "concreteType": "VariantsFilterResult",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "result",
          "storageKey": null,
          "args": null,
          "concreteType": "Variant",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "images",
              "args": null,
              "storageKey": null
            },
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "display_price",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "slug",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "vendor_variants_count",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "min_variant",
              "storageKey": null,
              "args": null,
              "concreteType": "Variant",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "vendor",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Shop",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "ref_id",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "page_info",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "items_count",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '49c1d932f697cfb959dde09ba52151e8';
module.exports = node;

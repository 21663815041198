// eslint-disable-next-line
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Icon from '~/modules/coreUI/components/basic/Icon';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const getBorderColor = (props, forceFocusMode) => {
  if (forceFocusMode || props.actAsInFocus) {
    return props.theme.colors.primary;
  }

  return props.theme.inputs.borderColor;
};

const DropdownIconContainer = withDirection(styled(Column)`
  cursor: pointer;

  i {
    line-height: 1;
    font-size: 8px;

    color: ${props => getBorderColor(props)};
  }
`);

const Button = withDirection(styled.button`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${props => props.theme.paddings.xxxxxLarge + 4}px;

  align-items: center;

  font-size: ${props => props.fontSize || props.theme.inputs.fontSize}px;

  color: ${props => (props.label ? props.theme.inputs.color : props.theme.inputs.placeholderColor)};
  background-color: white;

  padding-left: ${props => props.theme.paddings.large}px;
  padding-right: ${props => props.theme.paddings.large}px;
  white-space: nowrap;

  text-align: left;
  text-overflow: ellipsis;

  outline: none;
  border: solid ${props => props.theme.inputs.borderWidth}px ${props => getBorderColor(props)};
  border-radius: ${props => props.theme.inputs.radius}px;

  ${props => (props.direction === 'rtl'
    ? css`
        border-top-left-radius: ${props.rightBorderRadius || props.theme.inputs.radius};
        border-bottom-left-radius: ${props.rightBorderRadius || props.theme.inputs.radius};
          `
    : css`
        border-top-right-radius: ${props.rightBorderRadius || props.theme.inputs.radius};
        border-bottom-right-radius: ${props.rightBorderRadius || props.theme.inputs.radius};
  `)};

  cursor: pointer;

  img {
    width: 20px;
    height: 20px;

    margin-top: 1px;
    ${props => (props.direction === 'rtl'
    ? css`
        margin-right: -2px;
        margin-left: 2px;
          `
    : css`
        margin-left: -2px;
        margin-right: 2px;
    `)};
    border-radius: 50%;
  }

  &:focus {
    border: solid ${props => props.theme.inputs.borderWidth}px
      ${props => getBorderColor(props, true)};
  }

  &:focus i {
    color: ${props => getBorderColor(props, true)};
  }
`);

const ButtonLoadingIcon = withDirection(styled(Row)`
  height: 100%;
`);

const ButtonContainer = styled(Row)`
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};
  min-width: ${props => props.minWidth};

  align-items: stretch;
`;

// eslint-disable-next-line
class CustomButton extends React.Component {
  focus = () => {
    this.buttonRef.focus();
  };

  render = () => {
    const {
      width,
      onMouseDown,
      rightBorderRadius,
      image,
      label,
      placeholder,
      dropIconDistanceFromRight,
      fontSize,
      actAsInFocus,
      onKeyDown,
      onFocus,
      visible,
      isLoading,
      tabIndex,
      minWidth,
    } = this.props;

    return (
      <ButtonContainer
        width={width}
        minWidth={minWidth}
        onMouseDown={e => (isLoading ? null : onMouseDown(e))}
        onKeyDown={onKeyDown}
        visible={visible}
      >
        <Button
          onFocus={onFocus}
          tabIndex={tabIndex}
          innerRef={(ref) => {
            this.buttonRef = ref;
          }}
          fontSize={fontSize}
          rightBorderRadius={rightBorderRadius}
          label={label}
          actAsInFocus={actAsInFocus}
        >
          <Row fullWidth spaceBetweenJustified>
            <Row>
              {image}
              <Spacer size={0.2} />
              {label || placeholder}
            </Row>
            <Row>
              {this.props.isLoading && (
              <ButtonLoadingIcon dropIconDistanceFromRight={dropIconDistanceFromRight}>
                <FontAwesomeIcon icon={faSpinner} spin />
              </ButtonLoadingIcon>
              )}
              <Spacer />
              <DropdownIconContainer
                dropIconDistanceFromRight={dropIconDistanceFromRight}
                actAsInFocus={actAsInFocus}
              >
                <Icon className="icon-down-open" />
              </DropdownIconContainer>
            </Row>
          </Row>
        </Button>
      </ButtonContainer>
    );
  };
}

CustomButton.defaultProps = {
  image: null,
  label: null,
  tabIndex: null,
  placeholder: 'Select',
  width: '100%',
  minWidth: null,
  dropIconDistanceFromRight: null,
  rightBorderRadius: null,
  fontSize: null,
  actAsInFocus: false,
  onFocus: null,
  visible: false,
  isLoading: false,
};
CustomButton.propTypes = {
  image: PropTypes.element,
  label: PropTypes.string,
  tabIndex: PropTypes.number,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  onMouseDown: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  rightBorderRadius: PropTypes.string,
  dropIconDistanceFromRight: PropTypes.number,
  fontSize: PropTypes.number,
  actAsInFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default CustomButton;

import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import RelayForm from '~/modules/coreUI/components/forms/RelayForm';

export const VerifyFormMutationRoot = 'forgot_password_verify_code';
export const VerifyFormMutation = graphql`
  mutation RecoverVerifyByEmailFormMutation($email: String!, $code: String!) {
    forgot_password_verify_code(email: $email, code: $code) {
      errors {
        field
        messages
        code
      }
      message
    }
  }
`;

class VerifyByEmailForm extends React.Component {
  getValue = () => this.form.getValue();

  submitForm = () => {
    this.form.submitForm();
  };

  render = () => {
    const {
      onFormError, onFormSuccess, onFormLoading, addiontalMutationVariables,
    } = this.props;

    return (
      <RelayForm
        onRef={(ref) => {
          this.form = ref;
        }}
        onFormError={onFormError}
        onFormSuccess={onFormSuccess}
        onFormLoading={onFormLoading}
        addiontalMutationVariables={addiontalMutationVariables}
        mutationRoot="forgot_password_verify_code"
        options={{
          fields: [
            {
              name: 'code',
              placeholder: i18next.t('accountManagement:verifyAccount.PlaceholderTxt'),
              displayName: i18next.t('accountManagement:verifyAccount.confirmationCodeText'),
              input_type: 'textbox',
              type: 'RequiredString',
            },
          ],
        }}
        mutation={VerifyFormMutation}
      />
    );
  };
}

VerifyByEmailForm.propTypes = PropTypes.shape({
  onFormError: PropTypes.func.isRequired,
  onFormSuccess: PropTypes.func.isRequired,
  onFormLoading: PropTypes.func.isRequired,
  addiontalMutationVariables: PropTypes.shape({}).isRequired,
}).isRequired;

export default VerifyByEmailForm;

/* eslint-disable import/prefer-default-export */
import i18next from 'i18next';

const trancateAfterDot = (number) => {
  const [int, [f]] = `${number}.`.split('.');
  return [int, f].filter(Boolean).join('.');
};

const NumberToDivide = num => (num < 999999 ? 1000 : 1000000);
const sign = num => (num < 999999 ? i18next.t('ecommerceOrder:Loyalty.k') : i18next.t('ecommerceOrder:Loyalty.m'));
const truncateZeroNumbers = (num) => {
  const Number = num / NumberToDivide(num);
  return Number % 1 < 0.1 ? Math.floor(Number) : trancateAfterDot(Number);
};

export const kFormatter = num => (num > 999 ? `${truncateZeroNumbers(num)} ${sign(num)}` : num);

export const kIntergerFormatter = num => (num > 999 ? `${(num / Math.floor(NumberToDivide(num)).toFixed(1))} ${sign(num)}` : num);

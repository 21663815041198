import React from 'react';
import PropTypes from 'prop-types';
import ItemCard from '~/modules/coreUI/components/itemCard';
import ItemsCardsSlider from '~/modules/ecommerceCoreUI/components/basic/ItemsCardsSlider';

const Discounts = ({ discounts }) => (
  <React.Fragment>
    {discounts && discounts.length > 3 && (
      <ItemsCardsSlider>
        {discounts.map(variantInfo => (
          <ItemCard
            key={variantInfo.id}
            itemInfo={variantInfo}
            isVertical
          />
        ))
       }
      </ItemsCardsSlider>
    )}
  </React.Fragment>
);

Discounts.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.any),
};

Discounts.defaultProps = {
  discounts: null,
};

export default Discounts;

const LoginTrans = {
  En: {
    login: 'Log In',
    password: 'Password',
    Getinanddescoveryourgift: 'Get in, and discover your daily gift',
    RememberMe: 'Remember Me',
    LostYourPassword: 'Forgot Password?',
    Login: 'Log In',
    Byloginyouagreetoour: 'By login, you agree to our ',
    termsconditions: ' Terms & Conditions',
    ofuse: ' Terms & Conditions',
    EmailMobileNumber: 'Email or Mobile Number',
    CreateAnAccount: 'Create New Account',
    JoinOurCommunity: 'Join our community',
    REGISTER: 'Sign Up',
    Login_Lorem: 'Be Online.. Shop Differently!',
    FillRequiredField: '* Required field',
    welcomeMessage: 'Welcome!',
    errorMessage: 'Something went wrong!',
    success: 'Success',
  },
  Ar: {
    login: 'تسجيل الدخول',
    password: 'كلمة المرور',
    Getinanddescoveryourgift: 'سجل دخولك، واكتشف هديتك اليومية',
    RememberMe: 'تذكرني',
    LostYourPassword: 'هل نسيت كلمة المرور؟',
    Login: 'تسجيل الدخول',
    Byloginyouagreetoour: 'بتسجيلك للدخول، أنت توافق على ',
    termsconditions: ' الشروط والأحكام',
    ofuse: ' الشروط والأحكام',
    EmailMobileNumber: 'البريد الإلكتروني أو رقم الجوال',
    CreateAnAccount: 'إنشاء حساب جديد',
    JoinOurCommunity: 'انضم إلى مجتمعنا',
    REGISTER: 'الاشتراك',
    Login_Lorem: ' كن أونلاين.. تسوق بشكل مختلف!',
    FillRequiredField: '* حقل مطلوب',
    welcomeMessage: 'مرحبًا!',
    errorMessage: 'حدث خطأ ما!',
    success: 'تم الامر بنجاح',
  },
};
export default LoginTrans;

import React from 'react';
import HorizontalDesktopLayout from './HorizontalDesktopLayout';
import VerticalDesktopLayout from './VerticalDesktopLayout';
import HorizontalXsmallLayout from './HorizontalXsmallLayout';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const RenderHorizontalCard = props => (
  <React.Fragment>
    {props.media.xsmall ? (
      <HorizontalXsmallLayout {...props} />
    ) : (
      <HorizontalDesktopLayout {...props} />
    )}
  </React.Fragment>
);
export default withMedia(props => (
  <React.Fragment>
    {!props.isVertical ? (
      RenderHorizontalCard(props)
    ) : (
      <VerticalDesktopLayout {...props} />
    )}
  </React.Fragment>
));

const forgetEmailVerify = {
  En: {
    EmailParagraph: ' The verification code has been sent to your email, check your inbox',
    Button: 'Reset Your Password',
    Footer: 'Copyright © 2021, Ayk for eCommerce LLC. All Rights Reserved.',
    Resendit: 'Resend It',
    Didreceivethecodeyet: ' Didn\'t receive the code yet?',
    placeholderTxt: 'Add the code here',
    SendSuccessfully: 'Sent Successfully',
    Select: 'Select',
    confirmationCodeText: 'Verification Code',
    EmailSecondTitle: 'Email Sent',
    SMSSecondTitle: 'SMS Sent',
    SMSParagraph: ' The verification code has been sent to your mobile, check your messages',
  },
  Ar: {
    EmailParagraph: ' تم إرسال رمز التحقق إلى بريدك الإلكتروني، يرجى مراجعة البريد الوارد',
    Button: 'إعادة ضبط كلمة المرور',
    Footer: 'حقوق النشر © ٢٠٢١، أيك للتجارة الإلكترونية ذ.م.م. جميع الحقوق محفوظة.',
    Resendit: 'أرسله مرة أخرى',
    Didreceivethecodeyet: ' لم تستلم الرمز بعد؟',
    placeholderTxt: 'أضف الرمز هنا',
    SendSuccessfully: 'تم الإرسال بنجاح',
    Select: 'اختر',
    confirmationCodeText: 'شيفرة التأكيد',
    EmailSecondTitle: 'تم الإرسال',
    SMSSecondTitle: 'تم الإرسال',
    SMSParagraph: ' تم إرسال رمز التحقق إلى هاتفك الجوال، يرجى مراجعة الرسائل',
  },
};
export default forgetEmailVerify;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import ItemCard from '~/modules/coreUI/components/itemCard';
import ItemsCardsSlider from '~/modules/ecommerceCoreUI/components/basic/ItemsCardsSlider';

const Offers = ({ offers }) => (
  <React.Fragment>
    {offers && offers.length > 3 && (
      <ItemsCardsSlider>
        {offers.map(variantInfo => (
          <ItemCard
            key={variantInfo.id}
            isVertical
            itemInfo={variantInfo}
          />
        ))
       }
      </ItemsCardsSlider>
    )}
  </React.Fragment>
);

Offers.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.any),
};

Offers.defaultProps = {
  offers: null,
};

export default withRootQuery(
  Offers,
  graphql`
    query OffersQuery {
      variants_search(input: { offer: true }) {
        result {
          ...VariantData_variantInfo @relay(mask: false)
        }
      }
    }
  `,
);

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePageHeader from './Header';
import InnerPagesHeader from './InnerHeader';

export const Header = () => <HomePageHeader />;

export const InnerHeader = () => <InnerPagesHeader />;

export const HeaderContent = () => (
  <Switch>
    <Route exact path="/:locale(ar|en)?/CheckoutSteps/:stepName" component={InnerHeader} />
    <Route component={Header} />
  </Switch>
);

function isObjectDeepEqual(o1, o2) {
  return JSON.stringify(o1) === JSON.stringify(o2);
}

function objectsDeepNotEqualComparison(o1, o2) {
  return !isObjectDeepEqual(o1, o2);
}

export { isObjectDeepEqual };
export default objectsDeepNotEqualComparison;

const CartTrans = {
  En: {
    title: 'Shopping Cart',
    quantity: 'You have <e>{{quantity}} items</e> in your cart',
    loading: 'Loading your cart...',
    cartUpdated: 'Your shopping cart has been updated, Please review before proceeding to checkout',
    emptyHeader: 'Your cart is empty!',
    emptyContent: 'What are you waiting for?',
    continueShopping: 'Start Shopping',
    view: 'View Your Cart',
    checkout: 'Checkout',
    proceedCheckout: 'Proceed to Checkout',
    total: 'Total Amount',
    Items: {
      totalPrice: 'Total Price',
      quantity: 'Quantity',
      price: 'Price',
      product: 'Product',
    },
    addToCart: 'Add to Cart',
    buyNow: 'Buy Now',
    addToSmart: 'Add to Smart Cart',
    shipping: 'Shipping',
    selectMethod: 'Select Method',
    changeMethod: 'Change Method',
    securedCheckout: 'Secured Checkout',
    items: 'Items',
    verifyButton: 'Verify now',
    verifyLabel: 'You should verify you account ',
  },
  Ar: {
    title: 'عربة التسوق',
    quantity: 'لديك <e>{{quantity}} عناصر</e> في عربة تسوقك',
    loading: 'تحميل عربة تسوقك...',
    cartUpdated: 'تم تحديث عربة تسوقك، يرجى المراجعة قبل متابعة خطوات الدفع',
    emptyHeader: 'عربة تسوقك فارغة!',
    emptyContent: 'ماذا تنتظر؟',
    continueShopping: 'ابدأ التسوق',
    view: 'عرض عربة تسوقك',
    checkout: 'الدفع',
    proceedCheckout: 'التوجه نحو الدفع',
    total: 'المبلغ الإجمالي',
    Items: {
      totalPrice: 'السعر الإجمالي',
      quantity: 'الكمية',
      price: 'السعر',
      product: 'المنتج',
    },
    addToCart: 'أضف إلى العربة',
    buyNow: 'اشتر الآن',
    addToSmart: 'أضف إلى العربة الذكية',
    shipping: 'الشحن',
    selectMethod: 'اختر الطريقة',
    changeMethod: 'تغيير الطريقة',
    securedCheckout: 'دفع آمن',
    items: 'عناصر',
    verifyButton: 'تحقق الآن',
    verifyLabel: 'يجب عليك التحقق من حسابك ',
  },
};
export default CartTrans;

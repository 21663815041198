const loyaltyTrans = {
  En: {
    silverMembership: 'Silver Membership',
    goldMembership: 'Gold Membership',
    diamondMembership: 'Diamond Membership',
    silver: 'Silver',
    gold: 'Gold',
    diamond: 'Diamond',
    aykMembership: 'Ayk Mall Membership',
    earnPoints: 'Earn Points',
    learnPoints: 'Learn How to earn Ayk Points',
    forAyk: 'From Ayk Mall',
    forPaid: 'For each 1 QAR Paid',
    forRateReview: 'For Rate/Review',
    forPurchased: 'For purchased products or shops purchased from it.',
    forFriend: 'For Friend Invitation',
    inviteFriends: 'Invite Your Friends',
    getPromo: 'And get promo',
    forShare: 'For Share',
    onSocialMedia: 'On Social media of',
    point: 'Point',
    points: 'Points',
    gifts: 'Gifts',
    pointsLeftGold: 'points left for the Gold Membership',
    pointsLeftDiamond: 'points left for the Diamond Membership',
    pointsEarned: 'Points Earned',
    pointsLeftGift: 'points left for the next gift.',
    k: 'k',
    m: 'm',
    view: 'view',
    giftsNumber: 'you Have {{giftsNumber}} promos from Ayk Mall',
    shopsMembership: 'Shops Membership',
    or: 'or',
  },
  Ar: {
    silverMembership: 'عضوية فضية',
    goldMembership: 'عضوية ذهبية',
    diamondMembership: 'عضوية ماسية',
    silver: 'فضية',
    gold: 'ذهبية',
    diamond: 'ماسية',
    aykMembership: 'عضوية مول ايك',
    earnPoints: 'اكتساب النقط',
    learnPoints: 'تعلم كيفية اكتساب النقط',
    forAyk: 'لاجل مول ايك',
    forPaid: 'لكل ريال قطري مدفوع',
    forRateReview: 'لكل تقييم/مراجعة',
    forPurchased: 'لشراء منتج او الشراء من محل',
    forFriend: 'لكل دعوة صديق',
    inviteFriends: 'ادعى اصدقائك',
    getPromo: 'احصل على كوبون',
    forShare: 'للمشاركة',
    onSocialMedia: 'على مواقع التواصل الاجتماعى',
    point: 'نقطة',
    points: 'نقاط',
    gifts: 'هدايا',
    pointsLeftGold: 'نقاط متبقية للعضوية الذهبية',
    pointsLeftDiamond: 'نقاط متبقية للعضوية الماسية',
    pointsEarned: 'نقط تم ربحها',
    pointsLeftGift: 'نقط متبقية للهدية القادمة',
    k: 'الف',
    m: 'مليون',
    view: 'المزيد',
    giftsNumber: 'انت تملك {{giftsNumber}} هدايا من مول ايك',
    shopsMembership: 'عضوية المحلات',
    or: 'او',
  },
};
export default loyaltyTrans;

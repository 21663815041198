import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';

import { Column, Row, Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Button from '~/modules/coreUI/components/basic/Button';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import ForceMediaProvider from '~/modules/core/utils/mediaHelpers/ForceMediaProvider';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';

import ProceedToCheckoutButton from './areas/ProceedToCheckoutButton';
import CartTotal from './areas/CartTotal';

const ViewYourCartButton = () => (
  <ExternalLink url={urlFor('getCartLink')}>
    <Button block lg primary>
      {i18next.t('ecommerceOrder:Cart.view')}
    </Button>
  </ExternalLink>
);

const ScrollableContent = styled.div`
  max-height: ${props => 49 * props.theme.new.spacer}px;
  overflow: auto;
`;
const ActionButtons = props => (
  <Row fullWidth spaceBetween={2} {...props}>
    <ViewYourCartButton />
    <ProceedToCheckoutButton fullWidth primary isDropdown />
  </Row>
);

const LayoutMobile = (props) => {
  if (!props.cartItemsTable && !props.emptyStatePage) {
    return null;
  }
  return (
    <div>
      <ScrollableContent>
        <Column stretchAligned width="395px" padding="25px" paddingBottom="0">
          {props.header}

          {props.emptyStatePage ? (
            <React.Fragment>
              <Spacer size={4} />

              <ForceMediaProvider forceMediaQuery="xsmall">
                {props.emptyStatePage}
              </ForceMediaProvider>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {React.cloneElement(props.cartItemsTable, { isDropdown: true })}
            </React.Fragment>
          )}
        </Column>
      </ScrollableContent>
      {props.emptyStatePage ? (
        <Box pt={1.5} pl={2.5} pr={2.5} pb={2.5} fullWidth stretchAligned>
          {props.continueShoppingButton}
        </Box>
      ) : (
        <Box pt={1.5} pl={2.5} pr={2.5} pb={2.5} spaceBetween={1.5}>
          {
            props.dataLoaded
              ? (
                <CartTotal cartTotal={props.cartTotal} />
              ) : null
          }
          {
            props.dataLoaded
              ? (
                <ActionButtons {...props} />
              ) : null
          }
        </Box>
      )}
    </div>
  );
};

LayoutMobile.propTypes = {
  cartItemsTable: PropTypes.objectOf(PropTypes.any),
  header: PropTypes.element.isRequired,
  emptyStatePage: PropTypes.element,
  continueShoppingButton: PropTypes.element.isRequired,
  cartTotal: PropTypes.number.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
};
LayoutMobile.defaultProps = {
  cartItemsTable: null,
  emptyStatePage: null,
};
export default LayoutMobile;

/* eslint-disable indent */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { translate } from 'react-i18next';
import styled, { css } from 'react-emotion';
import { withRouter } from 'react-router-dom';

import Dropdown from '~/modules/coreUI/components/legacies/Dropdown';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import changeLanguage from '~/modules/ecommerceCore/utils/changeLanguage';

import {
  SimpleDropdownTriggerStyles,
  StyledHeaderDropdownContainer,
} from '../CustomHeaderComponentsStyles';

const options = [
  {
    text: 'EN',
    value: 'en',
    image: { src: `/${imagesPathName}/Header/uk.svg` },
  },
  {
    text: 'ع',
    value: 'ar',
    image: { src: `/${imagesPathName}/Header/ar.png` },
  },
];
const DropdownLanguageItems = withDirection(styled.div`
  span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }
  i.icon {
    font-size: 5px;
    display: inline-block;
    margin: 0 5px;
    width: auto;
    height: auto;
  }
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle !important;
  }
  .ui.dropdown .menu>.item {
    padding: 8px 3px !important;
    border-bottom: 1px solid #ebebeb;
    font-size: 11px !important;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: transparent !important;
    }
    ${props => (props.direction === 'rtl'
    && css`
        text-align: right;
      `
  )}
    >img, >.image {
      ${props => (props.direction === 'rtl'
      ? css`
          margin-left: 8px !important;
          margin-right: 0 !important;
        `
      : css`
        margin-right: 8px !important;
    `)}
    }
  }
  .visible.menu.transition {
    padding: 8px;
    width: 91px;
    >div {
      display: flex;
      align-items: center;
    }
    .active.item {
      span {
        color: ${props => props.theme.colors.primary} !important;
      }
      position: relative;
      background-color: transparent !important;
      font-weight: 300 !important;
      &:after {
        content: '\\F058';
        font-family: Icons, sans-serif;
        position: absolute;
        ${props => (props.direction === 'rtl'
          ? css`
              left: 0;
            `
          : css`
            right: 0;
        `)}
        color: ${props => props.theme.colors.primary};
        font-size: ${props => props.theme.fonts.sizes.small}px;
      }
    }
  }
  .ui.top.right.pointing.dropdown > .menu {
    right: -17px;
    &:after {
      right: 38px !important;
    }
  }
`);

class NavLanguageDesktop extends Component {
  // eslint-disable-next-line
  state = {
    currentLanguage: this.props.i18n.language,
  };

  componentDidMount = () => {
    // eslint-disable-next-line
    this.setState({ currentLanguage: this.props.i18n.language });
  };

  onChange = (value) => {
    changeLanguage(
      value,
      this.props.history.location,
    );
  };

  render = () => {
    const { currentLanguage } = this.state;

    return (
      <StyledHeaderDropdownContainer>
        <DropdownLanguageItems>
          <Dropdown
            options={options}
            pointing="top right"
            selectedValue={currentLanguage}
            customTriggerLabelStyles={SimpleDropdownTriggerStyles}
            onChange={this.onChange}
          />
        </DropdownLanguageItems>
      </StyledHeaderDropdownContainer>
    );
  };
}

NavLanguageDesktop.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({}),
  }).isRequired,
};

export default translate('ecommerceCoreUI')(withRouter(NavLanguageDesktop));

/* eslint-disable react/prop-types, camelcase */
import React from 'react';
import i18next from 'i18next';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const FormLabel = props => (
  <Row pl={1.5} pb={0.3} fullWidth>
    <Label sm important>{props.children}</Label>
    {props.required && (
      <Label sm error>*</Label>
    )}
  </Row>
);

export default userInfo => ([
  {
    name: 'country',
    label: <FormLabel>{i18next.t('accountManagement:Profile.country_name')}</FormLabel>,
    placeholder: i18next.t('accountManagement:Profile.country_name'),
    displayName: i18next.t('accountManagement:Profile.country_name'),
    input_type: 'country',
    type: 'OptionalCountry',
  },
  {
    name: 'default_lang',
    label: <FormLabel>{i18next.t('accountManagement:Profile.language')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.language'),
    placeholder: i18next.t('accountManagement:Profile.language'),
    input_type: 'dropDown',
    options: userInfo?.supported_languages?.map(language => ({
      label: i18next.t(`accountManagement:Profile.languages.${language}`),
      value: language,
    })),
    type: 'OptionalString',
  },
  {
    name: 'default_currency',
    label: <FormLabel>{i18next.t('accountManagement:Profile.currency')}</FormLabel>,
    displayName: i18next.t('accountManagement:Profile.currency'),
    placeholder: i18next.t('accountManagement:Profile.currency'),
    input_type: 'dropDown',
    options: userInfo?.supported_currencies?.map(currencyID => ({
      label: currencyID,
      value: currencyID,
    })),
    type: 'OptionalString',
  },
  {
    name: 'newsletter_subscription',
    label: <FormLabel>{i18next.t('accountManagement:Profile.newsletterSubscription')}</FormLabel>,
    input_type: 'checkbox',
    type: 'Boolean',
    semiBoldLabel: true,
    showAsToggle: true,
  },
  {
    name: 'receive_push_notifications',
    label: <FormLabel>{i18next.t('accountManagement:Profile.receivePushNotifications')}</FormLabel>,
    input_type: 'checkbox',
    type: 'Boolean',
    semiBoldLabel: true,
    showAsToggle: true,
  },
]);

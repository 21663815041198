import React from 'react';
import styled from 'react-emotion';
import i18next from 'i18next';
import Icon from '~/modules/coreUI/components/basic/Icon';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { Label } from '~/modules/coreUI/components/basic/Labels';

export const CheckoutLabel = styled(Label)`
  color: ${props => props.theme.colors.labels.emphasized};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

const SecurityIcon = styled(Icon)`
  color: ${props => props.theme.colors.labels.normal};
`;

const SecuredCheckout = () => (
  <React.Fragment>
    <SecurityIcon size={['lg', 'lg', 'xxl', 'xxl']} className="icon-checkout-security" />
    <Spacer size={[0.5, 1]} />
    <CheckoutLabel size={['xs', 'xs', 'lg', 'lg']}>{i18next.t('ecommerceOrder:Cart.securedCheckout')}</CheckoutLabel>
  </React.Fragment>
);

export default withMedia(SecuredCheckout);

import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

const AccountItems = [
  {
    title: 'ecommerceCoreUI:Tabs.Profile',
    url: urlFor('accountSettings', ['profile']),
    icon: 'icon-profile',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Orders',
    url: urlFor('accountSettings', ['my-orders']),
    icon: 'icon-list',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Refunds',
    url: urlFor('accountSettings', ['my-refunds']),
    icon: 'el-icon el-icon-refund',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.ShoppingCart',
    url: urlFor('getCartLink'),
    icon: 'icon-shop',
    guestView: true,
    guestAccessible: true,
  },
  {
    title: 'ecommerceCoreUI:Tabs.RecentlyViewed',
    url: urlFor('accountSettings', ['recent-viewed']),
    icon: 'el-icon el-icon-view',
    guestView: true,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Notifications',
    url: urlFor('accountSettings', ['notificationsAndMessages', 'notifications']),
    icon: 'icon-bell',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Wishlist',
    url: urlFor('accountSettings', ['wishlist']),
    icon: 'el-icon el-icon-heart',
    guestView: true,
  },
  {
    title: 'ecommerceCoreUI:Tabs.SmartCart',
    url: urlFor('accountSettings', ['smartcart']),
    icon: 'icon-smart-cart',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Following',
    url: urlFor('accountSettings', ['following']),
    icon: 'icon-follow-shop',
    guestView: true,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Loyalty',
    url: urlFor('accountSettings', ['loyalty']),
    icon: 'el-icon el-icon-loyalty',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Coupons',
    url: urlFor('accountSettings', ['coupons']),
    icon: 'el-icon el-icon-promo',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Settings',
    url: urlFor('accountSettings', ['settings']),
    icon: 'icon-setting',
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.Invite',
    icon: 'icon-invite',
    url: urlFor('accountSettings', ['invite']),
    guestView: false,
  },
  {
    title: 'ecommerceCoreUI:Tabs.CustomerService',
    icon: 'icon-customer',
    url: urlFor('getStaticPageLink', ['support']),
    guestView: true,
    guestAccessible: true,
  },
];

export default AccountItems;

/**
 * @flow
 * @relayHash 1c7dcee90d7cc6d7132fbbf224e32584
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DropdownProducts_queryResult$ref = any;
export type ProductSort = "a_to_z" | "best_selling" | "discount" | "highest_rating" | "most_popular" | "nearest" | "new_arrivals" | "price_asc" | "price_desc" | "z_to_a" | "%future added value";
export type ProductType = "good" | "offer" | "package_service" | "single_service" | "%future added value";
export type VariantsSearchInput = {
  freetext_search?: ?string,
  sort?: ?ProductSort,
  product_type?: ?ProductType,
  coordinates?: ?LocationCoordinatesInput,
  filter?: ?$ReadOnlyArray<?ProductFilterInput>,
  page?: ?PageInfoInput,
  category_ids?: ?$ReadOnlyArray<?string>,
  department_ids?: ?$ReadOnlyArray<?string>,
  brand_ids?: ?$ReadOnlyArray<?string>,
  vendor_ids?: ?$ReadOnlyArray<?string>,
  exclude_variants?: ?$ReadOnlyArray<?string>,
  offer?: ?boolean,
  hot_deal?: ?boolean,
  discount?: ?boolean,
  filter_options?: ?boolean,
};
export type LocationCoordinatesInput = {
  latitude?: ?number,
  longitude?: ?number,
};
export type ProductFilterInput = {
  name: string,
  values?: ?$ReadOnlyArray<?string>,
};
export type PageInfoInput = {
  limit?: ?number,
  offset?: ?number,
};
export type DropdownProductsQueryVariables = {|
  input?: ?VariantsSearchInput
|};
export type DropdownProductsQueryResponse = {|
  +$fragmentRefs: DropdownProducts_queryResult$ref
|};
export type DropdownProductsQuery = {|
  variables: DropdownProductsQueryVariables,
  response: DropdownProductsQueryResponse,
|};
*/


/*
query DropdownProductsQuery(
  $input: VariantsSearchInput
) {
  ...DropdownProducts_queryResult_2VV6jB
}

fragment DropdownProducts_queryResult_2VV6jB on Query {
  variants_autocomplete_search(input: $input) {
    result {
      images
      name
      display_price
      id
      slug
      vendor_variants_count
      min_variant {
        vendor {
          name
          ref_id
          id
        }
        id
      }
    }
    page_info {
      items_count
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "VariantsSearchInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DropdownProductsQuery",
  "id": null,
  "text": "query DropdownProductsQuery(\n  $input: VariantsSearchInput\n) {\n  ...DropdownProducts_queryResult_2VV6jB\n}\n\nfragment DropdownProducts_queryResult_2VV6jB on Query {\n  variants_autocomplete_search(input: $input) {\n    result {\n      images\n      name\n      display_price\n      id\n      slug\n      vendor_variants_count\n      min_variant {\n        vendor {\n          name\n          ref_id\n          id\n        }\n        id\n      }\n    }\n    page_info {\n      items_count\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DropdownProductsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "DropdownProducts_queryResult",
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DropdownProductsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "variants_autocomplete_search",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input",
            "type": "VariantsSearchInput"
          }
        ],
        "concreteType": "VariantsFilterResult",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Variant",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "images",
                "args": null,
                "storageKey": null
              },
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "display_price",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "vendor_variants_count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "min_variant",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "vendor",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Shop",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ref_id",
                        "args": null,
                        "storageKey": null
                      },
                      v2
                    ]
                  },
                  v2
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "page_info",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "items_count",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '41768c2073c0efcd1e944ac731f98bc7';
module.exports = node;

import React from 'react';
import styled, { css } from 'react-emotion';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const ArrowWrapper = withDirection(styled(Row)`
  position: absolute;
  top: 0;
  ${props => (props.direction === 'rtl'
    ? css`
        right: -${3.8 * props.theme.new.spacer}px;
        transform: scale(-1);
      `
    : css`
        left: -${3.8 * props.theme.new.spacer}px;
  `)}
  height: 100%;
  z-index: 1;
`);

const WheelArrow = () => (
  <ArrowWrapper>
    <img src={`/${imagesPathName}/wheel-arrow.png`} alt="arrow wheel" />
  </ArrowWrapper>
);

export default WheelArrow;

/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'react-emotion';
import { withTheme } from 'emotion-theming';

import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Button from '~/modules/coreUI/components/basic/Button';

export const ItemOptions = styled(Row)`
  flex-wrap: wrap;

  margin-bottom: ${props => -0.5 * props.theme.new.spacer}px;
  & > * {
    margin-bottom: ${props => 0.5 * props.theme.new.spacer}px;
  }
`;

export const OptionName = styled.span`
  max-width: ${props => 14 * props.theme.new.spacer}px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemOption = withTheme(props => (
  <Button
    disabled
    fullRound
    tight
    pxRatio={3}
    colors={{
      lineColor: props.theme.new.colors.labels.normal.normal,
      borderColor: props.theme.borders.color.light,
      backgroundColor: props.theme.colors.named.white,
    }}
    size={props.isDropdown ? 'xs' : ['xs', 'xs', 'sm']}
  >
    <OptionName>{props.children}</OptionName>
  </Button>
));

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import {
  Row,
  Column,
  Box,
} from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const CardContainer = withDirection(styled(Row)`
  height: ${props => 43 * props.theme.new.spacer}px;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  &:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1) !important;
  }
  ${props => (props.direction === 'rtl'
    && css`
    direction: rtl;
  `)}
`);

const OFFER_TAG_LINE_HEIGHT = '46px';

const VerticalDesktopLayout = ({
  imageRenderer,
  title,
  showBorder,
  tag,
  rating,
  offerLabel,
  originalPrice,
  actions,
  currentPrice,
  subTitle,
  location,
}) => (
  <CardContainer stretchAligned bordered={showBorder} thin light padding={1.5} spaceBetween={1}>
    <Column leftAligned fullWidth>
      <Row fullWidth spaceBetweenJustified topAligned height={OFFER_TAG_LINE_HEIGHT}>
        {tag}
        <Spacer grow />
        <Column height={OFFER_TAG_LINE_HEIGHT}>
          {actions}
        </Column>
      </Row>
      <Box topAligned fullWidth>
        {imageRenderer}
      </Box>
      <Spacer />
      <Column spaceBetween={0.5} topAligned grow>
        <Row topAligned>
          {title}
        </Row>
        {subTitle}
        {location}
      </Column>
      <Spacer />
      <Row spaceBetweenJustified bottomAligned fullWidth>
        <Column leftAligned spaceBetween={0.5}>
          {rating}
          {currentPrice}
          {originalPrice}
        </Column>
        <Spacer grow />
        {offerLabel}
      </Row>
    </Column>
  </CardContainer>
);
VerticalDesktopLayout.propTypes = {
  actions: PropTypes.element.isRequired,
  tag: PropTypes.element,
  offerLabel: PropTypes.element.isRequired,
  rating: PropTypes.element.isRequired,
  imageRenderer: PropTypes.element.isRequired,
  title: PropTypes.element.isRequired,
  originalPrice: PropTypes.element.isRequired,
  currentPrice: PropTypes.element.isRequired,
  showBorder: PropTypes.bool,
  subTitle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any),
  ]),
  location: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any),
  ]),
};

VerticalDesktopLayout.defaultProps = {
  showBorder: true,
  tag: null,
  subTitle: null,
  location: null,
};
export default VerticalDesktopLayout;

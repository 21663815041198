import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';

import EmptyStatePage from '~/modules/coreUI/components/layouts/emptyStatePage';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';

const ErrorPage = ({ errorId }) => (
  <Column spaceBetween={1.5}>
    <Spacer size={2.5} />
    <Label semiBold important xxl>
      {i18next.t('ecommerceCoreUI:WrongPages.oops')}
    </Label>
    <Label lg>
      {i18next.t('ecommerceCoreUI:WrongPages.serverErrorText')}
    </Label>
    <EmptyStatePage
      imagePath={`/${imagesPathName}/robot.png`}
    />
    <Label lg>
      {i18next.t('ecommerceCoreUI:WrongPages.errorCodeText')}
    </Label>
    <Label semiBold important lg>
      {errorId}
    </Label>
  </Column>
);

ErrorPage.propTypes = {
  errorId: PropTypes.string.isRequired,
};

export default withRouter(ErrorPage);

import React from 'react';
import styled from 'react-emotion';
import { translate } from 'react-i18next';

import ModalRoute from '~/modules/core/components/Modal/ModalRoute';
import { Column } from '~/modules/coreUI/components/legacies/Columns';
import Modal, { ModalContent } from '~/modules/core/components/Modal/index';
import { cssMediaMin, cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

import Login from '~/modules/accountManagement/containers/panels/login/LoginContainer';
import SuccessPanel from '~/modules/accountManagement/containers/panels/SuccessPanel';
import SocialMedia from '~/modules/accountManagement/containers/panels/socialMedia/SocialMedia';

import Signup from '~/modules/accountManagement/containers/panels/signup/SignupPanel';
import RecoverPassword from '~/modules/accountManagement/containers/panels/forgetPassword/recoverPassword/RecoverPassword';
import CreateNewPassword from '~/modules/accountManagement/containers/panels/forgetPassword/createNewPassword/CreateNewPassword';

import VerifyAccountByEmailOrSms from '~/modules/accountManagement/containers/panels/verifyAccount/VerifyAccountEmailOrSMSPanel';

import VerifyAccountByEmail from '~/modules/accountManagement/containers/panels/verifyAccount/VerifyByEmail';
import VerifyAccountBySMS from '~/modules/accountManagement/containers/panels/verifyAccount/VerifyBySMS';
import VerifyRecoverByEmail from '~/modules/accountManagement/containers/panels/forgetPassword/verifyByEmail/RecoverVerifyByEmail';

import ModalLoginFooter from '~/modules/accountManagement/components/footers/ModalLoginFooter';

import SocialMediaLanding from '~/modules/accountManagement/containers/panels/socialMedia/SocialMediaLanding';
import ConnectSocialMedia from '~/modules/accountManagement/containers/panels/socialMedia/ConnectSocialMedia';
import TriggerTwitterLogin from '~/modules/accountManagement/containers/panels/socialMedia/TriggerTwitterLogin';
import TwitterAuth from '~/modules/accountManagement/containers/panels/socialMedia/TwitterAuth';

const PanelsContainer = styled(Column)`
  justify-content: stretch;
  align-items: center;

  ${cssMediaMax.tablet`
    padding-top: 20px;
    padding-bottom: 30px;
  `};

  ${cssMediaMin.desktop`
    padding: 40px;
    padding-top: 0px;
  `}

  flex-grow: 1;
`;

const PanelsFooter = styled(Column)`
  justify-self: flex-end;
`;

const AccountManagementModals = () => (
  <Modal>
    <ModalContent modalHasMinHeight>
      <PanelsContainer>
        <ModalRoute exact path="/accountManagement/loginWithSocialMedia" component={SocialMedia} />
        <ModalRoute exact path="/accountManagement/socialMediaLanding" component={SocialMediaLanding} />
        <ModalRoute exact path="/accountManagement/connectSocialMedia" component={ConnectSocialMedia} />
        <ModalRoute exact path="/accountManagement/triggerTwitterLogin" component={TriggerTwitterLogin} />
        <ModalRoute exact path="/accountManagement/callback/:oauth_token/:oauth_verifier" component={TwitterAuth} />

        <ModalRoute exact path="/accountManagement/login" component={Login} />
        <ModalRoute exact path="/accountManagement/showSuccess" component={SuccessPanel} />

        <ModalRoute exact path="/accountManagement/signup" component={Signup} />

        <ModalRoute exact path="/accountManagement/recoverPassword" component={RecoverPassword} />
        <ModalRoute exact path="/accountManagement/recoverpassword/VerifyByEmail" component={VerifyRecoverByEmail} />
        <ModalRoute exact path="/accountManagement/recoverpassword/CreateNewPassword" component={CreateNewPassword} />

        <ModalRoute exact path="/accountManagement/VerifyAccountEmailOrSms" component={VerifyAccountByEmailOrSms} />
        <ModalRoute exact path="/accountManagement/VerifyByEmail" component={VerifyAccountByEmail} />
        <ModalRoute exact path="/accountManagement/VerifyBySMS" component={VerifyAccountBySMS} />
      </PanelsContainer>

      <PanelsFooter>
        <ModalRoute exact path="/accountManagement/login" component={SocialMedia} />

        <ModalRoute exact path="/accountManagement/signup" component={SocialMedia} />
        <ModalRoute exact path="/accountManagement/signup" component={ModalLoginFooter} />

        <ModalRoute exact path="/accountManagement/recoverPassword" component={ModalLoginFooter} />

        <ModalRoute exact path="/accountManagement/recoverpassword/VerifyByEmail" component={ModalLoginFooter} />
        <ModalRoute exact path="/accountManagement/recoverpassword/VerifyAccountBySMS" component={ModalLoginFooter} />

        <ModalRoute exact path="/accountManagement/recoverpassword/CreateNewPassword" component={ModalLoginFooter} />
      </PanelsFooter>
    </ModalContent>
  </Modal>
);

export default translate('accountManagement')(AccountManagementModals);

import { css } from 'emotion';

export const tabListStyle = props => css`
  min-width: 20%;
  height: fit-content;
  padding: 0px;
  margin-top: 0;
  background-color: ${props.theme.colors.named.white};
  border-radius: ${props.theme.borders.radius.normal}px;
  border: 1px solid ${props.theme.borders.color.light};
`;

export const tabsContainer = () => css`
  width: 100%;
  display: flex;
  margin-bottom: 50px;
`;

export const tabStyle = props => css`
  display: block;
  position: relative;
  ${props.direction === 'rtl'
    ? css`
        text-align: right;
      `
    : css`
        text-align: left;
    `}
  padding: 6.5px ${props.theme.paddings.large}px;
  margin: 0;
  margin-bottom: 1px;
  color: ${props.theme.colors.labels.emphasized};
  background-color: ${props.theme.colors.invertedLabels.important};
  border-radius: 0px;
  font-size: ${props.theme.fonts.sizes.medium - 2}px;
  opacity: 1;
  text-transform: capitalize;
  outline: none;
  line-height: 1.5;
  &&&&:hover {
    background-color: #f9f9f9;
  }
`;

export const activeTabStyle = props => css`
  color: ${props.theme.colors.primary};
  background-color: #f9f9f9;
  ${props.direction === 'rtl'
    ? css`
        padding-right: ${props.theme.paddings.medium + 2}px;
        border-right: 2px solid ${props.theme.colors.primary};
      `
    : css`
        padding-left: ${props.theme.paddings.medium + 2}px;
        border-left: 2px solid ${props.theme.colors.primary};
    `}
  border-radius: 0;
  &&&&:after {
    position: absolute;
    ${props.direction === 'rtl'
    ? css`
        left: ${props.theme.paddings.large}px;
        transform: rotate(180deg);
      `
    : css`
        right: ${props.theme.paddings.large}px;
    `}
    top: ${props.theme.paddings.small}px;
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: ${props.theme.fonts.weights.semiBold};
    content: '\f105';
  }
`;

export const activeContentStyle = () => css`
  width: 79%;
  min-height: 500px;
  background-color: transparent;
  position: relative;
`;

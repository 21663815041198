import React from 'react';
import styled from 'react-emotion';

import PropTypes from 'prop-types';

const ImageElm = styled.img`
  width: ${props => (props.width ? props.width : 'unset')};
  height: ${props => (props.height ? props.height : 'unset')};
`;

const Image = props => <ImageElm src={props.src} alt={props.alt} {...props} />;

Image.propTypes = PropTypes.shape({
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
}).isRequired;

export default Image;

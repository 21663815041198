import { graphql } from 'react-relay';

export const DeleteSmartCartMutationRoot = 'delete_smart_cart';

export const DeleteSmartCartMutation = graphql`
    mutation DeleteSmartCartMutation($id: ID) {
      remove_smart_cart(id: $id) {
        errors {
          code
          field
          messages
        }
        current_user {
          ...UserInfoFragment @relay(mask: false)
        }
      }
    }
  `;

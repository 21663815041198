import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import Rating from 'react-rating';

import { MediumSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import { RatingsCountLabel } from './ProductLabels';

const RatingContainer = withDirection(styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => (props.starsSize ? props.starsSize : 18)}px !important;
  color: #ffdf00;
  .empty {
    color: #bbbbbb;
  }
  bottom: 2px;
  position: relative;
  > span {
    ${props => props.direction === 'rtl'
      && css`
      direction: rtl !important;
      span {
        right: 0 !important;
      }
    `}
  }
  ${props => props.customStyle && props.customStyle(props)};
  
`);

const AykRating = (
  {
    rating, size, isNotReadOnly, ratingCount, customCountStyles, customStyle, spaced, starsSize,
  },
  props,
) => (
  <RatingContainer starsSize={starsSize} customStyle={customStyle}>
    <Rating
      {...props}
      stop={5}
      initialRating={rating}
      readonly={!isNotReadOnly}
      emptySymbol={`fas fa-star empty ${size || 'fa-sm'}`}
      placeholderSymbol={`fas fa-star empty ${size || 'fa-sm'}`}
      fullSymbol={`fas fa-star ${size || 'fa-sm'}`}
    />
    {spaced && <MediumSpacer />}
    {(ratingCount || ratingCount === 0) && (
      <RatingsCountLabel styles={customCountStyles} className="empty">
        {' '}
        {spaced ? `( ${ratingCount} )` : `(${ratingCount})`}
        {' '}
      </RatingsCountLabel>
    )}
  </RatingContainer>
);

AykRating.propTypes = PropTypes.shape({
  rating: PropTypes.number.isRequired,
  ratingCount: PropTypes.number,
}).isRequired;

export default AykRating;

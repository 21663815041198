import { graphql } from 'relay-runtime';

const TwitterAuthQuery = graphql`
  query TwitterAuthQuery($oAuthVerifier: String!, $oAuthToken: String!) {
    twitter_authenticate(oauth_token: $oAuthToken, oauth_verifier: $oAuthVerifier) {
      email
      name
      secret
      token
      errors {
        code
        field
        messages
      }
    }
  }
`;

export const variables = queryParams => ({
  oAuthVerifier: queryParams.oauth_verifier,
  oAuthToken: queryParams.oauth_token,
});

export default TwitterAuthQuery;

import React from 'react';
import i18next from 'i18next';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';

import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = () => (
  <Panel
    borderLessHeader
    borderLessBody
    headerTitle={
      i18next.t('accountManagement:Profile.changePassword')
    }
    body={(
      <Box centerAligned fullWidth>
        <Spacer size={1.5} />
        <ChangePasswordForm />
      </Box>
    )}
  />
);

export default withUserInfo(ChangePassword);

/* eslint-disable */
import React from 'react';
import i18next from 'i18next';
import styled from 'react-emotion';
import Countdown from 'react-countdown-now';
import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { XSmallLabel, XLargeLabel } from '~/modules/coreUI/components/basic/Labels';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const Completionist = () => (
  <LargeLabelStyle>{i18next.t('ecommerceOrder:expired')}</LargeLabelStyle>
);

const LargeLabelStyle = styled(XLargeLabel)`
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  color: ${props => props.theme.new.colors.labels.normal.important};
  font-size: 18px;
  ${cssMediaMax.tablet`
    font-size: 16px;
  `};
`;

const SmallLabelStyle = styled(XSmallLabel)`
  text-transform: capitalize;
`;

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => (
  <React.Fragment>
    {completed ? (
      <Completionist />
    ) : (
      <Row topAligned>
        {days > 0 &&
          <React.Fragment>
            <Column>
              <LargeLabelStyle>{days < 10 && '0'}{days}</LargeLabelStyle>
              <SmallLabelStyle>{i18next.t('ecommerceOrder:days')}</SmallLabelStyle>
            </Column>
            <LargeLabelStyle>:</LargeLabelStyle>
          </React.Fragment>
        }
        <Column>
          <LargeLabelStyle>{hours < 10 && '0'}{hours}</LargeLabelStyle>
          <SmallLabelStyle>{i18next.t('ecommerceOrder:hours')}</SmallLabelStyle>
        </Column>
        <LargeLabelStyle>:</LargeLabelStyle>
        <Column>
        <LargeLabelStyle>{minutes < 10 && '0'}{minutes}</LargeLabelStyle>
        <SmallLabelStyle>{i18next.t('ecommerceOrder:mins')}</SmallLabelStyle>
        </Column>
        <LargeLabelStyle>:</LargeLabelStyle>
        <Column>
          <LargeLabelStyle>{seconds < 10 && '0'}{seconds}</LargeLabelStyle>
          <SmallLabelStyle>{i18next.t('ecommerceOrder:secs')}</SmallLabelStyle>
        </Column>
      </Row>

    )}
  </React.Fragment>
);

const getMsDate = (expiryDate) => {
  const date = new Date(expiryDate); // some mock date
  const milliseconds = date.getTime();
  return milliseconds;
};

const ItemCountDown = ({ itemInfo }) => (
  <Countdown
    date={getMsDate(itemInfo.discount_expire_at)}
    renderer={renderer}
  />
);

export default ItemCountDown;

/* eslint-disable react/prop-types */
import React from 'react';
import { css } from 'emotion';

const containerStyles = css`
  position: relative;

  min-width: 50px;
  min-height: 50px;

  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);

  background-color: #f9f9f9;
`;

const imageStyles = css`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const bludDotStyles = css`
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: #33a8ff;
  border-radius: 50%;
  border: 1px solid white;
  top: 0;
  left: 35px;
  right: 0;
  bottom: 0;
  content: '';
`;

const defaultIcon = 'notifications_ayk.png';

const iconFileNames = {
  order: 'notifications_order.png',
  loyality: 'notifications_loyalty.png',
  promotion: 'notifications_promo.png',
  message: 'notifications_messages.png',
  refund: 'notifications_refund.png',
  account: 'notifications_account.png',
  vendor: 'notifications_shop.png',
  product: 'notifications_product.png',
  discount: 'notifications_offer.png',
  join_as_business: 'notifications_joinasbusiness.png',
  contact_us: 'notifications_contactus.png',
  transaction: 'notifications_transactions.png',
};

const NotificationIcon = props => (
  <div className={containerStyles}>
    {!props.isRead ? <div className={bludDotStyles} /> : null}
    <img
      src={`/${imagesPathName}/${iconFileNames[props.navKey] || defaultIcon}`}
      alt="alt"
      className={imageStyles}
    />
  </div>
);

export default NotificationIcon;

import { graphql } from 'react-relay';
import React from 'react';
import PropTypes from 'prop-types';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import ItemsCardsSlider from '~/modules/ecommerceCoreUI/components/basic/ItemsCardsSlider';
import Product from './Product';

const ProductsList = ({ queryResult }) => (
  <ItemsCardsSlider>
    {queryResult
      && queryResult.products.result
      && queryResult.products.result.map(entry => (
        // eslint-disable-next-line no-underscore-dangle
        <Product isVertical key={entry.__id} productInfo={entry} />
      ))}
  </ItemsCardsSlider>
);

ProductsList.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
};

export default withRootQuery(
  ProductsList,
  graphql`
    query ProductsListPageQuery {
      products(input: {}) {
        result {
          ...Product_productInfo
        }
        errors {
          field
          messages
          code
        }
      }
    }
  `,
);

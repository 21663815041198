/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import styled from 'react-emotion';
import { cssMediaMax, mediaSizesMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import SlickSlider from '~/modules/ecommerceCoreUI/components/slickSlider/SlickSlider';

const SliderWrapper = styled.div`
  > div > .slick-slider {
    margin: 0 -15px;
    ${cssMediaMax.mobile`
      margin: 0;
    `}
    > div > .slick-track {
      margin-bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      > .slick-slide {
        > div {
        margin: 24px 5px;
      }
      }
    }
    .slick-dots {
      margin-bottom: 40px;
    }
  }
`;
class ProductsSlider extends React.PureComponent {
  render = () => {
    const { children } = this.props;
    const settings = {
      dots: true,
      speed: 500,
      infinite: children.length <= 6 ? false : true,
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: mediaSizesMax.desktop + 20,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: children.length <= 5 ? false : true,
          },
        },
        {
          breakpoint: mediaSizesMax.tablet + 300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: children.length <= 4 ? false : true,
          },
        },
        {
          breakpoint: mediaSizesMax.mobile + 20,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: children.length <= 3 ? false : true,
            arrows: false,
          },
        },
        {
          breakpoint: mediaSizesMax.xsmall + 170,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: children.length <= 2 ? false : true,
            arrows: false,
          },
        },
        {
          breakpoint: mediaSizesMax.xsmall - 50,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            arrows: false,
          },
        },
      ],
    };
    return (
      <Container>
        <SliderWrapper>
          <SlickSlider settings={settings}>{children}</SlickSlider>
        </SliderWrapper>
      </Container>
    );
  };
}
ProductsSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductsSlider;

import React, { createContext, useReducer } from 'react';

import showPageErrorReducer from '../reducers/serverErrorReducer';

export const ServerErrorContext = createContext();

const ServerErrorContextProvider = (props) => {
  const [CurrentState, dispatch] = useReducer(showPageErrorReducer, { errors: false, connected: true });
  return (
    <ServerErrorContext.Provider value={{ CurrentState, dispatch }}>
      {props?.children}
    </ServerErrorContext.Provider>
  );
};

export default ServerErrorContextProvider;

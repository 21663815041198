/* eslint-disable react/prop-types, camelcase */
import React from 'react';

import Breadcrumbs from '~/modules/coreUI/components/basic/Breadcrumbs';
import BreadcrumbsLinks from '~/modules/ecommerceCore/BreadcrumbsLinks';

// Home > Cart

const CartBreadcrumbs = () => (
  <Breadcrumbs
    crumbs={[
      BreadcrumbsLinks.HOME(),
      BreadcrumbsLinks.CART(),
    ]}
  />
);

export default CartBreadcrumbs;

const breadcrumbs = {
  En: {
    products: 'Products',
    ask: 'Ask',
    askAykMall: 'Ask Ayk',
    cart: 'Cart',
    myAccount: 'My Account',
    support: 'Help Center',
    searchResult: 'Search Result',
  },
  Ar: {
    products: 'المنتجات',
    ask: 'اسأل',
    askAykMall: 'اسأل أيك',
    cart: 'العربة',
    myAccount: 'حسابي',
    support: 'مركز المساعدة',
    searchResult: 'نتائج البحث',
  },
};
export default breadcrumbs;

import React, { Component, Fragment } from 'react';
import { graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import PropTypes from 'prop-types';
import i18next from 'i18next';

import Button from '~/modules/coreUI/components/basic/Button';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';
import { isAuthenticated } from '~/modules/core/utils/accessManagementHelpers/userInfoUtils';
import { setLocalMessageFor } from '~/modules/accountManagement/utilities/messagesHelper';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';

const Mutation = graphql`
  mutation FollowMutation($vendor_id: ID!) {
    follow(vendor_id: $vendor_id) {
      current_user{
        followed_shops{
          ...Shop_shopInfo @relay(mask: false)
        }
      }
      result {
        followed
      }
      errors {
        field
        messages
        code
      }
    }
  }`;

const MutationRoot = 'follow';

class Follow extends Component {
  state = {
    loading: false,
  };

  onMutationError = () => {
    this.setState({
      loading: false,
    });
  };

  onMutationSuccess = () => {
    this.setState({
      loading: false,
    });

    this.props.notifyAlert({
      component: () => (
        <Label inverted sm emphasized>
          <i className="el-icon" />
          &nbsp;
          {this.props.followed ? i18next.t('ecommerceCoreUI:Alert.followAdd') : i18next.t('ecommerceCoreUI:Alert.followRemove')}
        </Label>
      ),
      type: AlertTypes.success,
      opacity: 1,
      toastID: 'Follow_success_toast',
    });
  };

  onMutationLoading = () => {
    this.setState({
      loading: true,
    });
  };

  toggleFollow = (e) => {
    // stop propagation to prevent shop card click that would open shop page
    e.stopPropagation();
    e.preventDefault();

    if (isAuthenticated()) {
      commitMutation(
        this.props.environment,
        Mutation,
        MutationRoot,
        {
          vendor_id: parseInt(this.props.vendorId, 10),
        },
        this.onMutationSuccess,
        this.onMutationError,
        this.onMutationLoading,
      );
    } else {
      setLocalMessageFor(PathRedirectKeysMapper.login, i18next.t('ecommerceCoreUI:Alert.wishlistLoginRequired'));
      navigateToModal(this.props.history, '/accountManagement/login');
    }
  };

  render() {
    const { props } = this;
    return (
      <Fragment>
        <Button
          semiBold
          primary
          fullRound
          size={props.size}
          iconName={this.props.followed ? '' : 'icon-plus'}
          inverted={!this.props.followed}
          onClicked={this.toggleFollow}
          loading={this.state.loading}
          {..._.omit(props, ['onMutationSubmit'])} // CODE_REVIEW : Seems like this is not needed and causes errors
        >
          {this.props.followed ? i18next.t('ecommerceCoreUI:ToolTipTrans.following') : i18next.t('ecommerceCoreUI:ToolTipTrans.follow')}
        </Button>
      </Fragment>
    );
  }
}

Follow.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  followed: PropTypes.bool,
  size: PropTypes.arrayOf(PropTypes.any),
  vendorId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Follow.defaultProps = {
  size: null,
  followed: false,
};
export default withRouter(withAlertMsg(withRelayEnvironment(
  Follow,
)));

import React from 'react';
import PropTypes from 'prop-types';

import i18next from 'i18next';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

import ResendEmailConfirmationCodeMutation, {
  ResendEmailConfirmationCodeMutationRoot,
} from './ResendEmailConfirmationCodeMutation';

import VerifyAccount from './VerifyAccount';

const IMAGE_PATH = `/${imagesPathName}/AccountManagement`;

class VerifyByEmail extends React.Component {
  state = {
    isLoading: false,
    error: null,
  };

  onMutationError = errors => this.setState({ error: errors });

  onMutationSuccess = () => /* No-op */ null;

  onMutationLoading = isLoading => this.setState({ isLoading });

  resendEmail = () => {
    commitMutation(
      this.props.environment,
      ResendEmailConfirmationCodeMutation,
      ResendEmailConfirmationCodeMutationRoot,
      {
        email: this.props.userInfo.email,
      },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
  };

  render = () => (
    <VerifyAccount
      imageSrc={`${IMAGE_PATH}/Email.png`}
      imageSrcSet={
        `${IMAGE_PATH}/Email.png 2x,
         ${IMAGE_PATH}/Email.png 3x`
      }
      contentText={i18next.t('accountManagement:verifyAccount.EmailTxt')}
      onResendClicked={this.resendEmail}
      isLoading={this.state.isLoading}
      error={this.state.error}
    />
  );
}

VerifyByEmail.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  environment: PropTypes.shape({}).isRequired,
};

export default withUserInfo(
  withRelayEnvironment(
    VerifyByEmail,
  ),
);

import React from 'react';
import PropTypes from 'prop-types';
import Rating from '~/modules/ecommerceCoreUI/components/basic/Rating';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const ItemRating = withMedia(({ itemInfo, media }) => (
  <Rating
    starsSize={media.xsmall ? 12 : 16}
    rating={itemInfo.avg_rating}
    ratingCount={itemInfo.rating_count}
  />
));

ItemRating.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default ItemRating;

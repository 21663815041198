import React from 'react';
import i18next from 'i18next';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Button from '~/modules/coreUI/components/basic/Button';
import EmptyStatePage from '~/modules/coreUI/components/layouts/emptyStatePage';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

const EmptyPageWrapper = styled.div`
  text-align: center;
`;
const EmptyOrdersList = () => (
  <EmptyPageWrapper>
    <Spacer size={1.8} />
    <EmptyStatePage
      imagePath={`/${imagesPathName}/products.png`}
      contentHeader={i18next.t('ecommerceOrder:Following.emptyHeader')}
      content={i18next.t('ecommerceOrder:Following.emptyLabel')}
    />
    <Spacer size={3} />
    <Link to={urlFor('getShopTracksLink')}>
      <Button width="270px" xl secondary>
        {i18next.t('ecommerceOrder:Following.viewShops')}
      </Button>
    </Link>
  </EmptyPageWrapper>
);

export default EmptyOrdersList;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WithCartData_cart$ref: FragmentReference;
export type WithCartData_cart = {
  +id: string,
  +ref_id: string,
  +name: ?string,
  +amount: number,
  +amount_in_qar: number,
  +quantity: number,
  +shipping_price: number,
  +raw_amount: number,
  +services_only: ?boolean,
  +least_state: ?string,
  +promo_code: ?string,
  +discount: number,
  +taxes: number,
  +selected_shipping_address: ?{
    +ref_id: string
  },
  +selected_billing_address: ?{
    +ref_id: string
  },
  +smart_cart: ?boolean,
  +user_smart_cart: ?boolean,
  +same_as_shipping: ?boolean,
  +payment_reference: ?string,
  +amount_with_cash: number,
  +amount_with_credit: number,
  +orders: $ReadOnlyArray<?{
    +guest_token: ?string,
    +amount: number,
    +id: string,
    +product_type: ?string,
    +selected_billing_address_id: ?number,
    +selected_shipping_address_id: ?number,
    +same_as_shipping: ?boolean,
    +vendor_branch_id: ?number,
    +ref_id: string,
    +total: number,
    +notes: ?string,
    +state: string,
    +promo_total: number,
    +tax_total: number,
    +adjustment_total: number,
    +free_shipping: ?boolean,
    +available_payment_methods: ?$ReadOnlyArray<?{
      +ref_id: string,
      +id: string,
      +name: string,
    }>,
    +line_items: $ReadOnlyArray<?{
      +created_at: ?any,
      +amount: number,
      +price: number,
      +warnings: ?$ReadOnlyArray<?{
        +field: ?string,
        +messages: ?$ReadOnlyArray<?string>,
        +blocking: ?boolean,
      }>,
      +id: string,
      +quantity: number,
      +variant_services: ?$ReadOnlyArray<?{
        +ref_id: string
      }>,
      +variant: {
        +name: string,
        +id: string,
        +ref_id: string,
        +images: $ReadOnlyArray<?string>,
        +price: number,
        +slug: ?string,
        +vendor_variants_count: ?number,
        +min_variant: ?{
          +vendor: ?{
            +name: ?string,
            +ref_id: string,
          }
        },
        +total_on_hand: ?number,
        +display_price: ?string,
        +images_attachments: $ReadOnlyArray<?{
          +url_original: string,
          +url_small: string,
        }>,
        +option_values: ?$ReadOnlyArray<?{
          +id: string,
          +name: ?string,
          +presentation: ?string,
        }>,
        +vendor: ?{
          +name: ?string,
          +active: ?boolean,
        },
      },
      +total: number,
    }>,
    +vendor: {
      +name: ?string,
      +branches: ?$ReadOnlyArray<?{
        +ref_id: string,
        +name: string,
        +address: ?string,
        +lat: ?number,
        +lng: ?number,
        +inwani_building: ?number,
        +inwani_street: ?number,
        +inwani_zone: ?number,
      }>,
      +payment_methods: $ReadOnlyArray<?{
        +ref_id: string,
        +name: string,
      }>,
    },
    +vendor_branch: ?{
      +ref_id: string,
      +name: string,
      +address: ?string,
      +lat: ?number,
      +lng: ?number,
      +inwani_building: ?number,
      +inwani_street: ?number,
      +inwani_zone: ?number,
    },
    +payments: $ReadOnlyArray<?{
      +payment_method: {
        +ref_id: string,
        +name: string,
      }
    }>,
    +shipments: $ReadOnlyArray<?{
      +ref_id: string,
      +shipping_rates: $ReadOnlyArray<?{
        +ref_id: string,
        +base_price: ?number,
        +shipping_method: {
          +calculator: ?{
            +type: string
          },
          +ref_id: string,
          +name: ?string,
          +estimated_delivery_time: ?string,
        },
      }>,
      +shipping_rate_selected: ?{
        +ref_id: string,
        +base_price: ?number,
        +shipping_method: {
          +calculator: ?{
            +type: string
          },
          +ref_id: string,
          +name: ?string,
          +estimated_delivery_time: ?string,
        },
      },
    }>,
  }>,
  +promotions: ?$ReadOnlyArray<?{
    +ref_id: string,
    +code: ?string,
  }>,
  +user: ?{
    +avatar_details: ?{
      +url_original: ?string,
      +name: ?string,
    },
    +first_name: ?string,
    +last_name: ?string,
  },
};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v5 = [
  v4
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "same_as_shipping",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url_original",
  "args": null,
  "storageKey": null
},
v10 = [
  v4,
  v1,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "address",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lat",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lng",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "inwani_building",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "inwani_street",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "inwani_zone",
    "args": null,
    "storageKey": null
  }
],
v11 = [
  v4,
  v1
],
v12 = [
  v4,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "base_price",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "shipping_method",
    "storageKey": null,
    "args": null,
    "concreteType": "VendorShippingMethod",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "calculator",
        "storageKey": null,
        "args": null,
        "concreteType": "Calculator",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          }
        ]
      },
      v4,
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "estimated_delivery_time",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "WithCartData_cart",
  "type": "Cart",
  "metadata": {
    "mask": false
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "taxes",
      "args": null,
      "storageKey": null
    },
    v0,
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amount_in_qar",
      "args": null,
      "storageKey": null
    },
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shipping_price",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "raw_amount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "services_only",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "least_state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "promo_code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "discount",
      "args": null,
      "storageKey": null
    },
    v4,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "selected_shipping_address",
      "storageKey": null,
      "args": null,
      "concreteType": "Address",
      "plural": false,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "selected_billing_address",
      "storageKey": null,
      "args": null,
      "concreteType": "Address",
      "plural": false,
      "selections": v5
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "smart_cart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "user_smart_cart",
      "args": null,
      "storageKey": null
    },
    v6,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "payment_reference",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amount_with_cash",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amount_with_credit",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "orders",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "guest_token",
          "args": null,
          "storageKey": null
        },
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "product_type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "selected_billing_address_id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "selected_shipping_address_id",
          "args": null,
          "storageKey": null
        },
        v6,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "vendor_branch_id",
          "args": null,
          "storageKey": null
        },
        v4,
        v7,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "notes",
          "args": null,
          "storageKey": null
        },
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "promo_total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tax_total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "adjustment_total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "free_shipping",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "available_payment_methods",
          "storageKey": null,
          "args": null,
          "concreteType": "PaymentMethod",
          "plural": true,
          "selections": [
            v4,
            v0,
            v1
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "line_items",
          "storageKey": null,
          "args": null,
          "concreteType": "LineItem",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "created_at",
              "args": null,
              "storageKey": null
            },
            v2,
            v8,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "warnings",
              "storageKey": null,
              "args": null,
              "concreteType": "Error",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "field",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "messages",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "blocking",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            v0,
            v3,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "variant_services",
              "storageKey": null,
              "args": null,
              "concreteType": "Variant",
              "plural": true,
              "selections": v5
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "variant",
              "storageKey": null,
              "args": null,
              "concreteType": "Variant",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "vendor_variants_count",
                  "args": null,
                  "storageKey": null
                },
                v1,
                v4,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "images",
                  "args": null,
                  "storageKey": null
                },
                v8,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "slug",
                  "args": null,
                  "storageKey": null
                },
                v0,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "min_variant",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Variant",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "vendor",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Shop",
                      "plural": false,
                      "selections": [
                        v1,
                        v4
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "total_on_hand",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "display_price",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "images_attachments",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ImageAttachment",
                  "plural": true,
                  "selections": [
                    v9,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "url_small",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "option_values",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OptionValue",
                  "plural": true,
                  "selections": [
                    v0,
                    v1,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "presentation",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "vendor",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Shop",
                  "plural": false,
                  "selections": [
                    v1,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "active",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            },
            v7
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "vendor",
          "storageKey": null,
          "args": null,
          "concreteType": "Shop",
          "plural": false,
          "selections": [
            v1,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "branches",
              "storageKey": null,
              "args": null,
              "concreteType": "VendorBranch",
              "plural": true,
              "selections": v10
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "payment_methods",
              "storageKey": null,
              "args": null,
              "concreteType": "PaymentMethod",
              "plural": true,
              "selections": v11
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "vendor_branch",
          "storageKey": null,
          "args": null,
          "concreteType": "VendorBranch",
          "plural": false,
          "selections": v10
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "payments",
          "storageKey": null,
          "args": null,
          "concreteType": "Payment",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "payment_method",
              "storageKey": null,
              "args": null,
              "concreteType": "PaymentMethod",
              "plural": false,
              "selections": v11
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "shipments",
          "storageKey": null,
          "args": null,
          "concreteType": "Shipment",
          "plural": true,
          "selections": [
            v4,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "shipping_rates",
              "storageKey": null,
              "args": null,
              "concreteType": "ShippingRate",
              "plural": true,
              "selections": v12
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "shipping_rate_selected",
              "storageKey": null,
              "args": null,
              "concreteType": "ShippingRate",
              "plural": false,
              "selections": v12
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "promotions",
      "storageKey": null,
      "args": null,
      "concreteType": "Promotion",
      "plural": true,
      "selections": [
        v4,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "UserPublicInfo",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "avatar_details",
          "storageKey": null,
          "args": null,
          "concreteType": "ImageDetails",
          "plural": false,
          "selections": [
            v9,
            v1
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "first_name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "last_name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '22a0620010e85be3811ce538581f5970';
module.exports = node;

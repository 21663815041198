import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled, { css } from 'react-emotion';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import Icon from '~/modules/coreUI/components/basic/Icon';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import BasicLink from '~/modules/ecommerceCoreUI/components/basic/Link';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import LabelWithAction from '~/modules/ecommerceOrder/containers/inviteFriend/LabelWithAction';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const Content = styled.div`
  border: none;
  display: inline-block;
  min-width: ${props => 20 * props.theme.new.spacer}px;
  width: ${props => 24.8 * props.theme.new.spacer}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 17px;
  line-height: 17px;
  ${props => cssMediaMax.xsmall`
    width: ${10 * props.theme.new.spacer}px;
    min-width: ${10 * props.theme.new.spacer}px;
  `}
`;
const IconWrapper = withDirection(styled(Icon)`
  &&& {
    ${props => (props.direction === 'rtl'
      && css`
          transform: rotate(180deg);
      `)}
  }
`);
const IconLabel = styled(Icon)`
  font-size: ${props => props.theme.fonts.sizes.xSmall}px;
`;
const AykGiftsInfo = ({ giftsNumber }) => (
  <LabelWithAction
    label={(
      <Row spaceBetween={0.5}>
        <IconLabel primary className="el-icon el-icon-gift-promo" />
        <Label primary sm>
          {`${giftsNumber} ${i18next.t('ecommerceOrder:Loyalty.gifts')}`}
        </Label>
      </Row>
    )}
    content={(
      <Content>
        {i18next.t('ecommerceOrder:Loyalty.giftsNumber', { giftsNumber })}
      </Content>
    )}
    action={(
      <BasicLink targeturl={urlFor('accountSettings', ['coupons'])} secondary>
        <Row rightAligned rightJustified spaceBetween={0.5}>
          <Label semiBold sm secondary>{i18next.t('ecommerceOrder:Loyalty.view')}</Label>
          <IconWrapper className="el-icon el-icon-right" />
        </Row>
      </BasicLink>
    )}
  />
);

AykGiftsInfo.propTypes = {
  giftsNumber: PropTypes.number.isRequired,
};

export default AykGiftsInfo;

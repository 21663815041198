import React from 'react';
import styled, { css } from 'react-emotion';
import { PropTypes } from 'prop-types';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import SocialMedia from '~/modules/ecommerceCoreUI/components/header/headerActions/SocialMedia';
import { BasicButton } from '~/modules/ecommerceCoreUI/components/basic/BasicButton';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import Heading from './Heading';
import { HomePageDropdownElement } from './DropdownContainer';

export const SideMenuContainer = withDirection(styled.div`
  width: 270px;
  max-height: 100vh;
  position: absolute;
  z-index: 5;
  top: 0;
  ${props => (props.direction === 'rtl'
    ? css`
        right: -270px;
      `
    : css`
        left: -270px;
  `)}
  bottom: 0;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.named.white};
  transition: all 1.5s;
  overflow: auto;
  a {
    color: #fff !important;
  }
`);
export const Item = withDirection(styled.div`
  position: relative;
  padding: 11px 27px;
  font-size: ${props => props.theme.fonts.sizes.small}px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  text-transform: capitalize;
  :after {
    width: 6px;
    height: 6px;
    position: absolute;
    ${props => (props.direction === 'rtl'
    ? css`
        right: 13px;
      `
    : css`
        left: 13px;
  `)}
    top: 17px;
    background-color: ${props => props.theme.colors.named.white};
    border-radius: 50%;
    content: '';
  }
`);
export const SocialMediaContent = styled(SocialMedia)`
  a {
    color: #fff;
  }
`;

export const Logout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const logout = (e, invalidateUserAndLogout) => {
  e.stopPropagation();
  invalidateUserAndLogout();
};

const SideMenu = props => (
  <SideMenuContainer>
    <Heading />
    <Item>
      {' '}
      <ExternalLink url="/">
        <span>{i18next.t('ecommerceCoreUI:homeTitle')}</span>
      </ExternalLink>
      {' '}
    </Item>
    <HomePageDropdownElement history={props.history} />
    {props.authenticated ? (
      <Logout>
        <Spacer size={1.5} />
        <BasicButton primary onClick={e => logout(e, props.invalidateUserAndLogout)}>
          {i18next.t('ecommerceCoreUI:logout')}
        </BasicButton>
        <Spacer size={1.5} />
      </Logout>
    ) : (
      ' '
    )}
    <Spacer size={1.5} />
    <SocialMediaContent isCentered />
    <Spacer size={2.5} />
  </SideMenuContainer>
);

SideMenu.propTypes = {
  invalidateUserAndLogout: PropTypes.func,
  authenticated: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({}).isRequired,
  }).isRequired,
};

SideMenu.defaultProps = {
  invalidateUserAndLogout: ' ',
  authenticated: ' ',
};

export default withRouter(withUserInfo(SideMenu));

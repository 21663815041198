const TracksTrans = {
  En: {
    products: 'Products',
    shops: 'Stores',
    homemade: 'Homemade',
    education: 'Edu Park',
    healthy: 'Health Park',
    productsWelcomeMessage: 'Shop by Products',
    shopsWelcomeMessage: 'Shop by Shops',
    homemadeWelcomeMessage: 'Shop by Shops',
    educationParkWelcomeMessage: 'Shop by Shops',
    healthParkWelcomeMessage: 'Shop by Shops',
  },
  Ar: {
    products: 'المنتجات',
    shops: 'المتاجر',
    homemade: 'الصناعة المنزلية',
    education: 'واحة التعليم',
    healthy: 'واحة الصحة',
    productsWelcomeMessage: 'تسوق بالمنتجات',
    shopsWelcomeMessage: 'تسوق من المحلات',
    homemadeWelcomeMessage: 'تسوق من المحلات',
    educationParkWelcomeMessage: 'تسوق من المحلات',
    healthParkWelcomeMessage: 'تسوق من المحلات',
  },
};

export default TracksTrans;

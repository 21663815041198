/**
 * @flow
 * @relayHash 219eb58fbdf3e84e65975e2e7e54ad8c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeaturedShopsQueryVariables = {||};
export type FeaturedShopsQueryResponse = {|
  +slides: $ReadOnlyArray<?{|
    +button_color: string,
    +button_text: string,
    +button_text_color: string,
    +id: string,
    +large_photo_object: ?{|
      +url_original: ?string
    |},
    +ref_id: string,
    +title: string,
    +title_color: string,
    +url: string,
  |}>
|};
export type FeaturedShopsQuery = {|
  variables: FeaturedShopsQueryVariables,
  response: FeaturedShopsQueryResponse,
|};
*/


/*
query FeaturedShopsQuery {
  slides(featured_shop: true) {
    button_color
    button_text
    button_text_color
    id
    large_photo_object {
      url_original
    }
    ref_id
    title
    title_color
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "slides",
    "storageKey": "slides(featured_shop:true)",
    "args": [
      {
        "kind": "Literal",
        "name": "featured_shop",
        "value": true,
        "type": "Boolean"
      }
    ],
    "concreteType": "Slider",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_text",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_text_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "large_photo_object",
        "storageKey": null,
        "args": null,
        "concreteType": "ImageDetails",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url_original",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ref_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "FeaturedShopsQuery",
  "id": null,
  "text": "query FeaturedShopsQuery {\n  slides(featured_shop: true) {\n    button_color\n    button_text\n    button_text_color\n    id\n    large_photo_object {\n      url_original\n    }\n    ref_id\n    title\n    title_color\n    url\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FeaturedShopsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "FeaturedShopsQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b86324f8ee05caeb3f0c35253b17a2d3';
module.exports = node;

import React from 'react';
import PropTypes from 'prop-types';

import i18next from 'i18next';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';

import ResendSMSConfirmationCodeMutation, {
  ResendSMSConfirmationCodeMutationRoot,
} from './ResendSMSConfirmationCodeMutation';

import VerifyAccount from './VerifyAccount';

const IMAGE_PATH = `/${imagesPathName}/AccountManagement`;

class VerifyBySMS extends React.Component {
  state = {
    isLoading: false,
    error: null,
  };

  onMutationError = errors => this.setState({ error: errors });

  onMutationSuccess = () => /* No-op */ null;

  onMutationLoading = isLoading => this.setState({ isLoading });

  resendEmail = () => {
    commitMutation(
      this.props.environment,
      ResendSMSConfirmationCodeMutation,
      ResendSMSConfirmationCodeMutationRoot,
      {
        email: this.props.userInfo.email,
      },
      this.onMutationSuccess,
      this.onMutationError,
      this.onMutationLoading,
    );
  };

  render = () => (
    <VerifyAccount
      imageSrc={`${IMAGE_PATH}/SMSImages.png`}
      imageSrcSet={
        `${IMAGE_PATH}/SMSImages.png 2x,
         ${IMAGE_PATH}/SMSImages.png 3x`
      }
      contentText={i18next.t('accountManagement:verifyAccount.SMSTxt')}
      onResendClicked={this.resendEmail}
      isLoading={this.state.isLoading}
      error={this.state.error}
    />
  );
}

VerifyBySMS.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  environment: PropTypes.shape({}).isRequired,
};

export default withUserInfo(
  withRelayEnvironment(
    VerifyBySMS,
  ),
);

/* eslint-disable indent */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'react-emotion';
import i18next from 'i18next';

import Dropdown from '~/modules/coreUI/components/legacies/Dropdown';
import {
  SimpleCurrencyDropdownTriggerStyles,
  StyledHeaderDropdownContainer,
} from '../CustomHeaderComponentsStyles';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import changeCurrency, { currentCurrency } from '~/modules/ecommerceCore/utils/changeCurrency';

const DropdownCurrencyItems = withDirection(styled(StyledHeaderDropdownContainer)`
  .item span {
    font-size: 11px;
  }
  i.icon {
    font-size: 5px;
    display: inline-block;
    margin: 0 5px;
    width: auto;
    height: auto;
  }
  .ui.top.right.pointing.dropdown > .menu {
    width: 79px;
    padding: 0 15px 7px;
    box-shadow: none;
    right: -14px;
  }
  .ui.dropdown .menu > .item {
    ${props => (props.direction === 'rtl'
      && css`
        text-align: right;
    `)}
    padding: 8px 0 !important;
    border-bottom: 1px solid rgba(235, 235, 235, 0.6);
    color: rgba(0, 0, 0, 0.5);
    &:hover {
      background: transparent;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .ui.top.pointing.dropdown > .left.menu:after,
  .ui.top.right.pointing.dropdown > .menu:after {
    right: 45% !important;
  }
  .ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    background: transparent;
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.fonts.weights.semiBold};
    position: relative;
    &:after {
      content: '\\F058';
      font-family: Icons, sans-serif;
      position: absolute;
      ${props => (props.direction === 'rtl'
        ? css`
            left: 0;
          `
        : css`
            right: 0;

      `)}
      top: 11px;
      color: ${props => props.theme.colors.primary};
      font-size: ${props => props.theme.fonts.sizes.small}px;
    }
  }
`);

const onChange = (value) => {
  const currencyChanged = changeCurrency(value);
  if (currencyChanged) {
    window.location.reload();
  }
};

const NavCurrencyDesktop = ({ options }) => (
  <DropdownCurrencyItems>
    <Dropdown
      pointing="top right"
      options={options.map(option => ({
        text: i18next.t(`coreUI:currency.${option}`),
        value: option,
      }))}
      customTriggerLabelStyles={SimpleCurrencyDropdownTriggerStyles}
      onChange={(value) => { onChange(value); }}
      selectedValue={currentCurrency()}
    />
  </DropdownCurrencyItems>
);

NavCurrencyDesktop.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NavCurrencyDesktop;

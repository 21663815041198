import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const MainImage = styled.img`
  height: ${props => 17 * props.theme.new.spacer}px !important;
  max-width: 100%;
  div {
    z-index: 9999 !important;
  }
  margin: auto;
  border-width: ${props => props.theme.borders.size.bold + 2}px;
  border-radius: ${props => props.theme.borders.radius.normal}px;
`;

const SliderMainImage = ({ smallImage }) => (
  <MainImage src={smallImage} alt="product" />
);

SliderMainImage.propTypes = {
  smallImage: PropTypes.string,
};

SliderMainImage.defaultProps = {
  smallImage: null,
};
export default SliderMainImage;

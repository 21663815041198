/* eslint-disable camelcase */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Message from '~/modules/ecommerceOrder/containers/notificationAndMessages/messages/message/Message';
import AskAykLink from '~/modules/ecommerceOrder/containers/notificationAndMessages/messages/message/AskAykLink';
import EmptyMessages from '~/modules/ecommerceOrder/containers/notificationAndMessages/messages/message/EmptyMessages';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import ViewAllLink from '~/modules/ecommerceOrder/containers/notificationAndMessages/notifications/ViewAllLink';

const AskAykContainer = styled(Row)`
  height: ${props => 3.7 * props.theme.new.spacer}px;
  background-color: #f8f7f7;
`;

const MessagesAndNotificationMenu = styled.div`
  width: 100%;
  height: ${props => 30 * props.theme.new.spacer}px;
  overflow-y: auto;
  background-color: white;
`;

export const MAX_NUMBER_OF_SHOWN_MESSAGES = 13;

const MessagesListTab = ({ threads }) => (
  <React.Fragment>
    {threads.length > 0 ? (
      <React.Fragment>
        <MessagesAndNotificationMenu fullWidth>
          <Column fullWidth>
            {threads.map((thread, index) => (
              index <= MAX_NUMBER_OF_SHOWN_MESSAGES - 2 && <Message isDropDown key={thread.ref_id} thread={thread} />
            ))}
            {threads?.length > MAX_NUMBER_OF_SHOWN_MESSAGES - 1 && (
            <ViewAllLink tabName="messages" />
            )}
          </Column>
        </MessagesAndNotificationMenu>
        <AskAykContainer fullWidth>
          <AskAykLink />
        </AskAykContainer>
      </React.Fragment>
    ) : (
      <Column>
        <Spacer size={4} />
        <EmptyMessages />
      </Column>
    )}
  </React.Fragment>
);

MessagesListTab.defaultProps = {
  threads: {},
};

MessagesListTab.propTypes = {
  threads: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MessagesListTab;

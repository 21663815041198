import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import i18next from 'i18next';
import _ from 'lodash';
import {
  css,
} from 'emotion';
import {
  withTheme,
} from 'emotion-theming';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const keyValueStyle = props => css`
  ${props.direction === 'rtl'
    ? css`
        text-align: right;
      `
    : css`
        text-align: left;
  `}
  font-size: ${props.theme.new.fonts.sizes.md}px;
  line-height: 2.14;
`;

const Key = withDirection(styled.th`
  ${props => keyValueStyle(props)};
  width: 200px;
  opacity: 0.7;
  font-weight: ${props => props.theme.new.fonts.weights.normal};
  color: ${props => props.theme.new.colors.labels.emphasized};
  font-size: ${props => props.theme.new.fonts.sizes.sm}px;
`);

const Value = withDirection(styled.td`
  ${props => keyValueStyle(props)};

  font-weight: ${props => props.theme.new.fonts.weights.normal};
  color: ${props => props.theme.new.colors.labels.important};
`);

const EmptyValue = withDirection(styled.td`
  ${props => keyValueStyle(props)};
  opacity: 0.3;
  font-weight: ${props => props.theme.new.fonts.weights.normal};
  color: ${props => props.theme.new.colors.labels.subtle};
`);

const tableFields = { // Values, are defaultValue
  email: 'N/A',
  nationality_name: 'N/A',
  mobile_number: 'N/A',
  gender: 'N/A',
  birthdate: 'DD/MM/YY',
  country_name: 'N/A',
  city_name: 'N/A',
  short_address: 'N/A',
};

const tableFieldsInCaseQatar = { // Values, are defaultValue
  ...tableFields,
  phone_provider_name: 'N/A',
  school_name: 'N/A',
  employer_name: 'N/A',
  insurance_company_name: 'N/A',
  club_name: 'N/A',
};

const getCurrentTableFields = (userInfo) => {
  if (userInfo.phone_code === '974') {
    return tableFieldsInCaseQatar;
  }
  return tableFields;
};


const UserDataTable = props => (
  <table>
    <tbody>
      {_.keys(getCurrentTableFields(props.userInfo)).map(key => (
        <tr key={`user-${key}`}>
          <Key key={`key-${key}`}>{i18next.t(`accountManagement:Profile.${key}`)}</Key>
          {props.userInfo[key] ? (
            <Value key={`value-${key}`}>{key === 'mobile_number' ? props.userInfo[key].replace(/[()]/g, '') : props.userInfo[key]}</Value>
          ) : (
            <EmptyValue key={`value-${key}`}>
              {props.userInfo.phone_code === '974' ? tableFieldsInCaseQatar[key] : tableFields[key]}
            </EmptyValue>
          )}
        </tr>
      ))}
    </tbody>
  </table>
);

UserDataTable.propTypes = {
  userInfo: PropTypes.shape({
    phone_code: PropTypes.string.isRequired,
  }).isRequired,
};

export default withTheme(UserDataTable);

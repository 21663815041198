import { graphql } from 'react-relay';

import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';

const mutation = graphql`
  mutation SaveSelectedAddressesMutation($input: CartCheckoutUpdateInput!) {
    cart_checkout_update(input: $input) {
      cart{
        ...WithCartData_cart @relay(mask: false)
      }
      errors{
        code
        field
        messages
      }
    }
  }
`;

export default (environment, selectedShippingId, selectedBillingId, checkoutNext = true, sameAsShipping, onSuccess, onLoading, onError) => commitMutation(
  environment,
  mutation,
  'cart_checkout_update',
  {
    input: {
      data: {
        selected_shipping_address_id: parseInt(selectedShippingId, 10),
        selected_billing_address_id: !sameAsShipping ? parseInt(selectedBillingId, 10) : null,
        use_shipping: sameAsShipping,
        checkout_next: checkoutNext,
        from_state: 'address',
      },
    },
  },
  onSuccess,
  (errors) => {
    if (errors) {
      onError(errors);
    }
  },
  onLoading,
);

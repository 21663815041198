import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';

import ProgressBar from '~/modules/ecommerceCoreUI/components/basic/progressBar/ProgressBar';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const StepsWrapper = withDirection(styled.div`
  width: 320px;
  ${props => (props.direction === 'rtl'
    ? css`
        padding-left: 15px;
      `
    : css`
        padding-right: 15px;
  `)}
`);

const infereType = (status) => {
  if (status === 'fail') {
    return 'error';
  }
  if (status === 'pass') {
    return 'primary';
  }
  if (status === 'pending') {
    return 'disabled';
  }
  return 'primary';
};

const OrderSteps = ({ progressBar }) => (
  <StepsWrapper>
    <ProgressBar
      steps={progressBar.map((step, index) => ({
        label: step.name,
        labelSize: ['xxs', 'xs'],
        stepSize: ['xxs'],
        content: (step.status === 'pending' ? (
          <span>&nbsp;</span>
        ) : (
          <i className={`fas fa-${step.status === 'fail' ? 'times' : 'check'}`} />
        )),
        state: 'done',
        type: infereType(step.status),
        mode: (step.status === 'pending' ? 'inverted' : 'normal'),
        isFirstStep: index === 0,
        isLastStep: index === (progressBar.length - 1),
        stepButtonProps: {
          /* eslint-disable react/prop-types */
          customStyles: props => css`
            padding: 0px;
            width: ${2.1 * props.theme.new.spacer}px;
            height: ${2.1 * props.theme.new.spacer}px;
          `,
          /* eslint-enable react/prop-types */
        },
      }))}
      labelPosition="bottom"
      stepsPosition="end"
    />
  </StepsWrapper>
);

OrderSteps.propTypes = {
  progressBar: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OrderSteps;

/* eslint-disable camelcase,jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Shiitake from 'shiitake';
import { Link } from 'react-router-dom';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import ItemImage from '~/modules/ecommerceCoreUI/components/basic/ItemImage';
import Rating from '~/modules/ecommerceCoreUI/components/productDetails/areas/ProductRating';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { ItemOptions, ItemOption } from '~/modules/ecommerceOrder/components/ItemOptions';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';
import Checkbox from '~/modules/coreUI/components/basic/Checkbox';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { currentCurrency } from '~/modules/ecommerceCore/utils/changeCurrency';

import RefundReasons from '~/modules/ecommerceOrder/containers/refund/RefundReasons';
import Layout from './layouts';
import OrderCounter from './OrderCounter';

const imageURL = images_attachments => ((images_attachments && images_attachments.length > 0) ? images_attachments[0].url_small : undefined);

const LineItem = (props) => {
  const {
    lineItem: {
      variant: {
        name, rating_count, avg_rating, images_attachments, option_values, slug, vendor_variants_count,
      },
      quantity,
      remaining_request_quantity,
      eligible_refund,
      ref_id,
      refund_status,
      admin_comment,
      comment,
      refund_request_reason,
      refund_attachment,
      price,
    },
    vendor,
    product_type,
    showOptions,
    showRating,
    showPrice,
    showQuantity,
    showCounter,
    onCountChange,
    checkboxValue,
    showCheckbox,
    onCheckboxChange,
    showRefundButton,
    orderNumber,
    showRefundStatus,
    showReasons,
    forceVendorVariant,
  } = props;

  return (
    <Layout
      checkbox={showCheckbox ? (
        <Checkbox xl secondary checked={checkboxValue} onChange={onCheckboxChange} fullRound />
      ) : (null)}
      counter={showCounter ? (
        <OrderCounter
          initialCount={remaining_request_quantity}
          onChange={onCountChange}
          disabled={!checkboxValue}
        />
      ) : null}
      itemImage={
        <ItemImage width={[7.5, 10]} height={[7.5, 10]} src={imageURL(images_attachments)} />
      }
      itemName={(
        <ExternalLink url={urlFor('getProductDetailsLink', [
          slug,
          vendor_variants_count,
          vendor,
          forceVendorVariant,
        ])}
        >
          <Label semiBold important size={['sm', 'md']}>
            <Shiitake lines={2} throttleRate={200}>
              {name}
            </Shiitake>
          </Label>
        </ExternalLink>
      )}
      itemOptions={showOptions ? (
        <ItemOptions spaceBetween={0.5}>
          {option_values
            && option_values.map(option => (
              <ItemOption key={`li_${option.id}`}>{option.presentation}</ItemOption>
            ))}
        </ItemOptions>
      ) : undefined}
      itemQuantity={showQuantity ? (
        <Fragment>
          <Label size={['sm', 'md']} primary>
            {quantity}
          </Label>
          <Spacer size={0.3} />
          <Label size={['sm', 'md']} emphasized>
            {i18next.t('ecommerceOrder:order.items')}
          </Label>
        </Fragment>
      ) : null}
      itemRating={showRating ? (
        <Link
          to={`${urlFor('getProductDetailsLink', [
            slug,
            vendor_variants_count,
            vendor,
            forceVendorVariant,
          ])}#rating`}
        >
          <Rating rating={avg_rating} ratingCount={rating_count} />
        </Link>
      ) : undefined}
      refundButton={
        (eligible_refund && showRefundButton && product_type === 'Good') ? (
          <Link to={urlFor('getRefundItemLink', [orderNumber, ref_id])}>
            <Label md>
              <i className="el-icon el-icon-refund" />
              {' '}
              {i18next.t('ecommerceOrder:refund.refundItem')}
            </Label>
          </Link>
        ) : null
      }
      cancelButton={
        (eligible_refund && showRefundButton && product_type === 'Service') ? (
          <Link to={urlFor('getCancelServiceLink', [orderNumber, ref_id])}>
            <Label md>
              <i className="el-icon el-icon-cancel" />
              {' '}
              {i18next.t('ecommerceOrder:refund.cancelService')}
            </Label>
          </Link>
        ) : null
      }
      refundReasons={showReasons ? (
        <RefundReasons
          refundReason={refund_request_reason?.main_reason}
          detailedReason={refund_request_reason?.name}
          myNote={comment}
          shopNote={admin_comment}
          refundAttachement={refund_attachment.url_small}
        />
      ) : null}
      refundStatus={showRefundStatus ? (
        <Label primary md>{refund_status}</Label>
      ) : null}
      itemPrice={showPrice ? (
        <Label lg important>{`${price} ${currentCurrency()}`}</Label>
      ) : undefined}
    />
  );
};

LineItem.defaultProps = {
  showOptions: true,
  showRating: true,
  showQuantity: true,
  showPrice: false,
  showCounter: false,
  onCountChange: null,
  vendor: null,
  checkboxValue: false,
  showCheckbox: false,
  onCheckboxChange: null,
  orderNumber: null,
  product_type: null,
  showRefundButton: true,
  showRefundStatus: false,
  showReasons: false,
  forceVendorVariant: true,
};

LineItem.propTypes = {
  lineItem: PropTypes.shape({}).isRequired,
  vendor: PropTypes.shape({}),
  product_type: PropTypes.string,
  showOptions: PropTypes.bool,
  showRating: PropTypes.bool,
  showQuantity: PropTypes.bool,
  showPrice: PropTypes.bool,
  showCounter: PropTypes.bool,
  onCountChange: PropTypes.func,
  checkboxValue: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  orderNumber: PropTypes.string,
  showRefundButton: PropTypes.bool,
  showReasons: PropTypes.bool,
  showRefundStatus: PropTypes.bool,
  forceVendorVariant: PropTypes.bool,
};

export default LineItem;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import i18next from 'i18next';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';

import Loader from '~/modules/coreUI/components/basic/Loader';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';

import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';

import TwitterAuthQuery, { variables } from './TwitterAuthQuery';

const TwitterAuth = (props) => {
  const onLoginSuccess = (user) => {
    const { history, saveSocialMediaData } = props;

    saveSocialMediaData(user);

    navigateToModal(history, '/accountManagement/socialMediaLanding');
  };

  const extractUserInfo = () => {
    const user = {
      ...props.queryResult.twitter_authenticate,
      provider: 'twitter',
    };

    const name = user.name || ' ';
    const [firstPart, ...secondPart] = name.split(' ');
    const lastName = secondPart.join(' ');
    user.name = name || '';
    user.firstName = firstPart || '';
    user.lastName = lastName || '';

    return user;
  };

  const showErrors = (errors) => {
    const errorMessage = errors[0].messages.length
      ? errors[0].messages[0]
      : i18next.t('accountManagement:SocialMedia.ErrorinConnectingtoTwitter');
    props.notifyAlert({ messageText: errorMessage, type: AlertTypes.error, toastID: 'TwitterAuth_error_toast' });
  };

  // Extract Query Result Data
  const { errors } = props.queryResult.twitter_authenticate;
  if (errors && errors.length) {
    showErrors(errors);
  } else {
    const user = extractUserInfo();

    onLoginSuccess(user);
  }

  return (
    <Column fullWidth fullHeight centerJustified>
      <Loader />
    </Column>
  );
};

TwitterAuth.propTypes = {
  saveSocialMediaData: PropTypes.func.isRequired,
  notifyAlert: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  queryResult: PropTypes.shape({
    twitter_authenticate: PropTypes.shape({
      errors: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default withAlertMsg(
  withUserInfo(
    withRouter(
      withRootQuery(
        TwitterAuth,
        TwitterAuthQuery,
        null,
        props => variables(props.match.params),
      ),
    ),
  ),
);

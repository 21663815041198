const CompareTrans = {
  En: {
    search: 'Search',
    title: 'Compare',
    subTitle: 'Feel free to compare between items',
    addToCompareLabel: 'Add Item to Compare',
    startFromLabel: 'Start from',
    errorMessage: 'You can compare between 4 products only',
  },
  Ar: {
    search: 'بحث',
    title: 'مقارنة',
    subTitle: 'لا تتردد في المقارنة بين العناصر',
    addToCompareLabel: 'أضف منتج للمقارنة',
    startFromLabel: 'يبدأ من',
    errorMessage: 'يمكنك المقارنة بين ٤ منتجات فقط',
  },
};
export default CompareTrans;

import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { translate } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import DepartmentsTab from './DepartmentsTab';

const Button = withDirection(styled.button`
  width: 61px;
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  background-color: ${props => props.theme.colors.primary};
  font-size: 20px;
  border: none;
  ${props => (props.direction === 'rtl'
    ? css`
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
        `
    : css`
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        `)}
  cursor: pointer;
  align-items: center;
  outline: none;
`);

const iconStyle = {
  marginRight: 0,
  verticalAlign: 'middle',
};

class DropDownBurger extends Component {
  state = {
    showDepartments: false,
  };

  toggleDepartments = () => {
    this.setState(prevState => ({ showDepartments: !prevState.showDepartments }));
  };

  hideDepartments = () => {
    this.setState({ showDepartments: false });
  };

  render() {
    return (
      <React.Fragment>
        <Button {...this.props} onClick={this.toggleDepartments}>
          <Icon style={iconStyle} name="bars" />
        </Button>
        <DepartmentsTab
          targetContainerIsShown={this.state.showDepartments}
          clickOutSide={this.hideDepartments}
        />
      </React.Fragment>
    );
  }
}

export default translate('ecommerceCoreUI')(DropDownBurger);

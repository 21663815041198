const Reviews = {
  En: {
    rateProduct: 'Rate Product',
    rateShop: 'Rate Shop',
    thanksForRating: 'Thank you for your review!',
    review: 'Review',
    reviews: 'Reviews',
    outOf: 'out of',
    editReview: 'Edit Review',
    writeReview: 'Write a Review',
    addReview: 'Add Review',
    add: 'Add',
    edit: 'Edit',
    cancel: 'Cancel',
    purchasedOn: 'Purchased on Ayk Mall from',
    optional: '(Optional)',
    enterYourOpinion: 'Type your review',
  },
  Ar: {
    rateProduct: 'قيم المنتج',
    rateShop: 'قيم المحل',
    thanksForRating: 'نشكرك على تقييمك!',
    review: 'التقييم',
    reviews: 'التقييمات',
    outOf: 'من أصل',
    editReview: 'تعديل التقييم',
    writeReview: 'اكتب تقييمك',
    addReview: 'أضف تقييمك',
    add: 'إضافة',
    edit: 'تعديل',
    cancel: 'إلغاء',
    purchasedOn: 'تم شراؤه من أيك مول من',
    optional: '(اختياري)',
    enterYourOpinion: 'اكتب تقييمك',
  },
};

export default Reviews;

const ToolTipTrans = {
  En: {
    facebook: 'Facebook',
    twitter: 'Twitter',
    youtube: 'YouTube',
    instagram: 'Instagram',
    googlePlus: 'Google+',
    userItem: 'My Cart',
    userProfile: 'My Account',
    wishList: 'My Wishlist',
    follow: 'Follow',
    following: 'Following',
    wishListCardAdd: 'Add to Wishlist',
    wishListCardRemove: 'Remove from Wishlist',
    compare: 'Compare',
    notification: 'Notifications & Messages',
    megaMenuIcon: 'All Departments',
    search: 'Search',
    newsletterButton: 'Join Now',
    newsletterPlaceHolder: 'Add your email here',
    shareToGooglePlus: 'Share on Google Plus',
    shareToTwitter: 'Share on Twitter',
    shareToFacebook: 'Share on Facebook',
    list: 'List',
    grid: 'Grid',
  },
  Ar: {
    facebook: 'فيسبوك',
    twitter: 'تويتر',
    youtube: 'يوتيوب',
    instagram: 'انستجرام',
    googlePlus: 'جوجل+',
    userItem: 'عربتي',
    userProfile: 'حسابي',
    wishList: 'قائمة رغباتي',
    follow: 'تابع',
    following: 'متابَع',
    wishListCardAdd: 'أضف إلى قائمة الرغبات',
    wishListCardRemove: ' احذف من قائمة الرغبات',
    compare: 'قارن',
    notification: 'الإشعارات والرسائل',
    megaMenuIcon: 'جميع الأقسام',
    search: 'بحث',
    newsletterButton: 'انضم الآن',
    newsletterPlaceHolder: 'أضف بريدك الإلكتروني هنا',
    shareToGooglePlus: 'مشاركة على جوجل+',
    shareToTwitter: 'مشاركة على تويتر',
    shareToFacebook: 'مشاركة على فيسبوك',
    list: 'قائمة',
    grid: 'شبكة',
  },
};
export default ToolTipTrans;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,
 jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

import { responsiveFontSizeStyle, infereFontColor } from '~/modules/coreUI/utils/infereStyle';


const StyledIcon = styled.i`
  cursor: pointer;
  ${props => !props.inheritColor
    && css`
      color: ${infereFontColor(props)};
    `};
  ${props => !props.inheritSize
    && css`
      ${responsiveFontSizeStyle(props)};
    `};
`;

const Icon = props => <StyledIcon {...props} className={props.className} onClick={props.onClick} />;

Icon.propTypes = PropTypes.shape({
  className: PropTypes.string.isRequired,
}).isRequired;

export default withMedia(Icon);

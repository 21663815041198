import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { SmallLabel } from '~/modules/coreUI/components/legacies/Labels';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Icon from '~/modules/coreUI/components/basic/Icon';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const TabPanel = styled(Column)`
  background-color: ${props => props.theme.colors.named.white};
  border: ${props => props.theme.borders.size.normal}px dashed ${props => props.theme.new.colors.labels.borders.normal.normal};
  border-radius: ${props => props.theme.borders.radius.small - 1}px;
  width: ${props => 8.5 * props.theme.new.spacer}px;
  height: ${props => 8 * props.theme.new.spacer}px;
  ${props => cssMediaMax.mobile`
    height: ${6.8 * props.theme.new.spacer}px;
    span {
      font-size: ${props.theme.new.fonts.sizes.xs}px;
    }
  `}
  cursor: pointer;
  span {
    text-align: center !important;
  }
  &:hover,
  &&.active {
    background-color: ${props => props.theme.colors.secondary};
    border: ${props => props.theme.borders.size.normal}px solid ${props => props.theme.colors.secondary};
    i,
    span {
      color: ${props => props.theme.colors.named.white};
    }
  }
`;
const TabPanelItem = ({
  text, icon, onClick, className,
}) => (
  <TabPanel className={className} onClick={onClick} centerAligned centerJustified spaceBetween={0.4}>
    <Icon className={`el-icon el-icon-${icon}`} color="rgba(0, 0, 0, 0.7)" size={2.2} />
    <SmallLabel>{text}</SmallLabel>
  </TabPanel>
);

TabPanelItem.defaultProps = {
  className: null,
};

TabPanelItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default TabPanelItem;

import React from 'react';
import styled from 'react-emotion';
import { Route, Switch } from 'react-router-dom';

import Newsletter from '../../ecommerceCoreUI/components/footer/Newsletter';
import FooterContainer from '../../ecommerceCoreUI/components/footer/FooterContainer';
import InnerPageFooter from '../../ecommerceCoreUI/components/innerFooter/InnerPageFooter';
import Copyright from '../../ecommerceCoreUI/components/footer/Copyright';

const FooterConatiner = styled.div`
  background: ${props => props.theme.colors.named.white};
`;

const CenteredContainer = styled.div`
  text-align: center;
`;

export const Footer = () => (
  <FooterConatiner>
    <Newsletter />
    <FooterContainer />
    <Copyright />
  </FooterConatiner>
);

export const InnerFooter = () => (
  <React.Fragment>
    <InnerPageFooter />
    <CenteredContainer>
      <Copyright />
    </CenteredContainer>
  </React.Fragment>
);

export const FooterContent = () => (
  <Switch>
    <Route exact path="/:locale(ar|en)?/CheckoutSteps/:stepName" component={InnerFooter} />
    <Route component={Footer} />
  </Switch>
);

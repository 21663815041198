import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import { css } from 'emotion';
import { Trans } from 'react-i18next';

import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import Icon from '~/modules/coreUI/components/basic/Icon';
import {
  CenterAlignedColumn,
  LeftAlignedColumn,
} from '~/modules/coreUI/components/legacies/Columns';
import {
  SocialMediaNameLabel,
  SocialMediaPromptLabel,
} from '~/modules/accountManagement/components/basic/Labels';
import { XXSmallSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

// TODO: Create one component for all these
const SocialMediaButtonContainer = withDirection(styled(Row)`
  width: 117px;
  height: 35px;

  color: ${props => props.theme.colors.named.white};
  background-color: ${props => props.background};

  font-size: ${props => props.theme.fonts.sizes.xxxSmall}px;
  font-weight: ${props => props.theme.fonts.weights.semiBold};

  border-radius: 3px;

  text-align: center;

  ${cssMediaMax.tablet`
    width: 35px;
    height: 35px;
  `}

  cursor: pointer;
  justify-content: center;
`);

const SocialMediaNameContainer = styled(LeftAlignedColumn)`
  color: white;
  line-height: ${props => props.theme.fonts.sizes.xxxSmall}px;

  ${cssMediaMax.tablet`
    display:none;
  `};
`;

const LeftIcon = withDirection(styled(CenterAlignedColumn)`
  width: 28px;
  font-size: 23px;

  @media (max-width: 1024px) {
    font-size: 27px;
    margin: auto;
    ${props => (props.direction === 'rtl'
    ? css`
        padding-right: ${props.theme.paddings.xSmall + 1}px;
      `
    : css`
        padding-left: ${props.theme.paddings.xSmall + 1}px;
    `)}
  }
`);

const SocialMediaButton = ({
  triggerLogin, socialMedia, beforeLoginAction, ...props
}) => {
  const handleButtonClick = () => {
    beforeLoginAction();
    triggerLogin();
  };

  return (
    <SocialMediaButtonContainer
      key={socialMedia.name}
      background={socialMedia.background}
      onClick={handleButtonClick}
      {...props}
    >
      <LeftIcon>
        <Icon className={socialMedia.icon} color="white" size={['xxl', '22']} />
      </LeftIcon>
      <Spacer size={0.3} />
      <SocialMediaNameContainer>
        <SocialMediaPromptLabel>
          <Trans i18nKey="LogInWith" />
        </SocialMediaPromptLabel>
        <XXSmallSpacer />
        {/* <SocialMediaNameLabel>{i18next.t(`accountManagement:SocialMedia.${socialMedia.name}`)}</SocialMediaNameLabel> */}
        <SocialMediaNameLabel>{i18next.t(socialMedia.key)}</SocialMediaNameLabel>
      </SocialMediaNameContainer>
    </SocialMediaButtonContainer>
  );
};

SocialMediaButton.propTypes = {
  triggerLogin: PropTypes.func.isRequired,
  beforeLoginAction: PropTypes.func.isRequired,
  socialMedia: PropTypes.shape({}).isRequired,
};

export default SocialMediaButton;

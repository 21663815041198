import { graphql } from 'relay-runtime';

const EmployerQuery = graphql`
  query EmployerQuery {
    employers {
      ref_id
      name
      id
    }
  }
`;


export default EmployerQuery;

import React from 'react';
import i18next from 'i18next';

import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';

const EmptyNotifications = () => (
  <Column>
    <Spacer size={7} />
    <img src={`/${imagesPathName}/notifications.png`} alt="notifications" />
    <Spacer size={2.5} />
    <Label lg important>{i18next.t('ecommerceOrder:notificationsAndMessages.emptyNotifications')}</Label>
  </Column>
);

export default EmptyNotifications;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled, { css } from 'react-emotion';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { XSmallLabel } from '~/modules/coreUI/components/basic/Labels';

const OfferLabelContainer = withMedia(styled(Box)`
  width: ${props => 6 * props.theme.new.spacer}px;
  height: ${props => 5 * props.theme.new.spacer}px;
  ${props => props.media.xsmall && css`
    width: ${3.8 * props.theme.new.spacer}px;
    height: ${3.3 * props.theme.new.spacer}px;
  `};
`);
const XSmallLabelStyle = withMedia(styled(XSmallLabel)`
  ${props => props.media.xsmall && css`
    font-size: ${props.theme.fonts.sizes.xxSmall - 1}px;
  `};
`);
const CircularProgressbarStyle = styled(CircularProgressbar)`
  .CircularProgressbar-path { stroke: #03e22d; }
  .CircularProgressbar-trail { stroke: #faf9f9; }
  .CircularProgressbar-text { fill: #000; font-weight: 600; }
  .CircularProgressbar-background { fill: green; }
`;

const ItemStockAmount = ({ itemInfo }) => (
  <Column spaceBetween={0.5}>
    <OfferLabelContainer>
      <CircularProgressbarStyle
        percentage={itemInfo.remaining_usage_percentage}
        text={`${itemInfo.remaining_usage_percentage}%`}
        strokeWidth={15}
        styles={{
          path: {
            // Tweak path to use flat or rounded ends:
            strokeLinecap: 'butt',
            // Tweak transition animation:
            transition: 'stroke-dashoffset 0.5s ease 0s',
          },
        }}
      />
    </OfferLabelContainer>
    <XSmallLabelStyle>{i18next.t('ecommerceCoreUI:Stock.in')}</XSmallLabelStyle>
  </Column>
);

ItemStockAmount.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default ItemStockAmount;

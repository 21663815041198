/**
 * @flow
 * @relayHash 6a6c4b6fa4667105664a3ef4dd009536
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PasswordInfoQuery = {
  current_password?: ?string,
  new_password?: ?string,
  password_confirmation?: ?string,
};
export type ChangeUserPasswordMutationVariables = {|
  input: PasswordInfoQuery
|};
export type ChangeUserPasswordMutationResponse = {|
  +update_user_password: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>
  |}
|};
export type ChangeUserPasswordMutation = {|
  variables: ChangeUserPasswordMutationVariables,
  response: ChangeUserPasswordMutationResponse,
|};
*/


/*
mutation ChangeUserPasswordMutation(
  $input: PasswordInfoQuery!
) {
  update_user_password(input: $input) {
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PasswordInfoQuery!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "update_user_password",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "PasswordInfoQuery"
      }
    ],
    "concreteType": "PasswordInfoResult",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "Error",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ChangeUserPasswordMutation",
  "id": null,
  "text": "mutation ChangeUserPasswordMutation(\n  $input: PasswordInfoQuery!\n) {\n  update_user_password(input: $input) {\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ChangeUserPasswordMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ChangeUserPasswordMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6805f62ac319eb0c13ee7447b4a7e200';
module.exports = node;

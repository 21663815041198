/* eslint-disable camelcase */
import React, { Component } from 'react';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { graphql } from 'react-relay';

import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { PathRedirectKeysMapper } from '~/modules/core/utils/relayHelpers/RelayProvider';
import { navigateToModal } from '~/modules/core/utils/modalHelpers';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

class NavWishList extends Component {
  state = {};

  openWishList = () => {
    const { authenticated, history } = this.props;

    if (authenticated) {
      history.push(urlFor('accountSettings', ['wishlist']));
    } else {
      this.props.saveCurrentPath(
        PathRedirectKeysMapper.showSuccess,
        false,
        urlFor('accountSettings', ['wishlist']),
        true,
      );
      navigateToModal(history, '/accountManagement/login');
    }
  };

  getIconName = () => {
    if (this.props.queryResult?.current_user?.wished_variants.length > 0) {
      return 'el-icon el-icon-heart-filled';
    }

    return 'icon-heart-1';
  }

  render() {
    return (
      <IconOnlyButton
        primary
        iconName={this.getIconName()}
        title={i18next.t('ecommerceCoreUI:ToolTipTrans.wishList')}
        size={28}
        onClick={this.openWishList}
      />
    );
  }
}

NavWishList.propTypes = PropTypes.shape({
  authenticated: PropTypes.boolen,
  saveCurrentPath: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
}).isRequired;

export default withUserInfo(withRouter(withRootQuery(
  NavWishList,
  graphql`
    query NavWishListQuery {
      current_user{
        ...WishData_variantInfo @relay(mask: false)
      }
    }
  `,
  NavWishList,
  null,
  { force: true },
)));

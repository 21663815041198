import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { responsiveStyle, infereSpaceSize } from '~/modules/coreUI/utils/infereStyle';
import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

export const StyledSpacer = withMedia(styled.div`
  flex-grow: ${props => (props.grow ? 1 : null)};

  ${props => responsiveStyle(
    props,
    'size',
    size => css`
        min-width: ${infereSpaceSize(props, size)};
        min-height: ${infereSpaceSize(props, size)};
      `,
  )};

  ${props => responsiveStyle(
    props,
    'width',
    width => css`
        width: ${infereSpaceSize(props, width)};
      `,
  )};

  ${props => responsiveStyle(
    props,
    'height',
    height => css`
        height: ${infereSpaceSize(props, height)};
      `,
  )};

  ${props => responsiveStyle(
    props,
    'minWidth',
    width => css`
        min-width: ${infereSpaceSize(props, width)};
      `,
  )};

  ${props => responsiveStyle(
    props,
    'minHeight',
    height => css`
        max-height: ${infereSpaceSize(props, height)};
      `,
  )};
`);

const Spacer = props => <StyledSpacer {...props} />;

Spacer.defaultProps = {
  size: 1,
};

Spacer.propTypes = PropTypes.shape({
  size: PropTypes.number,
}).isRequired;

export default Spacer;

export const HSpacer = props => <Spacer minHeight="1px" {...props} />;

export const VSpacer = props => <Spacer minWidth="1px" {...props} />;

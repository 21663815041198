import React, { Component } from 'react';
import styled from 'react-emotion';
import { translate } from 'react-i18next';
import Media from 'react-media';

import { CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import {
  cssMediaMin,
  cssMediaMax,
  mediaQueryMin,
  mediaQueryMax,
} from '~/modules/core/utils/cssHelpers/cssMedia';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import Panel from '~/modules/accountManagement/components/basic/Panel';
import { scrollCurrentModalToTop } from '~/modules/core/utils/modalHelpers';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import LoginFormPanel from './LoginPanel';
import SignupPromptPanel from './SignupPromptPanel';

const PanelContent = styled(CenterAlignedColumn)`
  ${cssMediaMin.desktop`
    height: 300px;
  `} width: 100%;
  justify-content: flex-end;
`;

const PanelContainer = styled(Panel)`
  flex-grow: 0;

  ${cssMediaMax.tablet`
    flex-grow: 1;
  `};
`;

const SeparatorContainer = styled(Separator)`
  margin-top: 77px;
`;

const RootContainer = styled(CenterAlignedRow)`
  align-self: stretch;
  justify-content: space-around;
  align-items: stretch;
  flex-grow: 2;

  ${cssMediaMax.tablet`
    flex-direction: column;
    align-items: center;
  `};
`;

class LoginContainer extends Component {
  componentDidMount() {
    scrollCurrentModalToTop();
  }

  renderSeparator = () => (
    <Media
      query={mediaQueryMin('desktop')}
      render={() => <SeparatorContainer vertical offset="100px" separatorLength="large" />}
    />
  );

  render = () => (
    <RootContainer>
      <LoginFormPanel panelContentContainer={PanelContent} panelContainer={PanelContainer} />
      {this.renderSeparator()}
      <Media query={mediaQueryMax('tablet')} render={() => <Spacer size={2} />} />
      <SignupPromptPanel panelContentContainer={PanelContent} panelContainer={PanelContainer} />
    </RootContainer>
  );
}

export default translate('accountManagement')(LoginContainer);

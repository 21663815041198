import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import { Header } from '~/modules/coreUI/components/basic/Labels';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const CardWrapper = styled.div`
  height: ${props => props.theme.new.spacer * 16.4}px;
  width: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 11px 16px;
  border-radius: ${props => props.theme.borders.radius.normal}px;
  overflow: hidden;

`;

const headerCustomStyle = props => css`
  font-size: ${props.theme.new.fonts.sizes.header + 4}px;
  color: ${props.color};
  text-transform: capitalize;
`;
const Button = styled.button`
  text-transform: capitalize;
  text-align: left;
  font-size: 14px;
  border-radius: ${props => props.theme.borders.radius.small - 1}px;
  color: ${props => props.color};
  background-color: ${props => props.bgColor};
  border: none;
  padding: 3px 10px;
  cursor: pointer;
`;
const ExternalLinkStyle = styled(ExternalLink)`
  display: block;
  width: 100%;
`;
const AdFeaturedShopCard = props => (
  <ExternalLinkStyle url={props.url} target="_self">
    <CardWrapper image={props.large_photo_object.url_original}>
      <Column leftAligned>
        {props.title && (
          <Header color={props.title_color} customStyle={headerCustomStyle}>{props.title}</Header>
        )}
        {props.button_text && (
          <Button
            color={props.button_text_color}
            bgColor={props.button_color}
          >
            {props.button_text}
          </Button>
        )}
      </Column>
    </CardWrapper>
  </ExternalLinkStyle>
);

AdFeaturedShopCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  button_text: PropTypes.string,
  title_color: PropTypes.string,
  button_color: PropTypes.string,
  button_text_color: PropTypes.string,
  large_photo_object: PropTypes.shape({
    url_original: PropTypes.string,
  }),
};

AdFeaturedShopCard.defaultProps = {
  title: null,
  url: null,
  button_text: null,
  title_color: null,
  button_color: null,
  button_text_color: null,
  large_photo_object: null,
};

export default AdFeaturedShopCard;

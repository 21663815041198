import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const LogoWrapper = styled(Row)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const WheelLogo = ({ direction }) => (
  <LogoWrapper centerJustified>
    <img
      src={
      direction === 'rtl'
        ? `/${imagesPathName}/logo-circled-ar.png`
        : `/${imagesPathName}/logo-circled.png`
      }
      alt="logo"
    />
  </LogoWrapper>
);

WheelLogo.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default withDirection(WheelLogo);

import { graphql } from 'relay-runtime';

const CurrentUserQuery = graphql`
  query CurrentUserQuery {
    current_user_details{
      current_user {
        ...UserInfoFragment @relay(mask: false)
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;


export default CurrentUserQuery;

/**
 * @flow
 * @relayHash 59c92fd15d88baf441408e06b3e7f917
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DepartmentTabPanContent_departmentInfo$ref = any;
export type DepartmentsTabQueryVariables = {||};
export type DepartmentsTabQueryResponse = {|
  +taxons: $ReadOnlyArray<?{|
    +id: string,
    +ref_id: string,
    +name: string,
    +$fragmentRefs: DepartmentTabPanContent_departmentInfo$ref,
  |}>
|};
export type DepartmentsTabQuery = {|
  variables: DepartmentsTabQueryVariables,
  response: DepartmentsTabQueryResponse,
|};
*/


/*
query DepartmentsTabQuery {
  taxons(type: department) {
    id
    ref_id
    name
    ...DepartmentTabPanContent_departmentInfo
  }
}

fragment DepartmentTabPanContent_departmentInfo on Taxon {
  name
  products_count
  mega_dropdown_description
  mega_dropdown_image
  ref_id
  children(limit: 6) {
    ...SubDepartmentsList_subDepartmentInfo
    id
  }
  top_brands {
    ...DepartmentBrands_topBrands
    id
  }
}

fragment SubDepartmentsList_subDepartmentInfo on Taxon {
  name
  children {
    ...TaxonLink_taxonInfo
    id
  }
}

fragment DepartmentBrands_topBrands on Taxon {
  ...TaxonLink_taxonInfo
}

fragment TaxonLink_taxonInfo on Taxon {
  id
  ref_id
  name
  type
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "department",
    "type": "TaxonKind"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  v1,
  v2,
  v3,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DepartmentsTabQuery",
  "id": null,
  "text": "query DepartmentsTabQuery {\n  taxons(type: department) {\n    id\n    ref_id\n    name\n    ...DepartmentTabPanContent_departmentInfo\n  }\n}\n\nfragment DepartmentTabPanContent_departmentInfo on Taxon {\n  name\n  products_count\n  mega_dropdown_description\n  mega_dropdown_image\n  ref_id\n  children(limit: 6) {\n    ...SubDepartmentsList_subDepartmentInfo\n    id\n  }\n  top_brands {\n    ...DepartmentBrands_topBrands\n    id\n  }\n}\n\nfragment SubDepartmentsList_subDepartmentInfo on Taxon {\n  name\n  children {\n    ...TaxonLink_taxonInfo\n    id\n  }\n}\n\nfragment DepartmentBrands_topBrands on Taxon {\n  ...TaxonLink_taxonInfo\n}\n\nfragment TaxonLink_taxonInfo on Taxon {\n  id\n  ref_id\n  name\n  type\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DepartmentsTabQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "taxons",
        "storageKey": "taxons(type:\"department\")",
        "args": v0,
        "concreteType": "Taxon",
        "plural": true,
        "selections": [
          v1,
          v2,
          v3,
          {
            "kind": "FragmentSpread",
            "name": "DepartmentTabPanContent_departmentInfo",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DepartmentsTabQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "taxons",
        "storageKey": "taxons(type:\"department\")",
        "args": v0,
        "concreteType": "Taxon",
        "plural": true,
        "selections": [
          v1,
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "products_count",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "mega_dropdown_description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "mega_dropdown_image",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "children",
            "storageKey": "children(limit:6)",
            "args": [
              {
                "kind": "Literal",
                "name": "limit",
                "value": 6,
                "type": "ID"
              }
            ],
            "concreteType": "Taxon",
            "plural": true,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "children",
                "storageKey": null,
                "args": null,
                "concreteType": "Taxon",
                "plural": true,
                "selections": v4
              },
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "top_brands",
            "storageKey": null,
            "args": null,
            "concreteType": "Taxon",
            "plural": true,
            "selections": v4
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75d15e6e7478a055c53412d2c268827c';
module.exports = node;

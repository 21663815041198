import React from 'react';
import PropTypes from 'prop-types';
import {
  SectionHeader,
  SectionHeaderSubtitle,
} from '~/modules/ecommerceCoreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import { XLargeSpacer, XXLargeSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import ProductsList from './ProductsList';

const ProductsListPage = ({ headerTitle, headerSubtitle }) => (
  <CenterAlignedColumn>
    <XLargeSpacer />
    <XLargeSpacer />
    <SectionHeader>{headerTitle}</SectionHeader>
    <Spacer size={0.5} />
    <SectionHeaderSubtitle>{headerSubtitle}</SectionHeaderSubtitle>
    <XXLargeSpacer />
    <ProductsList />
  </CenterAlignedColumn>
);

ProductsListPage.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerSubtitle: PropTypes.string.isRequired,
};

export default ProductsListPage;

/* eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';
import _ from 'lodash';

import { cssMediaMin, cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import { Column, CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';
import { PanelTitle, PanelSubtitle } from '~/modules/accountManagement/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';

export const PanelRoot = styled(Column)`
  width: ${props => props.panelWidth || '252px'};
  height: 100%;
  flex-grow: 1;
`;

const PanelHeaderContainer = styled(CenterAlignedColumn)`
  ${props => props.customStyle && props.customStyle(props)};
`;

const defaultHeaderContainerStyleMobile = props => css`
  min-width: 327px;

  padding-right: ${props.theme.paddings.large}px;
  padding-left: ${props.theme.paddings.large}px;
  padding-top: ${props.theme.paddings.xxLarge}px;
  padding-bottom: ${props.theme.paddings.large}px;

  border-bottom-right-radius: ${props.theme.borders.radius.large}px;
  border-bottom-left-radius: ${props.theme.borders.radius.large}px;
`;

const defaultHeaderContainerStyle = props => css`
  ${defaultHeaderContainerStyleMobile(props)};

  ${cssMediaMin.desktop`
    background-color: ${props.theme.colors.backgroundColor};
  `};
`;

const defaultHeaderTitleStyle = props => css`
  font-size: ${props.theme.fonts.sizes.xLarge}px;
  color: black;
  white-space: nowrap;
  ${cssMediaMax.tablet`
    font-size: ${props.theme.fonts.sizes.large + 2}px;
  `};
`;

const defaultHeaderSubtitleStyle = props => css`
  font-size: ${props.theme.fonts.sizes.xSmall}px;
  white-space: nowrap;
`;

const filterLegacyErrors = (errors) => {
  if (!errors || !errors.generalErrors) {
    if (_.isString(errors)) {
      return errors;
    }
    return null;
  }
  const KNOWN_LEGACY_ERRORS = ['email'];

  if (_.isArray(errors.generalErrors)) {
    return errors.generalErrors;
  } else if (_.isObject(errors.generalErrors)) { // eslint-disable-line no-else-return
    const validErrors = _.keys(errors.generalErrors)
      .filter(errorKey => KNOWN_LEGACY_ERRORS.includes(errorKey) === false);
    if (validErrors.length === 0) {
      return null;
    }
    return _.pick(errors, validErrors);
  }
  return errors.generalErrors;
};

const PanelBody = styled(Column)`
  ${props => props.customStyle && props.customStyle(props)};
`;

const Panel = (props) => {
  const {
    title,
    subTitle,
    titleLabel,
    subTitleLabel,
    showHeader,
    error,
    children,
    headerTitleStyle,
    headerSubtitleStyle,
    panelBodyStyle,
  } = props;

  return (
    <PanelRoot {...props} topAligned topJustified>
      {showHeader && (
        <PanelHeaderContainer customStyle={defaultHeaderContainerStyle}>
          <PanelTitle customStyle={headerTitleStyle || defaultHeaderTitleStyle}>
            {title || titleLabel}
          </PanelTitle>
          {(subTitle || subTitleLabel) && (
            <React.Fragment>
              <Spacer size={0.5} />
              <PanelSubtitle customStyle={headerSubtitleStyle || defaultHeaderSubtitleStyle}>
                {subTitle || subTitleLabel}
              </PanelSubtitle>
            </React.Fragment>
          )}
        </PanelHeaderContainer>
      )}
      <Spacer />
      {filterLegacyErrors(error) && (
        <PanelAlert type="error" borderRadius="5" inverted errors={error} />
      )}

      <PanelBody customStyle={panelBodyStyle} fullWidth centerAligned centerJustified grow>
        {children}
      </PanelBody>
    </PanelRoot>
  );
};

Panel.propTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  titleLabel: PropTypes.string.isRequired,
  subTitleLabel: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  error: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}).isRequired;

Panel.defaultProps = {
  showHeader: true,
};

export default Panel;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const Logo = styled.i`
  font-size: 44px;
  padding: ${props => props.theme.paddings.large}px;
  color: ${props => props.color};
  display: inline-block;
  opacity: 0.3;
  ${props => cssMediaMax.xsmall`
    font-size: 24px;
    padding: ${props.theme.paddings.medium}px;
  `}
`;

const CardLogo = ({ color, direction }) => (
  <div>
    {direction === 'rtl' ? (
      <Logo color={color} className="el-icon el-icon-logo-ar" />
    ) : (
      <Logo color={color} className="el-icon el-icon-logo-en" />
    )}
  </div>
);

CardLogo.propTypes = {
  color: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
};
export default withDirection(CardLogo);

import React from 'react';
import styled from 'react-emotion';
import Media from 'react-media';

import { CenterAlignedRow } from '~/modules/coreUI/components/legacies/Rows';
import { cssMediaMin, cssMediaMax, mediaQueryMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import { LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import NavSearch from './NavSearch';

const FlexSpacer = styled.div`
  ${cssMediaMin.desktop`
     flex-grow: 1;
  `};
`;

const CenterContent = styled(CenterAlignedRow)`
  flex-grow: 10;
`;

const MainContent = styled(CenterAlignedRow)`
  flex-grow: 1;
  ${props => cssMediaMax.tablet`
    padding: ${props.theme.paddings.large}px;
    .dropdown{
      .menu{
        width: 100%;
      }
    }
  `} ${cssMediaMax.mobile`
   .dropdown{
      padding: 11px;
      justify-content: space-between !important;
    }
  `};
`;
const ProductsSearch = styled(LinearLayout)`
  width: 100%;
  ${props => cssMediaMin.tablet`
    border: 1px solid ${props.theme.borders.color.light};
    border-radius: ${props.theme.borders.radius.normal}px;
  `};
`;

const NavProductsSearch = () => (
  <MainContent>
    <FlexSpacer />
    <CenterContent>
      <Media query={mediaQueryMin('mobile')}>
        {isOnMobile => (
          <ProductsSearch row={isOnMobile}>
            <NavSearch />
          </ProductsSearch>
        )}
      </Media>
    </CenterContent>
    <FlexSpacer />
  </MainContent>
);

export default NavProductsSearch;

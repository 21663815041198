import React from 'react';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import _ from 'lodash';

import InputSelect from '~/modules/coreUI/components/basic/select/InputSelect';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';

class CountriesDropdown extends React.Component {
  getSortedCountries = () => (!this.props.queryResult
    ? []
    : this.props.queryResult.list_countries
      .slice()
      .sort((c1, c2) => c1.name.localeCompare(c2.name)));

  getCountriesOptions = () => this.getSortedCountries()
    .filter(country => !this.props.deliverableOnly || country.accept_delivery_and_shipping)
    .map(country => ({
      label: country.name,
      image: <Image src={country.flag} alt={country.flag} />,
      value: country.ref_id,
      states_required: country.states_required,
      attrs: {
        callingCodes: country.phone_code,
      },
    }));

  getSelectedCountry = selectedCountryId => _.find(this.getCountriesOptions(), country => country.value === selectedCountryId);

  render = () => {
    const {
      value, tabIndex, placeholder, onChange,
    } = this.props;
    const CountriesOptions = this.getCountriesOptions();
    return (
      <InputSelect
        showInput={false}
        value={value.country_id}
        tabIndex={tabIndex}
        isLoading={this.props.isLoading}
        onChange={newValue => onChange({
          country_id: newValue,
          country_code: this.getSelectedCountry(newValue).attrs.callingCodes,
        })}
        showImageOnButton={false}
        getSelectedItemLabel={entry => `${entry.label}`}
        getSelectedItemImage={() => null}
        placeholder={placeholder}
        options={CountriesOptions}
      />
    );
  };
}

CountriesDropdown.defaultProps = {
  value: '',
  isLoading: false,
  tabIndex: null,
  deliverableOnly: false,
  queryResult: {
    list_countries: [],
  },
};

CountriesDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  deliverableOnly: PropTypes.bool,
  queryResult: PropTypes.shape({
    list_countries: PropTypes.arrayOf(
      PropTypes.shape({
        iso3: PropTypes.string,
        name: PropTypes.string.isRequired,
        ref_id: PropTypes.string.isRequired,
        flag: PropTypes.string,
        phone_code: PropTypes.string.isRequired,
        states_required: PropTypes.bool.isRequired,
      }),
    ),
  }),
};

export default withRootQuery(
  CountriesDropdown,
  graphql`
    query CountriesDropdownQuery {
      list_countries {
        iso3
        name
        ref_id
        flag
        phone_code
        accept_delivery_and_shipping
      }
    }
  `,
  CountriesDropdown,
);

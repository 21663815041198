import React from 'react';
import PropTypes from 'prop-types';

import assert from '~/modules/core/utils/jsHelpers/assert';

import AlertContext from './AlertContext';
import AlertToast from './alertComponent/AlertToast';

// TODO : Move updates to bractal

const AUTO_CLOSE_TIME_OUT = 5000;
class AlertProvider extends React.Component {
  state = {
    messageText: null,
    buttonAction: null,
    buttonText: null,
    type: null,
    icon: null,
    hidden: true,
    topFullWidth: false,
    opacity: null,
  };

  startAlert = (args) => {
    assert(!args.messageText || typeof args.messageText === 'string', 'Alert message should be of type string');
    this.setState({
      messageText: args.messageText,
      buttonAction: args.buttonAction, // TODO: SHOULD BE NAMED "autoClose"
      buttonText: args.buttonText,
      type: args.type,
      icon: args.icon,
      hidden: false,
      topFullWidth: args.topFullWidth,
      color: args.color,
      backgroundColor: args.backgroundColor,
      component: args.component,
      opacity: args.opacity,
      toastID: args.toastID,
    });

    if (!this.state.buttonAction) {
      setTimeout(() => this.setState({ hidden: true }), AUTO_CLOSE_TIME_OUT);
    }
  };

  dismiss = () => {
    if (this.state.buttonAction) {
      this.state.buttonAction();
      this.setState({ hidden: true });
    }
  };

  render = () => {
    const alertContent = (
      <AlertContext.Provider value={this.startAlert}>
        {!this.state.hidden && (
          <AlertToast
            {...this.props}
            component={this.state.component}
            messageText={this.state.messageText}
            autoClose={this.state.buttonAction ? false : AUTO_CLOSE_TIME_OUT}
            type={this.state.type}
            icon={this.state.icon}
            buttonText={this.state.buttonText}
            buttonAction={this.state.buttonAction ? this.dismiss : null}
            topFullWidth={this.state.topFullWidth}
            color={this.state.color || this.props.color}
            backgroundColor={this.state.backgroundColor || this.props.backgroundColor}
            opacity={this.state.opacity}
            toastID={this.state.toastID}
          />
        )}
        {this.props.children}
      </AlertContext.Provider>
    );

    return alertContent;
  };
}

AlertProvider.propTypes = PropTypes.shape({
  children: PropTypes.shape({}),
}).isRequired;

export default AlertProvider;

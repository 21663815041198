/* eslint-disable no-spaced-func, func-call-spacing */
import fp from 'lodash/fp';
import S from 'sanctuary';
import i18next from 'i18next';
import externalRedirect from '~/modules/ecommerceCore/utils/externalRedirect';
import { removeModalPartFromPath } from '~/modules/core/utils/modalHelpers/index';
import { prefixWith/* S_logWithPrefix */ } from '~/modules/core/utils/jsHelpers/functional'; // eslint-disable-line camelcase

const SUPPORTED_LOCALES = ['en', 'ar'];

const currentPath = () => window.location.pathname;
const currentOrigin = () => window.location.origin;

const getLocaleFromPath = path => SUPPORTED_LOCALES.find(
  locale => path.startsWith(`/${locale}`),
);

const prefixURLWith = prefix => url => (prefix ? (
  fp.flow(
    fp.replace(/^\//, ''),
    prefixWith(`/${prefix}/`),
  )(url)
) : (
  url
));

const fallbackToI18Language = locale => locale || i18next.language;
const fallbackToEnglish = locale => locale || 'en';

export const currentLocale = () => S.pipe([
  currentPath,
  getLocaleFromPath,
  fallbackToI18Language,
  fallbackToEnglish,
])(null);

export const removeLocaleFromPath = path => fp.flow(
  getLocaleFromPath,
  locale => path.replace(RegExp(`^/${locale}`), ''),
)(path);

const newLocaleReceived = locale => (locale === currentLocale() ? S.Left (null) : S.Right (locale));

const redirectToNewLocaleURL = S.curry2((location, url) => {
  externalRedirect(`${url}${location.search}`);
  return true;
});

const changeLanguage = (newLocale, location) => S.pipe([
  S.of (S.Either),
  S.chain (locale => newLocaleReceived(locale)),
  S.map (currentPath),
  S.map (removeLocaleFromPath),
  S.map (prefixURLWith (newLocale)),
  S.map (prefixWith (currentOrigin())),
  S.map (removeModalPartFromPath),
  S.map (redirectToNewLocaleURL (location)),
  S.isRight,
]) (newLocale);

export default changeLanguage;

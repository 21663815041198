import styled, { css } from 'react-emotion';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const Status = withDirection(styled.div`
  width: ${props => 0.9 * props.theme.new.spacer}px;
  height: ${props => 0.9 * props.theme.new.spacer}px;
  border-radius: 50%;
  ${props => (props.status === 'open' && css`
      background-color: #d32f2f;
  `)}
  ${props => (props.status === 'resolved' && css`
      background-color: #8cba25;
  `)}
  ${props => (props.status === 'in_progress' && css`
      background-color: #e8bf15;
  `)}
  ${props => (props.isUnread && css`
      position: absolute;
      z-index: 1;
      top: ${0.5 * props.theme.new.spacer}px;
      background-color: ${props.theme.new.colors.labels.normal.primary};
  `)}
`);

export default Status;

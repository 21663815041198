import { graphql } from 'relay-runtime';

const MarkReadMutation = graphql`
  mutation MarkReadMutation($notification_id: ID) {
    mark_as_read(notification_id: $notification_id) {
      notification {
        read
      }
      errors {
        field
        messages
        code
      }
    }
  }
`;


export default MarkReadMutation;

import React from 'react';
import PropTypes from 'prop-types';
import AykMemberShip from './AykMemberShip';
import EarnPoints from './EarnPoints';

const NoPoints = ({ queryResult }) => (
  <React.Fragment>
    <AykMemberShip queryResult={queryResult} />
    <EarnPoints />
  </React.Fragment>
);

NoPoints.propTypes = {
  queryResult: PropTypes.shape({}),
};

NoPoints.defaultProps = {
  queryResult: [],
};

export default NoPoints;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import DatePicker from 'react-date-picker';

const DatePickerWrapper = styled.div`
.react-date-picker__wrapper,
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__wrapper {
    height: ${props => 4.4 * props.theme.new.spacer}px;
    font-size: ${props => props.theme.new.fonts.sizes.sm}px;
    color: ${props => props.theme.new.colors.labels.normal.hint};
    background-color: ${props => props.theme.new.colors.labels.inverted.primary};
    padding-left: ${props => 1.5 * props.theme.new.spacer}px;
    padding-right: ${props => 1.5 * props.theme.new.spacer}px;
    outline: none;
    border: 1px solid ${props => props.theme.new.colors.labels.normal.hint};
    border-radius: ${props => 2.5 * props.theme.new.spacer}px;
    border-top-right-radius: ${props => 2.5 * props.theme.new.spacer}px;
    border-bottom-right-radius: ${props => 2.5 * props.theme.new.spacer}px;
    cursor: pointer;
  }
  .react-date-picker__inputGroup__input:invalid {
    background-color: ${props => props.theme.new.colors.labels.inverted.primary};
    outline: none;
  }
  i {
    color: ${props => props.theme.new.colors.labels.normal.hint};
    font-size: ${props => 1 + props.theme.new.fonts.sizes.lg}px;
  }
  .react-date-picker__calendar .react-calendar{
    border-radius: 5px;
  }

  .react-calendar__decade-view, .react-calendar__year-view{
    overflow: auto;
    height: 218px;
  }
`;

class CustomDatePicker extends React.Component {
  state = {
    value: this.props.initialValue ? new Date(this.props.initialValue * 1000) : null,
  };

  handleOptionChange = (value) => {
    const { onChange } = this.props;

    if (value) {
      onChange(value.getTime() / 1000);

      this.setState({
        value,
      });
    }
  };

  render = () => {
    const { value } = this.state;

    return (
      <DatePickerWrapper>
        <DatePicker
          calendarIcon={<i className="el-icon el-icon-calendar" />}
          calendarType="Arabic"
          onChange={this.handleOptionChange}
          value={value}
          clearIcon={null}
          locale="en-GB"
        />
      </DatePickerWrapper>
    );
  }
}

CustomDatePicker.defaultProps = {
  initialValue: null,
};

CustomDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

export default CustomDatePicker;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';

import withAlertMsg from '~/modules/core/utils/alertHelpers/withAlertContainer';
import AlertTypes from '~/modules/core/utils/alertHelpers/alertComponent/AlertTypes';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Icon from '~/modules/coreUI/components/basic/Icon';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import LabelWithAction from './LabelWithAction';

const Input = styled.input`
  border: none;
  display: inline-block;
  min-width: ${props => 20 * props.theme.new.spacer}px;
  width: ${props => 26.5 * props.theme.new.spacer}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${cssMediaMax.mobile`
    width: 100px;
    min-width: 100px;
  `}
  ${cssMediaMax.xsmall`
    width: 80px;
    min-width: 80px;
  `}
`;
const Action = styled(Row)`
  cursor: pointer;
  i {
    color: ${props => props.theme.colors.secondary};
  }
`;

const copyCode = (notifyAlert) => {
  const copyText = document.getElementById('referring_code');
  copyText.select();
  document.execCommand('copy');
  notifyAlert({
    component: () => (
      <Label inverted important semiBold>
        {i18next.t('ecommerceOrder:InviteFriend.successMessage')}
      </Label>
    ),
    type: AlertTypes.success,
    opacity: 1,
    toastID: 'copy_success_toast',
    autoClose: false,
  });
};


const CopyLinkArea = ({ referringCode, notifyAlert }) => (
  <LabelWithAction
    label={(
      <React.Fragment>
        <Icon primary className="el-icon el-icon-invite" />
        <Spacer size={0.5} />
        <Label primary sm>
          {i18next.t('ecommerceOrder:InviteFriend.invitationLink')}
        </Label>
      </React.Fragment>
    )}
    content={(
      <Input
        readOnly
        id="referring_code"
        value={`${window.location.origin}/invite/${referringCode}`}
      />
    )}
    action={(
      <Action spaceBetween={0.5} onClick={() => copyCode(notifyAlert)}>
        <Icon className="el-icon el-icon-copy" />
        <Label semiBold sm secondary>{i18next.t('ecommerceOrder:InviteFriend.copyLink')}</Label>
      </Action>
    )}
  />
);

CopyLinkArea.propTypes = {
  referringCode: PropTypes.string.isRequired,
  notifyAlert: PropTypes.func.isRequired,
};

export default withAlertMsg(CopyLinkArea);

const FooterTrans = {
  En: {
    termsConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    return: 'Refund Policy',
    financialPolicy: 'Financial Policy',
    faqs: 'Help Center',
    faq: 'Help Center',
    about: 'About Us',
    contact: 'Contact Us',
    ask: 'Ask Ayk',
    join: 'Join as a Business',
    downloadTitle: 'Download Our Apps',
    downloadSubTitle: 'Now you can download Ayk Mall app on your mobile, whether you are an iOS or Android user',
    copyrights: 'Copyright © 2021, Ayk for eCommerce LLC. All Rights Reserved.',
    follow: 'Follow Us',
    haveQuestion: 'Have a Question?',
    askAyk: 'Ask Ayk',
    noFaq: 'No FAQs yet!',
    faqDetailsHeader: '{{category}} FAQs',
    find: 'Search',
    howCanHelp: 'How can we help you?',
    noResultFound: 'No results found!',
    stillFaceIssues: 'Still have a question? please, feel free to contact us!',
    polices: 'Policies',
  },
  Ar: {
    termsConditions: 'الشروط والأحكام',
    privacyPolicy: 'سياسة الخصوصية',
    return: 'سياسة إرجاع المبالغ',
    financialPolicy: 'السياسة المالية',
    faqs: 'مركز المساعدة',
    faq: 'مركز المساعدة',
    about: 'من نحن؟',
    contact: 'تواصل معنا',
    ask: 'اسأل أيك',
    join: 'انضم كعمل تجاري',
    downloadTitle: 'تحميل تطبيقاتنا',
    downloadSubTitle: 'الآن يمكنك تحميل تطبيق أيك مول على هاتفك الجوال، سواءً كنت من مستخدمي آي.أو.إس أو أندرويد',
    copyrights: 'حقوق النشر © ٢٠٢١، أيك للتجارة الإلكترونية ذ.م.م. جميع الحقوق محفوظة.',
    follow: 'تابعنا',
    haveQuestion: 'هل لديك سؤال؟',
    askAyk: 'اسأل أيك',
    noFaq: 'لا توجد أسئلة شائعة حاليًا!',
    faqDetailsHeader: 'أسئلة {{category}} الشائعة',
    find: 'بحث',
    howCanHelp: 'كيف يمكننا مساعدتك؟',
    noResultFound: 'لا توجد نتائج!',
    stillFaceIssues: 'لا يزال لديك سؤال؟ لا تتردد بالتواصل معنا!',
    polices: 'السياسات',
  },
};
export default FooterTrans;

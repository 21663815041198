import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, translate } from 'react-i18next';
import i18next from 'i18next';
import _ from 'lodash';

import { fetchQuery, graphql } from 'relay-runtime';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { XSmallLabel } from '~/modules/coreUI/components/basic/Labels';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import {
  InwaniWrapper,
  InwaniContainer,
  Input,
  BuildingInfo,
  ZoneInfo,
  StreetInfo,
  CustomButton,
} from './InwaniStyles';

const query = graphql`
  query InwaniQuery($input: InwaniInput) {
    inwani(input: $input) {
      address
      country
      lat
      lng
    }
  }
`;

const INWANI_SERVER_ERROR_MESSAGE = direction => (direction === 'rtl' ? 'يحتوي عنوانى على قيم غير صالحة' : 'Inwani has invalid values');
const INWANI_VALIDATION_ERROR_MESSAGE = direction => (direction === 'rtl' ? 'عنوانى به حقول مفقودة' : 'Inwani has missing fields');

class Inwani extends Component {
  state = {
    value: this.props.value || {},
    isLoading: false,
  };

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  doesInwaniPropertyHasValue = propertyName => this.state.value && this.state.value[propertyName] && this.state.value[propertyName].trim();

  InwaniHasValues = (checkForAll = true) => {
    let hasValue = false;
    const predicate = checkForAll ? _.every : _.some;
    if (predicate(['inwani_street', 'inwani_zone', 'inwani_building'], this.doesInwaniPropertyHasValue)) {
      hasValue = true;
    }
    return hasValue;
  };

  validate = async () => {
    let inwaniResponse = {};
    if (this.InwaniHasValues(false)) {
      inwaniResponse = await this.findInwani();
    }
    return inwaniResponse;
  };

  findInwani = async () => {
    if (!this.InwaniHasValues()) {
      this.setValidationError(INWANI_VALIDATION_ERROR_MESSAGE(this.props.direction));
      return null;
    }

    this.resetInwaniQueryResult();

    this.setState({ isLoading: true });
    let inwaniAPIresponse = null;

    try {
      inwaniAPIresponse = await fetchQuery(this.props.environment, query, {
        input: {
          street: parseInt(this.state.value.inwani_street, 10),
          zone: parseInt(this.state.value.inwani_zone, 10),
          building: parseInt(this.state.value.inwani_building, 10),
        },
      }, { force: true });
      this.setState(prevState => ({
        value: {
          ...prevState.value,
          lat: inwaniAPIresponse.inwani.lat,
          lng: inwaniAPIresponse.inwani.lng,
          address1: inwaniAPIresponse.inwani.address,
        },
      }),
      () => {
        this.props.onChange({ ...this.state.value });
      });
      this.setState({ isLoading: false });

      if (inwaniAPIresponse.inwani.lat) {
        this.setValidationError(null);
      } else {
        this.setValidationError(INWANI_SERVER_ERROR_MESSAGE(this.props.direction));
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.setValidationError(error);
    }
    if (inwaniAPIresponse && inwaniAPIresponse.inwani && inwaniAPIresponse.inwani.address == null) {
      inwaniAPIresponse = null;
    }
    return inwaniAPIresponse;
  }

  setValidationError = (error) => {
    if (this.props.onFindInwaniFailed) {
      this.props.onFindInwaniFailed(error);
    }
  }

  resetInwaniQueryResult = (additionalValues) => {
    this.setState(prevState => ({
      value: {
        ..._.pick(prevState.value, ['inwani_street', 'inwani_zone', 'inwani_building']), // CODE_REVIEW : Extract to another method ( reset value to base props )
        ...additionalValues,
      },
    }),
    () => {
      this.props.onChange({
        ...this.state.value,
      });
    });
  }

  handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    this.resetInwaniQueryResult({
      [inputName]: inputValue,
    });
  };

  onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^[0-9\b]+$/.test(keyValue)) event.preventDefault();
  }

  renderInput = (isBuilding, placeholder, name, inputValue) => (
    <Input
      name={name}
      type="text"
      autoComplete="off"
      onKeyPress={e => this.onKeyPress(e)}
      onChange={e => this.handleInputChange(e)}
      placeholder={placeholder}
      maxLength="4"
      value={inputValue}
      isBuilding={isBuilding}
    />
  );

  render() {
    return (
      <InwaniWrapper>
        <InwaniContainer>
          <BuildingInfo fullWidth>
            <Row fullWidth spaceBetweenJustified>
              <XSmallLabel semiBold inverted important>
                <Trans i18nKey="Checkout.buildingNumber" />
              </XSmallLabel>
              <XSmallLabel inverted important className="ar">
                <Trans i18nKey="Checkout.buildingNumberAr" />
              </XSmallLabel>
            </Row>
            <Spacer size={1} />
            <Row fullWidth centerJustified>
              {this.renderInput(
                true,
                i18next.t('ecommerceOrder:Checkout.buildingNo'),
                'inwani_building',
                this.state.value.inwani_building,
              )}
            </Row>
          </BuildingInfo>
          <Row fullWidth>
            <ZoneInfo fullWidth>
              <Row fullWidth spaceBetweenJustified>
                <XSmallLabel semiBold inverted important>
                  <Trans i18nKey="Checkout.zone" />
                </XSmallLabel>
                <XSmallLabel inverted important>
                  <Trans i18nKey="Checkout.zoneAr" />
                </XSmallLabel>
              </Row>
              <Spacer size={1} />
              {this.renderInput(
                false,
                i18next.t('ecommerceOrder:Checkout.zoneNo'),
                'inwani_zone',
                this.state.value.inwani_zone,
              )}
            </ZoneInfo>
            <StreetInfo fullWidth>
              <Row fullWidth spaceBetweenJustified>
                <XSmallLabel semiBold inverted important>
                  <Trans i18nKey="Checkout.street" />
                </XSmallLabel>
                <XSmallLabel inverted important>
                  <Trans i18nKey="Checkout.streetAr" />
                </XSmallLabel>
              </Row>
              <Spacer size={1} />
              {this.renderInput(
                false,
                i18next.t('ecommerceOrder:Checkout.streetNo'),
                'inwani_street',
                this.state.value.inwani_street,
              )}
            </StreetInfo>
          </Row>
        </InwaniContainer>
        {this.props.editable && (
          <CustomButton
            sm
            block
            semiBold
            passive
            loading={this.state.isLoading}
            onClicked={e => this.findInwani(e)}
          >
            <Trans i18nKey="Checkout.inwaniButton" />
          </CustomButton>
        )}
      </InwaniWrapper>
    );
  }
}

Inwani.defaultProps = {
  value: {
    inwaniBuilding: '',
    inwaniZone: '',
    inwaniStreet: '',
  },
  editable: false,
  onFindInwaniFailed: null,
  media: null,
};

Inwani.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  onFindInwaniFailed: PropTypes.func,
  value: PropTypes.shape({
    inwaniBuilding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inwaniZone: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inwaniStreet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onRef: PropTypes.func.isRequired,
  media: PropTypes.shape({
    maxTablet: PropTypes.bool,
  }),
  editable: PropTypes.bool,
  direction: PropTypes.string.isRequired,
};

export default withDirection(withRelayEnvironment(translate('ecommerceOrder')(Inwani)));

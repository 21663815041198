import React from 'react';
import styled from 'react-emotion';

import { PanelRoot } from '~/modules/accountManagement/components/basic/Panel';
import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';


const InputLayout = styled(PanelRoot)`
  display: flex;
  width: 100%;
  align-items: stretch;
`;

const isClubImageUploadVisible = locals => locals.inputs.club_attachment.props.options.attrs.isVisible(locals);
const isSchoolImageUploadVisible = locals => locals.inputs.school_attachment.props.options.attrs.isVisible(locals);
const isProviderImageUploadVisible = locals => locals.inputs.phone_provider_attachment.props.options.attrs.isVisible(locals);
const isInshuranceImageUploadVisible = locals => locals.inputs.insurance_company_attachment.props.options.attrs.isVisible(locals);
const isEmployerImageUploadVisible = locals => locals.inputs.employer_attachment.props.options.attrs.isVisible(locals);

const EditUserInfoFormLayout = locals => (
  <Column topAligned>
    <Row fullWidth spaceBetween={[0, 1.5]}>
      <InputLayout>{locals.inputs.first_name}</InputLayout>
    </Row>
    <Row fullWidth spaceBetween={[0, 1.5]}>
      <InputLayout>{locals.inputs.last_name}</InputLayout>
    </Row>
    <Row fullWidth>
      <InputLayout>{locals.inputs.email}</InputLayout>
    </Row>
    <Row fullWidth spaceBetween={[0, 1.5]}>
      <InputLayout>{locals.inputs.nationality}</InputLayout>
    </Row>
    <Row fullWidth spaceBetween={[0, 1.5]}>
      <InputLayout>{locals.inputs.mobile_number}</InputLayout>
    </Row>
    <Row fullWidth topJustified pl={1.5}>
      <InputLayout>{locals.inputs.gender}</InputLayout>
    </Row>
    <Row fullWidth>
      <InputLayout>{locals.inputs.birthdate_timestamp}</InputLayout>
    </Row>
    <Row fullWidth spaceBetween={[0, 1.5]}>
      <InputLayout>{locals.inputs.country}</InputLayout>
    </Row>
    <Row fullWidth spaceBetween={[0, 1.5]}>
      <InputLayout>{locals.inputs.city}</InputLayout>
    </Row>
    <Row fullWidth>
      <InputLayout>{locals.inputs.short_address}</InputLayout>
    </Row>
    {
      locals.context.currentFormValues.country.country_code === '974' && (
        <React.Fragment>
          <Row fullWidth>
            <InputLayout>{locals.inputs.phone_provider_id}</InputLayout>
          </Row>
          <Row fullWidth bottomAligned spaceBetween={[0, 1.5]}>
            {isProviderImageUploadVisible(locals) && <InputLayout>{locals.inputs.phone_provider_attachment}</InputLayout>}
          </Row>
          <Row fullWidth>
            <InputLayout>{locals.inputs.school_id}</InputLayout>
          </Row>
          <Row fullWidth bottomAligned spaceBetween={[0, 1.5]}>
            {isSchoolImageUploadVisible(locals) && <InputLayout>{locals.inputs.school_attachment}</InputLayout>}
          </Row>
          <Row fullWidth>
            <InputLayout>{locals.inputs.employer_id}</InputLayout>
          </Row>
          <Row fullWidth bottomAligned spaceBetween={[0, 1.5]}>
            {isEmployerImageUploadVisible(locals) && <InputLayout>{locals.inputs.employer_attachment}</InputLayout>}
          </Row>
          <Row fullWidth>
            <InputLayout>{locals.inputs.insurance_company_id}</InputLayout>
          </Row>
          <Row fullWidth bottomAligned spaceBetween={[0, 1.5]}>
            {isInshuranceImageUploadVisible(locals) && <InputLayout>{locals.inputs.insurance_company_attachment}</InputLayout>}
          </Row>
          <Row fullWidth>
            <InputLayout>{locals.inputs.club_id}</InputLayout>
          </Row>
          <Row fullWidth bottomAligned spaceBetween={[0, 1.5]}>
            {isClubImageUploadVisible(locals) && <InputLayout>{locals.inputs.club_attachment}</InputLayout>}
          </Row>
        </React.Fragment>
      )
    }
  </Column>
);

export default EditUserInfoFormLayout;

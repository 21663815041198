/* eslint-disable camelcase */
import React, { Component } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Collapse } from 'react-collapse';
import { Link } from 'react-router-dom';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { Row, LinearLayout } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Separator from '~/modules/coreUI/components/layouts/helpers/Separator';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Rating from '~/modules/ecommerceCoreUI/components/productDetails/areas/ProductRating';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import injectInBetween from '~/modules/core/utils/jsHelpers/injectElementBetweenChildElements';
import urlFor from '~/modules/ecommerceCore/utils/UrlConcatination';

import LineItem from '../../lineItem/OrderLineItem';

const lineItemsStyle = css`
  padding: 15px 15px 0px 15px;
`;

const opennedLineItemsStyle = css`
  padding: 15px 0px 15px 15px;
`;

const bodyStyle = css`
  margin-right: 10px;
  margin-left: 10px;
`;

const collapseButtonStyle = css`
  cursor: pointer;
`;

class Body extends Component {
  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.collapsed !== prevState.prevCollapsed) {
      return {
        collapsed: newProps.collapsed,
        prevCollapsed: newProps.collapsed,
      };
    }

    return null;
  }

  state = {
    collapsed: true,
    prevCollapsed: true, // eslint-disable-line
  };

  collapseBody = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render = () => {
    const {
      media, vendor, line_items, collapseble, number, product_type,
    } = this.props;

    return (
      <div className={media.minTablet ? bodyStyle : null}>
        <Row fullWidth spaceBetweenJustified bottomAligned>
          <LinearLayout row={!media.maxMobile} leftAligned wrap="true">
            <Label lg important>
              {vendor.name}
            </Label>
            <Spacer size={[0.5, 0.5, 1.5]} media={media} />
            <Link
              to={`${urlFor('getProductTracksLink', [
                null,
                vendor.ref_id,
                vendor.name,
              ])}#rating`}
            >
              <Rating rating={vendor.avg_rating} ratingCount={vendor.rating_count} />
            </Link>
          </LinearLayout>
          {collapseble && (
            <Row spaceBetween={1}>
              <Label sm onClick={() => this.collapseBody()} className={collapseButtonStyle}>
                {this.state.collapsed ? i18next.t('ecommerceOrder:order.showDetails') : i18next.t('ecommerceOrder:order.hideDetails')}
              </Label>
              <Label sm onClick={() => this.collapseBody()} className={collapseButtonStyle}>
                <i className={`el-icon el-icon-${this.state.collapsed ? 'down' : 'up'}-arrow-gray`} />
              </Label>
            </Row>
          )}
        </Row>
        <Spacer size={1} />
        {collapseble ? (
          <Collapse isOpened={!this.state.collapsed} theme={{ content: lineItemsStyle }}>
            {injectInBetween(
              line_items.map(lineItem => (
                <LineItem
                  lineItem={lineItem}
                  key={`bd_${lineItem.id}`}
                  orderNumber={number}
                  vendor={vendor}
                  product_type={product_type}
                  {...this.props.lineItemProps}
                />
              )),
              <Separator separatorLength="full" />,
            )}
          </Collapse>
        ) : injectInBetween(
          line_items.map(lineItem => (
            <div className={opennedLineItemsStyle} key={`bd_${lineItem.ref_id}`}>
              <LineItem lineItem={lineItem} orderNumber={number} {...this.props} {...this.props.lineItemProps} />
            </div>
          )),
          <Separator separatorLength="full" spacerSize="0" />,
        )}
      </div>
    );
  };
}

Body.defaultProps = {
  media: null,
  collapseble: true,
  lineItemProps: null,
};

Body.propTypes = {
  media: PropTypes.shape({}),
  vendor: PropTypes.shape({}).isRequired,
  line_items: PropTypes.arrayOf(PropTypes.any).isRequired,
  collapseble: PropTypes.bool,
  lineItemProps: PropTypes.shape({}),
};

export default withMedia(Body);

import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Shiitake from 'shiitake';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';

const CardContentTitle = withMedia(styled(Label)`
  margin-top: ${props => 1 * props.theme.new.spacer}px;
  margin-bottom: ${props => props.theme.paddings.small}px;

  ${props => props.media.xsmall && css`
    font-size: ${props.theme.fonts.sizes.small}px;
  `};
`);

const NameContainer = styled.div`
  width: 100%;
`;

const Title = ({ itemInfo }) => (
  <NameContainer>
    <CardContentTitle semiBold size={['sm', 'lg']} important title={itemInfo.name}>
      <Shiitake lines={3}>{itemInfo.name}</Shiitake>
    </CardContentTitle>
  </NameContainer>
);

Title.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
};

export default Title;

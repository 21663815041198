import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';

const WelcomeMessage = styled.div`
  height: 18px;
  text-align: center;
  font-size: 18.5px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: capitalize;
  ${cssMediaMax.tablet`
    display: none;
  `}
`;
const TrackWelcomeMessage = ({ text }) => <WelcomeMessage>{text}</WelcomeMessage>;

TrackWelcomeMessage.propTypes = {
  text: PropTypes.string,
};

TrackWelcomeMessage.defaultProps = {
  text: '',
};

export default TrackWelcomeMessage;

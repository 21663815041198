/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DepartmentBrands_topBrands$ref = any;
type SubDepartmentsList_subDepartmentInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type DepartmentTabPanContent_departmentInfo$ref: FragmentReference;
export type DepartmentTabPanContent_departmentInfo = {|
  +name: string,
  +products_count: ?number,
  +mega_dropdown_description: ?string,
  +mega_dropdown_image: ?string,
  +ref_id: string,
  +children: ?$ReadOnlyArray<?{|
    +$fragmentRefs: SubDepartmentsList_subDepartmentInfo$ref
  |}>,
  +top_brands: ?$ReadOnlyArray<?{|
    +$fragmentRefs: DepartmentBrands_topBrands$ref
  |}>,
  +$refType: DepartmentTabPanContent_departmentInfo$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DepartmentTabPanContent_departmentInfo",
  "type": "Taxon",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "products_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mega_dropdown_description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mega_dropdown_image",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ref_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "children",
      "storageKey": "children(limit:6)",
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 6,
          "type": "ID"
        }
      ],
      "concreteType": "Taxon",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "SubDepartmentsList_subDepartmentInfo",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "top_brands",
      "storageKey": null,
      "args": null,
      "concreteType": "Taxon",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "DepartmentBrands_topBrands",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '320054de6c2f3b748cfff22caca85f42';
module.exports = node;

import { graphql } from 'react-relay';

export const AddToCartMutationRoot = 'cart_add_item';

export const AddToCartMutation = graphql`
  mutation AddToCartMutation($input: CartItemInput!) {
    cart_add_item(input: $input) {
      errors {
        code
        field
        messages
      }
      cart {
        ...WithCartData_cart @relay(mask: false)
      }
    }
  }
`;

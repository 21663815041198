import React, { Component } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { Row, Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';


const RadioButonWrapper = styled(Row)`
  ${props => props.customStyle && props.customStyle(props)};
`;

const LabelsWrapper = styled(Column)`
  ${props => props.labelsCustomStyle && props.labelsCustomStyle(props)};
`;


const RadioElm = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${props => props.radioElmStyle && props.radioElmStyle(props)};
`;

const CircleRadio = styled.div`
  position: relative;
  width: ${props => props.theme.new.spacer * 2.8}px;
  height: ${props => props.theme.new.spacer * 2.8}px;
  background-color: ${props => props.theme.colors.named.white};
  border: 1px solid ${props => props.theme.borders.color.normal};
  border-radius: 50%;
  ${props => props.circleRadioStyle && props.circleRadioStyle(props)};
`;

const Input = styled.input`
  display: none;

  &&:checked ~ div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.secondary};
    border: none;
    &::before {
      color: ${props => props.theme.colors.invertedLabels.important};
      font-family: 'fontello', sans-serif;
      font-size: medium;
      font-weight: normal;
      line-height: 1.5;
      content: '\\E838';
    }
  }
`;

const LabelTitle = styled(Label)`
  text-transform: capitalize;
`;

class CircleRadioButton extends Component {
  onChange = (event) => {
    this.props.changeRadioButtonValue(event.target.attributes.value.value);
    if (this.props.changeType) {
      this.props.changeType(event);
    }
  };

  render() {
    const {
      value,
      name,
      isChecked,
      circleRadioStyle,
      radioElmStyle,
      labelTitle,
      subLabel,
      radioButtonName,
      customStyle,
      labelsCustomStyle,
      currrency,
    } = this.props;

    return (
      <RadioButonWrapper customStyle={customStyle}>
        <RadioElm radioElmStyle={radioElmStyle}>
          <Input type="radio" name={name} value={value} label={radioButtonName} onChange={e => this.onChange(e)} checked={isChecked} />
          <CircleRadio circleRadioStyle={circleRadioStyle} />
        </RadioElm>
        <Spacer size={1} />
        {labelTitle
         && (
           <LabelsWrapper labelsCustomStyle={labelsCustomStyle} leftJustified leftAligned>
             <Row>
               <LabelTitle size={['sm', 'sm', 'md', 'md']} emphasized>
                 {labelTitle}
               </LabelTitle>
               <Spacer size={0.3} />
               <Label size={['xs']} emphasized>
                 {currrency}
               </Label>
             </Row>
             { subLabel !== '' && (
             <Label emphasized>
               <Spacer size={1} />
               {subLabel}
             </Label>
             )
              }
           </LabelsWrapper>
         )}
      </RadioButonWrapper>
    );
  }
}

CircleRadioButton.defaultProps = {
  circleRadioStyle: null,
  radioElmStyle: null,
  labelTitle: '',
  subLabel: '',
  radioButtonName: '',
  changeType: null,
  customStyle: null,
  labelsCustomStyle: null,
  currrency: '',
  name: '',
};

CircleRadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  circleRadioStyle: PropTypes.func,
  radioElmStyle: PropTypes.func,
  changeRadioButtonValue: PropTypes.func.isRequired,
  changeType: PropTypes.func,
  labelTitle: PropTypes.string,
  subLabel: PropTypes.string,
  radioButtonName: PropTypes.string,
  customStyle: PropTypes.func,
  labelsCustomStyle: PropTypes.func,
  currrency: PropTypes.string,
};

export default CircleRadioButton;

import i18next from 'i18next';
import ToolTipTrans from './tooltip';
import OfferTagTrans from './OfferTag';
import TracksTrans from './Tracks';
import StaticPageTrans from './StaticPage';
import TabsTrans from './Tabs';
import FooterTrans from './Footer';
import ProductTrans from './Product';
import DailyDeals from './DailyDeals';
import Reviews from './Reviews';
import WrongPages from './WrongPages';
import LuckyWheel from './LuckyWheel';

const loadLocales = () => {
  i18next.addResourceBundle(
    'en',
    'ecommerceCoreUI',
    {
      metadata: {
        appName: 'BADR Archetype',
        displayName: 'ecommerceCoreUI',
        description: 'This module contains mostly the helpers for displaying items related to ecommerce, like product cards, cart, .....etc',
      },
      home: {
        menuTitle: 'eCommerceUI',
      },
      homeTitle: 'Home',
      HeaderTrackOrders: 'Track Your Orders',
      HeaderAccount: 'My Account',
      HeaderSupport: 'Help Center',
      HeaderPrize: 'Daily Gift',
      submenu: {
        tracks: 'Tracks',
        currency: 'Currency',
        language: 'Language',
      },
      HeaderSubMenuTracks: 'Tracks',
      HeaderSubMenuCurrency: 'Currency',
      HeaderSubMenuLanguage: 'Language',

      HeaderProductItems: 'No Items',

      header: {
        allProducts: 'Products',
        shopsLoading: 'Loading...',
        search: 'Search',
      },
      search: {
        title: 'search',
      },
      loader: {
        defaultLoadingTextContent: 'Loading...',
      },

      Pagination: {
        PreviousButton: 'Previous',
        NextButton: 'Next',
      },

      PaginationResult: {
        ShowResult: 'Showing {{start}}-{{end}} of {{total}} Result',
        ShowResults: 'Showing {{start}}-{{end}} of {{total}} Results',
        ShowSinglePageResult: '{{total}} Result',
        ShowSinglePageResults: '{{total}} Results',
      },
      QuickSearch: {
        SeeAllResults: 'See All Results ({{count}})',
        NoResult: 'No Search Results (0)',
        Placeholder: 'What are you looking for?',
      },
      NoProductsResults: {
        NoProductsFound: 'No products found',
        SorryNoResultFound: 'Sorry, no results found',
        for: ' for ',
        ChangeSearchCriteria: 'Try to search again. We have many other products that you will like!',
      },
      NoShopsResults: {
        NoShopsFound: 'No shops found',
        ChangeSearchCriteria: 'Try to check your spelling, and search again!',
      },
      productFilters: {
        apply: 'Apply',
        priceRangeFrom: 'From',
        priceRangeTo: 'To',
        Filteration: 'Filtration',
        viewSearchResult: 'View Search Results',
      },
      SelectedTags: {
        clearAll: 'Clear All',
      },
      Newsletter: {
        title: 'Join Our Newsletter',
      },
      departmentInfo: {
        moreThan: 'More than',
        discover: 'Discover',
        text: 'Will be added from admin app',
        viewAllLabel: 'View All',
      },
      logout: 'Log Out',
      logIn: 'Log In',
      signUp: 'Sign Up',
      Stock: {
        in: 'Available',
        out: 'Not Available',
        only: 'Only {{stockItemsLength}} Available',
      },
      productsDepartments: 'Products Departments',
      Alert: {
        wishListCardAdd: 'Added to your wishlist successfully',
        wishListCardRemove: 'Removed from your wishlist successfully',
        followAdd: 'Added to your following list successfully',
        followRemove: 'Removed from your following list successfully',
        wishlistLoginRequired: 'Want to keep it? log in now',
      },
      DailyDeals: {
        ...DailyDeals.En,
      },
      ToolTipTrans: {
        ...ToolTipTrans.En,
      },
      OfferTagTrans: {
        ...OfferTagTrans.En,
      },
      StaticPageTrans: {
        ...StaticPageTrans.En,
      },
      TracksTrans: {
        ...TracksTrans.En,
      },
      Tabs: {
        ...TabsTrans.En,
      },
      Footer: {
        ...FooterTrans.En,
      },
      Product: {
        ...ProductTrans.En,
      },
      Reviews: {
        ...Reviews.En,
      },
      WrongPages: {
        ...WrongPages.En,
      },
      luckyWheel: {
        ...LuckyWheel.En,
      },
    },
    true,
    true,
  );

  i18next.addResourceBundle(
    'ar',
    'ecommerceCoreUI',
    {
      metadata: {
        appName: 'BADR Archetype',
        displayName: 'ecommerceCoreUI',
        description: 'This module contains mostly the helpers for displaying items related to ecommerce, like product cards, cart, .....etc',
      },
      homeTitle: 'الصفحة الرئيسية',
      HeaderTrackOrders: 'تتبع طلباتك',
      HeaderAccount: 'حسابي',
      HeaderSupport: 'مركز المساعدة',
      HeaderPrize: 'الهدية اليومية',
      submenu:
      {
        tracks: 'المسارات', currency: 'العملة', language: 'اللغة',
      },
      productsDepartments: 'أقسام المنتجات',
      HeaderSubMenuTracks: 'المسارات',
      HeaderSubMenuCurrency: 'العملة',
      HeaderSubMenuLanguage: 'اللغة',
      HeaderProductItems: 'فارغة',
      header:
      {
        allProducts: 'المنتجات',
        shopsLoading: 'تحميل...',
        search: 'بحث',
      },
      loader: { defaultLoadingTextContent: 'تحميل...' },
      Pagination: { PreviousButton: 'السابق', NextButton: 'التالي' },
      PaginationResult: {
        ShowResult: 'إظهار {{start}}-{{end}} من {{total}} نتيجة',
        ShowResults: 'إظهار {{start}}-{{end}} من {{total}} نتيجة',
        ShowSinglePageResult: 'نتيجة البحث: {{total}}',
        ShowSinglePageResults: 'نتائج البحث: {{total}}',
      },
      DailyDeals: {
        ...DailyDeals.Ar,
      },
      SelectedTags: {
        clearAll: 'مسح الكل',
      },
      logIn: 'تسجيل الدخول',
      signUp: 'الاشتراك',
      Newsletter: {
        title: 'انضم إلى قائمتنا البريدية',
      },
      search: {
        title: 'بحث',
      },
      departmentInfo: {
        moreThan: 'أكثر من',
        discover: 'اكتشف',
        text: 'تضاف من تطبيق الأدمن',
        viewAllLabel: 'عرض الكل',
      },
      QuickSearch:
      {
        SeeAllResults: 'شاهد جميع النتائج ({{count}})',
        NoResult: 'لا توجد نتائج (0)',
        Placeholder: 'ما الذي تبحث عنه؟ ',
      },
      NoProductsResults:
      {
        NoProductsFound: 'لا توجد منتجات',
        SorryNoResultFound: 'عفوًا، لم يتم العثور على نتائج',
        for: ' لـ ',
        ChangeSearchCriteria: 'حاول البحث مرة أخرى؛ فلدينا الكثير من المنتجات الأخرى التي ستنال إعجابك!',
      },
      NoShopsResults:
      {
        NoShopsFound: 'لا توجد محلات',
        ChangeSearchCriteria:
          'تحقق من الإملاء، وحاول البحث مرة أخرى!',
      },
      productFilters: {
        apply: 'إضافة',
        priceRangeFrom: 'من',
        priceRangeTo: 'إلى',
        Filteration: 'التصفية',
        viewSearchResult: 'عرض نتائج البحث',
      },
      logout: 'تسجيل الخروج',
      Stock: {
        in: 'متاح',
        out: 'غير متاح',
        only: 'متاح {{stockItemsLength}} فقط',
      },
      Alert: {
        wishListCardAdd: 'تمت الإضافة إلى قائمة رغباتك بنجاح',
        wishListCardRemove: 'تم الحذف من قائمة رغباتك بنجاح',
        followAdd: 'تمت الإضافة إلى قائمة متابعتك بنجاح',
        followRemove: 'تم الحذف من قائمة متابعتك بنجاح',
        wishlistLoginRequired: 'تود الاحتفاظ به؟ سجل دخول الآن',
      },
      ToolTipTrans: {
        ...ToolTipTrans.Ar,
      },
      OfferTagTrans: {
        ...OfferTagTrans.Ar,
      },
      StaticPageTrans: {
        ...StaticPageTrans.Ar,
      },
      TracksTrans: {
        ...TracksTrans.Ar,
      },
      Tabs: {
        ...TabsTrans.Ar,
      },
      Footer: {
        ...FooterTrans.Ar,
      },
      Product: {
        ...ProductTrans.Ar,
      },
      Reviews: {
        ...Reviews.Ar,
      },
      WrongPages: {
        ...WrongPages.Ar,
      },
      luckyWheel: {
        ...LuckyWheel.Ar,
      },
    },
    true,
    true,
  );

  i18next.loadNamespaces('ecommerceCoreUI');
};

export default loadLocales;

/* eslint-disable no-else-return */
import React from 'react';
import i18next from 'i18next';
import OfferTag from './OfferTag';
import ItemCountDown from './ItemCountDown';

const getDiscount = itemInfo => itemInfo.discount;

export const hasOfferTag = itemInfo => itemInfo.has_discount || itemInfo.has_offer || itemInfo.hot_deal || itemInfo.featured;

export default ({
  itemInfo,
  isHotDeal,
  isProduct,
  isShop,
}) => {
  let offerType = null;
  let content = null;
  if (isShop && itemInfo.featured) {
    offerType = 'hotOffer';
    content = i18next.t('ecommerceCoreUI:OfferTagTrans.sale');
  } else if (isHotDeal) {
    return <ItemCountDown itemInfo={isProduct ? itemInfo.min_variant : itemInfo} />;
  } else if (itemInfo.hot_deal) {
    offerType = 'hotOffer';
    content = i18next.t('ecommerceCoreUI:OfferTagTrans.hotDeal');
  } else if (itemInfo.has_offer) {
    offerType = 'offer';
    content = i18next.t('ecommerceCoreUI:OfferTagTrans.offer');
  } else if (itemInfo.has_discount) {
    offerType = 'hasDiscount';
    content = i18next.t(
      'ecommerceCoreUI:OfferTagTrans.discount',
      { discount: getDiscount(itemInfo) },
    );
  }
  return hasOfferTag(itemInfo) && (
    <OfferTag offerType={offerType}>
      <span>{content}</span>
    </OfferTag>
  );
};

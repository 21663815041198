import { commitMutation } from 'react-relay';
import findErrorsForCode from '~/modules/core/utils/relayHelpers/findErrorsForCode';

export default (environment, mutation, mutationRoot, variables, onSuccess = null, onError = null, onLoading = null) => {
  if (onLoading) {
    onLoading(true);
  }

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      const generalErrorsFieldNames = [];

      const serverErrors = {};
      let generalErrors = null;

      if (errors && errors.length > 0) {
        generalErrors = errors;
      }

      const error403 = findErrorsForCode(403, response);
      if (error403) {
        generalErrors = generalErrors || [];
        generalErrors.push(error403);
        generalErrorsFieldNames.push(error403.field);
      }

      if (generalErrors) {
        serverErrors.generalErrors = generalErrors;
      }

      const errorsExist = response
        && response[mutationRoot]
        && response[mutationRoot].errors
        && response[mutationRoot].errors.length;

      if (errorsExist) {
        response[mutationRoot].errors.forEach((error) => {
          if (!generalErrorsFieldNames.includes(error.field)) {
            serverErrors[error.field] = error.messages;
          }
        });
      }

      if (onLoading) {
        onLoading(false);
      }
      // form to render to show server errors (When no local errors are there)
      if (serverErrors && Object.keys(serverErrors).length > 0) {
        if (onError) {
          onError(serverErrors);
        }
      } else if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err) => {
      if (onLoading) {
        onLoading(false);
      }
      if (onError) {
        onError(err.message || err.toString());
      }
    },
  });
};

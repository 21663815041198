import React, { Fragment, useContext } from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import withMedia from '~/modules/core/utils/mediaHelpers/withMedia';
import { SideMenuContent } from '~/modules/ecommerceCoreUI/components/header/sideMenu/index';
import FloatingIcon from '~/modules/ecommerceCoreUI/components/floatingIcon/FloatingIcon';
import { HeaderContent } from './HeaderContent';
import { FooterContent } from './Footer';
import SiteTopAlert from './SiteTopAlert';
import PageContent from '../routes';
import ErrorBoundaryComponent from '../../../errorBoundaries/ErrorBoundaryComponent';
import { ServerErrorContext } from '../../../contextApi/contexts/serverErrorContext';
import NotConnected from '../../ecommerceCoreUI/components/errorPages/NotConnected';
import SeoConfigurations from '../../ecommerceCoreUI/components/Seo/SeoConfigurations';

const ErrorPage = React.lazy(() => import('../../ecommerceCoreUI/components/errorPages/ErrorPage'));

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  opacity: ${props => props.opacity};
`;

const Root = (props) => {
  const { CurrentState } = useContext(ServerErrorContext);
  const {
    isTabletOrMobile,
    isModalOpen,
    direction,
  } = props;
  const opacity = isModalOpen && isTabletOrMobile ? 0 : 1;
  return (
    <MainWrapper id="mainWrapper" opacity={opacity} dir={direction}>
      <FloatingIcon />
      {props.media.maxTablet && <SideMenuContent />}
      <SiteTopAlert />
      <HeaderContent />
      <ErrorBoundaryComponent>
        {
          navigator.onLine && CurrentState.errors && (
            <ErrorPage />
          )
        }
        {
          !navigator.onLine && !CurrentState.errors && (
            <NotConnected />
          )
        }
        {
          !navigator.onLine && navigator.vendor === 'Apple Computer, Inc.' && (
            <NotConnected />
          )
        }
        {
          navigator.onLine && !CurrentState.errors && (
            <Fragment>
              <SeoConfigurations />
              <PageContent />
            </Fragment>
          )
        }
      </ErrorBoundaryComponent>
      <FooterContent />
    </MainWrapper>
  );
};

Root.propTypes = {
  isTabletOrMobile: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
  media: PropTypes.shape({
    maxTablet: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withMedia(Root);

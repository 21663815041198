import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import RecommendedSlider from '~/modules/ecommerceHome/components/recommended/RecommendedSlider';
import RecommendedShopCard from '~/modules/ecommerceHome/components/recommended/RecommendedShopCard';
import AdRecommended from '~/modules/ecommerceCoreUI/components/advertisement/AdRecommended';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const SliderContainer = styled.div`
  width: 80%;
  ${cssMediaMax.desktop`
    width: 75%;
  `}
  ${cssMediaMax.mobile`
    width: 50%;
  `}
  ${cssMediaMax.xsmall`
    width: 100%;
  `}
`;

const PinnedProduct = styled.div`
  width: 20%;
  margin: 24px 0 !important;
  ${cssMediaMax.desktop`
    width: 25%;
  `}
  ${cssMediaMax.mobile`
    width: 50%;
    margin: 24px auto !important;
  `}
  ${cssMediaMax.xsmall`
    width: 100%;
  `}
  .ui.segment{
    border: none;
    background: transparent;

    ${props => cssMediaMax.mobile`
      margin: 0px ${props.theme.paddings.large}px !important;
  `}
  }
`;
const MainWrapper = styled(Row)`
  width: 100%;
  ${cssMediaMax.xsmall`
    flex-direction: column;
  `}
`;

class RecommendedShop extends React.PureComponent {
  render = () => {
    const { queryResult, trackname } = this.props;
    return (
      <MainWrapper spaceBetween={1} topAligned>
        {queryResult.recommended_shops.higlighted.length > 0 && (
          <PinnedProduct>
            <AdRecommended AdTrack={trackname} />
          </PinnedProduct>
        )}

        <SliderContainer>
          <RecommendedSlider>
            {queryResult
              && queryResult.recommended_shops.result.length > 0
              && queryResult.recommended_shops.result.map(shop => (
                <RecommendedShopCard
                  isVertical
                  // eslint-disable-next-line no-underscore-dangle
                  key={shop.__id}
                  shopInfo={shop}
                />
              ))}
          </RecommendedSlider>
        </SliderContainer>
      </MainWrapper>
    );
  }
}

RecommendedShop.propTypes = {
  queryResult: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logo_url: PropTypes.string,
    track: PropTypes.string,
    follow: PropTypes.bool,
  }).isRequired,
  trackname: PropTypes.string.isRequired,
};

export default withRootQuery(
  RecommendedShop,
  graphql`
    query RecommendedShopQuery($input: RecommendedShopTypeInput!) {
      recommended_shops(input: $input) {
        higlighted {
          ...RecommendedShopCard_shopInfo
        }
        result {
          ...RecommendedShopCard_shopInfo
        }
      }
    }
  `,
  null,
  props => ({
    input: {
      type: props.trackname,
    },
  }),
);

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { withTheme } from 'emotion-theming';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import { Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';
import PanelAlert from '~/modules/coreUI/components/forms/PanelAlert';

import EditUserInfoForm from './editInfoForm/EditUserInfoForm';
import ShowUserProfile from './showInfo';

class UserProfile extends React.Component {
  state = {
    isEditMode: false,
  };

  endEditingMode = () => {
    this.setState({ isEditMode: false });
    window.location.reload(); // WORKAROUND : To handle cases when email changes and session gets invalidated
  }

  saveProfileInfo = (response) => {
    const { updateUserInfoTempPartial } = this.props;

    updateUserInfoTempPartial({
      email: response.update_user_info.result.email,
      firstName: response.update_user_info.result.first_name,
      lastName: response.update_user_info.result.last_name,
    });

    this.endEditingMode();
  }

  editUserInfo = () => this.setState({ isEditMode: true });

  getUserInfo = (currentUser, userSettings) => ({
    first_name: currentUser?.first_name,
    last_name: currentUser?.last_name,
    email: currentUser?.email,
    country_code: currentUser?.country_code,
    phone_number: currentUser?.phone_number,
    mobile_number: `(${currentUser?.country_code}) ${currentUser?.phone_number}`,
    gender: currentUser?.gender,
    birthdate_timestamp: currentUser?.birthdate_timestamp,
    nationality: currentUser?.nationality?.ref_id,
    nationality_name: currentUser?.nationality?.name,
    country_id: currentUser?.country?.ref_id || userSettings?.current_location_country?.ref_id,
    phone_code: currentUser?.country?.phone_code || userSettings?.current_location_country?.phone_code,
    country_name: currentUser?.country?.name || userSettings?.current_location_country?.name,
    city_id: currentUser?.city?.ref_id,
    city_name: currentUser?.city?.name,
    short_address: currentUser?.short_address,
    club_id: currentUser?.club?.ref_id,
    club_name: currentUser?.club?.name,
    employer_id: currentUser?.employer?.ref_id,
    employer_name: currentUser?.employer?.name,
    insurance_company_id: currentUser?.insurance_company?.ref_id,
    insurance_company_name: currentUser?.insurance_company?.name,
    school_id: currentUser?.school?.ref_id,
    school_name: currentUser?.school?.name,
    phone_provider_id: currentUser?.phone_provider?.ref_id,
    phone_provider_name: currentUser?.phone_provider?.name,
    phone_provider_attachment: currentUser?.phone_provider_attachment?.name,
    school_attachment: currentUser?.school_attachment?.name,
    employer_attachment: currentUser?.employer_attachment?.name,
    insurance_company_attachment: currentUser?.insurance_company_attachment?.name,
    club_attachment: currentUser?.club_attachment?.name,
  });

  render = () => {
    let userInfo = null;
    const errors = this.props.currentUser?.errors;
    const hasErrors = errors?.length > 0;

    if (!hasErrors) {
      userInfo = this.getUserInfo(this.props.currentUser, this.props.userInfo.settings);
    }

    return (
      <Panel
        borderLessHeader
        borderLessBody
        headerTitle={
          i18next.t('accountManagement:Profile.personalInfo')
        }
        headerActions={!this.state.isEditMode ? ([{
          label: i18next.t('accountManagement:Profile.edit'),
          iconName: 'icon-edit',
          onClicked: this.editUserInfo,
        }]) : (
          null
        )}
        body={(
          <Box>
            <Spacer size={1.5} />
            {hasErrors && (
              <React.Fragment>
                <PanelAlert type="error" inverted errors={errors} />
                <Spacer size={1.5} />
              </React.Fragment>
            )}
            {this.state.isEditMode ? (
              <EditUserInfoForm
                onCloseEditModeRequested={this.endEditingMode}
                onSuccess={response => this.saveProfileInfo(response)}
                userInfo={userInfo}
              />
            ) : (
              <ShowUserProfile
                userInfo={userInfo}
                showErrors={this.showErrors}
              />
            )}
          </Box>
        )}
      />
    );
  }
}

UserProfile.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  userInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  updateUserInfoTempPartial: PropTypes.func.isRequired,
};


export default withTheme(withUserInfo(UserProfile));

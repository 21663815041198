import React from 'react';
import PropTypes from 'prop-types';
import NavSliderImage from './NavSliderImage';
import DoubleSlickSlider from './DoubleSlickSlider';
import SliderMainImage from './SliderMainImage';

const ItemImageSlider = ({ itemInfo, isVertical }) => {
  const images = itemInfo.images.slice(0, 3);
  return (
    <DoubleSlickSlider
      childrenCount={itemInfo.images.length}
      isVertical={isVertical}
      mainSliderChildren={
        images.map(image => (
          <SliderMainImage
            smallImage={image}
            key={image}
          />
        ))
      }
      navSliderChildren={
        images.map(image => (
          <NavSliderImage
            key={image}
            image={image}
          />
        ))
      }
    />
  );
};

ItemImageSlider.propTypes = {
  itemInfo: PropTypes.shape({}).isRequired,
  isVertical: PropTypes.bool,
};
ItemImageSlider.defaultProps = {
  isVertical: true,
};
export default ItemImageSlider;

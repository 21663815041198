/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { mediaSizesMax, cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import SlickSlider from '~/modules/ecommerceCoreUI/components/slickSlider/SlickSlider';

const SliderWrapper = styled.div`
  .slick-slider {
    .slick-track {
      margin-bottom: 50px !important;
    }
    .slick-dots {
      margin-bottom: 40px;
    }
    .slick-slide > div {
      margin: 24px 5px !important;
      ${cssMediaMax.xsmall`
        width: 100%;
        margin: 0 !important;
      `};
    }
  }
`;
const RecommendedSlider = ({ children }) => {
  const settings = {
    dots: true,
    speed: 500,
    infinite: children.length <= 5 ? false : true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: mediaSizesMax.desktop + 20,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: children.length <= 4 ? false : true,
        },
      },
      {
        breakpoint: mediaSizesMax.tablet + 300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: children.length <= 3 ? false : true,
        },
      },
      {
        breakpoint: mediaSizesMax.mobile + 20,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: children.length <= 2 ? false : true,
          arrows: false,
        },
      },
      {
        breakpoint: mediaSizesMax.mobile - 130,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <SliderWrapper>
      <SlickSlider settings={settings}>{children}</SlickSlider>
    </SliderWrapper>
  );
};

export default RecommendedSlider;

RecommendedSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

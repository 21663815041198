import React from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { removeModalPartFromLocation } from '~/modules/core/utils/modalHelpers';
import { Box } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { Loader } from 'semantic-ui-react';

import PRODUCT_SEARCH_PATH from './ProductSearchPath';
import SHOP_SEARCH_PATH from './ShopSearchPath';
import { withModules } from '../../core/utils/modulesLoader';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';

const ProductDetailsPage = React.lazy(() => import('~/modules/ecommerceCoreUI/components/productDetails/ProductDetailsPage'));
const ShopsTrack = React.lazy(() => import('~/modules/ecommerceShops/containers/shopsTrack/ShopsTrack'));
const AccountSettings = React.lazy(() => import('~/modules/accountManagement/containers/accountSettings'));
const StaticPage = React.lazy(() => import('~/modules/ecommerceCoreUI/containers/staticPages/StaticPage'));
const OrderDetails = React.lazy(() => import('~/modules/ecommerceOrder/containers/orderDetails/OrderDetails'));
const ComparePage = React.lazy(() => import('~/modules/ecommerceProducts/containers/compare/ComparePage'));
const SmartCartDetails = React.lazy(() => import('~/modules/ecommerceOrder/containers/smartCart/SmartCartDetails'));

const JoinBusiness = React.lazy(() => import('~/modules/ecommerceCoreUI/containers/staticPages/joinBusiness/JoinBusiness'));
const SuccessPage = React.lazy(() => import('~/modules/ecommerceOrder/containers/checkoutSteps/paymentCheckout/SuccessPage'));
const RefundRequest = React.lazy(() => import('~/modules/ecommerceOrder/containers/refundRequest/RefundRequest'));
const CancelService = React.lazy(() => import('~/modules/ecommerceOrder/containers/refundRequest/CancelService'));

const RefundDetails = React.lazy(() => import('~/modules/ecommerceOrder/containers/refund/RefundDetails'));
const FaqDetails = React.lazy(() => import('~/modules/ecommerceOrder/containers/customerService/FaqDetails'));
const InviteFriendLandingPage = React.lazy(() => import('~/modules/ecommerceOrder/containers/inviteFriend/InviteFriendLandingPage'));
const AskAyk = React.lazy(() => import('~/modules/ecommerceOrder/containers/AskAyk/AskAyk'));
const AskShop = React.lazy(() => import('~/modules/ecommerceOrder/containers/AskAyk/AskShop'));
const AskAykShopSuccessPage = React.lazy(() => import('~/modules/ecommerceOrder/containers/AskAyk/AskAykShopSuccessPage'));
const MessageDetails = React.lazy(() => import('~/modules/ecommerceOrder/containers/notificationAndMessages/messages/messageDetails/MessageDetails'));
const AccessErrorPage = React.lazy(() => import('~/modules/ecommerceCoreUI/components/errorPages/AccessErrorPage'));
const PageNotFound = React.lazy(() => import('~/modules/ecommerceCoreUI/components/errorPages/404_Page'));
const DeletedPage = React.lazy(() => import('~/modules/ecommerceCoreUI/components/errorPages/DeletedPage'));
const ShopProductsPage = React.lazy(() => import('../containers/ShopProducts'));

const ShopsPage = React.lazy(() => import('../containers/Shops'));
const ProductTracks = React.lazy(() => import('../containers/ProductTracks'));
const CheckoutSteps = React.lazy(() => import('../containers/CheckoutSteps'));
const ShopDetails = React.lazy(() => import('../containers/ShopDetails'));

const HomePage = React.lazy(() => import('../containers/Home'));
const ShoppingCart = React.lazy(() => import('../../ecommerceOrder/containers/shoppingCart'));
const CategoryPage = React.lazy(() => import('../containers/Category'));

const AccessPermissionsValidator = _.memoize(Component => withRouter(withUserInfo((props) => {
  if (!props.authenticated) {
    return (<AccessErrorPage authenticationIssue authorizationIssue={false} />);
  }
  if (props.match.params.authorization === 'forbidden') {
    return (<AccessErrorPage authorizationIssue />);
  }

  return (<Component>{props.children}</Component>);
})));

const PageContent = ({ history }) => (
  <React.Suspense fallback={<Loader />}>
    <Box fullWidth stretchAligned grow>
      <Switch location={removeModalPartFromLocation(history.location)}>
        <Route exact path="/:locale(ar|en)?/CheckoutSteps/:stepName(address|delivery|payment)" component={AccessPermissionsValidator(CheckoutSteps)} />
        <Route exact path="/:locale(ar|en)?/account/:tab(profile|wishlist|my-refunds|recent-viewed|my-orders|following|settings|coupons|invite|smartcart|loyalty)" component={AccessPermissionsValidator(AccountSettings)} />
        <Route exact path="/:locale(ar|en)?/account/:tab(notificationsAndMessages)/:tabIndex" component={AccessPermissionsValidator(AccountSettings)} />
        <Route exact path="/:locale(ar|en)?/cscm" component={AccessPermissionsValidator(AskAyk)} />
        <Route exact path="/:locale(ar|en)?/:vendorName/:vendorId/cscm" component={AccessPermissionsValidator(AskShop)} />

        <Route exact path="/:locale(ar|en)?/:orderNumber/od/:authorization(forbidden)?" component={AccessPermissionsValidator(OrderDetails)} />
        <Route exact path="/:locale(ar|en)?/:orderNumber/ro/:authorization(forbidden)?" component={AccessPermissionsValidator(RefundRequest)} />
        <Route exact path="/:locale(ar|en)?/:orderNumber/:itemID/ri/:authorization(forbidden)?" component={AccessPermissionsValidator(RefundRequest)} />
        <Route exact path="/:locale(ar|en)?/:orderNumber/rso/:authorization(forbidden)?" component={AccessPermissionsValidator(CancelService)} />
        <Route exact path="/:locale(ar|en)?/:orderNumber/:itemID/rsi/:authorization(forbidden)?" component={AccessPermissionsValidator(CancelService)} />
        <Route exact path="/:locale(ar|en)?/:refundNumber/rd/:authorization(forbidden)?" component={AccessPermissionsValidator(RefundDetails)} />
        <Route exact path="/:locale(ar|en)?/:ticketNumber/cscms/:authorization(forbidden)?" component={AccessPermissionsValidator(AskAykShopSuccessPage)} />
        <Route exact path="/:locale(ar|en)?/:messageId/md/:authorization(forbidden)?" component={AccessPermissionsValidator(MessageDetails)} />

        <Route exact path="/:locale(ar|en)?/invite/:inviteCode" component={InviteFriendLandingPage} />
        <Route exact path="/:locale(ar|en)?/" component={HomePage} />
        <Route exact strict path={PRODUCT_SEARCH_PATH} component={CategoryPage} />
        <Route exact strict path="/:locale(ar|en)?/:categoryID(\d+)-:taxon/:productSearchString?/:taxonType(c)-p/" component={CategoryPage} />
        <Route exact strict path="/:locale(ar|en)?/:departmentID(\d+)-:taxon/:productSearchString?/:taxonType(d)-p/" component={CategoryPage} />
        <Route exact strict path="/:locale(ar|en)?/:brandID(\d+)-:taxon/:productSearchString?/:taxonType(b)-p/" component={CategoryPage} />
        <Route exact strict path="/:locale(ar|en)?/:departmentID(\d+)-:taxon/:brandID(\d+)-:brand/:productSearchString?/:taxonType(d-b)-p/" component={CategoryPage} />
        <Route exact strict path="/:locale(ar|en)?/:vendorID(\d+)-:vendor/:productSearchString?/:taxonType(s)-p/" component={CategoryPage} />
        <Route exact strict path="/:locale(ar|en)?/:vendorID(\d+)-:vendorName/:categoryID(\d+)-:taxon/:productSearchString?/:taxonType(s-c)-p/" component={ShopProductsPage} />
        <Route exact strict path="/:locale(ar|en)?/:vendorID(\d+)-:vendorName/:brandID(\d+)-:taxon/:productSearchString?/:taxonType(s-b)-p/" component={ShopProductsPage} />
        <Route exact strict path={SHOP_SEARCH_PATH} component={ShopsPage} />
        <Route exact path="/:locale(ar|en)?/cart" component={ShoppingCart} />
        <Route exact path="/:locale(ar|en)?/:slug(\d+-.*)/p/" component={ProductDetailsPage} />
        <Route exact path="/:locale(ar|en)?/:vendorID(\d+)-:vendorName/:slug(\d+-.*)/sp/" component={ProductDetailsPage} />
        <Route exact path="/:locale(ar|en)?/:trackId(\d+)-:trackName/pt/" component={ProductTracks} />
        <Route exact path="/:locale(ar|en)?/:track(products)" component={ProductTracks} />
        <Route exact path="/:locale(ar|en)?/:vendorID(\d+)-:vendorName/s-pt/" component={ShopDetails} />
        <Route exact path="/:locale(ar|en)?/:vendorID(\d+)-:vendorName/:trackId(\d+)-:trackName/s-d-pt/" component={ShopDetails} />
        <Route exact path="/:locale(ar|en)?/:vendorID(\d+)-:vendorName/:trackId(\d+)-:trackName/:subDepId(\d+)-:subDepName/s-sd-pt/" component={ShopDetails} />
        <Route exact path="/:locale(ar|en)?/successPage" component={SuccessPage} />
        <Route exact path="/:locale(ar|en)?/:trackId(\d+)-:trackName/:track(product)/pt/" component={ProductTracks} />
        <Route exact path="/:locale(ar|en)?/:track(shops|homemade|healthPark|eduPark)" component={ShopsTrack} />
        <Route exact path="/:locale(ar|en)?/:trackId(\d+)-:trackName/:track(shops|homemade|healthPark|eduPark)/st/" component={ShopsTrack} />
        <Route exact path="/:locale(ar|en)?/:page(about_us|support|privacy_policy|terms_conditions|return_and_refund_condition|contact_us)/stp/" component={StaticPage} />
        <Route exact path="/:locale(ar|en)?/join/" component={JoinBusiness} />
        <Route exact path="/:locale(ar|en)?/compare" component={ComparePage} />
        <Route exact path="/:locale(ar|en)?/:cart_id/sc/" component={SmartCartDetails} />
        <Route exact path="/:locale(ar|en)?/:faqCategory/csrv" component={FaqDetails} />
        <Route exact path="/:locale(ar|en)?/:faqCategory/:searchString/csrv" component={FaqDetails} />
        <Route exact path="/:locale(ar|en)?/account_status" component={DeletedPage} />
        <Route component={PageNotFound} />
      </Switch>
    </Box>
  </React.Suspense>
);

PageContent.propTypes = PropTypes.shape({
  location: PropTypes.shape({}).isRequired,
}).isRequired;

export default withModules(withRouter(PageContent));

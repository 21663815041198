/**
 * @flow
 * @relayHash abc9f94372c891ccb63b282dae21cb8b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdLevel2CardQueryVariables = {||};
export type AdLevel2CardQueryResponse = {|
  +advertisements: $ReadOnlyArray<?{|
    +button_color: string,
    +button_text: string,
    +button_text_color: string,
    +id: string,
    +large_photo_object: ?{|
      +url_original: ?string
    |},
    +ref_id: string,
    +title: string,
    +title_color: string,
    +sub_title_1: string,
    +sub_title_1_color: string,
    +url: string,
  |}>
|};
export type AdLevel2CardQuery = {|
  variables: AdLevel2CardQueryVariables,
  response: AdLevel2CardQueryResponse,
|};
*/


/*
query AdLevel2CardQuery {
  advertisements(limit: 2, ad_section: homepage_2, kind: web) {
    button_color
    button_text
    button_text_color
    id
    large_photo_object {
      url_original
    }
    ref_id
    title
    title_color
    sub_title_1
    sub_title_1_color
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "advertisements",
    "storageKey": "advertisements(ad_section:\"homepage_2\",kind:\"web\",limit:2)",
    "args": [
      {
        "kind": "Literal",
        "name": "ad_section",
        "value": "homepage_2",
        "type": "AdSections"
      },
      {
        "kind": "Literal",
        "name": "kind",
        "value": "web",
        "type": "AdKind"
      },
      {
        "kind": "Literal",
        "name": "limit",
        "value": 2,
        "type": "Int"
      }
    ],
    "concreteType": "Advertisement",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ref_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_text_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "large_photo_object",
        "storageKey": null,
        "args": null,
        "concreteType": "ImageDetails",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url_original",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "button_text",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_title_1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sub_title_1_color",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AdLevel2CardQuery",
  "id": null,
  "text": "query AdLevel2CardQuery {\n  advertisements(limit: 2, ad_section: homepage_2, kind: web) {\n    button_color\n    button_text\n    button_text_color\n    id\n    large_photo_object {\n      url_original\n    }\n    ref_id\n    title\n    title_color\n    sub_title_1\n    sub_title_1_color\n    url\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AdLevel2CardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "AdLevel2CardQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe4cb72ea390a77f38ede0d5858afbc3';
module.exports = node;

import React from 'react';
import Loading from 'react-loading-components';
import i18next from 'i18next';
import { withTheme } from 'emotion-theming';

import { Label } from '~/modules/coreUI/components/legacies/Labels';
import { CenterAlignedColumn } from '~/modules/coreUI/components/legacies/Columns';

/**
 * Lader: function creates loader component
 * @param {Object} props optional passed props to component,
 * if nothing passed will use loaderDefaultProps
 * @param {String} props.loadingText optional text to display below loader
 * @returns {Component} Loader component with styles passed or default
 * @tutorial all available options (Types) can be found here
 * https://www.npmjs.com/package/react-loading-components
 */
function Loader(props) {
  const loaderDefaultProps = {
    type: 'puff',
    color: props.theme.colors.primary,
    width: 100,
    height: 100,
    loadingText: i18next.t('ecommerceCoreUI:loader.defaultLoadingTextContent'),
  };

  const loader = (
    <CenterAlignedColumn>
      <Loading
        type={props.type || loaderDefaultProps.type}
        width={props.width || loaderDefaultProps.width}
        height={props.height || loaderDefaultProps.height}
        fill={props.color || loaderDefaultProps.color}
      />

      {props.loadingText && (
        <Label color={props.colors || loaderDefaultProps.color}>{props.loadingText}</Label>
      )}
    </CenterAlignedColumn>
  );

  return loader;
}

export default withTheme(Loader);

/* eslint-disable import/prefer-default-export */
import styled from 'react-emotion';
import {
  LargeLabel,
  XSmallLabel,
  XXSmallLabel,
  XXXSmallLabel,
  SmallLabel,
  XLargeLabel,
} from '~/modules/coreUI/components/legacies/Labels';

export const PanelTitle = styled(LargeLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};

  ${props => props.customStyle && props.customStyle(props)};
`;
export const SecondTitle = styled(XLargeLabel)`
  color: ${props => props.theme.colors.labels.normal};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;
export const PanelSubtitle = styled(XSmallLabel)`
  color: ${props => props.theme.colors.labels.normal};

  ${props => props.customStyle && props.customStyle(props)};
`;

export const PanelContentLabel = styled(SmallLabel)`
  color: ${props => props.theme.colors.labels.normal};
`;

export const PanelContentMinorLabel = styled(XXSmallLabel)`
  color: ${props => props.theme.colors.labels.normal};
`;

export const PanelContentSmallLabel = styled(XSmallLabel)`
  color: ${props => props.theme.colors.labels.normal};
`;

export const ParagraphPanelContent = styled(PanelContentMinorLabel)`
  line-height: ${props => props.theme.fonts.sizes.xSmall * 1.7}px;
`;
export const CenteredParagraphPanelContent = styled(PanelContentLabel)`
  line-height: ${props => props.theme.fonts.sizes.xSmall * 1.7}px;
  text-align: center;
`;

export const SocialMediaSectionTitle = styled(XXSmallLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const SocialMediaNameLabel = styled(XXSmallLabel)`
  color: ${props => props.theme.colors.named.white};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const SocialMediaPromptLabel = styled(XXXSmallLabel)`
  color: ${props => props.theme.colors.named.white};
  font-weight: 600;
`;

export const EmailSentTitle = styled(LargeLabel)`
  color: ${props => props.theme.colors.labels.emphasized};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

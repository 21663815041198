import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import i18next from 'i18next';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import Panel from '~/modules/ecommerceCoreUI/components/basic/Panel';
import CurrentUserQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/CurrentUserQuery';

import NoPoints from './NoPoints';
import AllPoints from './AllPoints';
import ShopsPoints from './ShopsPoints';

const bodyStyle = () => css`
  padding: 15px 0;
`;
const headerStyle = () => css`
  font-weight: 600;
`;
const LoyaltyPrograms = ({ queryResult }) => (
  <div>
    <Panel
      borderLessHeader
      borderLessBody
      headerTitle={i18next.t('ecommerceOrder:Loyalty.aykMembership')}
      body={(
        <React.Fragment>
          {queryResult.current_user_details.current_user.ayk_loyalty_program.points !== 0 ? (
            <AllPoints queryResult={queryResult.current_user_details.current_user} />
          ) : (
            <NoPoints queryResult={queryResult.current_user_details.current_user} />
          )}
        </React.Fragment>
      )}
      bodyStyle={bodyStyle}
      headerStyle={headerStyle}
    />
    {queryResult.current_user_details.current_user.vendor_loyalty_programs.length !== 0 && (
      <Panel
        borderLessHeader
        borderLessBody
        headerTitle={i18next.t('ecommerceOrder:Loyalty.shopsMembership')}
        body={(<ShopsPoints queryResult={queryResult.current_user_details.current_user} />)}
        bodyStyle={bodyStyle}
      />
    )}
  </div>
);

LoyaltyPrograms.propTypes = {
  queryResult: PropTypes.shape({}),
};

LoyaltyPrograms.defaultProps = {
  queryResult: [],
};

export default withRootQuery(
  LoyaltyPrograms,
  CurrentUserQuery,
  null,
  null,
  { force: true },
);

/* eslint-disable camelcase */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

import Status from './MessageStatus';

const Logo = styled.div`
  position: relative;
  width: ${props => 5 * props.theme.new.spacer}px;
  height: ${props => 5 * props.theme.new.spacer}px;
  overflow: hidden;
  background-color: ${props => props.theme.new.colors.named.inverted};
  border: 1px solid ${props => props.theme.new.borders.color.light};
  border-radius: 50%;
`;

const Image = styled.img`
  width: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
`;

const LogoContainer = styled(Row)`
  position: relative;
`;

export const isUnreadMessage = unreadMessagesCount => unreadMessagesCount > 0;

const OwnerMessageLogo = ({ thread, direction }) => (
  <LogoContainer rightJustified>
    {!!isUnreadMessage(thread?.unread_messages_count) && <Status isUnread={!!isUnreadMessage(thread?.unread_messages_count)} />}
    <Logo>
      {thread?.vendor ? (
        <Image src={thread?.vendor?.logo?.url_original} alt={thread?.vendor?.logo?.name} />
      ) : (
        <Image
          src={
          direction === 'rtl'
            ? `/${imagesPathName}/Header/logo-header.png`
            : `/${imagesPathName}/Header/logo-en-lg.png`
        }
          alt=""
        />
      )}
    </Logo>
  </LogoContainer>
);

OwnerMessageLogo.defaultProps = {
  thread: {},
};

OwnerMessageLogo.propTypes = {
  thread: PropTypes.shape({}),
  direction: PropTypes.string.isRequired,
};

export default withDirection(OwnerMessageLogo);

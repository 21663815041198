import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';

import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import withRootQuery from '~/modules/core/utils/relayHelpers/withRootQuery';
import CurrentUserQuery from '~/modules/accountManagement/containers/accountSettings/queriesAndMutations/CurrentUserQuery';
import { Column } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import { LargeLabel } from '~/modules/coreUI/components/basic/Labels';
import EmptyStatePage from '~/modules/coreUI/components/layouts/emptyStatePage';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import CopyLinkArea from './CopyLinkArea';

const EmptyPageWrapper = styled(Column)`
  text-align: center;
  max-width: ${props => 50 * props.theme.new.spacer}px;
  margin: auto;
`;

const Label = styled(LargeLabel)`
  text-align: center;
  ${props => cssMediaMax.xsmall`
    font-size: ${props.theme.new.fonts.sizes.md}px;
  `}
`;
const InviteFriend = ({ queryResult }) => (
  <React.Fragment>
    <Spacer size={1.8} />
    <EmptyStatePage
      imagePath={`/${imagesPathName}/e-invite.png`}
      contentHeader={i18next.t('ecommerceOrder:Loyalty.inviteFriends')}
    />
    <EmptyPageWrapper spaceBetween={2.5}>
      <Label>{i18next.t('ecommerceOrder:InviteFriend.youEarn')}</Label>
      <CopyLinkArea referringCode={queryResult.current_user_details.current_user.referring_code} />
      <Label>{i18next.t('ecommerceOrder:InviteFriend.yourFriendEarn')}</Label>
    </EmptyPageWrapper>
  </React.Fragment>
);
InviteFriend.propTypes = {
  queryResult: PropTypes.shape({}).isRequired,
};

export default withRootQuery(
  InviteFriend,
  CurrentUserQuery,
  null,
  null,
  { force: true },
);

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'react-emotion';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';


const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  .slick-slider {
    text-align: center;
    .slick-slide,
    .slick-track,
    .slick-list {
      perspective: 2000;
      backface-visibility: hidden;
    }
    .slick-dots {
      li {
        margin: 0;
        button:before {
          font-size: ${props => props.theme.new.fonts.sizes.xxs}px;
          color: ${props => props.theme.colors.secondary};
        }
        &.slick-active button:before {
          font-size: ${props => props.theme.new.fonts.sizes.sm}px;
          color: ${props => props.theme.colors.secondary};
        }
      }
    }
    .slick-prev:before {
      content: '\\e802';
      font-family: fontello, sans-serif;
    }
    .slick-next:before {
      content: '\\e801';
      font-family: fontello, sans-serif;
    }
    .slick-prev {
      padding-right: 3px;
      left: 29px;
      :before {
        content: '\\e802';
        font-family: fontello, sans-serif;
      }
    }
    .slick-next {
      padding-left: 3px;
      right: 29px;
      left: auto;
      :before {
        content: '\\e801';
        font-family: fontello, sans-serif;
      }
    }
    .slick-prev,
    .slick-next {
      width: 32px;
      height: 80px;
      text-align: center;
      border-radius: 5px;
      display: inline-block;
      color: #7f7f7f;
      background-color: ${props => props.theme.colors.named.white};
      z-index: 1;
      top: 47%;
      box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.1);
      &:before {
        font-size: 47px;
        color: ${props => props.theme.colors.labels.important};
        opacity: 0.7;
      }
      &:hover:before {
        color: ${props => props.theme.colors.secondary};
      }
      opacity: 0.9;
    }
    .slick-disabled {
      color: ${props => props.theme.colors.labels.important};
      opacity: 0.5;
      cursor: not-allowed;
      &:hover:before {
        color: ${props => props.theme.colors.labels.important};
        opacity: 0.3;
      }
    }
    .slick-slide > div > div {
      width: 100%;
    }
  }
`;

class SlickSlider extends React.PureComponent {
  render = () => {
    const {
      children,
      settings,
      direction,
      innerRef,
      afterChange,
    } = this.props;
    const appendDirectionToSettings = pageDirection => ({
      rtl: pageDirection === 'rtl',
      ...settings,
    });
    return (
      <SliderWrapper>
        <Slider
          innerRef={innerRef}
          afterChange={afterChange}
          {...appendDirectionToSettings(direction)}
        >
          {direction === 'rtl'
            ? children.reverse()
            : children
          }
        </Slider>
      </SliderWrapper>
    );
  }
}

SlickSlider.propTypes = {
  children: PropTypes.node.isRequired,
  isVertical: PropTypes.bool,
  settings: PropTypes.objectOf(PropTypes.any).isRequired,
  direction: PropTypes.string.isRequired,
};
SlickSlider.defaultProps = {
  isVertical: true,
};

export default withDirection(SlickSlider);

import styled from 'react-emotion';
import { MediumLabel, SmallLabel, LargeLabel } from '~/modules/coreUI/components/basic/Labels';

export const NameLabel = styled(LargeLabel)`
  color: ${props => props.theme.colors.labels.important};
  text-transform: capitalize;
`;

export const PanelTitle = styled(LargeLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const RegionLabel = styled(MediumLabel)`
  color: ${props => props.theme.colors.labels.important};
  text-transform: capitalize;
`;

export const NewAddressLabel = styled(MediumLabel)`
  color: ${props => props.theme.colors.secondary};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;

export const Address = styled(MediumLabel)`
  color: ${props => props.theme.colors.labels.emphasized};
  opacity: 0.7;
`;

export const ToggleText = styled(SmallLabel)`
  color: ${props => props.theme.colors.labels.emphasized};
  font-weight: 600;
  opacity: 0.7;
`;

export const InfoHeaderLabel = styled(LargeLabel)`
  color: ${props => props.theme.colors.labels.important};
`;

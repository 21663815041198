import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import {
  XLargeLabel,
  MediumLabel,
  SmallLabel,
} from '~/modules/coreUI/components/basic/Labels';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Button from '~/modules/coreUI/components/basic/Button';
import withUserInfo from '~/modules/core/utils/accessManagementHelpers/withUserInfo';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import LuckyWheelVisitorForm from './LuckyWheelVisitorForm';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import CloseLuckyWheelBox from './CloseLuckyWheelBox';
import { LuckyWheelMutation, LuckyWheelMutationRoot } from './LuckyWheelMutation';
import withLuckyWheelInfo from './withLuckyWheelInfo';

const XLargeLabelStyle = styled(XLargeLabel)`
  color: ${props => props.theme.colors.labels.important};
  font-weight: ${props => props.theme.fonts.weights.semiBold};
`;
const MediumLabelStyle = styled(MediumLabel)`
  color: rgba(0,0,0,0.7);
`;

const SpinBoxDescription = ({
  authenticated,
  environment,
  setLuckyWheelLoading,
  handleSpinWheelResult,
}) => {
  const onMutationSuccess = (spinWheelResult) => {
    handleSpinWheelResult(spinWheelResult);
  };
  const handleSendMuttation = () => {
    setLuckyWheelLoading();
    commitMutation(
      environment,
      LuckyWheelMutation,
      LuckyWheelMutationRoot,
      { email: null },
      onMutationSuccess,
    );
  };
  return (
    <React.Fragment>
      <XLargeLabelStyle>{i18next.t('ecommerceCoreUI:luckyWheel.spinAndWin')}</XLargeLabelStyle>
      <Spacer size={1.5} />
      <MediumLabelStyle>{i18next.t('ecommerceCoreUI:luckyWheel.stayPlay')}</MediumLabelStyle>
      <MediumLabelStyle>{i18next.t('ecommerceCoreUI:luckyWheel.haveChance')}</MediumLabelStyle>
      <Spacer size={0.2} />
      <SmallLabel>{i18next.t('ecommerceCoreUI:luckyWheel.spinOnce')}</SmallLabel>
      <SmallLabel>{i18next.t('ecommerceCoreUI:luckyWheel.winCoupon')}</SmallLabel>
      <Spacer size={2.5} />
      {authenticated ? (
        <Button
          onClicked={() => handleSendMuttation()}
          width="110px"
          secondary
        >
          {i18next.t('ecommerceCoreUI:luckyWheel.spin')}
        </Button>
      ) : (
        <LuckyWheelVisitorForm />
      )}
      <Spacer size={4} />
      <CloseLuckyWheelBox
        text={i18next.t('ecommerceCoreUI:luckyWheel.notLucky')}
      />
    </React.Fragment>
  );
};

SpinBoxDescription.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  environment: PropTypes.shape({}).isRequired,
  setLuckyWheelLoading: PropTypes.func.isRequired,
  handleSpinWheelResult: PropTypes.func.isRequired,
};

export default withRelayEnvironment(withUserInfo(withLuckyWheelInfo(SpinBoxDescription)));

/* eslint-disable import/prefer-default-export */

export const desktopTopHeaderStyles = `
  padding-top: 25px;
  padding-bottom: 10px;  
`;

export const desktopBottomHeaderStyles = `
  box-shadow: none;
  border: none;
  border-radius: 7px;
`;

/* eslint-disable react/prop-types, camelcase */
import React from 'react';
import i18next from 'i18next';

import { IconOnlyButton } from '~/modules/ecommerceCoreUI/components/basic/Buttons';

const NotificationIcon = props => (
  <IconOnlyButton
    primary
    iconName="el-icon el-icon-bell-line"
    size={28}
    title={i18next.t('ecommerceCoreUI:ToolTipTrans.notification')}
    onClick={props.showNotificationsMenu}
  />
);

export default NotificationIcon;

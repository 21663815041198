import React from 'react';
import i18next from 'i18next';
import styled, { css } from 'react-emotion';

import PropTypes from 'prop-types';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import { cssMediaMax } from '~/modules/core/utils/cssHelpers/cssMedia';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';
import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const RadioElm = withDirection(styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    ${props => (props.direction === 'rtl'
    ? css`
      margin-left: ${props.theme.new.spacer * 1.5}px;
      `
    : css`
      margin-right: ${props.theme.new.spacer * 1.5}px;
  `)}
  }
  label {
    margin-right: 10px;
    cursor: pointer;
    input {
      display: none;
    }
  }
  ${cssMediaMax.tablet`
    justify-content: flex-start;
  `};
`);
const Input = styled.input`
  position: absolute;
  left: -999em;

  &&:checked + i {
    color: ${props => props.theme.colors.primary};
    opacity: 1;
  }
  &&:focus + i {
    color: ${props => props.theme.colors.primary};
  }
`;
// Should take same height as text inputs
const Icon = styled.i`
  color: ${props => props.theme.inputs.borderColor};
  font-size: ${props => 2 * props.theme.inputs.padding.top + props.theme.inputs.fontSize + 4}px;
  opacity: 0.7;
  cursor: pointer;
  &&:hover {
    color: ${props => props.theme.colors.primary};
    opacity: 1;
  }
`;

class Radio extends React.Component {
  state = {
    currentSelectedValue: this.props.value,
  };

  handleOptionChange = (value, onChange) => {
    onChange(value);
    this.setState({
      currentSelectedValue: value,
    });
  };

  changeFemaleClassName = () => {
    let femaleIcon = 'icon-female';
    if (this.state.currentSelectedValue === 'female') {
      femaleIcon = 'icon-female-filled';
    }
    return femaleIcon;
  };

  changeMaleClassName = () => {
    let maleIcon = 'icon-male';
    if (this.state.currentSelectedValue === 'male') {
      maleIcon = 'icon-male-filled';
    }
    return maleIcon;
  };

  render = () => (
    <RadioElm>
      <Row>
        <Label sm important>{i18next.t('accountManagement:Profile.gender')}</Label>
        {this.props.required && (
          <Label sm error>*</Label>
        )}
      </Row>
      <Spacer size={1.5} />
      <Input
        checked={this.state.currentSelectedValue === 'male'}
        type="radio"
        name="gender"
        value="male"
        id="male"
        tabIndex={this.props.tabIndex}
        onChange={event => this.handleOptionChange(event.target.value, this.props.onChange)}
      />
      <Icon
        title={i18next.t('coreUI:male')}
        className={this.changeMaleClassName()}
        onClick={() => this.handleOptionChange('male', this.props.onChange)}
      />
      <Spacer size={1} />
      <Input
        checked={this.state.currentSelectedValue === 'female'}
        type="radio"
        name="gender"
        value="female"
        id="female"
        tabIndex={this.props.tabIndex ? this.props.tabIndex + 1 : null}
        onChange={event => this.handleOptionChange(event.target.value, this.props.onChange)}
      />
      <Icon
        title={i18next.t('coreUI:female')}
        className={this.changeFemaleClassName()}
        onClick={() => this.handleOptionChange('female', this.props.onChange)}
      />
    </RadioElm>
  );
}

Radio.defaultProps = {
  tabIndex: null,
  value: null,
  required: false,
};
Radio.propTypes = {
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  required: PropTypes.bool,
};

export default Radio;

import React from 'react';
import PropTypes from 'prop-types';

import { Column, Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';

const MobilePanelHeader = props => (
  <Column leftAligned fullWidth spaceBetween={1}>
    <Column leftAligned fullWidth spaceBetween={0.5}>
      {props.orderNumberLabel}
      {props.orderNumberValue}
    </Column>
    <Row>{props.orderDate}</Row>
    <Row>{props.orderStatus}</Row>
    <Row fullWidth centerJustified>{props.orderSteps}</Row>
  </Column>
);

MobilePanelHeader.propTypes = {
  orderNumberLabel: PropTypes.element.isRequired,
  orderNumberValue: PropTypes.element.isRequired,
  orderDate: PropTypes.element.isRequired,
  orderStatus: PropTypes.element.isRequired,
  orderSteps: PropTypes.element.isRequired,
};

export default MobilePanelHeader;

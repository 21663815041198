/* eslint-disable no-useless-escape, max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import styled from 'react-emotion';
import Spacer from '~/modules/coreUI/components/layouts/helpers/Spacer';
import Button from '~/modules/coreUI/components/basic/Button';
import { ErrorLabel } from '~/modules/coreUI/components/basic/Labels';
import commitMutation from '~/modules/core/utils/relayHelpers/commitMutation';
import withRelayEnvironment from '~/modules/core/utils/relayHelpers/withRelayEnvironment';
import { LuckyWheelMutation, LuckyWheelMutationRoot } from './LuckyWheelMutation';
import withLuckyWheelInfo from './withLuckyWheelInfo';

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 12px 15px;
  border-radius: 25px;
  box-shadow: none;
  outline: none;
  border: none;
  border: 1px solid rgba(0,0,0,0.3);
  font-size: 13px;
  &&.hasError {
    border-color: rgba(211, 47, 47, 0.3);
  }
`;

class LuckyWheelVisitorForm extends Component {
  state = {
    emailValue: '',
    emailHasError: false,
    emailErrorText: '',
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleInputChange = (event) => {
    this.setState({
      emailValue: event.target.value,
      emailHasError: false,
    });
  }

  onMutationSuccess = (SpinWheelResult) => {
    this.props.handleSpinWheelResult(SpinWheelResult);
  }

  handleSubmitForm = (event) => {
    event.preventDefault();
    const { emailValue } = this.state;
    if (emailValue === '') {
      this.setState({
        emailHasError: true,
        emailErrorText: 'ecommerceCoreUI:luckyWheel.missingEmail',
      });
    } else if (!this.validateEmail(emailValue)) {
      this.setState({
        emailHasError: true,
        emailErrorText: 'ecommerceCoreUI:luckyWheel.notValidEmail',
      });
    } else {
      this.props.setLuckyWheelLoading();
      commitMutation(
        this.props.environment,
        LuckyWheelMutation,
        LuckyWheelMutationRoot,
        {
          email: emailValue,
        },
        this.onMutationSuccess,
      );
    }
  }

  render = () => {
    const { emailHasError, emailErrorText } = this.state;
    return (
      <React.Fragment>
        <Input type="text" autoComplete="autocomplete" className={emailHasError && 'hasError'} onChange={this.handleInputChange} placeholder={i18next.t('ecommerceCoreUI:luckyWheel.email')} />
        {emailHasError && (
          <ErrorLabel size="xxs">{i18next.t(emailErrorText)}</ErrorLabel>
        )}
        <Spacer size={3} />
        <Button onClicked={this.handleSubmitForm} width="110px" secondary>{i18next.t('ecommerceCoreUI:luckyWheel.spin')}</Button>
      </React.Fragment>
    );
  }
}

LuckyWheelVisitorForm.propTypes = {
  environment: PropTypes.shape({}).isRequired,
  setLuckyWheelLoading: PropTypes.func.isRequired,
  handleSpinWheelResult: PropTypes.func.isRequired,
};

export default withRelayEnvironment(withLuckyWheelInfo(LuckyWheelVisitorForm));

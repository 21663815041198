/**
 * @flow
 * @relayHash 509f1a34b7661b1375fc9efb94f14e6f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WishListQueryVariables = {||};
export type WishListQueryResponse = {|
  +current_user_details: ?{|
    +current_user: ?{|
      +wished_variants: ?$ReadOnlyArray<?{|
        +product: ?{|
          +min_vendor_variant: ?{|
            +id: string,
            +name: string,
            +price: number,
            +images_attachments: $ReadOnlyArray<?{|
              +url_product: string
            |}>,
          |}
        |},
        +product_type: ?string,
        +id: string,
        +ref_id: string,
        +images: $ReadOnlyArray<?string>,
        +summary: ?string,
        +images_attachments: $ReadOnlyArray<?{|
          +url_product: string
        |}>,
        +name: string,
        +avg_rating: ?number,
        +rating_count: ?number,
        +price: number,
        +slug: ?string,
        +vendor_variants_count: ?number,
        +discount_expire_at: ?any,
        +remaining_usage_percentage: ?number,
        +gift_image: ?{|
          +url_large: string,
          +url_original: string,
        |},
        +gift_text: ?string,
        +gift_included: ?boolean,
        +min_variant: ?{|
          +vendor: ?{|
            +name: ?string,
            +ref_id: string,
          |},
          +slug: ?string,
          +name: string,
          +ref_id: string,
          +root_variant_id: ?string,
          +discount_expire_at: ?any,
          +remaining_usage_percentage: ?number,
          +total_on_hand: ?number,
          +price: number,
          +original_price: number,
        |},
        +has_offer: ?boolean,
        +hot_deal: ?boolean,
        +has_discount: ?boolean,
        +wish_listed: ?boolean,
        +root_variant_id: ?string,
        +root_variant: ?{|
          +ref_id: string,
          +wish_listed: ?boolean,
        |},
        +original_price: number,
        +discount: ?number,
        +total_on_hand: ?number,
        +option_values: ?$ReadOnlyArray<?{|
          +id: string,
          +presentation: ?string,
        |}>,
        +vendor: ?{|
          +id: string,
          +ref_id: string,
          +name: ?string,
          +logo_url: ?string,
          +avg_rating: ?number,
          +rating_count: ?number,
          +followed: ?boolean,
          +grouped_shipping_methods: ?$ReadOnlyArray<?{|
            +code: ?string,
            +title: ?string,
            +description: ?string,
          |}>,
          +payment_methods: $ReadOnlyArray<?{|
            +id: string,
            +ref_id: string,
            +name: string,
            +description: ?string,
            +type: string,
            +code: ?string,
          |}>,
        |},
      |}>
    |},
    +errors: ?$ReadOnlyArray<?{|
      +field: ?string,
      +messages: ?$ReadOnlyArray<?string>,
      +code: ?number,
    |}>,
  |}
|};
export type WishListQuery = {|
  variables: WishListQueryVariables,
  response: WishListQueryResponse,
|};
*/


/*
query WishListQuery {
  current_user_details {
    current_user {
      wished_variants {
        product {
          min_vendor_variant {
            id
            name
            price
            images_attachments {
              url_product
            }
          }
          id
        }
        product_type
        id
        ref_id
        images
        summary
        images_attachments {
          url_product
        }
        name
        avg_rating
        rating_count
        price
        slug
        vendor_variants_count
        discount_expire_at
        remaining_usage_percentage
        gift_image {
          url_large
          url_original
        }
        gift_text
        gift_included
        min_variant {
          vendor {
            name
            ref_id
            id
          }
          slug
          name
          ref_id
          root_variant_id
          discount_expire_at
          remaining_usage_percentage
          total_on_hand
          price
          original_price
          id
        }
        has_offer
        hot_deal
        has_discount
        wish_listed
        root_variant_id
        root_variant {
          ref_id
          wish_listed
          id
        }
        original_price
        discount
        total_on_hand
        option_values {
          id
          presentation
        }
        vendor {
          id
          ref_id
          name
          logo_url
          avg_rating
          rating_count
          followed
          grouped_shipping_methods {
            code
            title
            description
          }
          payment_methods {
            id
            ref_id
            name
            description
            type
            code
          }
        }
      }
      id
    }
    errors {
      field
      messages
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "gift_image",
  "storageKey": null,
  "args": null,
  "concreteType": "ImageAttachment",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url_large",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url_original",
      "args": null,
      "storageKey": null
    }
  ]
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "images_attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "ImageAttachment",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url_product",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "min_vendor_variant",
  "storageKey": null,
  "args": null,
  "concreteType": "Variant",
  "plural": false,
  "selections": [
    v1,
    v2,
    v3,
    v4
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ref_id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "images",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avg_rating",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating_count",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "vendor_variants_count",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discount_expire_at",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "remaining_usage_percentage",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "product_type",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "gift_text",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "gift_included",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "root_variant_id",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total_on_hand",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "original_price",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "has_offer",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hot_deal",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "has_discount",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wish_listed",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discount",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "option_values",
  "storageKey": null,
  "args": null,
  "concreteType": "OptionValue",
  "plural": true,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "presentation",
      "args": null,
      "storageKey": null
    }
  ]
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
},
v28 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v29 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vendor",
  "storageKey": null,
  "args": null,
  "concreteType": "Shop",
  "plural": false,
  "selections": [
    v1,
    v6,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo_url",
      "args": null,
      "storageKey": null
    },
    v9,
    v10,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "followed",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "grouped_shipping_methods",
      "storageKey": null,
      "args": null,
      "concreteType": "VendorGroupedShipmentResult",
      "plural": true,
      "selections": [
        v27,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        },
        v28
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "payment_methods",
      "storageKey": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "plural": true,
      "selections": [
        v1,
        v6,
        v2,
        v28,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        v27
      ]
    }
  ]
},
v30 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "Error",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    },
    v27
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "WishListQuery",
  "id": null,
  "text": "query WishListQuery {\n  current_user_details {\n    current_user {\n      wished_variants {\n        product {\n          min_vendor_variant {\n            id\n            name\n            price\n            images_attachments {\n              url_product\n            }\n          }\n          id\n        }\n        product_type\n        id\n        ref_id\n        images\n        summary\n        images_attachments {\n          url_product\n        }\n        name\n        avg_rating\n        rating_count\n        price\n        slug\n        vendor_variants_count\n        discount_expire_at\n        remaining_usage_percentage\n        gift_image {\n          url_large\n          url_original\n        }\n        gift_text\n        gift_included\n        min_variant {\n          vendor {\n            name\n            ref_id\n            id\n          }\n          slug\n          name\n          ref_id\n          root_variant_id\n          discount_expire_at\n          remaining_usage_percentage\n          total_on_hand\n          price\n          original_price\n          id\n        }\n        has_offer\n        hot_deal\n        has_discount\n        wish_listed\n        root_variant_id\n        root_variant {\n          ref_id\n          wish_listed\n          id\n        }\n        original_price\n        discount\n        total_on_hand\n        option_values {\n          id\n          presentation\n        }\n        vendor {\n          id\n          ref_id\n          name\n          logo_url\n          avg_rating\n          rating_count\n          followed\n          grouped_shipping_methods {\n            code\n            title\n            description\n          }\n          payment_methods {\n            id\n            ref_id\n            name\n            description\n            type\n            code\n          }\n        }\n      }\n      id\n    }\n    errors {\n      field\n      messages\n      code\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WishListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "current_user_details",
        "storageKey": null,
        "args": null,
        "concreteType": "UserDetails",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "wished_variants",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": true,
                "selections": [
                  v0,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v5
                    ]
                  },
                  v1,
                  v6,
                  v7,
                  v8,
                  v4,
                  v2,
                  v9,
                  v10,
                  v3,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16,
                  v17,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "min_variant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Variant",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "vendor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Shop",
                        "plural": false,
                        "selections": [
                          v2,
                          v6
                        ]
                      },
                      v11,
                      v2,
                      v6,
                      v18,
                      v13,
                      v14,
                      v19,
                      v3,
                      v20
                    ]
                  },
                  v21,
                  v22,
                  v23,
                  v24,
                  v18,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "root_variant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Variant",
                    "plural": false,
                    "selections": [
                      v6,
                      v24
                    ]
                  },
                  v20,
                  v25,
                  v19,
                  v26,
                  v29
                ]
              }
            ]
          },
          v30
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WishListQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "current_user_details",
        "storageKey": null,
        "args": null,
        "concreteType": "UserDetails",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "current_user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "wished_variants",
                "storageKey": null,
                "args": null,
                "concreteType": "Variant",
                "plural": true,
                "selections": [
                  v0,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v5,
                      v1
                    ]
                  },
                  v1,
                  v6,
                  v7,
                  v8,
                  v4,
                  v2,
                  v9,
                  v10,
                  v3,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16,
                  v17,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "min_variant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Variant",
                    "plural": false,
                    "selections": [
                      v13,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "vendor",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Shop",
                        "plural": false,
                        "selections": [
                          v2,
                          v6,
                          v1
                        ]
                      },
                      v2,
                      v6,
                      v18,
                      v11,
                      v14,
                      v19,
                      v3,
                      v20,
                      v1
                    ]
                  },
                  v21,
                  v22,
                  v23,
                  v24,
                  v18,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "root_variant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Variant",
                    "plural": false,
                    "selections": [
                      v6,
                      v24,
                      v1
                    ]
                  },
                  v20,
                  v25,
                  v19,
                  v26,
                  v29
                ]
              },
              v1
            ]
          },
          v30
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4c6db28b468f1c1e781092e8153dc807';
module.exports = node;

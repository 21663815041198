import React from 'react';
import styled from 'react-emotion';
import i18next from 'i18next';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { LargeSpacer, MediumSpacer } from '~/modules/coreUI/components/legacies/Spacers';
import { cssMediaMax, cssMediaMin } from '~/modules/core/utils/cssHelpers/cssMedia';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';

const DownloadAppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    font-size: ${props => props.theme.fonts.sizes.xSmall}px;
    color: ${props => props.theme.colors.labels.subtle};
    font-weight: 300;
  }
`;
const DownloadAppImages = styled.div`
  display: flex;
  flex-direction: column;
  ${cssMediaMax.tablet`
    ${cssMediaMin.tablet`
      flex-direction: row;
    `}
  `};
`;

const DownloadApp = props => (
  <DownloadAppWrapper {...props}>
    <Label important semiBold lg>{i18next.t('ecommerceCoreUI:Footer.downloadTitle')}</Label>
    <LargeSpacer />
    <p>{i18next.t('ecommerceCoreUI:Footer.downloadSubTitle')}</p>
    <DownloadAppImages>
      <ExternalLink url={props.iosLink} target="_blank">
        <img src={`/${imagesPathName}/Footer/appstore-btn.png`} alt="" />
      </ExternalLink>
      <MediumSpacer />
      <ExternalLink url={props.androidLink} target="_blank">
        <img src={`/${imagesPathName}/Footer/android-btn.png`} alt="" />
      </ExternalLink>
    </DownloadAppImages>
  </DownloadAppWrapper>
);

export default DownloadApp;

/**
 * @flow
 * @relayHash 26f5f1d7be3af18cd7d9617cc143d59b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RecommendedShopCard_shopInfo$ref = any;
export type TrackType = "education_park" | "health_park" | "homemade" | "shops" | "%future added value";
export type RecommendedShopTypeInput = {
  type?: ?TrackType
};
export type RecommendedShopQueryVariables = {|
  input: RecommendedShopTypeInput
|};
export type RecommendedShopQueryResponse = {|
  +recommended_shops: ?{|
    +higlighted: ?$ReadOnlyArray<?{|
      +$fragmentRefs: RecommendedShopCard_shopInfo$ref
    |}>,
    +result: ?$ReadOnlyArray<?{|
      +$fragmentRefs: RecommendedShopCard_shopInfo$ref
    |}>,
  |}
|};
export type RecommendedShopQuery = {|
  variables: RecommendedShopQueryVariables,
  response: RecommendedShopQueryResponse,
|};
*/


/*
query RecommendedShopQuery(
  $input: RecommendedShopTypeInput!
) {
  recommended_shops(input: $input) {
    higlighted {
      ...RecommendedShopCard_shopInfo
      id
    }
    result {
      ...RecommendedShopCard_shopInfo
      id
    }
  }
}

fragment RecommendedShopCard_shopInfo on Shop {
  id
  ref_id
  logo_url
  track
  name
  follow
  avg_rating
  rating_count
  city {
    name
    id
  }
  country {
    name
    id
  }
  featured
  departments {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RecommendedShopTypeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RecommendedShopTypeInput!"
  }
],
v2 = [
  {
    "kind": "FragmentSpread",
    "name": "RecommendedShopCard_shopInfo",
    "args": null
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  v4,
  v3
],
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avg_rating",
    "args": null,
    "storageKey": null
  },
  v3,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "logo_url",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "track",
    "args": null,
    "storageKey": null
  },
  v4,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "follow",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "ref_id",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "rating_count",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "city",
    "storageKey": null,
    "args": null,
    "concreteType": "State",
    "plural": false,
    "selections": v5
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "country",
    "storageKey": null,
    "args": null,
    "concreteType": "Country",
    "plural": false,
    "selections": v5
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "featured",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "departments",
    "storageKey": null,
    "args": null,
    "concreteType": "TrackDepartment",
    "plural": true,
    "selections": [
      v3,
      v4
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RecommendedShopQuery",
  "id": null,
  "text": "query RecommendedShopQuery(\n  $input: RecommendedShopTypeInput!\n) {\n  recommended_shops(input: $input) {\n    higlighted {\n      ...RecommendedShopCard_shopInfo\n      id\n    }\n    result {\n      ...RecommendedShopCard_shopInfo\n      id\n    }\n  }\n}\n\nfragment RecommendedShopCard_shopInfo on Shop {\n  id\n  ref_id\n  logo_url\n  track\n  name\n  follow\n  avg_rating\n  rating_count\n  city {\n    name\n    id\n  }\n  country {\n    name\n    id\n  }\n  featured\n  departments {\n    id\n    name\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RecommendedShopQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_shops",
        "storageKey": null,
        "args": v1,
        "concreteType": "VendorRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "higlighted",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": v2
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": v2
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RecommendedShopQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recommended_shops",
        "storageKey": null,
        "args": v1,
        "concreteType": "VendorRecommendedResults",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "higlighted",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": v6
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "result",
            "storageKey": null,
            "args": null,
            "concreteType": "Shop",
            "plural": true,
            "selections": v6
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d8ba0ff5075d62546d65c241ba21fc5';
module.exports = node;

import React from 'react';
import t, { maybe } from 'tcomb-form';
import _ from 'lodash';
import MapContainer from './GoogleMap';

import { getGlobalAttrs } from '~/modules/coreUI/components/forms/Template';
import renderError from '~/modules/coreUI/components/forms/Errors';

export const GoogleMapFormElementTemplate = t.form.Form.templates.textbox.clone({
  renderInput: locals => (
    <MapContainer
      {...getGlobalAttrs(locals)}
      {...locals.attrs}
      autoDetectLocation={locals.attrs.autoDetectLocation}
      onChange={locals.onChange}
      value={locals.value}
    />
  ),
  renderError: locals => renderError(locals),
});

const isGoogleMapsValueValid = val => !!(val && val.lat && val.lng);

export const GoogleMapDataType = t.refinement(
  maybe(t.struct({
    lat: t.Number,
    lng: t.Number,
    address1: maybe(t.String),
  })),
  isGoogleMapsValueValid,
  'GoogleMapDataType',
);

GoogleMapDataType.getTcombFormFactory = () => t.form.Textbox;
GoogleMapDataType.getValidationErrorMessage = () => 'selected location is invalid';

const pickBasicMapFields = inwaniValues => _.pick(inwaniValues, ['lat', 'lng']);
const parseMapFields = inwaniValues => _.mapValues(inwaniValues, value => parseFloat(value));

export const flattenAndParseGoogleMapFieldValue = formVariables => ({
  ..._.omit(formVariables, 'map'),
  ...parseMapFields(pickBasicMapFields(formVariables.map)),
});

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Container as SemanticContainer } from 'semantic-ui-react';
import cuid from 'cuid';

import { Label } from '~/modules/coreUI/components/basic/Labels';
import { Row } from '~/modules/coreUI/components/layouts/helpers/LinearLayout';
import ExternalLink from '~/modules/coreUI/components/basic/ExternalLink';
import withDirection from '~/modules/core/utils/mediaHelpers/withDirection';

const labelStyles = css`
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const Breadcrumbs = props => (
  <SemanticContainer>
    <Row baselineAligned wrap="true" spaceBetween={0.5} paddingTop={1.5} paddingBottom={1.5}>
      {props.crumbs.filter(x => !!x).map((crumb, i, arr) => ((i + 1 !== arr.length) ? (
        <ExternalLink url={crumb.url} key={`${crumb.label}-${cuid()}`}>
          <Label xs subtle nowrap className={labelStyles}>
            {crumb.label}
            &nbsp;
            <i className={props.direction === 'rtl' ? 'fa fa-angle-left' : 'fa fa-angle-right'} />
          </Label>
        </ExternalLink>
      ) : (
        <Label xs hint key={crumb.label}>{crumb.label}</Label>
      )))}
    </Row>
  </SemanticContainer>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  direction: PropTypes.string.isRequired,
};

export default withDirection(Breadcrumbs);
